import { ScheduleItem } from 'pages/SchedulesPage/SchedulesPage.types';

type HvacScheduleItem = ScheduleItem & {
  type: string;
  name: string;
  usage: string;
  weekdays: string;
  on_time: string;
  off_time: string;
  holidays: string;
};

const hvacSchema = t => {
  return [
    {
      title: t('t3224') || 'Index',
      key: 'index',
      dataIndex: 'index',
      width: '8%',
      sorter: (a: HvacScheduleItem, b: HvacScheduleItem) => {
        return a?.id?.localeCompare(b?.id);
      },
    },
    {
      title: t('t2211') || 'Name',
      key: 'name',
      dataIndex: 'name',
      width: '8%',
      sorter: (a: HvacScheduleItem, b: HvacScheduleItem) => {
        return a?.name?.localeCompare(b?.name);
      },
    },
    {
      title: t('t3225') || 'Type',
      key: 'type',
      dataIndex: 'type',
      width: '8%',
      sorter: (a: HvacScheduleItem, b: HvacScheduleItem) => {
        return a?.type.localeCompare(b?.type);
      },
    },
    {
      title: t('t3327') || 'Usage',
      key: 'usage',
      dataIndex: 'usage',
      width: '8%',
      sorter: (a: HvacScheduleItem, b: HvacScheduleItem) => {
        return a?.usage.localeCompare(b?.usage);
      },
    },
    {
      title: t('t3396') || 'On Time',
      key: 'on_time',
      dataIndex: 'on_time',
      width: '8%',
      sorter: (a: HvacScheduleItem, b: HvacScheduleItem) => {
        return a?.on_time.localeCompare(b?.on_time);
      },
    },
    {
      title: t('t3329') || 'Off Time',
      key: 'off_time',
      dataIndex: 'off_time',
      width: '8%',
      sorter: (a: HvacScheduleItem, b: HvacScheduleItem) => {
        return a?.off_time.localeCompare(b?.off_time);
      },
    },
    {
      title: t('t3226') || 'Holidays',
      key: 'holidays',
      dataIndex: 'holidays',
      width: '8%',
      sorter: (a: HvacScheduleItem, b: HvacScheduleItem) => {
        return a?.holidays.localeCompare(b?.holidays);
      },
    },
    {
      title: t('t3330') || 'Weekdays',
      key: 'weekdays',
      dataIndex: 'weekdays',
      width: '8%',
      sorter: (a: HvacScheduleItem, b: HvacScheduleItem) => {
        return a?.weekdays.localeCompare(b?.weekdays);
      },
    },
  ];
};

export { hvacSchema };
