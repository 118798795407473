import { XML_DEVICE_STYPE } from '@danfoss/etui-sm-xml';
import { _ as _objectSpread2 } from './_rollupPluginBabelHelpers-e1ae8e90.js';
import { DeviceGroupedType } from './types/DeviceGrouped.js';
import { composeDeviceId } from './utils/compose-device-id.js';
import { composeRackId } from './utils/compose-rack-id.js';
import { getIsSuctionDevice } from './utils/get-is-suction-device.js';
import { getTopLevelDevice } from './utils/get-is-top-level-device.js';

/**
 * Utility to get root refrigeration device
 *
 * @export
 * @param {Device} device
 * @param {Device[]} devices
 * @returns {boolean}
 */
function getRootRefrigerationDevice(device, devices) {
  if (getTopLevelDevice(device)) {
    return true;
  }
  if (getIsSuctionDevice(device) && devices.length !== undefined) {
    if (isSuctionHasParent(device, devices) === false) {
      return true;
    }
  }
  return false;
}

// check the suction device arg1 with pack, rack or no comp's arg 1 if not matched then the it is an orphaned suction
function isSuctionHasParent(device, devices) {
  let isChild = false;
  for (const refrigeDevice of devices) {
    if (getTopLevelDevice(refrigeDevice)) {
      if (getIsChildToRoot(refrigeDevice, device)) {
        isChild = true;
        break;
      }
    }
  }
  return isChild;
}

const getIsChildToRoot = (rootDevice, possibleRootChild) => rootDevice.host === possibleRootChild.host && rootDevice.arg1 === possibleRootChild.arg2 && rootDevice.unit === possibleRootChild.unit || (possibleRootChild.arg2 === '' || possibleRootChild.arg2 === undefined) && possibleRootChild.stype !== XML_DEVICE_STYPE.S_TYPE_EVAP && possibleRootChild.arg1 === rootDevice.arg1;
function getRackGroups(deviceType, devices, suctionGroups) {
  const racks = [];
  const rootDevices = devices.filter(device => getRootRefrigerationDevice(device, devices));
  for (let k = 0; k < rootDevices.length; k++) {
    const rootDevice = _objectSpread2(_objectSpread2({}, rootDevices[k]), {}, {
      groupType: DeviceGroupedType.GROUP_DEVICE,
      deviceType
    });
    rootDevice.id = composeRackId(rootDevice);
    if (!(rootDevice.stype === XML_DEVICE_STYPE.S_TYPE_MULTISUCTION_PACK || rootDevice.stype === XML_DEVICE_STYPE.S_TYPE_RACK || rootDevice.stype === XML_DEVICE_STYPE.S_TYPE_NOCOMP_SUCTION || (rootDevice.stype === XML_DEVICE_STYPE.S_TYPE_NOCOMP_PACK || rootDevice.stype === XML_DEVICE_STYPE.S_TYPE_PACK) && rootDevice.nodetype === '255')) {
      // eslint-disable-next-line no-continue
      continue;
    }

    // rootChildren can be suction, condenser, hp control etc
    // This is the level before circuits
    const rootChildren = [];
    suctionGroups.forEach(child => {
      if (child.host === rootDevice.host) {
        if (getIsChildToRoot(rootDevice, child)) {
          rootChildren.push(_objectSpread2(_objectSpread2({}, child), {}, {
            id: composeDeviceId(child),
            items: child.items ? child.items : []
          }));
        }
      }
    });
    rootDevice.items = rootChildren;
    racks.push(rootDevice);
  }
  return racks;
}

export { getRackGroups as a, getRootRefrigerationDevice as b, getIsChildToRoot as g, isSuctionHasParent as i };
