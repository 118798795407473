import React from 'react';
import { SpinnerSize, Table } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements/';
import { useTheme } from 'styled-components';
import { ContentSpinner, EmptyState } from '@danfoss/etui-sm';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml/';
import { useTranslation } from 'react-i18next';
import { useRefrigCopy } from '../../context';
import {
  DevicesTable,
  DevicesTableContent,
} from '../../types/RefrigCopy.types';

export interface DevicesFlowTableProps {
  handleOnSelect: (selectedListItem: ConfigurationListItem) => void;
}

export const DevicesFlowTable = ({ handleOnSelect }: DevicesFlowTableProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    devicesTableData,
    selectAll,
    devicesListItems,
    isEmptyCopyDevicesTableData,
    setSelectAll,
  } = useRefrigCopy();

  const [devicesTable, setDevicesTable] = React.useState<DevicesTable[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<number[]>([]);
  const [isDevicesDataFetched, setIsDevicesDataFetched] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (devicesTableData?.devicesTableContent?.length) {
      if (!selectAll?.length) {
        const selectData = devicesListItems
          .slice(0, 3)
          .filter(data => data.name.length > 1);
        setSelectAll(selectData);
      }
      getTableData();
    }
    if (isEmptyCopyDevicesTableData) {
      setIsDevicesDataFetched(true);
    }
  }, [devicesTableData, isEmptyCopyDevicesTableData]);
  const columns = [
    {
      title: t('t76').toUpperCase(),
      dataIndex: 'name',
      key: 'name',
      width: '15%',
    },
    {
      title: t('t57').toUpperCase(),
      dataIndex: 'address',
      key: 'address',
      width: '15%',
    },
    {
      title: t('t355').toUpperCase(),
      dataIndex: 'model',
      key: 'model',
    },
  ];

  const getTableData = () => {
    const devicesTable: DevicesTable[] = [];
    const rowkeys: number[] = [];
    devicesTableData?.devicesTableContent?.forEach((content, index) => {
      if (content.toggleVal === '1') {
        rowkeys.push(index);
      }
      devicesTable.push({
        name: content.name,
        address: content.address,
        model: content.model,
        li: content.li,
        toggleVal: content.toggleVal,
        key: index,
      });
    });
    setSelectedRowKeys(rowkeys);
    setDevicesTable(devicesTable);
    setIsDevicesDataFetched(true);
  };

  const handleRowSelectionChange = (selectedKeys: number[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = React.useMemo(() => {
    return {
      selectedRowKeys,
      onChange: handleRowSelectionChange,
      onSelect: (selectedRow: DevicesTableContent) => {
        const selectedListItem = devicesListItems.find(
          item => item.li === selectedRow.li,
        );
        handleOnSelect(selectedListItem);
      },
      onSelectAll: (selected: boolean, selectedRows: DevicesTableContent[]) => {
        selected && selectedRows?.length
          ? handleOnSelect(selectAll[0])
          : handleOnSelect(selectAll[1]);
      },
      columnWidth: '1%',
    };
  }, [devicesTable]);

  return (
    <Div testId="devices-flow-table-content-div" style={{ marginTop: '70px' }}>
      {!isDevicesDataFetched ? (
        <Div testId="devices-table-spinner-div" style={{ marginTop: '200px' }}>
          <ContentSpinner size={SpinnerSize.small} transparent={true} />
        </Div>
      ) : isEmptyCopyDevicesTableData ? (
        <Div testId="devices-table-empty-div" style={{ marginTop: '200px' }}>
          <EmptyState title={t('t3351')} />
        </Div>
      ) : (
        <Table
          rowKey="devices-table-content"
          columns={columns}
          dataSource={devicesTable}
          pagination={false}
          emptyPlaceholder={null}
          rowSelection={rowSelection}
          styles={{
            td: { p: `${theme.spacing.sm}px`, height: 10 },
            root: { overflow: 'visible' },
          }}
        />
      )}
    </Div>
  );
};
