import { AkioShutdownSchedules, Refrigerations } from '../SchedulesPage.types';

export const filterSuctionLevelAkioShutdownSchedules = (
  refrigerations: Refrigerations[],
  scheduleType: string,
  scheduleIdsSuction: string,
): AkioShutdownSchedules[] => {
  return refrigerations
    ?.find(refrigeration => refrigeration?.id === scheduleType)
    ?.items?.find(refSchItem =>
      refSchItem?.items?.find(
        suctionItem =>
          suctionItem?.id === `${scheduleType}-${scheduleIdsSuction}`,
      ),
    )
    ?.items?.find(schItem => schItem.akioShutdownSchedules)
    ?.akioShutdownSchedules;
};
