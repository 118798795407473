export const getWeekdaysAndHolidaysFromByte = (
  byteValue: number,
  days: string[],
) => {
  const finalWeekDays = [];
  byteValue
    .toString(2)
    .padStart(days.length, '0')
    .split('')
    .forEach((value, index) => {
      if (value === '1') {
        finalWeekDays.push(days[index]);
      }
    });
  return finalWeekDays.reverse().join('');
};
