import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ConfigurationListItem,
  ConfigurationTabItem,
  Device,
} from '@danfoss/etui-sm-xml';
import { TABLE_ADDRESS, getArrayItemsIntersectionByField } from '../utils';
import { ConfigurationContext, ItemRenderers } from './ConfigurationContext';

export type ConfigurationProviderProps = {
  route?: string;
  rootMenuId?: TABLE_ADDRESS;
  children: React.ReactNode;
  itemByTabRenderers?: ItemRenderers;
};

export type MenuItems = Record<string, ConfigurationTabItem[]>;

export type MenuListItem = Record<string, ConfigurationListItem[]>;

export type MenuLevel = Record<string, number>;

export type DeviceItems = Record<string, Device[]>;

function ConfigurationProvider({
  route = '/configuration',
  rootMenuId = TABLE_ADDRESS.CONFIGURATION,
  children,
  itemByTabRenderers = {},
}: ConfigurationProviderProps) {
  const {
    location: { pathname },
    replace,
  } = useHistory();

  const cachedDevices = React.useRef({});

  const [menuLevelById, setMenuLevelById] = React.useState<MenuLevel>({});
  const [menuItemsById, setMenuItemsById] = React.useState<MenuItems>({});
  const [subMenuItemsById, setSubMenuItemsById] = React.useState<MenuItems>({});

  const [activeMenuId, setActiveMenuId] = React.useState<string>(null);

  const [configurationDataItem, setConfigurationDataItem] = React.useState([]);

  const [multiOptionsList, setmultiOptionsList] = React.useState<
    ConfigurationListItem[]
  >([]);

  const [isSecurityEnforcementChanged, setIsSecurityEnforcementChanged] =
    React.useState<boolean>(false);

  const handleOnSetMultiOptionsList = (dataItem: ConfigurationListItem[]) => {
    dataItem.length && setmultiOptionsList(dataItem);
  };

  const handleOnSetMenuLevel = (menuId: string, level: number) => {
    if (
      menuLevelById[menuId] === level ||
      menuId === TABLE_ADDRESS.CONFIGURATION
    )
      return;

    setMenuLevelById({
      ...menuLevelById,
      [menuId]: level,
    });
  };

  const handleOnSetMenuItems = (
    menuId: string,
    items: ConfigurationTabItem[],
  ) => {
    if (!items.length) return;

    const existingMenuItems = menuItemsById[menuId];

    const isFullyPresented =
      getArrayItemsIntersectionByField<ConfigurationTabItem>(
        existingMenuItems,
        items,
        'label',
      );

    if (!existingMenuItems?.length && items?.length) {
      setMenuItemsById({ ...menuItemsById, [menuId]: items });
      return;
    }

    if (existingMenuItems?.length && isFullyPresented) {
      return;
    }

    if (!items?.length && !existingMenuItems?.length) {
      return;
    }

    setMenuItemsById({
      ...menuItemsById,
      [menuId]: items,
    });
  };

  const handleOnSetSubMenuItems = (
    menuId: string,
    items: ConfigurationTabItem[],
  ) => {
    if (items.length) {
      setSubMenuItemsById({
        ...subMenuItemsById,
        [menuId]: items,
      });
    }
  };

  const handleOnSetConfigurationDataItem = (
    dataItem: ConfigurationListItem[],
  ) => {
    dataItem.length && setConfigurationDataItem(dataItem);
  };

  const handleOnSetSecurityEnforcementChanged = (value: boolean) => {
    setIsSecurityEnforcementChanged(value);
  };

  React.useEffect(() => {
    const splitPath = pathname.split('/');
    setActiveMenuId(splitPath[splitPath.length - 1]);
  }, [pathname]);

  React.useEffect(() => {
    const activeMenuItems = menuItemsById[activeMenuId] || [];
    if (activeMenuItems.length) {
      const indexItem = activeMenuItems[0];
      if (!pathname.includes(indexItem.id)) {
        replace(`${pathname}/${indexItem.id}`);
      }
    }
  }, [activeMenuId, menuItemsById, location.pathname]);

  return (
    <ConfigurationContext.Provider
      value={{
        route,
        rootMenuId,
        menuLevelById,
        activeMenuItem: activeMenuId,
        menuItemsById,
        subMenuItemsById,
        itemByTabRenderers,
        configurationDataItem,
        multiOptionsList,
        onSetMenuLevel: handleOnSetMenuLevel,
        onSetMenuItems: handleOnSetMenuItems,
        onSetSubMenuItems: handleOnSetSubMenuItems,
        onSetConfigurationDataItem: handleOnSetConfigurationDataItem,
        onSetMultiOptionsList: handleOnSetMultiOptionsList,
        cachedDevices: cachedDevices.current,
        isSecurityEnforcementChanged,
        onSetSecurityEnforcementChanged: handleOnSetSecurityEnforcementChanged,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
}

export { ConfigurationProvider };
