import * as React from 'react';

const DeviceIntegrationModulePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "device-integration" */ './DeviceIntegrationModulePage'
    ),
);

export { DeviceIntegrationModulePage };
