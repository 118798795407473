import { useXmlResource, useAuth } from '@danfoss/etui-sm';
import { Accordion, AccordionSection } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import React, { useState } from 'react';
import { StyleProps, useTheme } from '@danfoss/etui-system';
import { useTranslation } from 'react-i18next';
import { fetchCopySettings } from '../../actions/copy-settings-actions';
import { CopyFlowTitle } from '../CopyContent';
import { CopyItemSettingsContentList } from '../CopyItemSettingsContentList';
import { useRefrigCopy } from '../../context';
import { accordionStyle } from './styles';

export type SourceSettingsFlowContentProps = {
  tableAddress: string;
};

export const SourceSettingsFlowContent = ({
  tableAddress,
}: SourceSettingsFlowContentProps) => {
  const { url } = useXmlResource();
  const { user } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation();
  const [sectionNames, setSectionNames] = useState<string[]>(null);
  const { selectedDeviceBpidx } = useRefrigCopy();

  React.useEffect(() => {
    (async () => {
      const res = await fetchCopySettings(
        url,
        user,
        tableAddress,
        selectedDeviceBpidx,
        '1',
      );
      if (res) {
        setSectionNames(res?.groupnames?.groupname);
      }
    })();
  }, []);

  const sections = sectionNames?.map((sectionName, index: number) => {
    const { root, sectionContent } = accordionStyle(theme);
    const rootInput: StyleProps = {
      ...root,
      position: 'relative',
      zIndex: sectionNames.length - index,
    };
    return (
      <AccordionSection
        name={sectionName}
        itemProps={{ id: index }}
        key={index}
        defaultOpen={index === 0}
        styles={{
          root: rootInput,
          sectionContent,
        }}
      >
        <CopyItemSettingsContentList
          tableAddress="20104"
          index="2"
          group={index.toString()}
        />
      </AccordionSection>
    );
  });

  return (
    <Div mt="10px" height="100%" testId="settings-page-div">
      <CopyFlowTitle title={t('t3454')} subTitle={t('t3455')} />
      <Div height="100%">
        {sectionNames ? (
          <Accordion enableSingleSectionOpen={true}>{sections}</Accordion>
        ) : null}
      </Div>
    </Div>
  );
};
