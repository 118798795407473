import React from 'react';
import {
  Badge,
  icons,
  SelectedValueType,
  Spinner,
  SpinnerSize,
  TreeItem,
  TreeTableItem,
  TreeViewTable,
  TreeViewTableColumn,
  useResponsive,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from 'styled-components';
import { useRefrigLayout } from '../../context';
import {
  assignCaseToSelectedSuction,
  checkIsValidOperation,
  filterOutOrphanedCaseIds,
  getNoOfCasesAssigned,
  getSectionsUpdatedForCase,
  getSelectedDeviceCasesIds,
  getSelectedDeviceLevel,
  isCaseMatchesWithSuction,
  isMultiCaseDevice,
  isSuctionDevice,
  isSuctionMatchesWithPack,
  removeCaseFromTree,
  unAssignDevice,
} from '../../actions';
import { DiscoveryTableData } from '../../types/DiscoveryPage.types';
import { AvailableCasesTableData } from '../../types/RefrigLayout.types';
import { getSuctionGroupDevices } from '../../utils';
import { ShowParentDeviceWithIcon } from './ShowParentDeviceWithIcon';

export const SuctionGroupCompressorTable = () => {
  const {
    discoveryTableData,
    setDiscoveryTableData,
    setCurrentSelectedCaseDeviceIds,
    setCompressorControlData,
    compressorControlData,
    setCurrentSectionsOpen,
    setSelectedCompressorTreeDeviceId,
    currentSelectedCase,
    availableCasesData,
    setAvailableCasesData,
    isCaseAssignOperation,
    setIsCaseAssignOperation,
    isCaseRemoveOperation,
    setIsCaseRemoveOperation,
    allowMultiCaseCreation,
  } = useRefrigLayout();
  const [compressorData, setCompressorData] = React.useState<TreeTableItem[]>(
    [],
  );
  const [currentSelectedDevice, setcurrentSelectedDevice] =
    React.useState<TreeItem>(null);
  const [openSections, setOpenSections] = React.useState([]);
  const theme = useTheme();
  const { screenIsAtMost } = useResponsive({
    sm: parseInt(theme.breakpoints[1], 10),
  });
  const isSmView = screenIsAtMost('sm', ['portrait', 'landscape']);
  const compressorTableColumns: TreeViewTableColumn[] = isSmView
    ? [
        {
          title: 'Name',
          width: 4,
          key: 'col1',
        },
        {
          title: 'Address',
          width: 2,
          key: 'col2',
        },
        {
          title: 'Model',
          width: 3,
          key: 'col3',
        },
        {
          title: 'Assigned',
          width: 2,
          key: 'col4',
        },
      ]
    : [
        {
          title: 'Name',
          width: 2,
          key: 'col1',
        },
        {
          title: 'Address',
          key: 'col2',
        },
        {
          title: 'Model',
          key: 'col3',
        },
        {
          title: 'Assigned',
          key: 'col4',
        },
      ];

  const getMultiCaseDevices = (
    currentCaseDevice: DiscoveryTableData,
    caseDevices: DiscoveryTableData[],
  ) => {
    const multiCaseItems: TreeTableItem[] = [];

    caseDevices.forEach(caseDevice => {
      if (isMultiCaseDevice(currentCaseDevice, caseDevice)) {
        caseDevice.assigned = true;
        multiCaseItems.push({
          id: caseDevice.deviceId,
          name: '',
          treeTableRows: [
            {
              col1: (
                <ShowParentDeviceWithIcon
                  deviceName={caseDevice.name}
                  deviceIcon={icons.CASE_EVAPORATOR}
                  styleProp={{ marginTop: '10px' }}
                />
              ),
              col2: caseDevice.address,
              col3: caseDevice.model,
              col4: '',
            },
          ],
          allowRowSelection: true,
          items: [],
        });
      }
    });

    return multiCaseItems;
  };

  const getPackMappedDevices = (
    packDevice: DiscoveryTableData,
    suctionDevices: DiscoveryTableData[],
    caseDevices: DiscoveryTableData[],
  ) => {
    const suctionItems: TreeTableItem[] = [];
    suctionDevices.forEach(suctionDevice => {
      if (isSuctionMatchesWithPack(suctionDevice, packDevice)) {
        suctionDevice.assigned = true;
        suctionItems.push({
          id: suctionDevice.deviceId,
          name: '',
          treeTableRows: [
            {
              col1: (
                <ShowParentDeviceWithIcon
                  deviceName={suctionDevice.name}
                  deviceIcon={icons.SUCTION_GROUP}
                  styleProp={{ marginTop: '10px' }}
                />
              ),
              col2: suctionDevice.address,
              col3: '',
              col4: '',
            },
          ],
          allowRowSelection: true,
          items: getCaseDevicesForPack(suctionDevice, caseDevices),
        });
      }
    });

    return suctionItems;
  };

  const getCaseDevicesForPack = (
    suctionDevice: DiscoveryTableData,
    caseDevices: DiscoveryTableData[],
  ) => {
    const caseItems: TreeTableItem[] = [];
    caseDevices.forEach(caseDevice => {
      if (isCaseMatchesWithSuction(caseDevice, suctionDevice)) {
        caseDevice.assigned = true;
        caseItems.push({
          id: caseDevice.deviceId,
          name: '',
          treeTableRows: [
            {
              col1: (
                <ShowParentDeviceWithIcon
                  deviceName={caseDevice.name}
                  deviceIcon={icons.CASE_EVAPORATOR}
                  styleProp={{ marginTop: '10px' }}
                />
              ),
              col2: caseDevice.address,
              col3: caseDevice.model,
              col4: '',
            },
          ],
          allowRowSelection: true,
          items: getMultiCaseDevices(caseDevice, caseDevices),
        });
      }
    });
    return caseItems;
  };

  const updateCompressorTreeAfterOperation = (
    updateCompressorTree: TreeTableItem[],
  ) => {
    const newTreeData: TreeTableItem[] = [];
    updateCompressorTree.forEach(packDevice => {
      newTreeData.push({
        id: packDevice.id,
        name: packDevice.name,
        treeTableRows: [
          {
            col1: packDevice?.treeTableRows[0]?.col1,
            col2: packDevice?.treeTableRows[0]?.col2,
            col3: packDevice?.treeTableRows[0]?.col3,
            col4: (
              <Badge
                iconOnly={true}
                isRound={true}
                text={getNoOfCasesAssigned(packDevice.items)}
                styles={{
                  root: {
                    bg: '#7C7C7C',
                  },
                }}
              />
            ),
          },
        ],
        allowRowSelection: true,
        items: packDevice.items,
      });
    });

    return newTreeData;
  };

  const getMappedDeviceTable = (discoveryTableData: DiscoveryTableData[]) => {
    const { packDevices, suctionDevices, caseDevices } =
      getSuctionGroupDevices(discoveryTableData);
    const treeViewData: TreeTableItem[] = [];
    packDevices.forEach(packDevice => {
      const childDevices = getPackMappedDevices(
        packDevice,
        suctionDevices,
        caseDevices,
      );
      treeViewData.push({
        id: packDevice.deviceId,
        name: '',
        treeTableRows: [
          {
            col1: (
              <ShowParentDeviceWithIcon
                deviceName={packDevice.name}
                deviceIcon={icons.PACK_RACK}
                styleProp={{ marginTop: '10px' }}
              />
            ),
            col2: packDevice.address,
            col3: packDevice.model,
            col4: (
              <Badge
                iconOnly={true}
                isRound={true}
                text={getNoOfCasesAssigned(childDevices)}
                styles={{
                  root: {
                    bg: '#7C7C7C',
                  },
                }}
              />
            ),
          },
        ],
        items: childDevices,
        allowRowSelection: true,
      });
    });
    setCompressorData(treeViewData);
    setCompressorControlData(treeViewData);
  };

  const handleOpenSectionChange = (
    sectionsOpenUpdated: SelectedValueType[],
  ) => {
    if (sectionsOpenUpdated.length === 1) {
      if (isSuctionDevice(sectionsOpenUpdated.at(0), discoveryTableData)) {
        sectionsOpenUpdated.length = 0;
        setOpenSections(sectionsOpenUpdated);
      }
    }
    if (sectionsOpenUpdated.length >= 1) {
      sectionsOpenUpdated = filterOutOrphanedCaseIds(
        sectionsOpenUpdated,
        compressorControlData,
      );
      if (
        !checkIsValidOperation(
          sectionsOpenUpdated,
          discoveryTableData,
          compressorControlData,
        )
      ) {
        sectionsOpenUpdated.splice(
          sectionsOpenUpdated.indexOf(sectionsOpenUpdated.at(-1)),
          1,
        );
      }
    }
    setOpenSections(sectionsOpenUpdated);
    setCurrentSectionsOpen(sectionsOpenUpdated);
  };

  const handleCurrentSelectedCase = (
    currentSelectedCase: AvailableCasesTableData,
  ) => {
    let openSectionsUpdated = [];
    if (!openSections.length && currentSelectedCase) {
      openSectionsUpdated = getSectionsUpdatedForCase(
        currentSelectedCase,
        compressorControlData,
      );
      handleOpenSectionChange(openSectionsUpdated);
    } else if (openSections?.length && currentSelectedCase) {
      // assign case from existing pack group to selected pack group
      // update rk, sg and dim values and place the case in the last of selected pack group
      // remove the case from existing pack group
      if (isCaseAssignOperation) {
        // remove case and update the dim values of discovered devices for the new postion
        const {
          caseRemovedCompressorTree,
          removedCaseTreeObj,
          dimValuesUpdatedDiscoveryTableData,
        } = removeCaseFromTree(
          compressorData,
          currentSelectedCase,
          discoveryTableData,
        );
        // add case to the selected suction and update the dim values of discovered devices
        if (!removedCaseTreeObj.treeTableRows.at(0).col1) {
          removedCaseTreeObj.treeTableRows.at(0).col1 = (
            <ShowParentDeviceWithIcon
              deviceName={removedCaseTreeObj.name}
              deviceIcon={icons.CASE_EVAPORATOR}
              styleProp={{ marginTop: '10px' }}
            />
          );
          removedCaseTreeObj.name = '';
        }
        const {
          caseAssignedCompressorTree,
          dimValueUpdatedDiscoveryTableData,
        } = assignCaseToSelectedSuction(
          caseRemovedCompressorTree,
          removedCaseTreeObj,
          currentSelectedDevice.id,
          dimValuesUpdatedDiscoveryTableData,
        );
        const badgeUpdatedTreeData = updateCompressorTreeAfterOperation(
          caseAssignedCompressorTree,
        );
        const selectedCase = availableCasesData.find(
          availableCase =>
            availableCase.deviceId === currentSelectedCase.deviceId,
        );
        selectedCase.assigned = true;
        setCompressorData(badgeUpdatedTreeData);
        setCompressorControlData(badgeUpdatedTreeData);
        setAvailableCasesData(availableCasesData);
        setDiscoveryTableData(dimValueUpdatedDiscoveryTableData);
        setIsCaseAssignOperation(false);
        handleOpenSectionChange(openSections);
      }
      if (isCaseRemoveOperation) {
        const deviceToUnAssign = availableCasesData?.find(
          cases => cases?.deviceId === currentSelectedCase.deviceId,
        );
        // remove case from tree and update the dim values of discovered devices for the new postion
        const {
          caseRemovedCompressorTree,
          dimValuesUpdatedDiscoveryTableData,
        } = removeCaseFromTree(
          compressorData,
          deviceToUnAssign,
          discoveryTableData,
        );
        // unassign the removed case and update in available cases and discovered devices
        const updatedDiscoveryTableData = unAssignDevice(
          dimValuesUpdatedDiscoveryTableData,
          currentSelectedCase.deviceId,
        );
        deviceToUnAssign.assigned = false;
        const badgeUpdatedTreeData = updateCompressorTreeAfterOperation(
          caseRemovedCompressorTree,
        );
        setCompressorData(badgeUpdatedTreeData);
        setCompressorControlData(badgeUpdatedTreeData);
        setAvailableCasesData(availableCasesData);
        setDiscoveryTableData(updatedDiscoveryTableData);
        setIsCaseRemoveOperation(false);
        handleOpenSectionChange(openSections);
      }
      handleCurrentSelectedDevice(currentSelectedDevice);
      setSelectedCompressorTreeDeviceId(currentSelectedDevice.id);
    }
  };

  const handleCurrentSelectedDevice = (selectedItem: TreeItem) => {
    let selectedCaseDeviceIds: SelectedValueType[] = [];
    if (selectedItem) {
      const currentSelectedDeviceId = selectedItem.id;
      const deviceLevel = getSelectedDeviceLevel(
        currentSelectedDeviceId,
        compressorControlData,
      );
      selectedCaseDeviceIds = getSelectedDeviceCasesIds(
        deviceLevel,
        compressorControlData,
        currentSelectedDeviceId,
      );
      setCurrentSelectedCaseDeviceIds(selectedCaseDeviceIds);
      setSelectedCompressorTreeDeviceId(currentSelectedDeviceId);
    }
  };

  React.useEffect(() => {
    if (discoveryTableData?.length) {
      discoveryTableData.forEach(device => {
        device.assigned = false;
      });
      getMappedDeviceTable(discoveryTableData);
    }
  }, []);

  React.useEffect(() => {
    handleCurrentSelectedCase(currentSelectedCase);
  }, [currentSelectedCase]);

  React.useEffect(() => {
    handleCurrentSelectedDevice(currentSelectedDevice);
  }, [allowMultiCaseCreation]);

  return (
    <>
      {compressorData?.length ? (
        <Div
          testId="compressor-control-table-content-div"
          style={{ width: '500px', minWidth: 'auto' }}
          height="100%"
        >
          <TreeViewTable
            styles={{
              root: { height: '450px', overflow: 'auto' },
            }}
            columns={compressorTableColumns}
            data={compressorData}
            onRowSelect={(selectedItem: TreeItem) => {
              handleCurrentSelectedDevice(selectedItem);
              setcurrentSelectedDevice(selectedItem);
            }}
            onOpenSectionChange={handleOpenSectionChange}
            openSections={openSections}
          />
        </Div>
      ) : (
        <Div
          testId="circuit-flow-table-spinner-div"
          style={{ marginTop: '200px' }}
        >
          <Spinner size={SpinnerSize.small} />
        </Div>
      )}
    </>
  );
};
