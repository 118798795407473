import '@danfoss/etui-sm-xml';
import { getIsSuctionDevice } from './get-is-suction-device.js';
import './get-is-no-compressor-device.js';
import './get-is-pack-device.js';
import './get-is-rack-device.js';
import { getTopLevelDevice, isSuctionHasChildSuction } from './get-is-top-level-device.js';

/**
 * Utility to validate if device is a root refrigeration device
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsRootRefrigerationDevice(device) {
  if (getTopLevelDevice(device)) {
    return true;
  }
  return false;
}
function getIsRootSuctionDevice(device) {
  if (getIsSuctionDevice(device) && isSuctionHasChildSuction(device)) {
    return true;
  }
  return false;
}

export { getIsRootRefrigerationDevice, getIsRootSuctionDevice };
