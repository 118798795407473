import {
  RefrigerationSchedules,
  ScheduleItem,
} from 'pages/SchedulesPage/SchedulesPage.types';

type RefrigerationScheduleItem = ScheduleItem & {
  id: string;
  type: string;
  name: string;
  usage: string;
  weekdays: string;
  on_time: string;
  off_time: string;
  holidays: string;
};

const refrigerationSchema = t => {
  return [
    {
      title: t('t3230'),
      key: 'id',
      dataIndex: 'id',
      width: '8%',
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.id.localeCompare(b.id);
      },
    },
    {
      title: t('t76'),
      key: 'name',
      dataIndex: 'name',
      width: '8%',
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: t('t956'),
      key: 'cases',
      dataIndex: 'cases',
      width: '8%',
      render: (_, { cases }: RefrigerationSchedules) =>
        cases.map(caseItem => caseItem.name).join('\n'),
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: t('t3225'),
      key: 'type',
      dataIndex: 'type',
      width: '8%',
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.type.localeCompare(b.type);
      },
    },
    {
      title: t('t3327'),
      key: 'usage',
      dataIndex: 'usage',
      width: '8%',
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.usage.localeCompare(b.usage);
      },
    },
    {
      title: t('t3396'),
      key: 'on_time',
      dataIndex: 'on_time',
      width: '8%',
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.on_time.localeCompare(b.on_time);
      },
    },
    {
      title: t('t3329'),
      key: 'off_time',
      dataIndex: 'off_time',
      width: '8%',
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.off_time.localeCompare(b.off_time);
      },
    },
    {
      title: t('t41'),
      key: 'holidays',
      dataIndex: 'holidays',
      width: '8%',
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.holidays.localeCompare(b.holidays);
      },
    },
    {
      title: t('t3330'),
      key: 'weekdays',
      dataIndex: 'weekdays',
      width: '8%',
      sorter: (a: RefrigerationScheduleItem, b: RefrigerationScheduleItem) => {
        return a.weekdays.localeCompare(b.weekdays);
      },
    },
  ];
};

export { refrigerationSchema };
