import * as React from 'react';

const SoftwareRollbackPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "softwarerollback-page" */ './SoftwareRollbackPage'
    ),
);

export { SoftwareRollbackPage };
