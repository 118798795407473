import { XML_ACTION, fetchXmlCsvFileData } from '@danfoss/etui-sm-xml';

async function fetchEdfFiles(url, user, newEdfFiles) {
  const action = XML_ACTION.EXPORT_EDF_VERSIONS;
  return fetchXmlCsvFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action,
    attributes: {
      installed: newEdfFiles ? '0' : '1'
    },
    offset: 0
  });
}

export { fetchEdfFiles };
