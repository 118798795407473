import '@danfoss/etui-sm-xml';
import '../../_rollupPluginBabelHelpers-e1ae8e90.js';
import '../../constants/session-storage-constants.js';
import '../../constants/software-versions.js';
import 'react';
import 'use-deep-compare-effect';
import './utils/get-xml-backend-ip.js';
import './utils/get-xml-backend-protocol.js';
import './utils/get-xml-proxy-config.js';
import './utils/set-xml-proxy-config.js';
export { AppContext, AppStateProvider, Protocol } from './app-context.js';
export { useApp } from './hooks/use-app.js';
export { useXmlResource } from './hooks/use-xml-resource.js';
