export function getLastMatchedValueFromRegex(
  regex: RegExp,
  inputToMatch: string,
): [string, number] {
  let lastOccurrenceIndex: number = 0;
  let matchedString: string = '';
  let match;
  for (
    match = regex.exec(inputToMatch);
    match !== null;
    match = regex.exec(inputToMatch)
  ) {
    [matchedString] = match;
    lastOccurrenceIndex = match.index;
  }
  return [matchedString, lastOccurrenceIndex];
}
