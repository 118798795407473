import { addSessionTokenByUrl } from '@danfoss/etui-sm-xml';
import { STORAGE_USER_AUTHLEVEL_KEY, STORAGE_USER_USERNAME_KEY, STORAGE_USER_PASSWORD_KEY, STORAGE_USER_LANGUAGE_KEY } from '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';
import { getXmlBackendIp } from '../../app/utils/get-xml-backend-ip.js';
import { getXmlBackendProtocol } from '../../app/utils/get-xml-backend-protocol.js';

function setUserToStorage(payload) {
  const url = `${getXmlBackendProtocol()}//${getXmlBackendIp()}`;
  sessionStorage.setItem(STORAGE_USER_AUTHLEVEL_KEY, payload.authtype);
  sessionStorage.setItem(STORAGE_USER_USERNAME_KEY, payload.user || '');
  sessionStorage.setItem(STORAGE_USER_PASSWORD_KEY, payload.password || '');
  sessionStorage.setItem(STORAGE_USER_LANGUAGE_KEY, payload.language);
  !payload.skipSessionTokenAddition && addSessionTokenByUrl(url, payload.sessionToken);
}

export { setUserToStorage };
