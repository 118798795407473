import { User, XML_ACTION, fetchXMLData } from '@danfoss/etui-sm-xml';

async function sendBdCommands(url: string, user: User) {
  try {
    const data = await fetchXMLData({
      url,
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        index: '200',
        arg1: '0',
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.BD_COMMANDS,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
}

export { sendBdCommands };
