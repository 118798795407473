import React from 'react';
import { ConfigurationTabByUnitResponse } from 'pages/ConfigurationPage';
import { PollingStatusResponse } from '../components/ConfigurationItemContentList/types';

export interface ConfigurationCustomActionContextProps {
  isDownloadPage: boolean;
  menuId: string;
  isDownloadEnabledInNetworks: boolean;
  data: ConfigurationTabByUnitResponse;
  isDownloadInprogressInNetworks: boolean;
  isDownloadComplete: boolean;
  pollingData: PollingStatusResponse;
  progressText: (
    isDownloadInprogressInNetworks: boolean,
    data: ConfigurationTabByUnitResponse,
  ) => JSX.Element;
  setMenuId: (value: string) => void;
  setIsDownloadEnabledInNetworks: (value: boolean) => void;
  setData: (data: ConfigurationTabByUnitResponse) => void;
  setDownloadComplete: () => void;
  setPollingData: (data: PollingStatusResponse) => void;
}

export const ConfigurationCustomActionsContext = React.createContext(
  null as ConfigurationCustomActionContextProps | null,
);
