import * as React from 'react';

export interface ConfigurationModalContextProps {
  openModals: string[];
  toggleModals: (modalName: string) => void;
  closeAllModals: () => void;
}

const ConfigurationModalContext = React.createContext(
  null as ConfigurationModalContextProps | null,
);

export { ConfigurationModalContext };
