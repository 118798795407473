import {
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../../../RefrigLayoutModal/types';

export const getGenericCount = (devices: DiscoveryTableData[]) => {
  let cnt: number = 0;
  for (const device of devices) {
    if (!device?.removed && device.deviceType === DEVICE_TYPE.GEN_HVAC) {
      cnt++;
    }
  }
  return cnt;
};
