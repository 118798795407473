import {
  ConfigurationListDataArray,
  ConfigurationListDataObject,
  ConfigurationListItem,
} from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import {
  SourceAlarmTableContent,
  SourceAlarmTableData,
  SourceAlarmTablePageContent,
} from '../../../types/RefrigCopy.types';

export const processSourceAlarmTableData = (
  tableContentData: ConfigurationListItem[],
  levelList: ConfigurationListDataArray | ConfigurationListDataObject,
  page: string,
  t: TFunction,
) => {
  const sourceAlarmsTableContentList: SourceAlarmTableContent[] = [];
  tableContentData?.forEach((data, index) => {
    if (!data?.token.includes(t('t599'))) {
      const nextTableContentData = tableContentData[index + 1];
      const sourceAlarmsTableContent: SourceAlarmTableContent = {
        name: data?.name,
        level: data?.value,
        action:
          nextTableContentData?.name?.trim() === t('t599')
            ? tableContentData[index + 1]?.value
            : '',
        levelList: getLevelList(levelList as ConfigurationListDataArray),
        page,
        li: data?.li,
      };
      sourceAlarmsTableContentList.push(sourceAlarmsTableContent);
    }
  });
  return sourceAlarmsTableContentList;
};

export const checkIfPageContentNotAvailable = (
  sourceAlarmTablePageContent: SourceAlarmTablePageContent[],
  page: string,
) => {
  const filteredLength: number = sourceAlarmTablePageContent?.filter(
    pageContent => pageContent.page === page,
  )?.length;
  return filteredLength === 0;
};

export const findCurrentPageContent = (
  sourceAlarmTablePageContent: SourceAlarmTablePageContent[],
  page: string,
) => {
  return sourceAlarmTablePageContent?.find(pageContent => {
    return pageContent.page === page;
  });
};

const getLevelList = (levelListData: ConfigurationListDataArray) => {
  return levelListData?.map(value => ({
    value: value.datavalue,
    label: value._,
  }));
};

export const filterSourceAlarmTableContentList = (
  allPageData: SourceAlarmTableContent[],
  searchValue: string,
) => {
  const filteredSourceAlarmTableContentList = allPageData?.filter(content => {
    const name = content.name.toLowerCase();
    const value = searchValue?.toLowerCase();
    return name.includes(value);
  });
  return filteredSourceAlarmTableContentList;
};

export const findEditedListItem = (
  content: SourceAlarmTableContent,
  sourceAlarmsListItems: ConfigurationListItem[],
) => {
  return sourceAlarmsListItems?.find(item => {
    return (
      item.name.includes(content.name) &&
      item.page === content.page &&
      item.li === content.li
    );
  });
};

export const findEditedActionListItem = (
  content: SourceAlarmTableContent,
  sourceGraphListItems: ConfigurationListItem[],
) => {
  const index: number = sourceGraphListItems?.findIndex(item => {
    return (
      item.name === content.name &&
      item.page === content.page &&
      item.li === content.li
    );
  });
  return sourceGraphListItems[index + 1];
};

export const addPageToListItems = (
  listItems: ConfigurationListItem[],
  page: string,
) => {
  listItems?.forEach((_, index) => {
    listItems[index].page = page;
  });
  return listItems;
};

export const setLevelStatus = (
  sourceAlarmTableData: SourceAlarmTableData,
  selectedRowIndex: number,
  submitting: boolean,
  succeeded: boolean,
  failed: boolean,
  value?: string,
) => {
  if (value) {
    sourceAlarmTableData.sourceAlarmTableContent[selectedRowIndex].level =
      value;
  }
  sourceAlarmTableData.sourceAlarmTableContent[
    selectedRowIndex
  ].isLevelSubmitting = submitting;
  sourceAlarmTableData.sourceAlarmTableContent[
    selectedRowIndex
  ].isLevelSucceeded = succeeded;
  sourceAlarmTableData.sourceAlarmTableContent[selectedRowIndex].isLevelFailed =
    failed;
};

export const setActionStatus = (
  sourceAlarmTableData: SourceAlarmTableData,
  selectedRowIndex: number,
  submitting: boolean,
  succeeded: boolean,
  failed: boolean,
  value?: string,
) => {
  if (value) {
    sourceAlarmTableData.sourceAlarmTableContent[selectedRowIndex].action =
      value;
  }
  sourceAlarmTableData.sourceAlarmTableContent[
    selectedRowIndex
  ].isActionSubmitting = submitting;
  sourceAlarmTableData.sourceAlarmTableContent[
    selectedRowIndex
  ].isActionSucceeded = succeeded;
  sourceAlarmTableData.sourceAlarmTableContent[
    selectedRowIndex
  ].isActionFailed = failed;
};

/* export const findSelectedAlarmTableContent = (
  sourceAlarmTableContent: SourceAlarmTableContent[],
  listItem: ConfigurationListItem,
) => {
  return sourceAlarmTableContent?.find(responseContent => {
    return (
      listItem.li === responseContent?.li &&
      listItem.page === responseContent?.page &&
      listItem.name === responseContent?.name
    );
  });
};

export const findSelectedAlarmTableContentIndex = (
  sourceAlarmTableContent: SourceAlarmTableContent[],
  listItem: ConfigurationListItem,
) => {
  return sourceAlarmTableContent?.findIndex(responseContent => {
    return (
      listItem.li === responseContent?.li &&
      listItem.page === responseContent?.page &&
      listItem.name === responseContent?.name
    );
  });
}; */
