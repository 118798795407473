import * as React from 'react';
import { ConfigurationMenuContext } from './ConfigurationMenuContext';

function useConfigurationMenu() {
  const context = React.useContext(ConfigurationMenuContext);
  if (!context) {
    throw new Error(
      `useConfigurationMenu must be used within a ConfigurationMenuProvider`,
    );
  }
  return context;
}

export { useConfigurationMenu };
