const getQueryEquipmentIdByParamsEquipmentId = (match: {
  params: { equipmentId: string; tabview: string };
}) => {
  if (match && match.params && match.params.equipmentId) {
    const [, ...rest] = match.params.equipmentId.split('_');
    return rest.join('_');
  }
  return null;
};

export { getQueryEquipmentIdByParamsEquipmentId };
