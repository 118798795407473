import * as React from 'react';

type UseConfigItemEditReturnValue = {
  editMode: boolean;
  enableEditMode: () => void;
  disableEditMode: () => void;
};

const useConfigItemEdit = (): UseConfigItemEditReturnValue => {
  const [editMode, setEditMode] = React.useState(false);

  const enableEditMode = () => {
    setEditMode(true);
  };

  const disableEditMode = () => {
    setEditMode(false);
  };

  return { editMode, enableEditMode, disableEditMode };
};

export { useConfigItemEdit };
