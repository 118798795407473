import {
  User,
  Alarm,
  Unit,
  isCurrentlySelectedUnit,
} from '@danfoss/etui-sm-xml';
import { AlarmType } from '../constants';
import { getAlarmRefsPaginatedGroupedByUnit } from '../utils';
import { fetchAlarmsPaginatedByUnit } from './fetch-alarms-paginated-by-unit';

export type AlarmPaginated = Alarm & { pageIndex: number };

export async function fetchAlarmsPaginatedByUnits([
  url,
  alarmType,
  alarmRefs,
  pageIndex,
  pageSize,
  user,
  units,
  skipSessionUpdate = false,
]: [
  string,
  AlarmType,
  string[],
  number,
  number,
  User,
  Unit[],
  boolean?,
]): Promise<AlarmPaginated[]> {
  const alarmRefsGroupedByUnit = getAlarmRefsPaginatedGroupedByUnit(
    alarmRefs,
    pageIndex,
    pageSize,
  );

  const alarmRefsGroupedByUnitAddresses = Object.keys(alarmRefsGroupedByUnit);

  try {
    const alarms = await Promise.all(
      alarmRefsGroupedByUnitAddresses.map(unitAddress => {
        const unit = units.find(u => u.unit_addr === unitAddress);
        return fetchAlarmsPaginatedByUnit(
          url,
          alarmType,
          pageIndex,
          pageSize,
          alarmRefsGroupedByUnit,
          user,
          unit,
          skipSessionUpdate && isCurrentlySelectedUnit(unit),
        );
      }),
    );

    return alarms.flat().map(alarm => ({ ...alarm, pageIndex }));
  } catch (error) {
    throw new Error();
  }
}
