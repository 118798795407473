import { styled } from '@danfoss/etui-system';
import '@danfoss/etui-system-elements';
import '@danfoss/etui-core/utilities';
import { Page } from './Page.js';

const FullPage = styled(Page)`
  align-items: stretch;
  background-color: ${_ref => {
  let {
    theme,
    noBackground
  } = _ref;
  return noBackground ? 'transparent' : theme.palette.common.white;
}};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding-top: 0px;
`;

export { FullPage };
