import React from 'react';
import {
  ConfigurationListItem,
  XML_REFRIG_COPY_TAB_INDEX,
  fetchLayoutListByUnit,
} from '@danfoss/etui-sm-xml';
import useSWR from 'swr';
import { useAuth, useUnit, useXmlResource } from '@danfoss/etui-sm/';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Notification } from '@danfoss/etui-core';
import {
  AlarmsTableContent,
  AlarmsTableData,
} from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { writeConfigurationListItem } from '../../../../../Configuration';
import { REFRIG_SUCCESS_RESPONSE } from '../../../RefrigLayoutModal/types';
import { AlarmsFlowPage } from './AlarmsFlowPage';
import {
  filterAlarmsTableContentList,
  processAlarmsTableData,
} from './actions';

interface RefrigCopyAlarmsFlowContentProps {
  tableAddress: string;
}

export const AlarmsFlowContent = ({
  tableAddress,
}: RefrigCopyAlarmsFlowContentProps) => {
  const { url, ip } = useXmlResource();
  const { user } = useAuth();
  const { units, getFirstValidUrl } = useUnit();
  const filteredUnit = units.filter(unit =>
    getFirstValidUrl(unit).includes(ip),
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const unit = filteredUnit[0];

  const {
    page,
    setMultipage,
    alarmsTableData,
    setAlarmsTableData,
    searchMode,
    isAllPageDataFetched,
    searchValue,
    allPageData,
    pageValues,
    setIsAllPageDataFetched,
    setAllPageData,
    setIsPageDataFetched,
  } = useRefrigCopy();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const networkTableData = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.ALARMS,
          user,
          page,
        ]);
        if (networkTableData?.items?.l?.length) {
          let alarmsTableContentList: AlarmsTableContent[] = [];
          alarmsTableContentList = processAlarmsTableData(
            networkTableData as any,
          );
          setMultipage(networkTableData?.multipage);
          setAlarmsTableData({ alarmTableContent: alarmsTableContentList });
          setIsPageDataFetched(true);
        }
      } catch (error) {
        Notification.error({
          message: t('t17'),
          description: t('t3403'),
          duration: 3,
          theme,
        });
      }
    };
    if (!alarmsTableData?.alarmTableContent?.length) {
      setIsPageDataFetched(false);
      fetch();
    } else {
      fetch();
    }
  }, [page]);

  const handleOnSelect = async (listItem: ConfigurationListItem) => {
    const response = await withErrorHandled(
      t,
      theme,
      writeConfigurationListItem,
    )(
      url,
      user,
      null,
      `${tableAddress}-${XML_REFRIG_COPY_TAB_INDEX.ALARMS}`,
      null,
      '0',
      '0',
      listItem,
      listItem.fVal,
      listItem.iVal,
      '',
    );
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      revalidate();
    }
  };

  const revalidate = async () => {
    if (searchValue) {
      const result = await fetchAllPages(pages);
      let allContentList: AlarmsTableContent[] = [];
      allContentList = processAlarmsTableData(result);
      const filterAlarmTableContentList = filterAlarmsTableContentList(
        allContentList,
        searchValue,
      ) as AlarmsTableContent[];
      const filteredAlarmTableData: AlarmsTableData = {
        alarmTableContent: filterAlarmTableContentList,
      };
      setIsAllPageDataFetched(true);
      setAllPageData(allContentList);
      setAlarmsTableData(filteredAlarmTableData);
    } else {
      const tableList = await fetchLayoutListByUnit([
        url,
        unit,
        tableAddress,
        XML_REFRIG_COPY_TAB_INDEX.ALARMS,
        user,
        page.toString(),
      ]);
      if (tableList?.items?.l) {
        const alarmTableContentList: AlarmsTableContent[] =
          processAlarmsTableData(tableList as any);
        const updatedAlarmTableData: AlarmsTableData = {
          alarmTableContent: alarmTableContentList,
        };
        setAlarmsTableData(updatedAlarmTableData);
      }
    }
  };

  const pages = searchMode ? pageValues : [];

  const fetchAllPages = async (pages: number[]) => {
    const response = [];
    await pages.reduce(async (promise, page) => {
      await promise;
      page -= 1;
      const data = await fetchLayoutListByUnit([
        url,
        unit,
        tableAddress,
        XML_REFRIG_COPY_TAB_INDEX.ALARMS,
        user,
        page.toString(),
      ]);
      response.push(data);
    }, Promise.resolve());
    const allPageResponse = response.filter(data => data !== undefined).flat();
    return allPageResponse;
  };

  const { data, isLoading: isAllPagesDataLoading } = useSWR(
    pages,
    pages?.length && !allPageData?.length ? fetchAllPages : null,
  );
  if (data?.length && !allPageData?.length && !isAllPagesDataLoading) {
    let allContentList: AlarmsTableContent[] = [];
    allContentList = processAlarmsTableData(data);
    const filterAlarmTableContentList = filterAlarmsTableContentList(
      allContentList,
      searchValue,
    ) as AlarmsTableContent[];
    const filteredAlarmTableData: AlarmsTableData = {
      alarmTableContent: filterAlarmTableContentList,
    };
    setIsAllPageDataFetched(true);
    setAllPageData(allContentList);
    setAlarmsTableData(filteredAlarmTableData);
  }
  React.useEffect(() => {
    if (searchMode && isAllPageDataFetched) {
      const filterAlarmTableContentList = filterAlarmsTableContentList(
        allPageData,
        searchValue,
      ) as AlarmsTableContent[];
      const filteredAlarmsTableData: AlarmsTableData = {
        alarmTableContent: filterAlarmTableContentList,
      };
      setAlarmsTableData(filteredAlarmsTableData);
    }
  }, [searchValue]);

  return <AlarmsFlowPage handleOnSelect={handleOnSelect} />;
};
