import { Notification } from '@danfoss/etui-core';
import { isXmlError, XML_ERROR_CODE } from '@danfoss/etui-sm-xml';

export function withErrorHandled(t, theme, fn) {
  let res;
  function getTCode(c) {
    return c.toString().padStart(5, 't200');
  }
  function translator(code: XML_ERROR_CODE) {
    const tCode = getTCode(code);
    const translated = t(tCode);
    return translated === tCode ? '' : translated;
  }

  return async function wrapped(...args) {
    try {
      res = await fn(...args);
    } catch (err) {
      if (isXmlError(err)) {
        Notification.error({
          message: t('t85'),
          description:
            +err?.code === 0 && err?.message
              ? err?.message
              : err?.displayError(translator),
          duration: 5,
          theme,
        });
      }

      throw err; // propagate error further
    }

    // eslint-disable-next-line consistent-return
    return res;
  };
}
