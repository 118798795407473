import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-e1ae8e90.js';
import { omit } from '@danfoss/etui-core';

function getNormalizedSuction(suction) {
  const dirtyCompressorParameters = (suction === null || suction === void 0 ? void 0 : suction.compressors) || null;

  // On SM800A there is not devicedetails, but on SM800 there can be devicedetails.
  if (dirtyCompressorParameters && !suction.devicedetails) {
    const compressorParameters = omit(dirtyCompressorParameters, ['comp', 'total']);
    return _objectSpread2(_objectSpread2({}, suction), {}, {
      parameters: Object.keys(compressorParameters).map(parameter => ({
        tag: parameter,
        value: compressorParameters[parameter]
      }))
    });
  }
  return suction;
}

export { getNormalizedSuction };
