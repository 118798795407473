import { DiscoveryTableData } from '../types/DiscoveryPage.types';

export const getRackCount = (devices: DiscoveryTableData[]) => {
  let cnt: number = 0;
  let rk: number = 0;
  for (const device of devices) {
    if (device?.rk > rk && device?.rk < 255) {
      rk = device?.rk;
      cnt++;
    }
  }
  return cnt;
};
