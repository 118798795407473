import { fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function fetchUpdateSoftwareInstall(url, user, updateCommand) {
  try {
    // This will always throw error.
    // Due to that the system manager will restart before returning the response.
    const response = await fetchXMLData({
      url,
      attributes: {
        action: XML_ACTION.UPDATE_SW,
        user: user.user,
        password: user.password,
        sw_upgrade_cmd: updateCommand
      }
    });
    if (response.return_code !== '0') {
      throw new Error();
    }
    return response;
  } catch (error) {
    return null;
  }
}

export { fetchUpdateSoftwareInstall };
