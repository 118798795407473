import { DEVICE_LIST_TYPE } from '../types/DiscoveryPage.types';

export const getTypeBasedOnDeviceType = (deviceType: number): number => {
  switch (deviceType) {
    case 0:
    // eslint-disable-next-line default-case-last, no-fallthrough
    default:
      return null;
    case 1:
      return DEVICE_LIST_TYPE.EVAP;
    case 2:
      return DEVICE_LIST_TYPE.RACK;
    case 3:
      return DEVICE_LIST_TYPE.HVAC;
  }
};
