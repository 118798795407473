import * as React from 'react';
import { Modal, Button } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
// import { useSWRConfig } from 'swr';
import { useConfigurationModals } from '../../context';
import {
  AddressForm,
  AddressFormGeneric,
} from './ConfigurationListItemEditAddressForms';
import {
  editedItemByAddress,
  getIoTypesByUnit,
  getIsGeneric,
  getModValue,
  hasChanged,
  NodeTypes,
} from './utils/address-utils';
import { ConfigurationListItemEditProps } from './ConfigurationListItemEdit';

export function ConfigurationListItemEditAddress({
  item,
  addresses,
  unit,
  onSave,
  resetConfigSwrCache,
}: Pick<
  ConfigurationListItemEditProps,
  'item' | 'addresses' | 'unit' | 'onSave' | 'resetConfigSwrCache'
>) {
  const { t } = useTranslation();
  // const { mutate } = useSWRConfig();
  const theme = useTheme();
  const { openModals, toggleModals } = useConfigurationModals();
  const itemId = item.name + item.li;
  const isOpen = openModals.includes(itemId);
  const [isLoading, setisLoading] = React.useState(false);

  const isGeneric = getIsGeneric(addresses[0]?.pnum);
  const [editedItem, setEditedItem] = React.useState(
    editedItemByAddress(addresses, item),
  );
  const ioTypes = React.useMemo(
    () => getIoTypesByUnit(unit).map(io => ({ ...io, label: t(io.label) })),
    [unit],
  );

  const handleOpen = () => {
    setEditedItem(editedItemByAddress(addresses, item));
    toggleModals(itemId);
  };

  const onClose = () => {
    toggleModals(itemId);
  };

  const handleSave = React.useCallback(
    async ({ name, type, io, calc, node, mod, point, value }) => {
      setisLoading(true);
      const promises = [];
      if (editedItem.value !== name.trim()) {
        promises.push(onSave(editedItem, { ival: name.trim() }));
      }
      const { addressLine, optionsLine } = editedItem;
      const isUsualAddr = !addressLine.s_a || addressLine.s_a === '0';
      const isCalc = io?.value === NodeTypes.NODE_TYPE_CALC;
      const addressToChange = isUsualAddr
        ? {
            iotype: io.value,
            node: isCalc ? '0' : node,
            mod: getModValue(mod, io.value, point),
            point: isCalc ? calc.value : point,
          }
        : {
            iotype: NodeTypes.NODE_TYPE_NA,
            node: value,
            mod: '0',
            point: '0',
          };
      if (hasChanged(addressLine, addressToChange)) {
        promises.push(
          onSave({ ...addressLine, ...addressToChange }, { ival: '0' }),
        );
      }
      if (!isGeneric && optionsLine.iVal !== type.value) {
        promises.push(onSave(optionsLine, { ival: type.value }));
      }

      try {
        await Promise.all(promises);
        resetConfigSwrCache();
        onClose();
      } catch (e) {
        console.log(`ERROR: ${e}`);
      } finally {
        setisLoading(false);
      }
    },
    [editedItem, isGeneric],
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        styles={{ contentContainer: { p: 0 } }}
        header={<Modal.Header title={t('t163')} description={t('t864')} />}
      >
        <Div pt={20}>
          {isGeneric ? (
            <AddressFormGeneric
              isLoading={isLoading}
              item={editedItem}
              onCancel={onClose}
              onSave={handleSave}
            />
          ) : (
            <AddressForm
              isLoading={isLoading}
              item={editedItem}
              ioTypes={ioTypes}
              onCancel={onClose}
              onSave={handleSave}
            />
          )}
        </Div>
      </Modal>
      <Button
        p={`0 ${theme.spacing.md}px`}
        display="block"
        height="35px"
        onClick={handleOpen}
        variant="secondary"
      >
        {item.value}
      </Button>
    </>
  );
}
