/**
 * Get unit url by unitNetwork
 *
 * @param {UnitNetwork} unitNetwork
 * @returns
 */
function getUnitUrlByUnitNetwork(unitNetwork, protocol) {
  const ip = (unitNetwork === null || unitNetwork === void 0 ? void 0 : unitNetwork.ip) !== '0' ? unitNetwork.ip : (unitNetwork === null || unitNetwork === void 0 ? void 0 : unitNetwork.ip1) !== '0' ? unitNetwork.ip1 : (unitNetwork === null || unitNetwork === void 0 ? void 0 : unitNetwork.ip2) !== '0' ? unitNetwork.ip2 : unitNetwork.ip;
  return `${protocol}//${ip}:${unitNetwork.port}/xml.cgi`;
}

export { getUnitUrlByUnitNetwork };
