import * as React from 'react';
import { Steps } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import {
  XML_REFRIG_COPY_TAB_INDEX,
  XML_TABLE_NAMES,
} from '@danfoss/etui-sm-xml';
import { Div } from '@danfoss/etui-system-elements';
import { ContentSpinner } from '@danfoss/etui-sm';
import { useRefrigCopyContent } from '../../../../hooks';
import { getRefrigCopySteps } from '../../../../utils';
import { useRefrigCopy } from '../../context';
import { SourceControllerFlowContent } from '../SourceControllerFlowContent';
import { SourceSettingsFlowContent } from '../SourceSettingsFlowContent';
import { SourceAlarmFlowContent } from '../SourceAlarmFlowContent';
import { SourceGraphFlowContent } from '../SourceGraphFlowContent';
import { FilesFlowContent } from '../FilesFlowContent';
import { ParametersFlowContent } from '../ParametersFlowContent';
import { AlarmsFlowContent } from '../AlarmsFlowContent';
import { GraphFlowContent } from '../GraphFlowContent';
import { DevicesFlowContent } from '../DevicesFlowContent';
import { CopyFlowContent } from '../CopyFlowContent';
import { RefrigCopySteps } from '../../types/RefrigCopy.types';

interface RefrigCopyContentProps {
  onClose?: () => void;
}

export function RefrigCopyContent({ onClose }: RefrigCopyContentProps) {
  const { step, activeStepNumber, setRefrigSteps, refrigSteps } =
    useRefrigCopy();

  const { tableContentData, stepContents, networkTableAddress, isValidating } =
    useRefrigCopyContent(
      activeStepNumber.toString(),
      XML_TABLE_NAMES.TABLE_REFRIG_COPY,
    );

  const { t } = useTranslation();

  const steps: RefrigCopySteps[] = getRefrigCopySteps(stepContents, t);

  React.useEffect(() => {
    setRefrigSteps(steps);
  }, [steps?.length]);

  return (
    <>
      {isValidating ? (
        <Div>
          <ContentSpinner size={1} transparent={true} />
        </Div>
      ) : (
        <>
          {refrigSteps && (
            <Steps activeStep={activeStepNumber}>
              {refrigSteps?.map(s => (
                <Steps.Step
                  key={s?.stepNumber}
                  {...s}
                  isCompleted={s?.stepNumber < activeStepNumber}
                />
              ))}
            </Steps>
          )}
          {tableContentData &&
          step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_CONTROLLER ? (
            <SourceControllerFlowContent tableAddress={networkTableAddress} />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_SETTINGS ? (
            <SourceSettingsFlowContent tableAddress={networkTableAddress} />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS ? (
            <SourceAlarmFlowContent tableAddress={networkTableAddress} />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.SOURCE_GRAPH ? (
            <SourceGraphFlowContent tableAddress={networkTableAddress} />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.FILES ? (
            <FilesFlowContent
              onExit={onClose}
              tableAddress={networkTableAddress}
            />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.PARAMETERS ? (
            <ParametersFlowContent tableAddress={networkTableAddress} />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.ALARMS ? (
            <AlarmsFlowContent tableAddress={networkTableAddress} />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.GRAPH ? (
            <GraphFlowContent tableAddress={networkTableAddress} />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.DEVICES ? (
            <DevicesFlowContent tableAddress={networkTableAddress} />
          ) : step === +XML_REFRIG_COPY_TAB_INDEX.COPY ? (
            <CopyFlowContent tableAddress={networkTableAddress} />
          ) : null}
        </>
      )}
    </>
  );
}
