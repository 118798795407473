import * as React from 'react';
import { Switch } from 'react-router-dom';
import { lookupForRoutesToGuard } from './utils';

/*
 * Original Switch looks only at direct children and if property "path" or "location"
 * are present it assumes that the element is the Route. If "path" or "location" are not present,
 * it won't traverse following children properly, even if those *are* Route components.
 * thus making part of the components tree inside of Switch broken (impossible to access)
 */
export function SwitchWithGuard({ children }: { children: React.ReactNode }) {
  return React.createElement.call(
    React,
    Switch,
    null,
    lookupForRoutesToGuard(children),
  );
}
