import { ALARM_GROUPED_BY_UNIT_DELIMITER } from '../constants';

export function getAlarmRefsGroupedByUnit(alarmRefs: string[]) {
  return alarmRefs.reduce((acc, curr) => {
    const [unitAddr, alarmRef] = curr.split(ALARM_GROUPED_BY_UNIT_DELIMITER);
    // eslint-disable-next-line no-prototype-builtins
    if ((acc as object).hasOwnProperty(unitAddr)) {
      acc[unitAddr].push(alarmRef);
    } else {
      acc[unitAddr] = [alarmRef];
    }
    return acc;
  }, {});
}
