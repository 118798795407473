import { getSessionTokensFromStorage, trimUrlEnding, getUnitMetadata, fetchXMLData, XML_ACTION, getUnitNetworkByUnitUrl, addSessionTokenByUrl } from '@danfoss/etui-sm-xml';
import { Kind } from '@danfoss/etui-core';
import { SOFTWARE_VERSION_PREFIX } from '../constants/software-versions.js';
import '../utils/get-software-version-prefix.js';
import { getIsMatchedSoftwareVersion } from '../utils/get-is-matched-software-version.js';
import '../utils/get-is-misc-device.js';

const authorizeUnits = async (dispatch, urls, validUnitNetworks, initialUnit, user, showNotification) => {
  const isSM800A = getIsMatchedSoftwareVersion([initialUnit], [SOFTWARE_VERSION_PREFIX.G09, SOFTWARE_VERSION_PREFIX.G10]);
  const sessionTokens = getSessionTokensFromStorage();
  const filteredUrls = urls.filter(url => {
    const trimmedUrl = trimUrlEnding(url);
    return getUnitMetadata(trimmedUrl) && !(trimmedUrl in sessionTokens);
  });
  if (!filteredUrls.length) return;
  try {
    const responses = await Promise.allSettled(filteredUrls.map(url => fetchXMLData({
      url,
      attributes: {
        action: XML_ACTION.AUTH,
        user: user.user,
        password: user.password
      }
    })));
    responses.forEach((res, index) => {
      const url = filteredUrls[index];
      const currentNetwork = getUnitNetworkByUnitUrl(isSM800A, url, validUnitNetworks);
      if (res.status === 'rejected') {
        addSessionTokenByUrl(trimUrlEnding(url), null);
        throw new Error(res.reason);
      }
      const {
        session_token: sessionToken,
        authtype,
        authorization
      } = res.value;
      addSessionTokenByUrl(trimUrlEnding(url), sessionToken);
      if (authtype === '-1') {
        showNotification({
          message: 't3657',
          interpolation: {
            unit: trimUrlEnding(url)
          }
        });
      } else if (authorization === '0') {
        dispatch({
          type: 'UNAUTHORIZED_UNITS',
          payload: [currentNetwork]
        });
        showNotification({
          message: 't3658',
          interpolation: {
            unit: trimUrlEnding(url)
          }
        });
      }
    });
  } catch ({
    message
  }) {
    showNotification({
      kind: Kind.ERROR,
      message
    });
  } finally {
    warnIfFirmwareShouldBeUpdated(showNotification);
  }
};
const warnIfFirmwareShouldBeUpdated = showNotification => {
  const sessionTokens = getSessionTokensFromStorage();
  const hasActiveSessions = Object.values(sessionTokens).some(Boolean);
  if (!hasActiveSessions) return;
  Object.entries(sessionTokens).forEach(_ref => {
    let [untiUrl, sessionToken] = _ref;
    const portRegExp = /:([0-9]+)/;
    const unitUrl = trimUrlEnding(untiUrl);
    !sessionToken && portRegExp.test(unitUrl) && showNotification({
      message: 't3659',
      interpolation: {
        unit: unitUrl
      }
    });
  });
};

export { authorizeUnits };
