import * as React from 'react';
import { ContainerDimensions, icons } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { EmptyPageContent } from 'components/EmptyPageContent';
import { useTranslation } from 'react-i18next';
import { Progress } from './components/Progress';
import { HistoryGraphView } from './OldHistoryGraphView';

export function HistoryPageContent({
  dateRange,
  formats,
  loadProgress,
  indeterminateProgress,
  chartData,
  onFormatChange,
  legendMap,
  onChartsToDrawChange,
  onChartsDataClear,
}) {
  const { t } = useTranslation();

  return (
    <ContainerDimensions>
      {({ height }) => (
        <Div height={`${height}px`} overflow="auto">
          {loadProgress < 100 && (
            <Div
              width="100%"
              position="absolute"
              display="flex"
              justifyContent="center"
            >
              <Progress
                value={loadProgress}
                indeterminateProgress={indeterminateProgress}
              />
            </Div>
          )}
          {chartData ? (
            <HistoryGraphView
              dateRange={dateRange}
              chartData={chartData}
              formats={formats}
              onFormatChange={onFormatChange}
              legendMap={legendMap}
              onChartsToDrawChange={onChartsToDrawChange}
              onChartsDataClear={onChartsDataClear}
            />
          ) : (
            <EmptyPageContent
              title={t('t153')}
              description={t('t3126')}
              glyph={icons.GRAPH}
            />
          )}
        </Div>
      )}
    </ContainerDimensions>
  );
}
