import React from 'react';
import { ButtonProps, Icon, icons, Label, Modal } from '@danfoss/etui-core';
import { useTheme } from 'styled-components';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { AvailableCasesTableData } from '../RefrigLayoutModal/types';

interface WizardConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleOnOkClick: (
    currentCaseSelectedToAssign?: AvailableCasesTableData,
  ) => void;
  currentCaseSelectedToAssign?: AvailableCasesTableData;
  title: string;
  text?: string;
  subText?: string;
  isSuctionGroupScreen?: boolean;
}
export const WizardConfirmationModal = ({
  isOpen,
  onClose,
  handleOnOkClick,
  currentCaseSelectedToAssign,
  title,
  text,
  subText,
  isSuctionGroupScreen = false,
}: WizardConfirmationModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [shouldClose, setShouldClose] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShouldClose(false);
  }, []);

  const handleOkClick = () => {
    if (isSuctionGroupScreen) {
      handleOnOkClick(currentCaseSelectedToAssign);
    } else {
      handleOnOkClick();
    }
    onClose();
  };
  const renderButtons = (): Partial<ButtonProps>[] => {
    const closeButton: Partial<ButtonProps> = {
      children: t('t45'),
      block: true,
      onClick: onClose,
      variant: 'secondary',
    };

    const okButton: Partial<ButtonProps> = {
      children: t('t74'),
      block: true,
      onClick: handleOkClick,
      variant: 'primary',
    };
    return [closeButton, okButton];
  };
  return (
    <>
      <Modal
        styles={{
          contentContainer: {
            minWidth: [100, 400],
            minHeight: [100, 100],
          },
        }}
        isOpen={isOpen}
        onClose={onClose}
        shouldCloseOnOverlayClick={shouldClose}
        actionButtons={renderButtons()}
        header={<Modal.Header title={title} showCloseButton={false} />}
      >
        {isSuctionGroupScreen ? (
          <>
            <Div
              testId="wizard-close-icon-div"
              style={{ marginTop: '5px', marginLeft: '42%' }}
            >
              <Icon
                glyph={icons.WARNING}
                styles={{
                  root: {
                    color: theme.palette.warning.main,
                  },
                }}
                size={64}
              />
            </Div>
            <Div style={{ textAlign: 'center' }}>{text}</Div>
            <Div style={{ textAlign: 'center' }}>{subText}</Div>
          </>
        ) : (
          <>
            <Div
              testId="wizard-close-icon-div"
              style={{ marginTop: '5px', marginLeft: '152px' }}
            >
              <Icon
                glyph={icons.WARNING}
                styles={{
                  root: {
                    color: theme.palette.warning.main,
                  },
                }}
                size={64}
              />
            </Div>
            <Div
              testId="wizard-close-label-div"
              style={{ marginTop: '10px', marginLeft: '55px' }}
            >
              <Label>{text}</Label>
            </Div>
            <Div
              testId="wizard-close-label-second-div"
              style={{ marginLeft: '95px' }}
            >
              <Label>{subText}</Label>
            </Div>
          </>
        )}
      </Modal>
    </>
  );
};
