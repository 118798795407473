import { ConfigurationTabByUnitResponse } from 'pages/ConfigurationPage';
import { KeyedMutator } from 'swr';

export const updateListInSWRCache = (
  data: ConfigurationTabByUnitResponse,
  listItemIndex: string,
  valueProperty: string,
  tmpValue: string | number,
  mutationFunc: KeyedMutator<ConfigurationTabByUnitResponse>,
) => {
  if (![valueProperty, tmpValue].every(Boolean)) return mutationFunc();

  const temporaryList = data.list.reduce(
    (accumulator, listEntry) =>
      accumulator.concat(
        listEntry.li !== listItemIndex
          ? listEntry
          : { ...listEntry, [valueProperty]: tmpValue },
      ),
    [],
  );
  mutationFunc({ ...data, list: temporaryList });
};
