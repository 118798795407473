import {
  DEVICE_LIST_ACTION,
  DEVICE_LIST_TYPE,
} from '../types/DiscoveryPage.types';

export const getActionBasedOnType = (
  type: number | DEVICE_LIST_TYPE,
): string => {
  switch (type) {
    case DEVICE_LIST_TYPE.EVAP:
      return DEVICE_LIST_ACTION.EVAP;
    case DEVICE_LIST_TYPE.RACK:
      return DEVICE_LIST_ACTION.RACK;
    case DEVICE_LIST_TYPE.HVAC:
      return DEVICE_LIST_ACTION.HVAC;
    default:
      return null;
  }
};
