import * as React from 'react';
import { StoreHoursContext } from './StoreHoursContext';

function useStoreHours() {
  const context = React.useContext(StoreHoursContext);
  if (!context) {
    throw new Error(`useStoreHours must be used within a StoreHoursContext`);
  }
  return context;
}

export { useStoreHours };
