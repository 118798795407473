import { mapDateTimeToNumber } from './map-date-time-to-number.js';

function sortDateTimeString(a, b) {
  const [aDate, aTime] = a.split(' ');
  const [bDate, bTime] = b.split(' ');
  const aDateTime = mapDateTimeToNumber(aDate, aTime, '-', 'iso');
  const bDateTime = mapDateTimeToNumber(bDate, bTime, '-', 'iso');
  return bDateTime - aDateTime;
}

export { sortDateTimeString };
