import { parseXml } from './parse-xml.js';

function isEncoded(data) {
  try {
    const node = parseXml(data).documentElement;
    if (node != null && node.nodeName === 'mxfile') {
      const diagrams = node.getElementsByTagName('diagram');
      if (diagrams.length > 0) {
        const mxGraphModels = diagrams[0].getElementsByTagName('mxGraphModel');
        return mxGraphModels.length !== 1;
      }
    } else {
      return data.indexOf('mxGraphModel') === -1;
    }
  } catch (error) {
    console.info('Mx check: isEncoded == true');
    return true;
  }
  return false;
}

export { isEncoded };
