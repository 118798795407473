import {
  ConfigurationTabItem,
  ConfigurationListItem,
  User,
} from '@danfoss/etui-sm-xml';

function getIsAuthorized(
  tab: ConfigurationTabItem,
  item: ConfigurationListItem,
  user: User,
  wrightFlag: boolean,
): boolean {
  const tabAuthtype = tab ? +tab.authtype : 0;
  const itemAuthtype = item ? +item.authtype : 0;

  // No authorization required.
  if (itemAuthtype === 0 && tabAuthtype === 0) {
    return true;
  }

  // If an external configuration flag blocks wright access
  if (!wrightFlag) {
    return false;
  }

  const userAuthtype = parseInt(user?.authtype || '0', 10);

  // Always first check if there is authorization needed on tab level
  if (tabAuthtype) {
    return (+userAuthtype & tabAuthtype) > 0;
  }

  return (+userAuthtype & itemAuthtype) > 0;
}

export { getIsAuthorized };
