import { AddressBookItem } from 'types';
import {
  addAddressBookToCookie,
  addSiteToRecents,
} from 'utils/addressbook-helpers';
import { decrypt } from 'utils/crypto-helpers';

export const openSiteInANewTab = (
  site: AddressBookItem,
  addressBookPassword: string = '',
) => {
  const { protocol: windowProtocol, href: windowHref } = window.location;
  const { protocol, ipAddress, user } = site;
  const password =
    addressBookPassword && decrypt(site.password, addressBookPassword);

  addSiteToRecents({
    protocol,
    ipAddress,
    user,
  });
  addAddressBookToCookie();

  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  const path = windowHref.replace(windowProtocol, '');
  const href = `${protocol.value}${path}?ip=${ipAddress}&user=${user}&password=${password}`;
  link.href = href;
  link.click();
};
