import { TFunction } from 'i18next';
import { ScheduleType } from '../SchedulesPage.types';

const getScheduleTypeTextId = (type: ScheduleType, t: TFunction): string => {
  const text =
    type === 'Shutdown'
      ? 't391'
      : type === 'Defrost'
      ? 't403'
      : type === 'Case Lights'
      ? 't412'
      : type === 'Circuit night setbacks'
      ? 't3064'
      : type === 'Suction night setbacks'
      ? 't3065'
      : 't411';
  return t(text);
};

export { getScheduleTypeTextId };
