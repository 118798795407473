export const createBlobFromByteArray = (
  byteArray: Uint8Array,
  type: string,
) => {
  try {
    return new Blob([byteArray], { type });
  } catch (e) {
    throw new Error();
  }
};
