import React from 'react';
import { Span } from '@danfoss/etui-system-elements';
import { Icon, icons } from '@danfoss/etui-core';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRefrigLayout } from '../../context';

export const DiscoveryFlowStatusLegend = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isOfflineProgramming, discoveryDeviceStatusCnt } = useRefrigLayout();
  return (
    <>
      <Span>
        <Icon
          size={24}
          isIconLabel={true}
          styles={{
            root: {
              color: theme.palette.success.main,
            },
          }}
          glyph={icons.CHECK_CIRCLE}
        />
      </Span>
      <Span>
        {` ${discoveryDeviceStatusCnt?.statusGreenCnt || '0'} ${t('t3366')}  `}
      </Span>

      <Span
        style={{
          marginLeft: '50px',
        }}
      >
        <Icon
          size={24}
          isIconLabel={true}
          styles={{
            root: {
              color: theme.palette.warning.main,
            },
          }}
          glyph={icons.WARNING}
        />
      </Span>
      <Span>
        {` ${
          !isOfflineProgramming || !discoveryDeviceStatusCnt?.statusOrangeCnt
            ? '0'
            : discoveryDeviceStatusCnt?.statusOrangeCnt
        } ${t('t517')}  `}
      </Span>

      <Span
        style={{
          marginLeft: '50px',
        }}
      >
        <Icon
          size={24}
          isIconLabel={true}
          styles={{
            root: {
              color: theme.palette.error.main,
            },
          }}
          glyph={icons.ERROR_CIRCLE}
        />
      </Span>
      <Span>
        {` ${
          !isOfflineProgramming || !discoveryDeviceStatusCnt?.statusRedCnt
            ? '0'
            : discoveryDeviceStatusCnt?.statusRedCnt
        } ${t('t3367')}  `}
      </Span>
    </>
  );
};
