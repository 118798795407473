import { b as _objectWithoutProperties, _ as _objectSpread2 } from './_rollupPluginBabelHelpers-e1ae8e90.js';
import { styled } from '@danfoss/etui-system';
import { Div, Ul } from '@danfoss/etui-system-elements';

const _excluded = ["theme"];
const DropdownMenuDiv = styled(Div).attrs(_ref => {
  let {
      theme
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return _objectSpread2({
    boxShadow: theme.shadows[3],
    border: `1px solid ${theme.palette.divider}`,
    p: `${theme.spacing.xxs}px 0`
  }, props);
})`
  display: ${props => props.isVisible ? 'flex' : 'none'};
  flex-direction: column;
  background-color: white;
  border-radius: 2px;
  min-width: 200px;
`;

const DropdownMenuInnerDiv = styled(Ul)`
  position: static !important;
  max-height: ${props => props.maxHeight};
  overflow-y: auto;
`;

export { DropdownMenuDiv as D };
