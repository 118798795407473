import { useDashboard, WidgetComponentBase } from '@danfoss/etui-core';
import React from 'react';
import { DeviceTile } from '../DeviceTile';

export const DashboardDeviceWidget = props => {
  const { id } = props;
  const [, dispatch] = useDashboard();
  return (
    <WidgetComponentBase {...props}>
      <DeviceTile
        rowCount={1}
        onMaximize={() => dispatch({ type: 'SET_FULLSCREEN', id })}
        onMinimize={() => dispatch({ type: 'REMOVE_FULLSCREEN' })}
        isWidget={true}
        {...props}
      />
    </WidgetComponentBase>
  );
};
