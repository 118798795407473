import React from 'react';
import { Badge, Icon, TreeViewItemToggleDiv, icons } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { getNoOfCasesAssigned } from '../../actions';
import { ShowParentDeviceWithIcon } from './ShowParentDeviceWithIcon';
import * as S from './styles';

const MARGIN_STEP_PX = 16;

export const SuctionGroupTreeItem = ({
  item,
  options,
  level = 0,
  handleCurrentSelectedDevice,
  setcurrentSelectedDevice,
  currentSelectedDevice,
}) => {
  const theme = useTheme();
  const { getToggleProps, openSections } = options;
  const { onClick, onKeyDown, ...toggleProps } = getToggleProps();
  const hasNestedItems = !!item.items?.length;
  const isOpen = openSections.includes(item.id);
  const isRowSelected = currentSelectedDevice?.id === item.id;
  const margin = `${level * MARGIN_STEP_PX}px`;

  const handleClick = () => {
    handleCurrentSelectedDevice(item);
    setcurrentSelectedDevice(item);
  };

  const renderChildren = item => {
    if (!isOpen || !hasNestedItems) return null;

    return item.items.map(subitem => (
      <SuctionGroupTreeItem
        item={subitem}
        options={options}
        level={level + 1}
        handleCurrentSelectedDevice={handleCurrentSelectedDevice}
        setcurrentSelectedDevice={setcurrentSelectedDevice}
        currentSelectedDevice={currentSelectedDevice}
      />
    ));
  };

  return (
    <Div testId={`${item.id}-tree-item`} flex={1} mt={theme.spacing.sm}>
      <Div
        display="flex"
        onClick={handleClick}
        fontWeight={
          theme.typography[
            isRowSelected ? 'fontWeightBold' : 'fontWeightRegular'
          ]
        }
      >
        <S.SuctionGroupItemName>
          <TreeViewItemToggleDiv
            hasNestedItems={hasNestedItems}
            onClick={e => onClick(e, item)}
            testId={`${item.id}-toggle-div`}
            ml={margin}
            {...toggleProps}
          >
            <Icon
              glyph={icons.CHEVRON_DOWN}
              rotate={isOpen ? 0 : -90}
              size={16}
            />
          </TreeViewItemToggleDiv>
          <ShowParentDeviceWithIcon
            deviceName={item.name}
            deviceIcon={item.icon}
          />
        </S.SuctionGroupItemName>
        <S.SuctionGroupItemInfo testId="content-div">
          <Div>{item.address}</Div>
          <Div>{item.model}</Div>
          {!level && (
            <Badge
              isRound={true}
              text={getNoOfCasesAssigned(item.items)}
              styles={{
                root: {
                  bg: '#7C7C7C',
                  ml: theme.spacing.xlg,
                },
              }}
            />
          )}
        </S.SuctionGroupItemInfo>
      </Div>
      <Div>{renderChildren(item)}</Div>
    </Div>
  );
};
