import { TFunction } from 'i18next';

export const getHvacLayoutSteps = (t: TFunction) => [
  {
    title: t('t3355'),
    stepNumber: 1,
  },
  {
    title: t('t3343'),
    stepNumber: 2,
  },
  {
    title: t('t87'),
    stepNumber: 3,
  },
];
