export const routePaths = {
  pages: {
    home: '/',
    alarms: {
      listPath: '/alarms',
      listActivePath: '/alarms/active',
      listAckedPath: '/alarms/acked',
      listClearedPath: '/alarms/cleared',
      listStatusPath: '/alarms/status',
    },
    equipment: {
      listPath: '/equipment',
      showPath: '/equipment/:equipmentId',
      showStatusPath: '/equipment/:equipmentId/status',
      showAlarmsPath: '/equipment/:equipmentId/alarms',
      showSettingsPath: '/equipment/:equipmentId/settings',
      show: (equipmentId: string) => `/equipment/${equipmentId}`,
      showStatus: (equipmentId: string) => `/equipment/${equipmentId}/status`,
      showAlarms: (equipmentId: string) => `/equipment/${equipmentId}/alarms`,
      showSettings: (equipmentId: string) =>
        `/equipment/${equipmentId}/settings`,
    },
    info: {
      home: '/info',
      unit: '/info/unit',
      site: '/info/site',
    },
    information: {
      home: '/information',
      releaseNotes: '/information/releaseNotes',
      privacy: '/information/privacy',
      licenses: '/information/licenses',
    },
    history: '/history',
    configuration: '/configuration',
    schedules: {
      listPath: '/schedules',
      showPath: '/schedules/:equipmentId/:tabview',
      show: (equipmentId: string, tabview: string) =>
        `/schedules/${equipmentId}/${tabview}`,
    },
    login: '/login',
    clearedAlarms: '/cleared-alarms',
    diagnostics: '/diagnostics',
    haccp: '/haccp',
    graphicEditor: {
      listPath: '/graphic-editor',
      showEditPath: '/graphic-editor/edit/:graphId',
      showEdit: (graphId: string) => `/graphic-editor/edit/${graphId}`,
      showDrawPath: '/graphic-editor/draw/:graphId',
      showDraw: (graphId: string) => `/graphic-editor/draw/${graphId}`,
    },
    graphicViewer: {
      home: '/graphic-viewer',
      showPath: '/graphic-viewer/:graphId',
      show: (graphId: string) => `/graphic-viewer/${graphId}`,
    },
    graphicLocalEditor: {
      listPath: '/graphic-editor',
      showLocalGraphicEditPath: '/graphic-editor/local/edit/:graphId',
      showLocalGraphicEdit: (graphId: string) =>
        `/graphic-editor/local/edit/${graphId}`,
    },
    floorViews: '/floor-views',
    softwareRollback: '/software-rollback',
    softwareUpdate: '/software-update',
    system: {
      home: '/system',
      softwareManagement: '/system/software-management',
      database: '/system/database',
    },
    reports: '/reports',
    files: {
      listPath: '/files',
      deviceIntegrationPath: '/files/device-integration',
      deviceManagementPath: '/files/device-management',
    },
    deviceIntegration: '/device-integration',
    securityCompliance: '/security-compliance',
    notFound: '*',
  },
};
