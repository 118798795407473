import { getUnitUrlByUnitNetwork, trimUrlEnding, getSessionTokenByUrl, addSessionTokenByUrl, getUnitMetadata, updateUnitsMetadata } from '@danfoss/etui-sm-xml';

const dublicateInitialUnitInfoForAddressWithPortNumber = _ref => {
  let {
    url,
    protocol,
    initialUnit,
    unitNetworks
  } = _ref;
  const initialUnitNetwork = unitNetworks.find(unitNetwork => unitNetwork.addr === initialUnit.unit_addr);
  const intitalUnitUrlWithPort = getUnitUrlByUnitNetwork(initialUnitNetwork, protocol);
  const trimmedUrl = trimUrlEnding(url);
  const trimmedUrlWithPort = trimUrlEnding(intitalUnitUrlWithPort);
  const token = getSessionTokenByUrl(trimmedUrl);
  addSessionTokenByUrl(trimmedUrlWithPort, token);
  const metadata = getUnitMetadata(trimmedUrl);
  updateUnitsMetadata(trimmedUrlWithPort, metadata);
};

export { dublicateInitialUnitInfoForAddressWithPortNumber };
