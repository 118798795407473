import * as React from 'react';
import { Modal } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { Unit } from '@danfoss/etui-sm-xml';
import { useModal } from '@danfoss/etui-sm';
import { WizardConfirmationModal } from '../WizardConfirmationModal';
import { RefrigLayoutModalActions } from './RefrigLayoutModalActions';
import { RefrigLayoutContent } from './components/RefrigLayoutContent';
import { useRefrigLayout } from './context';

export interface RefrigLayoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  unit: Unit;
}
function RefrigLayoutInnerModal({
  isOpen,
  onClose,
  unit,
}: RefrigLayoutModalProps) {
  const { t } = useTranslation();
  const { setUnit } = useRefrigLayout();

  const handleOnOkClick = () => {
    onClose();
  };

  const [showWizardCloseModal] = useModal(WizardConfirmationModal, {
    handleOnOkClick,
    title: t('t3382'),
    text: t('t3383'),
    subText: t('t3380'),
  });
  React.useEffect(() => {
    if (unit) {
      setUnit(unit);
    }
  }, [unit]);

  const handleOnClose = () => {
    showWizardCloseModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      header={<Modal.Header title={t('t501')} description={t('t150')} />}
      actions={
        <RefrigLayoutModalActions
          onCompleteRefrigLayout={() => onClose()}
          onCancel={handleOnClose}
        />
      }
      style={{
        content: {
          width: '100%',
          height: '100%',
          maxWidth: '100%',
        },
        overlay: {},
      }}
      shouldCloseOnOverlayClick={false}
    >
      <RefrigLayoutContent />
    </Modal>
  );
}

export { RefrigLayoutInnerModal };
