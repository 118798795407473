import { REGEX_PATTERNS } from '../../constants/regex-patterns-constant.js';

/**
 * Validates port
 *
 * @export
 * @param {string} port
 * @returns {boolean}
 */
function getIsValidPort(port) {
  return REGEX_PATTERNS.PORT.test(port);
}

export { getIsValidPort };
