import { fetchXMLData, User, XML_ACTION } from '@danfoss/etui-sm-xml';
import { getArray } from '../../../../Configuration/utils';
import {
  DiscoveryDeviceDetail,
  DiscoveryResponse,
} from '../../RefrigLayoutModal/types';

export const filterAndUpdateOnlineSourceControllers = async (
  url: string,
  user: User,
  listItems: any,
  isHvac: boolean,
) => {
  const data: DiscoveryResponse = await fetchDevices(url, user, isHvac);
  const onlineDeviceDetails: DiscoveryDeviceDetail[] =
    filterOnlineDeviceDetails(data);
  updateListItemsWithOnlineControllers(listItems, onlineDeviceDetails);
  return listItems;
};

export const checkOnlineSourceControllersAvailable = (listItems: any) => {
  let isAvailable: boolean = false;
  listItems?.forEach(listItem => {
    if (listItem?.listdata?.length) {
      isAvailable = true;
    }
  });
  return isAvailable;
};

const updateListItemsWithOnlineControllers = (
  listItems: any,
  onlineDeviceDetails: DiscoveryDeviceDetail[],
) => {
  const onlineListData = [];
  listItems.forEach(listItem => {
    const listData = listItem?.listdata;
    if (listData?.length) {
      listData.forEach(data =>
        onlineDeviceDetails?.forEach(onlineDeviceDetail => {
          if (
            data?.datavalue !== '0' &&
            data?.datavalue === onlineDeviceDetail?.bpidx
          ) {
            onlineListData.push(data);
          }
        }),
      );
      let finalOnlineListData = removeDuplicate(onlineListData);
      if (finalOnlineListData?.length) {
        finalOnlineListData = [listData[0], ...finalOnlineListData];
      }
      listItem.listdata.length = 0;
      listItem.listdata = finalOnlineListData;
    }
  });
};

const removeDuplicate = (onlineListData: any) => {
  return onlineListData?.filter(
    (value, index, listData) =>
      listData?.findIndex(data => data?.datavalue === value?.datavalue) ===
      index,
  );
};

const filterOnlineDeviceDetails = (data: DiscoveryResponse) => {
  const devices = getArray(data?.devices?.d);
  return devices?.filter(device => device.onln === '1');
};

const fetchDevices = async (url: string, user: User, isHvac: boolean) => {
  const data = await fetchXMLData({
    url,
    attributes: {
      user: user?.user,
      password: user?.password,
      lang: user?.language,
      storeview_only: '1',
      version: 'C',
      action: isHvac ? XML_ACTION.READ_HVAC_SCAN : XML_ACTION.READ_REFRIG_SCAN,
    },
  });
  return data;
};
