import * as React from 'react';
import { Button, Modal } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { P, Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';

export function HistoryPageExportModal({
  isOpen,
  onClose,
  exportCsv,
  exportPng,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Header title={t('t175')} />}
      actionButtons={[
        {
          children: t('t45'),
          variant: 'tertiary',
          onClick: onClose,
        },
      ]}
      styles={{
        contentContainer: {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <P>{t('t274')}</P>
      <Div
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        mt={`${theme.spacing.md}px`}
        mb={`${theme.spacing.xs}px`}
      >
        {[
          {
            label: t('t416'),
            variant: 'primary' as any,
            action: exportCsv,
          },
          {
            label: t('t417'),
            variant: 'primary' as any,
            action: exportPng,
          },
        ].map(({ label, variant, action }, i, thisArray) => (
          <Button
            key={i}
            variant={variant}
            onClick={action}
            mb={i < thisArray.length - 1 ? `${theme.spacing.xxs}px` : 0}
          >
            {label}
          </Button>
        ))}
      </Div>
    </Modal>
  );
}
