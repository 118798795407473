function isXmlError(e) {
  return e instanceof XmlError;
}
class XmlError extends Error {
  constructor(_ref) {
    let {
      message,
      code
    } = _ref;
    super(message);
    this.name = this.constructor.name;
    this.code = code;
    if (!Error.captureStackTrace) {
      const tmp = new Error(message);
      Object.defineProperty(this, 'stack', {
        get() {
          return tmp.stack;
        }
      });
    } else {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  /**
   * Helps to get correct UI message of error based on its code
   * @param translator - takes error code. returns an empty string if no translation is available
   */
  displayError(translator) {
    if (!translator) {
      return this.message || '';
    }
    const translated = translator(this.code);
    return translated || this.message || '';
  }
}

export { XmlError, isXmlError };
