import * as React from 'react';
import {
  ConfigurationMenuProvider,
  ConfigurationModalProvider,
  ConfigurationProvider,
} from '../Configuration/context';
import { DeviceConfigurationProps } from './DeviceConfiguration.types';
import { DeviceConfigurationBase } from './DeviceConfigurationBase';

function DeviceConfiguration(props: DeviceConfigurationProps) {
  return (
    <ConfigurationProvider>
      <ConfigurationModalProvider>
        <ConfigurationMenuProvider>
          <DeviceConfigurationBase {...props} />
        </ConfigurationMenuProvider>
      </ConfigurationModalProvider>
    </ConfigurationProvider>
  );
}

export { DeviceConfiguration };
