import React from 'react';
import { useTranslation } from 'react-i18next';
import { Div } from '@danfoss/etui-system-elements';
import { LayoutFlowTitle } from '../LayoutContent';
import { CompressorsFlowTable } from '.';

export interface CompressorsFlowPageProps {
  handleOnCopyOk: (copyAmount: number, selectedRowIndex: number) => void;
  handleDeleteSelectedRow: (selectedRowIndex: number) => void;
  handleOnSuctionChange: (
    selectedRowIndex: number,
    selectedSuctionGroupValue: number,
  ) => void;
  tableDataLoading: boolean;
  handleOnAddRack: () => void;
}

export const CompressorsFlowPage = ({
  handleOnCopyOk,
  handleDeleteSelectedRow,
  handleOnSuctionChange,
  tableDataLoading,
  handleOnAddRack,
}: CompressorsFlowPageProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Div mt="10px">
        <LayoutFlowTitle
          title={t('t3358')}
          subTitle={t('t3359')}
          helpText={t('t3360')}
        />
        <CompressorsFlowTable
          handleOnCopyOk={handleOnCopyOk}
          handleDeleteSelectedRow={handleDeleteSelectedRow}
          handleOnSuctionChange={handleOnSuctionChange}
          tableDataLoading={tableDataLoading}
          handleOnAddRack={handleOnAddRack}
        />
      </Div>
    </>
  );
};
