import { getArray } from './get-array.js';

function getNormalizedDeviceDetails(device) {
  var _device$devicedetails, _device$devicedetails2, _device$devicedetails3;
  if (device !== null && device !== void 0 && (_device$devicedetails = device.devicedetails) !== null && _device$devicedetails !== void 0 && _device$devicedetails.devicedetail) {
    return getArray(device.devicedetails.devicedetail);
  }
  if (device !== null && device !== void 0 && (_device$devicedetails2 = device.devicedetails) !== null && _device$devicedetails2 !== void 0 && (_device$devicedetails3 = _device$devicedetails2.devicedetails) !== null && _device$devicedetails3 !== void 0 && _device$devicedetails3.devicedetail) {
    return getArray(device.devicedetails.devicedetails.devicedetail);
  }
  return null;
}

export { getNormalizedDeviceDetails };
