import { fetchXMLData, User, XML_ACTION, XML_TAG } from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';

export const deleteVz2Files = async ([user, url, fileName, t]: [
  User,
  string,
  string,
  TFunction,
]) => {
  const RAMDISK_EDF = 'ramdisk_edf';
  try {
    const data = await fetchXMLData({
      url,
      attributes: {
        user: user?.user,
        password: user?.password,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.DELETE_FILES,
      },
      items: [
        {
          tag: XML_TAG.FILE,
          attributes: {
            filename: fileName,
            access_area: RAMDISK_EDF,
          },
        },
      ],
    });
    if (data?.error !== '0') {
      throw new Error();
    }
    return data;
  } catch (e) {
    throw new Error(t('t3536'));
  }
};

export const deleteVizFiles = async ([user, url, fileName, t]: [
  User,
  string,
  string,
  TFunction,
]) => {
  const directory = '/User/';
  try {
    const data = await fetchXMLData({
      url,
      attributes: {
        user: user?.user,
        password: user?.password,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.DELETE_FFS_FILES,
      },
      items: [
        {
          tag: XML_TAG.FILE,
          attributes: {
            filename: fileName,
            directory,
          },
        },
      ],
    });
    if (data?.error !== '0') {
      throw new Error();
    }
    return data;
  } catch (e) {
    throw new Error(t('t3536'));
  }
};
