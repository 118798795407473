import { XML_DEVICE_COMBO } from '@danfoss/etui-sm-xml';

/**
 * Utility to get if device is light zone
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsMcxLightDevice(device) {
  return device.combo === XML_DEVICE_COMBO.COMBO_MCX;
}

export { getIsMcxLightDevice };
