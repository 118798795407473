import '@danfoss/etui-sm-xml';
import { checkNetwork } from './check-network.js';
import '../_rollupPluginBabelHelpers-e1ae8e90.js';
import '@danfoss/etui-core';
import '../constants/software-versions.js';
import { UPDATE_TYPE } from '../constants/software-update-type.js';
import { compareSoftwareVersions } from './compare-software-versions.js';
import './get-version-from-filename.js';
import { fetchCurrentSoftware } from '../actions/fetch-current-software.js';
import '../fetch-latest-version-filename-d4771f3b.js';
import { f as fetchLatestVersion, e as extractVersion } from '../get-latest-os-version-779b9c8f.js';
import './get-is-misc-device.js';

const validatePublicIpInInternalUnit = unitInfo => {
  var _unitInfo$internalUni;
  return (unitInfo === null || unitInfo === void 0 ? void 0 : (_unitInfo$internalUni = unitInfo.internalUnits) === null || _unitInfo$internalUni === void 0 ? void 0 : _unitInfo$internalUni.filter(internalUnit => {
    return checkNetwork(internalUnit === null || internalUnit === void 0 ? void 0 : internalUnit.ip) === false;
  }).length) !== 0;
};
const validatePrivateIpInExternalUnit = unitInfo => {
  var _unitInfo$externalUni;
  return (unitInfo === null || unitInfo === void 0 ? void 0 : (_unitInfo$externalUni = unitInfo.externalUnits) === null || _unitInfo$externalUni === void 0 ? void 0 : _unitInfo$externalUni.filter(externalUnit => {
    return checkNetwork(externalUnit === null || externalUnit === void 0 ? void 0 : externalUnit.ip) === true || checkNetwork(externalUnit === null || externalUnit === void 0 ? void 0 : externalUnit.ip1) === true || checkNetwork(externalUnit === null || externalUnit === void 0 ? void 0 : externalUnit.ip2) === true;
  }).length) !== 0;
};
const validateConnectedIp = (unitInfo, ip, units) => {
  if (unitInfo !== null && unitInfo !== void 0 && unitInfo.isDNS) {
    if (unitInfo !== null && unitInfo !== void 0 && unitInfo.isExternal) {
      return validateExternalUnitConnectedIp(unitInfo, ip);
    }
    return validateInternalUnitConnectedIp(unitInfo, ip, units);
  }
  return false;
};
const validateMacAddrForMasterSlaveUnits = units => {
  if ((units === null || units === void 0 ? void 0 : units.length) > 1) {
    return units === null || units === void 0 ? void 0 : units.every((unit, _index, arr) => {
      var _arr$;
      return (unit === null || unit === void 0 ? void 0 : unit.macAddr) === ((_arr$ = arr[0]) === null || _arr$ === void 0 ? void 0 : _arr$.macAddr);
    });
  }
  return false;
};
const validateMasterSlaveConfiguration = (macAddrSameForMasterSlaveUnits, units) => {
  if ((units === null || units === void 0 ? void 0 : units.length) > 1 && !macAddrSameForMasterSlaveUnits) {
    return validateDomainForMasterSlave(units) && validatePortForMasterSlave(units);
  }
  return macAddrSameForMasterSlaveUnits;
};
const getIpFromXmlBackendIp = xmlBackendIp => {
  const ip = xmlBackendIp !== null && xmlBackendIp !== void 0 && xmlBackendIp.includes(':') ? xmlBackendIp === null || xmlBackendIp === void 0 ? void 0 : xmlBackendIp.substring(0, xmlBackendIp === null || xmlBackendIp === void 0 ? void 0 : xmlBackendIp.indexOf(':')) : xmlBackendIp;
  return ip;
};
const checkMultipleWarnings = warnings => {
  return (warnings === null || warnings === void 0 ? void 0 : warnings.filter(value => value === true).length) > 1;
};
const validateDomainForMasterSlave = units => {
  return units === null || units === void 0 ? void 0 : units.every((unit, _index, arr) => {
    var _arr$2;
    return (unit === null || unit === void 0 ? void 0 : unit.domain) === ((_arr$2 = arr[0]) === null || _arr$2 === void 0 ? void 0 : _arr$2.domain);
  });
};
const validatePortForMasterSlave = units => {
  var _units$;
  const unit = (_units$ = units[0]) === null || _units$ === void 0 ? void 0 : _units$.unit;
  const unitNetworks = Array.isArray(unit) ? unit : [unit];
  return unitNetworks === null || unitNetworks === void 0 ? void 0 : unitNetworks.every((unitNetwork, _index, arr) => {
    var _arr$3;
    return (unitNetwork === null || unitNetwork === void 0 ? void 0 : unitNetwork.port) === ((_arr$3 = arr[0]) === null || _arr$3 === void 0 ? void 0 : _arr$3.port);
  });
};
const validateExternalUnitConnectedIp = (unitInfo, ip) => {
  var _unitInfo$externalUni2;
  return (unitInfo === null || unitInfo === void 0 ? void 0 : (_unitInfo$externalUni2 = unitInfo.externalUnits) === null || _unitInfo$externalUni2 === void 0 ? void 0 : _unitInfo$externalUni2.filter(externalUnit => (externalUnit === null || externalUnit === void 0 ? void 0 : externalUnit.ip) === ip || (externalUnit === null || externalUnit === void 0 ? void 0 : externalUnit.ip1) === ip || (externalUnit === null || externalUnit === void 0 ? void 0 : externalUnit.ip2) === ip).length) === 0;
};
const validateInternalUnitConnectedIp = (unitInfo, ip, units) => {
  return checkInternalUnitIp(unitInfo, ip) && checkUnitDomainName(units, ip);
};
const checkInternalUnitIp = (unitInfo, ip) => {
  var _unitInfo$internalUni2;
  return (unitInfo === null || unitInfo === void 0 ? void 0 : (_unitInfo$internalUni2 = unitInfo.internalUnits) === null || _unitInfo$internalUni2 === void 0 ? void 0 : _unitInfo$internalUni2.filter(internalUnit => (internalUnit === null || internalUnit === void 0 ? void 0 : internalUnit.ip) === ip).length) === 0;
};
const checkUnitDomainName = (units, ip) => {
  return (units === null || units === void 0 ? void 0 : units.filter(unit => (unit === null || unit === void 0 ? void 0 : unit.domain_name) === ip).length) === 0;
};
const checkLatestOSAvailable = async (units, latestFileUrl, getFirstValidUrl, user) => {
  const unitSoftwareVersions = [];
  if (units.length) {
    await units.reduce(async (promise, unit) => {
      var _extractVersion, _extractVersion2, _extractVersion3;
      await promise;
      const latestVersion = await fetchLatestVersion(latestFileUrl, UPDATE_TYPE.SPK);
      const url = getFirstValidUrl(unit);
      const currentVersionsFetched = await fetchCurrentSoftware([`fetchCurrentSoftware${url}`, user, url]);
      const usePackageVersion = compareSoftwareVersions('G09.000.141', (_extractVersion = extractVersion('sm_app', currentVersionsFetched)) === null || _extractVersion === void 0 ? void 0 : _extractVersion.version) === 'larger';
      const currentVersions = {
        softwareVersion: usePackageVersion && ((_extractVersion2 = extractVersion('SM800A-package', currentVersionsFetched)) === null || _extractVersion2 === void 0 ? void 0 : _extractVersion2.version) || ((_extractVersion3 = extractVersion('sm_app', currentVersionsFetched)) === null || _extractVersion3 === void 0 ? void 0 : _extractVersion3.version)
      };
      const latestVersionAvailable = compareSoftwareVersions(currentVersions.softwareVersion, latestVersion);
      unitSoftwareVersions.push(latestVersionAvailable);
    }, Promise.resolve());
  }
  const isLatestOsAvailable = unitSoftwareVersions.some(unitSoftwareVersion => unitSoftwareVersion === 'larger');
  return isLatestOsAvailable;
};

export { checkLatestOSAvailable, checkMultipleWarnings, getIpFromXmlBackendIp, validateConnectedIp, validateMacAddrForMasterSlaveUnits, validateMasterSlaveConfiguration, validatePrivateIpInExternalUnit, validatePublicIpInInternalUnit };
