import { fetchXMLData, User } from '@danfoss/etui-sm-xml';
import {
  DeviceModelResponse,
  DeviceTypeModelList,
} from '../types/DiscoveryPage.types';
import { getActionBasedOnType } from '../utils';

async function fetchDeviceListData([url, user, type]: [string, User, number]) {
  const action: string = getActionBasedOnType(type);
  const data: DeviceModelResponse = await fetchXMLData({
    url,
    attributes: {
      user: user?.user,
      password: user?.password,
      lang: user?.language,
      storeview_only: '1',
      version: 'C',
      action,
    },
  });
  if (data) {
    const deviceTypeModelList: DeviceTypeModelList = {
      type,
      modelList: data?.dt?.ld,
    };
    return deviceTypeModelList;
  }
  return null;
}

export { fetchDeviceListData };
