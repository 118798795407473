import { styled } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';
import { lighten, darken } from '@danfoss/etui-colors';

type ConfigurationMenuItemDivProps = DivProps & {
  isActive: boolean;
};

export const ConfigurationMenuItemDiv = styled(Div).attrs(
  ({ isActive, theme, ...props }: ConfigurationMenuItemDivProps) => ({
    display: 'flex',
    alignItems: 'center',
    color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
    bg: isActive ? lighten(theme.palette.primary.main, 0.9) : 'inherit',
    p: `${theme.spacing.xs}px`,
    pl: `${theme.spacing.xlg + theme.spacing.xs}px`,
    borderLeft: `3px solid ${
      isActive
        ? theme.palette.primary.main
        : darken(theme.palette.common.bg, 0.15)
    }`,
    textDecoration: 'none',
    ...theme.typography.button,
    ...props,
  }),
)<ConfigurationMenuItemDivProps>`
  min-height: 48px;
  outline: 0;

  :hover {
    color: ${({ theme }) => theme.palette.text.primary};
    border-left: 3px solid
      ${({ isActive, theme }) =>
        isActive
          ? theme.palette.primary.main
          : darken(theme.palette.common.bg, 0.35)};
  }
`;
