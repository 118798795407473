import { TFunction } from 'i18next';
import { XML_DEVICE_LIST } from '@danfoss/etui-sm-xml';
import {
  DeviceConstraintsData,
  DeviceTypeModelList,
  DEVICE_STATUS,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../../../RefrigLayoutModal/types';
import {
  getDeviceIndex,
  getTypeDescription,
} from '../../../RefrigLayoutModal/utils';

export const addHvacDevice = (
  hvacFinalTableData: DiscoveryTableData[],
  hvacDeviceTypeModelList: DeviceTypeModelList,
  deviceConstraintsData: DeviceConstraintsData,
  t: TFunction,
) => {
  const addedHvacFinalTableData: DiscoveryTableData = {
    newDevice: true,
    deviceId: getDeviceIndex(hvacFinalTableData) + 1,
    category: XML_DEVICE_LIST.DEV_TYPE_HVAC,
    deviceType: DEVICE_TYPE.HVAC,
    type: getTypeDescription(DEVICE_TYPE.HVAC, t),
    name: t('t87'),
    model: hvacDeviceTypeModelList?.modelList[0]?._,
    file: '',
    version: '',
    device: '',
    listname: hvacDeviceTypeModelList?.modelList[0]?._,
    model_idx: 1,
    ip: '',
    address: '-',
    ah: 0,
    dim1: '0',
    dim2: '0',
    dim3: '0',
    cfg: '0',
    online: '0',
    status: DEVICE_STATUS.RED,
    deviceBus: t('t490'),
    mx_naddr: deviceConstraintsData?.max_naddr,
    code: '-',
  };
  hvacFinalTableData.push(addedHvacFinalTableData);
  return hvacFinalTableData;
};
