import { fetchAlarmRefsByTypes, getUnitUrl, isCurrentlySelectedUnit } from '@danfoss/etui-sm-xml';

async function fetchAlarmRefsByUnits(_ref) {
  let [url, activeUser, activeUnits, alarmTypes, skipSessionUpdate = false] = _ref;
  const alarmsByUnit = await Promise.all(activeUnits.map(unit => fetchAlarmRefsByTypes(getUnitUrl(url, unit), alarmTypes, activeUser, skipSessionUpdate && isCurrentlySelectedUnit(unit))));
  const alarms = alarmsByUnit.reduce((acc, unitAlarms) => ({
    acked: acc.acked.concat(unitAlarms.acked),
    active: acc.active.concat(unitAlarms.active)
  }), {
    acked: [],
    active: []
  });
  return alarms;
}

export { fetchAlarmRefsByUnits };
