import * as React from 'react';

export interface IStoreHours {
  id: string;
  open: string[];
  close: string[];
  tooltipCloseTime: string[];
}

export type StoreHoursContextProps = {
  storeHours: IStoreHours[];
  onSetStoreHours: (storeHours: IStoreHours) => void;
  storeWeekdays: string[];
};

export const StoreHoursContext = React.createContext(
  null as StoreHoursContextProps | null,
);
