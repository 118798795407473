import { saveAs } from 'file-saver';

const REPORT_NAME = 'HACCP';
function appendLeadingZeroes(n) {
  return n <= 9 ? `0${n}` : n;
}
function fetchExportHaccpPdf(pdfBlob, storename, date) {
  const utcDateString = `${date.getUTCFullYear()}-${appendLeadingZeroes(date.getUTCMonth() + 1)}-${appendLeadingZeroes(date.getUTCDate())}`;
  saveAs(pdfBlob, `${REPORT_NAME} - ${storename ?? ''} - ${utcDateString}.pdf`);
}

export { fetchExportHaccpPdf };
