import { FormPasswords } from '../LoginPage.types';

interface ConfirmPasswordValidators {
  validate: (args: FormPasswords, isStrongPassword: string) => boolean;
  message: string;
}

export const CONFIRM_PASSWORD_ERRORS: ConfirmPasswordValidators[] = [
  {
    validate: ({ newPassword, confirmPassword }) =>
      !newPassword || !confirmPassword,
    message: 't3456',
  },
  {
    validate: ({ newPassword, confirmPassword }) =>
      newPassword !== confirmPassword,
    message: 't3176',
  },
  {
    validate: ({ newPassword, password }) => newPassword === password,
    message: 't3177',
  },
  {
    validate: ({ newPassword }, isStrongPassword) =>
      !checkIfPasswordStrong(newPassword, isStrongPassword),
    message: 't3175',
  },
];

function checkIfPasswordStrong(
  newPassword: string,
  isStrongPassword: string,
): boolean {
  if (isStrongPassword === '1') {
    const decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/;
    return !!newPassword.match(decimal);
  }
  return true;
}
