import {
  DeviceTypeModelList,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../../../RefrigLayoutModal/types';

export const isAddressEditable = (
  hvacDeviceTypeModelList: DeviceTypeModelList,
  device: DiscoveryTableData,
  gen_ofs_ah: number,
) => {
  if (
    hvacDeviceTypeModelList.modelList.findIndex(
      model => model._ === device?.listname,
    ) < gen_ofs_ah
  ) {
    return true;
  }
  return isEditable(device);
};

export const isEditable = (device: DiscoveryTableData) => {
  if (device?.deviceType === DEVICE_TYPE.GEN_HVAC && device?.online === '1') {
    return true;
  }
  return false;
};

export const isModelEditable = (device: DiscoveryTableData) => {
  return isEditable(device);
};
