import * as React from 'react';
import { CommandBar, Tabs } from '@danfoss/etui-core';
import { ConfigurationTabItem } from '@danfoss/etui-sm-xml';

export type DeviceConfigurationBaseHeaderProps = {
  activeTabId: string;
  tabs: ConfigurationTabItem[];
  onTabChange: (tabId: string) => void;
};

function DeviceConfigurationBaseHeader({
  activeTabId,
  tabs = [],
  onTabChange,
}: DeviceConfigurationBaseHeaderProps) {
  return (
    <CommandBar
      items={[
        {
          key: 'deviceTabs',
          onRender: i => (
            <Tabs key={i.key} onChange={onTabChange} value={activeTabId}>
              {tabs.map(item => (
                <Tabs.Tab key={item.id} tab={item.label} value={item.id} />
              ))}
            </Tabs>
          ),
        },
      ]}
      styles={{
        leftSide: { maxWidth: ['100%'], width: '100%' },
      }}
    />
  );
}

export { DeviceConfigurationBaseHeader };
