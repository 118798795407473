import { styled } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';

export const ProxyTextContainer = styled(Div)`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.xxlg}px;
`;
