import * as React from 'react';
import { Modal } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { Unit } from '@danfoss/etui-sm-xml';
import { useAuth, useModal, useXmlResource } from '@danfoss/etui-sm';
import { WizardConfirmationModal } from '../WizardConfirmationModal';
import { useRefrigCopy } from './context';
import { RefrigCopyModalActions } from './RefrigCopyModalActions';
import { RefrigCopyContent } from './components/RefrigCopyContent';
import { sendBdCommands } from './actions';

export interface RefrigCopyModalProps {
  isOpen: boolean;
  onClose: () => void;
  unit: Unit;
  isHvac?: boolean;
}

function RefrigCopyInnerModal({
  isOpen,
  onClose,
  unit,
  isHvac,
}: RefrigCopyModalProps) {
  const { t } = useTranslation();
  const { setUnit, setIsCopyControllerSelected, setIsHvac } = useRefrigCopy();
  const { url } = useXmlResource();
  const { user } = useAuth();
  const handleOnOkClick = () => {
    sendBdCommands(url, user);
    onClose();
    setIsCopyControllerSelected(true);
  };

  const [showWizardCloseModal] = useModal(WizardConfirmationModal, {
    handleOnOkClick,
    title: t('t3382'),
    text: t('t3383'),
    subText: t('t3380'),
  });

  React.useEffect(() => {
    if (unit) {
      setUnit(unit);
      setIsHvac(isHvac);
    }
  }, [unit]);

  const handleOnClose = () => {
    showWizardCloseModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      header={
        <Modal.Header
          title={t('t486')}
          description={isHvac ? t('t87') : t('t150')}
        />
      }
      actions={
        <RefrigCopyModalActions
          onCancel={handleOnClose}
          handleOnFinish={onClose}
        />
      }
      style={{
        content: {
          width: '100%',
          height: '100%',
          maxWidth: '100%',
        },
        overlay: {},
      }}
      shouldCloseOnOverlayClick={false}
    >
      <RefrigCopyContent onClose={onClose} />
    </Modal>
  );
}

export { RefrigCopyInnerModal };
