import { setProxyConfig, resetProxyConfig } from '@danfoss/etui-sm-xml';
import { STORAGE_PROXY_KEY } from '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';

function setXmlProxyConfig(proxyConfig) {
  if (proxyConfig && proxyConfig.proxyAddress) {
    sessionStorage.setItem(STORAGE_PROXY_KEY, JSON.stringify(proxyConfig));
    setProxyConfig(proxyConfig);
  } else {
    resetProxyConfig();
  }
}

export { setXmlProxyConfig };
