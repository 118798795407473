import './get-array.js';
import { getNormalizedDeviceDetails } from './get-normalized-device-details.js';

function getParametersFromDeviceDetails(device) {
  const deviceDetailsToNormalize = getNormalizedDeviceDetails(device);
  if (deviceDetailsToNormalize) {
    return deviceDetailsToNormalize.map(deviceDetail => ({
      tag: deviceDetail.tag,
      value: deviceDetail.value
    }));
  }
  return [];
}

export { getParametersFromDeviceDetails };
