export function getInitialDateTime(item): [Date, string] {
  const [, itemTime] = item.value.split(' ');
  const hourFormat = item.tf === '1' ? '24' : '12';
  const rawDate = new Date(parseInt(item.iVal, 10) * 1e3);
  const msGmt =
    (rawDate.getTime() / 1000 + rawDate.getTimezoneOffset() * 60) * 1e3;
  const date = new Date(msGmt);
  const time = hourFormat === '12' ? itemTime.replace(/^0/, '') : itemTime;

  return [date, time];
}
