import {
  CALENDAR_VIEW_FORMATS,
  XML_DEVICE_NODETYPE,
} from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import { getArray } from 'utils';
import {
  Aux_Heats,
  Cooling_Stages,
  DehumidHvacSchedule,
  Heat_Reclaims,
  HvacSchedule,
  HvacUnitScheduleRes,
  ScheduleConstant,
  ScheduleDetail,
  ScheduleItem,
  ScheduleRes,
} from '../SchedulesPage.types';
import { convertTimeFormats } from './convertTimeFormat';
import { getDayString } from './getDayString';
import { getGenSchedulesData } from './getGenSchedulesData';
import { getTimeStrFromHHMM } from './getTimeStrFromHHMM';

const getDayMinuteIO = (time: number): number => {
  const hr: number = Math.floor(time / 100);
  const mn: number = time % 100;
  return hr * 60 + mn;
};

const getShutdownSched = (device: ScheduleItem, timeFormat: '0' | '1') => {
  return device?.devices
    .filter(item => item.shutdown_schedules)
    .map(item => {
      const count: number = Number(item.shutdown_sched_cnt);
      const shutdownSched = [];
      // more than 1 schedule
      if (count > 1) {
        for (let i = 0; i < count; i++) {
          const scheduleDetail: ScheduleDetail = {
            count,
            defr_interval:
              getDayMinuteIO(Number(item.shutdown_schedules?.stop[i])) -
              getDayMinuteIO(Number(item.shutdown_schedules?.start[i])),
            on_time: getTimeStrFromHHMM(
              item.shutdown_schedules?.start[i],
              0,
              timeFormat,
            ),
            off_time: getTimeStrFromHHMM(
              item.shutdown_schedules?.stop[i],
              0,
              timeFormat,
            ),
            weekdays: getDayString(
              Number(item.shutdown_schedules?.days[i]),
              false,
            ),
            holidays: getDayString(
              Number(item.shutdown_schedules?.hdays[i]),
              true,
            ),
          };
          shutdownSched.push(scheduleDetail);
        }
      } else {
        shutdownSched.push({
          count,
          defr_interval:
            getDayMinuteIO(Number(item.shutdown_schedules?.stop)) -
            getDayMinuteIO(Number(item.shutdown_schedules?.start)),
          on_time: getTimeStrFromHHMM(
            item.shutdown_schedules?.start as string,
            0,
            timeFormat,
          ),
          off_time: getTimeStrFromHHMM(
            item.shutdown_schedules?.stop as string,
            0,
            timeFormat,
          ),
          weekdays: getDayString(Number(item.shutdown_schedules?.days), false),
          holidays: getDayString(Number(item.shutdown_schedules?.hdays), true),
        });
      }

      return { ...item, shutdownSchedules: shutdownSched };
    });
};

const getDefrostSched = (device: ScheduleItem, timeFormat: '0' | '1') => {
  return device?.devices
    .filter(item => item.schedules)
    .map(item => {
      const count: number = Number(item.schedules.num_scheds);
      const shutdownSched = [];
      if (count > 1) {
        for (let i = 0; i < count; i++) {
          const scheduleDetail: ScheduleDetail = {
            count,
            type: item.defrost_type,
            on_time: getTimeStrFromHHMM(
              item.schedules?.start[i],
              0,
              timeFormat,
            ),
            defr_interval: item.interval,
            weekdays: getDayString(Number(item.schedules?.defr_days[i]), false),
            holidays: getDayString(Number(item.schedules?.defr_hdays[i]), true),
          };
          shutdownSched.push(scheduleDetail);
        }
      } else {
        shutdownSched.push({
          count,
          type: item.defrost_type,
          on_time: getTimeStrFromHHMM(
            item.schedules?.start as string,
            0,
            timeFormat,
          ),
          defr_interval: item.interval,
          weekdays: getDayString(Number(item.schedules?.defr_days), false),
          holidays: getDayString(Number(item.schedules?.defr_hdays), true),
        });
      }
      return { ...item, defrostSchedules: shutdownSched };
    });
};

const getSchedulesData = (
  device: ScheduleItem,
  schedules: ScheduleRes,
  timeFormat: '0' | '1',
) => {
  let data = [];
  if (
    device?.nodetype === XML_DEVICE_NODETYPE.NODETYPE_GN ||
    device.id.startsWith('node_')
  ) {
    data = [...data, ...getGenSchedulesData(device, schedules)];
  }

  switch (device?.schedtype) {
    case ScheduleConstant.SCHED_VIEW_SHUTDOWN:
      return [...data, ...getShutdownSched(device, timeFormat)];
    case ScheduleConstant.SCHED_VIEW_DEFROST:
      return [...data, ...getDefrostSched(device, timeFormat)];
    case ScheduleConstant.SCHED_VIEW_NIGHTSB:
    case ScheduleConstant.SCHED_VIEW_CASE_LIGHTS:
    default:
      return data;
  }
};

const getHvacSchedules = (
  hvacSchedule: HvacUnitScheduleRes,
  t: TFunction,
  timeFormat: string,
): HvacSchedule[] => {
  const hvacSchedules: HvacSchedule[] = [];
  const { cooling_stages, heat_reclaims, aux_heats, dehumidification } =
    hvacSchedule;
  updateCoolingStageHvacSchedules(cooling_stages, hvacSchedules, t);
  updateHeatReclaimHvacSchedules(heat_reclaims, hvacSchedules, t);
  updateAuxHeatHvacSchedules(aux_heats, hvacSchedules, t);
  updateDehumidifyHvacScheules(dehumidification, hvacSchedules, t, timeFormat);
  return hvacSchedules.flat();
};

const updateCoolingStageHvacSchedules = (
  cooling_stages: Cooling_Stages,
  hvacSchedules: HvacSchedule[],
  t: TFunction,
) => {
  return getArray(cooling_stages?.nightsetback?.schedule)?.forEach(
    hvacCoolingSchedule => {
      const updatedHvacCoolingSchedule = {
        ...hvacCoolingSchedule,
        name: t('t3331'),
        usage: t('t3333'),
      };
      hvacSchedules.push(updatedHvacCoolingSchedule);
    },
  );
};

const updateHeatReclaimHvacSchedules = (
  heat_reclaims: Heat_Reclaims,
  hvacSchedules: HvacSchedule[],
  t: TFunction,
) => {
  return getArray(heat_reclaims?.nightsetback?.schedule)?.forEach(
    hvacHeatingSchedule => {
      const updatedHvacHeatingSchedule = {
        ...hvacHeatingSchedule,
        name: t('t3332'),
        usage: t('t3334'),
      };
      hvacSchedules.push(updatedHvacHeatingSchedule);
    },
  );
};

const updateAuxHeatHvacSchedules = (
  aux_heats: Aux_Heats,
  hvacSchedules: HvacSchedule[],
  t: TFunction,
) => {
  return getArray(aux_heats?.nightsetback?.schedule)?.forEach(
    auxHeatSchedule => {
      const updatedAuxHeatSchedule = {
        ...auxHeatSchedule,
        name: t('t3335'),
        usage: t('t3334'),
      };
      hvacSchedules.push(updatedAuxHeatSchedule);
    },
  );
};

const updateDehumidifyHvacScheules = (
  dehumidification: DehumidHvacSchedule,
  hvacSchedules: HvacSchedule[],
  t: TFunction,
  timeFormat: string,
) => {
  return getArray(
    dehumidification?.dehumidify?.nightsetback?.schedules?.list?.schedule,
  ).forEach((dehumidSchedule, index) => {
    const scheduleIndex = index + 1;
    const hvacSchedule: HvacSchedule = {
      index: scheduleIndex.toString(),
      days: dehumidSchedule?.days?._,
      holidays: dehumidSchedule?.holidays?._,
      start: convertTimeFormats(
        CALENDAR_VIEW_FORMATS.FORMAT_24,
        timeFormat,
        dehumidSchedule?.start?._?.padStart(4, '0'),
      ),
      stop: convertTimeFormats(
        CALENDAR_VIEW_FORMATS.FORMAT_24,
        timeFormat,
        dehumidSchedule?.stop?._?.padStart(4, '0'),
      ),
      type: dehumidSchedule?.type_display?._,
      name: t('t3336'),
      usage: dehumidification?.dehumidify?.type_display?._,
    };
    hvacSchedules.push(hvacSchedule);
  });
};
export { getSchedulesData, getHvacSchedules };
