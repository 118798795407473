import * as React from 'react';
import _get from 'lodash.get';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { Icon, IconGlyph, icons } from '@danfoss/etui-core';
import {
  checkForAccessRights,
  useAuth,
  useUnit,
  useUpdateVersions,
} from '@danfoss/etui-sm';
import { DataItem } from '../DataItem';

interface UnitStatusInfoProps {
  currentUnit: any;
  updateType: any;
  showUpdateNotifications: boolean;
  showUpdateNotificationText: boolean;
}
export function UnitStatusInfo({
  currentUnit,
  updateType,
  showUpdateNotifications = false,
  showUpdateNotificationText = false,
}: UnitStatusInfoProps) {
  const { getFirstValidUrl } = useUnit();
  const url = getFirstValidUrl(currentUnit);

  const { user } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation();
  const COMPUND_INDEX = 0;
  const { isFetchingCurrent, latestComparison } = useUpdateVersions(
    updateType,
    url,
    user,
  );
  const isSystemUser = checkForAccessRights(user?.authtype, COMPUND_INDEX);
  const isSpk = updateType === 'SPK';
  const updateTypePretty = isSpk ? 'SW' : '';

  let currentLabel = t('t3077', { updateType: updateTypePretty });
  currentLabel =
    latestComparison === 'larger' && showUpdateNotificationText
      ? `${currentLabel} (${t('t3072')})`
      : currentLabel;

  const icon: { icon: IconGlyph; color: string } =
    latestComparison === 'larger'
      ? { icon: icons.WARNING, color: theme.palette.warning.main }
      : latestComparison === 'equal'
      ? { icon: icons.CHECK_CIRCLE, color: theme.palette.success.main }
      : latestComparison === 'lower'
      ? { icon: icons.CHECK_CIRCLE, color: theme.palette.success.main }
      : null;

  const getWarningText = (text: string) => {
    const textArr = text.split(':');
    return textArr[0];
  };

  return (
    <Div testId="unit-status-info" mb={theme.spacing.md}>
      {showUpdateNotifications ? (
        <Div display="flex" flexDirection="row">
          <Icon
            size={24}
            styles={{
              root: {
                color: icon?.color,
                mr: `${theme.spacing.xs}px`,
              },
            }}
            glyph={icon?.icon}
          />
          <Div flex="1">
            <DataItem
              isLoading={isSystemUser && isFetchingCurrent}
              label={
                latestComparison === 'larger' || !isSystemUser
                  ? getWarningText(t('t225'))
                  : t('t564')
              }
              value=" "
              warning={!isSystemUser ? t('t3483') : null}
            />
          </Div>
        </Div>
      ) : null}
    </Div>
  );
}
