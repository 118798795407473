import React from 'react';
import { Div, Span } from '@danfoss/etui-system-elements';
import { Button, icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';

export interface HVACFinalAddDeviceProps {
  handleOnAddDevice: () => void;
}
export const HVACFinalAddDevice = ({
  handleOnAddDevice,
}: HVACFinalAddDeviceProps) => {
  const { t } = useTranslation();
  return (
    <Div
      style={{
        display: 'inline-block',
      }}
      testId="hvac-add-device-div"
    >
      <Span>
        <Button
          iconOnly={true}
          iconProps={{ glyph: icons.ADD, isIconLabel: true }}
          onClick={handleOnAddDevice}
        />
      </Span>
      <Span>{t('t3374')}</Span>
    </Div>
  );
};
