import {
  ConfigurationTabItem,
  XML_REFRIG_LAYOUT_TAB_INDEX,
} from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import { RefrigSteps } from '../modules/RefrigLayoutModal/types/DiscoveryPage.types';

export const getRefrigLayoutSteps = (
  skippi200: boolean,
  tabs: ConfigurationTabItem[],
  t: TFunction,
): RefrigSteps[] => {
  if (skippi200 && tabs) {
    tabs = tabs?.filter(tab => tab.index !== XML_REFRIG_LAYOUT_TAB_INDEX.PI200);
  }

  tabs = tabs?.filter(
    tab =>
      tab?.index !== XML_REFRIG_LAYOUT_TAB_INDEX.REFRIGERATION &&
      tab?.index !== XML_REFRIG_LAYOUT_TAB_INDEX.SUMMARY,
  );

  return tabs?.map((step, index) => {
    return {
      title:
        step.index === XML_REFRIG_LAYOUT_TAB_INDEX.COMPRESSORS
          ? `${step?.label}/Packs`
          : step.index === XML_REFRIG_LAYOUT_TAB_INDEX.SUCTION_GROUPS
          ? t('t519')
          : step.label,
      stepNumber: index + 1,
    };
  });
};
