import 'react';
import 'featurehub-javascript-client-sdk';
import './getUserDeviceType.js';
import './getUserPlatform.js';
import './createLocalClientContext.js';
import { createFeatureHubContext, createFeatureHubProvider } from './FeatureHubGenericContext.js';
import { createUseFeatureHubHook } from './useFeatureHubFactory.js';
import { createUseFeatureFlagHook } from './useFeatureFlagFactory.js';

function createFeatureHubInstance(input) {
  const FeatureHubContext = createFeatureHubContext();
  const FeatureHubProvider = createFeatureHubProvider(FeatureHubContext, input);
  const useFeatureHub = createUseFeatureHubHook(FeatureHubContext);
  const useFeatureFlag = createUseFeatureFlagHook(FeatureHubContext);
  return {
    FeatureHubProvider,
    FeatureHubContext,
    useFeatureHub,
    useFeatureFlag
  };
}

export { createFeatureHubInstance };
