import { TreeItem } from '@danfoss/etui-core';

const getNestedItemById = (
  itemsTree: TreeItem[],
  targetId: string,
): TreeItem | undefined => {
  let selectedItem: TreeItem = null;
  if (itemsTree?.length) {
    itemsTree?.every(item => {
      if (item?.id === targetId) {
        selectedItem = item;
      } else {
        selectedItem = item?.items?.find(subItem => subItem?.id === targetId);
        if (!selectedItem) {
          selectedItem = getNestedItemById(item?.items, targetId);
        }
      }
      if (selectedItem) return false;
      return true;
    });
  }
  return selectedItem;
};

export { getNestedItemById };
