import { DiscoveryTableData } from '../types/DiscoveryPage.types';

export const getDeviceIndex = (discoveryTableData: DiscoveryTableData[]) => {
  let i: number = 0;
  discoveryTableData?.forEach(device => {
    if (i < device?.deviceId) {
      i = device?.deviceId;
    }
  });
  return i;
};
