import { Device, XML_DEVICE_STR } from '@danfoss/etui-sm-xml';

const networkNodePointTypes: XML_DEVICE_STR[] = [
  XML_DEVICE_STR.STR_TYPE_ALL_RO,
  XML_DEVICE_STR.STR_TYPE_ALL_OI,
  XML_DEVICE_STR.STR_TYPE_ALL_SI,
  XML_DEVICE_STR.STR_TYPE_ALL_VO,
];

const networkNodePointType_tableAddress: string = '20071';

export function isDeviceNetworkNodePointTypes(devices: Device[]): boolean {
  return devices.some(device => networkNodePointTypes.includes(device.type));
}

export function isNetworkNodePointTypesTable(activeMenuItem: string): boolean {
  return activeMenuItem.split('-')[0] === networkNodePointType_tableAddress;
}
