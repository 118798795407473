function getArrayItemsIntersectionByField<T>(
  array1: T[],
  array2: T[],
  field: keyof T,
): boolean {
  if (!array1?.length || !array2?.length) return false;
  return array1.every(item1 =>
    array2.some(item2 => item2[field] === item1[field]),
  );
}

export { getArrayItemsIntersectionByField };
