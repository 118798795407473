import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { LayoutFlowTitle } from '../../../RefrigLayoutModal/components/LayoutContent/LayoutFlowTitle';
import { AlarmsFlowTable } from './AlarmsFlowTable';

export interface AlarmsFlowPageProps {
  handleOnSelect: (record: ConfigurationListItem) => void;
}

export const AlarmsFlowPage = ({ handleOnSelect }: AlarmsFlowPageProps) => {
  const { t } = useTranslation();
  return (
    <Div testId="alarms-flow-page-div" mt="10px">
      <LayoutFlowTitle
        title={t('t27')}
        subTitle={t('t3445')}
        glyph={icons.ALARM_URGENT}
      />
      <AlarmsFlowTable handleOnSelect={handleOnSelect} />
    </Div>
  );
};
