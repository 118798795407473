import { Device } from '@danfoss/etui-sm-xml';

function getBpidx(device: Device) {
  const stringBpidx = device.bpidx || '0';
  const bpidx = parseInt(stringBpidx, 10);

  if (bpidx > 0) {
    return bpidx - 1;
  }

  return bpidx;
}

export { getBpidx };
