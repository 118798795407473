import { styled } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { danfoss } from '@danfoss/etui-colors';

export const PreWrapper = styled(Div)`
  margin-right: ${({ theme }) => theme.spacing.sm}px;

  pre {
    font-weight: 400;
    color: ${danfoss.blueGrey[100]};
    margin: 0;
  }
`;
