import * as React from 'react';
import {
  ChartComponent,
  DateTime,
  Inject,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
  Zoom,
  AxisDirective,
  AxesDirective,
  DataLabel,
  RowDirective,
  RowsDirective,
  MultiLevelLabel,
  StepLineSeries,
  ZoomMode,
  Export,
  FontModel,
  MajorGridLinesModel,
  BorderModel,
} from '@syncfusion/ej2-react-charts';
import { useTranslation } from 'react-i18next';
import { defaultTheme } from '@danfoss/etui-themes';
import { chartBorderColor } from 'styles';
import { Measurement } from '@danfoss/etui-sm';
import { AxisType, ChartData, LegendMap } from '../HistoryPage.types';
import {
  formatHistoryAxisLabel,
  getAxisTitle,
  getChartLabelByType,
  getChartLabelFormat,
  getUnitType,
  group,
} from '../utils/history-utils';

const services = [
  LineSeries,
  StepLineSeries,
  Tooltip,
  DataLabel,
  DateTime,
  Zoom,
  MultiLevelLabel,
  Export,
];

const basicLineProps = {
  width: 2,
  animation: { enable: false },
  yName: 'dataValue',
};

const zoomSettings = {
  enableSelectionZooming: true,
  mode: 'X' as ZoomMode,
  enableScrollbar: true,
};

const tooltipSettings = {
  enable: true,
  enableAnimation: false,
  textStyle: {
    color: defaultTheme.palette.common.white,
    fontFamily: defaultTheme.typography.fontFamily,
  },
};

const textStyle: FontModel = {
  color: defaultTheme.palette.text.primary,
  fontFamily: defaultTheme.typography.fontFamily,
};

const titleStyle: FontModel = {
  ...textStyle,
  size: defaultTheme.typography.fontSizeSmall,
};

const borderStyle = { width: 0.5, color: chartBorderColor };

const primaryAxisStyle = {
  titleStyle,
  labelStyle: textStyle,
  majorGridLines: borderStyle as MajorGridLinesModel,
  majorTickLines: { color: chartBorderColor },
  lineStyle: { color: chartBorderColor },
};

const binaryChartHeight = 100;
const chartHeight = 300;

interface Props {
  data: ChartData[];
  dateTimeFormat: string;
  appUnitFormats: Pick<Measurement, 'pressure' | 'temperature' | 'light'>;
  legendMap: LegendMap;
  EmptyView: any;
  id?: string;
}

export const OldHistoryCharts = React.memo(
  React.forwardRef(
    (
      {
        data,
        dateTimeFormat,
        appUnitFormats,
        legendMap,
        EmptyView,
        id: chartId,
      }: Props,
      ref,
    ) => {
      const chartRef = React.useRef(null);
      const { t } = useTranslation();
      const { binary: binaryCharts, ...charts } = React.useMemo(
        () =>
          group<ChartData>(
            data.filter(({ param: { id } }) => !legendMap[id]?.isHidden),
            getUnitType,
          ),
        [data, legendMap],
      );

      const numOfBinaryCharts = binaryCharts?.length || 0;
      const availableChartTypes = Object.keys(charts);
      const numOfCharts = availableChartTypes.length;

      React.useImperativeHandle(ref, () => ({
        exportPng: () =>
          chartRef.current?.exportModule?.export('PNG', 'history-chart'),
      }));

      if (!numOfBinaryCharts && !numOfCharts) {
        return EmptyView;
      }

      return (
        <ChartComponent
          id={`charts${chartId}`}
          ref={chartRef}
          primaryXAxis={{
            valueType: 'DateTime',
            labelFormat: formatHistoryAxisLabel(dateTimeFormat),
            labelIntersectAction: 'Hide',
            desiredIntervals: 10,
            title:
              data && data[0].data[0] ? getAxisTitle(data[0].data[0].time) : '',
            ...primaryAxisStyle,
          }}
          width="100%"
          height={`${
            numOfBinaryCharts * binaryChartHeight + numOfCharts * chartHeight
          }px`}
          zoomSettings={zoomSettings}
          tooltip={tooltipSettings}
          axisLabelRender={args => {
            // @ts-ignore
            if (args.axis.properties.name.startsWith('binary')) {
              args.text = args.text === '0' ? t('t63') : t('t64');
            }
          }}
          tooltipRender={args => {
            // @ts-ignore
            if (args.series.properties.yAxisName?.startsWith('binary')) {
              const [x, y] = args.text.split(' : ');
              const replaceText = y.includes('1') ? t('t64') : t('t63');
              args.text = `${x} ${getAxisTitle(
                data[0].data[0].time,
              )}  : ${y.replace(/\d/, replaceText)}`;
            } else {
              const [x, y] = args.text.split(' : ');
              args.text = `${x} ${getAxisTitle(data[0].data[0].time)}  : ${y}`;
            }
          }}
          chartArea={{
            border: { color: chartBorderColor },
          }}
        >
          <Inject services={services} />
          <RowsDirective>
            {binaryCharts?.map(({ param: { id } }) => (
              <RowDirective
                key={id}
                height={`${binaryChartHeight}px`}
                border={borderStyle as BorderModel}
              />
            ))}
            {availableChartTypes.map(type => (
              <RowDirective
                key={type}
                height={`${chartHeight}px`}
                border={borderStyle as BorderModel}
              />
            ))}
          </RowsDirective>
          <AxesDirective>
            {binaryCharts?.map(({ param: { id } }, i) => (
              <AxisDirective
                key={id}
                rowIndex={i}
                name={`binary-${id}`}
                interval={1}
                maximum={1.2}
                title={t('t42')}
                {...primaryAxisStyle}
              />
            ))}
            {availableChartTypes.map((type, i) => (
              <AxisDirective
                key={type}
                rowIndex={numOfBinaryCharts + i}
                name={type}
                title={t(getChartLabelByType(type as AxisType))}
                labelFormat={getChartLabelFormat(
                  type as AxisType,
                  appUnitFormats,
                )}
                edgeLabelPlacement="Hide"
                rangePadding="Round"
                {...primaryAxisStyle}
              />
            ))}
          </AxesDirective>
          <SeriesCollectionDirective>
            {availableChartTypes.map(type =>
              charts[type]?.map(({ data: chartData, param }) => (
                <SeriesDirective
                  {...basicLineProps}
                  key={param.id}
                  dataSource={chartData}
                  xName="time"
                  type="Line"
                  name={param.name}
                  yAxisName={type}
                  fill={legendMap[param.id].color}
                  border={borderStyle as BorderModel}
                />
              )),
            )}
            {binaryCharts?.map(({ data: chartData, param }) => (
              <SeriesDirective
                {...basicLineProps}
                key={param.id}
                dataSource={chartData}
                xName="time"
                name={param.name}
                yAxisName={`binary-${param.id}`}
                type="StepLine"
                fill={legendMap[param.id].color}
                border={borderStyle as BorderModel}
              />
            ))}
          </SeriesCollectionDirective>
        </ChartComponent>
      );
    },
  ),
);
