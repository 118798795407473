import { REGEX_PATTERNS } from '../../constants/regex-patterns-constant.js';

/**
 * Validates ip
 *
 * @param {string} ip
 * @returns {boolean}
 */
function getIsValidIp(ip) {
  return REGEX_PATTERNS.IP.test(ip);
}

export { getIsValidIp };
