import { flattenArrayByKey } from '@danfoss/etui-sm/utils';
import { DeviceGrouped } from '@danfoss/etui-sm/types';

function getSelectedDeviceById(deviceId: string, devices: DeviceGrouped[]) {
  const flattenedDevices: DeviceGrouped[] = [];
  if (deviceId && devices && devices.length > 0) {
    flattenArrayByKey(devices, 'items', flattenedDevices);
  }

  if (flattenedDevices.length !== 0 && deviceId) {
    return flattenedDevices.find(device => device.id === deviceId);
  }
  return null;
}

export { getSelectedDeviceById };
