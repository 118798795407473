import { Device } from '@danfoss/etui-sm-xml';

function getBpidx(device: Device) {
  if (!device?.bpidx) return '0';

  const bpidx = parseInt(device.bpidx, 10);
  return bpidx > 0 ? bpidx - 1 : bpidx;
}

export { getBpidx };
