import {
  ContentError,
  ContentSpinner,
  Page as CorePage,
  PageProps,
} from '@danfoss/etui-sm';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export function Page({
  children,
  isBusy,
  isError,
  isReady,
  ...pageProps
}: {
  isBusy?: boolean;
  isError?: boolean;
  isReady?: boolean;
} & PageProps) {
  const { t } = useTranslation();
  return (
    <CorePage {...pageProps}>
      {isBusy && !isError && !isReady && <ContentSpinner size={1} />}
      {isError && (
        <ContentError
          title={t('t951')}
          message={t('t959')}
          onRetryClick={window.location.reload}
        />
      )}
      {isReady && !isError && !isBusy ? children : null}
    </CorePage>
  );
}
