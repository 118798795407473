// This is pretty much a dummy wrapper
// intended to work only with SwitchWithGuard,
// where all the magic happens
export function RouteGuard<
  T extends { [key: string]: any } = Record<string, any>,
>({
  children,
}: {
  children: React.ReactNode;
  guard: (args: T) => boolean;
} & T) {
  return children as JSX.Element;
}
