import { fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function fetchReadUnits(url, user) {
  return fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_UNITS,
      storeview_only: '1',
      version: 'C'
    }
  });
}

export { fetchReadUnits };
