export const FORM_TEST_ID_PREFIX = 'form-';
const ID_WITHOUT_PREFIX_COUNT = 5;

export const checkIfOtherItemClicked = (element: HTMLElement): string => {
  let currentElement = element;

  while (currentElement) {
    const elementId = currentElement.dataset.testid;
    if (elementId?.startsWith(FORM_TEST_ID_PREFIX))
      return elementId.slice(ID_WITHOUT_PREFIX_COUNT);
    currentElement = currentElement.parentElement;
  }

  return '';
};
