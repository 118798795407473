import * as React from 'react';
import { ConfigurationCustomActionsContext } from './ConfigurationCustomActionsContext';

function useConfigurationCustomActions() {
  const context = React.useContext(ConfigurationCustomActionsContext);
  if (!context) {
    throw new Error(
      `useConfigurationCustomActions must be used within a ConfigurationCustomActionsProvider`,
    );
  }
  return context;
}

export { useConfigurationCustomActions };
