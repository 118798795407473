import { Span } from '@danfoss/etui-system-elements';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const MINUTES_PER_HOUR = 60;
const TO_REVERT_MATH_SIGN = -1;
const MAX_ONE_DIGIT_NUMBER = 9;
const LENGTH_OF_A_TWO_DIGIT_NUM_WITH_SIGN = 3;
const LENGTH_OF_A_ONE_DIGIT_NUM_WITH_SIGN = 2;

const useUpdateTimeMessage = (): {
  message: () => JSX.Element;
} => {
  const { t } = useTranslation();

  const message = React.useCallback(() => {
    const timezoneOffset =
      (new Date().getTimezoneOffset() / MINUTES_PER_HOUR) * TO_REVERT_MATH_SIGN;
    const formatedOffset = `${timezoneOffset}`.padStart(
      Math.abs(timezoneOffset) > MAX_ONE_DIGIT_NUMBER
        ? LENGTH_OF_A_TWO_DIGIT_NUM_WITH_SIGN
        : LENGTH_OF_A_ONE_DIGIT_NUM_WITH_SIGN,
      '+',
    );

    return (
      <Span>
        {t('t3433')} <br />
        {t('t3434')}: {formatedOffset}
      </Span>
    );
  }, []);

  return { message };
};

export { useUpdateTimeMessage };
