export function withErrorHandler<F extends (...args: any) => any>(
  fn: F,
  cb: (e: Error) => void,
  cfg: { shouldPropagate?: boolean; returns?: any } = {
    shouldPropagate: false,
  },
) {
  let res: ReturnType<typeof fn>;

  return async function asyncWithErrorHandler(
    ...args: any
  ): Promise<ReturnType<typeof fn>> {
    try {
      res = await fn(...args);
    } catch (err) {
      cb(err);
      if (cfg.shouldPropagate) {
        throw err;
      }
      if (cfg.returns) {
        return cfg.returns;
      }
    }

    return res;
  } as F;
}
