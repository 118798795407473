import * as React from 'react';
import {
  Route,
  RouteProps,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '@danfoss/etui-sm/context';
import { shouldShowSecurityCompliancePopup } from '@danfoss/etui-sm-xml';
import { routePaths } from 'routePaths';
import { AppLayoutProps } from '../AppLayout';

interface AppRouteProps extends RouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  layout: React.ComponentClass<any>;
  layoutProps?: AppLayoutProps;
}

const AppRoute: React.FC<AppRouteProps> = ({
  component: Component,
  layout: Layout,
  layoutProps,
  render,
  ...rest
}) => {
  const { user } = useAuth();

  if (user && shouldShowSecurityCompliancePopup()) {
    return (
      <Redirect
        to={{
          pathname: routePaths.pages.securityCompliance,
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout {...layoutProps} {...props}>
          {user ? (
            Component ? (
              <Component {...props} />
            ) : (
              render && render(props)
            )
          ) : (
            <Redirect
              to={{
                pathname: routePaths.pages.login,
                state: {
                  prevPathName: props.location.pathname,
                  prevSearch: props.location.search,
                },
              }}
            />
          )}
        </Layout>
      )}
    />
  );
};

export { AppRoute };
