import * as React from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import { ConfigurationListItem, Unit } from '@danfoss/etui-sm-xml';
import { ContainerDimensions } from '@danfoss/etui-core';
import { SaveFunc } from '../../../../../Configuration/components/ConfigurationItemContentList/ConfigurationListItemEdit';
import {
  EditType,
  getEditType,
  getHtmlId,
  getIsEditableByItem,
} from '../../../../../Configuration/components/ConfigurationItemContentList/utils';
import { ConfigurationListItem as ListItem } from '../../../../../Configuration/components/ConfigurationItemContentList/ConfigurationListItem';

interface CopyItemVirtualizedListProps {
  isUpdating?: boolean;
  list: ConfigurationListItem[] | any;
  unit?: Unit;
  tabIsEditable: boolean;
  onSave?: SaveFunc;
  itemRenderer?: (...args: any[]) => React.ReactNode;
  isCopyControllerSelected?: boolean;
  isCopyControllerLoading?: boolean;
  handleBrowse?: (file: File, item: ConfigurationListItem) => void;
  isCopyLoading?: boolean;
  isWizards?: boolean;
}

function CopyItemVirtualizedList({
  isUpdating,
  list,
  unit,
  tabIsEditable,
  onSave,
  itemRenderer,
  isCopyControllerSelected,
  isCopyControllerLoading,
  handleBrowse,
  isCopyLoading,
  isWizards,
}: CopyItemVirtualizedListProps) {
  const ROW_HEIGHT_MIN = 42;
  const ROW_HEIGHT_MAX = 50;

  const getItemHasActionEditType = (item: ConfigurationListItem) =>
    getEditType(item) === EditType.Action;

  const getItemRowHeight = (item: ConfigurationListItem) =>
    getItemHasActionEditType(item) ? ROW_HEIGHT_MAX : ROW_HEIGHT_MIN;

  const Row = ({ rowIndex, style, data = [] }): JSX.Element => {
    const item = data[rowIndex];
    const isLast = data.length === rowIndex + 1;
    const isItemEditable = tabIsEditable && getIsEditableByItem(item);
    return (
      <>
        <span style={style} id="span">
          <ListItem
            isSearchable={true}
            key={rowIndex}
            index={rowIndex}
            item={item}
            unit={unit}
            isLast={isLast}
            isEditable={isItemEditable}
            isAuthorized={true}
            isUpdating={isUpdating}
            htmlId={getHtmlId(item, rowIndex)}
            onSave={onSave}
            itemRenderer={itemRenderer}
            isCopyControllerSelected={isCopyControllerSelected}
            isCopyControllerLoading={isCopyControllerLoading}
            handleBrowse={handleBrowse}
            isCopyWizard={true}
            isCopyLoading={isCopyLoading}
            isWizards={isWizards}
          />
        </span>
      </>
    );
  };

  return (
    <ContainerDimensions>
      {({ height, width }) => (
        <>
          <Grid
            columnCount={1}
            columnWidth={() => '100%'}
            height={height * 0.75}
            rowCount={list?.length || 0}
            rowHeight={index => getItemRowHeight(list[index])}
            width={width}
            itemData={list}
          >
            {Row}
          </Grid>
        </>
      )}
    </ContainerDimensions>
  );
}

export { CopyItemVirtualizedList };
