import useSWR from 'swr';
import { ModalContext, useAuth } from '@danfoss/etui-sm';
import React from 'react';
import { readPollingSuspensionStatus } from '../../Configuration';
import { PollingStatusResponse } from '../../Configuration/components/ConfigurationItemContentList/types';

function useSuspendPollingStatus(
  url: string,
  skipSessionUpdate: boolean,
  showBanner: boolean,
) {
  const { user } = useAuth();
  const { isAnyModalOpen } = React.useContext(ModalContext);
  let pollingEnabled = false;
  const { data: suspendResult } = useSWR<PollingStatusResponse>(
    () =>
      user && showBanner && !isAnyModalOpen
        ? ['polling status', url, user, skipSessionUpdate]
        : null,
    ([_key, url, user, skipSessionUpdate]) =>
      readPollingSuspensionStatus([url, user, skipSessionUpdate]),
    {
      refreshInterval: 15000,
    },
  );

  pollingEnabled = Boolean(Number(suspendResult?.enabled));
  return pollingEnabled;
}

export { useSuspendPollingStatus };
