import * as React from 'react';
import { SelectInput } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import {
  ConfigurationTabItem,
  Unit,
  fetchDevicesByUnit,
  Device,
  XML_DEVICE_COMBO,
  XML_DEVICE_LIST,
  XML_DEVICE_STYPE,
  User,
} from '@danfoss/etui-sm-xml';
import { useTranslation } from 'react-i18next';
import { useConfiguration, useConfigurationMenu } from '../../context';
import { useFetchDevices } from '../../hooks/useFetchDevices';
import { getDevicesByCombo, getDeviceTypeByComboAndSType } from './utils';
import { areObjectsEqual } from './utils/areObjectsEqual';

export interface ConfigurationItemContentMenuDeviceSelectProps {
  menuId: string;
  unit: Unit;
  stype: ConfigurationTabItem['stype'];
  selectedDevice: Device;
  combo: ConfigurationTabItem['combo'];
  onDeviceChange: (device: Device) => void;
  selectedItem?: any;
  isModal?: boolean;
}

async function fetchDevicesByComboAndSType([
  sourceUrl,
  unit,
  combo,
  stype,
  user,
]: [string, Unit, XML_DEVICE_COMBO, XML_DEVICE_STYPE, User]) {
  if (combo === XML_DEVICE_COMBO.COMBO_GENERIC) {
    let refrigDevices = [];
    let hvacDevices = [];
    let miscDevices = [];
    let lightDevices = [];

    const refrig = await fetchDevicesByUnit(
      sourceUrl,
      unit,
      XML_DEVICE_LIST.REFRIG,
      user,
    );

    if (refrig) {
      refrigDevices = refrig.device
        ? Array.isArray(refrig.device)
          ? refrig.device
          : [refrig.device]
        : [];
    }

    const hvac = await fetchDevicesByUnit(
      sourceUrl,
      unit,
      XML_DEVICE_LIST.HVAC,
      user,
    );

    if (hvac) {
      hvacDevices = hvac.device
        ? Array.isArray(hvac.device)
          ? hvac.device
          : [hvac.device]
        : [];
    }

    const misc = await fetchDevicesByUnit(
      sourceUrl,
      unit,
      XML_DEVICE_LIST.MISC,
      user,
    );

    if (misc) {
      miscDevices = misc.device
        ? Array.isArray(misc.device)
          ? misc.device
          : [misc.device]
        : [];
    }

    const light = await fetchDevicesByUnit(
      sourceUrl,
      unit,
      XML_DEVICE_LIST.LIGHTS,
      user,
    );

    if (light) {
      lightDevices = light.device
        ? Array.isArray(light.device)
          ? light.device
          : [light.device]
        : [];
    }

    const mcxDevices = lightDevices.filter(
      lightDevice => lightDevice.combo === XML_DEVICE_COMBO.COMBO_MCX,
    );

    return [...refrigDevices, ...hvacDevices, ...miscDevices, ...mcxDevices];
  }

  const deviceType = getDeviceTypeByComboAndSType(combo, stype);

  const data = await fetchDevicesByUnit(sourceUrl, unit, deviceType, user);

  if (data && combo === XML_DEVICE_COMBO.COMBO_MCX) {
    return data.device.filter(
      lightDevice => lightDevice.combo === XML_DEVICE_COMBO.COMBO_MCX,
    );
  }

  if (data) {
    return data.device;
  }

  return [];
}

function ConfigurationItemContentMenuDeviceSelect({
  unit,
  combo,
  stype,
  menuId,
  onDeviceChange,
  selectedDevice = null,
  selectedItem = {},
  isModal = false,
}: ConfigurationItemContentMenuDeviceSelectProps) {
  const theme = useTheme();

  const { t } = useTranslation();

  const { onSetDevices, onSetDevice, onSetModalDevice } =
    useConfigurationMenu();
  const { onSetEditedField } = useConfiguration();

  const isSched = combo === XML_DEVICE_COMBO.COMBO_SCHEDS;
  const isSchedOrPointCalc = isSched || combo === XML_DEVICE_COMBO.COMBO_CALCS;
  const title = isSched ? t('t420') : t('t963');

  const { data } = useFetchDevices({
    unit,
    combo,
    stype,
  });

  const devices: Device[] = React.useMemo(
    () =>
      data
        ? getDevicesByCombo(
            Array.isArray(data) ? data.filter(Boolean) : [data],
            combo,
          )
        : [],
    [data, combo],
  );

  onSetDevices(menuId, devices);

  const getOptions = React.useCallback(() => {
    return devices.map((device, index) => ({
      label: device.name,
      value: `${index}`,
    }));
  }, [devices]);

  const options = getOptions();
  let selectedOption = null;

  const hasNoPreselectedDevice =
    !selectedDevice || !Object.keys(selectedDevice).length;

  const setFirstOption = () => {
    [selectedOption] = options;
    onSetDevice(devices[0]);
  };

  const findSelectedOption = () => {
    const selectedIndex = devices.findIndex(dev =>
      areObjectsEqual(dev, selectedDevice),
    );
    selectedOption = options[selectedIndex];
  };

  const findSelectedOptionForModal = () => {
    let newSelectedDevice = null;
    let selectedIndex = null;

    devices.forEach((device, index) => {
      if (isSchedOrPointCalc) {
        if (+device.comboindex === +selectedItem.combo) {
          newSelectedDevice = device;
          selectedIndex = index;
        }
      } else if (
        parseInt(device.bpidx, 10) === parseInt(selectedItem.bnum, 10)
      ) {
        newSelectedDevice = device;
        selectedIndex = index;
      }
    });

    return { newSelectedDevice, selectedIndex };
  };

  if (isModal) {
    const { newSelectedDevice, selectedIndex } = findSelectedOptionForModal();
    selectedOption = options[selectedIndex];
    onSetModalDevice(selectedItem.name + selectedItem.li, newSelectedDevice);
  } else {
    hasNoPreselectedDevice ? setFirstOption() : findSelectedOption();
  }

  const handleOnChange = item => {
    onSetEditedField('');
    const newDevice = devices[item.value];
    onDeviceChange(newDevice);
  };

  return !options.length ? null : (
    <SelectInput
      label={title}
      name="device"
      value={selectedOption}
      options={options}
      onChange={handleOnChange}
      styles={{
        root: {
          width: ['100%', '25%'],
          borderRight: `1px solid ${theme.palette.divider}`,
        },
        input: { borderColor: 'transparent', boxShadow: 'none' },
        menu: { borderColor: 'transparent', top: '100%' },
      }}
    />
  );
}

export {
  ConfigurationItemContentMenuDeviceSelect,
  fetchDevicesByComboAndSType,
};
