import { fetchXMLData, User, XML_ACTION } from '@danfoss/etui-sm-xml';
import { LocalGraphicsVizFileData } from '../types/LocalGraphics.types';

export const readVizFiles = async ([url, user]: [string, User]) => {
  const data: LocalGraphicsVizFileData = await fetchXMLData({
    url,
    attributes: {
      user: user?.user,
      password: user?.password,
      lang: user?.language,
      storeview_only: '1',
      version: 'C',
      action: XML_ACTION.READ_VIZ_FILE,
    },
  });
  return data;
};
