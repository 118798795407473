import { timeoutPromise } from '../utils/timeout-promise.js';
import '@danfoss/etui-sm-xml';
import '../_rollupPluginBabelHelpers-e1ae8e90.js';
import '@danfoss/etui-core';
import '../constants/software-versions.js';
import { UPDATE_TYPE } from '../constants/software-update-type.js';
import { compareSoftwareVersions } from '../utils/compare-software-versions.js';
import '../utils/get-version-from-filename.js';
import { fetchCurrentSoftware } from '../actions/fetch-current-software.js';
import '../fetch-latest-version-filename-d4771f3b.js';
import { e as extractVersion, f as fetchLatestVersion } from '../get-latest-os-version-779b9c8f.js';
import '../utils/get-is-misc-device.js';
import React from 'react';
import 'react-i18next';
import useSWR from 'swr';
import 'use-deep-compare-effect';
import { checkForAccessRights } from '../context/auth/utils/check-auth.js';
import '@danfoss/etui-sm-i18n';
import '../modules/SystemModule/components/SettingsSoftwareUpdateModal/context/SoftwareUpdateContext.js';
import '../modules/SystemModule/components/SettingsSoftwareUpdateModal/context/hooks/use-multi-unit-update.js';
import { u as useUpdateTypeValues } from '../use-update-type-values-02406e44.js';

/**
 * A custom hook which provides latest and current versions for a specific updateType
 *
 * @param updateType type of the update to check versions for
 * @param xmlUrl the url/ip of the unit to check versions against
 * @param user Credentials of the user which has acces to the unit
 * @param latestVersionTimeout Timeout of fetching online version
 * @returns An object containing the latest online version, and the current version of the unit at the specified xmlUrl
 */
function useUpdateVersions(updateType, xmlUrl, user, latestVersionTimeout) {
  var _extractVersion, _extractVersion2, _extractVersion3, _extractVersion4, _extractVersion5;
  if (latestVersionTimeout === void 0) {
    latestVersionTimeout = 2000;
  }
  const {
    latestFileUrl
  } = useUpdateTypeValues(updateType);
  const COMPUND_INDEX = 0;
  const fetchLatestWithTimeout = () => timeoutPromise(fetchLatestVersion(latestFileUrl, updateType), latestVersionTimeout, 'could not reach danfoss servers');
  const {
    data: latestVersion,
    error: latestFetchError
  } = useSWR(latestFileUrl, updateType !== UPDATE_TYPE.S55 && updateType !== UPDATE_TYPE.ED3 ? fetchLatestWithTimeout : null, {
    revalidateOnFocus: false,
    shouldRetryOnError: false
  });
  const isFetchingLatest = !latestVersion && !latestFetchError;
  const shouldRequest = updateType !== UPDATE_TYPE.S55 && updateType !== UPDATE_TYPE.ED3 && checkForAccessRights(user === null || user === void 0 ? void 0 : user.authtype, COMPUND_INDEX);
  const {
    data: currentVersionsFetched,
    error: currentFetchError
  } = useSWR(shouldRequest && [`fetchCurrentSoftware${xmlUrl}`, user, xmlUrl], fetchCurrentSoftware, {
    shouldRetryOnError: true
  });
  const isFetchingCurrent = !currentVersionsFetched && !currentFetchError;
  const usePackageVersion = compareSoftwareVersions('G09.000.141', (_extractVersion = extractVersion('sm_app', currentVersionsFetched)) === null || _extractVersion === void 0 ? void 0 : _extractVersion.version) === 'larger';
  const currentVersions = {
    softwareVersion: usePackageVersion && ((_extractVersion2 = extractVersion('SM800A-package', currentVersionsFetched)) === null || _extractVersion2 === void 0 ? void 0 : _extractVersion2.version) || ((_extractVersion3 = extractVersion('sm_app', currentVersionsFetched)) === null || _extractVersion3 === void 0 ? void 0 : _extractVersion3.version),
    danuxVersion: (_extractVersion4 = extractVersion('danux', currentVersionsFetched)) === null || _extractVersion4 === void 0 ? void 0 : _extractVersion4.version,
    edfVersion: (_extractVersion5 = extractVersion('EDF_Files_Update', currentVersionsFetched)) === null || _extractVersion5 === void 0 ? void 0 : _extractVersion5.version
  };
  const latestComparison = React.useMemo(() => {
    if (!latestVersion || !currentVersions) {
      return null;
    }
    if (updateType === UPDATE_TYPE.SPK && currentVersions.softwareVersion) {
      return compareSoftwareVersions(currentVersions.softwareVersion, latestVersion);
    }
    if (updateType === UPDATE_TYPE.LPK && currentVersions.danuxVersion) {
      return compareSoftwareVersions(currentVersions.danuxVersion, latestVersion);
    }
    return null;
  }, [latestVersion, currentVersions]);
  const getRollbackVersion = software => {
    var _currentVersionsFetch;
    return currentVersionsFetched === null || currentVersionsFetched === void 0 ? void 0 : (_currentVersionsFetch = currentVersionsFetched.find(v => v.software === software)) === null || _currentVersionsFetch === void 0 ? void 0 : _currentVersionsFetch.rollbackversion;
  };
  return {
    isFetchingLatest,
    latestVersion,
    latestFetchError,
    isFetchingCurrent,
    currentVersions,
    currentVersionsFetched,
    currentFetchError,
    latestComparison,
    getRollbackVersion
  };
}

export { useUpdateVersions };
