export const DAYS = ['S', 'M', 'T', 'W', 'R', 'F', 'A'];

const getWeekdayIndex = days =>
  DAYS.map((day, index) => (days.includes(day) ? index : '-'));

const getScheduleDayFromLetter = (
  daysOfTheWeek: string[],
  letter: string,
  index: number,
): string | null => {
  if (['-', ' '].some(value => value === letter)) return null;
  return daysOfTheWeek[index];
};

export { getScheduleDayFromLetter, getWeekdayIndex };
