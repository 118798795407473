import * as React from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import { ConfigurationListItem, Unit } from '@danfoss/etui-sm-xml';
import { ContainerDimensions } from '@danfoss/etui-core';
import { SaveFunc } from '../../../../../Configuration/components/ConfigurationItemContentList/ConfigurationListItemEdit';
import {
  EditType,
  getEditType,
  getHtmlId,
  getIsEditableByItem,
} from '../../../../../Configuration/components/ConfigurationItemContentList/utils';
import { ConfigurationListItem as ListItem } from '../../../../../Configuration/components/ConfigurationItemContentList/ConfigurationListItem';
import { PI200FlowContent } from '../PI200FlowContent';
import { useRefrigLayout } from '../../context';

interface LayoutItemVirtualizedListProps {
  isUpdating?: boolean;
  list: ConfigurationListItem[] | any;
  unit?: Unit;
  tabIsEditable: boolean;
  onSave?: SaveFunc;
  itemRenderer?: (...args: any[]) => React.ReactNode;
  tableAddress: string;
}

function LayoutItemVirtualizedList({
  isUpdating,
  list,
  unit,
  tabIsEditable,
  onSave,
  itemRenderer,
  tableAddress,
}: LayoutItemVirtualizedListProps) {
  const ROW_HEIGHT_MIN = 42;
  const ROW_HEIGHT_MAX = 50;

  const { skipPI200, isHvacLayout } = useRefrigLayout();

  const getItemHasActionEditType = (item: ConfigurationListItem) =>
    getEditType(item) === EditType.Action;

  const getItemRowHeight = (item: ConfigurationListItem) =>
    getItemHasActionEditType(item) ? ROW_HEIGHT_MAX : ROW_HEIGHT_MIN;

  const Row = ({ rowIndex, style, data = [] }): JSX.Element => {
    const item = data[rowIndex];
    const isLast = data.length === rowIndex + 1;
    const isItemEditable = tabIsEditable && getIsEditableByItem(item);

    return (
      <span style={style} id="span">
        <ListItem
          key={rowIndex}
          index={rowIndex}
          item={item}
          unit={unit}
          isLast={isLast}
          isEditable={isItemEditable}
          isAuthorized={true}
          isUpdating={isUpdating}
          htmlId={getHtmlId(item, rowIndex)}
          onSave={onSave}
          itemRenderer={itemRenderer}
        />
      </span>
    );
  };

  return (
    <ContainerDimensions>
      {({ height, width }) => (
        <>
          <Grid
            columnCount={1}
            columnWidth={() => '100%'}
            height={height}
            rowCount={list?.length || 0}
            rowHeight={index => getItemRowHeight(list[index])}
            width={width}
            itemData={list}
          >
            {Row}
          </Grid>
          {!skipPI200 && !isHvacLayout ? (
            <PI200FlowContent tableAddress={tableAddress} />
          ) : null}
        </>
      )}
    </ContainerDimensions>
  );
}

export { LayoutItemVirtualizedList };
