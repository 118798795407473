/* IMPORTANT - MAKE SURE NOT TO EXPOSE ANY SECRETS IN HERE */
declare global {
  interface Window {
    env: any;
    electronConstants?: any;
  }
}

type EnvType = {
  REACT_SKIP_PREFLIGHT_CHECK: string;
  REACT_APP_WEBSITE_NAME: string;
  REACT_APP_SCADA_STORAGE: string;
  REACT_APP_AZURE_ACCOUNT: string;
  REACT_APP_AZURE_TOKEN: string;
  REACT_APP_IS_ELECTRON_PACKAGE: string;
  REACT_APP_FEATURE_HUB_API_KEY: string;
  REACT_APP_FEATURE_HUB_URL: string;
};

const electronEnvs = window.electronConstants || {};

export const env: EnvType = { ...process.env, ...window.env, ...electronEnvs };
