import '../../constants/regex-patterns-constant.js';
import './get-is-valid-dns.js';
import { getIsValidIp } from './get-is-valid-ip.js';
import { getIsValidIpOrDns } from './get-is-valid-ip-or-dns.js';
import { getIsValidPort } from './get-is-valid-port.js';

/**
 * Get if the ip with a port is valid
 *
 * @export
 * @param {string} [ipAndPort='']
 * @param {boolean} [allowDns=true]
 * @returns {boolean}
 */
function getIsValidIpAndPort(ipAndPort, allowDns) {
  if (ipAndPort === void 0) {
    ipAndPort = '';
  }
  if (allowDns === void 0) {
    allowDns = true;
  }
  if (ipAndPort.includes(':')) {
    const [ip, port] = ipAndPort.split(':');
    if (allowDns) {
      return getIsValidIpOrDns(ip) && (port ? getIsValidPort(port) : true);
    }
    return getIsValidIp(ip) && (port ? getIsValidPort(port) : true);
  }
  if (allowDns) {
    return getIsValidIpOrDns(ipAndPort);
  }
  return getIsValidIp(ipAndPort);
}

export { getIsValidIpAndPort };
