import { styled } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';

type Props = DivProps & {
  isMdView: boolean;
};

export const TextContainer = styled(Div)<Props>`
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0 5px 0 lightgray;
  margin-top: ${({ theme }) => theme.spacing.md}px;
  padding: ${({ theme }) => theme.spacing.sm}px;
  width: ${({ isMdView }) => (isMdView ? 348 : 460)}px;
`;
