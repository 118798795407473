import { ScheduleItem } from 'pages/SchedulesPage/SchedulesPage.types';

type HolidaySchemaItem = ScheduleItem & {
  open?: string;
  close?: string;
  start?: string;
  end?: string;
  desc?: string;
};

const holidaySchema = t => {
  return [
    {
      title: t('t3230') || 'ID',
      key: 'id',
      dataIndex: 'id',
      width: '8%',
      sorter: (a: HolidaySchemaItem, b: HolidaySchemaItem) => {
        return a?.id?.localeCompare(b?.id);
      },
    },
    {
      title: t('t3231') || 'Open',
      key: 'open',
      dataIndex: 'open',
      width: '8%',
      sorter: (a: HolidaySchemaItem, b: HolidaySchemaItem) => {
        return a?.open?.localeCompare(b?.open);
      },
    },
    {
      title: t('t3291') || 'Close',
      key: 'close',
      dataIndex: 'close',
      width: '8%',
      sorter: (a: HolidaySchemaItem, b: HolidaySchemaItem) => {
        return a?.close?.localeCompare(b?.close);
      },
    },
    {
      title: t('t3232') || 'Start',
      key: 'start',
      dataIndex: 'start',
      width: '8%',
      sorter: (a: HolidaySchemaItem, b: HolidaySchemaItem) => {
        return a?.start?.localeCompare(b?.start);
      },
    },
    {
      title: t('t3233') || 'End',
      key: 'end',
      dataIndex: 'end',
      width: '8%',
      sorter: (a: HolidaySchemaItem, b: HolidaySchemaItem) => {
        return a?.end?.localeCompare(b?.end);
      },
    },
    {
      title: t('t3234') || 'Description',
      key: 'desc',
      dataIndex: 'desc',
      width: '8%',
      sorter: (a: HolidaySchemaItem, b: HolidaySchemaItem) => {
        return a?.desc?.localeCompare(b?.desc);
      },
    },
  ];
};

export { holidaySchema };
