import {
  Device,
  fetchXMLData,
  User,
  XML_ACTION,
  XML_DEVICE_NODETYPE,
} from '@danfoss/etui-sm-xml';
import { getArray } from 'utils';
import { removeDeviceDuplicates } from '../utils';
import { MIGRATION_FAILURE } from '../utils/migration-error-codes';

async function getReadGeneric(
  url,
  user,
  retryCount,
  skipSessionUpdate = false,
) {
  let filteredDevices = [];
  try {
    const { device: genericDevices } = await fetchXMLData<{ device }>({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        lang: user.language,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.READ_GENERIC,
        ...(skipSessionUpdate ? { session_update: 'no' } : {}),
      },
    });
    filteredDevices = genericDevices
      ? getArray(genericDevices).filter(
          x =>
            x.filex !== '-1' && x.nodetype === XML_DEVICE_NODETYPE.NODETYPE_GN,
        )
      : [];
  } catch (e) {
    if (e && retryCount < 3) {
      retryCount++;
      return getReadGeneric(url, user, retryCount, skipSessionUpdate);
    }
    throw new Error(MIGRATION_FAILURE);
  }
  return filteredDevices;
}
export async function fetchGenericDevices(
  url: any,
  user: User,
  skipSessionUpdate?: boolean,
): Promise<Device[]> {
  let uniqueGenericDevices = [];
  const retryCount = 0;

  const filteredDevices = await getReadGeneric(
    url,
    user,
    retryCount,
    skipSessionUpdate,
  );

  if (filteredDevices) {
    uniqueGenericDevices = removeDeviceDuplicates(filteredDevices);
  }
  return uniqueGenericDevices;
}
