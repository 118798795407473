export interface HVACSteps {
  title: string;
  stepNumber: number;
}

export enum HVAC_STEP {
  NETWORK = '1',
}

export const HVAC_LAYOUT_TABLE_ADDR = '20111';
