import { readFileAsUrlData } from './read-file-as-url-data.js';

const fetchImageBase64String = async imageUrl => {
  let response;
  try {
    response = await fetch(imageUrl);
  } catch (err) {
    console.warn('XML Image extractor FETCH error: ', err.message);
    return null;
  }
  const imgBlob = await response.blob();
  let imgBase64;
  try {
    imgBase64 = await readFileAsUrlData(imgBlob);
  } catch (err) {
    console.warn('XML Image extractor READ FILE error: ', err.message);
    return null;
  }
  return imgBase64;
};

export { fetchImageBase64String };
