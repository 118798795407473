import { ConfigurationTabItem } from '@danfoss/etui-sm-xml';
import { getParentMenuItemsByPathname } from './getParentMenuItemsByPathname';

function getMenuItemByMenuIdAndPathname(
  baseRoute: string,
  menuId: string,
  pathname: string,
  menuItemsById: {},
  level: number = 2,
): ConfigurationTabItem {
  const menuItems = getParentMenuItemsByPathname(
    baseRoute,
    pathname,
    menuItemsById,
    level,
  );
  return menuItems.find(menuItem => menuItem.id === menuId);
}

export { getMenuItemByMenuIdAndPathname };
