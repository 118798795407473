import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { Alert } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { LayoutFlowTitle } from '../LayoutContent';
import { LayoutItemContentList } from '../LayoutItemContentList/LayoutItemContentList';

export type NetworkContentViewProps = {
  tableAddress: string;
  title: string;
  subTitle: string;
  helpText: string;
};
export const NetworkContentView = ({
  tableAddress,
  title,
  subTitle,
  helpText,
}: NetworkContentViewProps) => {
  const { t } = useTranslation();

  return (
    <Div mt="10px" height="100%">
      <Alert type="warning" message={t('t3354')} />
      <LayoutFlowTitle title={title} subTitle={subTitle} helpText={helpText} />
      <Div height="100%">
        <LayoutItemContentList tableAddress={tableAddress} />
      </Div>
    </Div>
  );
};
