import '../utils/read-file-as-url-data.js';
import '../utils/parse-xml.js';
import '../utils/is-encoded.js';
import 'pako';
import '../utils/decode.js';
import '../utils/fetch-image-base64-string.js';
import '../utils/get-xml.js';
import '../_rollupPluginBabelHelpers-6901a291.js';
import '@danfoss/etui-sm-xml';
import 'uuid';
import '@azure/storage-blob';
import 'crypto-js';
import '../storage/connectors/utils/get-hashed-string.js';
import '../storage/connectors/azure-connector/helpers/azure-sas-policy.helper.js';
import '../storage/connectors/azure-connector/helpers/get-pipline-object.js';
import '../storage/connectors/azure-connector/helpers/get-container-client-url.js';
import { AzureConnector } from '../storage/connectors/azure-connector/AzureConnector.js';
import 'jszip';
import { ZipConvertor } from '../storage/convertors/ZipConvertor.js';
import 'file-saver';
import '../annotation/Conditions/utils/execute-operation.js';
import '../annotation/Conditions/Condition.js';
import '../annotation/utils/bind-methods.js';
import '../annotation/utils/Subscribable.js';
import '../annotation/Conditions/ConditionCollection.js';
import '../annotation/Mappings/Mapping.js';
import '../annotation/Mappings/MappingCollection.js';
import '../annotation/DataSource/DataSource.js';
import '../annotation/Display/Display.js';
import '../annotation/Rules/Rule.js';
import '../annotation/Rules/RuleCollection.js';
import '../storage/ScadaStorageView.js';
import { ScadaStorage } from '../storage/ScadaStorage.js';
import { useState, useEffect } from 'react';

function useScadaStorageAzure(macAddr, authData) {
  const [storage, setStorage] = useState();
  const [error, setError] = useState(null);
  useEffect(() => {
    const initStorage = async unitMacAddr => {
      const connector = new AzureConnector(unitMacAddr, authData);
      const convertor = new ZipConvertor();
      return ScadaStorage.initStorage(connector, convertor);
    };
    if (macAddr !== null && macAddr !== void 0 && macAddr.length) {
      initStorage(macAddr).then(setStorage, setError);
    }
  }, [macAddr]);
  return {
    storage,
    error
  };
}

export { useScadaStorageAzure };
