import * as React from 'react';
import { ValueContainer } from './ValueContainer';
import { ValueGraphicsContainer } from './ValueGraphicsContainer';

export interface IValueColumnProps {
  value: string;
  width?: string;
  isDefrosting?: boolean;
  hasAlarm?: boolean;
}

export function ValueColumn({
  value,
  width = '100%',
  isDefrosting = false,
  hasAlarm = false,
}: IValueColumnProps) {
  return isDefrosting || hasAlarm ? (
    <ValueGraphicsContainer
      width={width}
      value={value}
      isDefrosting={isDefrosting}
      hasAlarm={hasAlarm}
    />
  ) : (
    <ValueContainer width={width}>{value || '-'}</ValueContainer>
  );
}
