import { TFunction } from 'i18next';
import {
  DeviceConstraintsData,
  DeviceTypeModelList,
  DEVICE_CATEGORY,
  DEVICE_STATUS,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../types/DiscoveryPage.types';
import {
  getDescriptionBasedOnType,
  getDeviceIndex,
  getMaxSg,
  getNextSuctionGroupNumber,
  getRackCount,
  getSuctionGroupCount,
} from '../utils';
import { insertSuctionGroup } from '.';

function addRack(
  discoveryTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  deviceSuction: DiscoveryTableData[],
  t: TFunction,
  deviceTypeModelList: DeviceTypeModelList,
) {
  if (
    getRackCount(discoveryTableData) >= +deviceConstraintsData?.max_rk ||
    getSuctionGroupCount(deviceSuction) >= +deviceConstraintsData?.max_sg
  ) {
    return discoveryTableData;
  }
  const discoveryTableDataObj: DiscoveryTableData = {
    deviceId: getDeviceIndex(discoveryTableData) + 1,
    removed: false,
    newDevice: true,
    category: DEVICE_CATEGORY.PACK,
    deviceType: DEVICE_TYPE.NO_COMP,
    type: getDescriptionBasedOnType(DEVICE_TYPE.NO_COMP, t),
    name: t('t135'),
    listname: deviceTypeModelList?.modelList[1]?._,
    file: '',
    version: '',
    device: '',
    model: deviceTypeModelList?.modelList[1]?._,
    model_idx: 0,
    ip: '',
    address: '-',
    rk: 1,
    sg: getNextSuctionGroupNumber(discoveryTableData),
    dim1: '0',
    dim2: '0',
    dim3: '0',
    num_sg: 1,
    cfg: '0',
    online: '0',
    status: DEVICE_STATUS.RED,
    case_type: t('t520'),
    deviceBus: t('t490'),
    mx_naddr: deviceConstraintsData?.max_naddr,
    max_sg: getMaxSg(DEVICE_TYPE.NO_COMP),
    assigned: false,
  };
  discoveryTableData.push(discoveryTableDataObj);
  const name = `${t('t402')} ${
    String.fromCharCode(65 + discoveryTableDataObj.rk - 1) +
    String.fromCharCode(65)
  }`;
  insertSuctionGroup(
    discoveryTableDataObj.rk,
    name,
    false,
    discoveryTableData,
    deviceConstraintsData,
    t,
  );
  return discoveryTableData;
}

export { addRack };
