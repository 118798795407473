import React from 'react';

function useMultiUnitUpdate() {
  const [selectedUnits, setSelectedUnits] = React.useState([]);
  const [currentUnitUpdate, setCurrentUnitUpdate] = React.useState(null);
  const [failedUnitUpdates, setFailedUnitUpdates] = React.useState([]);
  const [allUnitsUpdated, setAllUnitsUpdated] = React.useState(false);
  const [isLastUnitUpdate, setIsLastUnitUpdate] = React.useState(false);
  const addFailedUnitUpdate = unit => {
    setFailedUnitUpdates([...failedUnitUpdates, unit]);
  };
  const resetMultiUnitUpdate = React.useCallback(() => {
    setCurrentUnitUpdate(null);
    setFailedUnitUpdates([]);
    setAllUnitsUpdated(false);
    setSelectedUnits([]);
    setIsLastUnitUpdate(false);
  }, []);
  const getUnitsSorted = units => {
    return units.sort((unit1, unit2) => unit1.unit_addr > unit2.unit_addr ? -1 : 1);
  };
  React.useEffect(() => {
    if (currentUnitUpdate) {
      const unitsSorted = getUnitsSorted(selectedUnits);
      const currentIndex = unitsSorted.indexOf(unitsSorted.find(unit => unit.unit_addr === currentUnitUpdate.unit_addr));
      if (currentIndex === unitsSorted.length - 1) {
        setIsLastUnitUpdate(true);
      }
    }
  }, [currentUnitUpdate]);
  const setNextUnitUpdate = React.useCallback(() => {
    const unitsSorted = getUnitsSorted(selectedUnits);
    if (!currentUnitUpdate) {
      const lowestAddressUnit = unitsSorted[0];
      setCurrentUnitUpdate(lowestAddressUnit);
    } else {
      const currentIndex = unitsSorted.indexOf(unitsSorted.find(unit => unit.unit_addr === currentUnitUpdate.unit_addr));
      if (currentIndex >= unitsSorted.length - 1) {
        setAllUnitsUpdated(true);
        setCurrentUnitUpdate(null);
      }
      const nextUnitUpdate = unitsSorted[currentIndex + 1];
      setCurrentUnitUpdate(nextUnitUpdate);
    }
  }, [selectedUnits, currentUnitUpdate]);
  const onSelectedUnitsChanged = React.useCallback((unit, checked) => {
    if (checked && !selectedUnits.some(u => u.unit_addr === unit.unit_addr)) {
      const tempUnits = [...selectedUnits, unit];
      setSelectedUnits(tempUnits);
    } else {
      const selectedUnitsFiltered = selectedUnits.filter(u => u.unit_addr !== unit.unit_addr);
      setSelectedUnits(selectedUnitsFiltered);
    }
  }, [selectedUnits]);
  return {
    currentUnitUpdate,
    failedUnitUpdates,
    selectedUnits,
    allUnitsUpdated,
    isLastUnitUpdate,
    addFailedUnitUpdate,
    setNextUnitUpdate,
    setSelectedUnits,
    resetMultiUnitUpdate,
    onSelectedUnitsChanged
  };
}

export { useMultiUnitUpdate };
