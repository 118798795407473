import { useEffect } from 'react';

function useKeyboardEvent(key: string, callback: (event) => void) {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === key) {
        callback(event);
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, []);
}

export { useKeyboardEvent };
