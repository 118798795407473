import * as React from 'react';
import { UnitNetwork } from '@danfoss/etui-sm-xml';
import { DefaultTheme, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { A, P } from '@danfoss/etui-system-elements';

export interface OfflineUnitErrorProps {
  network: UnitNetwork;
  isHttp: boolean;
}
export function OfflineUnitError({ network, isHttp }: OfflineUnitErrorProps) {
  const theme: DefaultTheme = useTheme();
  const { t } = useTranslation();
  const link: string = getLink(isHttp, network);

  const trustCertificate = () => window.open(link, '_blank');
  return (
    <>
      <P color={theme.palette.secondary.main}>
        {`${t('t128')} - `} {`${t('t3324')} `}
        <A
          fontWeight={700}
          color="black"
          textDecoration="underline"
          cursor="pointer"
          onClick={trustCertificate}
        >
          {link}
        </A>
        {!isHttp && `, ${t('t3325')}`}
      </P>
    </>
  );
}

const getLink = (isHttp: boolean, network: UnitNetwork): string => {
  const protocol: string = isHttp ? `http://` : `https://`;
  const ipPort: string = `${network?.ip}:${network.port}`;
  return protocol + ipPort;
};
