import { Unit } from '@danfoss/etui-sm-xml';
import { DeviceType } from '@danfoss/etui-sm/types';

function haveMiscDevice(unit: Unit) {
  return (
    parseInt(unit.ecfans, 10) > 0 ||
    parseInt(unit.rail, 10) > 0 ||
    parseInt(unit.miscoi, 10) > 0 ||
    parseInt(unit.miscsi, 10) > 0 ||
    parseInt(unit.miscro, 10) > 0 ||
    parseInt(unit.miscvo, 10) > 0 ||
    parseInt(unit.monitoring, 10) > 0 ||
    parseInt(unit.drives, 10) > 0 ||
    parseInt(unit.ovrs, 10) > 0 ||
    parseInt(unit.leaks, 10) > 0
  );
}

function getDeviceTypesByUnits(units: Unit[]) {
  const deviceTypes: DeviceType[] = [];
  let haveRefrig = false;
  let haveHVAC = false;
  let haveLights = false;
  let haveMeters = false;
  let haveMisc = false;

  for (const unit of units) {
    if (parseInt(unit.refrig, 10) > 0) {
      haveRefrig = true;
    }
    if (parseInt(unit.hvac, 10) > 0) {
      haveHVAC = true;
    }
    if (parseInt(unit.lights, 10) > 0) {
      haveLights = true;
    }
    if (parseInt(unit.meters, 10) > 0) {
      haveMeters = true;
    }
    if (haveMiscDevice(unit)) {
      haveMisc = true;
    }
  }

  if (haveRefrig) {
    deviceTypes.push('refrig');
  }
  if (haveHVAC) {
    deviceTypes.push('hvac');
  }
  if (haveLights) {
    deviceTypes.push('lights');
  }
  if (haveMeters) {
    deviceTypes.push('meter');
  }
  if (haveMisc) {
    deviceTypes.push('misc');
  }

  return deviceTypes;
}

export { getDeviceTypesByUnits };
