import React from 'react';
import { Col, Modal, Row } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { Div } from '@danfoss/etui-system-elements';

interface SuctionGroupWaringModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  handleOnSgLessCountOkClick: () => void;
  warningMessage: string;
}
export const SuctionGroupWaringModal = ({
  isOpen,
  onClose,
  handleOnSgLessCountOkClick,
  warningMessage,
}: SuctionGroupWaringModalProps) => {
  const { t } = useTranslation();

  const handleOnOkClick = () => {
    handleOnSgLessCountOkClick();
    onClose();
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={<Modal.Header title={t('t3124')} showCloseButton={false} />}
        actionButtons={[
          {
            variant: 'secondary',
            children: t('t45'),
            onClick: onClose,
          },
          {
            variant: 'primary',
            onClick: handleOnOkClick,
            children: t('t44'),
          },
        ]}
        shouldCloseOnOverlayClick={true}
      >
        <Row>
          <Col xs={12}>
            <Div testId="suction-group-warning">{warningMessage}</Div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
