import { ConfigurationListItem } from '@danfoss/etui-sm-xml';

/**
 * Request to write configuration list item changes has to have fields
 * audit1, audit2, audit3, and audit4,
 * where audit1 is always the name of a tab (eg System),
 * and audit2/3/4 - name of the item, depending on its indent value
 * if indent value == "1", audit2 - will be the name of the item;
 * if indent > 1 -> depending on indent, the name of the item will be audit3 or audit4
 * whereas the _middle_ audits will be the _parent_ items (with smaller indent, but > 0)
 * @param listItems
 * @param itemIndex
 * @param root
 */
function getAuditNames(
  listItems: ConfigurationListItem[],
  itemIndex: number,
  root: string,
): Record<'audit1' | 'audit2' | 'audit3' | 'audit4', string> {
  const auditNames = [];
  let currentIndex = itemIndex + 1; // to check initial item as well
  let checkedIndent;
  // get audit items depending on items index and intent;
  do {
    currentIndex--;
    if (checkedIndent !== listItems[currentIndex]?.ind) {
      auditNames.unshift(listItems[currentIndex]?.name);
      checkedIndent = listItems[currentIndex]?.ind;
    }
  } while (listItems[currentIndex]?.ind !== '0' && currentIndex > 0);
  auditNames.unshift(root);

  return auditNames.reduce((acc, audit, i) => {
    acc[`audit${i + 1}`] = audit;
    return acc;
  }, {});
}

export { getAuditNames };
