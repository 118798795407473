import React from 'react';
import { useTheme } from 'styled-components';
import { Div, Span } from '@danfoss/etui-system-elements';
import { Icon, IconGlyph } from '@danfoss/etui-core';

export interface LayoutFlowTitleprops {
  title: string;
  subTitle: string;
  helpText?: string;
  glyph?: IconGlyph;
}
export const LayoutFlowTitle = ({
  title,
  subTitle,
  helpText,
  glyph,
}: LayoutFlowTitleprops) => {
  const theme = useTheme();
  return (
    <>
      <Div display="inline-flex" testId="layout-flow-title-div" mt="10px">
        {glyph && (
          <Span>
            <Icon isIconLabel={true} glyph={glyph} />
          </Span>
        )}
        <Span style={{ fontWeight: 'bold' }}>{title}</Span>
        <Span style={{ marginLeft: `${theme.spacing.md}px` }}>{subTitle}</Span>
      </Div>
      {helpText && (
        <Div testId="layout-flow-helptext-div" mt="20px" mb="20px" width="50%">
          <Span>{helpText}</Span>
        </Div>
      )}
    </>
  );
};
