import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import {
  icons,
  Icon,
  TextInput,
  TextInputProps,
  InputAddon,
} from '@danfoss/etui-core';
import { darken } from '@danfoss/etui-colors';

export function CasesSearchInput(props: TextInputProps) {
  const theme = useTheme();
  return (
    <TextInput
      {...props}
      startInputAddon={
        <InputAddon
          position="start"
          isPointerEventsDisabled={true}
          styles={{
            root: {
              top: `calc((42px - 24px) / 2)`,
              width: '24px',
              height: '24px',
            },
          }}
        >
          <Icon glyph={icons.SEARCH} />
        </InputAddon>
      }
      size="small"
      styles={{
        input: {
          borderColor: 'transparent',
          bg: darken(theme.palette.common.bg, 0.015),
          borderRadius: 0,
          boxShadow: 'none',
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      }}
    />
  );
}
