import * as React from 'react';
import { Div, Span } from '@danfoss/etui-system-elements';
import * as S from './styles';
import { parseLineBreaks } from './utils';
import { HelpPanelInfoItem } from './HelpPanel.types';

export interface InfoItemProps {
  item: HelpPanelInfoItem;
}

export const InfoItem: React.FC<InfoItemProps> = ({ item }) => (
  <Div key={item.itemKey}>
    <S.InfoItemTitle>{item.itemName}</S.InfoItemTitle>
    {item.itemProperties.map(({ propertyName, propertyDescription }) => (
      <Div key={propertyName}>
        <S.InfoItemPropertyTitle>{propertyName}: </S.InfoItemPropertyTitle>
        <Span>{parseLineBreaks(propertyDescription)}</Span>
      </Div>
    ))}
  </Div>
);
