import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import '../../types/Unit.js';
import '../url-helpers/get-is-valid-dns.js';
import '../url-helpers/get-is-valid-ip.js';
import '../url-helpers/get-is-valid-ip-or-dns.js';
import '../url-helpers/get-is-valid-port.js';
import { getUrlInfo } from '../url-helpers/get-url-info.js';
import './initial-unit-utils.js';
import './get-unit-networks-by-unit.js';
import { getUnitNetworkByUnit } from './get-unit-network-by-unit.js';
import { getUnitUrlByUnitNetwork } from './get-unit-url-by-unit-network.js';

/**
 * Get url by sourceUrl & unit
 *
 * @param {string} sourceUrl
 * @param {Unit} unit
 * @returns
 */
function getUnitUrl(sourceUrl, unit) {
  const {
    protocol
  } = getUrlInfo(sourceUrl);
  const unitNetwork = getUnitNetworkByUnit(sourceUrl, unit);
  return unitNetwork ? getUnitUrlByUnitNetwork(unitNetwork, protocol) : sourceUrl;
}

export { getUnitUrl };
