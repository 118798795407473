import React from 'react';
import { Spinner, SpinnerSize, Table } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '@danfoss/etui-sm';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { PI200Table, PI200TableContent, PI200TableData } from '../../types';
import { useRefrigLayout } from '../../context';

export interface PI200FlowTableProps {
  tableDataLoading?: boolean;
  handleOnSelect: (record: ConfigurationListItem) => void;
}

export const PI200FlowTable = ({
  tableDataLoading,
  handleOnSelect,
}: PI200FlowTableProps) => {
  const { t } = useTranslation();

  const { pi200TableData, selectAll, setSelectAll, notAuth } =
    useRefrigLayout();

  const tableColumns = [
    {
      title: t('t57'),
      dataIndex: 'address',
      key: 'address',
      titleBreakpoints: ['lg'],
    },
    {
      title: t('t355'),
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: t('t3386'),
      dataIndex: 'ipAddress',
      key: 'ipAddress',
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [gatewaysTable, setGatewaysTable] = React.useState<PI200TableContent[]>(
    [],
  );

  React.useEffect(() => {
    if (pi200TableData?.piTableContent.length) {
      getTableData(pi200TableData);
    }
  }, [pi200TableData]);

  const getTableData = (piTableData: PI200TableData) => {
    const pi200Table: PI200Table[] = [];
    const rowKeys: number[] = [];
    if (piTableData.piTableContent.length > 1) {
      const selectData = piTableData?.piTableContent
        .slice(0, 2)
        .map(data => data.li);
      setSelectAll(selectData);
      const tableData = piTableData?.piTableContent.slice(2);
      tableData.forEach((content, index) => {
        if (content.toggleVal === '1') {
          rowKeys.push(index);
        }
        pi200Table.push({
          address: content.address,
          model: content.model,
          ipAddress: content.ipAddress,
          toggleVal: content.toggleVal,
          key: index,
          li: content.li,
        });
      });
      setGatewaysTable(pi200Table);
      setSelectedRowKeys(rowKeys);
    }
  };

  const handleRowSelectionChange = async (selectedKeys: string[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = React.useMemo(() => {
    return {
      selectedRowKeys,
      onChange: handleRowSelectionChange,
      onSelect: record => {
        handleOnSelect(record.li);
      },
      onSelectAll: (selected, selectedRows) => {
        selected && selectedRows?.length
          ? handleOnSelect(selectAll[0])
          : handleOnSelect(selectAll[1]);
      },
      columnWidth: '1%',
    };
  }, [selectedRowKeys.length, gatewaysTable]);
  return (
    <>
      <Div testId="pi200-content-div">
        {tableDataLoading ? (
          <Div
            testId="gateways-flow-table-spinner-div"
            style={{ marginTop: '200px' }}
          >
            <Spinner size={SpinnerSize.small} />
          </Div>
        ) : !gatewaysTable.length && !notAuth ? (
          <Div
            testId="gateways-flow-table-empty-div"
            style={{ marginTop: '200px' }}
          >
            <EmptyState title={t('t3402')} />
          </Div>
        ) : notAuth ? (
          <Div
            testId="gateways-flow-table-notauth-div"
            style={{ marginTop: '200px' }}
          >
            <EmptyState title={notAuth.name} />
          </Div>
        ) : (
          <Div
            testId="gateways-flow-table-content-div"
            style={{ marginTop: '20px' }}
          >
            <Table
              styles={{ root: { height: '800px', overflow: 'auto' } }}
              columns={tableColumns}
              dataSource={gatewaysTable}
              rowSelection={rowSelection}
            />
          </Div>
        )}
      </Div>
    </>
  );
};
