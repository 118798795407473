import * as React from 'react';

function useFlash(
  time: number,
  onFlashEnd?: () => void,
): [() => void, boolean] {
  const [isActive, setIsActive] = React.useState(false);
  const endCbRef = React.useRef<() => void | null>(null);

  React.useEffect(() => {
    endCbRef.current = onFlashEnd;
  }, [onFlashEnd]);

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isActive) {
      timeout = setTimeout(() => {
        setIsActive(false);
        if (typeof endCbRef.current === 'function') {
          endCbRef.current();
        }
      }, time);
    }

    return () => clearTimeout(timeout);
  }, [isActive, onFlashEnd, time]);

  const startFlash = React.useCallback(() => {
    setIsActive(true);
  }, []);

  return [startFlash, isActive];
}

export { useFlash };
