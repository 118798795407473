import { DEVICE_TYPE, DiscoveryTableData } from '../types/DiscoveryPage.types';

function deleteCompressor(
  selectedRowIndex: number,
  discoveryTableData: DiscoveryTableData[],
  deviceSuction: DiscoveryTableData[],
) {
  let rk: number = 0;
  if (deviceSuction.length > 0 && selectedRowIndex >= 0) {
    for (const device of discoveryTableData) {
      const selecteRowDeviceId: number =
        deviceSuction[selectedRowIndex].deviceId;
      if (device.deviceId === selecteRowDeviceId) {
        rk = device.rk;
        device.removed = true;
        device.newDevice = true;
        break;
      }
    }
    deviceSuction[selectedRowIndex].removed = true;
    deviceSuction.splice(selectedRowIndex, 1);
  }
  const removedDiscoveryTableData: DiscoveryTableData[] =
    removeAllSuctionGroups(rk, discoveryTableData);
  const arrangedDiscoveryTableData: DiscoveryTableData[] = arrangeCompressors(
    removedDiscoveryTableData,
    deviceSuction,
  );
  return arrangedDiscoveryTableData;
}

const arrangeCompressors = (
  discoveryTableData: DiscoveryTableData[],
  deviceSuction: DiscoveryTableData[],
): DiscoveryTableData[] => {
  const devs = [];
  let rk: number = 1;
  if (!deviceSuction.length) {
    for (const device of discoveryTableData) {
      if (
        !device.removed &&
        (device.deviceType === DEVICE_TYPE.CIRCUIT ||
          device.deviceType === DEVICE_TYPE.GEN_CIRCUIT)
      ) {
        devs.push(device);
      }
    }
  } else {
    for (const suction of deviceSuction) {
      if (!suction.removed) {
        for (const device of discoveryTableData) {
          if (!device.removed && device.rk === suction.rk) {
            device.new_rk = rk;
            devs.push(device);
          }
        }
        rk++;
      }
    }
    for (const device of discoveryTableData) {
      if (!device.removed && (device.rk === 255 || device.rk === 0)) {
        devs.push(device);
      }
    }
    for (const dev of devs) {
      if (dev.deviceType === DEVICE_TYPE.GEN_PACK) {
        dev.dim1 = dev.new_rk;
      }
      dev.rk = dev.new_rk;
    }
  }
  let cnt: number = 0;
  discoveryTableData.length = 0;
  for (const device of devs) {
    device.deviceId = ++cnt;
    discoveryTableData.push(device);
  }
  return discoveryTableData;
};

const removeAllSuctionGroups = (
  rk: number,
  discoveryTableData: DiscoveryTableData[],
) => {
  let num_groups: number;
  const parentDevice: DiscoveryTableData = discoveryTableData.find(
    element =>
      (element.deviceType === DEVICE_TYPE.RACK ||
        element.deviceType === DEVICE_TYPE.NO_COMP ||
        element.deviceType === DEVICE_TYPE.GEN_PACK) &&
      element.rk === rk,
  );
  if (parentDevice) {
    num_groups = parentDevice.num_sg;
  } else {
    num_groups = 1;
  }
  for (const device of discoveryTableData) {
    if (device.rk === rk && device.deviceType === DEVICE_TYPE.RACK) {
      num_groups = device.num_sg;
      updateSuctionGroupCount(rk, num_groups, discoveryTableData);
      break;
    }
  }
  for (let index: number = 1; index <= num_groups; index++) {
    removeSuctionGroup(rk, discoveryTableData);
  }
  return discoveryTableData;
};

function removeSuctionGroup(
  rk: number,
  discoveryTableData: DiscoveryTableData[],
) {
  let sg: number;
  let device: DiscoveryTableData;
  const total: number = discoveryTableData.length;
  for (device of discoveryTableData) {
    if (
      (device.deviceType === DEVICE_TYPE.RACKSUCT ||
        device.deviceType === DEVICE_TYPE.GEN_SUCT ||
        device.deviceType === DEVICE_TYPE.NO_COMP_SUCT) &&
      device.rk === rk
    ) {
      sg = device.sg;
    }
  }
  for (let index = total - 1; index >= 0; index--) {
    device = discoveryTableData[index];
    if (
      (device.deviceType === DEVICE_TYPE.RACKSUCT ||
        device.deviceType === DEVICE_TYPE.GEN_SUCT ||
        device.deviceType === DEVICE_TYPE.NO_COMP_SUCT) &&
      device.rk === rk &&
      device.sg === sg
    ) {
      discoveryTableData.splice(index, 1);
      index--;
    } else if (
      device.deviceType === DEVICE_TYPE.CIRCUIT ||
      device.deviceType === DEVICE_TYPE.GEN_CIRCUIT
    ) {
      discoveryTableData[index].assigned = false;
      discoveryTableData[index].rk = 255;
      discoveryTableData[index].sg = 255;
      discoveryTableData[index].cfg = '0';
    }
  }
}

function updateSuctionGroupCount(
  rk: number,
  num_groups: number,
  discoveryTableData: DiscoveryTableData[],
) {
  let i: number = 0;
  for (const device of discoveryTableData) {
    if (isSuctionDevice(device.deviceType) && device.rk === rk) {
      discoveryTableData[i].num_sg = num_groups;
    }
    i++;
  }
}

const isSuctionDevice = (deviceType: string) => {
  switch (deviceType) {
    case DEVICE_TYPE.RACK:
    case DEVICE_TYPE.RACKSUCT:
    case DEVICE_TYPE.GEN_PACK:
    case DEVICE_TYPE.GEN_SUCT:
    case DEVICE_TYPE.NO_COMP:
    case DEVICE_TYPE.NO_COMP_SUCT:
      return true;
    default:
      return false;
  }
};

export {
  deleteCompressor,
  removeSuctionGroup,
  updateSuctionGroupCount,
  removeAllSuctionGroups,
};
