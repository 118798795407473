class DataSource {
  constructor() {
    this.sourceId = '';
    this.deviceName = '';
    this.decimal = 1;
    this.parameterName = '';
    this.units = '';
    this.address = '';
    this.paramIndex = 0;
  }
  setSourceId(sourceId) {
    this.sourceId = sourceId;
    return this;
  }
  getSourceId() {
    return this.sourceId;
  }
  setDeviceName(deviceName) {
    this.deviceName = deviceName;
    return this;
  }
  getDeviceName() {
    return this.deviceName;
  }
  setParameterName(parameterName) {
    this.parameterName = parameterName;
    return this;
  }
  getParameterName() {
    return this.parameterName;
  }
  setUnit(unit) {
    this.unit = unit.value;
    return this;
  }
  getUnit() {
    return this.unit;
  }
  setUnits(units) {
    this.units = units;
    return this;
  }
  getUnits() {
    return this.units;
  }
  setDecimal(dec) {
    this.decimal = dec;
    return this;
  }
  convertFixedDecimal(value) {
    return parseFloat(value).toFixed(this.decimal);
  }
  setAddress(address) {
    this.address = address;
    return this;
  }
  getAddress() {
    return this.address;
  }
  setParamIndex(paramIndex) {
    this.paramIndex = paramIndex;
    return this;
  }
  getParamIndex() {
    return this.paramIndex;
  }
  import(rawObj) {
    if (rawObj.sourceId) {
      this.sourceId = rawObj.sourceId;
    }
    if (rawObj.unit) {
      this.unit = rawObj.unit;
    }
    if (rawObj.units) {
      this.units = rawObj.units;
    }
    if (rawObj.parameterName) {
      this.parameterName = rawObj.parameterName;
    }
    if (rawObj.deviceName) {
      this.deviceName = rawObj.deviceName;
    }
    if (rawObj.decimal) {
      this.decimal = rawObj.decimal;
    }
    if (rawObj.address) {
      this.address = rawObj.address;
    }
    if (rawObj.paramIndex) {
      this.paramIndex = rawObj.paramIndex;
    }
    return this;
  }
}

export { DataSource };
