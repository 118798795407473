export interface DiscoveryResponse {
  action?: string;
  error?: string;
  lang?: string;
  password?: string;
  storeview_only?: string;
  user?: string;
  version?: string;
  casetypes?: DiscoveryCaseType;
  devices?: DiscoveryDevices;
}

export interface DiscoveryCaseType {
  total: string;
  type: DiscoveryType[];
}

export interface DiscoveryType {
  idx: string;
  name: string;
}

export interface DiscoveryDevices {
  config_cnt?: string;
  d?: DiscoveryDeviceDetail[];
  gen_ofs_cs?: string;
  gen_ofs_ct?: string;
  gen_ofs_pk?: string;
  max_ca?: string;
  max_cs?: string;
  max_ct?: string;
  max_naddr?: string;
  max_name?: string;
  max_octl?: string;
  max_rk?: string;
  max_sg?: string;
  max_sg_gen?: string;
  scan_cnt?: string;
  total?: string;
  gen_ofs_ah?: string;
  max_ah?: string;
}

export interface DiscoveryDeviceDetail {
  CO2?: string;
  addr?: string;
  bpidx?: string;
  bus?: string;
  cat1?: string;
  cat2?: string;
  cfg?: string;
  dev?: string;
  fil?: string;
  ip?: string;
  max_sg?: string;
  mdl?: string;
  mismatch?: string;
  name?: string;
  num_sg?: string;
  onln?: string;
  rk?: string;
  sg?: string;
  type?: string;
  ver?: string;
  dim1?: string;
  dim2?: string;
  dim3?: string;
  case_type?: string;
  is_monitor?: string;
  subcool?: string;
  sc?: string;
  circ_type?: string;
  mx_naddr?: string;
  ah?: string;
  hvac_type?: string;
}

export interface DiscoveryTableData {
  status?: string;
  name?: string;
  address?: string;
  model?: string;
  type?: string;
  deviceType?: string;
  deviceId?: number;
  code?: string;
  version?: string;
  deviceBus?: string;
  max_sg?: number;
  sg?: number;
  num_sg?: number;
  rk?: number;
  listname?: string;
  childSuction?: DiscoveryTableData[];
  subcool?: string;
  sc?: string;
  is_monitor?: string;
  case_type?: string;
  circ_type?: string;
  dim1?: string;
  dim2?: string;
  dim3?: string;
  model_idx?: number;
  cfg?: string;
  cat1?: string;
  cat2?: string;
  mx_naddr?: string;
  removed?: boolean;
  newDevice?: boolean;
  category?: number;
  multi?: number;
  file?: string;
  ver?: string;
  device?: string;
  ip?: string;
  online?: string;
  arranged?: boolean;
  new_rk?: number;
  assigned?: boolean;
  is_multi?: boolean;
  ah?: number;
  rel_sg?: string;
}

export interface DeviceCompressorData {
  updatedDiscoveryTableData: DiscoveryTableData[];
  suction: DiscoveryTableData[];
  dualSuctionDevices: DiscoveryTableData[];
}

export type CircuitsTableData = {
  id: number;
  name: JSX.Element;
  address: JSX.Element;
  model: JSX.Element;
  listname?: string;
  operation?: JSX.Element;
  deviceId?: number;
};

export interface DiscoveryPageData {
  discoveryTableData: DiscoveryTableData[];
  discoveryDeviceStatusCnt: DiscoveryDeviceStatusCnt;
  deviceConstraintsData: DeviceConstraintsData;
  caseTypes?: string[];
}

export interface DiscoveryDeviceStatusCnt {
  statusOrangeCnt: number;
  statusGreenCnt: number;
  statusRedCnt: number;
}

export interface DeviceConstraintsData {
  config_cnt?: string;
  gen_ofs_cs?: string;
  gen_ofs_ct?: string;
  gen_ofs_pk?: string;
  max_ca?: string;
  max_cs?: string;
  max_ct?: string;
  max_naddr?: string;
  max_name?: string;
  max_octl?: string;
  max_rk?: string;
  max_sg?: string;
  max_sg_gen?: string;
  scan_cnt?: string;
  gen_ofs_ah?: number;
  max_ah?: number;
  max_namelen?: number;
}

export enum DEVICE_TYPE {
  HVAC = 'hvac',
  UNKNOWN = 'unknown',
  NO_COMP = 'nocomp',
  RACK = 'rack',
  RACKSUCT = 'racksuct',
  CIRCUIT = 'circuit',
  NO_COMP_SUCT = 'nocomp_suction',
  PI200 = 'pi',
  GEN_SUCT = 'gen_suction',
  GEN_PACK = 'gen_pack',
  GEN_CIRCUIT = 'gen_circuit',
  GEN_DRIVE = 'gen_drive',
  GEN_HVAC = 'gen_hvac',
}

export enum DEVICE_BUS {
  UNKNOWN = 0,
  LON = 1,
  SNMP = 2,
  MODBUS = 3,
  PI200 = 4,
  EXT_MODBUS = 5,
  AK2_IP = 6,
}
export interface DeviceModelResponse {
  action: string;
  error: string;
  lang: string;
  password: string;
  simple: string;
  storeview_only: string;
  user: string;
  version: string;
  dt: DeviceModelList;
}

export interface DeviceModelList {
  ld: DeviceModel[];
}

export interface DeviceTypeModelList {
  type: number;
  modelList: DeviceModel[];
}

export interface DeviceModel {
  datavalue: string;
  multi?: string;
  _: string;
}

export enum DEVICE_BUS_DESCRIPTION {
  LON = 'LON',
  SNMP = 'IP',
  MODBUS = 'MODBUS 1',
  PI200 = 'PI',
  EXT_MODBUS = 'MODBUS 2',
  AK2_IP = 'AK2 IP',
}

export enum DEVICE_STATUS {
  ORANGE = 'orange',
  GREEN = 'green',
  RED = 'red',
}

export enum DEVICE_SCAN_STATUS {
  SCANNING = 1,
  IONET_NORM = 255,
  IONET_VERIFY_MIN = 1000,
}

export interface RescanResponse {
  action: string;
  error: string;
  lang: string;
  password: string;
  scanning: string;
  storeview_only: string;
  user: string;
  version: string;
}

export interface ScanStatusResponse {
  action: string;
  error: string;
  lang: string;
  password: string;
  storeview_only: string;
  user: string;
  version: string;
  scan_status: string;
  max_nodes: string;
}

export interface RefrigSteps {
  title: string;
  stepNumber: number;
}

export enum DEVICE_LIST_TYPE {
  EVAP = 20,
  RACK = 21,
  HVAC = 22,
}

export enum DEVICE_LIST_ACTION {
  EVAP = 'read_device_type_evap',
  RACK = 'read_device_type_rack',
  HVAC = 'read_device_type_hvac',
}

export const DEVICE_PACK_SUCTION_NAME = ['MT', 'LT', '', ''];

export enum DEVICE_CATEGORY {
  PACK = 0x0002,
}

export enum DEVICE_MAX_SG {
  MAX_SG_PACK = 2,
  MAX_SG_RACK = 5,
  MAX_SG_NOCOMP = 5,
}

export enum HVAC_DEVICE_MODEL {
  AHU = 'AHU',
  RTU = 'RTU',
}

export interface HvacFinalTableData {
  hvacTableData: DiscoveryTableData[];
  hvacDeviceName: string[];
  hvacDeviceAddress: string[];
}

export interface HvacTableData {
  name: JSX.Element;
  address: JSX.Element;
  model: JSX.Element;
  listname?: string;
  operation: JSX.Element;
}

export interface ConfigureDeviceResponse {
  action: string;
  error: string;
  lang: string;
  password: string;
  received: string;
  storeview_only: string;
  total: string;
  user: string;
  version: string;
}

export interface ConfigureDeviceStatusResponse {
  action: string;
  error: string;
  lang: string;
  password: string;
  received: string;
  storeview_only: string;
  user: string;
  version: string;
  progress: string;
  error2: string;
  error_msg: string;
}

export const SPECIAL_CHAR_REGEX = '^[0-9]+$';
