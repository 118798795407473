import { fetchXMLData, XML_ACTION, XML_UPDATE_SW_CMD } from '@danfoss/etui-sm-xml';
import { RETURN_CODE_NO_DOWNGRADE_ALLOWED } from './fetch-update-software-check.js';

async function fetchUpdateSoftwareDanuxCheck(url, user) {
  const response = await fetchXMLData({
    url,
    attributes: {
      action: XML_ACTION.UPDATE_SW,
      user: user.user,
      password: user.password,
      sw_upgrade_cmd: XML_UPDATE_SW_CMD.CHECK_LPK_VERSION
    }
  });
  if (response.return_code !== '0') {
    throw new Error(RETURN_CODE_NO_DOWNGRADE_ALLOWED);
  }
  return response;
}

export { fetchUpdateSoftwareDanuxCheck };
