function getIsEventInForm(event: React.FocusEvent<HTMLInputElement>) {
  const { relatedTarget } = event;
  if (!relatedTarget) {
    return false;
  }
  if (
    event &&
    event.target &&
    event.target.form &&
    event.target.form.contains(relatedTarget as HTMLElement)
  ) {
    return true;
  }
  return false;
}

export { getIsEventInForm };
