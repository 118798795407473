export function binarySearch<T, U>(
  data: T[],
  value: U,
  cb: (a: U, b: T) => number,
) {
  let m = 0;
  let n = data.length - 1;
  while (m <= n) {
    const k = (n + m) >> 1;
    const cmp = cb(value, data[k]);
    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }
  return m;
}
