import {
  getMenuItemByMenuIdAndPathname,
  getMenuItemsByMenuId,
} from '../../../utils';

function getTabByRootMenuIdOrMenuId(
  baseRoute: string,
  rootMenuId: string,
  menuId: string,
  pathname: string,
  menuItemsById: {},
  subMenuItemsById: {},
) {
  let closestTab = getMenuItemByMenuIdAndPathname(
    baseRoute,
    menuId,
    pathname,
    menuItemsById,
  );

  if (closestTab) {
    return closestTab;
  }

  closestTab = getMenuItemByMenuIdAndPathname(
    baseRoute,
    menuId,
    pathname,
    subMenuItemsById,
  );

  if (closestTab) {
    return closestTab;
  }

  const rootMenuItems = getMenuItemsByMenuId(rootMenuId, menuItemsById);
  return rootMenuItems.find(menuItem => menuItem.id === menuId);
}

export { getTabByRootMenuIdOrMenuId };
