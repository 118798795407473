import { ConfigurationTabItem } from '@danfoss/etui-sm-xml';
import { getParentMenuItemsByPathname } from '../../../utils';

function getActiveMenuItemsByMenuId(
  baseRoute: string,
  isRootLevel: boolean,
  activeMenuId: string,
  menuItemsById: {
    [key: string]: ConfigurationTabItem[];
  },
  subMenuItemsById: {
    [key: string]: ConfigurationTabItem[];
  },
  pathname: string,
  isParentFallbackEnabled: boolean = true,
) {
  if (isRootLevel) return [];

  let currentActiveMenuItems = menuItemsById[activeMenuId] || [];

  if (!currentActiveMenuItems.length) {
    currentActiveMenuItems = subMenuItemsById[activeMenuId];
  }

  if (
    isParentFallbackEnabled &&
    currentActiveMenuItems &&
    currentActiveMenuItems.length === 0
  ) {
    return getParentMenuItemsByPathname(baseRoute, pathname, menuItemsById);
  }

  return currentActiveMenuItems || [];
}

export { getActiveMenuItemsByMenuId };
