import { styled } from '@danfoss/etui-system';
import { Button } from '@danfoss/etui-system-elements';
import { darken } from '@danfoss/etui-colors';

export const AppHeaderNavItemDropdownItemButton = styled(Button).attrs(
  ({ theme, ...props }) => ({
    position: 'relative',
    bg: 'transparent',
    border: 'none',
    width: '100%',
    height: 36,
    lineHeight: '36px',
    display: 'block',
    cursor: 'pointer',
    textAlign: 'left',
    p: `0 ${theme.spacing.xlg}px 0 40px`,
    color: theme.palette.black[20],
    ...theme.typography.button,
    fontSize: theme.typography.pxToRem(13),
    ...props,
  }),
)`
  &:focus,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) =>
      darken(theme.palette.black[80] as string, theme.palette.tonalOffset)};
  }
`;
