import { XML_DEVICE_LIST } from '../../constants/xml-device-constant.js';

function getUnitMiscConfigCount(unit) {
  const haveRailHeat = parseInt(unit.rail, 10) > 0;
  const haveMiscROs = parseInt(unit.miscro, 10) > 0;
  const haveMiscSIs = parseInt(unit.miscsi, 10) > 0;
  const haveMiscOIs = parseInt(unit.miscoi, 10) > 0;
  const haveMiscVOs = parseInt(unit.miscvo, 10) > 0;
  const haveLeaks = parseInt(unit.leaks, 10) > 0;
  const haveMngrOver = parseInt(unit.ovrs, 10) > 0;
  const haveFans = parseInt(unit.ecfans, 10) > 0;
  let numMisc = 0;
  if (haveRailHeat) {
    numMisc++;
  }
  if (haveMiscROs) {
    numMisc++;
  }
  if (haveMiscSIs) {
    numMisc++;
  }
  if (haveMiscOIs) {
    numMisc++;
  }
  if (haveMiscVOs) {
    numMisc++;
  }
  if (haveLeaks) {
    numMisc++;
  }
  if (haveMngrOver) {
    numMisc++;
  }
  if (haveFans) {
    numMisc++;
  }
  return numMisc;
}
function getUnitsByDeviceType(units, type) {
  return units.filter(unit => {
    switch (type) {
      case XML_DEVICE_LIST.REFRIG:
      case XML_DEVICE_LIST.SUCTION:
        return parseInt(unit.refrig, 10) > 0;
      case XML_DEVICE_LIST.HVAC:
        return parseInt(unit.hvac, 10) > 0;
      case XML_DEVICE_LIST.LIGHTS:
        return parseInt(unit.lights, 10) > 0;
      case XML_DEVICE_LIST.MISC:
        return getUnitMiscConfigCount(unit) > 0;
      case XML_DEVICE_LIST.OI:
        return parseInt(unit.oi, 10) > 0;
      case XML_DEVICE_LIST.RO:
        return parseInt(unit.ro, 10) > 0;
      case XML_DEVICE_LIST.SI:
        return parseInt(unit.si, 10) > 0;
      case XML_DEVICE_LIST.VO:
        return parseInt(unit.vo, 10) > 0;
      case XML_DEVICE_LIST.SCHED:
        return parseInt(unit.scheds, 10) > 0;
      case XML_DEVICE_LIST.VLT:
        return parseInt(unit.vlts, 10) > 0;
      case XML_DEVICE_LIST.CALC:
        return parseInt(unit.calcs, 10) > 0;
      case XML_DEVICE_LIST.LEAK:
        return parseInt(unit.leaks, 10) > 0;
      case XML_DEVICE_LIST.METER:
        return parseInt(unit.meters, 10) > 0;
      case XML_DEVICE_LIST.DRIVE:
        return parseInt(unit.drives, 10) > 0;
      case XML_DEVICE_LIST.OVERRIDES:
        return parseInt(unit.ovrs, 10) > 0;
      case XML_DEVICE_LIST.FANS:
        return parseInt(unit.ecfans, 10) > 0;
      case XML_DEVICE_LIST.T_RACK:
      case XML_DEVICE_LIST.T_EVAP:
      case XML_DEVICE_LIST.T_HVAC:
      case XML_DEVICE_LIST.T_DRIVE:
      case XML_DEVICE_LIST.T_METER:
      case XML_DEVICE_LIST.T_FANS:
      case XML_DEVICE_LIST.T_LIGHT:
      case XML_DEVICE_LIST.T_LEAK:
      case XML_DEVICE_LIST.T_CORE:
        return true;
      default:
        return true;
    }
  });
}

export { getUnitsByDeviceType };
