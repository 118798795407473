import { useContext } from 'react';
import { SCADAContext } from './SCADAContext';
import { SCADAAction, SCADAState } from './types';

export function useSCADA(): {
  scadaStorage: SCADAState;
  scadaDispatch: React.Dispatch<SCADAAction>;
} {
  const context = useContext(SCADAContext);

  if (!context) {
    throw new Error(`useSCADA must be used within a SCADAProvider`);
  }

  return context;
}
