import { useRef, useEffect } from 'react';

export function usePrevious<T>(value: T, skipNullish = false) {
  const ref = useRef<T>();

  useEffect(() => {
    if (skipNullish && [undefined, null].some(v => v === value)) return;

    ref.current = value;
  }, [value]);

  return ref.current;
}
