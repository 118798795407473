import * as React from 'react';
import {
  ConfigurationListItem,
  ConfigurationTabItem,
} from '@danfoss/etui-sm-xml';
import { TABLE_ADDRESS } from '../utils';

export type ItemRenderers = Record<string, (...args: any[]) => React.ReactNode>;

export interface ConfigurationContextProps {
  route: string;
  rootMenuId: TABLE_ADDRESS;
  itemByTabRenderers?: ItemRenderers;
  menuLevelById: {
    [key: string]: number;
  };
  activeMenuItem: any;
  menuItemsById: {
    [key: string]: ConfigurationTabItem[];
  };
  subMenuItemsById: {
    [key: string]: ConfigurationTabItem[];
  };
  configurationDataItem: ConfigurationListItem[];
  multiOptionsList: ConfigurationListItem[];
  onSetMenuLevel: (menuId: string, level: number) => void;
  onSetMenuItems: (menuId: string, items: ConfigurationTabItem[]) => void;
  onSetSubMenuItems: (menuId: string, items: ConfigurationTabItem[]) => void;
  onSetConfigurationDataItem: (
    configurationDataItem: ConfigurationListItem[],
  ) => void;
  cachedDevices: { [key: string]: any[] };
  onSetMultiOptionsList: (
    configurationDataItem: ConfigurationListItem[],
  ) => void;
  isSecurityEnforcementChanged: boolean;
  onSetSecurityEnforcementChanged: (value: boolean) => void;
}

const ConfigurationContext = React.createContext(
  null as ConfigurationContextProps | null,
);

export { ConfigurationContext };
