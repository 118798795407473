import {
  User,
  Unit,
  fetchAlarmSummary,
  getUnitUrl,
  getAlarmSummaryRefsByKey,
} from '@danfoss/etui-sm-xml';
import { ALARM_GROUPED_BY_UNIT_DELIMITER } from '../constants';

const mapToUnitAlarm = (unit: Unit, alarmRef: string) =>
  `${unit.unit_addr}${ALARM_GROUPED_BY_UNIT_DELIMITER}${alarmRef}`;

export async function fetchAllAlarmRefsByUnit(
  url: string,
  user: User,
  unit: Unit,
  skipSessionUpdate?: boolean,
): Promise<{ active: string[]; acked: string[]; cleared: string[] }> {
  try {
    const response = await fetchAlarmSummary(
      getUnitUrl(url, unit),
      user,
      skipSessionUpdate,
    );
    const active = getAlarmSummaryRefsByKey(response, 'active').map(alarmRef =>
      mapToUnitAlarm(unit, alarmRef),
    );
    const acked = getAlarmSummaryRefsByKey(response, 'acked').map(alarmRef =>
      mapToUnitAlarm(unit, alarmRef),
    );
    const cleared = getAlarmSummaryRefsByKey(response, 'cleared').map(
      alarmRef => mapToUnitAlarm(unit, alarmRef),
    );

    return {
      active,
      acked,
      cleared,
    };
  } catch (error) {
    return {
      active: [],
      acked: [],
      cleared: [],
    };
  }
}
