import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@danfoss/etui-system';
import { defaultTheme } from '@danfoss/etui-themes';
import {
  AppStateProvider,
  AuthProvider,
  UnitProvider,
  AlarmProvider,
  ModalProvider,
} from '@danfoss/etui-sm';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './i18n';
import { App } from 'components/App';
import { AppProvide } from 'components/AppProvide';
import { SwrGlobal } from 'components/SwrGlobal';
import { featureHubApiKey, featureHubUrl } from 'config/index';
import { FeatureHubProvider } from 'hooks/featurehub/FeatureHubContext';
import Initialization from 'components/Initialization/Initialization';
import { SCADAProvider } from 'hooks/scada/SCADAContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const MOUNT_NODE = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <AppProvide
      providers={[
        [
          FeatureHubProvider,
          {
            url: featureHubUrl,
            apiKey: featureHubApiKey,
          },
        ],
        [ThemeProvider, { theme: defaultTheme }],
        AuthProvider,
        SwrGlobal,
        AppStateProvider,
        HashRouter,
        UnitProvider,
        SCADAProvider,
        ModalProvider,
        AlarmProvider,
        [DndProvider, { backend: HTML5Backend }],
      ]}
    >
      <Initialization>
        <App />
      </Initialization>
    </AppProvide>,
    MOUNT_NODE,
  );
};
render();

if (!window.electronConstants) {
  serviceWorkerRegistration.register();
}
