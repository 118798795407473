import { XML_DEVICE_STR, XML_DEVICE_COMBO } from '@danfoss/etui-sm-xml';

/**
 * Utility to validate if device is a meter
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsMeterDevice(device) {
  if (device.type === XML_DEVICE_STR.STR_TYPE_METER && device.combo === XML_DEVICE_COMBO.COMBO_ENERGY) {
    return true;
  }
  return false;
}

export { getIsMeterDevice };
