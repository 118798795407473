import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { useTranslation } from 'react-i18next';
import { LayoutFlowTitle } from '../LayoutContent/LayoutFlowTitle';
import { PI200FlowTable } from './PI200FlowTable';

export interface PI200FlowPageProps {
  tableDataLoading: boolean;
  handleOnSelect: (record: ConfigurationListItem) => void;
}

export const PI200FlowPage = ({
  tableDataLoading,
  handleOnSelect,
}: PI200FlowPageProps) => {
  const { t } = useTranslation();

  return (
    <Div testId="pi200-flow-page-div" mt="10px">
      <LayoutFlowTitle title={t('t3384')} subTitle={t('t3385')} />
      <PI200FlowTable
        tableDataLoading={tableDataLoading}
        handleOnSelect={handleOnSelect}
      />
    </Div>
  );
};
