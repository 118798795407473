import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { TABLE_ADDRESS } from '../../../utils';

export const isListItemTextOnly = (
  item: ConfigurationListItem,
  menuId: string,
): boolean => {
  const isOverride = menuId?.split('-')[0] === TABLE_ADDRESS.CONTROL_OVERRIDE;
  const isEdaEfZero = item && item.eda === '0' && item.ef === '0';
  const isValueEmpty = item && !item.value;

  return isOverride
    ? isEdaEfZero && !isValueEmpty
    : isEdaEfZero && isValueEmpty;
};
