const getQuerySchedTypeByParamsEquipmentId = (match: {
  params: { equipmentId: string; tabview: string };
}) => {
  if (match && match.params && match.params.equipmentId) {
    const [schedType] = match.params.equipmentId.split('_');
    return schedType;
  }
  return null;
};

export { getQuerySchedTypeByParamsEquipmentId };
