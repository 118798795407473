import { TFunction } from 'i18next';
import {
  DeviceConstraintsData,
  DEVICE_PACK_SUCTION_NAME,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../types/DiscoveryPage.types';
import { getCurrentSuctionGroupCount, getSuctionGroupCount } from '../utils';
import { insertSuctionGroup } from './compressor-flow-copy-actions';
import {
  removeSuctionGroup,
  updateSuctionGroupCount,
} from './compressor-flow-delete-actions';

function updateSuctionGroups(
  selectedRowIndex: number,
  selectedSuctionGroupValue: number,
  discoveryTableData: DiscoveryTableData[],
  deviceSuction: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  t: TFunction,
) {
  let num_groups = selectedSuctionGroupValue;
  const maxSg = +deviceConstraintsData.max_sg;
  const totalSuctionCount = getSuctionGroupCount(deviceSuction);
  const currentSuctionCount = getCurrentSuctionGroupCount(
    deviceSuction,
    selectedRowIndex,
  );
  const totalGroupsInOtherRacks = totalSuctionCount - currentSuctionCount;
  if (num_groups > Number(deviceSuction[selectedRowIndex]?.max_sg)) {
    num_groups = Number(deviceSuction[selectedRowIndex]?.max_sg);
  }
  if (totalGroupsInOtherRacks + num_groups > maxSg) {
    num_groups = maxSg - totalGroupsInOtherRacks;
  }
  if (
    (deviceSuction[selectedRowIndex].deviceType === DEVICE_TYPE.GEN_PACK ||
      deviceSuction[selectedRowIndex].deviceType === DEVICE_TYPE.NO_COMP) &&
    num_groups < 1
  ) {
    num_groups = 1;
  }
  return handleGroupCountChanged(
    selectedRowIndex,
    discoveryTableData,
    deviceSuction,
    deviceConstraintsData,
    num_groups,
    t,
  );
}

const handleGroupCountChanged = (
  selectedRowIndex: number,
  discoveryTableData: DiscoveryTableData[],
  deviceSuction: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  num_groups: number,
  t: TFunction,
) => {
  let origNumGroups: number = 1;
  const { rk, deviceId, deviceType } = deviceSuction[selectedRowIndex];
  let name: string = '';
  for (const device of discoveryTableData) {
    let newDevice = device;
    if (newDevice.deviceId === deviceId) {
      origNumGroups = newDevice.num_sg;
      if (origNumGroups === num_groups) {
        return discoveryTableData;
      }
      if (getSuctionGroupCount(deviceSuction) > +deviceConstraintsData.max_sg) {
        deviceSuction[selectedRowIndex].num_sg = origNumGroups;
        return discoveryTableData;
      }
      if (origNumGroups !== 0) {
        if (
          discoveryTableData.indexOf(device) + 1 <
          discoveryTableData.length
        ) {
          newDevice =
            discoveryTableData[discoveryTableData.indexOf(device) + 1];
        }
        if (newDevice?.name?.toString()?.length) {
          if (
            deviceType === DEVICE_TYPE.RACK ||
            deviceType === DEVICE_TYPE.NO_COMP
          ) {
            name = newDevice?.name?.substring(
              0,
              newDevice.name.toString().length - 1,
            );
          } else if (deviceType === DEVICE_TYPE.GEN_PACK) {
            name = newDevice.name.substring(
              0,
              newDevice.name.toString().length - 2,
            );
          }
        }
      } else {
        if (deviceType === DEVICE_TYPE.RACK) {
          name = `${t('t402')} ${String.fromCharCode(65 + newDevice.rk - 1)}`;
        }
        if (deviceType === DEVICE_TYPE.GEN_PACK) {
          name = `${t('t402')} `;
        }
      }
      break;
    }
  }
  const totalGroupsInOtherRacks: number =
    getSuctionGroupCount(deviceSuction) -
    getCurrentSuctionGroupCount(deviceSuction, selectedRowIndex);
  if (num_groups + totalGroupsInOtherRacks >= +deviceConstraintsData.max_sg) {
    num_groups = +deviceConstraintsData.max_sg - totalGroupsInOtherRacks;
    deviceSuction[selectedRowIndex].num_sg = num_groups; // need to update in discovery obj
  }
  if (origNumGroups === num_groups) {
    return discoveryTableData;
  }

  let index = 0;
  let suctionName: string;
  if (num_groups > origNumGroups) {
    for (index = origNumGroups; index < num_groups; index++) {
      if (
        deviceType === DEVICE_TYPE.RACK ||
        deviceType === DEVICE_TYPE.NO_COMP
      ) {
        suctionName = String.fromCharCode(65 + index);
      }
      if (deviceType === DEVICE_TYPE.GEN_PACK) {
        suctionName = DEVICE_PACK_SUCTION_NAME[index];
      }
      insertSuctionGroup(
        rk,
        name + suctionName,
        true,
        discoveryTableData,
        deviceConstraintsData,
        t,
      );
    }
  } else {
    for (index = origNumGroups; index > num_groups; index--) {
      removeSuctionGroup(rk, discoveryTableData);
    }
  }
  updateSuctionGroupCount(rk, num_groups, discoveryTableData);
  return discoveryTableData;
};

export { updateSuctionGroups };
