const getXml = function (node, linefeed) {
  if (linefeed === void 0) {
    linefeed = '';
  }
  const xmlSerializer = new XMLSerializer();
  let xml = xmlSerializer.serializeToString(node);
  linefeed = linefeed || '&#xa;';
  xml = xml.replace(/\n/g, linefeed);
  return xml;
};

export { getXml };
