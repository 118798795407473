import { styled } from '@danfoss/etui-system';
import { Article } from '@danfoss/etui-system-elements';
import { mediaMax } from '@danfoss/etui-core/utilities';

const Page = styled(Article)`
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;

  padding-top: ${_ref => {
  let {
    hasMenu,
    isFullPage,
    theme
  } = _ref;
  return hasMenu ? theme.shape.commandBarHeight : isFullPage ? 0 : theme.spacing.xlg;
}}px;

  ${_ref2 => {
  let {
    theme,
    hasMenu,
    isFullPage
  } = _ref2;
  return mediaMax(theme).xs`
    padding-top: ${hasMenu || isFullPage ? 0 : theme.spacing.md}px;
    padding-bottom: ${hasMenu ? theme.shape.commandBarHeight : 0}px;
  `;
}};
`;

export { Page };
