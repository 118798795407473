import { setProxyConfig } from '@danfoss/etui-sm-xml';
import { STORAGE_PROXY_KEY } from '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';

function parseProxyConfigString(proxyConfigString) {
  try {
    return JSON.parse(proxyConfigString);
  } catch (e) {
    return null;
  }
}

/**
 * Gets proxy configuration from local storage if exists
 *
 * @returns {AppState['proxy']}
 */
function getXmlProxyConfig() {
  const smProxyString = sessionStorage.getItem(STORAGE_PROXY_KEY);
  const parsedProxyConfig = parseProxyConfigString(smProxyString);
  setProxyConfig(parsedProxyConfig || {});
  return parsedProxyConfig;
}

export { getXmlProxyConfig, parseProxyConfigString };
