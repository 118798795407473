import * as React from 'react';

export const useKeyboardEvent = (key: string, callback: (event) => void) => {
  React.useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === key) {
        callback(event);
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, []);
};
