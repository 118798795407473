import SplashScreen from 'components/SplashScreen/SplashScreen';
import { useFeatureHub } from 'hooks/featurehub/FeatureHubContext';
import React from 'react';

interface InitializationProps {
  children: React.ReactNode;
}

const Initialization = ({ children }: InitializationProps) => {
  const { isReady } = useFeatureHub();
  if (!isReady) {
    return <SplashScreen />;
  }
  return <>{children}</>;
};

export default Initialization;
