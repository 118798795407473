import {
  Refrigerations,
  ScheduleItem,
  ScheduleTypes,
} from '../SchedulesPage.types';

const devicesByType = {
  [ScheduleTypes.DEFROST]: 'defrost_devices',
  [ScheduleTypes.NIGHT_SETBACK]: 'night_setback_devices',
  [ScheduleTypes.SHUTDOWN]: 'shutdown_devices',
  [ScheduleTypes.CASE_LIGHTING]: 'case_lighting_devices',
};

const scheduleIdByType = {
  [ScheduleTypes.DEFROST]: 'defrost_sch_id',
  [ScheduleTypes.NIGHT_SETBACK]: 'night_setback_sch_id',
  [ScheduleTypes.SHUTDOWN]: 'shutdown_sch_id',
  [ScheduleTypes.CASE_LIGHTING]: 'case_light_sch_id',
};

const getScheduleCategory = (refrigerations, scheduleType) =>
  refrigerations.find(category => scheduleType.includes(category.id));

export const findSceduleDevices = (
  refrigerations: Refrigerations[] = [],
  scheduleType: ScheduleTypes,
  scheduleId: string,
  ids: string[] = null,
): ScheduleItem[] => {
  const type =
    scheduleType === ScheduleTypes.CASE_LIGHTING
      ? ScheduleTypes.CASE_LIGHTS
      : scheduleType;

  const category = getScheduleCategory(refrigerations, type);
  const suctions = category.items
    .map(categoryItem => categoryItem.items)
    .flat();

  const selectedSuction = suctions.find(suction =>
    suction.id.includes(scheduleId),
  );

  const devices = devicesByType[scheduleType];
  const scheduleIdProp = scheduleIdByType[scheduleType];

  const suctionCases = selectedSuction.scheduleDevices[devices];

  const cases = ids
    ? suctionCases.filter(caseDevice =>
        ids.includes(caseDevice[scheduleIdProp]),
      )
    : suctionCases;

  return cases;
};
