export const defineMissingPages = <T>(
  dataStructure: { [key: number]: T },
  page: number,
): number[] => {
  const result = [];
  let index = 0;

  while (page >= index) {
    if (!(index in dataStructure)) {
      result.push(index);
    }

    index++;
  }

  return result;
};
