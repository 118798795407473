import { TFunction } from 'i18next';
import {
  DeviceConstraintsData,
  DEVICE_STATUS,
  DiscoveryTableData,
} from '../../../RefrigLayoutModal/types';
import { getGenericCount } from '../utils';
import { getDeviceIndex } from '../../../RefrigLayoutModal/utils';

export const copyHvacDevice = (
  copyAmount: number,
  selectedRowIndex: number,
  hvacFinalTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  t: TFunction,
) => {
  if (selectedRowIndex < 0 || selectedRowIndex >= hvacFinalTableData?.length) {
    selectedRowIndex = 0;
  }
  for (let index = 0; index < copyAmount; index++) {
    copyHvac(
      selectedRowIndex,
      hvacFinalTableData,
      deviceConstraintsData,
      t,
      index,
    );
  }
  return hvacFinalTableData;
};

const copyHvac = (
  selectedRowIndex: number,
  hvacFinalTableData: DiscoveryTableData[],
  deviceConstraintsData: DeviceConstraintsData,
  t: TFunction,
  index: number,
) => {
  if (getGenericCount(hvacFinalTableData) >= deviceConstraintsData?.max_ah) {
    return;
  }
  const selectedDevice: DiscoveryTableData =
    hvacFinalTableData[selectedRowIndex];
  const dimValue = +selectedDevice?.dim1 + index;
  const copiedDeviceData: DiscoveryTableData = {
    newDevice: true,
    deviceId: getDeviceIndex(hvacFinalTableData) + 1,
    ip: '',
    address: selectedDevice.address !== '-' ? '0' : '-',
    cfg: '0',
    online: '0',
    dim1: dimValue.toString(),
    name:
      selectedDevice?.name !== ''
        ? `${selectedDevice?.name} ${dimValue}`
        : `${selectedDevice?.name} ${t('t87')} ${dimValue}`,
    removed: false,
    category: selectedDevice?.category,
    deviceType: selectedDevice?.deviceType,
    type: selectedDevice?.type,
    model: selectedDevice?.model,
    file: selectedDevice?.file,
    version: selectedDevice?.version,
    device: selectedDevice?.device,
    listname: selectedDevice?.listname,
    model_idx: selectedDevice?.model_idx,
    dim2: '0',
    dim3: '0',
    status: DEVICE_STATUS.RED,
    deviceBus: selectedDevice?.deviceBus,
    mx_naddr: selectedDevice?.mx_naddr,
    ah: selectedDevice?.ah,
  };
  hvacFinalTableData.push(copiedDeviceData);
};
