import { getAlarmSummaryRefsByKey } from './helpers/alarm-helpers.js';
import './helpers/xml-helpers.js';
import { fetchAlarmSummary } from './actions/alarm-actions/fetch-alarm-summary.js';

/**
 * Get alarm refs by alarm type
 *
 * @param {string} url
 * @param {AlarmType} alarmType
 * @param {User} user
 * @returns {Promise<string[]>}
 */
async function fetchAlarmRefsByTypes(url, alarmTypes, user, skipSessionUpdate) {
  const alarmSummaryData = await fetchAlarmSummary(url, user, skipSessionUpdate);
  const alarmRefs = {};
  alarmTypes.forEach(alarmType => {
    alarmRefs[alarmType] = getAlarmSummaryRefsByKey(alarmSummaryData, alarmType);
  });
  return alarmRefs;
}

export { fetchAlarmRefsByTypes as f };
