import { getUrlInfo } from '@danfoss/etui-sm-xml';
import { STORAGE_UNITXMLURL_KEY } from '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';
import { getSessionStorageValue } from './get-session-storage-value.js';

function getXmlBackendIp() {
  const xmlUrl = getSessionStorageValue(STORAGE_UNITXMLURL_KEY);
  const {
    host
  } = getUrlInfo(xmlUrl || '');
  return host;
}

export { getXmlBackendIp };
