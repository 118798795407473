import * as React from 'react';
import { Div, Span } from '@danfoss/etui-system-elements';
import {
  DateRangePicker,
  Modal,
  Button,
  Row,
  Col,
  Form,
} from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { TimeInput } from '../TimeInput';
import { AmPm, timeToMs } from '../TimeInput/utils';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { ConfigurationListItemEditDisplayValue } from './ConfigurationListItemEditDisplayValue';
import { ConfigurationListItemEditLoadingOverlay } from './ConfigurationListItemEditLoadingOverlay';
import { useFlash } from './hooks/useFlash';
import {
  buildDateTimeToDisplay,
  buildTime,
  getCurrentDateTime,
  getInitialDateTime,
} from './utils';

function ConfigurationListItemEditDateTime({
  item,
  name,
  autoFocus,
  isAuthorized,
  onSave,
}: BaseEditProps) {
  const [, , itemAmPm] = item.value.split(' ');
  const hourFormat = item.tf === '1' ? '24' : '12';
  const dateName = `datetime-date-${name}`;
  const timeName = `datetime-time-${name}`;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [externalSource, setExternalSource] = React.useState(false);
  const theme = useTheme();

  const { control, setValue, reset, formState, handleSubmit, clearErrors } =
    useForm();
  const { isSubmitting } = formState;
  const [startErrorFlash, isError] = useFlash(2000, () => clearErrors());
  const [startSuccessFlash, isSuccess] = useFlash(2000, () => clearErrors());
  const iconXOffset = isSubmitting ? 42 : 12;
  const [dayPeriod, setDayPeriod] = React.useState(itemAmPm);
  const [defaultDate, defaultTime] = React.useMemo(
    () => getInitialDateTime(item),
    [item.value],
  );
  const [defaultValue, setDefaultValue] = React.useState({
    [timeName]: defaultTime,
    [dateName]: defaultDate,
  });

  function onClose() {
    setIsOpen(false);
  }

  const handleOnSubmit = async values => {
    const _time = values[timeName];
    const _date = values[dateName];
    // create date
    const year = _date.getFullYear();
    const month = _date.getMonth() + 1;
    const day = _date.getDate();
    const dateString = `${year}-${month}-${day}`;
    const date = new Date(dateString);
    // create time
    const time = timeToMs(_time);
    const secondToMs = 1000;
    const newDateTimeMs = date.getTime() + time;
    const fullDate = new Date(newDateTimeMs);
    const newValue = (fullDate.getTime() / secondToMs).toString();
    const formatedDateAndTime = buildDateTimeToDisplay(
      _date,
      _time,
      hourFormat,
    );
    try {
      await onSave(item, {
        ival: newValue,
        newValue: formatedDateAndTime,
      });

      setDayPeriod(parseInt(_time, 10) > 11 ? AmPm.PM : AmPm.AM);
      item.value = formatedDateAndTime;
      setDefaultValue({
        [timeName]: buildTime(_time, hourFormat),
        [dateName]: _date,
      });
      setExternalSource(false);
      startSuccessFlash();
      onClose();
    } catch (e) {
      // in case some specific styles around controls are needed on error
      // this can be uncommented and used
      // [dateName, timeName].forEach(n => setError(n, { type: 'server' }));
      // eslint-disable-next-line no-console
      console.error(e);
      reset(defaultValue);
      startErrorFlash();
    }
  };

  React.useEffect(() => {
    reset(defaultValue);
  }, [isOpen]);

  const setTimeToNow = () => {
    const [date, time] = getCurrentDateTime();
    const currentValue = {
      [timeName]: time,
      [dateName]: date,
    };
    setDayPeriod(parseInt(time, 10) > 11 ? AmPm.PM : AmPm.AM);
    setExternalSource(true);
    reset(currentValue);
  };

  return isAuthorized ? (
    <Div position="relative">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={<Modal.Header title={t('t83')} />}
        styles={{
          contentContainer: {
            height: '330px',
            p: `${theme.spacing.xs}px ${theme.spacing.md}px`,
          },
        }}
      >
        <Form
          testId="time-and-date-form"
          onSubmit={handleSubmit(handleOnSubmit)}
          styles={{
            root: {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
          }}
        >
          <Div>
            <Row>
              <Controller
                control={control}
                name={dateName}
                defaultValue={defaultValue[dateName]}
                render={({ onChange, value }) => (
                  <DateRangePicker
                    // TODO use user's format
                    dateFormat="MM/DD/YY"
                    startDate={value}
                    endDate={value}
                    disableRange={true}
                    label=""
                    onChange={({ startDate }) => {
                      setValue(dateName, startDate);
                      onChange(startDate);
                    }}
                    styles={{
                      input: {
                        height: '42px',
                        fontSize: theme.typography.fontSizeBase,
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={timeName}
                defaultValue={defaultValue[timeName]}
                render={({ onChange, value }) => {
                  return (
                    <TimeInput
                      size="small"
                      externalSourceValue={
                        externalSource && `${value} ${dayPeriod}`
                      }
                      defaultValue={defaultValue[timeName] as string}
                      defaultAmPm={dayPeriod as AmPm}
                      hourFormat={hourFormat}
                      autoFocus={autoFocus}
                      onTimeChange={({ value: newTime }) => {
                        setExternalSource(false);
                        setValue(timeName, newTime);
                        onChange(newTime);
                      }}
                      styles={{
                        root: {
                          ml: `${theme.spacing.sm}px`,
                        },
                        input: {
                          width: '100px',
                        },
                      }}
                    />
                  );
                }}
              />
            </Row>
            <Row
              styles={{
                root: {
                  justifyContent: 'flex-end',
                },
              }}
            >
              <Button
                variant="tertiary"
                width="100px"
                small={true}
                onClick={setTimeToNow}
                m={`${theme.spacing.md}px`}
              >
                {t('t859')}
              </Button>
            </Row>
          </Div>

          {/* Buttons are here and not in Modal's actions for datepicker popup to overflow them */}
          <Row spacingSize="xxs">
            <Col xs={6} spacingSize="xxs">
              <Button variant="primary" type="submit" block={true}>
                {t('t266')}
              </Button>
            </Col>
            <Col xs={6} spacingSize="xxs">
              <Button variant="secondary" onClick={onClose} block={true}>
                {t('t45')}
              </Button>
            </Col>
          </Row>
        </Form>
        {(isSubmitting || isError) && (
          <ConfigurationListItemEditLoadingOverlay isError={isError} />
        )}
      </Modal>

      <ConfigurationListItemEditDisplayValue
        testId="modal-toggler"
        onClick={() => setIsOpen(true)}
        value={item.value}
      />
      <Div
        display="flex"
        alignItems="center"
        position="absolute"
        top="0"
        right={['unset', `-${iconXOffset}px`]}
        left={[`-${iconXOffset}px`, 'unset']}
        height="42px"
      >
        <ConfigurationListitemEditInputAddon
          isSubmitting={isSubmitting}
          isSucceeded={isSuccess}
          isFailed={isError}
          styles={{
            inputAddon: {
              root: {
                top: isSubmitting ? '8px' : '4px',
              },
            },
          }}
        />
      </Div>
    </Div>
  ) : (
    <Span>{item.value}</Span>
  );
}

export { ConfigurationListItemEditDateTime };
