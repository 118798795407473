import {
  DEVICE_BUS,
  DEVICE_BUS_DESCRIPTION,
} from '../types/DiscoveryPage.types';

export const getBusDescription = (deviceBus: number, ip: string): string => {
  switch (deviceBus) {
    case DEVICE_BUS.LON:
      return DEVICE_BUS_DESCRIPTION.LON;
    case DEVICE_BUS.SNMP:
      return `${DEVICE_BUS_DESCRIPTION.SNMP} ${ip}`;
    case DEVICE_BUS.MODBUS:
      return DEVICE_BUS_DESCRIPTION.MODBUS;
    case DEVICE_BUS.PI200:
      return DEVICE_BUS_DESCRIPTION.PI200;
    case DEVICE_BUS.EXT_MODBUS:
      return DEVICE_BUS_DESCRIPTION.EXT_MODBUS;
    case DEVICE_BUS.AK2_IP:
      return DEVICE_BUS_DESCRIPTION.AK2_IP;
    default:
      return '-';
  }
};
