import * as React from 'react';
import { DeviceBasedMenu, DeviceTreeView } from 'components/DeviceBasedMenu';
import { TreeItem, Button } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { DeviceType } from '@danfoss/etui-sm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Div } from '@danfoss/etui-system-elements';
import { HistoryTimeRange } from './components/HistoryTimeRange';
import { HistoryTreeItem } from './components/OldHistoryTreeItem';
import { isHistoryObj } from './utils/history-utils';

export function HistoryPageLeftMenu({
  menuEntries,
  dateRange,
  setDateRange,
  formats,
  data,
  checkedItems,
  setCheckedItems,
  fetchParams,
  alreadyFetched,
  startHistoryQuery,
  group,
  setGroup,
  canDraw,
  isAllHistoryParamsLoaded,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm({
    mode: 'all',
  });

  const handleClearCheckedItems = (items: TreeItem[]) => {
    const itemsIds = items.map(item => item.id);
    const newItems = checkedItems.filter(i => !itemsIds.includes(i));
    setCheckedItems(newItems);
  };

  const renderItem = item =>
    item === 'time-range' ? (
      <HistoryTimeRange
        control={control}
        dateRange={dateRange}
        setDateRange={setDateRange}
        timeFormat={formats?.timeFormat}
        dateFormat={formats?.dateFormat}
      />
    ) : (
      <DeviceTreeView
        isFilterDisabled={!data}
        enableFilter={true}
        data={data}
        type={item as DeviceType}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
        getChildrenToCheck={({ items = [] }) =>
          items.filter(isHistoryObj).map(({ id }) => id)
        }
        rowTemplate={props => (
          <HistoryTreeItem
            key={props.item.name}
            {...props}
            onFetchSubItems={fetchParams}
            alreadyFetched={alreadyFetched}
            isAllHistoryParamsLoaded={isAllHistoryParamsLoaded}
            checkIfShouldFetch={(treeItem: TreeItem) =>
              !isHistoryObj(treeItem) && !alreadyFetched.has(treeItem.id)
            }
            formats={formats}
          />
        )}
      />
    );

  return (
    <>
      <DeviceBasedMenu
        entries={menuEntries as any}
        minHeightItems={new Set(['time-range'])}
        onMenuGroupChange={setGroup}
        preExpanded={[group]}
        bottomSpace={65} // button height
        renderEntry={renderItem}
        data={data}
        checkedItems={checkedItems}
        onClearCheckedItems={handleClearCheckedItems}
      />
      <Div
        position="absolute"
        bottom={0}
        width="100%"
        p={`${theme.spacing.xs}px`}
        borderTop={`1px solid ${theme.palette.divider}`}
      >
        <Button
          onClick={handleSubmit(startHistoryQuery)}
          variant="primary"
          width="100%"
          disabled={!canDraw}
          testId="draw-history"
        >
          {t('t879')}
        </Button>
      </Div>
    </>
  );
}
