import { ScheduleTypeConstant } from '../SchedulesPage.types';

const getScheduleTypeValue = (type: string): number => {
  const schedType =
    type === 'Shutdown'
      ? ScheduleTypeConstant.SCHED_SHUTDOWN
      : type === 'Defrost'
      ? ScheduleTypeConstant.SCHED_DEFROST
      : type === 'Case Lights'
      ? ScheduleTypeConstant.SCHED_CASE_LIGHTS
      : type === 'Circuit night setbacks'
      ? ScheduleTypeConstant.SCHED_NIGHTSB
      : type === 'Suction night setbacks'
      ? ScheduleTypeConstant.SCHED_NIGHTSB
      : type === 'Night Setback'
      ? ScheduleTypeConstant.SCHED_NIGHTSB
      : null;

  return schedType;
};

const isScheduleTypeConfigured = (type: string, schedules: any): boolean => {
  const schedType = getScheduleTypeValue(type).toString();
  if (schedules?.device?.length) {
    const isConfigured = schedules.device.some(
      schedDevice => schedDevice?.schedules?.schedType === schedType,
    );
    return isConfigured;
  }
  return false;
};

export { getScheduleTypeValue, isScheduleTypeConfigured };
