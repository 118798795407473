import { Device } from '@danfoss/etui-sm-xml';
import { getSourceIdMeta } from 'pages/GraphicEditorPage/actions';

export function findDeviceForParameter(
  parameterId,
  parameters: (Device & { id: string })[],
) {
  if (parameterId) {
    const { host, nodetype, node, mod, point } = getSourceIdMeta(parameterId);
    return parameters?.find(param => {
      const attr = getSourceIdMeta(param.id);
      return (
        `${attr.host}` === `${host}` &&
        `${attr.nodetype}` === `${nodetype}` &&
        `${attr.node}` === `${node}` &&
        `${attr.mod}` === `${mod}` &&
        `${attr.point}` === `${point}`
      );
    });
  }
  return null;
}
