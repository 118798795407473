import '../constants/session-storage-constants.js';
import { XML_ACTION } from '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Metadata.js';
import { _ as _objectSpread2, a as _objectWithoutProperties } from '../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import 'csvtojson';
import '../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../fetch-xml/fetch-xml-data.js';

const _excluded = ["url", "user", "filename", "progressCb", "access_area"];
function getFileChunk(url, user, offset, filename, access_area, attrs) {
  if (attrs === void 0) {
    attrs = {};
  }
  return fetchXMLData({
    url,
    attributes: _objectSpread2({
      version: 'C',
      storeview_only: '1',
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.GET_FILE,
      filename,
      offset,
      access_area
    }, attrs)
  });
}
async function getFile(_ref) {
  let {
      url,
      user,
      filename,
      progressCb = () => {},
      access_area
    } = _ref,
    attrs = _objectWithoutProperties(_ref, _excluded);
  let completed = false;
  let offset = 0;
  let fileContent = '';
  let loadedBytes = 0;
  while (!completed) {
    // eslint-disable-next-line no-await-in-loop
    const file = await getFileChunk(url, user, offset, filename, access_area, attrs);
    if (file) {
      fileContent += file.encodedfile.b64;
      if (file.done === '1') {
        completed = true;
      } else {
        loadedBytes += Number(file.num_bytes);
        offset = Array.isArray(file.offset) ? file.offset[file.offset.length - 1] : file.offset;
      }
      progressCb(Math.floor(loadedBytes / Number(file.enc_bytes) * 100));
    } else {
      completed = true;
      fileContent = null;
    }
  }
  progressCb(100);
  return fileContent;
}

export { getFile, getFileChunk };
