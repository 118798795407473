import { AkioDefrostSchedules, Refrigerations } from '../SchedulesPage.types';
import { filterCaseLevelAkioDefrostSchedules } from './filterCaseLevelAkioDefrostSchedules';
import { filterPackLevelAkioDefrostSchedules } from './filterPackLevelAkioDefrostSchedules';
import { filterSuctionLevelAkioDefrostSchedules } from './filterSuctionLevelAkioDefrostSchedules';

export const filterAkioDefrostSchedules = (
  refrigerations: Refrigerations[],
  schedulesType: string,
  schedulesIds: string,
): AkioDefrostSchedules[] => {
  if (!schedulesIds) {
    return filterCaseLevelAkioDefrostSchedules(refrigerations, schedulesType);
  }
  if (schedulesIds.includes('pack')) {
    return filterPackLevelAkioDefrostSchedules(
      refrigerations,
      schedulesType,
      schedulesIds,
    );
  }
  if (schedulesIds.includes('suction')) {
    return filterSuctionLevelAkioDefrostSchedules(
      refrigerations,
      schedulesType,
      schedulesIds,
    );
  }
};
