import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { Toggle } from '@danfoss/etui-core';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { useFlash } from './hooks/useFlash';

function ConfigurationListItemEditToggle({
  item,
  isAuthorized,
  onSave,
  name,
}: BaseEditProps) {
  const valueProperty = 'toggleVal';
  const initialValue = item[valueProperty];
  const [isChecked, setIsChecked] = React.useState(initialValue !== '0');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // before the poll tick toggle control is undetermined
  // after the changes are applied or not, we need to sync
  const syncUi = React.useCallback(() => {
    setIsChecked(initialValue !== '0');
  }, [initialValue]);

  const [startSuccessFlash, isSuccess] = useFlash(2000, syncUi);
  const [startErrorFlash, isError] = useFlash(2000, syncUi);

  const handleSave = React.useCallback(async () => {
    const newValue = !isChecked;
    const tmpValue = newValue ? '1' : '0';
    setIsSubmitting(true);
    setIsChecked(newValue);
    try {
      await onSave(item, {
        value: '',
        ival: item.iVal,
        fval: item.fVal,
        valueProperty,
        tmpValue,
      });
      startSuccessFlash();
    } catch (e) {
      startErrorFlash();
      setIsChecked(isChecked);
    } finally {
      setIsSubmitting(false);
    }
  }, [item, isChecked, onSave]);

  React.useEffect(() => {
    syncUi();
  }, [syncUi]);

  const iconXOffset = isSubmitting ? 24 : 52;

  return (
    <Div position="relative">
      <Toggle
        name={name}
        isChecked={isChecked}
        onChange={handleSave}
        renderLabels={() => item.value}
        isDisabled={!isAuthorized || isSubmitting || isError || isSuccess}
      />
      <Div
        display="flex"
        alignItems="center"
        position="absolute"
        top="-6px"
        right={['unset', `${iconXOffset}px`]}
        left={[`${iconXOffset}px`, 'unset']}
        height="42px"
      >
        <ConfigurationListitemEditInputAddon
          isSubmitting={isSubmitting}
          isSucceeded={isSuccess}
          isFailed={isError}
          styles={{
            inputAddon: {
              root: {
                top: isSubmitting ? '8px' : '4px',
              },
            },
          }}
        />
      </Div>
    </Div>
  );
}

export { ConfigurationListItemEditToggle };
