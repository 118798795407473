import {
  Device,
  fetchXMLData,
  UnitNetwork,
  User,
  XML_ACTION,
  XML_DEVICE_NODETYPE,
} from '@danfoss/etui-sm-xml';
import { getArray } from 'utils';
import { getNodeTypeFromAction } from '../utils/get-node-type-from-action';
import { MIGRATION_FAILURE } from '../utils/migration-error-codes';

type Parameter<T = any> = T & {
  /**
   * Computed id
   *
   * @type {string}
   */
  id: string;
};

async function getGenericDeviceParams(
  url,
  user,
  action,
  responseKey,
  nodeType,
  unit,
  retryCount,
) {
  let resp;
  let result;
  try {
    resp = await fetchXMLData(fetchRequestContent(url, user, action));
    result = mapAkioRespToParameters(resp[responseKey], nodeType, unit);
  } catch (e) {
    if (e && retryCount < 3) {
      retryCount++;
      return getGenericDeviceParams(
        url,
        user,
        action,
        responseKey,
        nodeType,
        unit,
        retryCount,
      );
    }
    throw new Error(MIGRATION_FAILURE);
  }
  return result;
}

export async function fetchDeviceParameters(
  url: string,
  user: User,
  action: XML_ACTION,
  unit: UnitNetwork,
  device?: any,
): Promise<Parameter[]> {
  const retryCount = 0;
  const { nodeType, responseKey } = getNodeTypeFromAction(action);
  if (nodeType !== XML_DEVICE_NODETYPE.NODETYPE_GN) {
    return getGenericDeviceParams(
      url,
      user,
      action,
      responseKey,
      nodeType,
      unit,
      retryCount,
    );
  }
  if (device) {
    return getGenericDeviceParameters(url, user, device, unit);
  }
  return null;
}

async function getGenericDeviceParameters(url, user, device, unit) {
  const retryCount = 0;
  return fetchGenericDeviceParams(url, user, device, unit, retryCount);
}

async function fetchGenericDeviceParams(url, user, device, unit, retryCount) {
  let result;
  try {
    const node = 1000 + parseInt(device.filex, 10);

    const { params } = await fetchXMLData<{ params: { name: [] } }>({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        lang: user.language,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.READ_DEVICE_PARAMS,
        node,
      },
    });

    result = mapGenericRespToParameters(params.name, device, unit);
  } catch (e) {
    if (e && retryCount < 3) {
      retryCount++;
      return fetchGenericDeviceParams(url, user, device, unit, retryCount);
    }
    throw new Error(MIGRATION_FAILURE);
  }
  return result;
}

function mapAkioRespToParameters(
  params: any[],
  nodetype: XML_DEVICE_NODETYPE,
  unit: UnitNetwork,
) {
  if (params) {
    const result = getArray(params).map(param => {
      let id = `misc_${unit.addr}-${nodetype}-${param.node}-0-0-${
        param.mod || 0
      }-${param.point || 0}`;
      id = param.id ? `${id}-${param.id}` : id;
      return { nodeType: nodetype, ...param, id };
    });
    return result;
  }
  return [];
}

function mapGenericRespToParameters(
  params: any[],
  device: Device,
  unit: UnitNetwork,
) {
  const paramsMapped = [];

  const length = params ? params.length : 0;
  for (let i = 0; i < length; i++) {
    const paramIndex = i;
    const param = params[i];
    const id = `generic_${unit.addr}-${device.nodetype}-${device.node}-0-0-${device.mod}-0-0-0-${device.point}-${param.cid}-${param.vid}`;
    paramsMapped.push({
      id,
      paramIndex,
      device,
      node: device.node,
      mode: device.mod,
      point: device.point,
      name: param._,
      ...param,
    });
  }
  return paramsMapped;
}

function fetchRequestContent(url, user, action) {
  return {
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      storeview_only: '1',
      version: 'C',
      action,
    },
  };
}
