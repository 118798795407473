import React from 'react';
// import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Div, Span } from '@danfoss/etui-system-elements';

export interface CopyFlowTitleProps {
  title: string;
  subTitle: string;
  helpText?: string;
}
export const CopyFlowTitle = ({
  title,
  subTitle,
  helpText,
}: CopyFlowTitleProps) => {
  const theme = useTheme();
  return (
    <>
      <Div display="inline-flex" testId="layout-flow-title-div">
        <Span style={{ fontWeight: 'bold' }}>{title}</Span>
        <Span style={{ marginLeft: `${theme.spacing.md}px` }}>{subTitle}</Span>
      </Div>
      {helpText && (
        <Div testId="layout-flow-helptext-div" mt="20px" mb="20px" width="50%">
          <Span>{helpText}</Span>
        </Div>
      )}
    </>
  );
};
