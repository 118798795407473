export const convertB64ToByteArray = (b64: string) => {
  try {
    const byteCharacters = atob(b64);
    const byteArray = new Uint8Array(new ArrayBuffer(byteCharacters.length));
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
    return byteArray;
  } catch (e) {
    throw new Error();
  }
};
