import { XML_ACTION, XML_TAG, fetchXMLData, fetchXMLFile, fetchUploadFileByChunks } from '@danfoss/etui-sm-xml';
import 'crypto-js';
import { getHashedString } from '../utils/get-hashed-string.js';

const XML_DEVICE_DIRECTORY = '/danfoss/user/';
class XmlConnector {
  constructor(macAddr, user, url) {
    this.macAddr = getHashedString(macAddr);
    this.user = user;
    this.url = url;
  }

  // eslint-disable-next-line class-methods-use-this,no-empty-function
  async connect() {}
  async delete() {
    const attributes = {
      action: XML_ACTION.DELETE_FFS_FILES,
      user: this.user.user,
      password: this.user.password
    };
    const items = [{
      tag: XML_TAG.FILE,
      attributes: {
        filename: this.macAddr,
        directory: XML_DEVICE_DIRECTORY
      }
    }];
    try {
      await fetchXMLData({
        url: this.url,
        attributes,
        items
      });
      return {
        succeeded: true
      };
    } catch (error) {
      console.warn('XML connector DELETE error: ', error.message);
      return {
        succeeded: false,
        error
      };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async fetch() {
    let blobBody;
    try {
      blobBody = await fetchXMLFile({
        url: this.url,
        user: this.user.user,
        password: this.user.password,
        filename: null,
        filepath: this.macAddr,
        offset: 0,
        done: 0
      });
    } catch (error) {
      console.warn('XML connector FETCH error: ', error.message);
      return {
        succeeded: false,
        error
      };
    }
    return {
      succeeded: true,
      blobBody
    };
  }

  // eslint-disable-next-line class-methods-use-this
  async put(_ref) {
    let {
      fileChunksArray,
      fileSize
    } = _ref;
    const handleSetProgress = (index, fileChunks) => {
      console.log('handleSetProgress: ', index, fileChunks);
    };
    try {
      await fetchUploadFileByChunks(this.url, this.user, fileChunksArray, this.macAddr,
      // fileName
      fileSize, 0, 1, null, handleSetProgress);
    } catch (error) {
      console.warn('XML connector PUT error: ', error.message);
      return {
        succeeded: false,
        error
      };
    }
    return {
      succeeded: true
    };
  }
}

export { XmlConnector };
