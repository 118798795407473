import { XML_DEVICE_STR, XML_DEVICE_COMBO } from '@danfoss/etui-sm-xml';

/**
 * Utility to validate if device is a suction
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsSuctionDevice(device) {
  if ((device.type === XML_DEVICE_STR.STR_TYPE_PACK || device.type === XML_DEVICE_STR.STR_TYPE_RACK || device.type === XML_DEVICE_STR.STR_TYPE_NOCOMPSUCT) && (device.combo === XML_DEVICE_COMBO.COMBO_SUCTION || device.combo === XML_DEVICE_COMBO.COMBO_SUCTALL || device.combo === XML_DEVICE_COMBO.COMBO_MULTI_SUCTION || device.combo === XML_DEVICE_COMBO.COMBO_SUCTION_RACK)) {
    return true;
  }
  return false;
}

export { getIsSuctionDevice };
