import React from 'react';
import { Col, Row } from '@danfoss/etui-core';
import { AnimatedSpinner } from '@danfoss/etui-sm';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

function SplashScreen() {
  const { t } = useTranslation();
  return (
    <S.Main>
      <Col>
        <Row styles={{ root: { justifyContent: 'center' } }}>
          <S.DanfossIcon />
        </Row>
        <S.Title>StoreView Web®</S.Title>
        <Row styles={{ root: { justifyContent: 'center' } }}>
          <S.Text>{t('t113')}</S.Text>
        </Row>
        <AnimatedSpinner />
      </Col>
    </S.Main>
  );
}

export default SplashScreen;
