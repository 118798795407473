import '../constants/software-versions.js';
import { UPDATE_TYPE } from '../constants/software-update-type.js';

const getVersionFromFilename = (filename, updateType) => {
  let version = filename;
  if (updateType === UPDATE_TYPE.SPK) {
    /**
     * If the filename is the newer sm800A-package version format, the version seems to be extractable by removing the SM800A prefix and deliting the file extension
     */
    if (filename.toLowerCase().includes('sm800a-')) {
      version = filename.toLowerCase().replace('sm800a-', '').replace('.spk', '');
    } else {
      /**
       * The filenames seems to have a naming convention, which enables getting the version by splitting on '_' on the filename
       * here https://sm800a.danfoss.com/sw_shared/
       */

      const nameSplit = filename.split('_');
      version = nameSplit[2] ? nameSplit[2].substring(2) : filename;
    }
  }
  if (updateType === UPDATE_TYPE.LPK) {
    /**
     * The filenames seems to have a naming convention, with the version postfixed with the filetype(.lpk)
     * here https://sm800a.danfoss.com/Danux_shared/
     */

    version = filename.replace('.lpk', '').replace('Danux', '');
  }
  if (updateType === UPDATE_TYPE.EPK) {
    /**
     * The filenames seems to have a naming convention, which enables getting the version by splitting on '_' on the filename
     * here https://sm800a.danfoss.com/EDF_shared/
     */

    const nameSplit = filename.split('_');
    version = nameSplit[3] ? nameSplit[3].replace('.epk', '') : filename;
  }
  return version;
};

export { getVersionFromFilename };
