import JSZip from 'jszip';
import { ZipConvertor } from './ZipConvertor.js';

const MAX_FILE_CHUNK_SIZE = 102400;
class ZipBase64Convertor extends ZipConvertor {
  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this
  async convert(viewList) {
    const zip = new JSZip();
    viewList.forEach(view => {
      const viewSavePath = `${view.id}`;

      // Add view.json file
      zip.file(`${viewSavePath}/view.json`, JSON.stringify(view), {
        createFolders: false
      });
    });
    const blobFile = await zip.generateAsync({
      type: 'blob'
    });
    const base64 = await zip.generateAsync({
      type: 'base64'
    });
    const b64 = base64.split('base64,').pop();
    const fileChunksArray = [];
    let b64FileSliced = b64;
    const fileLength = b64FileSliced.length;

    // If file length is greater than 102400 divide it into pieces and store it into an array.
    if (fileLength > MAX_FILE_CHUNK_SIZE) {
      // MAX file size allowed is 128MB, RMT used 102400.
      const numFileChunks = Math.ceil(fileLength / MAX_FILE_CHUNK_SIZE);
      let fileChunk;
      for (let i = 0; i < numFileChunks; i++) {
        // Last file chunk will be the remaining bites.
        fileChunk = b64FileSliced.substr(0, MAX_FILE_CHUNK_SIZE);
        fileChunksArray.push(fileChunk);
        b64FileSliced = b64FileSliced.slice(MAX_FILE_CHUNK_SIZE);
      }
    } else {
      fileChunksArray.push(b64FileSliced);
    }
    return {
      // @ts-ignore
      fileChunksArray,
      fileSize: blobFile.size
    };
  }
}

export { ZipBase64Convertor };
