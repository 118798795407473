import * as React from 'react';
import { styled, keyframes, css } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { darken } from '@danfoss/etui-colors';

const pulse = keyframes`
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -75% 0%;
    }
`;

export const Skeleton = styled(Div)`
  display: block;
  border-radius: 2px;
  background: ${() => css`
    linear-gradient(
      -90deg,
      ${darken('#fafcfc', 0.06)} 0%,
      ${darken('#f2f5f5', 0.025)} 50%,
      ${darken('#f2f5f5', 0.05)} 100%
    );
  `};
  background-size: 400% 400%;
  animation: ${pulse} 1.4s ease-in-out infinite;
`;

export function Skeletons({ times, ...props }) {
  return (
    <>
      {new Array(times)
        .fill('magic' /* doesn't matter what to fill with */)
        .map((_, i) => (
          <Skeleton key={i} {...props} />
        ))}
    </>
  );
}
