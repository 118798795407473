import * as React from 'react';
import { Unit } from '@danfoss/etui-sm-xml';
import { ConfigurationProvider } from '../../../../Configuration/context';
import { RefrigLayoutProvider } from '../../RefrigLayoutModal/context';
import { useKeepSessionAlive } from '../../../../../ConfigurationPage/UnitConfiguration/hooks/use-keep-session-alive';
import { HVACLayoutInnerModal } from './HVACLayoutInnerModal';

export interface RefrigarationLayoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  unit: Unit;
}
function HVACLayoutModal({
  isOpen,
  onClose,
  unit,
}: RefrigarationLayoutModalProps) {
  useKeepSessionAlive();
  return (
    <ConfigurationProvider>
      <RefrigLayoutProvider>
        <HVACLayoutInnerModal isOpen={isOpen} onClose={onClose} unit={unit} />
      </RefrigLayoutProvider>
    </ConfigurationProvider>
  );
}

export { HVACLayoutModal };
