import { useContext } from 'react';
import { SoftwareUpdateContext } from '../SoftwareUpdateContext.js';

function useSoftwareUpdate() {
  const context = useContext(SoftwareUpdateContext);
  if (!context) {
    throw new Error(`useSoftwareUpdate must be used within a SoftwareUpdateProvider`);
  }
  return context;
}

export { useSoftwareUpdate };
