async function fetchProxyUserData(proxy, url) {
  const fullUrl = `https://${url}/tenants/${proxy.companyGuid}/users/me`;
  const response = await fetch(fullUrl, {
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${proxy.token}`
    },
    method: 'GET',
    mode: 'cors'
  });
  const {
    email,
    company,
    permissions
  } = await response.json();
  return {
    mail: email,
    company: company || 'Danfoss',
    permissionSet: permissions
  };
}

export { fetchProxyUserData };
