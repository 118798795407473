import { useState, useEffect } from 'react';
import { useDebounce } from './use-debounce.js';
import { matchSorter } from 'match-sorter';

function useSearch(initialData, keys) {
  if (initialData === void 0) {
    initialData = [];
  }
  if (keys === void 0) {
    keys = [];
  }
  const [initData, setInitData] = useState(initialData);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState(null);
  const onSearchInputChange = e => {
    const searchString = e.target.value;
    setSearchValue(searchString);
  };
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    if (initialData && !initData) {
      setInitData(initialData);
    }
    if (initialData && initData && initialData.length !== initData.length) {
      setInitData(initialData);
    }
  }, [initialData]);
  useEffect(() => {
    if (debouncedSearchValue && initData) {
      const newMatchedData = matchSorter(initData, debouncedSearchValue, {
        keys
      });
      setData(newMatchedData);
    }
  }, [debouncedSearchValue, initData]);
  const updateInitData = newInitData => setInitData(newInitData);
  return {
    data: searchValue && data ? data : initData,
    searchValue,
    onSearchInputChange,
    updateInitData
  };
}

export { useSearch };
