import { Color } from '@danfoss/etui-colors';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';

export enum REFRIG_LAYOUT_STEP {
  NETWORK = '1',
  PI200 = '2',
}

export enum SUCTION_GROUP_DEVICE_LEVELS {
  PACK_RACK_NOCOMP = '0',
  SUCTION = '1',
  CASE = '2',
  MULTI_CASE = '3',
}

export const REFRIF_LAYOUT_TABLE_ADDR = '20089';

export type AvailableCasesTableData = {
  id: number;
  name: string;
  address: string;
  model: string;
  listname?: string;
  deviceId?: number;
  assigned: boolean;
  key: number;
  disableCheckBox?: boolean;
  checked?: boolean;
  color?: Color;
};

export type CompressorControlData = {
  id: number;
  name: string;
  address: string;
  model: string;
  listname?: string;
  deviceId?: number;
  assigned: boolean;
  key: number;
};
export const REFRIG_LAYOUT_TABLE_ADDR = '20089';

export const REFRIG_SUCCESS_RESPONSE = 'Success';
export interface PI200Table {
  address: string;
  model: string;
  ipAddress: string;
  toggleVal: string;
  key: number;
  li: ConfigurationListItem;
}

export interface PI200TableContent {
  address: string;
  model: string;
  ipAddress: string;
  toggleVal: string;
  li: ConfigurationListItem;
}
export interface PI200TableData {
  piTableContent?: PI200TableContent[];
  isLoading?: boolean;
}
