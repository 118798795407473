import { DeviceGrouped } from '@danfoss/etui-sm';

function flattenDeviceTree(devices: DeviceGrouped[]) {
  return devices.reduce(
    (acc, device) =>
      acc.concat(device, device.items ? flattenDeviceTree(device.items) : []),
    [],
  );
}

export function flattenDevices(
  devicesData: Record<string, DeviceGrouped[]>,
): DeviceGrouped[] {
  return devicesData
    ? Object.values(devicesData).reduce(
        (acc, devicesByType) => acc.concat(flattenDeviceTree(devicesByType)),
        [],
      )
    : [];
}
