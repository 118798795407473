import React from 'react';

export function useSaveWithRevalidate<T>(
  saveFunction: (...args: any[]) => Promise<T>,
  revalidate: () => void,
  interval?: number,
  bypassRevalidate?: boolean,
) {
  if (bypassRevalidate) {
    return { execute: async (...args) => saveFunction(...args) };
  }
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);
  const saveAndRevalidate = async (...args) => {
    const result = await saveFunction(...args);
    timeoutRef.current = setTimeout(revalidate, interval);
    return result;
  };
  return { execute: async (...args) => saveAndRevalidate(...args) };
}
