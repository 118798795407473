import {
  DeviceModel,
  DeviceTypeModelList,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../types/DiscoveryPage.types';

export const getModelListName = (
  device: DiscoveryTableData,
  deviceModelList: DeviceModel[],
) => {
  const result = deviceModelList.find(
    value =>
      value._.indexOf(device?.code) !== -1 &&
      value._.indexOf(device?.model) !== -1,
  );
  return result?._ || deviceModelList[1]?._;
};

export const getHvacModelListName = (
  device: DiscoveryTableData,
  deviceModelList: DeviceModel[],
) => {
  const result = deviceModelList.find(
    value => value._.indexOf(device?.model) !== -1,
  );
  return result?._;
};

export const getHvacModelListNameIndex = (
  device: DiscoveryTableData,
  deviceModelList: DeviceModel[],
) => {
  return deviceModelList.findIndex(model => model._ === device?.listname) + 1;
};

export const getCircuitModelSelected = (
  circuits: DiscoveryTableData,
  circuitModelList: DeviceTypeModelList,
) =>
  circuits.deviceType === DEVICE_TYPE.CIRCUIT
    ? !Number(circuits?.is_monitor) && !Number(circuits?.subcool)
      ? circuits?.model === circuitModelList?.modelList[0]._
        ? circuitModelList?.modelList[0]._
        : circuitModelList?.modelList[1]._
      : Number(circuits.subcool)
      ? circuitModelList?.modelList[2]._
      : Number(circuits?.is_monitor)
      ? circuitModelList?.modelList[3]._
      : null
    : getModelListName(circuits, circuitModelList.modelList);
