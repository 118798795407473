import * as React from 'react';
import { IStoreHours, StoreHoursContext } from './StoreHoursContext';

const DAYS_IN_A_WEEK = 7;

const putSundayFirst = (daysOfTheWeek: string[]): string[] => {
  const copy = [...daysOfTheWeek];
  copy.unshift(copy.pop());
  return copy;
};

export const getStoreWeekdays = (storeHours: IStoreHours[]) => {
  const daysOfTheWeek = storeHours.map(day => day.id);
  return putSundayFirst(daysOfTheWeek);
};

export function StoreHoursProvider({ children }) {
  const [storeHours, setStoreHours] = React.useState<IStoreHours[]>([]);

  const handleStoreHours = (dayStoreHours: IStoreHours) => {
    if (storeHours.length === DAYS_IN_A_WEEK) return;
    setStoreHours(prevState => [...prevState, dayStoreHours]);
  };

  return (
    <StoreHoursContext.Provider
      value={{
        storeHours,
        onSetStoreHours: handleStoreHours,
        storeWeekdays: getStoreWeekdays(storeHours),
      }}
    >
      {children}
    </StoreHoursContext.Provider>
  );
}
