import React from 'react';
import { useAuth, useXmlResource } from '@danfoss/etui-sm';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { Notification } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { isXmlError } from '@danfoss/etui-sm-xml';
import { fetchDiscoveryDataByUnit } from '../../actions';
import {
  DEVICE_STATUS,
  DEVICE_TYPE,
  DiscoveryPageData,
  DiscoveryTableData,
} from '../../types/DiscoveryPage.types';
import { useRefrigLayout } from '../../context';
import { fetchHvacDiscoveryDataByUnit } from '../../../HVACModules/HVACLayoutModal/actions/hvac-discovery-flow-actions';
import { DiscoveryFlowPage } from '.';

export interface DiscoveryFlowContentProps {
  isHvacDiscovery: boolean;
}

export const DiscoveryFlowContent = ({
  isHvacDiscovery,
}: DiscoveryFlowContentProps) => {
  const {
    setDiscoveryTableData,
    setDiscoveryDeviceStatusCnt,
    setIsOfflineProgramming,
    setFilteredTableData,
    setIsTableRefreshing,
    setDeviceConstraintsData,
    setIsHvac,
    isHvac,
    discoveryTableData,
    isOfflineProgramming,
    setCaseTypes,
    unit,
  } = useRefrigLayout();
  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    data: discoveryPageData,
    error,
    isLoading,
  } = useSWR(
    () => [xmlBackendURL, user, t, isOfflineProgramming, unit],
    !discoveryTableData?.length
      ? !isHvacDiscovery
        ? fetchDiscoveryDataByUnit
        : fetchHvacDiscoveryDataByUnit
      : null,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );
  if (isXmlError(error)) {
    Notification.error({
      message: t('t17'),
      description: t('t3349'),
      duration: 3,
      theme,
    });
  }
  const tableDataLoading = !error && isLoading;

  React.useEffect(() => {
    if (discoveryPageData) {
      setDiscoveryTableData(discoveryPageData?.discoveryTableData);
      setDeviceConstraintsData(discoveryPageData?.deviceConstraintsData);
      setDiscoveryDeviceStatusCnt(discoveryPageData?.discoveryDeviceStatusCnt);
      setDeviceConstraintsData(discoveryPageData.deviceConstraintsData);
      setIsHvac(isHvacDiscovery);
      setCaseTypes(discoveryPageData?.caseTypes);
    }
  }, [discoveryPageData]);

  const handleOfflineProgramming = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    if (!checked) {
      const filteredTableData: DiscoveryTableData[] =
        discoveryTableData?.filter(device => {
          if (
            device.deviceType === DEVICE_TYPE.GEN_PACK &&
            (device.status === DEVICE_STATUS.RED ||
              device.status === DEVICE_STATUS.RED_ORANGE)
          ) {
            return false;
          }

          if (device.deviceType === DEVICE_TYPE.GEN_SUCT) {
            const correspondingPack = discoveryTableData.find(
              pack =>
                pack.deviceType === DEVICE_TYPE.GEN_PACK &&
                pack.model === device.model &&
                pack.address === device.address &&
                !pack.status.includes(DEVICE_STATUS.RED),
            );
            return !!correspondingPack;
          }

          return !device.status.includes(DEVICE_STATUS.RED);
        });

      setFilteredTableData(filteredTableData);
    }
    setIsOfflineProgramming(checked);
  };

  const handleOnRescanComplete = (rescannedPageData: DiscoveryPageData) => {
    setDiscoveryTableData(rescannedPageData?.discoveryTableData);
    setDiscoveryDeviceStatusCnt(rescannedPageData?.discoveryDeviceStatusCnt);
  };

  const handleOnRefresh = async () => {
    setIsTableRefreshing(true);
    try {
      const refreshedPageData: DiscoveryPageData = !isHvac
        ? await fetchDiscoveryDataByUnit([
            xmlBackendURL,
            user,
            t,
            isOfflineProgramming,
            unit,
          ])
        : await fetchHvacDiscoveryDataByUnit([
            xmlBackendURL,
            user,
            t,
            isOfflineProgramming,
            unit,
          ]);
      setDiscoveryTableData(refreshedPageData?.discoveryTableData);
      setDiscoveryDeviceStatusCnt(refreshedPageData?.discoveryDeviceStatusCnt);
      setIsTableRefreshing(false);
      Notification.open({
        message: t('t3123'),
        description: t('t3346'),
        duration: 3,
        type: 'success',
        theme,
      });
    } catch (e) {
      if (isXmlError(e)) {
        Notification.error({
          message: t('t3347'),
          description: e.displayError(t),
          duration: 3,
          theme,
        });
      }
    }
  };

  return (
    <DiscoveryFlowPage
      handleOfflineProgramming={handleOfflineProgramming}
      onRescanComplete={handleOnRescanComplete}
      handleOnRefresh={handleOnRefresh}
      tableDataLoading={tableDataLoading}
    />
  );
};
