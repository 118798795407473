import React from 'react';
import {
  HaccpPage,
  UPDATE_TYPE,
  useAuth,
  useUnit,
  useUpdateVersions,
  useXmlResource,
} from '@danfoss/etui-sm';
import { useHistory } from 'react-router-dom';
import {
  fetchConfigurationTabByUnit,
  fetchConfigurationTabsByUnit,
} from 'pages/ConfigurationPage';
import { routePaths } from '../../routePaths';
import { HISTORY_CONFIG_MENU_INDEX } from './utils/constants';

export function HaccpReportPage() {
  const { push } = useHistory();
  const { url: xmlBackendURL, ip } = useXmlResource();
  const { units, getFirstValidUrl } = useUnit();
  const { user } = useAuth();
  const [currentUnit, setCurrentUnit] = React.useState(null);
  const { currentVersions, isFetchingCurrent } = useUpdateVersions(
    UPDATE_TYPE.SPK,
    xmlBackendURL,
    user,
  );
  const [link, setLink] = React.useState(null);

  // The configuration indeces have shifted with the addition of the security tab in version 4.0.10
  function findHistoryTabIndex(softwareVersion: string): number {
    return softwareVersion.localeCompare('4.0.10', undefined, {
      numeric: true,
      sensitivity: 'base',
    }) === -1
      ? HISTORY_CONFIG_MENU_INDEX - 1
      : HISTORY_CONFIG_MENU_INDEX; // 0 is equal, -1 currentversion is older, 1 currentversion is newer
  }

  React.useEffect(() => {
    const filteredUnit = units.filter(unit =>
      getFirstValidUrl(unit).includes(ip),
    );
    setCurrentUnit(filteredUnit);

    const fetchHACCPConfigUrl = async () => {
      const unit = filteredUnit[0];
      const tabs = await fetchConfigurationTabsByUnit([
        xmlBackendURL,
        unit,
        '',
        '20091',
        user,
      ]);
      const historyTabIndex = findHistoryTabIndex(
        currentVersions.softwareVersion,
      );
      const historyTab = tabs[historyTabIndex];
      const subtabs = await fetchConfigurationTabByUnit([
        xmlBackendURL,
        unit,
        historyTab.id,
        user,
      ]);
      const subtab = subtabs.tabs[0];

      setLink(
        // ['/configuration', historyTab.id, subtab.id].join('/'),
        [routePaths.pages.configuration, historyTab.id, subtab.id].join('/'),
      );
    };

    !isFetchingCurrent && fetchHACCPConfigUrl();
  }, [isFetchingCurrent]);

  const goToConfig = () => {
    push({
      pathname: link,
      state: { custom: true },
    });
  };

  return (
    <HaccpPage
      onGoToConfigClicked={link ? goToConfig : null}
      currentUnit={currentUnit}
    />
  );
}
