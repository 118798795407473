import * as React from 'react';
import { ButtonProps, Li } from '@danfoss/etui-system-elements';
import * as S from './styles';

export function AppHeaderNavItemDropdownItem(props: ButtonProps) {
  return (
    <Li role="menuitem" position="relative">
      <S.AppHeaderNavItemDropdownItemButton {...props} />
    </Li>
  );
}
