import * as React from 'react';
import { ConfigurationContext } from './ConfigurationContext';

function useConfiguration() {
  const context = React.useContext(ConfigurationContext);
  if (!context) {
    throw new Error(
      `useConfiguration must be used within a ConfigurationProvider`,
    );
  }
  return context;
}

export { useConfiguration };
