const readFileAsUrlData = imgBlob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(imgBlob);
  reader.onloadend = function () {
    resolve(reader.result);
  };
  reader.onerror = function () {
    reject(reader.error);
  };
});

export { readFileAsUrlData };
