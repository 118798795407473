import {
  RefrigerationSchedules,
  ScheduleTypes,
} from '../../../SchedulesPage.types';
import {
  generateDefrostSchedId,
  generateHVACSchedId,
  generateNonDefrostSchedId,
} from './generateScheduleIdbyType';

const filterDefrostIds = schedules =>
  schedules.map(schedule => generateDefrostSchedId(schedule));

const filterLightningIds = schedules =>
  schedules.map(sched => generateNonDefrostSchedId(sched));

const filterHVACIds = schedules =>
  schedules.map(sched => generateHVACSchedId(sched));

const filterBySchedType = {
  [ScheduleTypes.REF_DEFROST]: filterDefrostIds,
  [ScheduleTypes.REF_NON_DEFROST]: filterDefrostIds,
  [ScheduleTypes.LIGHTING]: filterLightningIds,
  [ScheduleTypes.HVAC]: filterHVACIds,
};

export const filterIdsByScheduleType = (
  schedules: RefrigerationSchedules[],
  schedType: ScheduleTypes,
): string[] => filterBySchedType[schedType](schedules);
