import { XML_ACTION } from '@danfoss/etui-sm-xml';
import { refrigerationSchema } from '../components/ScheduleDefaultTable/ColumnsSchemas/RefrigerationSchema';
import { holidaySchema } from '../components/ScheduleDefaultTable/ColumnsSchemas/HolidaySchema';
import { lightingSchema } from '../components/ScheduleDefaultTable/ColumnsSchemas/LightingSchema';
import { storeSchema } from '../components/ScheduleDefaultTable/ColumnsSchemas/StoreSchema';
import { applyFilter } from '../utils/filterRefrigerationSchedules';
import { ScheduleTypeConstant } from '../SchedulesPage.types';
import { hvacSchema } from '../components/ScheduleDefaultTable/ColumnsSchemas/HvacSchema';

type OverrideSheduleOptions = {
  holidays: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  store: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  lighting: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  hvac: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  case_lights: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  case_lights_section: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  defrost: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  defrost_akio: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  defrost_section: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  shutdown: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  shutdown_section: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  night_setback: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
  night_setback_section: {
    regex: RegExp;
    fieldSchema: string[];
    columnsSchema: any;
    xmlAction: string | number;
    timeFormat: string | null;
    displayOpts: {
      tableview: boolean;
      calendarview: boolean;
    };
    dataFilteringOptions: any | null;
  };
};

export const OVERRIDE_SCHEDULE_OPTIONS: OverrideSheduleOptions = {
  holidays: {
    regex: /^[store]+[-]+[holidays]{1,}$/gi,
    fieldSchema: ['holiday'],
    columnsSchema: holidaySchema,
    xmlAction: XML_ACTION.READ_HOLIDAYS,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: false,
    },
    dataFilteringOptions: null,
  },
  store: {
    regex: /^[store]+[-]+[hours]{1,}$/gi,
    fieldSchema: ['days', 'day'],
    columnsSchema: storeSchema,
    xmlAction: XML_ACTION.READ_STORE_SCHEDULE,
    timeFormat: '0',
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: null,
  },
  lighting: {
    regex: /^[lighting]+[-]+[ZONE]+[-]+[0-9]{1,}$/gi,
    fieldSchema: ['zone', 'schedules', 'schedule'],
    columnsSchema: lightingSchema,
    xmlAction: XML_ACTION.READ_LIGHTING_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: null,
  },
  hvac: {
    regex: /^[hvac]+[-]+[0-9]{1,}$/gi,
    fieldSchema: ['hvac', 'schedules', 'schedule'],
    columnsSchema: hvacSchema,
    xmlAction: XML_ACTION.READ_HVACS,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: null,
  },
  case_lights: {
    regex: /^([case lights]+[-]+[a-z]+[0-9]+[0-9]+[-]+[0-9]{1,}.*)$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([ScheduleTypeConstant.SCHED_CASE_LIGHTS]),
  },
  case_lights_section: {
    regex: /^([case lights]{1,})$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([ScheduleTypeConstant.SCHED_CASE_LIGHTS]),
  },
  defrost: {
    regex: /^([defrost]+[-]+[a-z]+[0-9]+[0-9]+[-]+[0-9]{1,}.*)$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([
      ScheduleTypeConstant.SCHED_DEFROST,
      ScheduleTypeConstant.SCHED_COORD_DEFROST,
      ScheduleTypeConstant.SCHED_ADAPTIVE_DEFROST,
    ]),
  },
  defrost_akio: {
    regex: /^([defrost]+[-]+[a-z]+[0-9]+[0-9].*)$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([
      ScheduleTypeConstant.SCHED_DEFROST,
      ScheduleTypeConstant.SCHED_COORD_DEFROST,
      ScheduleTypeConstant.SCHED_ADAPTIVE_DEFROST,
    ]),
  },
  defrost_section: {
    regex: /^([defrost]{1,})$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([ScheduleTypeConstant.SCHED_DEFROST]),
  },
  shutdown: {
    regex: /^([shutdown]+[-]+[a-z]+[0-9]+[0-9]+[-]+[0-9]{1,}.*)$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([ScheduleTypeConstant.SCHED_SHUTDOWN]),
  },
  shutdown_section: {
    regex: /^([shutdown]{1,})$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([ScheduleTypeConstant.SCHED_SHUTDOWN]),
  },
  night_setback: {
    regex: /^([night setback]+[-]+[a-z]+[0-9]+[0-9]+[-]+[0-9]{1,}.*)$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([ScheduleTypeConstant.SCHED_NIGHTSB]),
  },
  night_setback_section: {
    regex: /^([night setback]{1,})$/gi,
    fieldSchema: ['device'],
    columnsSchema: refrigerationSchema,
    xmlAction: XML_ACTION.READ_SCHEDULE,
    timeFormat: null,
    displayOpts: {
      tableview: true,
      calendarview: true,
    },
    dataFilteringOptions: applyFilter([ScheduleTypeConstant.SCHED_NIGHTSB]),
  },
};
