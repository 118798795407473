import * as React from 'react';
import { Accordion } from '@danfoss/etui-sm/components';
import { DeviceGrouped, DeviceType } from '@danfoss/etui-sm';
import {
  ContainerDimensions,
  TreeItem,
  TreeViewProps,
} from '@danfoss/etui-core';
import { DeviceBasedMenuItem } from './DeviceBasedMenuItem';

export type DeviceData = { [key in DeviceType]?: DeviceGrouped[] };

type MenuProps = {
  entries: string[];
  renderEntry: (e: string) => React.ReactNode;
  preExpanded: string[];
  minHeightItems?: Set<string>;
  onMenuGroupChange: (group: string) => void;
  bottomSpace?: number;
  checkedItems?: TreeViewProps['checkedItems'];
  data?: DeviceData;
  onClearCheckedItems?: (items: TreeItem[]) => void;
};

const DeviceBasedMenu = React.memo(function DeviceBasedMenu({
  entries,
  preExpanded,
  renderEntry,
  minHeightItems,
  onMenuGroupChange,
  onClearCheckedItems,
  checkedItems,
  data,
  bottomSpace = 0,
}: MenuProps) {
  const handleOnMenuGroupChange = (args: React.ReactText[]) => {
    onMenuGroupChange(args[0] as unknown as DeviceType);
  };

  return (
    <ContainerDimensions>
      {({ height }) => (
        <Accordion
          onChange={handleOnMenuGroupChange}
          allowZeroExpanded={true}
          preExpanded={preExpanded}
        >
          {entries.map((type, index) => (
            <DeviceBasedMenuItem
              renderEntry={renderEntry}
              isOfMinHeight={minHeightItems && minHeightItems.has(type)}
              key={`${type}-${index}`}
              deviceTypes={entries as DeviceType[]}
              type={type as any}
              height={height - bottomSpace}
              data={data}
              checkedItems={checkedItems}
              onClearCheckedItems={onClearCheckedItems}
            />
          ))}
        </Accordion>
      )}
    </ContainerDimensions>
  );
});

export { DeviceBasedMenu };
