export const FORMATTED_LABEL = {
  200910: 't460',
  200911: 't3184',
  200913: 't3340',
  200918: 't37',
  201050: 't136',
  201051: 't167',
  201052: 't3337',
  201053: 't3338',
  201054: 't3339',
  201055: 't166',
  200919: 't37',
  200914: 't3585',
};
