import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { Skeleton } from '@danfoss/etui-sm/components';

export type ConfigurationItemContentSkeletonItemProps = { isLast?: boolean };

function ConfigurationItemContentSkeletonItem({
  isLast = false,
}: ConfigurationItemContentSkeletonItemProps) {
  const theme = useTheme();

  return (
    <Div
      display="flex"
      alignItems="center"
      borderBottom={!isLast ? `1px solid ${theme.palette.divider}` : 'none'}
      height="42px"
      p={[`0 ${theme.spacing.xs}px`, null]}
    >
      <Div width={['100%', '75%', '60%', '50%']} m="0 auto">
        <Skeleton height={20} width="100%" />
      </Div>
    </Div>
  );
}

export { ConfigurationItemContentSkeletonItem };
