import React from 'react';
import { useModal, useXmlResource } from '@danfoss/etui-sm';
import { DropdownMenu, icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import {
  UnitMetadata,
  getUnitMetadata,
  trimUrlEnding,
} from '@danfoss/etui-sm-xml';
import { CopyModal } from '../RefrigLayoutModal/modals/CopyModal';

const oldInstallationVersionPropName = 'Installation-version';
const newInstallationVersionPropName = 'Installation_version';
export interface LayoutDropDownMenuProps {
  rowIndex: number;
  handleOnCopyOk: (copyAmount: number, selectedRowIndex: number) => void;
  handleDeleteSelectedRow: (selectedRowIndex: number) => void;
  isCircuitScreen?: boolean;
}

export const LayoutDropDownMenu = ({
  rowIndex,
  handleOnCopyOk,
  handleDeleteSelectedRow,
  isCircuitScreen = false,
}: LayoutDropDownMenuProps) => {
  const MAXCIRCUITCOPY = 160;
  const LEGACYMAXCIRCUITCOPY = 100;
  const [selectedRowId, setSelectedRowId] = React.useState<number>(0);
  const { t } = useTranslation();
  const { url } = useXmlResource();
  const metadata = getUnitMetadata(trimUrlEnding(url));

  const handleCopyOk = (copyAmount: number) => {
    handleOnCopyOk(copyAmount, selectedRowId);
  };
  const getMaxCopyAmount = (metadata: UnitMetadata): number => {
    const installationVersion =
      metadata?.[newInstallationVersionPropName] ||
      metadata?.[oldInstallationVersionPropName];

    return isCircuitScreen && installationVersion && +installationVersion[0] > 3
      ? MAXCIRCUITCOPY
      : LEGACYMAXCIRCUITCOPY;
  };

  const [showCopyModal] = useModal(CopyModal, {
    min: 1,
    max: getMaxCopyAmount(metadata),
    handleCopyOk,
  });

  const handleSelectedRowId = (key: number) => {
    setSelectedRowId(key);
  };

  const renderDefaultItems = (closeDropdown?: () => void) => {
    const handleOnCopy = (key: number) => () => {
      closeDropdown();
      handleSelectedRowId(key);
      showCopyModal();
    };

    const handleOnDelete = (key: number) => () => {
      closeDropdown();
      handleDeleteSelectedRow(key);
    };

    return (
      <>
        <DropdownMenu.Item onClick={handleOnCopy(rowIndex)}>
          {t('t486')}
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleOnDelete(rowIndex)}>
          {t('t2215')}
        </DropdownMenu.Item>
      </>
    );
  };
  return (
    <DropdownMenu
      styles={{ menuContainer: { zIndex: 100, width: '110px' } }}
      iconOnly={true}
      icon={{ glyph: icons.DOTS_HORIZONTAL }}
      renderList={({ closeDropdown }) => renderDefaultItems(closeDropdown)}
    />
  );
};
