import '../constants/session-storage-constants.js';
import '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Metadata.js';
import '../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import { buildXMLCommand } from '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import { fetchXML } from './fetch-xml.js';

async function fetchXMLData(_ref) {
  let {
    url,
    attributes,
    items = [],
    cfg
  } = _ref;
  const xml = buildXMLCommand({
    attributes,
    items
  });
  return fetchXML({
    body: xml,
    url,
    cfg
  });
}

export { fetchXMLData };
