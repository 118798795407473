import '@danfoss/etui-sm-xml';
import '@danfoss/etui-core';
import '../../constants/software-versions.js';
import '../../utils/get-software-version-prefix.js';
import { getIsMatchedSoftwareVersion } from '../../utils/get-is-matched-software-version.js';
import '../../utils/get-is-misc-device.js';

function VersionDependentRender(_ref) {
  let {
    units,
    dependentVersionPrefix = [],
    children
  } = _ref;
  const isPrefixMatched = getIsMatchedSoftwareVersion(units, dependentVersionPrefix);
  return isPrefixMatched ? children : null;
}

export { VersionDependentRender };
