function flattenArrayByKey(array, key, result) {
  array.forEach(el => {
    if (el[key] && el[key].length !== 0) {
      result.push(el);
      flattenArrayByKey(el[key], key, result);
    } else {
      result.push(el);
    }
  });
}

export { flattenArrayByKey };
