import '../../_rollupPluginBabelHelpers-6901a291.js';
import 'uuid';
import '../Conditions/utils/execute-operation.js';
import '../Conditions/Condition.js';
import { bindMethods } from '../utils/bind-methods.js';
import { Subscribable } from '../utils/Subscribable.js';
import { Mapping } from './Mapping.js';

class MappingCollection extends Subscribable {
  constructor() {
    super();
    this.mappings = [];
    this.shapeBy = 'id';
    bindMethods(this, ['addMapping', 'removeMapping', 'toggleMapping']);
    Object.defineProperties(this, {
      addMapping: {
        enumerable: false
      },
      removeMapping: {
        enumerable: false
      },
      toggleMapping: {
        enumerable: false
      }
    });
  }
  getMappings() {
    return this.mappings;
  }
  getMappingById(mappingId) {
    return this.mappings.find(map => map.id === mappingId);
  }
  addMapping() {
    const mapping = new Mapping(this.shapeBy);
    this.mappings.push(mapping);
    this.notify();
    return mapping;
  }
  importMappings(raw) {
    if (raw.shapeBy) {
      this.shapeBy = raw.shapeBy;
    }
    if (Array.isArray(raw.mappings)) {
      raw.mappings.forEach(rawObj => {
        const shapeBy = rawObj.shapeBy || this.shapeBy;
        const mapping = new Mapping(shapeBy);
        mapping.import(rawObj);
        this.mappings.push(mapping);
      });
    }
    return this;
  }
  setShapeBy(shapeBy) {
    this.shapeBy = shapeBy;
    this.notify();
    return this;
  }
  removeMapping(mappingId) {
    this.mappings = this.mappings.filter(map => map.id !== mappingId);
    this.notify();
    return this;
  }
  toggleMapping(mappingId) {
    const mapping = this.getMappingById(mappingId);
    if (mapping) {
      mapping.setHidden(!mapping.isHidden());
      this.notify();
    }
    return this;
  }
  getMappingCount() {
    return this.mappings.length;
  }
  toJSON() {
    return {
      shapeBy: this.shapeBy,
      mappings: this.mappings || []
    };
  }
}

export { MappingCollection };
