const getTableData = data => {
  const newTableData = [];
  const defaultLen = 24;
  for (let i = 0; i < data.jsonFile.length; i++) {
    if (i > 3) {
      let row = {};
      let fieldObj = {};
      if (i & 1) {
        const prevIndex = i - 1;
        Object.entries(data.jsonFile[i]).forEach((entry, index) => {
          let rowEntry = {};
          const [key] = entry;
          if (index === 0) {
            rowEntry = {
              [key]: data.jsonFile[prevIndex][key]
            };
          } else {
            const isDefrost = data.jsonFile[i][key] === 'Y' ? true : '';
            fieldObj = {
              value: data.jsonFile[prevIndex][key],
              defrost: isDefrost
            };
            rowEntry = {
              [key]: fieldObj
            };
          }
          row = Object.assign(row, rowEntry);
        });
        const rowObjLength = Object.keys(row).length - 1;
        if (rowObjLength < defaultLen) {
          const extraColumnLen = defaultLen - rowObjLength;
          for (let j = 0; j <= extraColumnLen; j++) {
            const field = `field${rowObjLength + (j + 2)}`;
            const emptyObj = {};
            emptyObj[field] = {
              value: '-',
              defrost: ''
            };
            Object.assign(row, emptyObj);
          }
        }
        newTableData.push(row);
      }
    }
  }
  return newTableData;
};

export { getTableData };
