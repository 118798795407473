import * as React from 'react';
import _get from 'lodash.get';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { UnitNetwork } from '@danfoss/etui-sm-xml';
import { DataItem } from '../DataItem';
import { Seperator } from '../Seperator';

export interface UnitMinimalSoftwareInfoProps {
  currentUnit: any;
  network: UnitNetwork;
  isConnectedIpNotFound: boolean;
}

export function UnitMinimalSoftwareInfo({
  currentUnit,
  network,
  isConnectedIpNotFound,
}: UnitMinimalSoftwareInfoProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const InfoMap = [
    { label: 't3320', path: 'store_name' },
    { label: 't887', path: 'macAddr' },
  ];

  const getVersionContent = () => (
    <>
      {InfoMap.map(({ path, label }) => {
        const info = _get(
          { ...currentUnit, unit: getArray(currentUnit?.unit) },
          path,
        );
        return info ? (
          <Div key={`${label}_${path}`}>
            <Seperator />
            <DataItem label={t(label)} value={info} />
          </Div>
        ) : null;
      })}
      <Seperator />
      <DataItem
        label={t('t3305')}
        value={network?.ip}
        warning={isConnectedIpNotFound ? t('t3297') : ''}
      />
      <Seperator />
    </>
  );
  return (
    <Div testId="unit-min-software-info">
      <Div
        display="flex"
        flexDirection="row"
        mr={`${theme.spacing.xs}px`}
        ml={`${theme.spacing.lg}px`}
        mb={`${theme.spacing.lg}px`}
      >
        <Div
          testId="unit-min-software-info-content"
          flex="1"
          ml={`${theme.spacing.sm}px`}
        >
          {getVersionContent()}
        </Div>
      </Div>
    </Div>
  );
}

function getArray<T>(data: T | T[]): T[] {
  if (!data) {
    return [];
  }
  return Array.isArray(data) ? (data as T[]) : [data];
}
