import { User, Unit } from '@danfoss/etui-sm-xml';
import { AlarmType } from '../constants';
import { splitArrayIntoGroups } from '../utils';
import { fetchAlarmsPaginatedByUnits } from './fetch-alarms-paginated-by-units';

export async function fetchAllAlarmsByUnits(
  url: string,
  alarmRefs: string[],
  user: User,
  units: Unit[],
  skipSessionUpdate?: boolean,
) {
  const pageSize = 14;
  const pages = splitArrayIntoGroups(alarmRefs, pageSize);

  const responses = await Promise.all(
    pages.map((_, index) =>
      fetchAlarmsPaginatedByUnits([
        url,
        AlarmType.ACTIVE,
        alarmRefs,
        index,
        pageSize,
        user,
        units,
        skipSessionUpdate,
      ]),
    ),
  );

  return responses.flat();
}
