import { fetchXMLData, XML_ACTION, XML_UPDATE_SW_CMD } from '@danfoss/etui-sm-xml';

const RETURN_CODE_DANUX_UPDATE_NEEDED = 'Danux update needed';
const RETURN_CODE_NO_DOWNGRADE_ALLOWED = 'Downgrade not allowed';
async function fetchUpdateSoftwareCheck(url, user) {
  const response = await fetchXMLData({
    url,
    attributes: {
      action: XML_ACTION.UPDATE_SW,
      user: user.user,
      password: user.password,
      sw_upgrade_cmd: XML_UPDATE_SW_CMD.CHECK_PREREQUISITES
    }
  });
  if (response.return_code === '1') {
    throw new Error(RETURN_CODE_DANUX_UPDATE_NEEDED);
  }
  if (response.return_code === '2') {
    throw new Error(RETURN_CODE_NO_DOWNGRADE_ALLOWED);
  }
  if (response.return_code !== '0') {
    throw new Error();
  }
  return response;
}

export { RETURN_CODE_DANUX_UPDATE_NEEDED, RETURN_CODE_NO_DOWNGRADE_ALLOWED, fetchUpdateSoftwareCheck };
