import JSZip from 'jszip';

class ZipConvertor {
  /**
   * Return zip file with structure
   *    |--- view_uuid_1
   *    |   |--- background-image.png
   *    |   |--- some-other-image.jpeg
   *    |   |--- ...
   *    |   |--- view.json
   *    |
   *    |--- view_uuid_2
   *    |   |--- background-image.png
   *    |   |--- some-other-image.jpeg
   *    |   |--- ...
   *    |   |--- view.json
   *    |
   *    |...
   * @param viewList
   */
  // eslint-disable-next-line class-methods-use-this
  convert(viewList) {
    const zip = new JSZip();
    viewList.forEach(view => {
      const viewSavePath = `${view.id}`;

      // Add view.json file
      zip.file(`${viewSavePath}/view.json`, JSON.stringify(view), {
        createFolders: false
      });

      // TODO: <-- test image, should be removed
      // const fileName = `background-image.png`;
      // const myImg = new Image(200, 200);
      // myImg.src = fileName;
      // // @ts-ignore
      // const imgFile = new Blob([myImg], {type: 'image/png'});
      // zip.file(`${viewSavePath}/${fileName}`, imgFile, {base64: true, createFolders: false});
    });
    return zip.generateAsync({
      type: 'blob'
    });
  }
  async parse(zipFile) {
    const _that = this;
    return JSZip.loadAsync(zipFile).then(function loadCallback(zip) {
      const promises = [];
      zip.forEach(relativePath => {
        if (_that.isJson(relativePath)) {
          // @ts-ignore
          promises.push(_that.parseJson(relativePath, zip));
        }
        // Can be extended for example - parse images
        // if(_that.isImage(relativePath)) {
        //     promises.push(_that.parseImage(relativePath, zip));
        // }
      });
      return promises;
    }).then(promises => Promise.all(promises));
  }

  // eslint-disable-next-line class-methods-use-this
  isJson(filename) {
    return filename.includes('json');
  }

  // eslint-disable-next-line class-methods-use-this
  async parseJson(path, zip) {
    // @ts-ignore
    const jsonString = await zip.file(path).async('string');
    return JSON.parse(jsonString);
  }
}

export { ZipConvertor };
