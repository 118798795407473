import '../../constants/session-storage-constants.js';
import '../../constants/xml-action-constant.js';
import '../../constants/xml-tag-constant.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import '../../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import { acknowledgeAlarmByRef } from './fetch-acknowledge-alarm-by-ref.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import '../../fetch-xml/fetch-xml-data.js';

async function acknowledgeAlarm(url, alarm, user) {
  return acknowledgeAlarmByRef(url, alarm.ref, user);
}

export { acknowledgeAlarm };
