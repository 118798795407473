import React from 'react';
import {
  ColumnProps,
  Spinner,
  SpinnerSize,
  Table,
  Notification,
} from '@danfoss/etui-core';
import {
  EmptyState,
  getLanguageCodeByName,
  sortStringAlphabetically,
  useAuth,
  useModal,
} from '@danfoss/etui-sm';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useRefrigLayout } from '../../context';
import { DiscoveryTableData } from '../../types/DiscoveryPage.types';
import {
  checkIsMultiCaseOperation,
  findSelectedCaseToAssign,
  findSelectedCaseToRemove,
  getCurrentOperation,
  getFromAndToGroupText,
  getFromAndToPackGroup,
  getRecordColor,
  getSelectedDeviceLevel,
  isCaseAssignAllowed,
} from '../../actions';
import {
  AvailableCasesTableData,
  SUCTION_GROUP_DEVICE_LEVELS,
} from '../../types/RefrigLayout.types';
import { WizardConfirmationModal } from '../../../WizardConfirmationModal';
import { getCircuitModelSelected } from '../../utils';

export interface SuctionGroupCasesTableProps {
  filterCircuits: () => void;
}

export const SuctionGroupCasesTable = ({
  filterCircuits,
}: SuctionGroupCasesTableProps) => {
  const { user } = useAuth();

  const { t } = useTranslation();

  const theme = useTheme();

  const [availableCases, setAvailableCases] =
    React.useState<AvailableCasesTableData[]>();

  const {
    circuitTypeModelList,
    circuitsTableData,
    currentSelectedCaseDeviceIds,
    currentSectionsOpen,
    selectedCompressorTreeDeviceId,
    compressorControlData,
    setCurrentSelectedCase,
    discoveryTableData,
    availableCasesData,
    allowMultiCaseCreation,
    setAvailableCasesData,
    currentSearchInputText,
    setSearchedAvailableCasesData,
    searchedAvailableCasesData,
    currentSelectedRowKeys,
    setCurrentSelectedRowKeys,
    setIsCaseAssignOperation,
    setIsCaseRemoveOperation,
  } = useRefrigLayout();

  const [actionConfirmationLabel, setActionConfirmationLabel] =
    React.useState<string>(null);

  const [actionConfirmationSubLabel, setActionConfirmationSubLabel] =
    React.useState<string>(null);

  const [currentCaseSelectedToAssign, setCurrentCaseSelectedToAssign] =
    React.useState<AvailableCasesTableData>(null);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const langCode = getLanguageCodeByName(user?.language || 'English') || 'en';

  React.useEffect(() => {
    if (circuitTypeModelList?.modelList?.length) {
      filterCircuits();
    }
  }, [circuitTypeModelList?.modelList?.length]);

  React.useEffect(() => {
    if (circuitsTableData?.length) {
      updateCircuitsTable(circuitsTableData);
    }
  }, [circuitsTableData]);

  const getSearchedCases = (
    currentSearchInputText: string,
    availableCases: AvailableCasesTableData[],
  ) => {
    const searchedCases = availableCases?.filter(cases =>
      cases.name.toLowerCase().includes(currentSearchInputText.toLowerCase()),
    );
    return searchedCases;
  };

  React.useMemo(() => {
    const filteredCases1 = currentSearchInputText
      ? getSearchedCases(currentSearchInputText, availableCasesData)
      : availableCasesData;
    setAvailableCases(filteredCases1);
    setSearchedAvailableCasesData(filteredCases1);
  }, [currentSearchInputText]);

  React.useEffect(() => {
    updateAssignedCases(currentSelectedCaseDeviceIds);
  }, [currentSelectedCaseDeviceIds]);

  React.useEffect(() => {
    handleCaseSelection(currentSelectedCaseDeviceIds);
  }, [selectedCompressorTreeDeviceId, allowMultiCaseCreation]);

  React.useEffect(() => {
    if (availableCasesData?.length && !selectedCompressorTreeDeviceId) {
      availableCasesData?.forEach(cases => {
        cases.assigned = false;
      });
      setAvailableCases(availableCasesData);
    }
  }, []);

  const handleCaseSelection = (selectedCaseDeviceIds: number[]) => {
    const currentSelectedDeviceIds = selectedCompressorTreeDeviceId;
    const level = getSelectedDeviceLevel(
      currentSelectedDeviceIds,
      compressorControlData,
    );
    if (selectedCaseDeviceIds.length >= 1) {
      if (level === SUCTION_GROUP_DEVICE_LEVELS.PACK_RACK_NOCOMP) {
        // collect keys of unselected checkboxes
        availableCases?.forEach(cases => {
          cases.disableCheckBox = true;
        });
      }
      if (
        level === SUCTION_GROUP_DEVICE_LEVELS.SUCTION ||
        (level === SUCTION_GROUP_DEVICE_LEVELS.CASE && allowMultiCaseCreation)
      ) {
        availableCases?.forEach(cases => {
          if (
            selectedRowKeys.includes(cases.key) ||
            cases.assigned ||
            (!cases.assigned && cases.disableCheckBox)
          ) {
            cases.disableCheckBox = false;
          }
        });
      } else if (
        level === SUCTION_GROUP_DEVICE_LEVELS.CASE &&
        !allowMultiCaseCreation
      ) {
        availableCases?.forEach(cases => {
          cases.disableCheckBox = true;
        });
      }
    } else if (
      level === SUCTION_GROUP_DEVICE_LEVELS.PACK_RACK_NOCOMP ||
      (level === SUCTION_GROUP_DEVICE_LEVELS.CASE && !allowMultiCaseCreation) ||
      level === SUCTION_GROUP_DEVICE_LEVELS.MULTI_CASE
    ) {
      availableCases?.forEach(cases => {
        cases.disableCheckBox = true;
      });
    } else {
      availableCases?.forEach(cases => {
        if (selectedCompressorTreeDeviceId === cases.deviceId) {
          cases.disableCheckBox = true;
        } else {
          cases.disableCheckBox = false;
        }
      });
    }
    setAvailableCases(availableCases);
  };

  const availableCasesColumn: ColumnProps<unknown>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record: AvailableCasesTableData) => {
        return (
          <Div
            color={getRecordColor(
              record,
              selectedRowKeys,
              theme,
              selectedCompressorTreeDeviceId,
            )}
          >
            {record.name}
          </Div>
        );
      },
      sorter: (a: { name: string }, b: { name: string }) =>
        sortStringAlphabetically(a.name, b.name, langCode),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (_, record: AvailableCasesTableData) => {
        return (
          <Div
            color={getRecordColor(
              record,
              selectedRowKeys,
              theme,
              selectedCompressorTreeDeviceId,
            )}
          >
            {record.address}
          </Div>
        );
      },
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      render: (_, record: AvailableCasesTableData) => {
        return (
          <Div
            color={getRecordColor(
              record,
              selectedRowKeys,
              theme,
              selectedCompressorTreeDeviceId,
            )}
          >
            {record.model}
          </Div>
        );
      },
    },
  ];

  const updateCircuitsTable = (filteredCircuitsData: DiscoveryTableData[]) => {
    const circuitTableData: AvailableCasesTableData[] = [];

    filteredCircuitsData.forEach(
      (circuits: DiscoveryTableData, index: number) => {
        circuits.listname = getCircuitModelSelected(
          circuits,
          circuitTypeModelList,
        );
        circuitTableData.push(getCircuitTableDataItem(circuits, index + 1));
      },
    );
    setAvailableCases(circuitTableData);
    setAvailableCasesData(circuitTableData);
    setSearchedAvailableCasesData(circuitTableData);
  };

  const getCircuitTableDataItem = (
    circuits: DiscoveryTableData,
    tableIndex: number,
  ): AvailableCasesTableData => {
    return {
      id: tableIndex,
      name: circuits.name,
      address: circuits.address,
      model: circuits.model,
      listname: circuits.listname,
      deviceId: circuits.deviceId,
      assigned: circuits.assigned,
      key: tableIndex,
      disableCheckBox: false,
      checked: false,
    };
  };

  const updateAssignedCases = (selectedCaseDeviceIds: number[]) => {
    const selectedCases = [];
    selectedCaseDeviceIds?.forEach(deviceId => {
      availableCasesData?.forEach(caseData => {
        if (caseData.deviceId === deviceId) {
          caseData.checked = true;
          selectedCases.push(caseData.key);
        } else {
          caseData.checked = false;
        }
      });
    });
    setCurrentSelectedCase(null);
    setCurrentCaseSelectedToAssign(null);
    setSelectedRowKeys(selectedCases);
    setCurrentSelectedRowKeys(selectedCases);
  };

  const handleOnOkClick = (
    currentCaseSelectedToAssign: AvailableCasesTableData,
  ) => {
    const selectedDeviceLevel = getSelectedDeviceLevel(
      selectedCompressorTreeDeviceId,
      compressorControlData,
    );
    const isMultiCase = checkIsMultiCaseOperation(selectedDeviceLevel);
    if (isMultiCase && !allowMultiCaseCreation) {
      return;
    }
    const caseAssignAllowed = isCaseAssignAllowed(
      discoveryTableData,
      currentSectionsOpen,
      compressorControlData,
      currentCaseSelectedToAssign.deviceId,
    );
    if (caseAssignAllowed) {
      setIsCaseRemoveOperation(false);
      setIsCaseAssignOperation(true);
      setCurrentSelectedCase(currentCaseSelectedToAssign);
    } else {
      showErrorMessage();
    }
  };

  const [showWizardConfirmationModal] = useModal(WizardConfirmationModal, {
    handleOnOkClick,
    title: t('t3439'),
    text: actionConfirmationLabel,
    subText: actionConfirmationSubLabel,
    currentCaseSelectedToAssign,
    isSuctionGroupScreen: true,
  });

  const handleRowSelectionChange = async (selectedKeys: number[]) => {
    if (!currentSectionsOpen?.length) {
      const selectedCaseForOperation = findSelectedCaseToAssign(
        selectedKeys,
        searchedAvailableCasesData,
      );
      selectedKeys.forEach(selectedKey => {
        searchedAvailableCasesData.find(
          cases => cases.key === +selectedKey,
        ).checked = true;
      });
      setSelectedRowKeys(selectedKeys);
      setCurrentSelectedCase(selectedCaseForOperation);
    } else {
      const { isAssignOperation, isRemoveOperation } = getCurrentOperation(
        currentSelectedRowKeys,
        selectedKeys,
      );

      if (isAssignOperation) {
        const selectedCaseForOperation = findSelectedCaseToAssign(
          selectedKeys,
          searchedAvailableCasesData,
        );

        if (selectedCaseForOperation?.assigned) {
          const {
            fromPackGroup,
            fromSuctionGroup,
            fromCaseGroup,
            toPackGroup,
            toCaseGroup,
            toSuctionGroup,
          } = getFromAndToPackGroup(
            selectedCaseForOperation,
            compressorControlData,
            selectedCompressorTreeDeviceId,
            discoveryTableData,
          );
          const { toDeviceGroup, fromDeviceGroup } = getFromAndToGroupText(
            fromPackGroup,
            fromSuctionGroup,
            fromCaseGroup,
            toPackGroup,
            toCaseGroup,
            toSuctionGroup,
          );
          const actionTextLabel = t('t3435', {
            toPackGroup: `${toDeviceGroup}`,
          });

          const actionTextSubLabel = t('t3436', {
            fromPackGroup: `${fromDeviceGroup}`,
          });
          setCurrentCaseSelectedToAssign(selectedCaseForOperation);
          setActionConfirmationLabel(actionTextLabel);
          setActionConfirmationSubLabel(actionTextSubLabel);
          showWizardConfirmationModal();
        } else {
          handleOnOkClick(selectedCaseForOperation);
        }
      } else if (isRemoveOperation) {
        // get the case obj that has to be removed
        const selectedCaseForRemoval = findSelectedCaseToRemove(
          selectedKeys,
          currentSelectedRowKeys,
          searchedAvailableCasesData,
        );
        // get from discovery data as well
        const removedCase = discoveryTableData?.find(
          cases => cases?.deviceId === selectedCaseForRemoval.deviceId,
        );
        // check for case restriction
        const caseAssignAllowed = isCaseAssignAllowed(
          discoveryTableData,
          currentSectionsOpen,
          compressorControlData,
          selectedCaseForRemoval.deviceId,
        );
        if (removedCase?.cfg === '1' && !caseAssignAllowed) {
          showErrorMessage();
        } else {
          // if no restriction then proceed for tree and available cases update
          setIsCaseRemoveOperation(true);
          setIsCaseAssignOperation(false);
          setCurrentSelectedCase(selectedCaseForRemoval);
        }
      }
    }
  };

  const showErrorMessage = () => {
    Notification.error({
      message: t('t832'),
      description: t('t225'),
      duration: 3,
      theme,
    });
  };

  return (
    <>
      {circuitTypeModelList && availableCases?.length ? (
        <>
          <Div height="100%" testId="available-cases-table-content-div">
            <Table
              styles={{ root: { height: '450px', overflow: 'auto' } }}
              dataSource={availableCases}
              columns={availableCasesColumn}
              rowSelection={{
                selectedRowKeys,
                onChange: handleRowSelectionChange,
                getCheckboxProps: (record: AvailableCasesTableData) => ({
                  disabled: record.disableCheckBox,
                }),
              }}
              disableHeaderCheckBox={true}
              pagination={false}
            />
          </Div>
        </>
      ) : !circuitTypeModelList ? (
        <Div
          testId="circuit-flow-table-spinner-div"
          style={{ marginTop: '200px' }}
        >
          <Spinner size={SpinnerSize.small} />
        </Div>
      ) : (
        <Div testId="discovery-flow-table-empty-div">
          <EmptyState title={t('t3351')} />
        </Div>
      )}
    </>
  );
};
