import { DefaultTheme } from 'styled-components';

export const accordionStyle = (theme: DefaultTheme) => ({
  root: {
    mt: theme.spacing.sm,
    mb: theme.spacing.sm,
    boxShadow: theme.shadows[2],
    height: '100%',
  },
  sectionContent: {
    p: `${theme.spacing.xs}px`,
    mt: 0,
    bg: '#fff',
  },
});
