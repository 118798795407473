import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { icons } from '@danfoss/etui-core';
import FloatingModalButton from '../FloatingModalButton';
import { HelpPanelProps } from './HelpPanel.types';
import InfoItemCategoryContainer from './InfoItemCategoryContainer';

export const HelpPanel: React.FC<HelpPanelProps> = props => {
  const theme = useTheme();

  return (
    <FloatingModalButton
      height={props.height || 400}
      modalChildren={
        props.customTemplate || (
          <InfoItemCategoryContainer
            items={props.items}
            isLoading={props.isLoading}
            isError={props.isError}
            categoryIconMapping={props.categoryIconMapping}
          />
        )
      }
      {...props}
      buttonProps={{
        ...props.buttonProps,
        iconProps: {
          glyph: icons.HELP_CIRCLE,
          ...props.buttonProps?.iconProps,
        },
        styles: {
          ...props.buttonProps?.styles,
          root: {
            p: `${theme.spacing.xs}px`,
            ...props.buttonProps?.styles?.root,
          },
        },
      }}
    />
  );
};
