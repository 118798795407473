import { trimUrlEnding, getUnitMetadata } from '@danfoss/etui-sm-xml';
import useSWR from 'swr';
import { useXmlResource, useAuth } from '@danfoss/etui-sm';
import { fetchInitialUnit } from '@danfoss/etui-sm/actions';

const MINIMUM_TIMEOUT_INTERVAL = 55000;
const oldInstallationVersionPropName = 'Installation-version';
const newInstallationVersionPropName = 'Installation_version';
function useKeepSessionAlive() {
  const {
    url
  } = useXmlResource();
  const {
    user
  } = useAuth();
  const trimmedUrl = trimUrlEnding(url);
  const metadata = getUnitMetadata(trimmedUrl) || {};
  const installationVersion = metadata[newInstallationVersionPropName] || metadata[oldInstallationVersionPropName];
  const shouldSendRequest = installationVersion && +installationVersion[0] > 3;
  useSWR(shouldSendRequest && [url, user], _ref => {
    let [url, user] = _ref;
    return fetchInitialUnit(url, user);
  }, {
    refreshInterval: MINIMUM_TIMEOUT_INTERVAL
  });
}

export { useKeepSessionAlive };
