import { fetchXMLData, XML_ACTION, XML_UPDATE_SW_CMD } from '@danfoss/etui-sm-xml';

async function fetchRemoveEdfBackups(url, user) {
  const response = await fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.UPDATE_SW,
      sw_upgrade_cmd: XML_UPDATE_SW_CMD.REMOVE_BACKUP_EDFS,
      group: 'EDF'
    }
  });
  if (response.return_code && response.return_code !== '0') {
    throw Error('error_code was not 0 on removing edf backups');
  }
  return response;
}

export { fetchRemoveEdfBackups };
