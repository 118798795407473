import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { Alert } from '@danfoss/etui-core';
import { LayoutFlowTitle } from '../../../RefrigLayoutModal/components/LayoutContent/LayoutFlowTitle';
import { CopyFlowTable } from '.';

export const CopyFlowPage = () => {
  const { t } = useTranslation();
  return (
    <Div testId="copy-flow-page-div" mt="10px">
      <Alert type="warning" message={t('t3420')} />
      <LayoutFlowTitle
        title={t('t3421')}
        subTitle={t('t3422')}
        helpText={t('t3423')}
      />
      <CopyFlowTable />
    </Div>
  );
};
