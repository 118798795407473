function mapDateTimeToNumber(d, t, dateDelimiter, dateFormat) {
  if (dateDelimiter === void 0) {
    dateDelimiter = '/';
  }
  if (dateFormat === void 0) {
    dateFormat = 'short';
  }
  const dateFormatIndexes = {
    year: dateFormat === 'short' ? 2 : 0,
    month: dateFormat === 'short' ? 1 : 1,
    day: dateFormat === 'short' ? 0 : 2
  };
  const dates = d.split(dateDelimiter);
  const times = t.replace(/\D/g, '');
  return Number(dates[dateFormatIndexes.year] + dates[dateFormatIndexes.month] + dates[dateFormatIndexes.day] + times);
}

export { mapDateTimeToNumber };
