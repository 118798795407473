import { Div } from '@danfoss/etui-system-elements';

export interface DeviceFlowStatusIconToolTipMessageProps {
  message: string;
}
export const DeviceFlowStatusIconToolTipMessage = ({
  message,
}: DeviceFlowStatusIconToolTipMessageProps) => {
  const messages = message?.split('&');
  return (
    <Div testId="device-flow-status-icon-tool-tip-message-div">
      {messages?.map((message, index) => {
        return (
          <Div key={index} testId={`${message}-div`}>
            {message}
          </Div>
        );
      })}
    </Div>
  );
};
