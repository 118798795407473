import { fetchXMLData, User, XML_ACTION } from '@danfoss/etui-sm-xml';
import { FileContent } from '../types/LocalGraphics.types';

export const getLocalGraphicFiles = async ([user, url, fileName, isSM800A]: [
  User,
  string,
  string,
  boolean,
]) => {
  const offset: string = '0';
  let b64: string = '';
  try {
    let fileContentResponse = await fetchFileContent([
      user,
      url,
      fileName,
      offset,
      isSM800A,
    ]);
    if (fileContentResponse?.error !== '0') {
      throw new Error();
    }
    b64 = fileContentResponse?.encodedfile?.b64;
    while (fileContentResponse?.done !== '1') {
      // eslint-disable-next-line no-await-in-loop
      fileContentResponse = await fetchFileContent([
        user,
        url,
        fileName,
        fileContentResponse?.offset[1].toString(),
        isSM800A,
      ]);
      b64 += fileContentResponse?.encodedfile?.b64;
    }
    const fileContent: FileContent = {
      b64,
      size: fileContentResponse?.enc_bytes,
      offset: fileContentResponse?.tot_bytes,
    };
    return fileContent;
  } catch (e) {
    throw new Error();
  }
};

const fetchFileContent = async ([user, url, fileName, offset, isSM800A]: [
  User,
  string,
  string,
  string,
  boolean,
]) => {
  try {
    const data = await fetchXMLData({
      url,
      attributes: isSM800A
        ? getAttributesSM800A(user, offset, fileName)
        : getAttributesSM800(user, offset, fileName),
    });
    return data;
  } catch (e) {
    throw new Error();
  }
};

const getAttributesSM800A = (user: User, offset: string, fileName: string) => {
  return {
    user: user?.user,
    password: user?.password,
    offset,
    access_area: 'user',
    filename: fileName,
    storeview_only: '1',
    version: 'C',
    action: XML_ACTION.GET_FILE,
  };
};

const getAttributesSM800 = (user: User, offset: string, fileName: string) => {
  return {
    user: user?.user,
    password: user?.password,
    offset,
    filename: fileName,
    storeview_only: '1',
    version: 'C',
    action: XML_ACTION.GET_FILE,
  };
};
