const getTimeStrFromHHMM = (
  ts: string,
  it: number,
  tf: '0' | '1' = '0',
): string => {
  // ts = string representation of time in HHMM format
  // it = integer version of time in HHMM format

  let s1: string = '';
  let s2: string;
  let hhmm: number;
  const systemtimeformat = Number(tf);

  if (ts) {
    hhmm = parseInt(ts, 10);
  } else {
    hhmm = it;
  }
  let hr: number = Math.floor(hhmm / 100);
  const mn: number = hhmm % 100;

  if (systemtimeformat) {
    // 24 hour format
    if (hr < 10) {
      s1 += '0';
    }
    s1 += `${hr}:`;

    if (mn < 10) {
      s1 += '0';
    }
    s1 += `${mn}`;
    return s1;
  }

  // else 12 hour format
  if (hr > 12) {
    hr -= 12;
    if (hr < 10) {
      s1 += '0';
    }
    s1 += `${hr}:`;
    s2 = ' PM';
  } else {
    if (hr === 0) {
      s1 = '12:';
    } else {
      if (hr < 10) {
        s1 += '0';
      }
      s1 += `${hr}:`;
    }
    if (hr === 12) {
      s2 = ' PM';
    } else {
      s2 = ' AM';
    }
  }
  if (mn < 10) {
    s1 += '0';
  }
  s1 += `${mn}`;
  s1 += s2;
  return s1;
};

export { getTimeStrFromHHMM };
