import * as React from 'react';
import { Unit, UnitNetwork } from '@danfoss/etui-sm-xml';
import { SelectInput, SelectInputOption } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';

export type ConfigurationMenuUnitSelectProps = {
  label: string;
  units: Unit[];
  disabledUnitNetworks?: UnitNetwork[];
  selectedUnit: Unit;
  onUnitSelection: (u: Unit) => void;
};

const getUnitDisplayName = ({ unit_name, unit_addr }: Unit) =>
  `${unit_name} addr: ${unit_addr}`;

const getNetworkDisplayName = ({ name, addr }: UnitNetwork) =>
  `${name} addr: ${addr}`;

const unitToOption = (u: Unit): SelectInputOption => ({
  value: u.unit_name || getUnitDisplayName(u),
  label: getUnitDisplayName(u),
});

const networkToDisabledOption = (network: UnitNetwork) => ({
  value: network.name,
  label: getNetworkDisplayName(network),
  isDisabled: true,
});

const ConfigurationMenuUnitSelect = React.memo(
  ({
    label,
    units = [],
    selectedUnit,
    disabledUnitNetworks = [],
    onUnitSelection,
  }: ConfigurationMenuUnitSelectProps) => {
    const theme = useTheme();

    const options = React.useMemo(() => {
      const unitOptions = units.map(unit => unitToOption(unit));
      const disabledOptions = disabledUnitNetworks.map(network =>
        networkToDisabledOption(network),
      );
      return [...unitOptions, ...disabledOptions];
    }, [units, disabledUnitNetworks]);

    return (
      <SelectInput
        name="unit-select"
        label={label}
        options={options}
        value={selectedUnit ? unitToOption(selectedUnit) : null}
        onChange={(option: SelectInputOption) => {
          const newOptionIndex = options.findIndex(opt => opt === option);
          onUnitSelection(units[newOptionIndex]);
        }}
        styles={{
          root: { borderBottom: `1px solid ${theme.palette.divider}` },
          input: {
            borderColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
          },
        }}
      />
    );
  },
);

export { ConfigurationMenuUnitSelect };
