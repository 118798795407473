import React from 'react';
import { saveAs } from 'file-saver';
import { Col, Modal, Row, Spinner } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { Div } from '@danfoss/etui-system-elements';
import {
  SoftwareUpdateAnimatedIcon,
  useAuth,
  useXmlResource,
} from '@danfoss/etui-sm';
import { fetchCopyConfigFile, sendBdCommands } from '../../actions';

interface RefrigCopySaveFileModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  onExit: () => void;
  bnum: number;
}
export const RefrigCopySaveFileModal = ({
  isOpen,
  onClose,
  onExit,
  bnum,
}: RefrigCopySaveFileModalProps) => {
  const { t } = useTranslation();
  const { url } = useXmlResource();
  const { user } = useAuth();
  const [isSuccess, setIsSuccess] = React.useState(false);

  const handleExitClick = () => {
    sendBdCommands(url, user);
    onExit();
  };

  React.useEffect(() => {
    (async () => {
      const { fileContent, fileName } = await fetchCopyConfigFile(
        url,
        user,
        bnum,
      );
      if (fileContent) {
        setIsSuccess(true);
      }
      const blob = new Blob([fileContent], { type: 'text/plain' });
      saveAs(blob, `${fileName}.cfz`);
    })();
  }, []);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={<Modal.Header title="Save file" showCloseButton={false} />}
        actionButtons={[
          {
            variant: 'secondary',
            children: t('t3382'),
            onClick: handleExitClick,
          },
          {
            variant: 'primary',
            children: t('t812'),
            onClick: onClose,
          },
        ]}
        shouldCloseOnOverlayClick={true}
      >
        <Row>
          <Col xs={12}>
            {isSuccess ? (
              <>
                <SoftwareUpdateAnimatedIcon
                  isDone={true}
                  isInProgress={false}
                />
                <Div>{t('t3450')}</Div>
                <Div>{t('t3451')}</Div>
              </>
            ) : (
              <>
                <Spinner />
                <Div>{t('t3452')}</Div>
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};
