import { styled } from '@danfoss/etui-system';
import { H5 } from '@danfoss/etui-core';

export default styled(H5)`
  height: fit-content;
  font-weight: ${({ theme }) => `${theme.typography.fontWeightBold}`};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  max-width: 100%;
`;
