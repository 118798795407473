import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Form, Div, Label } from '@danfoss/etui-system-elements';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { range } from '../utils/history-constants';
import { DateTimeInput } from './DateTimeInput';

export function HistoryTimeRange({
  control,
  dateRange,
  setDateRange,
  dateFormat,
  timeFormat,
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Form p={`${theme.spacing.xs}px`}>
      {range.map(({ name, label }, i) => (
        <React.Fragment key={name}>
          <Controller
            control={control}
            name={name}
            defaultValue={dateRange[name]}
            render={({ onChange, value }) => (
              <Div display="flex" flexDirection="column">
                <Label
                  {...theme.typography.label}
                  fontSize={theme.typography.fontSizeSmall}
                  color={theme.palette.text.secondary}
                >
                  {t(label)}
                </Label>
                <DateTimeInput
                  timeFormat={timeFormat === '0' ? '12' : '24'}
                  dateFormat={dateFormat}
                  name={name}
                  label={null}
                  onChange={date => {
                    setDateRange(prevRange => ({
                      ...prevRange,
                      [name]: date,
                    }));
                    onChange(date);
                  }}
                  value={value}
                  styles={{
                    root: i === 0 ? { mb: `${theme.spacing.xs}px` } : {},
                  }}
                />
              </Div>
            )}
          />
        </React.Fragment>
      ))}
    </Form>
  );
}
