function getClosestAccordion(el) {
  return el && (el.matches('[data-accordion-component="Accordion"]') ? el : getClosestAccordion(el.parentElement));
}
function getSiblingButtons(item) {
  const parentAccordion = getClosestAccordion(item);
  return parentAccordion && Array.from(parentAccordion.querySelectorAll('[data-accordion-component="AccordionItemButton"]'));
}
function focusFirstSiblingOf(item) {
  const siblings = getSiblingButtons(item) || [];
  const first = siblings[0];
  if (first) {
    first.focus();
  }
}
function focusLastSiblingOf(item) {
  const siblings = getSiblingButtons(item) || [];
  const last = siblings[siblings.length - 1];
  if (last) {
    last.focus();
  }
}
function focusNextSiblingOf(item) {
  const siblings = getSiblingButtons(item) || [];
  const currentIndex = siblings.indexOf(item);
  if (currentIndex !== -1) {
    const next = siblings[currentIndex + 1];
    if (next) {
      next.focus();
    }
  }
}
function focusPreviousSiblingOf(item) {
  const siblings = getSiblingButtons(item) || [];
  const currentIndex = siblings.indexOf(item);
  if (currentIndex !== -1) {
    const previous = siblings[currentIndex - 1];
    if (previous) {
      previous.focus();
    }
  }
}

export { focusFirstSiblingOf, focusLastSiblingOf, focusNextSiblingOf, focusPreviousSiblingOf, getClosestAccordion, getSiblingButtons };
