import * as React from 'react';

export * from './Configuration';
export * from './DeviceConfiguration';
export * from './UnitConfiguration';

const ConfigurationPageOld = React.lazy(
  () =>
    import(
      /* webpackChunkName: "old-configuration-page" */ './ConfigurationPageOld'
    ),
);

export { ConfigurationPageOld };
