import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { icons } from '@danfoss/etui-core';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml/';
import { useTranslation } from 'react-i18next';
import { LayoutFlowTitle } from '../../../RefrigLayoutModal/components/LayoutContent/LayoutFlowTitle';
import { DevicesFlowCopy, DevicesFlowTable } from '.';

export interface DevicesFlowPageProps {
  handleOnSelect: (selectedListItem: ConfigurationListItem) => void;
}

export const DevicesFlowPage = ({ handleOnSelect }: DevicesFlowPageProps) => {
  const { t } = useTranslation();
  return (
    <Div testId="device-page-div" mt="10px">
      <LayoutFlowTitle
        title={t('t3427')}
        subTitle={t('t3428')}
        helpText={t('t3429')}
        glyph={icons.COPY}
      />
      <DevicesFlowCopy />
      <DevicesFlowTable handleOnSelect={handleOnSelect} />
    </Div>
  );
};
