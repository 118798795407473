import { ConfigurationListItem } from '@danfoss/etui-sm-xml/';
import { TFunction } from 'i18next';
import { CopyDevicesTableContent } from '../../../types/RefrigCopy.types';
import { processData } from '../../../utils';

export const processCopyDevicesTableData = (
  tableContentData: ConfigurationListItem[],
  t: TFunction,
) => {
  const copyDevicesTableContentList: CopyDevicesTableContent[] = [];
  const copyDone: boolean =
    tableContentData[1]?.name?.toLowerCase().trim() ===
    t('t3425').toLowerCase();
  tableContentData?.forEach((data, index) => {
    if ((!copyDone && index > 5) || (copyDone && index > 3)) {
      const deviceDetails = processData(data);
      const copyDevicesTableContent: CopyDevicesTableContent = {
        address: deviceDetails?.address?.trim(),
        model: deviceDetails?.model?.trim(),
        name: deviceDetails?.name?.trim(),
        li: data.li,
        status: data.value,
        isCopying: true,
      };
      copyDevicesTableContentList.push(copyDevicesTableContent);
    }
  });
  return copyDevicesTableContentList;
};

export const compareCopiedWithOldDevicesContent = (
  oldDevicesTableContent: CopyDevicesTableContent[],
  copiedDeviesTableContent: CopyDevicesTableContent[],
  t: TFunction,
) => {
  oldDevicesTableContent.forEach((oldContent, oldContentIndex) => {
    copiedDeviesTableContent.forEach((copiedContent, copiedContentIndex) => {
      if (
        oldContentIndex === copiedContentIndex &&
        oldContent.name === copiedContent.name &&
        oldContent.model === copiedContent.model &&
        oldContent.address === copiedContent.address &&
        oldContent.status !== copiedContent.status
      ) {
        if (
          copiedContent.status &&
          copiedContent.status.toLowerCase() !== t('t486').toLowerCase()
        ) {
          const status = copiedContent.status
            .substring(
              copiedContent.status.indexOf(' '),
              copiedContent.status.length,
            )
            .trim();
          copiedContent.isCopying = false;
          copiedContent.status = status;
          oldContent.isCopying = false;
        } else {
          oldContent.status = copiedContent.status;
        }
      }
    });
  });
};
