export class ConfigAuthConst {
  static VERIFICATION_REQUEST: number = 0x80000000;
  // TODO research another auth const from legacy app...
}

function useConfigAuth<T extends { authtype: string }>(item: T): boolean {
  return Boolean(
    parseInt(item?.authtype, 10) & ConfigAuthConst.VERIFICATION_REQUEST,
  );
}

export { useConfigAuth };
