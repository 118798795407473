import { styled, css } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';

const SpinnerWrapperDiv = styled(Div)`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 1;

  ${props => !props.noBackground && css`
      background: white;
    `};

  ${props => props.transparent && css`
      background: rgba(255, 255, 255, 0.5);
    `};
`;

export { SpinnerWrapperDiv as S };
