import { Unit } from '@danfoss/etui-sm-xml';

export function getIsUnitHasMisc(unit: Unit) {
  return (
    parseInt(unit.ecfans, 10) > 0 ||
    parseInt(unit.rail, 10) > 0 ||
    parseInt(unit.miscoi, 10) > 0 ||
    parseInt(unit.miscsi, 10) > 0 ||
    parseInt(unit.miscro, 10) > 0 ||
    parseInt(unit.miscvo, 10) > 0 ||
    parseInt(unit.monitoring, 10) > 0 ||
    parseInt(unit.drives, 10) > 0 ||
    parseInt(unit.ovrs, 10) > 0 ||
    parseInt(unit.leaks, 10) > 0
  );
}
