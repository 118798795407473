import React from 'react';
import { parse } from 'himalaya';
import moment from 'moment';

function useEdfFileLatestSM800(shouldStart, filesFolder) {
  const [latestFileName, setLatestFileName] = React.useState();
  const [latestFileUrl, setLatestFileUrl] = React.useState();
  const [latestFileVersion, setLatestFileVersion] = React.useState();
  const [pending, setPending] = React.useState(true);
  const [error, setError] = React.useState();
  const [value, setValue] = React.useState();
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    const dateList = [];
    const actualDateList = [];
    const fileList = [];
    const fetchLatestFile = async () => {
      setPending(true);
      setProgress(10);
      try {
        const response = await fetch(filesFolder);
        setProgress(25);
        if (response.status === 200) {
          parse(await Promise.resolve(response.text())).map(responseJson => responseJson.children.filter(responseJsonChild => responseJsonChild.tagName === 'body').map(bodyObj => bodyObj.children.filter(bodyObjChild => bodyObjChild.tagName === 'pre').map(preObj =>
          // eslint-disable-next-line array-callback-return
          preObj.children.filter(preObjChild => {
            if (preObjChild.content !== undefined) {
              const dateValue = preObjChild.content.substring(0, preObjChild.content.trim().lastIndexOf(' '));
              const date = new Date(dateValue.trim());
              dateList.push(date);
              actualDateList.push(date);
            }
            if (preObjChild.children !== undefined && preObjChild.children.length > 0) {
              // eslint-disable-next-line array-callback-return
              preObjChild.children.filter(preObjChildContent => {
                if (preObjChildContent.content !== undefined && (preObjChildContent.content.includes('ED3') || preObjChildContent.content.includes('ED4'))) {
                  fileList.push(preObjChildContent.content);
                }
              });
            }
          })))).flat().flat();
          const latestFile = fileList[actualDateList.indexOf(dateList.sort((date1, date2) => date2 - date1)[0])];
          setLatestFileVersion(extractLatestFileVersion(latestFile));
          setLatestFileName(latestFile);
          setLatestFileUrl(`${filesFolder}${latestFile}`);
          setPending(false);
          setProgress(50);
        } else {
          setPending(true);
          setError(new Error(response.statusText));
        }
      } catch (e) {
        setPending(true);
        setError(new Error(e));
      }
    };
    if (shouldStart) {
      fetchLatestFile();
    }
  }, [shouldStart]);
  async function fetchEdfFiles() {
    setProgress(75);
    try {
      const response = await fetch(latestFileUrl);
      const fileValue = await Promise.resolve(response.blob());
      setProgress(100);
      setValue(fileValue);
    } catch (e) {
      setError(new Error(e));
    }
  }
  return {
    latestFileUrl,
    latestFileName,
    error,
    pending,
    value,
    fetchEdfFiles,
    progress,
    latestFileVersion
  };
}
function extractLatestFileVersion(latestFile) {
  const extensionRemoved = latestFile.substring(0, latestFile.indexOf('.'));
  const values = extensionRemoved.split('_');
  const month = values[3];
  const date = values[4];
  const year = values[5];
  const latestFileDate = `${month}-${date}-${year}`;
  const version = moment(latestFileDate).format('YYYYMMDD');
  return version;
}

export { useEdfFileLatestSM800 as u };
