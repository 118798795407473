/**
 * Normalize data to array.
 *
 * @export
 * @template T
 * @param {T[]} data
 * @returns {T[]}
 */
function getArray(data) {
  if (!data) {
    return [];
  }
  return Array.isArray(data) ? data : [data];
}

export { getArray };
