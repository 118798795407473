import {
  ConfigurationTabItem,
  XML_DEVICE_COMBO,
  XML_DEVICE_STYPE,
  XML_DEVICE_LIST,
  XML_DEVICE_STR,
  Device,
  getDeviceListByDeviceCombo,
  XML_DEVICE_NODETYPE,
} from '@danfoss/etui-sm-xml';
import {
  getIsRootRefrigerationDevice,
  getIsSuctionDevice,
} from '@danfoss/etui-sm';

function getDeviceTypeByComboAndSType(
  combo: ConfigurationTabItem['combo'],
  stype: ConfigurationTabItem['stype'],
) {
  if (combo === XML_DEVICE_COMBO.COMBO_NONE) {
    if (
      stype === XML_DEVICE_STYPE.S_TYPE_HPCO ||
      stype === XML_DEVICE_STYPE.S_TYPE_RECE ||
      stype === XML_DEVICE_STYPE.S_TYPE_HEAR ||
      stype === XML_DEVICE_STYPE.S_TYPE_HTWA
    ) {
      switch (stype) {
        case XML_DEVICE_STYPE.S_TYPE_HPCO:
          return XML_DEVICE_LIST.HPCO;

        case XML_DEVICE_STYPE.S_TYPE_RECE:
          return XML_DEVICE_LIST.RECE;

        case XML_DEVICE_STYPE.S_TYPE_HEAR:
          return XML_DEVICE_LIST.HEAR;

        case XML_DEVICE_STYPE.S_TYPE_HTWA:
          return XML_DEVICE_LIST.HTWA;

        default:
          throw new Error(`The stype: ${stype} is not supported`);
      }
    }
  }

  return getDeviceListByDeviceCombo(combo);
}

function getRackDevices(devices: Device[]) {
  return devices.filter(getIsRootRefrigerationDevice);
}

function getSuctionDevices(devices: Device[]) {
  return devices.filter(getIsSuctionDevice);
}

function getDevicesByType(devices: Device[], type: XML_DEVICE_STR) {
  return devices
    .filter(device => device.type === type)
    .map((device, index) => ({ ...device, comboindex: index }));
}

function getDevicesByCombo(
  devices: Device[],
  combo: ConfigurationTabItem['combo'],
): Device[] {
  // condenser
  if (combo === XML_DEVICE_COMBO.COMBO_COND) {
    return devices.filter(device => {
      // I'm not sure at all about commenting this out but this fixes
      // devices combo not appearing at Control -> Refrigeration -> Condenser
      // if (
      //   device.type === XML_DEVICE_STR.STR_TYPE_RACK &&
      //   device.combo === XML_DEVICE_COMBO.COMBO_RACK &&
      //   device.condenser !== '0'
      // ) {
      //   return true;
      // }
      return device.type === XML_DEVICE_STR.STR_TYPE_COND;
    });
  }

  // rack
  if (combo === XML_DEVICE_COMBO.COMBO_RACK) {
    return getRackDevices(devices).filter(device => {
      return device.stype !== XML_DEVICE_STYPE.S_TYPE_PACK;
    });
  }

  // rack only
  if (combo === XML_DEVICE_COMBO.COMBO_RACKONLY) {
    return getRackDevices(devices).filter(device => {
      if (device.type !== XML_DEVICE_STR.STR_TYPE_RACK) {
        return false;
      }

      if (device.combo !== XML_DEVICE_COMBO.COMBO_RACK) {
        return false;
      }

      return true;
    });
  }

  // suction
  if (combo === XML_DEVICE_COMBO.COMBO_SUCTION) {
    return getSuctionDevices(devices);
  }

  // multi suction
  if (combo === XML_DEVICE_COMBO.COMBO_MULTI_SUCTION) {
    return getRackDevices(devices);
  }

  // suction all
  if (combo === XML_DEVICE_COMBO.COMBO_SUCTALL) {
    return getSuctionDevices(devices);
  }

  // evaporators & circuits
  if (
    combo === XML_DEVICE_COMBO.COMBO_CIRCUIT ||
    combo === XML_DEVICE_COMBO.COMBO_EVAPS
  ) {
    return devices.filter(device => {
      if (
        device.type === XML_DEVICE_STR.STR_TYPE_EVAP ||
        device.type === XML_DEVICE_STR.STR_TYPE_EVAPIO
      ) {
        return true;
      }
      return false;
    });
  }

  // core sense
  if (combo === XML_DEVICE_COMBO.COMBO_CORESENSE) {
    return devices.filter(device => {
      if (
        device.type === XML_DEVICE_STR.STR_TYPE_CORESENSE &&
        device.combo === XML_DEVICE_COMBO.COMBO_CORESENSE
      ) {
        return true;
      }
      return false;
    });
  }

  // drives
  if (combo === XML_DEVICE_COMBO.COMBO_DRIVES) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_DRIVE);
  }

  // hvac
  if (combo === XML_DEVICE_COMBO.COMBO_HVAC) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_HVAC);
  }

  if (combo === XML_DEVICE_COMBO.COMBO_MCX) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_LIGHTING);
  }

  // light zones
  if (combo === XML_DEVICE_COMBO.COMBO_LIGHTS) {
    return devices
      .filter(device => device.type === XML_DEVICE_STR.STR_TYPE_ZONE)
      .map((device, index) => {
        if (device.type === XML_DEVICE_STR.STR_TYPE_ZONE) {
          return {
            ...device,
            comboindex: index,
            comboindex_light: index,
          };
        }
        return {
          ...device,
          comboindex_light: index,
        };
      });
  }

  // light panels
  if (combo === XML_DEVICE_COMBO.COMBO_PANELS) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_PANEL);
  }

  // scheds
  if (combo === XML_DEVICE_COMBO.COMBO_SCHEDS) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_SCHED);
  }

  // energy
  if (combo === XML_DEVICE_COMBO.COMBO_ENERGY) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_METER);
  }

  // vlt
  if (combo === XML_DEVICE_COMBO.COMBO_VLT) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_VLT);
  }

  // leak
  if (combo === XML_DEVICE_COMBO.COMBO_LEAK) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_LEAK);
  }

  // overrides
  if (combo === XML_DEVICE_COMBO.COMBO_OVERRIDES) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_OVERRIDE);
  }

  // fans
  if (combo === XML_DEVICE_COMBO.COMBO_FANS) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_ECFAN);
  }

  // calcs
  if (combo === XML_DEVICE_COMBO.COMBO_CALCS) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_CALPT);
  }

  // misc ro
  if (combo === XML_DEVICE_COMBO.COMBO_MIS_RO) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_RO);
  }

  // misc si
  if (combo === XML_DEVICE_COMBO.COMBO_MIS_SI) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_SI);
  }

  // misc oi
  if (combo === XML_DEVICE_COMBO.COMBO_MIS_OI) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_OI);
  }

  // misc vo
  if (combo === XML_DEVICE_COMBO.COMBO_MIS_VO) {
    return getDevicesByType(devices, XML_DEVICE_STR.STR_TYPE_VO);
  }

  // oi, ro, si, vo
  if (
    combo === XML_DEVICE_COMBO.COMBO_OI ||
    combo === XML_DEVICE_COMBO.COMBO_RO ||
    combo === XML_DEVICE_COMBO.COMBO_SI ||
    combo === XML_DEVICE_COMBO.COMBO_VO
  ) {
    return devices;
  }

  // generic
  if (combo === XML_DEVICE_COMBO.COMBO_GENERIC) {
    return devices.filter(device => {
      if (
        device.type === XML_DEVICE_STR.STR_TYPE_PACK ||
        device.type === XML_DEVICE_STR.STR_TYPE_EVAP ||
        device.type === XML_DEVICE_STR.STR_TYPE_DRIVEPACK ||
        device.type === XML_DEVICE_STR.STR_TYPE_DRIVE ||
        device.type === XML_DEVICE_STR.STR_TYPE_LEAK ||
        device.type === XML_DEVICE_STR.STR_TYPE_ECFAN ||
        device.type === XML_DEVICE_STR.STR_TYPE_LIGHTING ||
        (device.type === XML_DEVICE_STR.STR_TYPE_HVAC &&
          device.nodetype === XML_DEVICE_NODETYPE.NODETYPE_GN)
      ) {
        if (device?.suction?.toString() === '1') {
          return false;
        }

        if (device.type === XML_DEVICE_STR.STR_TYPE_LEAK) {
          if (device.modelname && device.modelname.length === 0) {
            return false;
          }
        }

        if (device.type === XML_DEVICE_STR.STR_TYPE_EVAP) {
          if (device.multicasename && device.multicasename.length === 0) {
            return false;
          }
        }

        return true;
      }
      return false;
    });
  }

  if (combo === XML_DEVICE_COMBO.COMBO_SENS) {
    return Array(10)
      .fill('')
      .map((_, i) => {
        const index = i + 1;
        const name = `Level ${index}`; // t804
        return {
          name,
          arg1: index,
          arg2: 1,
          combo,
          nodetype: 255, // Deviceconstant.NODE_TYPE_NA
          node: 0,
          bpidx: 1,
        };
      }) as unknown as Device[];
  }

  return [];
}

export { getDevicesByCombo, getDeviceTypeByComboAndSType };
