import * as React from 'react';
import { icons } from '@danfoss/etui-core';

export const getIconForCategory = (
  categoryKey: string,
  categoryMapping: any = {},
) => {
  return categoryMapping[categoryKey?.toLowerCase()] || icons.PARAMETERS;
};

export const parseLineBreaks = (text: string) =>
  text.split('\\n').map(textFragment => (
    <React.Fragment key={textFragment}>
      {textFragment}
      <br />
    </React.Fragment>
  ));
