import { SOFTWARE_VERSION_PREFIX } from '../../../constants/software-versions.js';

const fillWithZeroes = value => `${value}`.padStart(2, '0');
const getDBFileName = unit => {
  var _unit$software;
  const now = new Date();
  const date = now.toISOString().split('T')[0].replace(/-/g, '');
  const hours = fillWithZeroes(now.getHours());
  const minutes = fillWithZeroes(now.getMinutes());
  const seconds = fillWithZeroes(now.getSeconds());
  const time = `${hours}${minutes}${seconds}`;
  const isSM800 = unit === null || unit === void 0 ? void 0 : (_unit$software = unit.software) === null || _unit$software === void 0 ? void 0 : _unit$software.includes(SOFTWARE_VERSION_PREFIX.G08);
  return isSM800 ? 'latest.s55' : `${unit.unit_name}_Database_${date}_${time}.dpk`;
};

export { getDBFileName };
