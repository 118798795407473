import { useScadaStorageAzure, useScadaStorageXml } from '@danfoss/etui-scada';
import { storageType } from '../../../config';

const hooksMap = {
  azure: useScadaStorageAzure,
  xml: useScadaStorageXml,
};

export function getStorageHook():
  | typeof useScadaStorageAzure
  | typeof useScadaStorageXml
  | null {
  const envStorage = storageType;

  return hooksMap[envStorage] ?? null;
}
