import '../constants/regex-patterns-constant.js';
import '../constants/session-storage-constants.js';
import { XML_ACTION } from '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Metadata.js';
import '../types/Unit.js';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/url-helpers/get-is-valid-dns.js';
import '../helpers/url-helpers/get-is-valid-ip.js';
import '../helpers/url-helpers/get-is-valid-ip-or-dns.js';
import '../helpers/url-helpers/get-is-valid-port.js';
import '../helpers/url-helpers/get-url-info.js';
import '../helpers/unit-helpers/initial-unit-utils.js';
import '../helpers/unit-helpers/get-unit-networks-by-unit.js';
import '../helpers/unit-helpers/get-unit-network-by-unit.js';
import '../helpers/unit-helpers/get-unit-url-by-unit-network.js';
import { getUnitUrl } from '../helpers/unit-helpers/get-unit-url.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import 'csvtojson';
import '../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../fetch-xml/fetch-xml-data.js';

async function fetchConfigurationTableByUnit(_ref) {
  let [sourceUrl, unit, table_name, tableaddress, user] = _ref;
  return fetchXMLData({
    url: getUnitUrl(sourceUrl, unit),
    attributes: {
      user: user === null || user === void 0 ? void 0 : user.user,
      password: user === null || user === void 0 ? void 0 : user.password,
      lang: user === null || user === void 0 ? void 0 : user.language,
      action: XML_ACTION.READ_TABLE,
      table_name,
      tableaddress
    }
  });
}
async function fetchConfigurationListByUnit(sourceUrl, unit, tableaddress, index, user, useparent, isconfigure, bpidx, stype, nodetype, node, group, subgroup, page, combo, configuretype, arg1, arg2, arg3, old_cfgtype, listtabname, pnum, parentpnum, listindex, parentlistindex, bnum, parentbnum, parentgroup, calptindex, listgroup, listcombo, skipSessionUpdate) {
  if (useparent === void 0) {
    useparent = null;
  }
  if (isconfigure === void 0) {
    isconfigure = null;
  }
  if (bpidx === void 0) {
    bpidx = null;
  }
  if (stype === void 0) {
    stype = null;
  }
  if (nodetype === void 0) {
    nodetype = null;
  }
  if (node === void 0) {
    node = null;
  }
  if (group === void 0) {
    group = null;
  }
  if (subgroup === void 0) {
    subgroup = null;
  }
  if (page === void 0) {
    page = null;
  }
  if (combo === void 0) {
    combo = null;
  }
  if (configuretype === void 0) {
    configuretype = null;
  }
  if (arg1 === void 0) {
    arg1 = null;
  }
  if (arg2 === void 0) {
    arg2 = null;
  }
  if (arg3 === void 0) {
    arg3 = null;
  }
  if (old_cfgtype === void 0) {
    old_cfgtype = null;
  }
  if (listtabname === void 0) {
    listtabname = null;
  }
  if (pnum === void 0) {
    pnum = null;
  }
  if (parentpnum === void 0) {
    parentpnum = null;
  }
  if (listindex === void 0) {
    listindex = null;
  }
  if (parentlistindex === void 0) {
    parentlistindex = null;
  }
  if (bnum === void 0) {
    bnum = null;
  }
  if (parentbnum === void 0) {
    parentbnum = null;
  }
  if (parentgroup === void 0) {
    parentgroup = null;
  }
  if (calptindex === void 0) {
    calptindex = null;
  }
  if (listgroup === void 0) {
    listgroup = null;
  }
  if (listcombo === void 0) {
    listcombo = null;
  }
  if (skipSessionUpdate === void 0) {
    skipSessionUpdate = false;
  }
  return fetchXMLData({
    url: getUnitUrl(sourceUrl, unit),
    attributes: _objectSpread2({
      user: user === null || user === void 0 ? void 0 : user.user,
      password: user === null || user === void 0 ? void 0 : user.password,
      lang: user === null || user === void 0 ? void 0 : user.language,
      action: XML_ACTION.READ_LIST,
      table_name: '',
      tableaddress,
      index,
      useparent,
      isconfigure,
      bpidx,
      stype,
      nodetype,
      node,
      group,
      subgroup,
      page,
      combo,
      configuretype,
      arg1,
      arg2,
      arg3,
      old_cfgtype,
      listtabname,
      pnum,
      parentpnum,
      listindex,
      parentlistindex,
      bnum,
      parentbnum,
      parentgroup,
      calptindex,
      listgroup,
      listcombo,
      storeview_only: '1',
      version: 'C'
    }, skipSessionUpdate ? {
      session_update: 'no'
    } : {})
  });
}
async function fetchConfigurationWriteList(url, tableaddress, useparent, isconfigure, configuretype, bpidx, lang, user) {
  if (useparent === void 0) {
    useparent = '0';
  }
  if (isconfigure === void 0) {
    isconfigure = '0';
  }
  if (configuretype === void 0) {
    configuretype = '';
  }
  if (bpidx === void 0) {
    bpidx = '0';
  }
  return fetchXMLData({
    url,
    attributes: {
      lang,
      user: user === null || user === void 0 ? void 0 : user.user,
      password: user === null || user === void 0 ? void 0 : user.password,
      action: XML_ACTION.WRITE_LIST,
      table_name: '',
      tableaddress,
      useparent,
      isconfigure,
      configuretype,
      bpidx
    }
  });
}
async function fetchConfigurationList(_ref2) {
  let [sourceUrl, tableaddress, index, user, useparent = null, isconfigure = null, bpidx = null, stype = null, nodetype = null, node = null, group = null, subgroup = null, page = null, combo = null, configuretype = null, arg1 = null, arg2 = null, arg3 = null, old_cfgtype = null] = _ref2;
  return fetchXMLData({
    url: sourceUrl,
    attributes: {
      user: user === null || user === void 0 ? void 0 : user.user,
      password: user === null || user === void 0 ? void 0 : user.password,
      lang: user === null || user === void 0 ? void 0 : user.language,
      action: XML_ACTION.READ_LIST,
      table_name: '',
      tableaddress,
      index,
      useparent,
      isconfigure,
      bpidx,
      stype,
      nodetype,
      node,
      group,
      subgroup,
      page,
      combo,
      configuretype,
      arg1,
      arg2,
      arg3,
      old_cfgtype,
      storeview_only: '1',
      version: 'C'
    }
  });
}
async function fetchLayoutListByUnit(_ref3) {
  let [sourceUrl, unit, tableaddress, index, user, page, nodetype] = _ref3;
  return fetchXMLData({
    url: getUnitUrl(sourceUrl, unit),
    attributes: {
      user: user === null || user === void 0 ? void 0 : user.user,
      password: user === null || user === void 0 ? void 0 : user.password,
      lang: user === null || user === void 0 ? void 0 : user.language,
      action: XML_ACTION.READ_LIST,
      storeview_only: '1',
      table_name: '',
      tableaddress,
      index,
      page,
      nodetype,
      version: 'C'
    }
  });
}
async function fetchLayoutListByUnitWithGroup(_ref4) {
  let [sourceUrl, unit, tableaddress, index, user, group, combo, nodetype, bpidx] = _ref4;
  return fetchXMLData({
    url: getUnitUrl(sourceUrl, unit),
    attributes: {
      user: user === null || user === void 0 ? void 0 : user.user,
      password: user === null || user === void 0 ? void 0 : user.password,
      lang: user === null || user === void 0 ? void 0 : user.language,
      action: XML_ACTION.READ_LIST,
      table_name: '',
      tableaddress,
      index,
      group,
      combo,
      nodetype,
      bpidx,
      storeview_only: '1',
      version: 'C'
    }
  });
}

export { fetchConfigurationList, fetchConfigurationListByUnit, fetchConfigurationTableByUnit, fetchConfigurationWriteList, fetchLayoutListByUnit, fetchLayoutListByUnitWithGroup };
