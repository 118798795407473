import { StrategyAttributePlatformName } from 'featurehub-javascript-client-sdk';

const getUserPlatform = _ref => {
  let {
    isWindows,
    isMacOs,
    isAndroid,
    isIOS
  } = _ref;
  return isWindows ? StrategyAttributePlatformName.Windows : isMacOs ? StrategyAttributePlatformName.Macos : isAndroid ? StrategyAttributePlatformName.Android : isIOS ? StrategyAttributePlatformName.Ios : StrategyAttributePlatformName.Windows;
};

export { getUserPlatform };
