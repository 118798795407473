/**
 *
 * @param reference version can be either sm_app, danux or SM800A-package version
 * @param comparison version can be either sm_app, danux or SM800A-package version
 * @returns wether the comparison version is higher lower or equal to the provided reference version
 */
const compareSoftwareVersions = (reference, comparison) => {
  if (reference === comparison) {
    return 'equal';
  }
  if (!reference && comparison) {
    return 'larger';
  }
  if (reference && !comparison) {
    return 'lower';
  }
  const isSmAppVersion = version => version.startsWith('G') || version.startsWith('SMG');

  /*
   *  if providing a SmAppVersion as reference but comparison is package version,
   *  assume the provided reference is older than the package version format in general
   */
  if (isSmAppVersion(reference) && !isSmAppVersion(comparison)) {
    return 'larger';
  }

  /*
   *  if providing a SmAppVersion as comparison but reference is package version,
   *  assume the provided comparison is older than the package version format in general
   */
  if (!isSmAppVersion(reference) && isSmAppVersion(comparison)) {
    return 'lower';
  }
  const createSplit = version => {
    version = isSmAppVersion(version) ? version.startsWith('SMG') ? version.slice(3) : version.slice(1) : version;
    return version.replace('-alpha', '.0.') // Force alpha preid to be lower version than beta
    .replace('-beta', '.1.').replace(' ', '').replace('SMG', '').replace('GSM', '').replace('SM', '').split('.');
  };
  const referenceSplit = createSplit(reference);
  const comparisonSplit = createSplit(comparison);
  const largestSplit = referenceSplit.length > comparisonSplit.length ? referenceSplit : comparisonSplit;
  for (let i = 0; i < largestSplit.length; i++) {
    const getNumberFromVersion = versionSplit => {
      return versionSplit.length > i ? Number(versionSplit[i]) : Number.MAX_SAFE_INTEGER; // Consider 1.0.0 to be larger than 1.0.0.1
    };
    const latestNumber = getNumberFromVersion(comparisonSplit);
    const currentNumber = getNumberFromVersion(referenceSplit);
    if (latestNumber > currentNumber) {
      return 'larger';
    }
    if (latestNumber < currentNumber) {
      return 'lower';
    }
  }

  // If comparison was not returned in loop, assume newer version format and thereby a newer version
  return 'larger';
};

export { compareSoftwareVersions };
