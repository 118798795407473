function stringToNumber(s) {
  return parseInt(s.replace(/\D/g, ''), 10);
}
function getOlderSoftwareVersion(software1, software2) {
  if (!software1 || software1 === software2) {
    return software2;
  }
  const v1 = {
    split: software1.split('.'),
    original: software1
  };
  const v2 = {
    split: software2.split('.'),
    original: software2
  };
  const shorter = v1.split.length <= v2.split.length ? v1 : v2;
  const longer = shorter === v1 ? v2 : v1;
  for (let i = 0; i < shorter.split.length; i++) {
    const n1 = stringToNumber(shorter.split[i]);
    const n2 = stringToNumber(longer.split[i]);
    if (n1 < n2) {
      return shorter.original;
    }
    if (n1 > n2) {
      return longer.original;
    }
  }
  if (shorter.split.length < longer.split.length) {
    return shorter.original;
  }
  return longer.original;
}

export { getOlderSoftwareVersion };
