import * as React from 'react';
import { Steps } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import {
  XML_REFRIG_LAYOUT_TAB_INDEX,
  XML_TABLE_NAMES,
} from '@danfoss/etui-sm-xml';
import { Div } from '@danfoss/etui-system-elements';
import { ContentSpinner } from '@danfoss/etui-sm';
import { NetworkContentView } from '../NetworkFlowContent';
import { useRefrigLayoutContent } from '../../../../hooks';
import { getIsSkippi200, getRefrigLayoutSteps } from '../../../../utils';
import { useRefrigLayout } from '../../context';
import { DiscoveryFlowContent } from '../DiscoveryFlowContent';
import { CompressorsFlowContent } from '../CompressorsFlowContent';
import { CircuitsFlowContent } from '../CircuitsFlowContent';
import { SuctionGroupsFlowContent } from '../SuctionGroupsFlowContent';
import { RefrigSteps } from '../../types/DiscoveryPage.types';
import { REFRIG_LAYOUT_STEP } from '../../types/RefrigLayout.types';

export function RefrigLayoutContent() {
  const {
    step,
    activeStepNumber,
    setRefrigSteps,
    refrigSteps,
    setSkipPI200,
    setNetworkListData,
  } = useRefrigLayout();

  const { tableContentData, stepContents, networkTableAddress, isValidating } =
    useRefrigLayoutContent(
      REFRIG_LAYOUT_STEP.NETWORK,
      XML_TABLE_NAMES.TABLE_REFRIG_SCAN,
    );

  const { t } = useTranslation();

  const steps: RefrigSteps[] = getRefrigLayoutSteps(true, stepContents, t);

  React.useEffect(() => {
    setRefrigSteps(steps);
  }, [steps?.length]);

  React.useEffect(() => {
    if (tableContentData?.length) {
      setNetworkListData(tableContentData);
      const isSkippi200 = getIsSkippi200(tableContentData);
      setSkipPI200(isSkippi200);
    }
  }, [tableContentData]);

  return (
    <>
      {isValidating ? (
        <Div>
          <ContentSpinner size={1} transparent={true} />
        </Div>
      ) : (
        <>
          {refrigSteps && (
            <Steps activeStep={activeStepNumber}>
              {refrigSteps?.map(s => (
                <Steps.Step
                  key={s?.stepNumber}
                  {...s}
                  isCompleted={s?.stepNumber < activeStepNumber}
                />
              ))}
            </Steps>
          )}
          {tableContentData && step === +XML_REFRIG_LAYOUT_TAB_INDEX.NETWORK ? (
            <NetworkContentView
              title={t('t3355')}
              subTitle={t('t3356')}
              helpText={t('t3357')}
              tableAddress={networkTableAddress}
            />
          ) : step === +XML_REFRIG_LAYOUT_TAB_INDEX.DISCOVERY ? (
            <DiscoveryFlowContent isHvacDiscovery={false} />
          ) : step === +XML_REFRIG_LAYOUT_TAB_INDEX.COMPRESSORS ? (
            <CompressorsFlowContent />
          ) : step === +XML_REFRIG_LAYOUT_TAB_INDEX.CIRCUITS ? (
            <CircuitsFlowContent />
          ) : step === +XML_REFRIG_LAYOUT_TAB_INDEX.SUCTION_GROUPS ? (
            <SuctionGroupsFlowContent />
          ) : null}
        </>
      )}
    </>
  );
}
