import { SVG_PREFIX } from './mxgraph-constants.js';
import { getAnimatedSpinner } from '../utils/get-animated-spinner.js';

/* eslint-disable */

const ELEMENT_WIDTH = 120;
const ELEMENT_HEIGHT = 40;
const LOADER_ICON = getAnimatedSpinner();
class RuleContainer {
  constructor(_ref) {
    let {
      graph,
      container,
      value,
      item,
      createRule
    } = _ref;
    this.graph = graph;
    this.container = container;
    this.value = value;
    this.item = item;
    this.createRule = createRule;
    this.init();
  }
  init() {
    new mxRubberband(this.graph);
    this.graph.setConnectable(true);
    toolbar = new mxToolbar(this.container);
    // @ts-ignore
    toolbar.enabled = false;

    // Inserts a cell at the given location
    const funct = (graph, evt) => {
      const model = graph.getModel();
      const root = model.getRoot();
      const textLayer = root.children[1] ? root.children[1] : graph.importCells([new mxCell()], null, null, root)[0];
      const cell = new mxCell(this.value, new mxGeometry(0, 0, ELEMENT_WIDTH, ELEMENT_HEIGHT), 'rounded=1;whiteSpace=wrap;html=1;opacity=70;strokeColor=none');
      cell.setVertex(true);
      const {
        x,
        y
      } = graph.getPointForEvent(evt);
      const cells = graph.importCells([cell], x, y, textLayer);
      const svgIconId = `${cells[0].id}-svg_icon`;
      this.graph.insertVertex(cells[0], svgIconId, ``, -20, 0, 20, 20, `${SVG_PREFIX}${LOADER_ICON};`);
      graph.scrollCellToVisible(cells[0]);
      graph.setSelectionCells(cells[0]);
      this.createRule(cells[0]);
    };
    mxUtils.makeDraggable(this.container, this.graph, funct);
  }
}

export { RuleContainer };
