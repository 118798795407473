import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, ModalProps } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationListItem as ConfigurationListItemProp } from '@danfoss/etui-sm-xml';
import { useAuth } from '@danfoss/etui-sm';
import { useConfiguration } from '../../context';
import { checkPasswordUpdatedForCurrentUser } from './utils';

export type ConfigurationListItemEditSaveModalProps = Partial<ModalProps> & {
  header?: string;
  onSaveChanges: () => void;
  onCancelChanges: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  item?: ConfigurationListItemProp;
};

function ConfigurationListItemEditSaveModal({
  header,
  onSaveChanges,
  onCancelChanges,
  onClose,
  isOpen,
  item,
}: ConfigurationListItemEditSaveModalProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { configurationDataItem } = useConfiguration();
  const isPasswordField = checkPasswordUpdatedForCurrentUser(
    item,
    configurationDataItem,
    item?.value,
    user,
  );

  const handleClose = event => {
    onClose(event);
    onCancelChanges(event);
  };

  const handleSubmit = event => {
    onClose(event);
    onSaveChanges();
  };

  React.useEffect(() => {
    if (!isOpen) return;

    const hanldeKeyDown = (event: KeyboardEvent) => {
      event.key === 'Escape' && handleClose(event);
      event.key === 'Enter' && handleSubmit(event);
    };

    document.addEventListener('keydown', hanldeKeyDown);
    return () => document.removeEventListener('keydown', hanldeKeyDown);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Header title={header || t('t214')} />}
      actionButtons={[
        {
          variant: 'secondary',
          children: t('t45'),
          onClick: handleClose,
        },
        {
          variant: 'primary',
          onClick: handleSubmit,
          children: t('t44'),
        },
      ]}
    >
      <Row>
        <Col xs={12}>
          <Div testId="field-update-warning">
            {isPasswordField ? `${t('t965')} ${t('t3316')}` : t('t965')}
          </Div>
        </Col>
      </Row>
    </Modal>
  );
}

export { ConfigurationListItemEditSaveModal };
