import { fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function fetchUpdateEdfFiles(selectedEdfFiles, url, user) {
  const response = await fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.INSTALL_EDF_VERSIONS,
      storeview_only: '1',
      version: 'C'
    },
    items: selectedEdfFiles.map(filename => ({
      tag: 'filename',
      value: filename
    }))
  });
  if (response.return_code && response.return_code !== '0') {
    throw Error('error_code was not 0 on edf files update');
  }
  return response;
}

export { fetchUpdateEdfFiles };
