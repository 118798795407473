import { getArray } from 'utils';
import {
  ScheduleDetail,
  ScheduleDevice,
  ScheduleRes,
  ScheduleTreeItem,
  ScheduleType,
} from '../SchedulesPage.types';

const adaptSchedules = (schedules: ScheduleRes) => {
  const schedulesDevice = schedules?.device
    ? Array.isArray(schedules?.device)
      ? schedules?.device
      : [schedules?.device]
    : [];

  return schedulesDevice.map((sched: ScheduleDevice) => ({
    ...sched,
    usage: sched.schedules.usage,
  }));
};

const getSchedule = (
  type: ScheduleType | 'Case Lighting',
  schedule: ScheduleRes,
) => {
  const adaptedSched = adaptSchedules(schedule);
  return adaptedSched
    .filter(sched => sched.usage === type)
    .map(({ schedules }: ScheduleDevice) => {
      const schedDetail: ScheduleDetail[] = schedules?.schedules?.sch_detail
        ? getArray(schedules?.schedules?.sch_detail)
        : [];
      return schedDetail.map((detail: ScheduleDetail) => {
        return {
          count: Number(schedules.schedules.sch_number),
          type: detail.type,
          defr_interval: parseInt(schedules?.def_interval, 10),
          on_time: detail.on_time,
          off_time: detail.off_time,
          weekdays: detail.weekdays,
          holidays: detail.holidays,
        };
      });
    })
    .flat();
};

const getGenSchedulesData = (
  selectedDevice: ScheduleTreeItem,
  schedules: ScheduleRes,
) => {
  const res = selectedDevice.devices.map((device: ScheduleTreeItem) => {
    return {
      ...device,
      shutdownSchedules: device.shutdown_sch_id
        ? getSchedule('Shutdown', schedules)
        : [],
      defrostSchedules: device.defrost_sch_id
        ? getSchedule('Defrost', schedules)
        : [],
      lightsSchedules: device.case_light_sch_id
        ? getSchedule('Case Lighting', schedules)
        : [],
      nightSchedules: device.night_setback_sch_id
        ? getSchedule('Night Setback', schedules)
        : [],
    };
  });

  return res;
};

export { getGenSchedulesData };
