export const QUICKSETUP_MENU = {
  authtype: '1',
  combo: '0',
  comboreserve: '0',
  configuretype: '0',
  editable: '1',
  id: '20091-0',
  index: '0',
  label: 'Quick Setup',
  needcombinecase: '0',
  optimization: '0',
};

export enum QUICKSETUP_SUBMENU {
  SYSTEM_USERS = '/20091-2/20085',
  SYSTEM_PREFERENCES = '/20091-2/20085-0',
  SYSTEM_LICENSING = '/20091-2/20085',
}
