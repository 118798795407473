import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Device,
  Unit,
  getUnitUrl,
  ConfigurationTabItem,
  ConfigurationListItem,
  CONFIGURATION_REFRESH_INTERVAL,
  XML_DEVICE_COMBO,
} from '@danfoss/etui-sm-xml';
import {
  ContentEmpty,
  ContentSpinner,
  useXmlResource,
  useAuth,
  getIsMatchedSoftwareVersion,
  SOFTWARE_VERSION_PREFIX,
  useUnit,
  EmptyState,
} from '@danfoss/etui-sm/';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import {
  ContainerDimensions,
  TableVirtualizedInfinite,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { usePrevious } from 'hooks';
import {
  useConfiguration,
  useConfigurationAuth,
  useConfigurationModals,
  useConfigurationMenu,
} from '../..';
import {
  ConfigurationTabByUnitResponse,
  fetchConfigurationTabByUnit,
  writeConfigurationListItem,
} from '../../actions';
import { withErrorHandled } from '../../utils/with-error-handled';
import { ConfigurationItemVirtualizedList as VirtualizedList } from './ConfigurationItemVirtualizedList';
import {
  checkPasswordUpdatedForCurrentUser,
  createSequenceFromNumber,
  getAuditNames,
  getBpidx,
  getIsEditableByTab,
  getTableDataRefreshInterval,
} from './utils';
import { NodeTypes, getAddresses } from './utils/address-utils';
import { useSaveWithRevalidate } from './hooks/useSaveWithRevalidate';
import { prepareAuditTableData } from './utils/auditTrailTableConfig';
import { getFallbackData } from './utils/get-fallback-data';

export type ConfigurationListItemAddress = ConfigurationListItem & {
  addressLine: ConfigurationListItem;
  optionsLine: ConfigurationListItem;
};

export type ConfigurationItemContentListProps = {
  menuId?: string;
  unit: Unit;
  tab?: ConfigurationTabItem;
  isconfigure?: '0' | '1';
  refreshInterval?: number;
  preselectedDevice?: Device;
  itemRenderer?: (...args: any[]) => React.ReactNode;
  secondLevelMenuItems?: ConfigurationTabItem;
  onPasswordUpdatedForCurrentUser?: (
    passwordUpdated: boolean,
    updatedPassword: string,
  ) => void;
  updateTime?: (menuId: string, currentTimeMs: number) => void;
};

function ConfigurationItemContentList({
  menuId,
  unit,
  tab = null,
  isconfigure = '0',
  refreshInterval = CONFIGURATION_REFRESH_INTERVAL.DEFAULT,
  itemRenderer,
  secondLevelMenuItems,
  onPasswordUpdatedForCurrentUser,
  preselectedDevice = null,
  updateTime,
}: ConfigurationItemContentListProps) {
  const { openModals } = useConfigurationModals();
  const { user } = useAuth();
  const { url: xmlBackendURL } = useXmlResource();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    location: { pathname },
  } = useHistory();
  const { getItemIsAuthorized } = useConfigurationAuth(tab, user);
  const {
    onSetConfigurationDataItem,
    editedField,
    onSetSubgroupnames,
    cachedDevices,
    onSetMultiPage,
  } = useConfiguration();

  const [tabId, tabOrder] = menuId.split('-');

  const {
    device,
    deviceGroup,
    deviceSubgroup,
    page,
    searchMode,
    searchValue,
    deviceIsUpdated,
    onDeviceUpdate,
    foodGroup,
  } = useConfigurationMenu();

  const selectedDevice = preselectedDevice || device || ({} as Device);
  const isAuditTrail = menuId.includes('20105-5');
  const { units } = useUnit();
  const isSm800A = getIsMatchedSoftwareVersion(units, [
    SOFTWARE_VERSION_PREFIX.G09,
    SOFTWARE_VERSION_PREFIX.G10,
  ]);
  const refreshFetchIntervalValue = getTableDataRefreshInterval(
    pathname,
    isSm800A,
  );

  const useTabCombo = Boolean(tab?.combo);
  const tabIsEditable = getIsEditableByTab(tab);
  const configuretype = tab?.configuretype || '0';

  const useparent = '0';
  const bpidx = getBpidx(selectedDevice);
  const stype = selectedDevice.stype || null;
  const node = selectedDevice.node || null;
  const combo = useTabCombo ? tab.combo : selectedDevice.combo || '0';
  const isCPT = combo === XML_DEVICE_COMBO.COMBO_CPT;
  const nodetype = isCPT
    ? NodeTypes.NODE_TYPE_NA
    : selectedDevice.nodetype || null;
  const arg1 = isCPT ? foodGroup?.index : selectedDevice.arg1 || null;
  const arg2 = selectedDevice.arg2 || null;
  const arg3 = selectedDevice.arg3 || null;
  const isOnline = selectedDevice.online ? +selectedDevice.online : null;
  const old_cfgtype = secondLevelMenuItems?.configuretype || '0';
  const fallbackData = getFallbackData(tabId, tabOrder);

  const prepareArguments = (pageNumber: string) => [
    xmlBackendURL,
    unit,
    menuId,
    user,
    cachedDevices,
    useparent,
    isconfigure,
    bpidx,
    stype,
    nodetype,
    node,
    deviceGroup || '0',
    deviceSubgroup || '0',
    pageNumber,
    combo,
    configuretype,
    arg1,
    arg2,
    arg3,
    isOnline,
    old_cfgtype,
  ];

  const { data, error, mutate, isValidating } =
    useSWR<ConfigurationTabByUnitResponse>(
      () =>
        openModals.length || editedField || searchMode
          ? null
          : prepareArguments(page),
      fetchConfigurationTabByUnit,
      {
        refreshInterval: refreshFetchIntervalValue,
      },
    );

  const prevMenuId = usePrevious(menuId);
  const prevValidation = usePrevious(isValidating);
  const isRequestFinished = !isValidating && prevValidation;
  if (isRequestFinished && prevMenuId === menuId && updateTime) {
    updateTime(menuId, Date.now());
  }

  const dataList = React.useRef(fallbackData.list || []);

  const list = data?.list || [];
  const subgroupnames = data?.subgroupnames || [];
  const multipage = data?.multipage;

  const amountOfPages = React.useRef<number>();

  React.useEffect(() => {
    if (multipage) {
      amountOfPages.current = +multipage;
    }
  }, [multipage]);

  const createArrayOfPages = React.useCallback(
    numOfPages => createSequenceFromNumber(numOfPages),
    [amountOfPages],
  );

  const getFullList = (listOfPages: string[]) =>
    listOfPages.map(pageNum => prepareArguments(pageNum));

  const pages = searchMode && createArrayOfPages(amountOfPages.current);

  const fetchAllPages = async listOfRequests => {
    const response = await Promise.allSettled(
      listOfRequests.map(options => fetchConfigurationTabByUnit(options)),
    );

    return (
      response
        .filter(promise => promise.status === 'fulfilled')
        // @ts-ignore
        .map(promise => promise.value.list)
        .flat()
    );
  };

  const { data: allPagesData = [], isLoading: isAllPagesDataLoading } = useSWR(
    pages ? getFullList(pages) : null,
    fetchAllPages,
  );

  const addresses = React.useMemo(
    () => getAddresses(dataList.current),
    [dataList.current],
  );

  useDeepCompareEffect(() => {
    onSetConfigurationDataItem(dataList.current);
  }, [dataList.current]);

  useDeepCompareEffect(() => {
    onSetSubgroupnames(menuId, subgroupnames);
  }, [subgroupnames]);

  React.useEffect(() => {
    onSetMultiPage(menuId, multipage);
  }, [menuId, multipage]);

  const handleSave = React.useCallback(
    async (listItem, { value, ival, fval = null } = {}) => {
      const res = withErrorHandled(t, theme, writeConfigurationListItem)(
        getUnitUrl(xmlBackendURL, unit),
        user,
        getAuditNames(dataList.current, parseInt(listItem.li, 10), tab?.label),
        menuId,
        selectedDevice || null,
        deviceGroup,
        configuretype,
        listItem,
        value,
        ival,
        fval,
        undefined,
        undefined,
        combo,
        arg1,
      );

      const maskedPassword: string = value?.replace(/./g, '*');
      const isPasswordUpdatedForCurrentUser =
        checkPasswordUpdatedForCurrentUser(
          listItem,
          dataList.current,
          maskedPassword,
          user,
        );

      if (isPasswordUpdatedForCurrentUser) {
        onPasswordUpdatedForCurrentUser(isPasswordUpdatedForCurrentUser, value);
      }

      return res;
    },
    [
      selectedDevice,
      deviceGroup,
      dataList.current,
      menuId,
      configuretype,
      tab?.label,
      unit,
      user,
      xmlBackendURL,
    ],
  );

  const { execute: executeOnHandleSave } = useSaveWithRevalidate(
    handleSave,
    mutate,
    refreshInterval,
    true,
  );

  const resetConfigSwrCache = () => {
    mutate({} as ConfigurationTabByUnitResponse);
    dataList.current = [];
  };

  const filterAllData = React.useCallback(
    (listItems: ConfigurationListItem[]): ConfigurationListItem[] => {
      const processedSearchWord = searchValue.trim().toLowerCase();
      let lastFoundIndex = null;

      const filteredItems = listItems.filter(({ devicetype, name }, index) => {
        if (devicetype && name.toLowerCase().includes(processedSearchWord)) {
          lastFoundIndex = index;
          return true;
        }

        return lastFoundIndex !== null && lastFoundIndex + 1 === index;
      });

      return filteredItems;
    },
    [searchValue],
  );

  if (!openModals.length && !editedField && !isValidating) {
    dataList.current = searchMode ? filterAllData(allPagesData) : list;
    onDeviceUpdate(false);
  }

  const noFilteredItems =
    searchMode && !isAllPagesDataLoading && !dataList.current.length;

  React.useEffect(() => {
    dataList.current = list;
  }, [pathname]);

  const isLoading =
    (!dataList.current?.length && !error) || isAllPagesDataLoading;

  const auditTableData =
    isAuditTrail && prepareAuditTableData(dataList.current);

  const auditTrailtableColumns = [
    {
      title: t('t3183'),
      dataIndex: 'eventId',
      ellipsis: true,
    },
    {
      title: t('t3184'),
      dataIndex: 'eventTime',
      ellipsis: true,
    },
    {
      title: t('t3185'),
      dataIndex: 'eventRole',
      ellipsis: true,
    },
    {
      title: t('t3186'),
      dataIndex: 'eventInfo',
      ellipsis: true,
    },
  ];

  const renderAuditInfoContent = !isLoading && isAuditTrail && (
    <ContainerDimensions>
      {({ width, height }) => (
        <Div
          style={{
            height,
            padding: `${theme.spacing.sm}px`,
          }}
        >
          <TableVirtualizedInfinite
            hasNextPage={false}
            isNextPageLoading={true}
            totalCount={auditTableData.length}
            minimumBatchSize={500}
            onLoadNextPage={null}
            rowKey={(record: any, index) => {
              return `${record?.eventId}-${index}`;
            }}
            scroll={{ y: height - theme.spacing.xxlg * 2, x: width }}
            columns={auditTrailtableColumns}
            dataSource={auditTableData}
            emptyPlaceholder={<ContentEmpty title={t('t33')} />}
          />
        </Div>
      )}
    </ContainerDimensions>
  );

  return (
    <>
      {noFilteredItems ? (
        <EmptyState
          title={t('t3341')}
          size="small"
          styles={{ root: { p: theme.spacing.md } }}
        />
      ) : isLoading || deviceIsUpdated ? (
        <Div testId="item-content-spinner">
          <ContentSpinner size={1} transparent={true} />
        </Div>
      ) : isAuditTrail ? (
        renderAuditInfoContent
      ) : (
        <VirtualizedList
          menuId={menuId}
          isUpdating={isValidating}
          isFailed={!!error}
          list={dataList.current}
          tab={tab}
          getItemIsAuthorized={getItemIsAuthorized}
          addresses={addresses}
          unit={unit}
          tabIsEditable={tabIsEditable}
          onSave={executeOnHandleSave}
          itemRenderer={itemRenderer}
          resetConfigSwrCache={resetConfigSwrCache}
        />
      )}
    </>
  );
}

export { ConfigurationItemContentList };
