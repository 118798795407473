import { getUnitUrlByUnitNetwork } from '@danfoss/etui-sm-xml';

function getFirstValidUnitUrls(units, unitInfo, protocol) {
  const firstValidUnitUrls = units.map(u => {
    const validUnitNetworks = unitInfo.validUnitNetworks.filter(n => n.addr === u.unit_addr);
    const internalNetwork = validUnitNetworks === null || validUnitNetworks === void 0 ? void 0 : validUnitNetworks.find(n => !n.isExternal);
    const externalNetwork = validUnitNetworks === null || validUnitNetworks === void 0 ? void 0 : validUnitNetworks.find(n => n.isExternal);
    const firstValidNetwork = internalNetwork || externalNetwork;
    const firstValidUrl = getUnitUrlByUnitNetwork(firstValidNetwork, protocol);
    return {
      addr: u.unit_addr,
      url: firstValidUrl,
      network: firstValidNetwork
    };
  });
  return firstValidUnitUrls;
}

export { getFirstValidUnitUrls };
