import { ConfigurationListItem } from '@danfoss/etui-sm-xml/';
import { DevicesTableContent } from '../../../types/RefrigCopy.types';
import { processData } from '../../../utils';

export const processDevicesTableData = (
  tableContentData: ConfigurationListItem[],
) => {
  const devicesTableContentList: DevicesTableContent[] = [];
  tableContentData?.forEach(data => {
    if (data.toggle && data.toggleVal) {
      const deviceDetails = processData(data);
      const devicesTableContent: DevicesTableContent = {
        address: deviceDetails?.address?.trim(),
        model: deviceDetails?.model?.trim(),
        name: deviceDetails?.name?.trim(),
        li: data?.li,
        toggleVal: data?.toggleVal,
      };
      devicesTableContentList.push(devicesTableContent);
    }
  });
  return devicesTableContentList;
};
