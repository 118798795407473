/**
 *
 * @param date - Date to parse - required format - 07:00AM - [hour]:[minute]([AM|PM]?)
 *
 *  updated --> now works without 12h unit mark 07:00
 */

import { OpenClose } from '../SchedulesPage.types';

const parseResponseTimeString = (date: string): OpenClose => {
  const regex = /^([0-9]{2})[:]([0-9]{2})([\s]{1,})?([AM|PM]{1,})?$/gi;

  const result = regex.exec(date);

  if (!result) return null;

  const [, hour, minute, , units = null] = result;

  return {
    hour,
    minute,
    units,
  };
};

const parseRelativeTimeString = (time: string) => {
  const replacedScheduleString = time.replace(/[+|-]{1}/gi, '');
  return parseResponseTimeString(replacedScheduleString);
};

export { parseResponseTimeString, parseRelativeTimeString };
