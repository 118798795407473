import { XmlError } from '@danfoss/etui-sm-xml';

export function errorCode2TCode(errorCode: XmlError['code']): string {
  // TODO is this too fragile?
  // map might be better
  // error codes are [1-111, 999, 998]
  // t codes are [2001-2111, 2999, 2998]
  return errorCode.toString().padStart(5, 't200');
}

export function getErrorCodeTranslator(t) {
  return function errorCodeTranslator(c: XmlError['code']) {
    const tCode = errorCode2TCode(c);
    const translation = t(tCode);
    if (translation === tCode) {
      // no translation available in dictionary
      return '';
    }
    return translation;
  };
}
