/**
 * Normalize data to array.
 *
 * @export
 * @template T
 * @param {T[]} data
 * @returns {T[]}
 */
export function getArray<T>(data: T | T[]): T[] {
  if (!data) {
    return [];
  }
  return Array.isArray(data) ? (data as T[]) : [data];
}
