import { Div, Span } from '@danfoss/etui-system-elements';
import { Icon, IconGlyph } from '@danfoss/etui-core';
import React from 'react';

export interface ShowParentDeviceWithIconProps {
  deviceName: string;
  deviceIcon: IconGlyph;
  styleProp?: React.CSSProperties;
}

export const ShowParentDeviceWithIcon = ({
  deviceName,
  deviceIcon,
  styleProp,
}: ShowParentDeviceWithIconProps) => {
  return (
    <Div testId="parent-device-div" style={styleProp}>
      <Span testId="device-icon">
        <Icon isIconLabel={true} glyph={deviceIcon} />
      </Span>
      <Span testId="device-name">{deviceName}</Span>
    </Div>
  );
};
