import { XmlErrorResponse } from '@danfoss/etui-sm';
import { useState, useCallback, useEffect } from 'react';

function useAsync<T, U = any>(
  asyncFunction: (...args: U[]) => Promise<T>,
  immediate = true,
) {
  const [executeCount, setExecuteCount] = useState(0);
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState<T>(null);
  const [error, setError] = useState<XmlErrorResponse>(null);

  const handleSetValue = (response: any) => {
    setValue(response);
    setExecuteCount(executeCount + 1);
  };

  const handleSetError = (e: Error) => {
    setError(e);
    setExecuteCount(executeCount + 1);
  };

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (...args: U[]) => {
      setPending(true);
      setValue(null);
      setError(null);
      return asyncFunction(...args)
        .then((response: any) => handleSetValue(response))
        .catch((e: XmlErrorResponse) => handleSetError(e))
        .finally(() => setPending(false));
    },
    [asyncFunction],
  );

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, pending, value, error, executeCount };
}

export { useAsync };
