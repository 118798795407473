import {
  ButtonProps,
  SelectedValueType,
  TreeTableItem,
} from '@danfoss/etui-core';
import * as React from 'react';
import { ConfigurationListItem, Unit } from '@danfoss/etui-sm-xml';
import {
  CircuitsTableData,
  DeviceCompressorData,
  DeviceConstraintsData,
  DeviceTypeModelList,
  DiscoveryDeviceStatusCnt,
  DiscoveryTableData,
  RefrigSteps,
} from '../types/DiscoveryPage.types';
import { PI200TableData } from '../types';
import { AvailableCasesTableData } from '../types/RefrigLayout.types';
import {
  RefrigLayoutContext,
  RefrigLayoutContextProps,
  RefrigLayoutFlow,
} from './RefrigLayoutContext';

interface RefrigLayoutProviderProps {
  children: React.ReactNode;
}

function RefrigLayoutProvider({ children }: RefrigLayoutProviderProps) {
  const DEFAULT_FLOW = null;
  const DEFAULT_BUTTONS_BY_STEP = {};
  const DEFAULT_STEP = 1;

  const [selectedFlow, setSelectedFlow] =
    React.useState<RefrigLayoutFlow>(DEFAULT_FLOW);

  const [unit, setSelectedUnit] = React.useState<Unit>();

  const [buttonsByStep, setButtonsByStep] = React.useState<
    RefrigLayoutContextProps['buttonsByStep']
  >(DEFAULT_BUTTONS_BY_STEP);

  const [step, setStep] =
    React.useState<RefrigLayoutContextProps['step']>(DEFAULT_STEP);

  const [circuitsData, setCircuitsData] = React.useState<CircuitsTableData[]>(
    [],
  );

  const [deviceConstraintsData, setDeviceConstraintsData] =
    React.useState<DeviceConstraintsData>(null);

  const [compressorControlData, setCompressorControlData] = React.useState<
    TreeTableItem[]
  >([]);

  const [availableCasesData, setAvailableCasesData] = React.useState<
    AvailableCasesTableData[]
  >([]);

  const [searchedAvailableCasesData, setSearchedAvailableCasesData] =
    React.useState<AvailableCasesTableData[]>([]);

  const [caseTypes, setCaseTypes] = React.useState<string[]>([]);

  const [discoveryTableData, setDiscoveryTableData] = React.useState<
    DiscoveryTableData[]
  >([]);
  const [filteredTableData, setFilteredTableData] = React.useState<
    DiscoveryTableData[]
  >([]);
  const [circuitsTableData, setCircuitsTableData] = React.useState<
    DiscoveryTableData[]
  >([]);
  const [isOfflineProgramming, setIsOfflineProgramming] =
    React.useState<boolean>(true);

  const [isCaseAssignOperation, setIsCaseAssignOperation] =
    React.useState<boolean>(false);
  const [isCaseRemoveOperation, setIsCaseRemoveOperation] =
    React.useState<boolean>(false);
  const [allowMultiCaseCreation, setAllowMultiCaseCreation] =
    React.useState<boolean>(false);
  const [activeStepNumber, setActiveStepNumber] = React.useState<number>(1);
  const [discoveryDeviceStatusCnt, setDiscoveryDeviceStatusCnt] =
    React.useState<DiscoveryDeviceStatusCnt>(null);

  const setFlow = React.useCallback(
    (newFlow: RefrigLayoutFlow) => {
      setSelectedFlow(newFlow);
    },
    [selectedFlow],
  );
  const setButtons = React.useCallback(
    (newButtons: ButtonProps[]) => {
      setButtonsByStep({ [step]: newButtons });
    },
    [step],
  );
  const [refrigSteps, setRefrigSteps] = React.useState<RefrigSteps[]>([]);

  const [currentSelectedCaseDeviceIds, setCurrentSelectedCaseDeviceIds] =
    React.useState<SelectedValueType[]>([]);

  const [currentSectionsOpen, setCurrentSectionsOpen] = React.useState<
    number[]
  >([]);

  const setUnit = React.useCallback(
    (unit: Unit) => {
      setSelectedUnit(unit);
    },
    [selectedFlow],
  );
  const [isTableRefreshing, setIsTableRefreshing] =
    React.useState<boolean>(false);
  const [deviceTypeModelList, setDeviceTypeModelList] =
    React.useState<DeviceTypeModelList>();
  const [currentSelectedRowKeys, setCurrentSelectedRowKeys] = React.useState<
    number[]
  >([]);
  const [deviceCompressorData, setDeviceCompressorData] =
    React.useState<DeviceCompressorData>();
  const [currentSelectedCase, setCurrentSelectedCase] =
    React.useState<AvailableCasesTableData>();
  const [selectedCompressorDeviceId, setSelectedCompressorDeviceId] =
    React.useState<number>();

  const [selectedCompressorTreeDeviceId, setSelectedCompressorTreeDeviceId] =
    React.useState<number>();

  const [selectedCompressorRowIndex, setSelectedCompressorRowIndex] =
    React.useState<number>(0);
  const [currentSearchInputText, setCurrentSearchInputText] =
    React.useState<string>('');

  const [currentEditingField, setCurrentEditingField] =
    React.useState<string>('');

  const [skipPI200, setSkipPI200] = React.useState<boolean>(false);
  const [isHvacLayout, setIsHvacLayout] = React.useState<boolean>(false);
  const [networkListData, setNetworkListData] = React.useState([]);

  const [circuitTypeModelList, setCircuitTypeModelList] =
    React.useState<DeviceTypeModelList>();
  const [isHvac, setIsHvac] = React.useState<boolean>(false);
  const [hvacDeviceTypeModelList, setHvacDeviceTypeModelList] =
    React.useState<DeviceTypeModelList>();
  const [hvacFinalTableData, setHvacFinalTableData] = React.useState<
    DiscoveryTableData[]
  >([]);

  const [pi200TableData, setPI200TableData] = React.useState<PI200TableData>();
  const [selectAll, setSelectAll] = React.useState<ConfigurationListItem[]>([]);
  const [notAuth, setNotAuth] = React.useState<ConfigurationListItem>(null);

  return (
    <RefrigLayoutContext.Provider
      value={{
        flow: selectedFlow,
        setFlow,
        setButtons,
        buttonsByStep,
        setStep,
        step,
        deviceConstraintsData,
        setDeviceConstraintsData,
        discoveryTableData,
        setDiscoveryTableData,
        filteredTableData,
        setFilteredTableData,
        isOfflineProgramming,
        setIsOfflineProgramming,
        discoveryDeviceStatusCnt,
        setDiscoveryDeviceStatusCnt,
        activeStepNumber,
        setActiveStepNumber,
        refrigSteps,
        setRefrigSteps,
        unit,
        setUnit,
        isTableRefreshing,
        setIsTableRefreshing,
        skipPI200,
        setSkipPI200,
        networkListData,
        setNetworkListData,
        setCircuitTypeModelList,
        circuitTypeModelList,
        caseTypes,
        setCaseTypes,
        circuitsData,
        setCircuitsData,
        circuitsTableData,
        setCircuitsTableData,
        currentEditingField,
        setCurrentEditingField,
        deviceTypeModelList,
        setDeviceTypeModelList,
        deviceCompressorData,
        setDeviceCompressorData,
        selectedCompressorDeviceId,
        setSelectedCompressorDeviceId,
        selectedCompressorRowIndex,
        setSelectedCompressorRowIndex,
        isHvac,
        setIsHvac,
        hvacDeviceTypeModelList,
        setHvacDeviceTypeModelList,
        hvacFinalTableData,
        setHvacFinalTableData,
        isHvacLayout,
        setIsHvacLayout,
        currentSelectedCaseDeviceIds,
        setCurrentSelectedCaseDeviceIds,
        compressorControlData,
        setCompressorControlData,
        availableCasesData,
        setAvailableCasesData,
        currentSectionsOpen,
        setCurrentSectionsOpen,
        currentSelectedCase,
        setCurrentSelectedCase,
        allowMultiCaseCreation,
        setAllowMultiCaseCreation,
        currentSearchInputText,
        setCurrentSearchInputText,
        searchedAvailableCasesData,
        setSearchedAvailableCasesData,
        currentSelectedRowKeys,
        setCurrentSelectedRowKeys,
        isCaseAssignOperation,
        setIsCaseAssignOperation,
        isCaseRemoveOperation,
        setIsCaseRemoveOperation,
        pi200TableData,
        setPI200TableData,
        selectAll,
        setSelectAll,
        notAuth,
        setNotAuth,
        selectedCompressorTreeDeviceId,
        setSelectedCompressorTreeDeviceId,
      }}
    >
      {children}
    </RefrigLayoutContext.Provider>
  );
}

export { RefrigLayoutProvider };
