const getCombinedEdfFilesList = (installedData, updateData) => {
  let combined = installedData ? installedData.map(_ref => {
    let {
      filename,
      date,
      size
    } = _ref;
    return {
      filename,
      dateInstalled: date,
      sizeInstalled: size
    };
  }) : null;
  if (updateData) {
    if (!combined) {
      combined = [];
    }
    updateData.forEach(_ref2 => {
      let {
        filename: newFilename,
        date,
        size
      } = _ref2;
      const existingFile = combined.find(_ref3 => {
        let {
          filename: existingFilename
        } = _ref3;
        return existingFilename === newFilename;
      });
      if (existingFile) {
        existingFile.dateUpdate = date;
        existingFile.sizeUpdate = size;
      } else {
        combined.push({
          filename: newFilename,
          dateUpdate: date,
          sizeUpdate: size
        });
      }
    });
  }
  if (combined) {
    combined.sort((a, b) => !a.dateInstalled ? -1 : a.dateInstalled > b.dateInstalled ? -1 : 1);
  }
  return combined;
};

export { getCombinedEdfFilesList };
