import 'react';
import '@danfoss/etui-system';
import '@danfoss/etui-system-elements';
import { getFirstKeyValuePair } from './get-first-key-value-pair.js';

const getStoreName = data => {
  const firstObject = data[0];
  const {
    value: nameField
  } = getFirstKeyValuePair(firstObject);
  const nameSplit = nameField ? nameField.split(':') : [];
  if (nameSplit.length > 1) {
    return nameSplit[1];
  }
  return null;
};

export { getStoreName };
