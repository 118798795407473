/**
 * Return info about a given url
 *
 * @export
 * @param {string} url
 * @returns {({ protocol: 'http:' | 'https:'; host: string })}
 */
function getUrlInfo(url) {
  const urlSplit = url.split('/');
  const protocol = urlSplit[0];
  const host = urlSplit[2];
  return {
    protocol,
    host
  };
}

export { getUrlInfo };
