import React from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { useAuth, useXmlResource } from '@danfoss/etui-sm';
import { isXmlError } from '@danfoss/etui-sm-xml';
import { Notification } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import {
  fetchDeviceListData,
  processCompressorData,
  updateSuctionGroups,
} from '../../actions';
import { useRefrigLayout } from '../../context';
import { getTypeBasedOnDeviceType } from '../../utils';
import {
  DeviceCompressorData,
  DiscoveryTableData,
} from '../../types/DiscoveryPage.types';
import { copyCompressor } from '../../actions/compressor-flow-copy-actions';
import { deleteCompressor } from '../../actions/compressor-flow-delete-actions';
import { addRack } from '../../actions/compressor-flow-add-actions';
import { CompressorsFlowPage } from './CompressorsFlowPage';

export const CompressorsFlowContent = () => {
  const { t } = useTranslation();
  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();
  const theme = useTheme();
  const {
    discoveryTableData,
    deviceTypeModelList,
    deviceCompressorData,
    deviceConstraintsData,
    isOfflineProgramming,
    filteredTableData,
    setDeviceTypeModelList,
    setDeviceCompressorData,
    setDiscoveryTableData,
  } = useRefrigLayout();
  const type: number = getTypeBasedOnDeviceType(2);
  const {
    data: deviceModelList,
    isLoading,
    error,
  } = useSWR(
    () => [xmlBackendURL, user, type],
    !deviceTypeModelList ? fetchDeviceListData : null,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  if (isXmlError(error)) {
    Notification.error({
      message: t('t17'),
      description: t('t3364'),
      duration: 3,
      theme,
    });
  }

  const tableDataLoading = !error && isLoading;

  React.useEffect(() => {
    if (deviceModelList) {
      setDeviceTypeModelList(deviceModelList);
    }
  }, [deviceModelList]);

  React.useEffect(() => {
    if (deviceTypeModelList?.modelList?.length && !deviceCompressorData) {
      const updatedData: DeviceCompressorData = processCompressorData(
        isOfflineProgramming ? discoveryTableData : filteredTableData,
        t,
        deviceTypeModelList,
        true,
      );
      setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
      setDeviceCompressorData(updatedData);
    }
  }, [deviceTypeModelList]);

  const handleOnCopyOk = (copyAmount: number, selectedRowIndex: number) => {
    const copiedDiscoveryTableData: DiscoveryTableData[] = copyCompressor(
      copyAmount,
      selectedRowIndex,
      discoveryTableData,
      deviceConstraintsData,
      deviceCompressorData.suction,
      t,
    );
    const updatedData: DeviceCompressorData = processCompressorData(
      copiedDiscoveryTableData,
      t,
      deviceTypeModelList,
      false,
    );
    setDiscoveryTableData(copiedDiscoveryTableData);
    setDeviceCompressorData(updatedData);
  };

  const handleDeleteSelectedRow = (selectedRowIndex: number) => {
    const deletedDiscoveryTableData: DiscoveryTableData[] = deleteCompressor(
      selectedRowIndex,
      discoveryTableData,
      deviceCompressorData.suction,
    );
    const updatedData: DeviceCompressorData = processCompressorData(
      deletedDiscoveryTableData,
      t,
      deviceTypeModelList,
      false,
    );
    setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
    setDeviceCompressorData(updatedData);
  };

  const handleOnSuctionChange = (
    selectedRowIndex: number,
    selectedSuctionGroupValue: number,
  ) => {
    const updatedSuctionGroupData: DiscoveryTableData[] = updateSuctionGroups(
      selectedRowIndex,
      selectedSuctionGroupValue,
      discoveryTableData,
      deviceCompressorData.suction,
      deviceConstraintsData,
      t,
    );

    const updatedData: DeviceCompressorData = processCompressorData(
      updatedSuctionGroupData,
      t,
      deviceTypeModelList,
      false,
    );
    setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
    setDeviceCompressorData(updatedData);
  };

  const handleOnAddRack = () => {
    const addedPackData: DiscoveryTableData[] = addRack(
      discoveryTableData,
      deviceConstraintsData,
      deviceCompressorData?.suction,
      t,
      deviceTypeModelList,
    );

    const updatedData: DeviceCompressorData = processCompressorData(
      addedPackData,
      t,
      deviceTypeModelList,
      false,
    );
    setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
    setDeviceCompressorData(updatedData);
  };

  return (
    <CompressorsFlowPage
      handleOnCopyOk={handleOnCopyOk}
      handleDeleteSelectedRow={handleDeleteSelectedRow}
      handleOnSuctionChange={handleOnSuctionChange}
      tableDataLoading={tableDataLoading}
      handleOnAddRack={handleOnAddRack}
    />
  );
};
