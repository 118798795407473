import { DEVICE_TYPE, DiscoveryTableData } from '../types';

const deviceTypes: DEVICE_TYPE[] = [
  DEVICE_TYPE.CIRCUIT,
  DEVICE_TYPE.GEN_CIRCUIT,
];

export function isCircuitUnderNewSuctionBeforeCreation(
  device: DiscoveryTableData,
  newSuctionRk: number,
): boolean {
  return (
    device.rk === newSuctionRk &&
    deviceTypes.includes(device.deviceType as DEVICE_TYPE)
  );
}
