export function removeDeviceDuplicates(devices: any[]) {
  const uniqueDevices = devices.reduce((device, currentDevice) => {
    if (
      !device.some(
        previousDevice =>
          previousDevice.point === currentDevice.point &&
          previousDevice.filex === currentDevice.filex &&
          previousDevice.mod === currentDevice.mod &&
          previousDevice.node === currentDevice.node &&
          previousDevice.nodeType === currentDevice.nodeType &&
          previousDevice.host === currentDevice.host,
      )
    ) {
      device.push(currentDevice);
    }
    return device;
  }, []);
  return uniqueDevices;
}
