import React from 'react';
import {
  ConfigurationListItem,
  fetchLayoutListByUnit,
  XML_REFRIG_COPY_TAB_INDEX,
} from '@danfoss/etui-sm-xml/';
import { useAuth, useUnit, useXmlResource } from '@danfoss/etui-sm/';
import { Notification } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system/';
import { useRefrigCopy } from '../../context';
import { getArray } from '../../../../../Configuration/utils';
import {
  DevicesTableContent,
  DevicesTableData,
} from '../../types/RefrigCopy.types';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { writeConfigurationListItem } from '../../../../../Configuration/actions';
import { REFRIG_SUCCESS_RESPONSE } from '../../../RefrigLayoutModal/types';
import { processDevicesTableData } from './actions';
import { DevicesFlowPage } from '.';

interface DeviceFlowContentProps {
  tableAddress: string;
}

export const DevicesFlowContent = ({
  tableAddress,
}: DeviceFlowContentProps) => {
  const { url, ip } = useXmlResource();
  const { user } = useAuth();
  const { units, getFirstValidUrl } = useUnit();
  const filteredUnit = units.filter(unit =>
    getFirstValidUrl(unit).includes(ip),
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const unit = filteredUnit[0];
  const {
    page,
    devicesTableContent,
    setDevicesTableContent,
    setDevicesTableData,
    setDevicesListItems,
    setIsEmptyCopyDevicesTableData,
  } = useRefrigCopy();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const networkTableData = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.DEVICES,
          user,
          page,
        ]);
        if (networkTableData?.items?.l?.length) {
          const tableContentData = getArray(networkTableData?.items?.l);
          const devicesTableContent: DevicesTableContent[] =
            processDevicesTableData(tableContentData);
          const updatedDevicesTableData: DevicesTableData = {
            devicesTableContent,
          };
          setDevicesListItems(tableContentData);
          setDevicesTableContent(devicesTableContent);
          setDevicesTableData(updatedDevicesTableData);
          if (!updatedDevicesTableData.devicesTableContent.length) {
            setIsEmptyCopyDevicesTableData(true);
          }
        }
      } catch (error) {
        Notification.error({
          message: t('t17'),
          description: t('t3426'),
          duration: 3,
          theme,
        });
      }
    };
    if (!devicesTableContent.length) {
      fetch();
    }
  }, [page]);

  const saveSelectedRow = async (listItem: ConfigurationListItem) => {
    const response = await withErrorHandled(
      t,
      theme,
      writeConfigurationListItem,
    )(
      url,
      user,
      null,
      `${tableAddress}-${XML_REFRIG_COPY_TAB_INDEX.DEVICES}`,
      null,
      '0',
      '0',
      listItem,
      listItem.fVal,
      listItem.iVal,
      '',
    );
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      await revalidate();
    }
  };

  const revalidate = async () => {
    const result = await fetchLayoutListByUnit([
      url,
      unit,
      tableAddress,
      XML_REFRIG_COPY_TAB_INDEX.DEVICES,
      user,
      page.toString(),
    ]);
    if (result?.items?.l) {
      const tableContentData = getArray(result?.items?.l);
      const devicesTableContent: DevicesTableContent[] =
        processDevicesTableData(tableContentData);
      const updatedDevicesTableData: DevicesTableData = {
        devicesTableContent,
      };
      setDevicesListItems(tableContentData);
      setDevicesTableContent(devicesTableContent);
      setDevicesTableData(updatedDevicesTableData);
    }
  };

  const handleOnSelect = async (selectedListItem: ConfigurationListItem) => {
    await saveSelectedRow(selectedListItem);
  };

  return <DevicesFlowPage handleOnSelect={handleOnSelect} />;
};
