import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { LayoutFlowTitle } from '../../../RefrigLayoutModal/components/LayoutContent/LayoutFlowTitle';
import { GraphFlowTable } from './GraphFlowTable';

export interface GraphFlowPageProps {
  handleOnSelect: (record: ConfigurationListItem) => void;
}

export const GraphFlowPage = ({ handleOnSelect }: GraphFlowPageProps) => {
  const { t } = useTranslation();
  return (
    <Div testId="graph-flow-page-div" mt="10px">
      <LayoutFlowTitle
        title={t('t3446')}
        subTitle={t('t3447')}
        glyph={icons.GRAPH}
      />
      <GraphFlowTable handleOnSelect={handleOnSelect} />
    </Div>
  );
};
