export function generateNumberArray(min: number, max: number): number[] {
  const values = [min];
  for (let value of values) {
    if (value <= max - 1) {
      value = ++value;
      values.push(value);
    } else {
      break;
    }
  }
  return values;
}
