import { styled, css } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';
import { darken } from '@danfoss/etui-colors';

type Props = DivProps & {
  level: number;
  hasNestedItems: boolean;
  isRowSelected: boolean;
};

export const TreeViewItemToggleDiv = styled(Div).attrs(
  ({ level, theme, ...props }: Props): DivProps => ({
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    p: `${theme.spacing.xxs}px`,
    lineHeight: 0,
    ml: level === 0 ? 0 : `${level * theme.spacing.lg}px`,
    ...props,
  }),
)<Props>`
  transition: all 0.2s;
  ${({ hasNestedItems }) =>
    !hasNestedItems &&
    css`
      visibility: hidden;
    `}

  &:focus,
  &:hover {
    outline: 0;
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) =>
      darken(theme.palette.action.hover, 0.075)};
  }
`;
