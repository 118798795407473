import * as React from 'react';
import { A, Span } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { deleteCookie } from 'utils';
import { COOKIE_PROTOCOL } from 'pages/LoginPage/hooks';
import {
  DESKTOP_INSTALLER_URL,
  FAQ_SECURE_CONNECTION_URL,
} from '../../../../config';

export const SecurityWarning = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const sharedStyles = {
    display: 'block',
    fontWeight: 700,
    textDecoration: 'underline',
    marginTop: `${theme.spacing.sm}px`,
    cursor: 'pointer',
  };

  const downloadDesktopVersion = () =>
    window.open(DESKTOP_INSTALLER_URL, '_blank');

  const visitFAQ = () => window.open(FAQ_SECURE_CONNECTION_URL, '_blank');

  React.useEffect(() => {
    deleteCookie(COOKIE_PROTOCOL);
  }, []);

  return (
    <>
      <Span testId="security-warning">{t('t3681')}</Span>
      <A {...sharedStyles} onClick={downloadDesktopVersion}>
        {t('t3138')}
      </A>
      <A {...sharedStyles} onClick={visitFAQ}>
        {t('t3682')}
      </A>
    </>
  );
};
