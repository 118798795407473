import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Unit } from '@danfoss/etui-sm-xml';
import { useUnit } from '@danfoss/etui-sm';
import {
  ConfigurationMenu,
  ConfigurationMenuUnitSelect,
  TABLE_ADDRESS,
} from '../Configuration';

export interface UnitConfigurationBaseMenuProps {
  units: Unit[];
  selectedUnit: Unit;
  onUnitSelection: (u: Unit) => void;
}

const UnitConfigurationBaseMenu = React.memo(
  ({
    units = [] as Unit[],
    selectedUnit,
    onUnitSelection,
  }: UnitConfigurationBaseMenuProps) => {
    const { t } = useTranslation();

    const { failedNetworks } = useUnit();

    const disabledUnitNetworks = React.useMemo(
      () => failedNetworks.map(n => n.network),
      [failedNetworks],
    );

    return (
      <>
        <ConfigurationMenuUnitSelect
          label={t('t136')}
          units={units}
          disabledUnitNetworks={disabledUnitNetworks}
          selectedUnit={selectedUnit}
          onUnitSelection={onUnitSelection}
        />

        <ConfigurationMenu
          unit={selectedUnit}
          hiddenMenuItems={[`${TABLE_ADDRESS.CONFIGURATION}-${0}`]}
        />
      </>
    );
  },
);

export { UnitConfigurationBaseMenu };
