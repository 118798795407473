import { getAlarmRefsGroupedByUnit } from './get-alarm-refs-grouped-by-unit';

export function getAlarmRefsPaginatedGroupedByUnit(
  alarmRefs: string[],
  pageIndex: number,
  pageSize: number,
) {
  const alarmRefsPaginated = alarmRefs.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize,
  );

  return getAlarmRefsGroupedByUnit(alarmRefsPaginated);
}
