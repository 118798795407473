export enum AmPm {
  AM = 'AM',
  PM = 'PM',
}
export enum Sign {
  Plus = '+',
  Minus = '-',
}

// tweaked from https://stackoverflow.com/a/34402261/5531166
// ^                # Start of the string
//  (?:             # NCG1
//    (?:           # NCG2
//      0\d         # A zero followed by a single number between zero and nine
//      |           # OR
//      1\d         # A literal '1' followed by a single number between zero and nine
//      |           # OR
//      2[0-3]      # A literal '2' followed by a single number between zero and three
//     )            # CLOSE NCG2
//     :            # A literal colon ':'
//     [0-5]\d      # A single number from 0 to 5 followed by any digit
//   )              # CLOSE NCG1
// $                # End of the string
export const timeRegex24 = /^(?:(?:0?\d|1\d|2[0-3]):[0-5]\d)$/;
// ^                # Start of the string
//  (?:             # NCG1
//    (?:           # NCG2
//      \d          # Single number between zero and nine
//      |           # OR
//      1[0-2]      # A literal '1' followed by a single number between zero and two
//     )            # CLOSE NCG2
//     :            # A literal colon ':'
//     [0-5]\d      # A single number from 0 to 5 followed by any digit
//   )              # CLOSE NCG1
// $                # End of the string
export const timeRegex12 = /^(?:(?:\d|1[0-2]):[0-5]\d)$/;

function isEditing12Hors(newInput, caret, initialValue) {
  if (caret <= 1 || (caret === 2 && initialValue.length === 5)) {
    return true;
  }
  return caret === 2 && initialValue[0] === '1' && newInput <= 2;
}

function handleMinutesChange(newInput, newCaret, initialValue: string) {
  const [prevHours, prevMinutes] = initialValue.split(':');
  const [prevTenMinute, prevMinute] = prevMinutes.split('').map(Number);
  const len = initialValue.length;
  const minutesStart = len - 2;
  if (newCaret === len) {
    return [`${prevHours}:${prevTenMinute}${newInput}`, newCaret];
  }
  if (newCaret > len) {
    return [initialValue, newCaret];
  }
  if (newInput >= 6) {
    return [initialValue, newCaret - 1];
  }
  return [
    `${prevHours}:${newInput}${prevMinute}`,
    newCaret === minutesStart ? newCaret + 1 : newCaret,
  ];
}

function isValidHourHandling(first: number, second: number) {
  return first < 2 || (first === 2 && second < 4);
}

function isEditingHours(caret: number) {
  return caret <= 2;
}

export function handle12Edit({
  newInputValue,
  newInputCaret,
  newInput,
  prevTenHour,
  prevHour,
  prevMinutes,
  initialValue,
}: any) {
  if (isEditing12Hors(newInput, newInputCaret, initialValue)) {
    if (newInputCaret === 1) {
      if (newInput === 0) {
        newInputValue = initialValue;
        newInputCaret = 0;
      } else if (newInput === 1) {
        if (prevTenHour) {
          // 10| 11 | 12
          newInputValue = initialValue;
          newInputCaret = 1;
        } else if (prevHour < 3) {
          // 1 | 2
          newInputValue = `${newInput}${prevHour}:${prevMinutes}`;
        } else {
          newInputValue = `${newInput}:${prevMinutes}`;
        }
      } else {
        newInputValue = `${newInput}:${prevMinutes}`;
        newInputCaret += 1;
      }
    } else if (newInput >= 3) {
      newInputValue = `${newInput}:${prevMinutes}`;
    } else {
      newInputValue = `${
        prevTenHour === 0 ? prevHour : prevTenHour
      }${newInput}:${prevMinutes}`;
      newInputCaret += 1;
    }
    return [newInputValue, newInputCaret];
  }

  return handleMinutesChange(newInput, newInputCaret, initialValue);
}

export function handle24Edit({
  newInputValue,
  newInputCaret,
  newInput,
  prevTenHour,
  prevHour,
  prevMinutes,
  prevCaret,
  initialValue,
}: any) {
  if (isEditingHours(newInputCaret)) {
    const first = newInputCaret === 1 ? newInput : prevTenHour;
    const second = newInputCaret === 1 ? prevHour : newInput;
    if (isValidHourHandling(first, second)) {
      newInputValue = `${first}${second}:${prevMinutes}`;
    } else {
      newInputCaret = prevCaret;
    }
    return [newInputValue, newInputCaret];
  }
  return handleMinutesChange(newInput, newInputCaret, initialValue);
}

export function handle12Delete({
  newInputCaret,
  newInputValue,
  initialValue,
  prevCaret,
  prevHour,
  prevTenHour,
  prevMinutes,
}: any) {
  const delimeterStart = initialValue.length === 5 ? 2 : 1;
  if (delimeterStart >= newInputCaret) {
    // removing hours
    const newHours =
      initialValue.length === 5
        ? newInputCaret === 0
          ? prevHour || 12
          : prevTenHour
        : 12;
    newInputValue = `${newHours}:${prevMinutes}`;
  } else {
    // eslint-disable-next-line prefer-template, prettier/prettier
    newInputValue = initialValue.substring(0, newInputCaret) + '0' + initialValue.substring(newInputCaret + 1);
  }
  newInputCaret =
    delimeterStart === newInputCaret || delimeterStart === prevCaret
      ? prevCaret
      : newInputCaret;
  return [newInputValue, newInputCaret];
}

export function handle24Delete({
  newInputCaret,
  newInputValue,
  initialValue,
  prevCaret,
}: any) {
  // basically replace deleted value with 0 and move caret taking in account ':'
  if (newInputCaret === 2) {
    // eslint-disable-next-line prefer-template, prettier/prettier
    newInputValue = initialValue.substring(0, prevCaret) + '0' + initialValue.substring(newInputCaret)
  } else {
    // eslint-disable-next-line prefer-template, prettier/prettier
    newInputValue = initialValue.substring(0, newInputCaret) + '0' + initialValue.substring(newInputCaret + 1)
  }
  newInputCaret =
    newInputCaret === 2 || newInputCaret === 3 ? prevCaret : newInputCaret;
  return [newInputValue, newInputCaret];
}

export function get24Hour(hour: string, amPm: AmPm): string {
  const hourInt = parseInt(hour, 10);
  if (amPm === AmPm.AM) {
    return hourInt === 12 ? '00' : hour;
  }
  if (amPm === AmPm.PM) {
    return hourInt === 12 ? hour : `${hourInt + 12}`;
  }
  return hour;
}

export function from12ToSaveValue(s: string, amPm: AmPm) {
  const [hours, minutes] = s.split(':');
  let hoursInt = Number(hours);
  if (amPm === AmPm.PM) {
    hoursInt = hoursInt === 12 ? 12 : hoursInt + 12;
  }
  if (amPm === AmPm.AM) {
    hoursInt = hoursInt === 12 ? 0 : hoursInt;
  }
  return `${hoursInt.toString().padStart(2, '0')}:${minutes}`;
}

export function formatSaveValueTo12(s: string) {
  const [hours, minutes] = s.split(':').map(Number);
  if (hours < 12) {
    return `${hours === 0 ? 12 : hours}:${minutes} ${AmPm.AM}`;
  }

  return `${hours === 12 ? hours : hours - 12}:${minutes} ${AmPm.PM}`;
}

export function timeToMs(time: string) {
  const [hours, minutes] = time.split(':').map(Number);
  return (hours * 3600 + minutes * 60) * 1e3;
}
