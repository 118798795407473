import { ConfigurationListItem } from '@danfoss/etui-sm-xml';

const getValue = (name: string) => {
  return name.substring(name.lastIndexOf(' '), name.length);
};

const removeAddressFromName = (deviceName: string, deviceAddress: string) => {
  return deviceName
    .substring(0, deviceName.length - deviceAddress.length)
    .trim();
};

const removeModelFromName = (
  deviceName: string,
  nameWithoutAddress: string,
  deviceModel: string,
) => {
  return deviceName
    .substring(0, nameWithoutAddress.length - deviceModel.length)
    .trim();
};

export const processData = (data: ConfigurationListItem) => {
  const address = getValue(data.name).trim();
  let updatedDataName = removeAddressFromName(data.name, address);
  const model = getValue(updatedDataName);
  updatedDataName = removeModelFromName(data.name, updatedDataName, model);
  const name = updatedDataName;
  return {
    name,
    model,
    address,
  };
};
