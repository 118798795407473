const PRIVATE_NETWORKS = [{
  min: '10.0.0.0',
  max: '10.255.255.255'
}, {
  min: '172.16.0.0',
  max: '172.31.255.255'
}, {
  min: '192.168.0.0',
  max: '192.168.255.255'
}];
const splitIp = ip => ip.split('.').map(str => +str);
const isIpInRange = (ip, _ref) => {
  let {
    min,
    max
  } = _ref;
  let isInRange = true;
  const splittedIp = splitIp(ip);
  const splittedMinIp = splitIp(min);
  const splittedMaxIp = splitIp(max);
  splittedIp.forEach((item, index) => {
    const num = item;
    const minEdge = splittedMinIp[index];
    const maxEdge = splittedMaxIp[index];
    if (minEdge > num || num > maxEdge) {
      isInRange = false;
    }
  });
  return isInRange;
};
const checkNetwork = ip => {
  if (!ipAddressAndPortRegExp.test(ip)) return false;
  return PRIVATE_NETWORKS.some(network => isIpInRange(ip, network));
};
const ipAddressAndPortRegExp = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}(:(\d{1,5}))?$/;

export { checkNetwork, ipAddressAndPortRegExp };
