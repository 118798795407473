import { v4 } from 'uuid';
import '../Conditions/utils/execute-operation.js';
import { Condition } from '../Conditions/Condition.js';

class Mapping {
  constructor(shapeBy) {
    this.id = v4();
    this.shapeBy = shapeBy;
    this.hidden = false;
  }
  isHidden() {
    return this.hidden;
  }
  setHidden(hide) {
    this.hidden = hide;
    return this;
  }
  setShapeIdentifier(shapeIdentifier) {
    this.shapeIdentifier = shapeIdentifier;
    return this;
  }
  setAction(mapAction) {
    this.action = mapAction.value;
    return this;
  }
  setState(state) {
    this.state = state;
    return this;
  }
  setValue(value) {
    this.value = value;
    return this;
  }
  import(rawObj) {
    if (rawObj.id) {
      this.id = rawObj.id;
    }
    if (rawObj.hidden) {
      this.hidden = rawObj.hidden;
    }
    if (rawObj.shapeBy) {
      this.shapeBy = rawObj.shapeBy;
    }
    if (rawObj.shapeIdentifier) {
      this.shapeIdentifier = rawObj.shapeIdentifier;
    }
    if (rawObj.state) {
      const cond = new Condition();
      cond.import(rawObj.state);
      this.state = cond;
    }
    if (rawObj.action) {
      this.action = rawObj.action;
    }
    if (rawObj.value) {
      this.value = rawObj.value;
    }
    return this;
  }
}

export { Mapping };
