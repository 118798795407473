import { ChartPoint, ChartData } from '../HistoryPage.types';
import { binarySearch } from './binary-search';

function extractData(
  data: ChartData['data'],
  start: Date,
  end: Date,
  compareCb: (v: number, p: ChartPoint) => number,
) {
  const intervalStart = binarySearch(data, +start, compareCb);
  const intervalEnd = binarySearch(data, +end, compareCb);

  return data.slice(intervalStart, intervalEnd + 1);
}

export function filterByDateRange(
  chartData: ChartData[],
  start: Date,
  end: Date,
): ChartData[] {
  return chartData.map(({ param, data }) => ({
    param,
    data: extractData(
      data,
      start,
      end,
      (value: number, { time }) => value - time,
    ),
  }));
}
