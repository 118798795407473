const operationalColors = {
  noData: '#ffffff',
  noDataBorder: '#E5E8EB',
  defrost: '#F9F73E',
  defrostBorder: '#F1C40F',
  textColorDark: '#303844'
};
const colorSettings = {
  operationalColors: {
    defrost: {
      name: 'Defrost',
      bgColor: operationalColors.defrost,
      color: operationalColors.textColorDark,
      border: `1px solid ${operationalColors.defrostBorder}`,
      content: ''
    },
    NoData: {
      name: 'No data',
      bgColor: '',
      color: operationalColors.textColorDark,
      border: `1px solid ${operationalColors.noDataBorder}`,
      content: '-'
    }
  }
};
const getOperationalColorsColumns = () => Object.values(colorSettings.operationalColors);
function getSetpointValue(value) {
  let setPoint = value;
  if (Array.isArray(setPoint)) {
    setPoint = setPoint.reduce((acc, current) => acc + parseFloat(current), 0) / setPoint.length;
  }
  if (isNaN(setPoint)) {
    return null;
  }
  return parseFloat(setPoint);
}

export { colorSettings, getOperationalColorsColumns, getSetpointValue, operationalColors };
