function findCurrentMenuItemWithLevel(
  menuLevelById: {},
  currentPath: string,
  level: number,
) {
  return (Object.entries(menuLevelById).find(
    ([id, l]) => l === level && new RegExp(`/${id}`).test(currentPath),
  ) || [])[0];
}

export { findCurrentMenuItemWithLevel };
