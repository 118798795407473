import * as React from 'react';
import _get from 'lodash.get';
import { useTheme } from '@danfoss/etui-system';
import { Div, Span } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { Icon, IconGlyph, icons, Tooltip } from '@danfoss/etui-core';
import {
  checkForAccessRights,
  useAuth,
  useUnit,
  useUpdateVersions,
} from '@danfoss/etui-sm';
import { Link } from 'react-router-dom';
import { routePaths } from 'routePaths';
import { UnitNetwork } from '@danfoss/etui-sm-xml';
import { DataItem } from '../DataItem';
import { Seperator } from '../Seperator';
import { getUnitDetails } from '../../utils';

function getArray<T>(data: T | T[]): T[] {
  if (!data) {
    return [];
  }
  return Array.isArray(data) ? (data as T[]) : [data];
}

interface IndiviualUnitInfoProps {
  currentUnit: any;
  network: UnitNetwork;
  updateType: any;
  displayCurrentVersion?: boolean;
  onInternet?: any;
  overrideCurrentValue?: any;
  showUpdateNotifications: boolean;
  showUpdateNotificationText: boolean;
  isPublicIpInInternalUnit: boolean;
  isPrivateIpInExternalUnit: boolean;
  macAddrSameForMasterSlaveUnits: boolean;
}
export function IndiviualUnitInfo({
  currentUnit,
  network,
  updateType,
  displayCurrentVersion = true,
  onInternet = null,
  overrideCurrentValue = null,
  showUpdateNotifications = false,
  showUpdateNotificationText = false,
  isPublicIpInInternalUnit = false,
  isPrivateIpInExternalUnit = false,
  macAddrSameForMasterSlaveUnits = false,
}: IndiviualUnitInfoProps) {
  const { getFirstValidUrl } = useUnit();
  const url = getFirstValidUrl(currentUnit);

  const { user } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    latestVersion,
    latestFetchError,
    isFetchingCurrent,
    currentVersions,
    latestComparison,
  } = useUpdateVersions(updateType, url, user);

  onInternet && onInternet(!latestFetchError && latestVersion);

  const isSystemUser = checkForAccessRights(user?.authtype, 0);
  const isSpk = updateType === 'SPK';
  const isLpk = updateType === 'LPK';

  const updateTypePretty = isSpk ? 'SW' : isLpk ? 'Danux' : '';

  let currentLabel = t('t3077', { updateType: updateTypePretty });
  currentLabel =
    latestComparison === 'larger' && showUpdateNotificationText
      ? `${currentLabel} (${t('t3072')})`
      : currentLabel;
  const currentVersion = isSpk
    ? currentVersions?.softwareVersion
    : isLpk
    ? currentVersions.danuxVersion
    : null;

  const icon: { icon: IconGlyph; color: string } =
    latestComparison === 'larger' || !isSystemUser
      ? { icon: icons.WARNING, color: theme.palette.warning.main }
      : latestComparison === 'equal'
      ? { icon: icons.CHECK_CIRCLE, color: theme.palette.success.main }
      : latestComparison === 'lower'
      ? { icon: icons.CHECK_CIRCLE, color: theme.palette.success.main }
      : null;

  const InfoMap = [
    { label: 't889', path: 'unit_name' },
    { label: 't889', path: 'name' },
    { label: 't355', path: 'sys_model_name' },
    { label: 't883', path: 'sys_model_name' },
    { label: 't887', path: 'macAddr' },
  ];
  const getVersionContent = () => (
    <>
      <Seperator />
      {displayCurrentVersion && (
        <DataItem
          isLoading={isSystemUser && isFetchingCurrent}
          label={currentLabel}
          value={
            isSystemUser
              ? overrideCurrentValue ||
                currentVersion ||
                currentUnit?.software ||
                t('t520')
              : t('t520')
          }
        />
      )}
      <Seperator />
      <DataItem label={t('t886')} value={currentVersions?.danuxVersion} />
      {InfoMap.map(({ path, label }) => {
        const info = _get(
          { ...currentUnit, unit: getArray(currentUnit?.unit) },
          path,
        );
        return info ? (
          <Div key={`${label}_${path}`}>
            <Seperator />
            {path && path === 'macAddr' ? (
              <DataItem
                label={t(label)}
                value={info}
                warning={macAddrSameForMasterSlaveUnits ? t('t3311') : ''}
              />
            ) : (
              <DataItem label={t(label)} value={info} />
            )}
          </Div>
        ) : null;
      })}
      <Seperator />
      <DataItem
        label={t('t3306')}
        value={getUnitDetails(getArray(currentUnit?.unit), network).ip}
        warning={isPublicIpInInternalUnit ? t('t3295') : ''}
      />
      <Seperator />
      <DataItem
        label={t('t3323')}
        value={getUnitDetails(getArray(currentUnit?.unit), network).port}
      />
      <Seperator />
      <DataItem
        label={t('t3307')}
        value={getUnitDetails(getArray(currentUnit?.unit_internet), network).ip}
        warning={isPrivateIpInExternalUnit ? t('t3296') : ''}
      />
      <Seperator />
      <DataItem
        label={t('t3322')}
        value={
          getUnitDetails(getArray(currentUnit?.unit_internet), network).port
        }
      />
      <Seperator />
      {latestComparison === 'larger' ? (
        <Div display="flex">
          <Span width="50%" textAlign="left" color={theme.palette.primary.main}>
            {t('t3114')}
          </Span>
          <Link
            style={{
              textDecoration: 'underline',
              color: 'inherit',
              whiteSpace: 'nowrap',
              fontStyle: 'bold',
            }}
            title={t('t3115')}
            to={routePaths.pages.system.softwareManagement}
          >
            {t('t3115')}
          </Link>
        </Div>
      ) : null}
    </>
  );

  const SoftwareVersionUpdateIcon = () => {
    return (
      <Icon
        size={24}
        styles={{
          root: {
            color: icon?.color,
            mr: `${theme.spacing.xs}px`,
            mt: `${theme.spacing.md}px`,
          },
        }}
        glyph={icon?.icon}
      />
    );
  };
  return (
    <Div testId="individual-unit-info">
      {showUpdateNotifications && icon ? (
        <Div
          testId="individual-unit-info-content"
          display="flex"
          flexDirection="row"
        >
          <Div testId="individual-unit-info-tooltip">
            {latestComparison === 'larger' || !isSystemUser ? (
              <Tooltip
                placement="bottom"
                message={
                  !isSystemUser ? t('t3483') : t('t3321', { latestVersion })
                }
                warning={true}
                warningWidth="200%"
              >
                <SoftwareVersionUpdateIcon />
              </Tooltip>
            ) : (
              <SoftwareVersionUpdateIcon />
            )}
          </Div>

          <Div testId="individual-unit-info-version-content" flex="1">
            {getVersionContent()}
          </Div>
        </Div>
      ) : (
        getVersionContent()
      )}
    </Div>
  );
}
