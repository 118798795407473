import * as React from 'react';
import { Protocol } from '@danfoss/etui-sm';
import { getCookie, setCookie } from 'utils';
import { isElectron } from '../../../config';

export const COOKIE_PROTOCOL = 'nextProtocol';
const COOKIE_PROTOCOL_MAX_AGE_SECS = 10;

export const useLoginCookies = (onIssueCb: () => void) => {
  const setProtocolToCookies = (newProtocol: Protocol) =>
    setCookie(COOKIE_PROTOCOL, newProtocol, {
      'max-age': COOKIE_PROTOCOL_MAX_AGE_SECS,
    });

  React.useEffect(() => {
    const currentProtocol = window.location.protocol;
    const protocolFromCookies = getCookie(COOKIE_PROTOCOL);

    if (
      !isElectron &&
      protocolFromCookies &&
      protocolFromCookies !== currentProtocol
    ) {
      setTimeout(onIssueCb, 0);
    }
  }, []);

  return setProtocolToCookies;
};
