import '../../constants/session-storage-constants.js';
import { XML_ACTION } from '../../constants/xml-action-constant.js';
import { XML_ERROR_CODE } from '../../constants/xml-error-code.js';
import { XML_TAG } from '../../constants/xml-tag-constant.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import { _ as _objectSpread2 } from '../../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../../fetch-xml/fetch-xml-data.js';

/**
 * Fetch alarms details by refs
 *
 * @export
 * @param {string} url
 * @param {string[]} alarmsRefs
 * @param {User} user
 * @returns {Promise<Alarm[]>}
 */
async function fetchAlarmsByRefs(url, alarmsRefs, user, skipSessionUpdate) {
  const data = await fetchXMLData({
    url,
    attributes: _objectSpread2({
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.ALARM_DETAIL_GROUP,
      storeview_only: '1',
      version: 'C'
    }, skipSessionUpdate ? {
      session_update: 'no'
    } : {}),
    cfg: {
      allowedErrorCodes: [XML_ERROR_CODE.ALARM_REF]
    },
    items: alarmsRefs.map(ref => {
      return {
        tag: XML_TAG.ALARM,
        attributes: {
          expanded: '2',
          current: ref
        }
      };
    })
  });
  if (data && data.alm) {
    return Array.isArray(data.alm) ? data.alm : [data.alm];
  }
  return [];
}

export { fetchAlarmsByRefs };
