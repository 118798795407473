import { AmPm } from '../../TimeInput/utils';
import { HourFormat } from '../../TimeInput/TimeInput';

export const buildDate = (date: Date): string =>
  [
    `${date.getDate()}`.padStart(2, '0'),
    `${date.getMonth() + 1}`.padStart(2, '0'),
    `${date.getFullYear()}`,
  ].join('/');

export const buildTime = (time: string, hourformat: HourFormat): string => {
  let hours = parseInt(time, 10);
  if (hourformat === '12')
    hours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

  return `${hours}`.padStart(2, '0') + time.slice(-3);
};

export function buildDateTimeToDisplay(date, time, hourformat: HourFormat) {
  const dateValue = buildDate(date);
  const timeValue = buildTime(time, hourformat);
  let period = '';
  if (hourformat === '12') period = parseInt(time, 10) > 11 ? AmPm.PM : AmPm.AM;

  return period
    ? `${dateValue} ${timeValue} ${period}`
    : `${dateValue} ${timeValue}`;
}

export const buildRelativeTime = (time: string): string => {
  const sign = /\d/.test(time[0]) ? '+' : time[0];
  const hours = time.match(/\d+/)[0].padStart(2, '0');
  const minutes = time.split(':')[1];
  return `${sign}${hours}:${minutes}`;
};
