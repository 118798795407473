import { XML_ACTION, fetchXMLData } from '@danfoss/etui-sm-xml';
import {
  SECURITY_TABLE_ADDRESS,
  SECURITY_TABLE_ADDRESS_INDEX,
} from '../SecurityCompliancePage.types';

export const getSecurityConfigList = async (url: string) => {
  const response = await fetchXMLData({
    url,
    attributes: {
      action: XML_ACTION.READ_LIST,
      tableaddress: SECURITY_TABLE_ADDRESS,
      index: SECURITY_TABLE_ADDRESS_INDEX,
      storeview_only: '1',
      version: 'C',
    },
  });

  // @ts-ignore
  return response.items.l;
};
