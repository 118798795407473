import useSWR from 'swr';
import { useAuth } from '@danfoss/etui-sm/context/auth';
import { useXmlResource } from '@danfoss/etui-sm/context/app';
import {
  Device,
  Unit,
  XML_DEVICE_COMBO,
  XML_DEVICE_STYPE,
} from '@danfoss/etui-sm-xml';
import { fetchDevicesByComboAndSType } from '../components';

interface FetchDeviceParams {
  unit: Unit;
  combo: XML_DEVICE_COMBO;
  stype: XML_DEVICE_STYPE;
  shouldMakeRequest?: boolean;
}

const useFetchDevices = ({ unit, combo, stype }: FetchDeviceParams) => {
  const { user } = useAuth();
  const { url } = useXmlResource();
  const { data, error, isValidating } = useSWR<Device[]>(
    () => (url && user ? [url, unit, combo, stype, user] : null),
    fetchDevicesByComboAndSType,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      revalidateOnMount: true,
    },
  );

  const isLoading = !data && !error && isValidating;

  return { isLoading, data, error };
};

export { useFetchDevices };
