import React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Button, H4, H6, useResponsive } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { P } from '@danfoss/etui-system-elements';
import * as S from './styles';

const IE_END_OF_LIFE_LINK =
  'https://blogs.windows.com/windowsexperience/2022/06/15/internet-explorer-11-has-retired-and-is-officially-out-of-support-what-you-need-to-know/';

export function BrowserSupportInfo() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });
  const isMdView = screenIsAtMost('md');
  const openLinkDescription = () => window.open(IE_END_OF_LIFE_LINK, '_blank');

  return (
    <S.ContentWrapper isMdView={isMdView}>
      {isMdView ? (
        <>
          <H6 mb={`${theme.spacing.md}px`}>{t('t3288')}</H6>
          <P mb={`${theme.spacing.md}px !important`}>{t('t3289')}</P>
        </>
      ) : (
        <>
          <H4 mb={theme.spacing.lg}>{t('t3288')}</H4>
          <H6 fontWeight={400} mb={theme.spacing.lg}>
            {t('t3289')}
          </H6>
        </>
      )}
      <Button
        small={isMdView}
        onClick={openLinkDescription}
        variant="primary"
        cursor="pointer"
      >
        {t('t3290')}
      </Button>
    </S.ContentWrapper>
  );
}
