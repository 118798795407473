import { XML_DEVICE_NODETYPE } from '@danfoss/etui-sm-xml';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-e1ae8e90.js';
import { DeviceGroupedType } from '../types/DeviceGrouped.js';
import { composeDeviceId } from './compose-device-id.js';
import { getIsOnOffOutputDevice } from './get-is-on-off-output-device.js';
import { getIsRelayOutputDevice } from './get-is-relay-output-device.js';
import { getIsSensorInputDevice } from './get-is-sensor-input-device.js';
import { getIsVariableOutputDevice } from './get-is-variable-output-device.js';

function getGroupedMiscDevices(deviceType, devices) {
  const miscGroups = [];
  const oi = {
    id: `${deviceType}_oi`,
    groupType: DeviceGroupedType.GROUP_ONLY,
    name: 'On/Off Input',
    nodetype: XML_DEVICE_NODETYPE.NODETYPE_OI,
    deviceType,
    items: []
  };
  const ro = {
    id: `${deviceType}_ro`,
    groupType: DeviceGroupedType.GROUP_ONLY,
    name: 'Relay Output',
    nodetype: XML_DEVICE_NODETYPE.NODETYPE_RO,
    deviceType,
    items: []
  };
  const si = {
    id: `${deviceType}_si`,
    groupType: DeviceGroupedType.GROUP_ONLY,
    name: 'Sensor Input',
    nodetype: XML_DEVICE_NODETYPE.NODETYPE_SI,
    deviceType,
    items: []
  };
  const vo = {
    id: `${deviceType}_vo`,
    groupType: DeviceGroupedType.GROUP_ONLY,
    name: 'Variable Output',
    nodetype: XML_DEVICE_NODETYPE.NODETYPE_VO,
    deviceType,
    items: []
  };
  const others = {
    id: `${deviceType}_others`,
    groupType: DeviceGroupedType.GROUP_ONLY,
    name: 'Others',
    nodetype: XML_DEVICE_NODETYPE.NODETYPE_NA,
    deviceType,
    items: []
  };
  const allDevices = devices === null || devices === void 0 ? void 0 : devices.map(device => {
    return _objectSpread2(_objectSpread2({}, device), {}, {
      groupType: DeviceGroupedType.DEVICE_ONLY,
      id: composeDeviceId(device),
      deviceType,
      items: []
    });
  });
  allDevices.forEach(device => {
    const isOI = getIsOnOffOutputDevice(device);
    const isRO = getIsRelayOutputDevice(device);
    const isSI = getIsSensorInputDevice(device);
    const isVO = getIsVariableOutputDevice(device);
    const isOther = !isOI && !isRO && !isSI && !isVO;
    if (isOI) {
      oi.items.push(device);
    }
    if (isRO) {
      ro.items.push(device);
    }
    if (isSI) {
      si.items.push(device);
    }
    if (isVO) {
      vo.items.push(device);
    }
    if (isOther) {
      others.items.push(device);
    }
  });
  if (oi.items.length !== 0) {
    miscGroups.push(oi);
  }
  if (ro.items.length !== 0) {
    miscGroups.push(ro);
  }
  if (si.items.length !== 0) {
    miscGroups.push(si);
  }
  if (vo.items.length !== 0) {
    miscGroups.push(vo);
  }
  if (others.items.length !== 0) {
    miscGroups.push(others);
  }
  return miscGroups;
}

export { getGroupedMiscDevices };
