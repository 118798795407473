const STORAGE_USER_AUTHLEVEL_KEY = 'AuthLevel';
const STORAGE_USER_USERNAME_KEY = 'User';
const STORAGE_USER_PASSWORD_KEY = 'Password';
const STORAGE_USER_LANGUAGE_KEY = 'Language';
const STORAGE_PROXY_KEY = 'smProxy';
const STORAGE_XML_BACKEND_IP_KEY = 'xmlBackendIp';
const STORAGE_XML_BACKEND_PROTOCOL_KEY = 'xmlBackendProtocol';

// storage key for svb
const STORAGE_UNITXMLURL_KEY = 'unitXMLURL';

// Api Gateway
const STORAGE_URL = 'URL';
const STORAGE_API_KEY = 'API_KEY';

export { STORAGE_API_KEY, STORAGE_PROXY_KEY, STORAGE_UNITXMLURL_KEY, STORAGE_URL, STORAGE_USER_AUTHLEVEL_KEY, STORAGE_USER_LANGUAGE_KEY, STORAGE_USER_PASSWORD_KEY, STORAGE_USER_USERNAME_KEY, STORAGE_XML_BACKEND_IP_KEY, STORAGE_XML_BACKEND_PROTOCOL_KEY };
