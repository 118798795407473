import { createGlobalStyle } from 'styled-components';
import { defaultTheme } from '@danfoss/etui-themes';
import 'sanitize.css';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-react-schedule/styles/material.css';
import { danfoss } from '@danfoss/etui-colors';

export const chartBorderColor = defaultTheme.palette.grey[40];

export const GlobalStyle = createGlobalStyle`
  html {
    display: flex;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  body {
    display: flex;
    flex: auto;
    align-self: stretch;
    max-width: 100%;
    max-height: 100%;
    font-family: system-ui,
    /* macOS 10.11-10.12 */ -apple-system,
    /* Windows 6+ */ Segoe UI,
    /* Android 4+ */ Roboto,
    /* Ubuntu 10.10+ */ Ubuntu,
    /* Gnome 3+ */ Cantarell,
    /* KDE Plasma 5+ */ Noto Sans,
    /* fallback */ sans-serif,
    /* macOS emoji */ "Apple Color Emoji",
    /* Windows emoji */ "Segoe UI Emoji",
    /* Windows emoji */ "Segoe UI Symbol",
    /* Linux emoji */ "Noto Color Emoji";
    font-size: 14px;
    line-height: 1.5;
    color: #303844;
    background-color: #fafcfc;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    min-width: 320px;
  }

  .navbar {
    display: 'none';
  }

  .schedule-component {
    overflow: auto;
    max-height: 400px;
    min-height: 100px;
  }

  .e-schedule .e-timeline-view .e-resource-text, .e-schedule .e-timeline-month-view
  .e-resource-text .e-quick-popup-wrapper .e-cell-popup .e-date-time-details, .e-quick-popup-wrapper
  .e-cell-popup .e-location-details, .e-quick-popup-wrapper .e-cell-popup .e-time-zone-details,
  .e-quick-popup-wrapper .e-cell-popup .e-description-details, .e-quick-popup-wrapper
  .e-cell-popup .e-resource-details, .e-quick-popup-wrapper .e-event-popup
  .e-date-time-details, .e-quick-popup-wrapper .e-event-popup .e-location-details,
  .e-quick-popup-wrapper .e-event-popup .e-time-zone-details, .e-quick-popup-wrapper
  .e-event-popup .e-description-details, .e-quick-popup-wrapper .e-event-popup .e-resource-details,
  .e-schedule .e-event-popup .e-popup-content .e-popup-content__item {
    color: ${({ theme }) => theme.palette.text.primary};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeBase};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  }

  .e-schedule .e-timeline-view .e-appointment, .e-schedule
  .e-timeline-month-view .e-appointment {
    height: 26px !important;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-color: ${chartBorderColor};
  }

  .e-schedule .e-timeline-view .e-resource-cells, .e-schedule
  .e-timeline-month-view .e-resource-cells {
    height: 36px !important;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-color: ${chartBorderColor};
  }

  .e-schedule .e-timeline-view .e-work-cells, .e-schedule
  .e-timeline-month-view .e-work-cells {
    height: 36px !important;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-color: ${chartBorderColor};
  }

  .e-schedule .e-timeline-view .e-date-header-wrap table col, 
  .e-schedule .e-timeline-view .e-content-wrap table col { 
    width: 10px !important; 
  } 

  .e-schedule .e-timeline-view .e-date-header-wrap table td.e-current-day,
  .e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day {
    display: none !important;
  }

  .e-schedule .e-event-popup .e-popup-content .e-popup-content__item, 
  .e-schedule .e-event-popup .e-popup-header .e-header-icon-wrapper {
    display: flex;
  }

  .e-schedule .e-event-popup .e-popup-header {
    background-color: ${danfoss.grey['40']};
  }

  .e-schedule .e-event-popup .e-popup-header .e-header-icon-wrapper .e-subject,
  .e-schedule .e-event-popup .e-popup-header .e-header-icon-wrapper .e-icon-btn {
    color: ${({ theme }) => theme.palette.text.primary} !important;
  }

  .e-schedule .e-event-popup .e-popup-header .e-header-icon-wrapper {
    justify-content: space-between;
    padding: 16px 32px;
  }

  .e-quick-popup-wrapper .e-event-popup .e-popup-content {
    padding: 8px 32px 32px 32px;
  }

  .e-schedule .e-event-popup .e-popup-content .e-popup-content__item div {
    width: 50%;
  }
`;

export const schedulePalette = {
  violet: '#8168c4',
};
