const ALARMS_REFS_ARRAY_SIZE = 20;
const splitArrayIntoSizedArrayWithArrays = function (arr, group) {
  if (group === void 0) {
    group = ALARMS_REFS_ARRAY_SIZE;
  }
  let res = [];
  for (let i = 0; i < arr.length; i += group) {
    res = [...res, arr.slice(i, i + group)];
  }
  return res;
};
function getAlarmSummaryRefsByKey(data, key) {
  return data && data[key] && data[key].ref ? Array.isArray(data[key].ref) ? data[key].ref : [data[key].ref] : [];
}
function getAlarmSummaryDeviceRefsByKey(data, key) {
  return data && data[key] && data[key].ref ? Array.isArray(data[key].ref) ? data[key].ref.map(r => r._) : [data[key].ref._] : [];
}

export { ALARMS_REFS_ARRAY_SIZE, getAlarmSummaryDeviceRefsByKey, getAlarmSummaryRefsByKey, splitArrayIntoSizedArrayWithArrays };
