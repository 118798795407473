import { CALENDAR_VIEW_FORMATS } from '@danfoss/etui-sm-xml';
import { getArray } from 'pages/ConfigurationPage';
import {
  AkioDefrostSchedules,
  AkioSheduleTypes,
  AkioShutdownSchedules,
  Holidays,
  HvacSchedule,
  OverrideSchedules,
  Refrigerations,
  RefrigerationSchedules,
  ScheduleResponses,
  ScheduleTypeConstant,
  ScheduleTypes,
  TimeConstant,
  Weekdays,
} from '../SchedulesPage.types';
import { getOverrideScheduleOptions } from './getOverridedScheduleOptions';
import { checkIfSchedulesConfigured } from './checkSchedulesConfigured';
import { filterRefgirerationSchedules } from './filterRefrigerationSchedules';
import { filterAkioShutdownSchedules } from './filterAkioShutdownSchedules';
import { addTime, convertTimeFormats } from './convertTimeFormat';
import { filterAkioDefrostSchedules } from './filterAkioDefrostSchedules';
import { getWeekdaysAndHolidaysFromByte } from './getWeekdaysAndHolidaysFromByte';
import { findSceduleDevices } from './findSceduleDevices';
import { areObjectsEqual, getStoreSchedulesData } from '.';

export const NO_INFO = `-`;
export const AKIO_PREFIX = `akioDefrost`;

export const updateHolidaysWeekDays = (
  schedule: RefrigerationSchedules,
  previousSchedule: RefrigerationSchedules,
): RefrigerationSchedules => {
  if (
    schedule.weekdays &&
    schedule.holidays &&
    schedule.id === previousSchedule.id
  ) {
    schedule.weekdays = previousSchedule.weekdays;

    schedule.holidays = previousSchedule.holidays;

    return schedule;
  }
};
export const getOverridedScheduleData = (
  schedulesData: ScheduleResponses | HvacSchedule[],
  type: string,
  timeFormat: string,
  refrigerations: Refrigerations[],
): OverrideSchedules => {
  const schema = getOverrideScheduleOptions(type);

  const access = schema?.fieldSchema;

  if (!access) return [];

  let shedulesDataObj = schedulesData;

  for (const item of access) {
    if (!shedulesDataObj?.[item]) break;
    shedulesDataObj = shedulesDataObj[item];
  }

  const availableSchedules = shedulesDataObj || [];

  const data = Array.isArray(availableSchedules)
    ? availableSchedules
    : [availableSchedules];

  let schedules: RefrigerationSchedules[] = [];
  let isParentItem: boolean;
  let schedulesType: string;

  if (
    ['pack', 'suction', 'hvac'].some(deviceType => type.includes(deviceType))
  ) {
    schedulesType = type.slice(0, type.indexOf('-'));
    isParentItem = false;
  } else {
    schedulesType = type;
    isParentItem = true;
  }

  const schedulesIds: string = isParentItem
    ? null
    : type?.slice(type.indexOf('-') + 1, type.length);
  switch (schedulesType) {
    case ScheduleTypes.STORE_HOURS:
      return getStoreSchedulesData(data);

    case ScheduleTypes.DEFROST:
      schedules = filterRefgirerationSchedules(
        data,
        schedulesIds,
        refrigerations,
        ScheduleTypes.DEFROST,
        [
          ScheduleTypeConstant.SCHED_DEFROST,
          ScheduleTypeConstant.SCHED_COORD_DEFROST,
          ScheduleTypeConstant.SCHED_ADAPTIVE_DEFROST,
        ],
      );
      updateDefrostScheduleObj(schedules, timeFormat);
      filterAndPushAkioDefrostScheduleObj(
        refrigerations,
        schedulesType,
        schedulesIds,
        timeFormat,
        schedules,
      );
      return schedules;
    case ScheduleTypes.NIGHT_SETBACK:
      return filterRefgirerationSchedules(
        data,
        schedulesIds,
        refrigerations,
        ScheduleTypes.NIGHT_SETBACK,
        [ScheduleTypeConstant.SCHED_NIGHTSB],
      );
    case ScheduleTypes.SHUTDOWN:
      schedules = filterRefgirerationSchedules(
        data,
        schedulesIds,
        refrigerations,
        ScheduleTypes.SHUTDOWN,
        [ScheduleTypeConstant.SCHED_SHUTDOWN],
      );
      filterAndPushAkioShutdownScheduleObj(
        refrigerations,
        schedulesType,
        schedulesIds,
        timeFormat,
        schedules,
      );
      return schedules;
    case ScheduleTypes.CASE_LIGHTS:
      return filterRefgirerationSchedules(
        data,
        schedulesIds,
        refrigerations,
        ScheduleTypes.CASE_LIGHTING,
        [ScheduleTypeConstant.SCHED_CASE_LIGHTS],
      );
    case ScheduleTypes.HVAC:
      return updateSchedulesWithHvac(data as HvacSchedule[], schedules);
    default:
  }
  const ids = type.split('-')[2];

  const schdeluesData = schema.dataFilteringOptions
    ? schema.dataFilteringOptions(data, ids, refrigerations, schedulesType)
    : checkIfSchedulesConfigured(data);

  return schdeluesData.map((item, index) => {
    return {
      ...item,
      key: `${item.value}-${index}`,
    };
  });
};

const updateSchedulesWithHvac = (
  hvacSchedules: HvacSchedule[],
  schedules: RefrigerationSchedules[],
): RefrigerationSchedules[] => {
  hvacSchedules.forEach(hvacSchedule => {
    const hvacScheduleObj: RefrigerationSchedules = {
      index: hvacSchedule.index,
      name: hvacSchedule.name,
      type: hvacSchedule.type,
      usage: hvacSchedule.usage,
      on_time: hvacSchedule.start,
      off_time: hvacSchedule.stop,
      weekdays: hvacSchedule.days,
      holidays: hvacSchedule.holidays,
    };
    schedules.push(hvacScheduleObj);
  });
  return schedules;
};

const filterAndPushAkioDefrostScheduleObj = (
  refrigerations: Refrigerations[],
  schedulesType: ScheduleTypes,
  schedulesIds: string,
  timeFormat: string,
  schedules: RefrigerationSchedules[],
): void => {
  const filteredAkioDefrostSchedule: AkioDefrostSchedules[] =
    filterAkioDefrostSchedules(refrigerations, schedulesType, schedulesIds);

  if (filteredAkioDefrostSchedule?.length) {
    const cases = findSceduleDevices(
      refrigerations,
      schedulesType,
      schedulesIds,
    );

    filteredAkioDefrostSchedule.forEach((sched, schedIndex) => {
      const startTimes: string[] = getArray(sched.start);
      startTimes.forEach(startTime => {
        const akioDefrostSchedule: RefrigerationSchedules =
          getAkioDefrostScheduleObj(
            sched,
            schedulesType,
            timeFormat,
            startTime,
            `${AKIO_PREFIX}${schedIndex}`,
          );
        akioDefrostSchedule.cases = cases.filter(caseItem =>
          areObjectsEqual(caseItem.schedules, sched),
        );
        schedules.push(akioDefrostSchedule);
      });
    });
  }
};

const getAkioDefrostScheduleObj = (
  akioDefrostSchedules: AkioDefrostSchedules,
  schedulesType: ScheduleTypes,
  timeFormat: string,
  startTime: string,
  index: string,
): RefrigerationSchedules => {
  startTime = startTime.padStart(4, '0');
  const akioDefrostSchedule: RefrigerationSchedules = {
    id: NO_INFO,
    index,
    name: schedulesType,
    type: AkioSheduleTypes[+akioDefrostSchedules.defrost_type] || NO_INFO,
    usage: schedulesType,
    on_time: convertTimeFormats(
      CALENDAR_VIEW_FORMATS.FORMAT_24,
      timeFormat,
      startTime,
    ),
    off_time: akioDefrostSchedules.interval
      ? addTime(startTime, timeFormat, +akioDefrostSchedules.interval)
      : NO_INFO,
    weekdays: getWeekdaysAndHolidaysFromByte(
      +akioDefrostSchedules.defr_days,
      Weekdays,
    ),
    holidays: getWeekdaysAndHolidaysFromByte(
      +akioDefrostSchedules.defr_hdays,
      Holidays,
    ),
  };
  return akioDefrostSchedule;
};

const filterAndPushAkioShutdownScheduleObj = (
  refrigerations: Refrigerations[],
  schedulesType: ScheduleTypes,
  schedulesIds: string,
  timeFormat: string,
  schedules: RefrigerationSchedules[],
): void => {
  return filterAkioShutdownSchedules(
    refrigerations,
    schedulesType,
    schedulesIds,
  )?.forEach((akioShutdownSchedule, index) => {
    const akioShutdownScheduleObj: RefrigerationSchedules =
      getAkioShutdownScheduleObj(
        schedulesType,
        akioShutdownSchedule,
        timeFormat,
        index,
      );
    schedules.push(akioShutdownScheduleObj);
  });
};

const getAkioShutdownScheduleObj = (
  schedulesType: string,
  akioShutdownSchedule: AkioShutdownSchedules,
  timeFormat: string,
  index: number,
): RefrigerationSchedules => {
  const startTime = akioShutdownSchedule.start.padStart(4, '0');
  const stopTime = akioShutdownSchedule.stop.padStart(4, '0');

  const akioShutdownScheduleObj: RefrigerationSchedules = {
    id: NO_INFO,
    index: `${index}`,
    name: schedulesType,
    type: AkioSheduleTypes[+akioShutdownSchedule.defrost_type],
    usage: schedulesType,
    on_time: convertTimeFormats(
      CALENDAR_VIEW_FORMATS.FORMAT_24,
      timeFormat,
      startTime,
    ),
    off_time: convertTimeFormats(
      CALENDAR_VIEW_FORMATS.FORMAT_24,
      timeFormat,
      stopTime,
    ),
    weekdays: getWeekdaysAndHolidaysFromByte(
      +akioShutdownSchedule.days,
      Weekdays,
    ),
    holidays: getWeekdaysAndHolidaysFromByte(
      +akioShutdownSchedule.hdays,
      Holidays,
    ),
  };
  return akioShutdownScheduleObj;
};
const updateDefrostScheduleObj = (
  schedules: RefrigerationSchedules[],
  timeFormat: string,
): void => {
  if (schedules?.length) {
    schedules.forEach((schedule: RefrigerationSchedules, index: number) => {
      if (schedule.on_time && schedule.off_time) {
        schedule.off_time = addTime(
          schedule.on_time,
          timeFormat,
          TimeConstant.THIRTY_MIN,
        );
      }
      if (index) {
        schedule = updateHolidaysWeekDays(schedule, schedules[index - 1]);
      }
    });
  }
};
