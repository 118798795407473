import React from 'react';
import { useHistory } from 'react-router-dom';
import { Device, FoodGroups } from '@danfoss/etui-sm-xml';
import { areObjectsEqual } from '../components/ConfigurationItemContentMenuDeviceSelect/utils/areObjectsEqual';
import { ConfigurationMenuContext } from './ConfigurationMenuContext';

type DeviceItems = Record<string, Device[]>;

export function ConfigurationMenuProvider({ children }) {
  const {
    location: { pathname },
  } = useHistory();

  const [devicesById, setDevicesById] = React.useState<DeviceItems>({});
  const [device, setDevice] = React.useState<Device>(null);
  const [foodGroup, setFoodGroup] = React.useState<FoodGroups>(null);
  const [deviceIsUpdated, setDeviceIsUpdated] = React.useState<Boolean>(false);
  const [deviceGroup, setDeviceGroup] = React.useState<string>('0');
  const [deviceSubgroup, setDeviceSubgroup] = React.useState<string>('0');
  const [page, setPage] = React.useState<string>('0');
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [searchMode, setSearchMode] = React.useState<boolean>(false);

  const [modalDevices, setModalDevices] = React.useState<{
    [key: string]: Device;
  }>({});
  const [modalDeviceGroup, setModalDeviceGroup] = React.useState<string>('0');
  const [modalDeviceSubgroup, setModalDeviceSubgroup] =
    React.useState<string>('0');
  const [modalPage, setModalPage] = React.useState<string>('0');

  const handleOnSetFoodGroup = (newFoodGroup: FoodGroups) => {
    if (newFoodGroup && !areObjectsEqual(foodGroup, newFoodGroup)) {
      setFoodGroup(newFoodGroup);
    }
  };

  const handleOnSetDeviceItems = (menuId: string, items: Device[]) => {
    if (items?.length && !areObjectsEqual(devicesById[menuId], items)) {
      setDevicesById({
        ...devicesById,
        [menuId]: items,
      });
    }
  };

  const handleOnSetDevice = (newDevice: Device) => {
    if (newDevice && !areObjectsEqual(device, newDevice)) {
      setDevice(newDevice);
      handleDeviceUpdate(true);
    }
  };

  const handleDeviceUpdate = (state: Boolean) => {
    if (state !== deviceIsUpdated) {
      setDeviceIsUpdated(state);
    }
  };

  const handleOnSetDeviceGroup = (newDeviceGroup: string) => {
    if (newDeviceGroup && newDeviceGroup !== deviceGroup) {
      setDeviceGroup(newDeviceGroup);
    }
  };

  const handleOnSetDeviceSubgroup = (newDeviceSubgroup: string) => {
    if (newDeviceSubgroup && newDeviceSubgroup !== deviceSubgroup) {
      setDeviceSubgroup(newDeviceSubgroup);
    }
  };

  const handleOnSetPage = (newPage: string) => {
    if (newPage && newPage !== page) {
      setPage(newPage);
    }
  };

  const handleOnSetSearchValue = (value: string) => {
    setSearchValue(value);
    setSearchMode(true);
  };

  const handleOnSetModalDevice = (key: string, newDevice: Device) => {
    if (!areObjectsEqual(modalDevices[key], newDevice)) {
      setModalDevices(prevState => ({
        ...prevState,
        [key]: newDevice,
      }));
    }
  };

  const handleOnSetModalDeviceGroup = (newDeviceGroup: string) => {
    if (newDeviceGroup && newDeviceGroup !== modalDeviceGroup) {
      setModalDeviceGroup(newDeviceGroup);
    }
  };

  const handleOnSetModalDeviceSubgroup = (newDeviceSubgroup: string) => {
    if (newDeviceSubgroup && newDeviceSubgroup !== modalDeviceSubgroup) {
      setModalDeviceSubgroup(newDeviceSubgroup);
    }
  };

  const handleOnSetModalPage = (newPage: string) => {
    if (newPage && newPage !== modalPage) {
      setModalPage(newPage);
    }
  };

  const refreshModalContextState = () => {
    setModalDevices({});
    setModalDeviceGroup('0');
    setModalDeviceSubgroup('0');
    setModalPage('0');
  };

  const refreshContextState = () => {
    setDevice(null);
    setDeviceGroup('0');
    setDeviceSubgroup('0');
    setPage('0');
    refreshModalContextState();
  };

  React.useEffect(() => {
    refreshContextState();
    setSearchMode(false);
    setFoodGroup(null);
  }, [pathname]);

  return (
    <ConfigurationMenuContext.Provider
      value={{
        devicesById,
        onSetDevices: handleOnSetDeviceItems,
        device,
        onSetDevice: handleOnSetDevice,
        foodGroup,
        onSetFoodGroup: handleOnSetFoodGroup,
        deviceIsUpdated,
        onDeviceUpdate: handleDeviceUpdate,
        deviceGroup,
        onSetDeviceGroup: handleOnSetDeviceGroup,
        deviceSubgroup,
        onSetDeviceSubgroup: handleOnSetDeviceSubgroup,
        page,
        onSetPage: handleOnSetPage,
        searchMode,
        searchValue,
        onSetSearchValue: handleOnSetSearchValue,
        modalDevices,
        onSetModalDevice: handleOnSetModalDevice,
        modalDeviceGroup,
        onSetModalDeviceGroup: handleOnSetModalDeviceGroup,
        modalDeviceSubgroup,
        onSetModalDeviceSubgroup: handleOnSetModalDeviceSubgroup,
        modalPage,
        onSetModalPage: handleOnSetModalPage,
        refreshContextState,
        refreshModalContextState,
      }}
    >
      {children}
    </ConfigurationMenuContext.Provider>
  );
}
