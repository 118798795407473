import { styled } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';

type Props = DivProps & { isMdView: boolean };

export const ContentWrapper = styled(Div)<Props>`
  margin-top: ${({ theme, isMdView }) =>
    theme.spacing[isMdView ? 'md' : 'xxlg'] * 2}px;
  padding: ${({ theme }) => theme.spacing.md}px;
  text-align: center;

  * {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
