import * as React from 'react';
import { Drawer, CommandBar, HeaderNavItem, icons } from '@danfoss/etui-core';
import { P } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { useUnit } from '@danfoss/etui-sm/context';
import { Measurement, DateFormat, useModal } from '@danfoss/etui-sm';
import {
  AxisType,
  ChartData,
  DateRange,
  LegendConfig,
  LegendMap,
} from './HistoryPage.types';
import { OldHistoryCharts as HistoryCharts } from './components/OldHistoryCharts';
import { HistoryLegend } from './components/OldHistoryLegend';
import { historyExportCSV } from './utils/history-export-csv';
import { HistoryPageConfigModal } from './HistoryPageConfigModal';
import { HistoryPageExportModal } from './HistoryPageExportModal';

interface Props {
  dateRange: DateRange;
  chartData: ChartData[];
  formats: Measurement;
  onFormatChange: (formats: Partial<Record<AxisType, string>>) => void;
  onChartsToDrawChange: (id: string, conf: Partial<LegendConfig>) => void;
  onChartsDataClear: () => void;
  legendMap: LegendMap;
}

function getTimeFormat(timeFormatValue: Measurement['timeFormat']) {
  if (timeFormatValue === '1') {
    return 'HH:mm:ss';
  }
  if (timeFormatValue === '0') {
    return 'hh:mm:ss a (zzzz)';
  }
  return '';
}

function getDateFormat(dateFormat: Measurement['dateFormat']) {
  switch (dateFormat) {
    case DateFormat.MMDDYY:
      return 'MM/dd/yy';
    case DateFormat.MMDDYYYY:
      return 'MM/dd/yyyy';
    case DateFormat.DDMMYY:
      return 'dd/MM/yy';
    case DateFormat.DDMMYYYY:
      return 'dd/MM/yyyy';
    default:
      return '';
  }
}

function getDateTimeFormat(dateFormat, timeFormat) {
  return `${getDateFormat(dateFormat)} ${getTimeFormat(timeFormat)}`;
}

export function HistoryGraphView({
  dateRange,
  chartData,
  formats,
  onFormatChange,
  legendMap,
  onChartsToDrawChange,
  onChartsDataClear,
}: Props) {
  const chartsRef = React.useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const [handleConfigModal] = useModal(HistoryPageConfigModal, {
    formats,
    onFormatChange,
  });
  const [handleExportModal] = useModal(HistoryPageExportModal, {
    exportCsv: () => historyExportCSV(chartData, dateRange, units, formats),
    exportPng: chartsRef.current?.exportPng,
  });
  const { units } = useUnit();

  const { timeFormat, dateFormat, appUnitFormats } = React.useMemo(() => {
    const { timeFormat: time, dateFormat: date, ...unitFormats } = formats;
    return {
      appUnitFormats: unitFormats,
      timeFormat: time,
      dateFormat: date,
    };
  }, [formats]);

  const emptyView = React.useMemo(
    () => (
      <P m={`${theme.spacing.lg}px`} testId="history-no-charts">
        {t('t877')}
      </P>
    ),
    [],
  );

  return (
    <>
      <CommandBar
        items={[
          {
            key: 'clear',
            onRender: () => (
              <HeaderNavItem
                key="clear"
                iconProps={{ glyph: icons.CLOSE }}
                bottom={true}
                onClick={onChartsDataClear}
                props={{ root: { testId: 'clear-history' } }}
              />
            ),
          },
        ]}
        farItems={[
          {
            key: t('t830'),
            iconProps: {
              glyph: icons.COG,
            },
            onClick: handleConfigModal,
          },
          {
            key: t('t175'),
            iconProps: {
              glyph: icons.SHARE,
            },
            disabled: !chartData || chartData.length === 0,
            onClick: handleExportModal,
          },
        ]}
      />
      {chartData?.length > 0 && (
        <HistoryCharts
          ref={chartsRef}
          legendMap={legendMap}
          data={chartData}
          appUnitFormats={appUnitFormats}
          dateTimeFormat={getDateTimeFormat(dateFormat, timeFormat)}
          EmptyView={emptyView}
        />
      )}
      {chartData?.length > 0 && (
        <Drawer sneakPeakPercent={1} maxOpenPercent={60}>
          <HistoryLegend
            legendMap={legendMap}
            data={chartData}
            onChartsToDrawChange={onChartsToDrawChange}
          />
        </Drawer>
      )}
    </>
  );
}
