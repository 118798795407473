import { IconGlyph, icons } from '@danfoss/etui-core';
import { DeviceType } from '@danfoss/etui-sm/types';

const iconByType: { [key: string]: IconGlyph } = {
  refrig: icons.REFRIGERATION,
  hvac: icons.HVAC,
  misc: icons.MISC,
  lights: icons.LIGHTING,
  meter: icons.ELECTRICS,
  'time-range': icons.CALENDAR,
};

const getDeviceIconByDeviceType = (type: DeviceType): IconGlyph =>
  iconByType[type];

export { getDeviceIconByDeviceType };
