import { newPipeline, AnonymousCredential } from '@azure/storage-blob';
import { SasUpdatePolicyFactory, SasStore } from './azure-sas-policy.helper.js';

const getPipelineObject = sasToken => {
  const sasStore = new SasStore(sasToken);
  const pipeline = newPipeline(new AnonymousCredential());
  // Inject SAS update policy factory into current pipeline
  pipeline.factories.unshift(new SasUpdatePolicyFactory(sasStore));
  return pipeline;
};

export { getPipelineObject };
