import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { LayoutFlowTitle } from '../../../RefrigLayoutModal/components/LayoutContent/LayoutFlowTitle';
import { ParametersFlowTable } from './ParametersFlowTable';

export interface ParametersFlowPageProps {
  handleOnSelect: (record: ConfigurationListItem) => void;
}

export const ParametersFlowPage = ({
  handleOnSelect,
}: ParametersFlowPageProps) => {
  const { t } = useTranslation();
  return (
    <Div testId="parameters-flow-page-div" mt="10px">
      <LayoutFlowTitle
        title={t('t3442')}
        subTitle={t('t3443')}
        helpText={t('t3444')}
        glyph={icons.PARAMETERS}
      />
      <ParametersFlowTable handleOnSelect={handleOnSelect} />
    </Div>
  );
};
