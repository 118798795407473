import '@danfoss/etui-sm-xml';
import { getIsSuctionDevice } from './get-is-suction-device.js';
import { getIsNoCompressorDevice } from './get-is-no-compressor-device.js';
import { getIsPackDevice } from './get-is-pack-device.js';
import { getIsRackDevice } from './get-is-rack-device.js';

function getTopLevelDevice(device) {
  if ((getIsPackDevice(device) || getIsRackDevice(device) || getIsNoCompressorDevice(device)) && !getIsSuctionDevice(device)) {
    return true;
  }
  return false;
}
function isSuctionHasChildSuction(device) {
  let hasChildSuction = false;
  if (device && device.items && device.items.length > 0) {
    for (const item of device.items) {
      if (getIsSuctionDevice(item)) {
        hasChildSuction = true;
        break;
      }
    }
  }
  return hasChildSuction;
}

export { getTopLevelDevice, isSuctionHasChildSuction };
