import { DEVICE_TYPE, DiscoveryTableData } from '../types';

export const checkforSameDevices = (
  deviceA: DiscoveryTableData,
  deviceB: DiscoveryTableData,
) => {
  if (!deviceA || !deviceB) return false;
  if (
    deviceA.rk === deviceB.rk &&
    (deviceA.deviceType === DEVICE_TYPE.NO_COMP_SUCT ||
      deviceA.address === deviceB.address)
  ) {
    return true;
  }
};
