import {
  ConfigurationItem,
  ConfigurationTabItem,
  Device,
  fetchXMLData,
  getUnitUrl,
  Unit,
  User,
  XML_ACTION,
} from '@danfoss/etui-sm-xml';

async function fetchDeviceConfigurationByUnit([sourceUrl, unit, device, user]: [
  string,
  Unit,
  Device,
  User,
]): Promise<ConfigurationTabItem[]> {
  let bpidx: number = 0;
  const deviceBpidx = parseInt(device.bpidx, 10);
  if (deviceBpidx > 0) {
    bpidx = deviceBpidx - 1;
  }

  const data = await fetchXMLData<{
    tableaddress: string;
    items: {
      tabitem: ConfigurationTabItem[];
    };
  }>({
    url: getUnitUrl(sourceUrl, unit),
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_STATUS,
      arg1: device.arg1,
      arg2: device.arg2,
      arg3: device.arg3,
      bpidx: bpidx.toString(),
      node: device.node,
      nodetype: device.nodetype,
      stype: device.stype,
      mod: device.mod,
      point: device.point,
      iotype: device.iotype,
    },
  });

  if (data && data.items && data.items.tabitem) {
    const tabItems = Array.isArray(data.items.tabitem)
      ? data.items.tabitem
      : [data.items.tabitem];
    return tabItems.map(item => {
      return {
        ...item,
        id: `${data.tableaddress}-${item.index}`,
      };
    });
  }

  return [];
}
async function fetchCompleteDeviceConfigurationByUnit([
  sourceUrl,
  unit,
  device,
  user,
]: [string, Unit, Device, User]): Promise<ConfigurationItem> {
  let bpidx: number = 0;
  const deviceBpidx = parseInt(device.bpidx, 10);
  if (deviceBpidx > 0) {
    bpidx = deviceBpidx - 1;
  }

  const data = await fetchXMLData<ConfigurationItem>({
    url: getUnitUrl(sourceUrl, unit),
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_STATUS,
      arg1: device.arg1,
      arg2: device.arg2,
      arg3: device.arg3,
      bpidx: bpidx.toString(),
      node: device.node,
      nodetype: device.nodetype,
      stype: device.stype,
      mod: device.mod,
      point: device.point,
      iotype: device.iotype,
    },
  });

  return data || null;
}
export {
  fetchDeviceConfigurationByUnit,
  fetchCompleteDeviceConfigurationByUnit,
};
