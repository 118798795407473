import '../utils/read-file-as-url-data.js';
import '../utils/parse-xml.js';
import '../utils/is-encoded.js';
import 'pako';
import '../utils/decode.js';
import '../utils/fetch-image-base64-string.js';
import '../utils/get-xml.js';
import { b as _defineProperty } from '../_rollupPluginBabelHelpers-6901a291.js';
import 'uuid';
import { saveAs } from 'file-saver';
import '../annotation/Conditions/utils/execute-operation.js';
import '../annotation/Conditions/Condition.js';
import '../annotation/utils/bind-methods.js';
import '../annotation/utils/Subscribable.js';
import '../annotation/Conditions/ConditionCollection.js';
import '../annotation/Mappings/Mapping.js';
import '../annotation/Mappings/MappingCollection.js';
import '../annotation/DataSource/DataSource.js';
import '../annotation/Display/Display.js';
import '../annotation/Rules/Rule.js';
import '../annotation/Rules/RuleCollection.js';
import { ScadaStorageView } from './ScadaStorageView.js';

class ScadaStorage {
  constructor(connector, convertor) {
    this.views = [];
    this.connector = connector;
    this.convertor = convertor;
  }
  async connect() {
    await this.connector.connect();
    const response = await this.connector.fetch();
    let rawData;
    if (response.succeeded) {
      rawData = await this.convertor.parse(response === null || response === void 0 ? void 0 : response.blobBody);
    }
    if (rawData) {
      this.views = rawData.map(rawView => {
        const newView = new ScadaStorageView('');
        newView.importView(rawView);
        return newView;
      });
      this.applySortIndices();
    }
  }
  static applySortIndexOnView(view, orderIndex, parentSortIndex) {
    if (parentSortIndex === void 0) {
      parentSortIndex = null;
    }
    view.sortIndex = parentSortIndex !== null ? `${parentSortIndex}${ScadaStorage.sortIndexSeparator}${orderIndex}` : `${orderIndex}`;
    if (view.subViews) {
      view.subViews.forEach((v, i) => ScadaStorage.applySortIndexOnView(v, i, view.sortIndex));
    }
  }
  static splitViewSortIndex(sortIndex) {
    const levels = sortIndex.split(ScadaStorage.sortIndexSeparator);
    const parentSortIndex = levels.slice(0, -1).join(ScadaStorage.sortIndexSeparator);
    const viewIndex = Number(levels[levels.length - 1]);
    return [parentSortIndex, viewIndex];
  }
  applySortIndices() {
    this.views.forEach((view, index) => {
      ScadaStorage.applySortIndexOnView(view, index, null);
    });
  }
  getRelatedSubViews(sortIndex) {
    const [parentSortIndex] = ScadaStorage.splitViewSortIndex(sortIndex);
    return parentSortIndex ? this.getViewBySortIndex(parentSortIndex).subViews : this.getViews();
  }
  async remove() {
    await this.connector.delete();
  }
  async update() {
    let succeeded = false;
    const converted = await this.convertor.convert(this.views);
    if (converted) {
      ({
        succeeded
      } = await this.connector.put(converted));
    }
    return {
      succeeded
    };
  }
  addView(viewName, parentSortIndex, xml) {
    if (parentSortIndex === void 0) {
      parentSortIndex = '';
    }
    if (xml === void 0) {
      xml = '';
    }
    const destinationViews = parentSortIndex ? this.getViewBySortIndex(parentSortIndex).subViews : this.views;
    const view = new ScadaStorageView('');
    view.importView({
      name: viewName,
      sortIndex: parentSortIndex ? `${parentSortIndex}-${destinationViews.length}` : `${destinationViews.length}`,
      xml
    });
    destinationViews.push(view);
    return view;
  }
  duplicateView(viewId) {
    var _existView$rules$getR;
    const existView = this.getViewById(viewId);
    if (existView === undefined) {
      return undefined;
    }
    const [parentSortIndex] = ScadaStorage.splitViewSortIndex(existView.sortIndex);
    const newView = this.addView(`Copy of ${existView.name}`, parentSortIndex);
    newView.xml = existView.xml;
    (_existView$rules$getR = existView.rules.getRules()) === null || _existView$rules$getR === void 0 ? void 0 : _existView$rules$getR.forEach(rule => {
      const newRule = newView.rules.addRule(rule.name);
      newRule.import(rule.getData());
    });
    return newView;
  }
  renameView(viewId, name) {
    const view = this.getViewById(viewId);
    if (view === undefined) {
      return undefined;
    }
    view.name = name;
    view.updatedAt = Date.now();
    return view;
  }

  /**
   * Export view as a ZIP file
   * @param viewId
   * @param convertor - specific convertor for view encryption
   */
  async exportView(convertor, viewId) {
    if (convertor === void 0) {
      convertor = undefined;
    }
    const view = this.getViewById(viewId);
    if (view) {
      const currentConvertor = convertor || this.convertor;
      const converted = await currentConvertor.convert([view]);
      saveAs(converted, `${view.name}.zip`);
    }
  }

  /**
   * Export all Scada views as a ZIP file
   * @param convertor - specific convertor for view encryption
   */
  async exportAllViews(convertor) {
    if (convertor === void 0) {
      convertor = undefined;
    }
    const views = this.getViews();
    if (views.length) {
      const currentConvertor = convertor || this.convertor;
      const converted = await currentConvertor.convert(views);
      // TODO: ability to changes file name
      saveAs(converted, `scada-views.zip`);
    }
  }
  getViewById(viewId) {
    function checkViews(views, id) {
      for (let i = 0; i < views.length; i++) {
        if (views[i].id === id) {
          return views[i];
        }
        if (views[i].subViews) {
          const matchedSubView = checkViews(views[i].subViews, id);
          if (matchedSubView) {
            return matchedSubView;
          }
        }
      }
      return null;
    }
    return checkViews(this.views, viewId);
  }

  // should be cheaper than getViewById in terms of time complexity
  getViewBySortIndex(sortIndex) {
    return sortIndex.split(ScadaStorage.sortIndexSeparator).reduce((subViews, level, index, self) => index === self.length - 1 ? subViews[level] : subViews[level].subViews, this.views) || null;
  }
  move(sourceId, destId) {
    const sourceView = this.getViewById(sourceId);
    const destView = this.getViewById(destId);
    const destSortIndex = destView.sortIndex;
    const [, destViewIndex] = ScadaStorage.splitViewSortIndex(destSortIndex);
    const views = this.getRelatedSubViews(destSortIndex);
    this.removeView(sourceId);
    views.splice(destViewIndex, 0, sourceView);
    this.applySortIndices();
    return this;
  }
  nest(sourceId, destId) {
    const sourceView = this.getViewById(sourceId);
    const destView = this.getViewById(destId);
    destView.subViews = [...(destView.subViews || []), sourceView];
    this.removeView(sourceId);
    this.applySortIndices();
    return this;
  }
  getViews() {
    return this.views;
  }
  removeView(viewId) {
    const view = this.getViewById(viewId);
    const [, viewIndex] = ScadaStorage.splitViewSortIndex(view.sortIndex);
    const views = this.getRelatedSubViews(view.sortIndex);
    views.splice(viewIndex, 1);
    this.applySortIndices();
    return this.views;
  }
  static async initStorage(connector, convertor) {
    const storage = new ScadaStorage(connector, convertor);
    await storage.connect();
    return storage;
  }
}
_defineProperty(ScadaStorage, "sortIndexSeparator", '-');

export { ScadaStorage };
