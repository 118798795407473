import {
  ConfigurationListItem,
  fetchXMLData,
  XML_ACTION,
} from '@danfoss/etui-sm-xml';
import {
  SECURITY_TABLE_ADDRESS,
  SECURITY_TABLE_ADDRESS_INDEX,
} from '../SecurityCompliancePage.types';

export const initChanges = async (
  url: string,
  listItem: ConfigurationListItem,
) => {
  const { ef: enterfunc, pnum, type, token } = listItem;
  await fetchXMLData({
    url,
    attributes: {
      action: XML_ACTION.WRITE_LIST,
      tableaddress: SECURITY_TABLE_ADDRESS,
      index: SECURITY_TABLE_ADDRESS_INDEX,
      storeview_only: '1',
      version: 'C',
      ...{ enterfunc, pnum, type, token },
    },
  });
};
