import { parseXml } from './parse-xml.js';
import pako from 'pako';

function getTextContent(node) {
  return node != null ? node[node.textContent === undefined ? 'text' : 'textContent'] : '';
}
function decode(data, encode, deflate, base64) {
  try {
    const node = parseXml(data).documentElement;
    if (node != null && node.nodeName === 'mxfile') {
      const diagrams = node.getElementsByTagName('diagram');
      if (diagrams.length > 0) {
        const diagram = diagrams[0];
        const mxGraphModels = diagram.getElementsByTagName('mxGraphModel');
        if (mxGraphModels.length !== 0) {
          data = diagram.innerHTML;
        } else {
          data = getTextContent(diagram);
        }
      }
    }
  } catch (e) {
    console.error('Error in mxGraph decode', e);
  }
  if (base64) {
    data = atob(data);
  }
  if (deflate && data.length > 0) {
    data = pako.inflateRaw(Uint8Array.from(data, c => c.charCodeAt(0)), {
      to: 'string'
    });
  }
  if (encode) {
    data = decodeURIComponent(data);
  }
  return data;
}

export { decode };
