import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { darken } from '@danfoss/etui-colors';
import { ellipsis } from '@danfoss/etui-core';

export function ValueContainer({ width, children }) {
  const theme = useTheme();

  return (
    <Div
      testId="value-div"
      display="flex"
      alignItems="center"
      height="25px"
      p={`${theme.spacing.xxs}px ${theme.spacing.xs}px`}
      color={theme.palette.text.secondary}
      fontSize={theme.typography.pxToRem(10)}
      border={`1px solid ${darken(theme.palette.common.white, 0.03)}`}
      borderRadius={theme.shape.borderRadius}
      bg={darken(theme.palette.common.white, 0.015)}
      letterSpacing={theme.typography.label.letterSpacing}
      whiteSpace="nowrap"
      overflow="hidden"
      textTransform="capitalize"
      textDecoration="none"
      minWidth={40}
      width={width}
      title={children || '-'}
      {...(ellipsis() as any)}
    >
      {children}
    </Div>
  );
}
