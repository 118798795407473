/* eslint-disable no-await-in-loop */
import {
  fetchDeviceHistoryConfig,
  HistoryCfg,
  startHistoryQuery,
  User,
  Unit,
  Device,
  getHistoryFileChunk,
  QueryStatus,
  readHistoryQueryStatus,
  readQueryData,
  getUrlInfo,
  getProxyConfig,
} from '@danfoss/etui-sm-xml';
import { getArray, getResourceUrl } from 'utils';
import { getHistoryObjId } from './history-utils';

// Helpers
const tick = (n = 1) =>
  new Promise(res => {
    setTimeout(res, n * 100);
  });

function fileStringToArrayBuffer(fileContent: string): ArrayBuffer {
  const str = atob(fileContent);
  const buffer = new ArrayBuffer(str.length);
  const view = new Int8Array(buffer);
  for (let iIndex = 0; iIndex < str.length; iIndex++) {
    view[iIndex] = str.charCodeAt(iIndex);
  }
  return buffer;
}
// End helpers

export function fetchDeviceParams(
  url: string,
  user: User,
  unit: Unit,
  device: Device & { id?: string },
) {
  return fetchDeviceHistoryConfig(url, user, unit, device).then(
    ({ history_cfg, current_secs, daylightsavings, timezone }) => {
      const zone: number = Number(timezone);
      const dst: number = Number(daylightsavings);
      let offset: number = zone * 60 * 60 * 10;
      if (dst === 1) {
        offset += 60 * 60 * 1000;
      }
      const mSecsOffZone = offset;
      const mSecsOffset255toPC = Number(current_secs) * 1000 - Date.now(); // unit time - PC time is the offset between PC and the 255
      const mSecs255Time = Number(current_secs) * 1000;
      if (history_cfg) {
        return getArray<HistoryCfg>(history_cfg).map((historyObj, index) => {
          return {
            ...historyObj,
            id: getHistoryObjId(device.id, index),
            url,
            mSecsOffZone,
            mSecs255Time,
            mSecsOffset255toPC,
            // in legacy each item's nodetype gets rewritten by device nodetype (why?)
            nodetype: device.nodetype,
          };
        });
      }
      return [];
    },
  );
}

export function startHistoryQueries(
  user: User,
  start: number,
  end: number,
  historyObjs: HistoryCfg[],
) {
  // map uniq urls to history objects
  // uniq urls can be for units that return different result for getUnitUrl(url, unit);
  const unitUrlToObjMap: { [key: string]: HistoryCfg[] } = historyObjs.reduce(
    (acc, obj) => {
      if (acc[obj.url]) {
        acc[obj.url].push(obj);
      } else {
        acc[obj.url] = [obj];
      }
      return acc;
    },
    {},
  );
  // return history query number for each uniq url
  return Promise.all(
    Object.entries(unitUrlToObjMap).map(([url, historyObjsByUrl]) =>
      startHistoryQuery(url, user, start, end, historyObjsByUrl),
    ),
  );
}

async function getHistoryFileByResource(url: string) {
  const { protocol, host } = getUrlInfo(url);
  const { url: resourceUrl, headers } = getResourceUrl(
    host,
    'history.pge',
    getProxyConfig(),
  );

  const file = await fetch(`${protocol.replace(':', '')}://${resourceUrl}`, {
    cache: 'no-store',
    headers,
  });

  return file.arrayBuffer();
}

async function getHistoryFileFromXml(url: string, user: User) {
  let completed = false;
  let offset = 0;
  let fileContent = '';
  while (!completed) {
    const file = await getHistoryFileChunk(url, user, offset);
    if (file) {
      fileContent += file.encodedfile.b64;
      if (file.done === '1') {
        completed = true;
      } else {
        offset = Array.isArray(file.offset)
          ? file.offset[file.offset.length - 1]
          : file.offset;
      }
    } else {
      completed = true;
      fileContent = null;
    }
  }
  return fileStringToArrayBuffer(fileContent);
}

async function getHistoryFileContent(
  url: string,
  user: User,
  shouldFetchHistoryByResource: boolean,
) {
  if (shouldFetchHistoryByResource) {
    return getHistoryFileByResource(url);
  }
  return getHistoryFileFromXml(url, user);
}

export async function runCheckForQuery(
  query: string[],
  user: User,
  onProgressChange,
  shouldFetchHistoryByResource: boolean,
  attemps: number,
): Promise<ArrayBuffer> {
  const [queryId, url] = query;
  let status: QueryStatus = 'active';
  while (status === 'active') {
    try {
      const {
        status: nextStatus,
        points_collected: pointsCollected,
        total_points: totalPoints,
      } = await readHistoryQueryStatus(url, user, queryId);
      status = nextStatus;
      onProgressChange(
        queryId,
        Math.round((pointsCollected * 100) / totalPoints),
      );

      if (nextStatus === 'active')
        await tick(shouldFetchHistoryByResource ? 7.5 : 5);
    } catch (e) {
      if (attemps) {
        return runCheckForQuery(
          query,
          user,
          onProgressChange,
          shouldFetchHistoryByResource,
          attemps--,
        );
      }

      throw new Error();
    }
  }
  if (!shouldFetchHistoryByResource) {
    await readQueryData(url, user, queryId); // TODO should be used to check if history collected successfully on BE side
  }

  return getHistoryFileContent(url, user, shouldFetchHistoryByResource);
}

export async function runChecksForQueries(
  user,
  queries,
  onProgressChange,
  shouldFetchHistoryByResource: boolean,
): Promise<ArrayBuffer[]> {
  const CHECK_QUERY_ATTEMPS = 3;
  const progressMap: { [key: string]: number } = queries.reduce(
    // eslint-disable-next-line no-return-assign, no-sequences
    (acc, [queryId]) => ((acc[queryId] = 0), acc),
    {},
  );
  function onQueryProgress(queryId: string, progress: number) {
    if (progress === 0 || progress >= 100 || isNaN(progress)) {
      return;
    }
    progressMap[queryId] = progress;
    onProgressChange(
      Math.round(
        Object.values(progressMap).reduce(
          // eslint-disable-next-line no-return-assign
          (acc, queryProgress) => (acc += queryProgress),
          0,
        ) / queries.length,
      ),
    );
  }
  return Promise.all(
    queries.map(q =>
      runCheckForQuery(
        q,
        user,
        onQueryProgress,
        shouldFetchHistoryByResource,
        CHECK_QUERY_ATTEMPS,
      ),
    ),
  );
}
