import '@danfoss/etui-sm-xml';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../utils/delay.js';
import '../actions/fetch-read-units.js';
import { fetchUpdateSoftwareUnitStatus } from '../actions/fetch-update-software-unit-status.js';
import { executeForceResetSm800 } from '../actions/execute-force-reset-sm800.js';
import { fetchUpdateEdfFilesSM800 } from '../actions/fetch-update-edf-files-sm800.js';

function useEdfUploadSM800(selectedEdfFiles, url, user, shouldStart, timeout, sm800InstallFiles) {
  const {
    t
  } = useTranslation();
  const [progress, setProgress] = React.useState(0);
  const [installSuccess, setInstallSuccess] = React.useState(false);
  const [forceReset, setForceReset] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(null);
  const handleSetProgress = importProgress => {
    setProgress(importProgress);
  };
  const handleSetInstallSucess = edfInstallSuccess => {
    setInstallSuccess(edfInstallSuccess);
  };
  const handleSetFailure = () => {
    setFailure(new Error(t('t3240')));
  };
  React.useEffect(() => {
    const loadEdfFiles = async () => {
      const set = new Set();
      const totalLength = selectedEdfFiles.length;
      sm800InstallFiles.filter(item => selectedEdfFiles.includes(item.name)).filter(eachObj => {
        const duplicate = set.has(eachObj.name);
        set.add(eachObj.name);
        return !duplicate;
      }).reduce(async (promise, insatllEdf) => {
        await promise;
        await fetchUpdateEdfFilesSM800(insatllEdf, url, user, selectedEdfFiles, totalLength, handleSetProgress, handleSetInstallSucess, handleSetFailure);
      }, Promise.resolve);
    };
    if (shouldStart) {
      loadEdfFiles();
    }
  }, [shouldStart]);
  React.useEffect(() => {
    async function forceResetSM800() {
      await executeForceResetSm800(url, user);
    }
    if (installSuccess) {
      forceResetSM800();
      setProgress(60);
      setForceReset(true);
    }
  }, [installSuccess]);
  React.useEffect(() => {
    const checkUnitStatus = async () => {
      const sm800Delay = 10000;
      let completed = false;
      const startTime = +new Date();
      let remaingProgress = progress;
      while (!completed) {
        // eslint-disable-next-line no-await-in-loop
        const unitStatus = await fetchUpdateSoftwareUnitStatus(url, user, sm800Delay);
        if (unitStatus === 'down') {
          if (remaingProgress !== 100) {
            remaingProgress += 2.5;
            setProgress(remaingProgress);
          }
          completed = false;
        } else {
          completed = true;
          setProgress(100);
          setSuccess(true);
        }
        const installTime = +new Date() - startTime;
        if (!completed && installTime > timeout) {
          setFailure(new Error(t('t3222')));
          completed = true;
        }
      }
    };
    if (forceReset) {
      checkUnitStatus();
    }
  }, [forceReset]);
  return {
    progress,
    installSuccess,
    success,
    failure
  };
}

export { useEdfUploadSM800 };
