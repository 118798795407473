import { WeekCalendarEvent } from '@danfoss/etui-core';
import { Theme } from '@danfoss/etui-themes';
import {
  BaseEventItemOptions,
  ScheduleItemGraphicOptions,
} from 'pages/SchedulesPage/SchedulesPage.types';
import { SHCEDULES_BLUE_SHADES } from 'pages/SchedulesPage/utils/getBlueColorString';

export const prepareSchedules = (
  schedules: WeekCalendarEvent[][],
  scheduleDisplayOptions: ScheduleItemGraphicOptions[],
  theme: Theme,
) => {
  return schedules.map(scheduleItem => {
    return scheduleItem.map(item => {
      const searchedItem = scheduleDisplayOptions.find(
        sched => sched.id === item.innerId,
      );

      const { color, offset } = searchedItem;
      return {
        ...item,
        styles: {
          root: {
            bg: color,
            boxShadow:
              color < SHCEDULES_BLUE_SHADES[12]
                ? 'none'
                : `0 0 1px 1px ${theme.palette.grey[100]}`,
            left: `${offset}px`,
            minHeight: '15px',
            borderRadius: '12px',
            width: BaseEventItemOptions.width,
          },
        },
      };
    });
  });
};
