import {
  acknowledgeAlarmByRef,
  User,
  getUnitUrl,
  Unit,
} from '@danfoss/etui-sm-xml';
import { ALARM_GROUPED_BY_UNIT_DELIMITER } from '../constants';

async function fetchAcknowledgeAlarmByRef(
  url: string,
  unitAddress: string,
  alarmRef: string,
  user: User,
) {
  try {
    await acknowledgeAlarmByRef(url, alarmRef, user);
    return `${unitAddress}${ALARM_GROUPED_BY_UNIT_DELIMITER}${alarmRef}`;
  } catch (error) {
    return null;
  }
}

export async function fetchAcknowledgeAlarmsByUnit(
  url: string,
  alarmRefsGroupedByUnit: { [key: string]: string[] },
  user: User,
  unit: Unit,
) {
  const unitUrl = getUnitUrl(url, unit);
  const unitAddress = unit.unit_addr;

  const alarmRefs = alarmRefsGroupedByUnit[unitAddress];

  const acknowledgedAlarmRefs = await Promise.all(
    alarmRefs.map(alarmRef => {
      return fetchAcknowledgeAlarmByRef(unitUrl, unitAddress, alarmRef, user);
    }),
  );

  return acknowledgedAlarmRefs.filter(Boolean);
}
