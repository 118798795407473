import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import '../../constants/xml-action-constant.js';
import '../../constants/xml-tag-constant.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import '../../types/Unit.js';
import '../../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/url-helpers/get-is-valid-dns.js';
import '../../helpers/url-helpers/get-is-valid-ip.js';
import '../../helpers/url-helpers/get-is-valid-ip-or-dns.js';
import '../../helpers/url-helpers/get-is-valid-port.js';
import '../../helpers/url-helpers/get-url-info.js';
import '../../helpers/unit-helpers/initial-unit-utils.js';
import '../../helpers/unit-helpers/get-unit-networks-by-unit.js';
import '../../helpers/unit-helpers/get-unit-network-by-unit.js';
import '../../helpers/unit-helpers/get-unit-url-by-unit-network.js';
import { getUnitUrl } from '../../helpers/unit-helpers/get-unit-url.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import './fetch-acknowledge-alarm-by-ref.js';
import { acknowledgeAlarm } from './fetch-acknowledge-alarm.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import '../../fetch-xml/fetch-xml-data.js';

async function acknowledgeAlarmByUnit(sourceUrl, unit, alarm, user) {
  const url = getUnitUrl(sourceUrl, unit);
  return acknowledgeAlarm(url, alarm, user);
}

export { acknowledgeAlarmByUnit };
