import { mapBinaryStringToBlob } from '../../../utils/map-binary-string-to-blob.js';
import { fetchXMLFileData, XML_ACTION } from '@danfoss/etui-sm-xml';
import { saveAs } from 'file-saver';

async function fetchExportDiagnosticsLog(url, user) {
  const {
    fileName,
    fileContent
  } = await fetchXMLFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action: XML_ACTION.UPDATE_SW,
    swCmd: 'export_debug_logs'
  });
  const blob = mapBinaryStringToBlob(fileContent);
  saveAs(blob, fileName);
}

export { fetchExportDiagnosticsLog };
