import React from 'react';
import { Device, FoodGroups } from '@danfoss/etui-sm-xml';

export type ConfigurationMenuContextProps = {
  devicesById: {
    [key: string]: Device[];
  };
  onSetDevices: (menuId: string, items: Device[]) => void;
  device: Device;
  onSetDevice: (device: Device) => void;
  foodGroup: FoodGroups;
  onSetFoodGroup: (foodgroup: FoodGroups) => void;
  deviceIsUpdated: Boolean;
  onDeviceUpdate: (state: Boolean) => void;
  deviceGroup: string;
  onSetDeviceGroup: (deviceGroup: string) => void;
  deviceSubgroup: string;
  onSetDeviceSubgroup: (deviceSubroup: string) => void;
  page: string;
  onSetPage: (page: string) => void;
  modalDevices: { [key: string]: Device };
  onSetModalDevice: (key: string, modalDevice: Device) => void;
  modalDeviceGroup: string;
  onSetModalDeviceGroup: (modalDeviceGroup: string) => void;
  modalDeviceSubgroup: string;
  onSetModalDeviceSubgroup: (modalDeviceSubgroup: string) => void;
  modalPage: string;
  onSetModalPage: (modalPage: string) => void;
  refreshContextState: () => void;
  refreshModalContextState: () => void;
  searchMode: boolean;
  searchValue: string;
  onSetSearchValue: (value: string) => void;
};

export const ConfigurationMenuContext = React.createContext(
  null as ConfigurationMenuContextProps | null,
);
