import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Unit } from '@danfoss/etui-sm-xml';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { darken } from '@danfoss/etui-colors';
import {
  ConfigurationMenuProvider,
  ConfigurationModalProvider,
  useConfiguration,
} from '../../context';
import { ConfigurationItemHeader } from '../ConfigurationItemHeader';
import { ConfigurationItemContent } from '../ConfigurationItemContent';
import { ConfigurationOptions } from '../../data/ConfigurationOptions';

type ConfigurationItemProps = {
  unit: Unit;
  level?: number;
  InfoElement?: any;
  options?: ConfigurationOptions;
  onPasswordUpdatedForCurrentUser?: (
    passwordUpdated: boolean,
    updatedPassword: string,
  ) => void;
  updateTime?: (menuId: string, currentTimeMs: number) => void;
};

const ConfigurationItem = React.memo(
  ({
    unit,
    level = 0,
    InfoElement,
    options = {},
    onPasswordUpdatedForCurrentUser,
    updateTime,
  }: ConfigurationItemProps) => {
    const theme = useTheme();

    const { route } = useConfiguration();

    const handlePasswordUpdatedForCurrentUser = (
      passwordUpdated: boolean,
      updatedPassword: string,
    ) => {
      onPasswordUpdatedForCurrentUser(passwordUpdated, updatedPassword);
    };

    return (
      <ConfigurationModalProvider>
        <ConfigurationMenuProvider>
          <ConfigurationItemHeader options={options} />
          <Div
            height="calc(100% - 50px)"
            bg={darken(theme.palette.common.bg, 0.04)}
          >
            <Switch>
              <Route path={`${route}/:menuId`}>
                <ConfigurationItemContent
                  unit={unit}
                  level={level}
                  InfoElement={InfoElement}
                  onPasswordUpdatedForCurrentUser={
                    handlePasswordUpdatedForCurrentUser
                  }
                  updateTime={updateTime}
                />
              </Route>
            </Switch>
          </Div>
        </ConfigurationMenuProvider>
      </ConfigurationModalProvider>
    );
  },
);

export { ConfigurationItem };
