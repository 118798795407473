import { fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function fetchEdfFilesSM800(url, user) {
  try {
    const response = await fetchXMLData({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        lang: user.language,
        action: XML_ACTION.READ_DIRECTORY,
        directory: '/User/'
      }
    });
    return response;
  } catch (e) {
    return new Error();
  }
}

export { fetchEdfFilesSM800 };
