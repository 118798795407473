import { getMenuIdsByPathname } from './get-menu-ids-by-pathname';

/**
 * Get the parent menu id by pathname and level
 *
 * @param {string} baseRoute
 * @param {string} pathname
 * @param {number} [level=2]
 * @returns
 */
function getParentMenuIdByPathname(
  baseRoute: string,
  pathname: string,
  level: number = 2,
) {
  const parentMenuIds = getMenuIdsByPathname(baseRoute, pathname);
  return parentMenuIds[parentMenuIds.length - level];
}

export { getParentMenuIdByPathname };
