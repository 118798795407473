import { D as DeviceCategory, S as StorageType, M as MessageType, b as DeviceSpecificTag, d as MultiTags } from '../../../DeviceIntegrationPage.types-1a4ac020.js';

function findDuplicates(arr) {
  const s = new Set(arr);
  // compare the size of array and Set
  if (arr.length !== s.size) {
    return true;
  }
  return false;
}
function validateEntries(data, deviceCategory, t) {
  const parameters = data.parameters || [];
  const alarms = data.alarms || [];
  const dynlists = data.dynlists || [];
  const groups = data.groups || [];
  const global_errors = [];
  const result = {
    summary: null,
    explanation: null,
    errors: []
  };
  const device_group = deviceCategory ? deviceCategory.value : DeviceCategory.General;
  const special_character_regex = new RegExp('^[a-zA-Z0-9-&/() ]+$');
  const label_special_character_regex = new RegExp('^[a-zA-Z0-9-&/().°+ _]+$');
  const fn_special_character_regex = new RegExp('^[a-zA-Z0-9-._]+$');
  const max_fn_character_regex = new RegExp('^.{1,8}$');
  const max_character_regex = new RegExp('^.{1,20}$');
  const label_max_character_regex = new RegExp('^.{1,40}$');
  const groupMap = new Map();
  let gpi = 0;
  let j = 0;
  for (; gpi < groups.length; gpi++) {
    groupMap.set(gpi, 0);
  }

  // HEADER VALIDATION

  if (!data.fileName) {
    result.errors.push({
      type: 'custom',
      name: `fileName`,
      message: t('t3268', {
        field: t('t76')
      })
    });
  } else {
    // RULE: Max 8 chars and no special chars
    let regex_error_message = '';
    let regex_error = false;
    if (!fn_special_character_regex.test(data.fileName)) {
      regex_error_message += t('t3272');
      regex_error = true;
    }
    if (!max_fn_character_regex.test(data.fileName)) {
      if (regex_error) {
        regex_error_message += '\n';
      }
      regex_error_message += t('t3273', {
        lim: '8'
      });
      regex_error = true;
    }
    if (regex_error) {
      result.errors.push({
        type: 'custom',
        name: `fileName`,
        message: regex_error_message
      });
    }
  }

  // PARAMETER VALIDATION

  // RULE: Required Entries
  for (let i = 0; i < parameters.length; i++) {
    // Check required fields
    if (!parameters[i].address) {
      result.errors.push({
        type: 'custom',
        name: `parameters[${String(i)}].address`,
        message: t('t3268', {
          field: t('t3274')
        })
      });
    }
    if (!parameters[i].description) {
      result.errors.push({
        type: 'custom',
        name: `parameters[${String(i)}].description`,
        message: t('t3268', {
          field: t('t76')
        })
      });
    } else {
      // RULE: Max 20 chars and no special chars
      let regex_error_message = '';
      let regex_error = false;
      if (!special_character_regex.test(parameters[i].description)) {
        regex_error_message += t('t3272');
        regex_error = true;
      }
      if (!max_character_regex.test(parameters[i].description)) {
        if (regex_error) {
          regex_error_message += '\n';
        }
        regex_error_message += t('t3273', {
          lim: '20'
        });
        regex_error = true;
      }
      if (regex_error) {
        result.errors.push({
          type: 'custom',
          name: `parameters[${String(i)}].description`,
          message: regex_error_message
        });
      }
    }
    if (!parameters[i].storageType) {
      result.errors.push({
        type: 'custom',
        name: `parameters[${String(i)}].storageType`,
        message: t('t3268', {
          field: t('t3251')
        })
      });
    }
    if (!parameters[i].messageType) {
      result.errors.push({
        type: 'custom',
        name: `parameters[${String(i)}].messageType`,
        message: t('t3268', {
          field: t('t3256')
        })
      });
    }
    if (!parameters[i].swapMode) {
      result.errors.push({
        type: 'custom',
        name: `parameters[${String(i)}].swapMode`,
        message: t('t3268', {
          field: t('t3258')
        })
      });
    }

    // Value Limits
    if (parameters[i].min && parameters[i].max && parameters[i].min > parameters[i].max) {
      result.errors.push({
        type: 'custom',
        name: `parameters[${String(i)}].max`,
        message: t('t3269')
      });
    }
    if (parameters[i].def > parameters[i].max || parameters[i].def < parameters[i].min) {
      result.errors.push({
        type: 'custom',
        name: `parameters[${String(i)}].def`,
        message: t('t3270')
      });
    }

    // RULE: MessageType and StorageType combinations
    if (parameters[i].messageType && parameters[i].storageType) {
      const mt = parameters[i].messageType;
      const st = parameters[i].storageType;
      if (st.value !== StorageType.U1 && (mt.value === MessageType.COILS || mt.value === MessageType.DISCRETE_INPUT)) {
        result.errors.push({
          type: 'custom',
          name: `parameters[${String(i)}].storageType`,
          message: t('t3271', {
            reg: 'U1',
            mt: t('t3256')
          })
        });
      }
    }

    // RULE: Max 50 parameters per group
    if (parameters[i].groupIndex) {
      const gi = parameters[i].groupIndex;
      const groupCount = groupMap.get(gi);
      groupMap.set(gi, groupCount + 1);
    }
  }

  // ALARM VALIDATION

  // RULE: Required Entries
  for (let i = 0; i < alarms.length; i++) {
    if (!alarms[i].parameterIdx) {
      result.errors.push({
        type: 'custom',
        name: `alarms[${String(i)}].parameterIdx`,
        message: t('t3268', {
          field: t('t119')
        })
      });
    }
    // RULE: Max 20 chars and no special chars
    if (!alarms[i].name) {
      result.errors.push({
        type: 'custom',
        name: `alarms[${String(i)}].name`,
        message: t('t3268', {
          field: t('t76')
        })
      });
    } else {
      let regex_error_message = '';
      let regex_error = false;
      if (!label_special_character_regex.test(alarms[i].name)) {
        regex_error_message += t('t3272');
        regex_error = true;
      }
      if (!max_character_regex.test(alarms[i].name)) {
        if (regex_error) {
          regex_error_message += '\n';
        }
        regex_error_message += t('t3273', {
          lim: '40'
        });
        regex_error = true;
      }
      if (regex_error) {
        result.errors.push({
          type: 'custom',
          name: `alarms[${String(i)}].name`,
          message: regex_error_message
        });
      }
    }
    if (!alarms[i].bit) {
      result.errors.push({
        type: 'custom',
        name: `alarms[${String(i)}].bit`,
        message: t('t3268', {
          field: t('t944')
        })
      });
    }
  }

  // DYNLIST VALIDATION

  // RULE: Required Entries
  for (let i = 0; i < dynlists.length; i++) {
    if (!dynlists[i].name) {
      result.errors.push({
        type: 'custom',
        name: `dynlists[${String(i)}].name`,
        message: t('t3268', {
          field: t('t76')
        })
      });
    } else {
      // RULE: Max 20 chars and no special chars
      let regex_error_message = '';
      let regex_error = false;
      if (!label_special_character_regex.test(dynlists[i].name)) {
        regex_error_message += t('t3272');
        regex_error = true;
      }
      if (!label_max_character_regex.test(dynlists[i].name)) {
        if (regex_error) {
          regex_error_message += '\n';
        }
        regex_error_message += t('t3273', {
          lim: '40'
        });
        regex_error = true;
      }
      if (regex_error) {
        result.errors.push({
          type: 'custom',
          name: `dynlists[${String(i)}].name`,
          message: regex_error_message
        });
      }
    }
    if (!dynlists[i].list) {
      result.errors.push({
        type: 'custom',
        name: `dynlists[${String(i)}].list`,
        message: t('t3274')
      });
    }
  }

  // DYNLIST VALIDAION

  for (let dli = 0; dli < dynlists.length; dli++) {
    const labels = dynlists[dli].list.replace(/:|; /g, ',').split(',');
    for (const lb of labels) {
      if (!lb) {
        result.errors.push({
          type: 'custom',
          name: `dynlists[${String(dli)}].list`,
          message: t('t3268', {
            field: t('t3253')
          })
        });
      } else {
        let regex_error_message = '';
        let regex_error = false;
        if (!label_special_character_regex.test(lb.trim())) {
          regex_error_message += t('t3272');
          regex_error = true;
        }
        if (!label_max_character_regex.test(lb.trim())) {
          if (regex_error) {
            regex_error_message += '\n';
          }
          regex_error_message += t('t3273', {
            lim: '40'
          });
          regex_error = true;
        }
        if (regex_error) {
          result.errors.push({
            type: 'custom',
            name: `dynlists[${String(dli)}].list`,
            message: regex_error_message
          });
        }
      }
    }
    if (findDuplicates(labels)) {
      result.errors.push({
        type: 'custom',
        name: `dynlists[${String(dli)}].list`,
        message: t('t3284')
      });
    }
  }

  // TAG VALIDATION

  // RULE: Required Tags per form
  const tags_once_required = [];
  switch (device_group) {
    case DeviceCategory.Evaporation:
      tags_once_required.push(DeviceSpecificTag.EVAP_CASE_DEFROST);
      tags_once_required.push(DeviceSpecificTag.EVAP_CASE_SETPOINT1);
      tags_once_required.push(DeviceSpecificTag.EVAP_CASE_TEMP1);
      tags_once_required.push(DeviceSpecificTag.EVAP_CASE_DEFROST);
      tags_once_required.push(DeviceSpecificTag.EVAP_CASE_DEFROST_OFF);
      tags_once_required.push(DeviceSpecificTag.MAIN_SWITCH);
      break;
    case DeviceCategory.HVAC:
      tags_once_required.push(DeviceSpecificTag.MAIN_SWITCH);
      break;
  }

  // Tags that are required exactly once
  const missing_tags_once_required = [];
  for (const tor of tags_once_required) {
    const main_switch_params = parameters === null || parameters === void 0 ? void 0 : parameters.filter(p => {
      var _p$deviceSpecificTags;
      return ((_p$deviceSpecificTags = p.deviceSpecificTags) === null || _p$deviceSpecificTags === void 0 ? void 0 : _p$deviceSpecificTags.filter(tag => {
        return tag.value === tor;
      }).length) === 1;
    });
    if (main_switch_params.length === 0) {
      missing_tags_once_required.push(DeviceSpecificTag[tor]);
    }
  }
  if (missing_tags_once_required.length > 0) {
    let required_tag_list = '';
    j = 0;
    for (const rt of missing_tags_once_required) {
      required_tag_list += `'${rt}'`;
      j++;
      if (j < missing_tags_once_required.length) {
        required_tag_list += ', ';
      }
    }
    const required_tag_error = t('t3275', {
      taglist: required_tag_list
    });
    global_errors.push(required_tag_error);
  }

  // Tags which can not be duplicated
  const nTag = [];
  const tags_duplicated = [];
  for (const par of parameters) {
    if (par.deviceSpecificTags) {
      for (const dst of par.deviceSpecificTags) {
        if (!MultiTags.includes(dst.label)) {
          if (!nTag.find(k => k === dst.label)) {
            nTag.push(dst.label);
          } else if (!tags_duplicated.find(k => k === dst.label)) {
            tags_duplicated.push(dst.label);
          }
        }
      }
    }
  }
  if (tags_duplicated.length > 0) {
    let duplicated_tag_list = '';
    j = 0;
    for (const dt of tags_duplicated) {
      duplicated_tag_list += `'${dt}'`;
      j++;
      if (j < tags_duplicated.length) {
        duplicated_tag_list += ',  ';
      }
    }
    const duplicated_tag_error = t('t3300', {
      taglist: duplicated_tag_list
    });
    global_errors.push(duplicated_tag_error);
  }

  // GROUP VALIDATION

  const groups_with_too_many_parameters = [];
  for (gpi = 0; gpi < groups.length; gpi++) {
    // RULE: Name is required
    if (!groups[gpi].name) {
      result.errors.push({
        type: 'custom',
        name: `groups[${String(gpi)}].name`,
        message: t('t3268', {
          field: t('t76')
        })
      });
    } else {
      // RULE: No special chars and max length 20
      let regex_error_message = '';
      let regex_error = false;
      if (!special_character_regex.test(groups[gpi].name)) {
        regex_error_message += t('t3272');
        regex_error = true;
      }
      if (!max_character_regex.test(groups[gpi].name)) {
        if (regex_error) {
          regex_error_message += '\n';
        }
        regex_error_message += t('t3273', {
          lim: '20'
        });
        regex_error = true;
      }
      if (regex_error) {
        result.errors.push({
          type: 'custom',
          name: `groups[${String(gpi)}].name`,
          message: regex_error_message
        });
      }
    }
    if (groupMap.get(gpi) > 50) {
      groups_with_too_many_parameters.push(groups[gpi].name);
    }
  }
  if (groups_with_too_many_parameters.length > 0) {
    let required_group_list = '';
    for (gpi = 0; gpi < groups_with_too_many_parameters.length; gpi++) {
      required_group_list += groups_with_too_many_parameters[gpi];
      if (gpi < groups_with_too_many_parameters.length - 1) {
        required_group_list += ', ';
      }
    }
    const group_error = t('t3276', {
      grouplist: required_group_list,
      lim: '50'
    });
    global_errors.push(group_error);
  }

  // END OF VALIDATION

  if (result.errors.length > 0 || global_errors.length && !result.summary) {
    result.summary = t('t3277');
  }
  let geidx = 0;
  if (global_errors.length > 0) {
    result.explanation = '';
  }
  for (const ge of global_errors) {
    result.explanation += ge;
    if (geidx < global_errors.length - 1) {
      result.explanation += '\n';
    }
    geidx += 1;
  }
  return result;
}

export { validateEntries };
