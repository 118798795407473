/**
 * Get software version prefix
 *
 * @export
 * @param {string} version
 * @returns
 */
function getSoftwareVersionPrefix(version) {
  const versionPrefix = splitSoftwareVersion(version);
  return (versionPrefix === null || versionPrefix === void 0 ? void 0 : versionPrefix.length) >= 0 ? versionPrefix[0] : null;
}
function getSoftwareVersionMinor(version) {
  const versionPrefix = splitSoftwareVersion(version);
  return (versionPrefix === null || versionPrefix === void 0 ? void 0 : versionPrefix.length) > 0 ? versionPrefix[1] : null;
}
function splitSoftwareVersion(version) {
  if (version.includes('.')) {
    return version.split('.');
  }
  return null;
}

export { getSoftwareVersionMinor, getSoftwareVersionPrefix };
