import { fetchXMLData, XML_ACTION, XML_TAG } from '@danfoss/etui-sm-xml';

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * Upload certificate file
 *
 * @export
 * @param {string} _key
 * @param {User} user
 * @param {string} url
 * @returns
 */
async function fetchLoadCertFile(file, user, url) {
  const base64 = await readFileAsync(file);
  const b64 = base64.split('base64,').pop();
  const data = await fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.UPLOAD_CERT_FILE,
      done: '1',
      index: 1,
      filename: file.name,
      offset: file.size
    },
    items: [{
      tag: XML_TAG.B64,
      value: b64
    }]
  });
  return data;
}

export { fetchLoadCertFile };
