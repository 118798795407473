import { cleanUniqueTimeout, setUniqueTimout } from '../../utils/unique-timer.js';
import 'pako';

const PATTERN_FILL_COLOR = /(fillColor=#.*?;)/gi;
const BLINK_HIGHLIGHT_COLOR = '#f5f242';
const CELL_LABEL_LINK = 'Go to link';
class GraphState {
  constructor(mxCell, graph) {
    this.mxCell = mxCell;
    this.mxGraph = graph;
    this.appliedActions = [];
    this.backups = {};
    this.initBackups();
  }
  initBackups() {
    this.setBackup('change_text', this.mxCell.getValue() || '');
    this.setBackup('shape_hide', this.mxCell.isVisible());
    this.setBackup('change_color', this.getMxCellStyleFillColor());
    this.setBackup('shape_blink', 'unset_blink');
    this.setBackup('link_action', null);
  }
  setBackup(action, value) {
    this.backups[action] = value;
  }
  getBackupValue(action) {
    return this.backups[action];
  }
  setAppliedAction(action) {
    if (!this.appliedActions.includes(action)) {
      this.appliedActions.push(action);
    }
  }
  unsetAppliedAction(action) {
    const index = this.appliedActions.indexOf(action);
    if (index !== -1) {
      this.appliedActions.splice(index, 1);
    }
  }
  getActionDiffs(actualActions) {
    return this.appliedActions.filter(action => !actualActions.includes(action));
  }
  setState(mappings, conditions, dataValue) {
    const actualActions = mappings.filter(mapping => this.isActionWillRun(mapping, conditions, dataValue)).map(_ref => {
      let {
        action,
        value
      } = _ref;
      this.setAppliedAction(action);
      this.actionSwitcher(action, value || dataValue);
      return action;
    });
    this.getActionDiffs(actualActions).forEach(action => {
      this.unsetAppliedAction(action);
      const value = this.getBackupValue(action);
      if (value !== undefined) {
        this.actionSwitcher(action, value);
      }
    });
    return this;
  }
  isActionWillRun(mapping, conditions, dataValue) {
    const condition = mapping.state ? conditions.find(c => {
      var _mapping$state;
      return c.id === ((_mapping$state = mapping.state) === null || _mapping$state === void 0 ? void 0 : _mapping$state.id);
    }) : null;
    if (mapping.isHidden()) {
      return false;
    }
    if (!mapping.shapeIdentifier) {
      return false;
    }
    if (!this.isCurrentMxCellShapeBy(mapping.shapeBy, mapping.shapeIdentifier)) {
      return false;
    }
    if (!condition) {
      return false;
    }
    if (!condition.isFulfilled(dataValue)) {
      return false;
    }
    return true;
  }
  isCurrentMxCellShapeBy(shapeBy, identifier) {
    if (shapeBy === 'id') {
      return this.mxCell.id === identifier;
    }
    return false;
  }
  actionSwitcher(action, actionValue) {
    switch (action) {
      case 'change_text':
        this.setTextLabel(actionValue);
        break;
      case 'shape_hide':
        this.hideMxCell(actionValue);
        break;
      case 'change_color':
        this.fillMxCell(actionValue);
        break;
      case 'shape_blink':
        this.blinkMxCell(actionValue);
        break;
      case 'link_action':
        this.setMxCellLink(actionValue);
        break;
      default:
        console.log(`GraphState actionSwitcher: can't find action: ${action}`);
    }
  }
  setTextLabel(text) {
    var _state$style;
    const state = this.mxGraph.getGraph().view.getState(this.mxCell);
    if (state && (state === null || state === void 0 ? void 0 : (_state$style = state.style) === null || _state$style === void 0 ? void 0 : _state$style.shape) === 'connector') {
      let style = this.mxCell.getStyle();
      const verticalAlignBottom = 'verticalAlign=bottom;';
      if (!style.includes(verticalAlignBottom)) {
        style += verticalAlignBottom;
      }
      this.mxCell.setStyle(style);
    }
    this.mxGraph.setLabelCell(this.mxCell, String(text));
  }
  hideMxCell(actionValue) {
    if (actionValue === void 0) {
      actionValue = false;
    }
    actionValue = typeof actionValue === 'string' ? false : actionValue;
    this.mxCell.setVisible(actionValue);
  }
  fillMxCell(color) {
    if (color !== undefined) {
      let fillColorParam = '';
      if (color !== '') {
        fillColorParam = `fillColor=${color};`;
      }
      let style = this.mxCell.getStyle();
      if (PATTERN_FILL_COLOR.test(style)) {
        style = style.replace(PATTERN_FILL_COLOR, fillColorParam);
      } else {
        style += fillColorParam;
      }
      this.mxCell.setStyle(style);
    }
  }
  getMxCellStyleFillColor() {
    const style = this.mxCell.getStyle();
    const fillColorStyle = PATTERN_FILL_COLOR.exec(style);
    if (fillColorStyle === null) {
      return '';
    }
    const color = fillColorStyle[0].split('=')[1];
    return color.slice(0, -1);
  }
  blinkMxCell(value) {
    const timeoutId = `blink-action_${this.mxCell.getId()}`;
    let timerBlinkOn;
    let timerBlinkOff;
    if (value === 'unset_blink') {
      this.mxGraph.unHighlightMxCell(this.mxCell);
      cleanUniqueTimeout(timeoutId);
      return;
    }
    if (!this.mxCell.blink) {
      this.mxCell.blink = true;
      cleanUniqueTimeout(timeoutId);
      const _self = this;
      const ms = Number.isInteger(+value) ? +value : 500;
      const blinkOn = () => {
        _self.mxGraph.highlightMxCell(_self.mxCell, BLINK_HIGHLIGHT_COLOR);
        if (timerBlinkOff) {
          cleanUniqueTimeout(timerBlinkOff);
        }
        timerBlinkOn = setUniqueTimout(timeoutId, ms, blinkOff);
      };
      const blinkOff = () => {
        _self.mxGraph.unHighlightMxCell(_self.mxCell);
        if (timerBlinkOn) {
          cleanUniqueTimeout(timerBlinkOn);
        }
        timerBlinkOff = setUniqueTimout(timeoutId, ms, blinkOn);
      };
      blinkOn();
    }
  }
  setMxCellLink(url) {
    if (url === null) {
      this.mxCell.hasLinkAction = false;
      this.mxCell.setValue('');
      this.mxCell.linkActionUrl = '';
      this.mxGraph.unsetMouseListener();
      return;
    }
    if (!this.mxCell.hasLinkAction) {
      this.mxCell.hasLinkAction = true;
      this.mxCell.setValue(CELL_LABEL_LINK);
      this.mxCell.linkActionUrl = url;
      this.mxGraph.setMouseListener();
    }
  }
}

export { GraphState };
