export const getHelpText = (tabItems: tabItem[], index: string) => {
  let rawHelpTexts: string = '';

  const helpTextTabItem = tabItems?.find(tabItem => tabItem?.index === index);
  if (helpTextTabItem) {
    Object.keys(helpTextTabItem)
      .filter(tabItemKey => tabItemKey?.includes('help'))
      .forEach(helpText => {
        rawHelpTexts += `${helpTextTabItem[helpText]} `;
      });
  }

  const helpTexts = rawHelpTexts?.split(' ');

  helpTexts?.forEach((helpText, i) => {
    if (helpText?.includes('wiz000')) {
      helpTexts?.splice(i, 1);
    }
  });

  const wizRemovedText = helpTexts?.join(' ');

  return {
    helpText: wizRemovedText?.split('$')?.join('\n\n'),
    label: helpTextTabItem?.label,
  };
};

export type tabItem = {
  authtype: string;
  combo: string;
  comboreserve: string;
  configuretype: string;
  editable: string;
  help1?: string;
  help2?: string;
  help3?: string;
  help4?: string;
  help5?: string;
  help6?: string;
  help7?: string;
  index?: string;
  label?: string;
  needcombinecase: string;
  optimization: string;
};
