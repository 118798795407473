import * as React from 'react';
import { ConfigurationTabItem } from '@danfoss/etui-sm-xml/';

export type ModalSettings = {
  modalName: string;
  scrollTop: number;
};

export interface ConfigurationModalContextProps {
  openModals: string[];
  toggleModals: (modalName: string) => void;
  clearAndSetModals: (modalName: string) => void;
  activeTab: { [key: string]: ConfigurationTabItem };
  onSetActiveTab: (key: string, tab: ConfigurationTabItem) => void;
  modalScrollSettings: { [key: string]: number };
  handleModalScrollSettings: (modalSettings: ModalSettings) => void;
  removeModalScrollSettings: (modalName: string) => void;
}

const ConfigurationModalContext = React.createContext(
  null as ConfigurationModalContextProps | null,
);

export { ConfigurationModalContext };
