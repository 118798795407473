import { mapBinaryStringToBlob } from '../../../utils/map-binary-string-to-blob.js';
import { saveAs } from 'file-saver';

const REPORT_NAME = 'haccp';
async function fetchExportHaccpReport(csvFile, storename, date) {
  const blob = mapBinaryStringToBlob(csvFile.fileContent);
  saveAs(blob, `${REPORT_NAME}-${storename ?? ''}-${date.toDateString()}.csv`);
}

export { fetchExportHaccpReport };
