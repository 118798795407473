import { useXmlResource } from '@danfoss/etui-sm/context/app';
import { useAuth } from '@danfoss/etui-sm/context/auth';
import useSWR from 'swr';
import { fetchDeviceListData } from '../modules/RefrigLayoutModal/actions';

function getDeviceModelList(type: number) {
  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();

  const { data: deviceModelList } = useSWR(
    () => [xmlBackendURL, user, type],
    fetchDeviceListData,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  return {
    deviceModelList,
  };
}

export { getDeviceModelList };
