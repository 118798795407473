import { SM800A_default_port, SM800_default_port } from '../../constants/url-constants.js';

function getUnitNetworkByUnitUrl(isSM800A, url, networkList) {
  var _urlParts$, _urlParts$2;
  const urlParts = url.match(/^https?:\/\/([^:/?#]+)(?::(\d+))?/);
  const urlIp = (urlParts === null || urlParts === void 0 ? void 0 : (_urlParts$ = urlParts[1]) === null || _urlParts$ === void 0 ? void 0 : _urlParts$.toString()) || '';
  const urlPort = (urlParts === null || urlParts === void 0 ? void 0 : (_urlParts$2 = urlParts[2]) === null || _urlParts$2 === void 0 ? void 0 : _urlParts$2.toString()) || '';
  const defaultPort = isSM800A ? SM800A_default_port : SM800_default_port;
  return networkList.find(network => {
    return network.ip === urlIp && (network.port ? network.port === urlPort || !urlPort && network.port === defaultPort : true);
  });
}

export { getUnitNetworkByUnitUrl };
