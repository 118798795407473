import * as React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from 'react-router-dom';
import useSWR from 'swr';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Div } from '@danfoss/etui-system-elements';
import { Unit } from '@danfoss/etui-sm-xml';
import { PageNotFound, useXmlResource, useAuth } from '@danfoss/etui-sm';
import {
  ConfigurationTabByUnitResponse,
  fetchConfigurationTabByUnit,
} from '../../actions';
import { useConfiguration, useConfigurationMenu } from '../../context';
import { ConfigurationItemContentList } from '../ConfigurationItemContentList';
import { ConfigurationItemContentMenu } from '../ConfigurationItemContentMenu';
import {
  findCurrentMenuItemWithLevel,
  getTabByRootMenuIdOrMenuId,
} from './utils';

interface ConfigurationItemContentProps {
  unit: Unit;
  level: number;
  InfoElement?: any;
  onPasswordUpdatedForCurrentUser: (
    passwordUpdated: boolean,
    updatedPassword: string,
  ) => void;
  updateTime?: (menuId: string, currentTimeMs: number) => void;
}

const ConfigurationItemContent = React.memo(
  ({
    unit,
    level,
    InfoElement,
    onPasswordUpdatedForCurrentUser,
    updateTime,
  }: ConfigurationItemContentProps) => {
    const {
      location: { pathname },
    } = useHistory();

    const { url } = useRouteMatch();
    const { menuId } = useParams<{ menuId: string }>();
    const { user } = useAuth();
    const { url: xmlBackendURL } = useXmlResource();

    const {
      route: baseRoute,
      menuLevelById,
      rootMenuId,
      itemByTabRenderers,
      menuItemsById,
      subMenuItemsById,
      onSetMenuItems,
      onSetSubMenuItems,
      onSetMenuLevel,
      onSetMultiPage,
      onSetMenugroups,
      onSetSubgroupnames,
      cachedDevices,
    } = useConfiguration();

    const { device, page, deviceGroup, deviceSubgroup } =
      useConfigurationMenu();

    const [tabId, tabOrder] = menuId?.split('-');
    const thisTabItemRenderer =
      itemByTabRenderers[tabOrder ? `${tabId}-${tabOrder}` : tabId];

    const isLastInRecursion =
      pathname?.lastIndexOf(menuId) === pathname?.length - menuId?.length;

    /**
     * start
     *
     * This basically done for request attribiutes:
     * old_cfgtype as secondLevelMenuItem.configuretype
     * and
     * configuretype as closestParentTab.configuretype
     * which are needed for correct request of tabs and list items.
     */

    const topLevelMenuId = findCurrentMenuItemWithLevel(
      menuLevelById,
      pathname,
      0,
    );

    const closestParentMenuId = findCurrentMenuItemWithLevel(
      menuLevelById,
      pathname,
      level - 1,
    );

    const secondLevelMenuItem = menuItemsById[topLevelMenuId]?.find(({ id }) =>
      new RegExp(`/${id}`).test(pathname),
    );

    const closestParentTab = menuItemsById[closestParentMenuId]?.find(
      ({ id }) => id === menuId,
    );

    /**
     * end
     */

    const tab = React.useMemo(() => {
      return (
        getTabByRootMenuIdOrMenuId(
          baseRoute,
          rootMenuId,
          menuId,
          pathname,
          menuItemsById,
          subMenuItemsById,
        ) || subMenuItemsById[menuId]?.find(submenu => submenu.id === menuId)
      );
    }, [
      baseRoute,
      rootMenuId,
      menuId,
      pathname,
      menuItemsById,
      subMenuItemsById,
    ]);

    const basicRequest = [
      xmlBackendURL,
      unit,
      menuId,
      user,
      cachedDevices,
      '0',
      '1',
      device ? parseInt(device.bpidx, 10) - 1 : '0',
      device ? device.stype : null,
      device ? device.nodetype : null,
      device ? device.node : null,
      deviceGroup || '0',
      deviceSubgroup || '0',
      page,
      tab ? tab.combo : device ? device.combo : '0',
      closestParentTab
        ? closestParentTab.configuretype
        : tab
        ? tab.configuretype
        : null,
      device ? device.arg1 : null,
      device ? device.arg2 : null,
      device ? device.arg3 : null,
      device ? device?.online : null,
    ];

    const isInfoPage = !!InfoElement;

    const { data, error } = useSWR<ConfigurationTabByUnitResponse>(
      isInfoPage || !tabId
        ? null
        : level === 0
        ? basicRequest
        : secondLevelMenuItem?.configuretype
        ? [...basicRequest, secondLevelMenuItem?.configuretype]
        : null,
      fetchConfigurationTabByUnit,
      {
        revalidateOnFocus: false,
        shouldRetryOnError: true,
      },
    );

    const tabs = data?.tabs || [];
    const subtabs = data?.subtabs || [];
    const groupnames = data?.groupnames || [];
    const subgroupnames = data?.subgroupnames || [];
    const list = data?.list;
    const multipage = data?.multipage;

    useDeepCompareEffect(() => {
      onSetMenuLevel(menuId, level);
      onSetMultiPage(menuId, multipage);
      onSetMenuItems(menuId, tabs);
      onSetSubMenuItems(menuId, subtabs);
      onSetMenugroups(menuId, groupnames);
      onSetSubgroupnames(menuId, subgroupnames);
    }, [menuId, multipage, subtabs, tabs, groupnames, subgroupnames]);

    const handlePasswordUpdatedForCurrentUser = (
      passwordUpdated: boolean,
      updatedPassword: string,
    ) => {
      onPasswordUpdatedForCurrentUser(passwordUpdated, updatedPassword);
    };

    const isFailed = !isInfoPage && !list && error;

    if (menuId && !tabId) return <PageNotFound />;

    return (
      <>
        <Switch>
          <Route path={`${url}/:menuId`}>
            <ConfigurationItemContent
              unit={unit}
              InfoElement={InfoElement}
              level={level + 1}
              onPasswordUpdatedForCurrentUser={
                handlePasswordUpdatedForCurrentUser
              }
            />
          </Route>
        </Switch>

        {isFailed || isInfoPage ? (
          <Div>
            <InfoElement selectedUnit={unit} />
          </Div>
        ) : tab && isLastInRecursion ? (
          <Div height="100%">
            <ConfigurationItemContentMenu
              tab={tab}
              unit={unit}
              menuId={menuId}
            />
            <ConfigurationItemContentList
              menuId={menuId}
              unit={unit}
              tab={tab}
              isconfigure="1"
              itemRenderer={thisTabItemRenderer}
              secondLevelMenuItems={secondLevelMenuItem}
              onPasswordUpdatedForCurrentUser={
                handlePasswordUpdatedForCurrentUser
              }
              updateTime={updateTime}
            />
          </Div>
        ) : null}
      </>
    );
  },
);

export { ConfigurationItemContent };
