import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import {
  Button,
  ButtonProps,
  ClickOutside,
  IconProps,
  icons,
  ModalProps,
} from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { Modal } from '@danfoss/etui-core/Modal';
import { uniqueId } from 'lodash';
import { FloatingModalButtonProps } from './FloatingModalButton.types';
import { getFloatingModalPosition } from './utils';

export const FloatingModalButton: React.FC<FloatingModalButtonProps> = ({
  buttonProps,
  modalProps,
  modalHeaderProps,
  title,
  modalChildren,
  children,
  height = 400,
  placement = 'bottom',
  width = 350,
}) => {
  const theme = useTheme();
  const parentRef = React.createRef<HTMLDivElement>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const parentId = React.useMemo(() => uniqueId('floatingModalButton'), []);
  const [{ top, left, modalWidth, modalHeight }, setModalPlacement] =
    React.useState({
      top: 0,
      left: 0,
      modalWidth: width,
      modalHeight: height,
    });

  const onClickOutside = () => {
    handleClose();
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateModalPosition);
    return () => window.removeEventListener('resize', updateModalPosition);
  }, []);

  const handleClose = () => setIsModalOpen(false);
  const handleAfterOpen = () => {};

  const onButtonClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const updateModalPosition = () => {
    if (parentRef.current && isModalOpen) {
      setModalPlacement(
        getFloatingModalPosition(
          parentRef.current,
          modalWidth,
          height,
          placement,
          theme,
        ),
      );
    }
  };

  React.useEffect(updateModalPosition, [isModalOpen]);

  const getModalParentSelector = () => {
    return document.getElementById(parentId);
  };

  const defaultModalProps: ModalProps = {
    ...modalProps,
    isOpen: isModalOpen,
    onClose: handleClose,
    onAfterOpen: handleAfterOpen,
    style: {
      ...modalProps?.style,
      overlay: {
        ...modalProps?.style?.overlay,
        width: modalWidth,
        height: 'fit-content',
        maxHeight: modalHeight,
        position: 'absolute',
        zIndex: 10,
        background: 'none',
        top,
        left,
      },
      content: {
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        border: `1px solid ${theme.palette.black[20]}`,
        ...modalProps?.style?.content,
        width: '100%',
        height: modalHeight,
      },
    },
    header: (
      <Modal.Header
        icon={{
          glyph: icons.HELP_CIRCLE,
          size: 32,
          styles: {
            root: {
              mr: 0,
            },
          },
        }}
        iconColor={theme.palette.text.primary}
        title={title}
        styles={{
          root: {
            borderBottom: `1px solid ${theme.palette.black[20]}`,
            ...modalHeaderProps?.styles?.root,
            textAlign: 'left',
            display: 'inline-flex',
            alignItems: 'center',
            p: `${theme.spacing.sm}px 35px ${theme.spacing.sm}px ${theme.spacing.sm}px`,
          },
          header: {
            ...modalHeaderProps?.styles?.header,
            title: {
              ...modalHeaderProps?.styles?.header?.title,
              textAlign: 'left',
              textTransform: 'uppercase',
              width: '100%',
              fontWeight: 500,
              fontSize: '1.175rem',
              letterSpacing: '0.15em',
              overflow: 'hidden',
              textOverflow: 'ellipsis !important',
              whiteSpace: 'nowrap !important',
            },
          },
        }}
        {...modalHeaderProps}
      />
    ),
    animationTiming: 200,
    parentSelector: getModalParentSelector,
  };

  const defaultButtonProps: ButtonProps = {
    iconOnly: true,
    testId: 'HelpPanelButton',
    ...buttonProps,
    styles: {
      ...buttonProps?.styles,
      root: {
        height: '100%',
        ...buttonProps?.styles?.root,
      },
    },
    iconProps: {
      glyph: icons.HELP_CIRCLE,
      size: 32,
      ...buttonProps?.iconProps,
    } as IconProps,
    onClick: onButtonClick,
  };

  return (
    <ClickOutside onClickOutside={onClickOutside}>
      <Div elRef={parentRef}>
        <Button {...defaultButtonProps}>{children}</Button>
        <Div id={parentId} style={{ position: 'relative' }}>
          {isModalOpen && <Modal {...defaultModalProps}>{modalChildren}</Modal>}
        </Div>
      </Div>
    </ClickOutside>
  );
};
