import React from 'react';
import { Div, Span } from '@danfoss/etui-system-elements';
import { Button, icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { lighten } from '@danfoss/etui-colors';

export interface CompressorsFlowAddPackProps {
  handleOnAddPack: () => void;
  disabled?: boolean;
}
export const CompressorsFlowAddPack = ({
  handleOnAddPack,
  disabled = false,
}: CompressorsFlowAddPackProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Div
      style={{
        display: 'inline-block',
      }}
      testId="compressor-add-pack-div"
    >
      <Span>
        <Button
          disabled={disabled}
          iconOnly={true}
          iconProps={{ glyph: icons.ADD, isIconLabel: true }}
          onClick={handleOnAddPack}
        />
      </Span>
      <Span
        color={
          disabled
            ? lighten(theme.palette.text.primary, 0.75)
            : theme.palette.text.secondary
        }
      >
        {t('t3369')}
      </Span>
    </Div>
  );
};
