import * as React from 'react';
import { DateRangePicker, DateRangePickerProps } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { AmPm, timeToMs } from '../utils/history-utils';
import { TimeInput } from './TimeInput';

function getInitialTime(value: Date, timeFormat: '12' | '24') {
  const date = new Date(value);
  let defaultAmPm;
  let defaultHours = date.getHours();
  if (timeFormat === '12') {
    defaultAmPm = defaultHours >= 12 ? AmPm.PM : AmPm.AM;
    defaultHours %= 12;
    defaultHours = defaultHours || 12;
  }
  const defaultMinutes = date.getMinutes().toString().padStart(2, '0');

  return [
    `${
      timeFormat === '12'
        ? defaultHours
        : defaultHours.toString().padStart(2, '0')
    }:${defaultMinutes}`,
    defaultAmPm,
  ];
}

/**
 *
 * Simplified and more generic version of the same component from configuration page
 */
export function DateTimeInput({
  value,
  name,
  label,
  onChange,
  timeFormat = '24',
  dateFormat = 'DD/MM/YY',
  styles = {},
}: {
  value: Date;
  name: string;
  label: string;
  onChange: (d: Date) => void;
  dateFormat: string;
  timeFormat: '12' | '24';
  styles: DateRangePickerProps['styles'];
}) {
  const theme = useTheme();
  const [defaultTime, defaultAmPm] = getInitialTime(value, timeFormat);
  const startOfDay = new Date(value);
  startOfDay.setHours(0, 0, 0, 0);

  function handleOnChange({ startDate: dateValue, value: timeValue }) {
    const date = dateValue || startOfDay;
    date.setHours(0, 0, 0, 0);
    const time = timeToMs(timeValue || defaultTime);
    onChange(new Date(date.getTime() + time));
  }

  return (
    <Div display="flex">
      <DateRangePicker
        dateFormat={dateFormat}
        name={`datetime-date-${name}`}
        startDate={startOfDay}
        disableRange={true}
        label={label}
        onChange={handleOnChange}
        styles={{
          root: { flex: '1 1 0', ...styles.root },
          input: {
            height: '42px',
            fontSize: theme.typography.fontSizeBase,
          },
        }}
      />
      <TimeInput
        hourFormat={timeFormat}
        name={`datetime-time-${name}`}
        size="small"
        defaultValue={defaultTime}
        defaultAmPm={defaultAmPm}
        onTimeChange={handleOnChange as any}
        styles={{
          root: {
            ml: `${theme.spacing.xxs}px`,
          },
          input: {
            width: '100px',
          },
        }}
      />
    </Div>
  );
}
