import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { IconProps, TileButton } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';

export function QuickSetupTileButton({
  title,
  icon,
  onClick,
  m,
  disabled = false,
}: {
  title: string;
  icon: IconProps['glyph'];
  onClick: Function;
  m?: string;
  disabled?: boolean;
}) {
  const theme = useTheme();
  return (
    <Div
      style={{ backgroundColor: theme.palette.common.white }}
      width="120px"
      height="110px"
      m={m || `${theme.spacing.md}px`}
    >
      <TileButton
        isDisabled={disabled}
        styles={{
          root: {
            cursor: 'pointer',
            fontSize: theme.typography.fontSizeXSmall,
            color: theme.palette.text.primary,
          },
        }}
        onClick={() => onClick()}
        title={title}
        icon={{
          glyph: icon,
          size: 48,
          styles: {
            root: {
              color: theme.palette.grey[60],
            },
          },
        }}
      />
    </Div>
  );
}
