import { ProxyConfig } from '@danfoss/etui-sm-xml';

export function getResourceUrl(
  address: string,
  request: string,
  proxyConfig?: ProxyConfig,
) {
  const { proxyAddress, token, deviceGuid, companyGuid } = proxyConfig || {};
  return {
    url: proxyAddress
      ? `${proxyAddress}/sm-xmlv2?q=${window.btoa(
          JSON.stringify({ request, address, deviceGuid, companyGuid }),
        )}`
      : `${address}/${request}`,
    headers: proxyAddress && { Authorization: `Bearer ${token}` },
  };
}
