import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { SvWInfoPage } from 'pages/InfoPage/components/SvWInfoPage';

const SiteInfoPage: React.FC = () => (
  <Div>
    <SvWInfoPage selectedUnit={null} />
  </Div>
);

export default SiteInfoPage;
