import { Unit } from '@danfoss/etui-sm-xml';
import { getArray } from './get-array';

function getAppNameByUnitInternet(u: Unit) {
  const { unit_internet, unit_addr } = u;
  const { name, ip, port } = getArray(unit_internet).find(
    ({ addr }) => addr === unit_addr,
  );

  return name || `${ip}:${port}`;
}

export function getAppNameByUnits(units: Unit[] = []) {
  const [masterUnit] = units;
  if (!masterUnit) {
    return '';
  }
  const { store_name } = masterUnit;

  return store_name || getAppNameByUnitInternet(masterUnit);
}
