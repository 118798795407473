import {
  i18n,
  languageDetector,
  initReactI18next,
  initConfig,
} from '@danfoss/etui-sm-i18n';

i18n.use(languageDetector).use(initReactI18next).init(initConfig);

export default i18n;
