import React from 'react';
import { Icon, IconGlyph, Tooltip } from '@danfoss/etui-core';

interface DeviceFlowStatusCntIconProps {
  colorTheme: string;
  iconGlyph: IconGlyph;
  message: string;
  isLastRow: boolean;
}
export const DeviceFlowStatusCntIcon = ({
  colorTheme,
  iconGlyph,
  message,
  isLastRow,
}: DeviceFlowStatusCntIconProps) => {
  return (
    <Tooltip
      message={message}
      placement={isLastRow ? 'right' : 'bottom'}
      warning={!isLastRow}
      warningWidth="100%"
    >
      <Icon
        size={24}
        styles={{
          root: {
            color: colorTheme,
          },
        }}
        glyph={iconGlyph}
      />
    </Tooltip>
  );
};
