import * as React from 'react';
import { RefrigCopyContext } from '../RefrigCopyContext';

export function useRefrigCopy() {
  const context = React.useContext(RefrigCopyContext);
  if (!context) {
    throw new Error(`useRefrigCopy must be used within a RefrigCopyProvider`);
  }
  return context;
}
