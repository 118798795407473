import { icons, SelectInputOption } from '@danfoss/etui-core';
import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { SourceAlarmTableContent } from '../../types/RefrigCopy.types';
import { LayoutFlowTitle } from '../../../RefrigLayoutModal/components/LayoutContent/LayoutFlowTitle';
import { SourceAlarmFlowTable } from './SourceAlarmFlowTable';

export interface SourceAlarmFlowPageProps {
  handleOnLevelChange: (
    selectedOption: SelectInputOption,
    content: SourceAlarmTableContent,
    selectedRowIndex: number,
  ) => void;
  handleOnActionChange: (
    selectedOption: SelectInputOption,
    content: SourceAlarmTableContent,
    selectedRowIndex: number,
  ) => void;
  handleOnEnableToggle: (isChecked: boolean) => void;
}

export const SourceAlarmFlowPage = ({
  handleOnLevelChange,
  handleOnActionChange,
  handleOnEnableToggle,
}: SourceAlarmFlowPageProps) => {
  const { t } = useTranslation();
  return (
    <Div mt="10px">
      <LayoutFlowTitle
        title={t('t3405')}
        subTitle={t('t3406')}
        glyph={icons.ALARM_URGENT}
      />
      <SourceAlarmFlowTable
        handleOnLevelChange={handleOnLevelChange}
        handleOnActionChange={handleOnActionChange}
        handleOnEnableToggle={handleOnEnableToggle}
      />
    </Div>
  );
};
