import { useContext, useState, useEffect } from 'react';

function createUseFeatureFlagHook(genericContext) {
  return flag => {
    const context = useContext(genericContext);
    if (!context) throw new Error('Not inside a featurehub context');
    const {
      client,
      localClient,
      isReady
    } = context;
    const [value, setValue] = useState((client === null || client === void 0 ? void 0 : client.feature(flag).value) || false);
    if (!isReady) throw new Error('Featurehub is not ready');
    useEffect(() => {
      if (!client) return () => {};
      const listener = stateHolder => setValue(stateHolder.value);
      const featureFlag = client.feature(flag);
      if (!featureFlag.exists) {
        // eslint-disable-next-line no-console
        console.warn(`Feature Flag "${flag}" is missing from FeatureHub`);
        const localFeatureFlag = localClient.feature(flag);
        setValue(localFeatureFlag.value);
        return () => {};
      }
      featureFlag.addListener(listener);
      setValue(featureFlag.value);
      return () => {
        featureFlag.removeListener(listener);
      };
    }, [client, flag]);
    return value;
  };
}

export { createUseFeatureFlagHook };
