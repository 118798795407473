import * as React from 'react';
import { H4, ProgressIndicator } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

export function Progress({
  value,
  indeterminateProgress,
}: {
  value: number;
  indeterminateProgress: boolean;
}) {
  const { t } = useTranslation();

  return (
    <S.ProgressIndicatorDiv>
      <H4>{t('t439')}</H4>
      <Div>
        {indeterminateProgress ? (
          <ProgressIndicator value={0} intermediateMode={true} content={null} />
        ) : (
          <ProgressIndicator radius={90} value={value} />
        )}
      </Div>
    </S.ProgressIndicatorDiv>
  );
}
