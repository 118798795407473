import { LocalClientContext, FeatureValueType } from 'featurehub-javascript-client-sdk';

function createLocalClientContext(context) {
  const featureMapper = _ref => {
    let [key, value] = _ref;
    return {
      id: key,
      key,
      type: FeatureValueType.Boolean,
      value
    };
  };
  return new LocalClientContext({
    id: 'local',
    features: Object.entries(context).map(featureMapper)
  });
}

export { createLocalClientContext };
