import { XML_DEVICE_LIST } from '@danfoss/etui-sm-xml';
import { DeviceType } from '@danfoss/etui-sm/types';

function getXmlDeviceListByDeviceType(type: DeviceType): XML_DEVICE_LIST {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'refrig':
      return XML_DEVICE_LIST.REFRIG;
    case 'hvac':
      return XML_DEVICE_LIST.HVAC;
    case 'lights':
      return XML_DEVICE_LIST.LIGHTS;
    case 'meter':
      return XML_DEVICE_LIST.METER;
    case 'misc':
      return XML_DEVICE_LIST.MISC;
  }
}

export { getXmlDeviceListByDeviceType };
