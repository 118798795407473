import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { Steps } from '@danfoss/etui-core';
import { ContentSpinner } from '@danfoss/etui-sm';
import {
  XML_HVAC_LAYOUT_TAB_INDEX,
  XML_TABLE_NAMES,
} from '@danfoss/etui-sm-xml';
import { HVACSteps, HVAC_STEP } from '../../types';
import { useRefrigLayoutContent } from '../../../../../hooks';
import { getHvacLayoutSteps } from '../../../../../utils';
import { HVACFinalContent } from '../HVACLayoutFinalContent';
import { useRefrigLayout } from '../../../../RefrigLayoutModal/context';
import { DiscoveryFlowContent } from '../../../../RefrigLayoutModal/components/DiscoveryFlowContent';
import { NetworkContentView } from '../../../../RefrigLayoutModal/components/NetworkFlowContent';

export function HVACLayoutContent() {
  const {
    activeStepNumber,
    setRefrigSteps,
    step,
    setNetworkListData,
    setIsHvacLayout,
  } = useRefrigLayout();

  const { t } = useTranslation();

  const hvacSteps: HVACSteps[] = getHvacLayoutSteps(t);

  const {
    tableContentData,
    networkTableAddress: hvacTableaddress,
    isValidating,
  } = useRefrigLayoutContent(
    HVAC_STEP.NETWORK,
    XML_TABLE_NAMES.TABLE_HVAC_SCAN,
  );

  React.useEffect(() => {
    setRefrigSteps(hvacSteps);
    setIsHvacLayout(true);
  }, []);

  React.useEffect(() => {
    if (tableContentData?.length) {
      setNetworkListData(tableContentData);
    }
  }, [tableContentData]);

  return (
    <>
      {isValidating ? (
        <Div>
          <ContentSpinner size={1} transparent={true} />
        </Div>
      ) : (
        <>
          {hvacSteps && (
            <Steps activeStep={activeStepNumber}>
              {hvacSteps?.map(s => (
                <Steps.Step
                  key={s?.stepNumber}
                  {...s}
                  isCompleted={s?.stepNumber < activeStepNumber}
                />
              ))}
            </Steps>
          )}
          {tableContentData && step === +XML_HVAC_LAYOUT_TAB_INDEX.NETWORK ? (
            <NetworkContentView
              title={t('t3355')}
              subTitle={t('t3356')}
              helpText={t('t3370')}
              tableAddress={hvacTableaddress}
            />
          ) : step === +XML_HVAC_LAYOUT_TAB_INDEX.DISCOVERY ? (
            <DiscoveryFlowContent isHvacDiscovery={true} />
          ) : step === +XML_HVAC_LAYOUT_TAB_INDEX.HVAC ? (
            <HVACFinalContent />
          ) : null}
        </>
      )}
    </>
  );
}
