import { a as _objectSpread2 } from '../../../_rollupPluginBabelHelpers-6901a291.js';
import { useContext } from 'react';
import { GraphicViewerContext, GraphicViewerDispatchContext } from './GraphicViewerContext.js';

function useGraphicViewer() {
  const state = useContext(GraphicViewerContext);
  const dispatch = useContext(GraphicViewerDispatchContext);
  if (!state) {
    throw new Error(`useGraphicViewer must be used within a GraphicViewerProvider`);
  }
  const setPanelOpen = payload => {
    dispatch({
      type: 'TOGGLE_PANEL',
      payload
    });
  };
  const setPanelOpenItems = payload => {
    dispatch({
      type: 'UPDATE_PANEL_OPEN_ITEMS',
      payload
    });
  };
  return _objectSpread2(_objectSpread2({}, state), {}, {
    setPanelOpen,
    setPanelOpenItems
  });
}

export { useGraphicViewer };
