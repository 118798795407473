import { BaseRequestPolicy } from '@azure/storage-blob';

// eslint-disable-next-line max-classes-per-file
class SasStore {
  constructor(sasToken) {
    this.sasCache = {};
    this.sasToken = sasToken;
  }

  // Get a valid SAS for blob
  async getValidSASForBlob(blobURL) {
    // @ts-ignore
    if (this.sasCache[blobURL] && this.isSasStillValidInNext2Mins(this.sasCache[blobURL])) {
      // @ts-ignore
      return this.sasCache[blobURL];
    }
    this.sasCache[blobURL] = await this.getNewSasForBlob(blobURL);
    return this.sasCache[blobURL];
  }

  // Return true if "se" section in SAS is still valid in next 2 mins
  // eslint-disable-next-line class-methods-use-this
  isSasStillValidInNext2Mins(sas) {
    const expiryStringInSas = new URL(`http://hostname${sas}`).searchParams.get('se');
    // @ts-ignore
    return new Date(expiryStringInSas) - new Date() >= 2 * 60 * 1000;
  }

  // Get a new SAS for blob, we assume a SAS starts with a "?"
  async getNewSasForBlob(blobURL) {
    this._blobURL = blobURL;
    // TODO: You need to implement this
    return this.sasToken;
  }
}
class SasUpdatePolicyFactory {
  constructor(sasStore) {
    this.sasStore = sasStore;
  }
  create(nextPolicy, options) {
    return new SasUpdatePolicy(nextPolicy, options, this.sasStore);
  }
}
class SasUpdatePolicy extends BaseRequestPolicy {
  constructor(nextPolicy, options, sasStore) {
    super(nextPolicy, options);
    this.sasStore = sasStore;
  }
  async sendRequest(request) {
    const urlObj = new URL(request.url);
    const sas = await this.sasStore.getValidSASForBlob(`${urlObj.origin}${urlObj.pathname}`);
    new URL(`http://hostname${sas}`).searchParams.forEach((value, key) => {
      urlObj.searchParams.set(key, value);
    });

    // Update request URL with latest SAS
    request.url = urlObj.toString();
    return this._nextPolicy.sendRequest(request);
  }
}

export { SasStore, SasUpdatePolicy, SasUpdatePolicyFactory };
