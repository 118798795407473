import { XML_DEVICE_NODETYPE, XML_DEVICE_STR } from '@danfoss/etui-sm-xml';

/**
 * Utility to validate if device is a sensor input
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsSensorInputDevice(device) {
  return device.nodetype === XML_DEVICE_NODETYPE.NODETYPE_SI || device.type === XML_DEVICE_STR.STR_TYPE_SI || device.type === XML_DEVICE_STR.STR_TYPE_SI_RH || device.type === XML_DEVICE_STR.STR_TYPE_ALL_SI;
}

export { getIsSensorInputDevice };
