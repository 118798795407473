import {
  BuildXMLOptions,
  fetchXMLData,
  User,
  XML_ACTION,
  XML_TAG,
} from '@danfoss/etui-sm-xml';
import {
  Compressor,
  CompressorData,
  CompressorUnloadersStatus,
  CompressorUnloaderVisibilityStatus,
  PATTERNS,
  StagingPatternResponse,
  StagingPatternTableData,
  STEPS,
  Unloader,
  UnloadersVisibility,
} from '../components/ConfigurationItemContentList/types';

export const fetchPatternData = async (
  url: string,
  user: User,
  arg1: string,
): Promise<StagingPatternResponse> => {
  const data: StagingPatternResponse =
    await fetchXMLData<StagingPatternResponse>({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        arg1,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.GET_PATTERNS,
      },
    });
  return data;
};

export const writePatternData = async (
  user: User,
  url: string,
  arg1: string,
  stepValue: string,
  patterDataToWrite: number[],
): Promise<StagingPatternResponse> => {
  const data: StagingPatternResponse =
    await fetchXMLData<StagingPatternResponse>({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        arg1,
        steps: stepValue,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.WRITE_PATTERNS,
      },
      items: buildPatternOptions(patterDataToWrite),
    });
  return data;
};

export const buildPatternOptions = (patterDataToWrite: number[]) => {
  const xmlOptions: BuildXMLOptions[] = [];
  patterDataToWrite.forEach(pattern => {
    const option: BuildXMLOptions = {
      tag: XML_TAG.PATTERN,
      value: pattern.toString(),
    };
    xmlOptions.push(option);
  });
  return xmlOptions;
};

export const getStagingPatternDataToWrite = (
  stagingPatternTableData: StagingPatternTableData[],
  stepValue: number,
) => {
  const tableDatatoWrite: StagingPatternTableData[] =
    stagingPatternTableData.splice(0, stepValue);
  const patterDataToWrite: number[] = [];
  tableDatatoWrite.forEach(tableData => {
    const bitSeq: number[] = [];
    tableData.compressorUnloaderVisibiltyStatus.forEach(compressor => {
      const statuses: boolean[] = getCompressorStatusesToWrite(
        compressor.compressorUnloadersStatus,
        compressor.unloadersVisibility,
      );
      getBitSeqFromStatuses(statuses, bitSeq);
    });
    const pattern: number = buildPatterDataToWrite(bitSeq);
    patterDataToWrite.push(pattern);
  });
  return patterDataToWrite;
};

export const buildPatterDataToWrite = (bitSeq: number[]) => {
  let pattern: number = 0;
  bitSeq.forEach((bit, index) => {
    pattern += bit * 2 ** index;
  });
  return pattern;
};

export const getBitSeqFromStatuses = (status: boolean[], bitSeq: number[]) => {
  status.forEach(status => {
    bitSeq.push(status ? 1 : 0);
  });
  return bitSeq;
};

export const getCompressorStatusesToWrite = (
  compressorUnloadersStatus: CompressorUnloadersStatus,
  unloadersVisibility: UnloadersVisibility,
) => {
  const statuses: boolean[] = [];
  statuses.push(compressorUnloadersStatus.compressorStatus);
  if (unloadersVisibility.unloader1Visible) {
    statuses.push(compressorUnloadersStatus.unloader1Status);
  }
  if (unloadersVisibility.unloader2Visible) {
    statuses.push(compressorUnloadersStatus.unloader2Status);
  }
  if (unloadersVisibility.unloader3Visible) {
    statuses.push(compressorUnloadersStatus.unloader3Status);
  }
  if (unloadersVisibility.unloader4Visible) {
    statuses.push(compressorUnloadersStatus.unloader4Status);
  }
  return statuses;
};

export const processPatternData = (
  patternData: StagingPatternResponse,
): StagingPatternTableData[] => {
  const compressorData: CompressorData = getCompressorData(patternData);
  const patternSteps: number[][] = getPatternSteps(
    patternData,
    compressorData.totalCompressors,
  );
  return getStagingPatternTableData(
    compressorData,
    patternSteps,
    +patternData.t_step,
  );
};

export const updateDefaultPatternData = (
  patternData: StagingPatternResponse,
) => {
  if (patternData.t_step === STEPS.MIN_STEP) {
    patternData = {
      ...patternData,
      t_step: STEPS.DEFAULT_STEP,
      ptrn: [PATTERNS.DEFAULT_PTRN, PATTERNS.DEFAULT_PTRN],
    };
  }
  return patternData;
};

export const filterStagingPatternTableData = (
  step: number,
  stagingPatternTableData: StagingPatternTableData[],
) => {
  const filteredStagingPatternTableData = stagingPatternTableData.splice(
    0,
    step,
  );
  return filteredStagingPatternTableData;
};

export const addStagingPatternTableData = (
  clonedPatternData: StagingPatternResponse,
  step: number,
  stagingPatternTableData: StagingPatternTableData[],
) => {
  clonedPatternData.ptrn = [];
  const stepsToAdd = step - stagingPatternTableData.length;
  for (let index = 0; index < stepsToAdd; index++) {
    clonedPatternData.ptrn.push(PATTERNS.DEFAULT_PTRN);
    clonedPatternData.t_step = (index + 1).toString();
    const addedStagingPaternTableData: StagingPatternTableData[] =
      processPatternData(clonedPatternData);
    addedStagingPaternTableData[0].textCount = getTextCount(
      stagingPatternTableData.length,
    );
    stagingPatternTableData.push(addedStagingPaternTableData[0]);
  }
  return [...stagingPatternTableData];
};

export const getStagingPatternTableData = (
  compressorData: CompressorData,
  patternSteps: number[][],
  numberOfSteps: number,
): StagingPatternTableData[] => {
  const stagingPatternTableDataArray: StagingPatternTableData[] = [];
  for (let step = 0; step < numberOfSteps; step++) {
    const compressorUnloaderVisibiltyStatusArray: CompressorUnloaderVisibilityStatus[] =
      [];
    const textCount: string = getTextCount(step);
    const decodedPattern: number[] = patternSteps[step];
    const compressorsLength: number = compressorData.compressors.length;
    let capacity: number = 0;
    let startIdx = 0;
    let endIdx = 0;

    for (let compIndex = 0; compIndex < compressorsLength; compIndex++) {
      const compressor: Compressor = compressorData.compressors[compIndex];
      endIdx += compressor.numOfUnloader + 1;

      const unloadersVisibility: UnloadersVisibility = getLoadersVisibility(
        compressorData.compressors[compIndex].numOfUnloader,
      );

      const compressorBinaryStatus: number[] = getCompressorBinaryStatus(
        startIdx,
        endIdx,
        decodedPattern,
        compressorsLength,
      );

      const compressorUnloadersStatus: CompressorUnloadersStatus =
        getCompressorUnloaderStatus(compressorBinaryStatus);

      const calculatedCapacity: number = calculateCapacity(
        compressorUnloadersStatus,
        compressor.numOfUnloader,
        compressor.unloader,
        compressor.capacity,
      );

      capacity += calculatedCapacity;
      startIdx = endIdx;
      const updatedCompressor: Compressor = {
        id: compressor.id,
        capacity: calculatedCapacity,
        numOfUnloader: compressor.numOfUnloader,
        singleComp: compressor.singleComp,
        unloader: compressor.unloader,
        actualCapacity: compressor.capacity,
      };
      const compressorUnloaderVisibiltyStatus: CompressorUnloaderVisibilityStatus =
        {
          unloadersVisibility,
          compressorUnloadersStatus,
          compressor: updatedCompressor,
        };
      compressorUnloaderVisibiltyStatusArray.push(
        compressorUnloaderVisibiltyStatus,
      );
    }
    const stagingPatternTableData: StagingPatternTableData = {
      textCount,
      capacity,
      compressorUnloaderVisibiltyStatus: compressorUnloaderVisibiltyStatusArray,
    };
    stagingPatternTableDataArray.push(stagingPatternTableData);
  }
  return stagingPatternTableDataArray;
};

export const calculateCapacity = (
  compressorUnloadersStatus: CompressorUnloadersStatus,
  numOfUnloaders: number,
  unloaders: Unloader[],
  compressorCapacity: number,
) => {
  if (
    !compressorUnloadersStatus.compressorStatus ||
    numOfUnloaders !== unloaders.length
  ) {
    return roundOffCapacity(0.0);
  }
  let capValue: number = compressorCapacity;
  if (numOfUnloaders > 3 && !compressorUnloadersStatus.unloader4Status) {
    capValue = deductUnloaderCapacity(
      capValue,
      unloaders[3].pct,
      compressorCapacity,
    );
  }

  if (numOfUnloaders > 2 && !compressorUnloadersStatus.unloader3Status) {
    capValue = deductUnloaderCapacity(
      capValue,
      unloaders[2].pct,
      compressorCapacity,
    );
  }

  if (numOfUnloaders > 1 && !compressorUnloadersStatus.unloader2Status) {
    capValue = deductUnloaderCapacity(
      capValue,
      unloaders[1].pct,
      compressorCapacity,
    );
  }

  if (numOfUnloaders > 0 && !compressorUnloadersStatus.unloader1Status) {
    capValue = deductUnloaderCapacity(
      capValue,
      unloaders[0].pct,
      compressorCapacity,
    );
  }
  return roundOffCapacity(capValue);
};

export const deductUnloaderCapacity = (
  total: number,
  pct: number,
  compressorCapacity: number,
) => {
  return total - (compressorCapacity * pct) / 100.0;
};

export const roundOffCapacity = (calculateCapacity: number) => {
  return +roundToPrecision(calculateCapacity, 0.1).toFixed(2);
};

export const roundToPrecision = (calculateCapacity, precision) => {
  const capacity =
    +calculateCapacity + (precision === undefined ? 0.5 : precision / 2);
  return capacity - (capacity % (precision === undefined ? 1 : +precision));
};

export const getCompressorUnloaderStatus = (
  compressorBinaryStatus: number[],
) => {
  const compressorBinaryStatusLength = compressorBinaryStatus.length;
  const compressorUnloadersStatus: CompressorUnloadersStatus = {
    compressorStatus:
      compressorBinaryStatusLength > 0 && toBoolean(compressorBinaryStatus[0]),
    unloader1Status:
      compressorBinaryStatusLength > 1 && toBoolean(compressorBinaryStatus[1]),
    unloader2Status:
      compressorBinaryStatusLength > 2 && toBoolean(compressorBinaryStatus[2]),
    unloader3Status:
      compressorBinaryStatusLength > 3 && toBoolean(compressorBinaryStatus[3]),
    unloader4Status:
      compressorBinaryStatusLength > 4 && toBoolean(compressorBinaryStatus[4]),
  };
  return compressorUnloadersStatus;
};

export const toBoolean = (flag: number) => {
  return flag === 1;
};

export const getCompressorBinaryStatus = (
  startIdx: number,
  endIdx: number,
  decodedPattern: number[],
  compressorsLength: number,
) => {
  if (!decodedPattern) {
    decodedPattern = [];
    for (let index = 0; index < compressorsLength; index++) {
      decodedPattern.push(0);
    }
  }
  return decodedPattern.slice(startIdx, endIdx);
};

export const getLoadersVisibility = (numOfUnloader: number) => {
  const unloadersVisibility: UnloadersVisibility = {
    unloader1Visible: numOfUnloader >= 1,
    unloader2Visible: numOfUnloader >= 2,
    unloader3Visible: numOfUnloader >= 3,
    unloader4Visible: numOfUnloader >= 4,
  };
  return unloadersVisibility;
};

export const getTextCount = (step: number) => {
  return step < 10
    ? `${STEPS.HASH_STEP}${STEPS.MIN_STEP}${step}`
    : `${STEPS.HASH_STEP}${step}`;
};

export const getPatternSteps = (
  patternData: StagingPatternResponse,
  totalCompressors: number,
) => {
  const numberOfSteps: number = +patternData.t_step;
  const patternSteps: number[][] = [];
  for (let stepIndex = 0; stepIndex < numberOfSteps; stepIndex++) {
    patternSteps.push(decode(+patternData.ptrn[stepIndex], totalCompressors));
  }
  return patternSteps;
};

export const decode = (pattern: number, totalCompressors: number) => {
  const binaryArr: number[] = [];
  let num: number = pattern;

  while (num > 0) {
    num /= 2;
    if (Math.floor(num) === num) {
      binaryArr.push(0);
    } else {
      binaryArr.push(1);
    }

    num = Math.floor(num);
  }

  if (binaryArr.length < totalCompressors) {
    for (
      let index: number = binaryArr.length;
      index < totalCompressors;
      index++
    ) {
      binaryArr.push(0);
    }
  }

  return binaryArr;
};

export const getCompressorData = (patternData: StagingPatternResponse) => {
  const numberOfCompressors: number = +patternData.t_comp;
  const compressors: Compressor[] = [];

  let totalCompressors: number = 0;
  for (
    let compIndex: number = 0;
    compIndex < numberOfCompressors;
    compIndex++
  ) {
    const compressor: Compressor = getCompressors(
      numberOfCompressors,
      patternData,
      compIndex,
    );
    getUnloader(compressor, patternData, compIndex);
    compressors.push(compressor);
    totalCompressors += compressor.numOfUnloader;
  }
  const compressorData: CompressorData = {
    compressors,
    totalCompressors,
  };
  return compressorData;
};

export const getUnloader = (
  compressor: Compressor,
  patternData: StagingPatternResponse,
  compIndex: number,
) => {
  for (
    let unldIndex: number = 0;
    unldIndex < compressor.numOfUnloader;
    unldIndex++
  ) {
    const singleUnld = compressor.numOfUnloader === 1;
    const unld_pct = compressor.singleComp
      ? singleUnld
        ? patternData.comp.unld_pct
        : patternData.comp.unld_pct[unldIndex]
      : singleUnld
      ? patternData.comp[compIndex].unld_pct
      : patternData.comp[compIndex].unld_pct[unldIndex];
    const unloader: Unloader = { pct: +unld_pct, status: false };
    compressor.unloader.push(unloader);
  }
};

export const getCompressors = (
  numberOfCompressors: number,
  patternData: StagingPatternResponse,
  compIndex: number,
) => {
  const singleComp = numberOfCompressors === 1;
  const cap = singleComp
    ? +patternData.comp.capacity
    : +patternData.comp[compIndex].capacity;
  const unld = singleComp
    ? +patternData.comp.t_unld
    : +patternData.comp[compIndex].t_unld;
  const compressor: Compressor = {
    id: compIndex + 1,
    numOfUnloader: unld,
    capacity: cap,
    singleComp,
    unloader: [],
  };
  return compressor;
};

export const updateOnCompressorClick = (
  rowIndex: number,
  compIndex: number,
  stagingPatternTableDataArray: StagingPatternTableData[],
) => {
  const stagingPatternTableData: StagingPatternTableData =
    stagingPatternTableDataArray[rowIndex];
  const { compressorUnloadersStatus, compressor } =
    stagingPatternTableData.compressorUnloaderVisibiltyStatus[compIndex];

  compressorUnloadersStatus.compressorStatus =
    !compressorUnloadersStatus.compressorStatus;
  updateUnloaderStatusOnCompressorClick(compressorUnloadersStatus);
  updateCapacity(
    compressorUnloadersStatus,
    compressor,
    stagingPatternTableData,
  );
  return stagingPatternTableDataArray;
};

export const updateUnloaderStatusOnCompressorClick = (
  compressorUnloadersStatus: CompressorUnloadersStatus,
) => {
  if (!compressorUnloadersStatus.compressorStatus) {
    compressorUnloadersStatus.unloader1Status = false;
    compressorUnloadersStatus.unloader2Status = false;
    compressorUnloadersStatus.unloader3Status = false;
    compressorUnloadersStatus.unloader4Status = false;
  }
};

export const updateOnUnloaderClick = (
  rowIndex: number,
  compIndex: number,
  unloaderIndex: number,
  stagingPatternTableDataArray: StagingPatternTableData[],
) => {
  const stagingPatternTableData: StagingPatternTableData =
    stagingPatternTableDataArray[rowIndex];
  const { compressorUnloadersStatus, compressor } =
    stagingPatternTableData.compressorUnloaderVisibiltyStatus[compIndex];

  if (!compressorUnloadersStatus.compressorStatus) {
    compressorUnloadersStatus.compressorStatus = true;
  }
  updateUnloadersStatus(compressorUnloadersStatus, unloaderIndex);
  updateCapacity(
    compressorUnloadersStatus,
    compressor,
    stagingPatternTableData,
  );
  return stagingPatternTableDataArray;
};

export const updateCapacity = (
  compressorUnloadersStatus: CompressorUnloadersStatus,
  compressor: Compressor,
  stagingPatternTableData: StagingPatternTableData,
) => {
  let capacity: number = 0;
  const updatedCapacity: number = calculateCapacity(
    compressorUnloadersStatus,
    compressor.numOfUnloader,
    compressor.unloader,
    compressor.actualCapacity,
  );
  compressor.capacity = updatedCapacity;
  stagingPatternTableData.compressorUnloaderVisibiltyStatus.forEach(
    compUnld => {
      capacity += compUnld.compressor.capacity;
    },
  );
  stagingPatternTableData.capacity = capacity;
};

export const updateUnloadersStatus = (
  compressorUnloadersStatus: CompressorUnloadersStatus,
  unloaderIndex: number,
) => {
  const unloader: number = unloaderIndex + 1;
  switch (unloader) {
    case 1: {
      if (compressorUnloadersStatus.unloader1Status) {
        compressorUnloadersStatus.unloader1Status = false;
        compressorUnloadersStatus.unloader2Status = false;
        compressorUnloadersStatus.unloader3Status = false;
        compressorUnloadersStatus.unloader4Status = false;
      } else {
        compressorUnloadersStatus.unloader1Status = true;
        compressorUnloadersStatus.unloader2Status = false;
        compressorUnloadersStatus.unloader3Status = false;
        compressorUnloadersStatus.unloader4Status = false;
      }
      break;
    }
    case 2: {
      if (compressorUnloadersStatus.unloader2Status) {
        compressorUnloadersStatus.unloader2Status = false;
        compressorUnloadersStatus.unloader3Status = false;
        compressorUnloadersStatus.unloader4Status = false;
      } else {
        compressorUnloadersStatus.unloader1Status = true;
        compressorUnloadersStatus.unloader2Status = true;
        compressorUnloadersStatus.unloader3Status = false;
        compressorUnloadersStatus.unloader4Status = false;
      }
      break;
    }
    case 3: {
      if (compressorUnloadersStatus.unloader3Status) {
        compressorUnloadersStatus.unloader3Status = false;
        compressorUnloadersStatus.unloader4Status = false;
      } else {
        compressorUnloadersStatus.unloader1Status = true;
        compressorUnloadersStatus.unloader2Status = true;
        compressorUnloadersStatus.unloader3Status = true;
        compressorUnloadersStatus.unloader4Status = false;
      }
      break;
    }
    case 4: {
      if (compressorUnloadersStatus.unloader4Status) {
        compressorUnloadersStatus.unloader4Status = false;
      } else {
        compressorUnloadersStatus.unloader1Status = true;
        compressorUnloadersStatus.unloader2Status = true;
        compressorUnloadersStatus.unloader3Status = true;
        compressorUnloadersStatus.unloader4Status = true;
      }
      break;
    }
    default:
      break;
  }
};
