export const SHCEDULES_BLUE_SHADES = [
  '#DDEAF8',
  '#CCE0F5',
  '#A9CCEF',
  '#98C2EB',
  '#87B8E8',
  '#76ADE5',
  '#65A3E2',
  '#5499DE',
  '#438FDB',
  '#3185D8',
  '#277ACE',
  '#2470BC',
  '#1F61A3',
  '#1D5C9A',
  '#1A5289',
  '#174778',
  '#143D67',
  '#103356',
  '#0D2945',
  '#0A1F33',
  '#071422',
  '#030A11',
];

export function* bluish(): Iterator<string> {
  let step = 0;

  while (true) {
    if (step === SHCEDULES_BLUE_SHADES.length) step = 0;
    yield SHCEDULES_BLUE_SHADES[step];
    step++;
  }
}
