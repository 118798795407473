import { TFunction } from 'i18next';
import { Theme } from '@danfoss/etui-themes';
import { Notification } from '@danfoss/etui-core';

const THREE_SECONDS = 3;

export const showErrorNotification = (
  theme: Theme,
  t: TFunction,
  { message }: Error,
): void => {
  Notification.error({
    theme,
    message: t('t17'),
    description: t(message),
    duration: THREE_SECONDS,
  });
};

export const showSuccessNotification = (
  theme: Theme,
  t: TFunction,
  description: string,
): void => {
  Notification.success({
    theme,
    message: t('t721'),
    description,
    duration: THREE_SECONDS,
  });
};
