import { styled, createElement } from '@danfoss/etui-system';
import { DivProps } from '@danfoss/etui-system-elements';

const MainElement = createElement('main');

export const Main = styled(MainElement)<DivProps & { isForbidden?: boolean }>`
  align-items: center;
  background: ${({ theme }) =>
    `radial-gradient(${theme.palette.brand.light} 65%,#4c0209 135%)`};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  width: 100vw;
`;
