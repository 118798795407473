function tryCatch(cb: () => any, fallBack: any) {
  try {
    return cb();
  } catch {
    return fallBack;
  }
}

export function objToB64Uri(obj: Object) {
  return tryCatch(() => encodeURIComponent(btoa(JSON.stringify(obj))), '');
}

export function b64UriToObj(s: string) {
  return tryCatch(() => JSON.parse(atob(decodeURIComponent(s))), {});
}
