import { AmPm } from '../../TimeInput';
import { HourFormat } from '../../TimeInput/TimeInput';

const defineAmOrPm = (time: string, hourFormat: HourFormat): string => {
  if (hourFormat === '24') return '';

  const hours = parseInt(time, 10);
  return hours > 11 ? AmPm.PM : AmPm.AM;
};

export { defineAmOrPm };
