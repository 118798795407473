import * as React from 'react';
import useSWR from 'swr';
import { useResponsive } from '@danfoss/etui-core';
import { ContentError } from '@danfoss/etui-sm';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from 'hooks/featurehub/FeatureHubContext';
import {
  ConfigurationAuthProvider,
  DeviceConfigurationWidget,
  getUnitByDevice,
} from '../Configuration';
import { fetchDeviceConfigurationByUnit } from './actions';
import { DeviceConfigurationBaseProps } from './DeviceConfiguration.types';

function DeviceConfigurationBase({
  xmlBackendUrl,
  device,
  units,
  user,
}: DeviceConfigurationBaseProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const unit = getUnitByDevice(units, device);
  const DeviceConfigurationWrite = useFeatureFlag('DeviceConfigurationWrite');
  const { screenIsAtMost } = useResponsive({
    lg: parseInt(theme.breakpoints[2], 10),
  });
  const isLgView = screenIsAtMost('lg');

  const {
    data: deviceTabs,
    error: deviceTabsError,
    mutate,
  } = useSWR(
    () => (unit ? [xmlBackendUrl, unit, device, user] : null),
    fetchDeviceConfigurationByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );
  return deviceTabsError ? (
    <ContentError
      title={t('t17')}
      message={t('t66')}
      onRetryClick={() => mutate()}
    />
  ) : !deviceTabs?.length ? null : (
    <ConfigurationAuthProvider
      access={{ writeAccess: DeviceConfigurationWrite }}
    >
      <Div display="flex">
        <DeviceConfigurationWidget
          xmlBackendUrl={xmlBackendUrl}
          device={device}
          user={user}
          unit={unit}
          tabs={isLgView ? deviceTabs : deviceTabs.slice(0, 1)}
        />
        {!isLgView && deviceTabs.length > 1 ? (
          <>
            <Div width="1px" bg={theme.palette.divider} />
            <DeviceConfigurationWidget
              xmlBackendUrl={xmlBackendUrl}
              device={device}
              user={user}
              unit={unit}
              tabs={deviceTabs.slice(1)}
            />
          </>
        ) : null}
      </Div>
    </ConfigurationAuthProvider>
  );
}

export { DeviceConfigurationBase };
