import '../../constants/session-storage-constants.js';
import { XML_ACTION } from '../../constants/xml-action-constant.js';
import '../../constants/xml-tag-constant.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import { _ as _objectSpread2 } from '../../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../../fetch-xml/fetch-xml-data.js';

/**
 * Get alarm summary
 *
 * @param {string} url
 * @param {User} user
 * @returns {Promise<AlarmSummaryResponse>}
 */
async function fetchAlarmSummary(url, user, skipSessionUpdate) {
  return fetchXMLData({
    url,
    attributes: _objectSpread2({
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.ALARM_SUMMARY,
      storeview_only: '1',
      version: 'C'
    }, skipSessionUpdate ? {
      session_update: 'no'
    } : {})
  });
}

export { fetchAlarmSummary };
