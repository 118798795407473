import {
  fetchXMLData,
  User,
  XML_ACTION,
  XML_DEVICE_LIST,
} from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import { getArray } from '../../../../../Configuration';
import {
  getBusDescription,
  getHvacModelListName,
  getTypeDescription,
} from '../../../RefrigLayoutModal/utils';
import {
  DeviceConstraintsData,
  DeviceModel,
  DEVICE_STATUS,
  DEVICE_TYPE,
  DiscoveryDeviceDetail,
  DiscoveryDeviceStatusCnt,
  DiscoveryPageData,
  DiscoveryResponse,
  DiscoveryTableData,
  HvacFinalTableData,
  HVAC_DEVICE_MODEL,
} from '../../../RefrigLayoutModal/types/DiscoveryPage.types';

async function fetchHvacDiscoveryDataByUnit([
  url,
  user,
  t,
  isOfflineProgramming,
]: [string, User, TFunction, boolean]) {
  try {
    const data: DiscoveryResponse = await fetchXMLData({
      url,
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.READ_HVAC_SCAN,
      },
    });
    return data
      ? processHvacDiscoveryResponse(data, t, isOfflineProgramming)
      : null;
  } catch (e) {
    throw new Error(e);
  }
}

const processHvacDiscoveryResponse = (
  data: DiscoveryResponse,
  t: TFunction,
  isOfflineProgramming: boolean,
) => {
  const filteredData: DiscoveryDeviceDetail[] = filterHvacDiscoveryResponse(
    data,
    isOfflineProgramming,
  );
  const hvacDeviceConstraintsData: DeviceConstraintsData =
    getHvacDeviceConstraintsData(data);
  const hvacDiscoveryTableData: DiscoveryTableData[] =
    getHvacDiscoveryTableData(filteredData, t);
  const hvacDiscoveryDeviceStatusCnt: DiscoveryDeviceStatusCnt =
    getHvacDiscoveryDeviceStatusCnt(hvacDiscoveryTableData);
  const hvacDiscoveryPageData: DiscoveryPageData = {
    discoveryTableData: hvacDiscoveryTableData,
    deviceConstraintsData: hvacDeviceConstraintsData,
    discoveryDeviceStatusCnt: hvacDiscoveryDeviceStatusCnt,
  };
  return hvacDiscoveryPageData;
};

const filterHvacDiscoveryResponse = (
  data: DiscoveryResponse,
  isOfflineProgramming: boolean,
) => {
  const filteredData: DiscoveryDeviceDetail[] = [];
  getArray(data?.devices?.d)?.forEach(device1 => {
    let isDeviceFiltered = false;
    if (
      device1?.type === DEVICE_TYPE.HVAC &&
      (isOfflineProgramming || device1?.onln === '1')
    ) {
      filteredData.push(device1);
      isDeviceFiltered = true;
    }
    if (!isDeviceFiltered) {
      let same = false;
      for (const device2 of filteredData) {
        if (isSameDevice(device1, device2)) {
          same = true;
          break;
        }
      }
      let existingDb = false;
      if (!isOfflineProgramming && device1?.onln === '0') {
        existingDb = true;
      }
      if (!same && !existingDb) {
        filteredData.push(device1);
      }
    }
  });
  return filteredData;
};

const isSameDevice = (
  device1: DiscoveryDeviceDetail,
  device2: DiscoveryDeviceDetail,
) => {
  return device1?.type === device2?.type && device1?.addr === device2?.addr;
};

const getHvacDiscoveryDeviceStatusCnt = (
  hvacDiscoveryTableData: DiscoveryTableData[],
) => {
  let statusOrangeCnt: number = 0;
  let statusGreenCnt: number = 0;
  let statusRedCnt: number = 0;
  hvacDiscoveryTableData?.forEach(hvacDevice => {
    switch (hvacDevice?.status) {
      case DEVICE_STATUS.ORANGE:
        statusOrangeCnt = ++statusOrangeCnt;
        break;
      case DEVICE_STATUS.GREEN:
        statusGreenCnt = ++statusGreenCnt;
        break;
      case DEVICE_STATUS.RED:
        statusRedCnt = ++statusRedCnt;
        break;
      default:
        break;
    }
  });
  return {
    statusOrangeCnt,
    statusGreenCnt,
    statusRedCnt,
  };
};

const getHvacDiscoveryTableData = (
  devices: DiscoveryDeviceDetail[],
  t: TFunction,
) => {
  const hvacDiscoveryTableData: DiscoveryTableData[] = [];
  devices?.forEach((device, index) => {
    const hvacDiscoveryTableDataObj: DiscoveryTableData =
      getHvacDiscoveryTableDataObj(device, index, t);
    hvacDiscoveryTableData.push(hvacDiscoveryTableDataObj);
  });
  return hvacDiscoveryTableData;
};

const getHvacDiscoveryTableDataObj = (
  device: DiscoveryDeviceDetail,
  index: number,
  t: TFunction,
) => {
  const hvacDiscoveryTableData: DiscoveryTableData = {
    deviceId: index + 1,
    ah: +device?.ah,
    name: getDeviceName(device?.name, device?.mdl, device?.addr),
    model: getDeviceModel(device?.type, device?.hvac_type, device?.mdl),
    address: getDeviceAddress(device?.type, device?.addr),
    status: getDeviceStatus(device?.type, device?.onln),
    type: getTypeDescription(device?.type, t),
    deviceType: device?.type,
    removed: false,
    assigned: false,
    newDevice: false,
    arranged: false,
    model_idx: 0,
    online: device?.onln,
    cfg: device?.cfg,
    is_multi: false,
    cat1: '0',
    cat2: '0',
    category: +device?.cat1,
    multi: +device.cat2 & XML_DEVICE_LIST.DEV_MULTI_SECT,
    file: device?.fil,
    version: device?.ver || '-',
    device: device?.dev,
    ip: device?.ip,
    dim1: device?.dim1 || '0',
    dim2: device?.dim2 || '0',
    dim3: device?.dim3 || '0',
    deviceBus: getBusDescription(+device?.bus, device?.ip),
    code: device?.dev || '-',
  };
  return hvacDiscoveryTableData;
};

const getDeviceStatus = (type: string, onln: string) => {
  return type === DEVICE_TYPE.HVAC
    ? DEVICE_STATUS.RED
    : onln === '1'
    ? DEVICE_STATUS.GREEN
    : DEVICE_STATUS.RED;
};

const getDeviceAddress = (type: string, addr: string) => {
  return type === DEVICE_TYPE.HVAC ? '-' : addr;
};

const getDeviceModel = (type: string, hvac_type: string, mdl: string) => {
  return type === DEVICE_TYPE.HVAC
    ? hvac_type === '1'
      ? HVAC_DEVICE_MODEL.RTU
      : HVAC_DEVICE_MODEL.AHU
    : mdl || '-';
};

const getDeviceName = (name: string, mdl: string, addr: string) => {
  return name === 'Uncfg' ||
    name === 'GnCFG' ||
    name === 'Incfg' ||
    name === 'UnKfg' ||
    name === 'Nem k' ||
    name === 'NÃ£oCf' ||
    name === 'NoCfg'
    ? `${mdl} ${addr}`
    : name || '-';
};

const getHvacDeviceConstraintsData = (data: DiscoveryResponse) => {
  const hvacDeviceContraintsData: DeviceConstraintsData = {
    gen_ofs_ah: +data?.devices?.gen_ofs_ah,
    max_ah: +data?.devices?.max_ah,
    max_naddr: data?.devices?.max_naddr,
    max_namelen: +data?.devices?.max_name || 5,
    max_octl: data?.devices?.max_octl,
  };
  return hvacDeviceContraintsData;
};

function processHvacFinalTableData(
  discoveryTableData: DiscoveryTableData[],
  deviceModelList: DeviceModel[],
) {
  const hvacTableData: DiscoveryTableData[] = [];
  const hvacDeviceName: string[] = [];
  const hvacDeviceAddress: string[] = [];
  discoveryTableData?.forEach(device => {
    device.listname = getHvacModelListName(device, deviceModelList);
    hvacDeviceName.push(device?.name);
    hvacDeviceAddress.push(device?.address);
    hvacTableData.push(device);
  });
  const hvacFinalTableData: HvacFinalTableData = {
    hvacTableData,
    hvacDeviceName,
    hvacDeviceAddress,
  };
  return hvacFinalTableData;
}

export { fetchHvacDiscoveryDataByUnit, processHvacFinalTableData };
