import {
  DEVICE_MAX_SG,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../types/DiscoveryPage.types';

export const getSuctionGroupCount = (
  suction: DiscoveryTableData[] = [],
): number => suction.reduce((acc, { num_sg }) => acc + num_sg, 0);

export const getCurrentSuctionGroupCount = (
  suction: DiscoveryTableData[],
  selectedRowIndex: number,
): number => {
  return suction[selectedRowIndex]?.num_sg;
};

export const getNextSuctionGroupNumber = (
  discoveryTableData: DiscoveryTableData[],
) => {
  let sg = 0;
  discoveryTableData?.forEach(device => {
    if (device?.sg > sg && device?.sg < 255) {
      sg = device?.sg;
    }
  });
  return sg + 1;
};

export const getMaxSg = (deviceType: string) => {
  if (deviceType === DEVICE_TYPE.NO_COMP) {
    return DEVICE_MAX_SG.MAX_SG_NOCOMP;
  }
  if (deviceType === DEVICE_TYPE.GEN_PACK) {
    return DEVICE_MAX_SG.MAX_SG_PACK;
  }
  if (deviceType === DEVICE_TYPE.RACK) {
    return DEVICE_MAX_SG.MAX_SG_RACK;
  }
  return 1;
};
