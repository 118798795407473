import { executeOperation } from './utils/execute-operation.js';

class Condition {
  constructor(id) {
    if (id === void 0) {
      id = 0;
    }
    this.id = id;
    this.color = '';
    this.operator = undefined;
  }
  import(rawObj) {
    if (rawObj.id) {
      this.id = rawObj.id;
    }
    if (rawObj.color) {
      this.setColor(rawObj.color);
    }
    if (rawObj.operator) {
      this.operator = rawObj.operator;
    }
    if (rawObj.value) {
      this.value = rawObj.value;
    }
    return this;
  }
  setColor(color) {
    this.color = color;
    return this;
  }
  setOperator(operator) {
    this.operator = operator.value;
    return this;
  }
  setValue(value) {
    this.value = value;
    return this;
  }
  isFulfilled(compareValue) {
    if (this.operator) {
      return executeOperation[this.operator](compareValue, this.value);
    }
    return false;
  }
}

export { Condition };
