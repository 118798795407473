import { Device, XML_DEVICE_STR } from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import { getSuctionGroups } from '@danfoss/etui-sm/utils';
import { ScheduleConstant, ScheduleTreeItem } from '../SchedulesPage.types';

const scheduleTypes: ScheduleConstant[] = [
  ScheduleConstant.SCHED_VIEW_SHUTDOWN,
  ScheduleConstant.SCHED_VIEW_DEFROST,
  ScheduleConstant.SCHED_VIEW_CASE_LIGHTS,
  ScheduleConstant.SCHED_VIEW_NIGHTSB,
];

const getSuctionDevices = (data: Device[]) => {
  const suctionDevices =
    data &&
    getSuctionGroups('refrig', data).filter(
      (device: Device) =>
        device.type === XML_DEVICE_STR.STR_TYPE_RACK ||
        device.type === XML_DEVICE_STR.STR_TYPE_PACK,
    );
  return suctionDevices;
};

const scheduleNameByScheduleTypeMatrix: { [key in ScheduleConstant]: string } =
  {
    [ScheduleConstant.SCHED_VIEW_SHUTDOWN]: 't391',
    [ScheduleConstant.SCHED_VIEW_DEFROST]: 't403',
    [ScheduleConstant.SCHED_VIEW_CASE_LIGHTS]: 't412',
    [ScheduleConstant.SCHED_VIEW_NIGHTSB]: 't411',
  };

const getNameBySchedTypes = (schedType: ScheduleConstant) =>
  scheduleNameByScheduleTypeMatrix[schedType];

const getTreeData = (data: Device[], t: TFunction) => {
  const schedtypes: ScheduleConstant[] = scheduleTypes;
  const treeData = [];

  for (let i: number = 0; i < schedtypes.length; i++) {
    const item: ScheduleTreeItem = {
      id: `node_${schedtypes[i]}_${i}`,
      schedtype: schedtypes[i],
      comboindex: 255,
      items: [],
      name: t(getNameBySchedTypes(schedtypes[i])),
    };

    const suctionDevices = data && getSuctionDevices(data);

    const tempItems = [];

    for (let j: number = 0; j < suctionDevices?.length; j++) {
      const subDevices = {
        ...suctionDevices[j],
        id: `${schedtypes[i]}_${j}`,
        items: [],
        schedtype: schedtypes[i],
        devices: suctionDevices[j].items,
      };
      tempItems.push(subDevices);
    }

    item.items = tempItems;
    treeData.push(item);
  }
  return treeData;
};

export { getTreeData };
