import { ScheduleType, ScheduleTypes } from '../SchedulesPage.types';

const getIconToListItemById = (type: ScheduleType): string => {
  switch (type) {
    case ScheduleTypes.DEFROST:
      return 'DEFROST';
    case ScheduleTypes.CASE_LIGHTS:
      return 'LIGHTING';
    case ScheduleTypes.NIGHT_SETBACK:
      return 'NIGHT_SETBACK';
    case ScheduleTypes.SHUTDOWN:
      return 'NO_ACCESS';
    case ScheduleTypes.CIRCUIT_NIGHT_SETBACKS:
    case ScheduleTypes.SUCTION_NIGHT_SETBACKS:
      return 'NIGHT_SETBACK';
    default:
      return null;
  }
};

export { getIconToListItemById };
