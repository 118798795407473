import { ConfigurationListItem, Unit } from '@danfoss/etui-sm-xml';
import { ConfigurationListItemAddress } from '../ConfigurationItemContentList';

export enum NodeTypes {
  NODE_TYPE_OI = '0',
  NODE_TYPE_RCO = '20',
  NODE_TYPE_CTRL = '21',
  NODE_TYPE_CALC = '22',
  NODE_TYPE_NA = '255',
}

export function getIoTypesByUnit(u: Unit) {
  return [
    { value: NodeTypes.NODE_TYPE_CALC, label: 't477' },
    { value: NodeTypes.NODE_TYPE_RCO, label: 'AK-CM' },
    { value: NodeTypes.NODE_TYPE_CTRL, label: 't135' },
    {
      value: NodeTypes.NODE_TYPE_OI,
      label: Number(u.legacytype) === 2 ? 'COMTROL' : 'AKCess',
    },
  ];
}

export function getModValue(mod, io, point) {
  switch (io) {
    case NodeTypes.NODE_TYPE_CALC:
      return '0';
    case NodeTypes.NODE_TYPE_CTRL:
    case NodeTypes.NODE_TYPE_OI:
      return point;
    default:
      return mod;
  }
}

enum NameField {
  PN_OCTL_DESCRIPTION = 4241,
  PN_OCTL_NAME = 4657,
  PN_ANLG_NAME = 4553,
  PN_DIGI_NAME = 4606,
  PN_LITE_MCX_NAME = 4136,
}

export function getIsGeneric(pnum: string) {
  return (
    parseInt(pnum, 10) !== NameField.PN_ANLG_NAME &&
    parseInt(pnum, 10) !== NameField.PN_DIGI_NAME
  );
}

export function hasChanged(obj, toChange) {
  return Object.keys(toChange).some(k => obj[k] !== toChange[k]);
}

export function editedItemByAddress(addresses, item) {
  return addresses.find(({ addressLine: { li } }) => li === item.li) || null;
}

enum AddressField {
  PN_DBSY_DIGI_OI_BDPT_NUM = 4701,
  PN_DBSY_DIGI_RO_BDPT_NUM = 4702,
  PN_DBSY_ANLG_SI_BDPT_NUM = 4703,
  PN_DBSY_ANLG_VO_BDPT_NUM = 4704,
  PN_DBSY_OCTL_16_BDPT_NUM = 4705,
  PN_DBSY_OCTL_RT_BDPT_NUM = 4706,
  PN_DBSY_OCTL_EM_BDPT_NUM = 4707,
  PN_DBSY_OCTL_LP_BDPT_NUM = 4708,
  PN_DBSY_OCTL_VL_BDPT_NUM = 4709,
  PN_DBSY_OCTL_CM_BDPT_NUM = 4710,
  PN_DBSY_OCTL_EK_BDPT_NUM = 4711,
  PN_DBSY_OCTL_DM_BDPT_NUM = 4712,
  PN_DBSY_OCTL_CT_BDPT_NUM = 4713,
  PN_DBSY_OCTL_DC_BDPT_NUM = 4714,
  PN_DBSY_OCTL_CH_BDPT_NUM = 4715,
  PN_DBSY_OCTL_CR_BDPT_NUM = 4716,
  PN_DBSY_OCTL_GN_BDPT_NUM = 4717,
}

enum OptionField {
  PN_ANLG_VALUE_TYPE = 4565,
  PN_DIGI_VALUE_TYPE = 4617,
}

export function getAddresses(
  items: ConfigurationListItem[] = [],
): ConfigurationListItemAddress[] {
  return items.reduce((acc, item) => {
    const lastNameItem = acc[acc.length - 1];
    const pnum = parseInt(item.pnum, 10);
    const isNameField = pnum in NameField;
    const isAddressField = pnum in AddressField;
    const isOptionField = pnum in OptionField;

    if (isNameField) {
      acc.push({ ...item, name: item.value });
      return acc;
    }

    if (isAddressField) {
      if (lastNameItem) lastNameItem.addressLine = { ...item };
      else acc.push({ addressLine: { ...item } });

      return acc;
    }

    if (isOptionField) {
      if (lastNameItem) lastNameItem.optionsLine = { ...item };
      else acc.push({ optionsLine: { ...item } });

      return acc;
    }

    return acc;
  }, []);
}
