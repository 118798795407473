import React from 'react';
import { useTranslation } from 'react-i18next';
import { Div } from '@danfoss/etui-system-elements';
import { LayoutFlowTitle } from '../../../../RefrigLayoutModal/components/LayoutContent/LayoutFlowTitle';
import { HVACFinalTable } from '.';

export interface HVACFinalPageProps {
  tableDataLoading: boolean;
  handleOnCopyOk: (copyAmount: number, selectedRowIndex: number) => void;
  handleDeleteSelectedRow: (selectedRowIndex: number) => void;
  handleOnAddRack: () => void;
}
export const HVACFinalPage = ({
  tableDataLoading,
  handleOnCopyOk,
  handleDeleteSelectedRow,
  handleOnAddRack,
}: HVACFinalPageProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Div mt="10px">
        <LayoutFlowTitle
          title={t('t87')}
          subTitle={t('t3375')}
          helpText={t('t3372')}
        />
        <HVACFinalTable
          tableDataLoading={tableDataLoading}
          handleOnCopyOk={handleOnCopyOk}
          handleDeleteSelectedRow={handleDeleteSelectedRow}
          handleOnAddRack={handleOnAddRack}
        />
      </Div>
    </>
  );
};
