import '@danfoss/etui-sm-xml';
import '../utils/check-network.js';
import '../_rollupPluginBabelHelpers-e1ae8e90.js';
import '@danfoss/etui-core';
import '../constants/session-storage-constants.js';
import { SOFTWARE_VERSION_PREFIX } from '../constants/software-versions.js';
import { UPDATE_TYPE } from '../constants/software-update-type.js';
import '../utils/get-software-version-prefix.js';
import { getIsMatchedSoftwareVersion } from '../utils/get-is-matched-software-version.js';
import '../utils/get-language-code-by-name.js';
import '../utils/get-measurements-by-unit.js';
import '../utils/get-user-from-storage.js';
import '../utils/compare-software-versions.js';
import '../utils/get-version-from-filename.js';
import '../actions/authorize-units.js';
import '../actions/fetch-current-software.js';
import '../actions/fetch-initial-unit.js';
import '../fetch-latest-version-filename-d4771f3b.js';
import '../actions/fetch-metadata-files.js';
import '../actions/fetch-other-units.js';
import '../get-latest-os-version-779b9c8f.js';
import { getIpFromXmlBackendIp, validatePublicIpInInternalUnit, validatePrivateIpInExternalUnit, validateConnectedIp, validateMacAddrForMasterSlaveUnits, checkMultipleWarnings, checkLatestOSAvailable } from '../utils/check-unit-configuration.js';
import '../utils/get-first-valid-unit-urls.js';
import '../utils/get-current-unit.js';
import '../utils/get-is-misc-device.js';
import React from 'react';
import 'react-i18next';
import 'use-deep-compare-effect';
import '../context/app/utils/get-xml-backend-ip.js';
import '../context/app/utils/get-xml-backend-protocol.js';
import '../context/app/utils/get-xml-proxy-config.js';
import '../context/app/utils/set-xml-proxy-config.js';
import '../context/app/app-context.js';
import '../context/app/hooks/use-app.js';
import '../context/app/hooks/use-xml-resource.js';
import { checkForAccessRights } from '../context/auth/utils/check-auth.js';
import '@danfoss/etui-sm-i18n';
import '../context/auth/utils/logout-clean-up.js';
import '../context/auth/utils/set-user-to-storage.js';
import '../context/auth/auth-context.js';
import '../context/auth/hooks/use-auth.js';
import 'styled-components';
import '../context/unit/utils/duplicate-session-info.js';
import '../context/unit/unit-actions.js';
import '../context/unit/unit-context.js';
import { useUnit } from '../context/unit/hooks/use-unit.js';
import '../modules/SystemModule/components/SettingsSoftwareUpdateModal/context/SoftwareUpdateContext.js';
import '../modules/SystemModule/components/SettingsSoftwareUpdateModal/context/hooks/use-multi-unit-update.js';
import { u as useUpdateTypeValues } from '../use-update-type-values-02406e44.js';

function useUnitConfiguration(units, unitInfo, xmlBackendIp, user) {
  const {
    getFirstValidUrl
  } = useUnit();
  const {
    latestFileUrl
  } = useUpdateTypeValues(UPDATE_TYPE.SPK);
  const COMPUND_INDEX = 0;
  const [latestOSVersionAvailable, setLatestOSVersionAvailable] = React.useState(false);
  const isSM800A = getIsMatchedSoftwareVersion(units, [SOFTWARE_VERSION_PREFIX.G09, SOFTWARE_VERSION_PREFIX.G10]);
  React.useEffect(() => {
    const checkAvailableOSVersion = async () => {
      const latestVersionAvailable = await checkLatestOSAvailable(units, latestFileUrl, getFirstValidUrl, user);
      setLatestOSVersionAvailable(latestVersionAvailable);
    };
    if (units.length && isSM800A && checkForAccessRights(user === null || user === void 0 ? void 0 : user.authtype, COMPUND_INDEX) && !privateIpInExternalUnit) {
      checkAvailableOSVersion();
    }
  }, [units.length]);
  const ip = getIpFromXmlBackendIp(xmlBackendIp);
  const publicIpInInternalUnit = validatePublicIpInInternalUnit(unitInfo);
  const privateIpInExternalUnit = validatePrivateIpInExternalUnit(unitInfo);
  const connectedIpNotFound = validateConnectedIp(unitInfo, ip, units);
  const macAddrSameForMasterSlaveUnits = validateMacAddrForMasterSlaveUnits(units);
  const warnings = [];
  warnings.push(publicIpInInternalUnit);
  warnings.push(privateIpInExternalUnit);
  warnings.push(connectedIpNotFound);
  warnings.push(macAddrSameForMasterSlaveUnits);
  const multipleWarnings = checkMultipleWarnings(warnings);
  return {
    publicIpInInternalUnit,
    privateIpInExternalUnit,
    connectedIpNotFound,
    macAddrSameForMasterSlaveUnits,
    multipleWarnings,
    latestOSVersionAvailable
  };
}

export { useUnitConfiguration };
