import * as React from 'react';

export function AppProvide({
  children,
  providers,
}: {
  children: React.ReactNode;
  providers: (
    | React.ComponentType
    // TODO explore better typechecking here
    | [React.ComponentType, { [key: string]: any }]
  )[];
}) {
  return (
    <>
      {providers.reduceRight((componentTree, cfg) => {
        const [Provider, props] = Array.isArray(cfg) ? cfg : [cfg, {}];
        return <Provider {...props}>{componentTree}</Provider>;
      }, children)}
    </>
  );
}
