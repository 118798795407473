import { Unit, Device } from '@danfoss/etui-sm-xml';

function getUnitByDevice(units: Unit[], device: Device) {
  if (units?.length > 0 && device) {
    return units.find(unit => unit.unit_addr === device.host);
  }
  return null;
}

export { getUnitByDevice };
