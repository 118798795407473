import * as React from 'react';
import { dates } from '@danfoss/etui-core';
import { CALENDAR_VIEW_FORMATS } from '@danfoss/etui-sm-xml';
import {
  RefrigerationSchedules,
  ScheduleTypes,
} from 'pages/SchedulesPage/SchedulesPage.types';
import {
  isOpenHoursLaterThanCloseHours,
  convertTimeFormats,
  getOffTimeDayIndex,
  getWeekdayIndex,
  NO_INFO,
  updateScheduleOffTime,
} from 'pages/SchedulesPage/utils';
import { IStoreHours } from 'pages/SchedulesPage/context';
import {
  getScheduleDates,
  getScheduleDatesFromIndexes,
} from './getScheduleDates';
import { getStoreDay } from './getStoreDay';
import { getWeekDays } from './getWeekDays';
import { prepareSchedules } from './prepareSchedules';
import { generateScheduleIdbyType } from './generateScheduleIdbyType';
import { filterIdsByScheduleType } from './filterIdsByScheduleType';
import { sumRelativeTime } from './sumRelativeTime';
import { buildScheduleGraphicOptions } from './buildScheduleGraphicOptions';

type ScheduleDayMapping = {
  [key: string]: any[];
};

export const DAYS_IN_A_WEEK = 7;

export const getSchedules = ({
  scheduleData,
  scheduleType,
  timeFormat,
  storeHours,
  storeWeekdays,
  t,
  theme,
}) => {
  const schedulesDayMapping = getWeekDays(storeWeekdays) as ScheduleDayMapping;
  const defrostSchedules = scheduleData.map((day: RefrigerationSchedules) => {
    const weekDayIndex = day.weekdays ? getWeekdayIndex(day.weekdays) : null;
    const weekDayNames = weekDayIndex
      ? getScheduleDatesFromIndexes(storeWeekdays, weekDayIndex)
      : getScheduleDates(storeWeekdays, day);

    const isRelativeMode = [day.on, day.on_time].some(
      time => time && /[+|-]/.test(time[0]),
    );

    return weekDayNames.map((activeDay: string) => {
      const storeDay = getStoreDay(day);
      const { on, off, on_time, off_time } = storeDay;
      const dayStoreHours: IStoreHours = storeHours.find(
        day => day.id === activeDay,
      );
      let openDayOffsetIndex = 0;
      let closeDayOffsetIndex = 0;

      if (isRelativeMode) {
        const [openTimeObject, openDayOffset] = sumRelativeTime(
          dayStoreHours.open,
          on || on_time,
          timeFormat,
          false,
          activeDay,
          storeDay,
          storeWeekdays,
        );
        openDayOffsetIndex = openDayOffset;

        const [closeTimeObject, closeDayOffset] = sumRelativeTime(
          dayStoreHours.tooltipCloseTime,
          off || off_time,
          timeFormat,
          dayStoreHours.tooltipCloseTime.join('') === '0000',
          activeDay,
          storeDay,
          storeWeekdays,
        );
        closeDayOffsetIndex = closeDayOffset;

        storeDay.open = openTimeObject;
        storeDay.close = closeTimeObject;
        storeDay.on_time = `${openTimeObject.hour}:${openTimeObject.minute}${
          openTimeObject.units || ''
        }`;
        storeDay.off_time = `${closeTimeObject.hour}:${closeTimeObject.minute}${
          closeTimeObject.units || ''
        }`;

        if (
          isOpenHoursLaterThanCloseHours(storeDay.on_time, storeDay.off_time) &&
          !openDayOffsetIndex &&
          !closeDayOffsetIndex
        ) {
          storeDay.on_time = null;
          storeDay.off_time = null;
        }
      } else if (scheduleType === ScheduleTypes.LIGHTING) {
        storeDay.on_time = on;
        storeDay.off_time = off;
      }

      const activeDayIndex = storeWeekdays.indexOf(activeDay);
      const onTimeDayIndex = activeDayIndex + openDayOffsetIndex;

      const offTimeDayIndex = isRelativeMode
        ? activeDayIndex + closeDayOffsetIndex
        : storeDay.off_time === NO_INFO
        ? onTimeDayIndex
        : getOffTimeDayIndex(
            activeDay,
            storeDay,
            onTimeDayIndex,
            storeWeekdays,
          );

      if (storeDay.off_time === NO_INFO) {
        storeDay.off_time = storeDay.on_time;
      }

      const updatedOffTime =
        closeDayOffsetIndex > 0 &&
        updateScheduleOffTime(
          activeDay,
          storeDay,
          timeFormat,
          storeWeekdays,
          isRelativeMode,
        );

      const convertedOpenTime = convertTimeFormats(
        CALENDAR_VIEW_FORMATS.FORMAT_12,
        CALENDAR_VIEW_FORMATS.FORMAT_24,
        storeDay.on_time,
      ).split(':');

      const convertedCloseTime = convertTimeFormats(
        CALENDAR_VIEW_FORMATS.FORMAT_12,
        CALENDAR_VIEW_FORMATS.FORMAT_24,
        updatedOffTime || storeDay.off_time,
      ).split(':');

      schedulesDayMapping[activeDay].push(storeDay);

      const fallbackTitle = `${t('t3315')} - ${storeDay.index}`;
      const tooltipTitle = storeDay.name || fallbackTitle;
      const { open, close } = storeDay;
      const tooltipEventStartDate = open
        ? `${open.hour}:${open.minute} ${open.units || ''}`
        : NO_INFO;
      const tooltipEventEndDate = close
        ? `${close.hour}:${close.minute} ${close.units || ''}`
        : NO_INFO;
      const tooltipCases = day.cases?.map(caseItem => caseItem.name).join(', ');

      const startDate = dates.manipulateDate(
        onTimeDayIndex < 0
          ? DAYS_IN_A_WEEK + onTimeDayIndex
          : onTimeDayIndex % DAYS_IN_A_WEEK,
        +convertedOpenTime[0],
        +convertedOpenTime[1],
      );

      const endDate = dates.manipulateDate(
        offTimeDayIndex % DAYS_IN_A_WEEK,
        +convertedCloseTime[0],
        +convertedCloseTime[1],
      );

      const finalSchedule = {
        id: activeDay,
        innerId: generateScheduleIdbyType(storeDay, scheduleType),
        startDate,
        endDate,
        tooltipMessage: tooltipTitle,
        // TODO: create separate component for tooltip
        tooltipContent: (
          <>
            <div
              style={{
                display: 'flex',
                minWidth: '150px',
                justifyContent: 'space-between',
              }}
            >
              <div>{t('t3232')}</div>
              <div>{tooltipEventStartDate}</div>
            </div>
            <div
              style={{
                display: 'flex',
                minWidth: '150px',
                justifyContent: 'space-between',
              }}
            >
              <div>{t('t3233')}</div>
              <div>{tooltipEventEndDate}</div>
            </div>
            {tooltipCases && (
              <div style={{ whiteSpace: 'pre-line' }}>
                <b>{t('t956')}:</b>
                <br />
                {tooltipCases}
              </div>
            )}
          </>
        ),
        hideEventMarkers: true,
      };

      return finalSchedule;
    });
  });

  const uniqueIdsByDays = Object.entries(schedulesDayMapping).map(
    ([day, schedules]) => ({
      [day]: filterIdsByScheduleType(schedules, scheduleType),
    }),
  );
  const scheduleGraphicOptions = buildScheduleGraphicOptions(uniqueIdsByDays);
  const schedules = prepareSchedules(
    defrostSchedules,
    scheduleGraphicOptions,
    theme,
  );
  return schedules;
};
