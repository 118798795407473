const DEFAULT = 0;
let counter = DEFAULT;
function nextUuid() {
  const current = counter;
  counter += 1;
  return current;
}
function resetNextUuid() {
  counter = DEFAULT;
}

export { nextUuid, resetNextUuid };
