function toHex(n: number): string {
  return n.toString(16).padStart(2, '0');
}

export function* color(freq: number = 0.7): Iterator<string> {
  let step = 0;
  while (true) {
    const rgb = [
      Math.round(Math.sin(freq * step + 0) * 127 + 128),
      Math.round(Math.sin(freq * step + 2) * 127 + 128),
      Math.round(Math.sin(freq * step + 4) * 127 + 128),
    ];
    yield `#${rgb.map(toHex).join('')}`;
    step++;
  }
}
