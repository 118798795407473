import { Div } from '@danfoss/etui-system-elements';
import { Alert } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { CopyItemContentList } from '../CopyItemContentList';
import { CopyFlowTitle } from '../CopyContent';

export type SourceControllerViewProps = {
  tableAddress: string;
};

export const SourceControllerFlowContent = ({
  tableAddress,
}: SourceControllerViewProps) => {
  const { t } = useTranslation();
  return (
    <Div testId="source-controller-div" mt="10px" height="100%">
      <Alert type="warning" message={t('t3394')} />
      <CopyFlowTitle title={t('t3387')} subTitle={t('t3395')} />
      <Div height="100%">
        <CopyItemContentList tableAddress={tableAddress} />
      </Div>
    </Div>
  );
};
