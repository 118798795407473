function generateDynListObject(dynListString) {
  const labels = [];
  if (dynListString) {
    for (const el of dynListString.split(';')) {
      const ell = el.trim().split(':');
      const label = {
        name: ell[0],
        value: ell[1]
      };
      labels.push(label);
    }
  }
  return labels;
}

export { generateDynListObject };
