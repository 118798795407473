import { DeviceGrouped } from '@danfoss/etui-sm';
import { Device, Rack, XML_DEVICE_STYPE } from '@danfoss/etui-sm-xml';

export function getRackByGroupedDevices(
  device: DeviceGrouped,
  groupedDevices: DeviceGrouped<Device>[],
) {
  return groupedDevices.find(rootDevice => {
    if (rootDevice.host === device.host) {
      if (
        (rootDevice.arg1 === device.arg2 && rootDevice.unit === device.unit) ||
        ((device.arg2 === '' || device.arg2 === undefined) &&
          device.stype !== XML_DEVICE_STYPE.S_TYPE_EVAP &&
          device.arg1 === rootDevice.arg1)
      ) {
        return rootDevice;
      }
      return null;
    }
    return null;
  }) as unknown as DeviceGrouped<Rack>;
}
