import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { AnimatedSpinner, ErrorIcon } from '@danfoss/etui-sm';

function ConfigurationListItemEditLoadingOverlay({
  isError,
}: {
  isError?: boolean;
}) {
  return (
    <Div
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      cursor="progress"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {isError ? <ErrorIcon show={true} /> : <AnimatedSpinner />}
      <Div
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        bg="white"
        opacity={0.5}
      />
    </Div>
  );
}

export { ConfigurationListItemEditLoadingOverlay };
