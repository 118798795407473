const timeoutMap = new Map();
const setUniqueTimout = (timeoutId, ms, cb) => {
  const thread = window.setTimeout(cb, ms);
  timeoutMap.set(timeoutId, thread);
  return timeoutId;
};
const cleanUniqueTimeout = timeoutId => {
  const tmp = timeoutMap.get(timeoutId);
  if (tmp !== undefined) {
    timeoutMap.delete(timeoutId);
    window.clearInterval(tmp);
  }
};

export { cleanUniqueTimeout, setUniqueTimout };
