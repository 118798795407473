import * as React from 'react';
import { icons, Modal, ModalHeader, useResponsive } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import {
  SecurityBrowserCertificateProvider,
  BrowserCertificateTabsCommandBar,
  BrowserCertificateTabsContent,
} from '@danfoss/etui-sm/modules';
import { SettingsBrowserCertificateModalActions } from './SettingsBrowserCertificateModalActions';

export interface SettingsBrowserCertificateModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SettingsBrowserCertificateModal({
  isOpen,
  onClose,
}: SettingsBrowserCertificateModalProps) {
  const theme = useTheme();

  const { screenIsAtMost } = useResponsive({
    xs: parseInt(theme.breakpoints[0], 10),
  });

  const isXs = screenIsAtMost('xs', ['portrait', 'landscape']);

  return (
    <SecurityBrowserCertificateProvider>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <ModalHeader
            onClose={onClose}
            icon={{ glyph: icons.KEY }}
            title="Browser certificate"
          />
        }
        actions={<SettingsBrowserCertificateModalActions />}
        animationTiming={250}
        fullPageSize={isXs}
        shouldCloseOnOverlayClick={false}
        styles={{
          contentContainer: {
            overflow: 'unset',
            overflowX: 'hidden',
            overflowY: 'scroll',
            minWidth: [320, 480],
            minHeight: 500,
            p: 0,
          },
        }}
      >
        <BrowserCertificateTabsCommandBar />
        <BrowserCertificateTabsContent />
      </Modal>
    </SecurityBrowserCertificateProvider>
  );
}
