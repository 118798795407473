import { useState, useCallback } from 'react';

export function useLocalStorage<T>(
  key: string,
  initialValue?: T,
): [T, (val: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        return JSON.parse(item);
      }
      window.localStorage.setItem(key, JSON.stringify(initialValue));

      return initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // TODO
    }
  };

  return [storedValue, setValue];
}

export function useLocalStorageSet<T>(key: string) {
  const [value, setValue] = useLocalStorage<T[]>(key, []);

  const add = useCallback(
    (item: T) => {
      try {
        const newVal = Array.from(new Set([...value, item]));
        setValue(newVal);
        window.localStorage.setItem(key, JSON.stringify(newVal));
      } catch (e) {
        // If user is in private mode or has storage restriction
        // localStorage can throw.
      }
    },
    [value, key, setValue],
  );

  const remove = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key]);

  return {
    all: value,
    add,
    remove,
  };
}
