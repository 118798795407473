import { AkioShutdownSchedules, Refrigerations } from '../SchedulesPage.types';

export const filterCaseLevelAkioShutdownSchedules = (
  refrigertaions: Refrigerations[],
  scheduleType: string,
): AkioShutdownSchedules[] => {
  return refrigertaions
    ?.find(refrigeration => refrigeration?.id === scheduleType)
    ?.items?.find(item =>
      item?.items?.find(subItems => subItems?.akioShutdownSchedules),
    )
    ?.items?.find(item => item.akioShutdownSchedules)?.akioShutdownSchedules;
};
