import * as React from 'react';
import { useModal } from '@danfoss/etui-sm';
import { PasswordPrompt } from '../components/AddressBook/PasswordPrompt';

export function usePasswordPrompt() {
  const [promptResolver, setPromptResolver] = React.useState(() => {});
  const [promptMessage, setPromptMessage] = React.useState('');

  const [showPasswordPrompt, closePasswordPrompt] = useModal(PasswordPrompt, {
    onResolve: promptResolver,
    promptMessage,
  });

  const handlePromptResolver = func => setPromptResolver(() => func);

  return {
    setPromptMessage,
    onPromptResolve: handlePromptResolver,
    showPasswordPrompt,
    closePasswordPrompt,
  };
}
