import {
  fetchAlarmsByRefs,
  User,
  getUnitUrl,
  Unit,
} from '@danfoss/etui-sm-xml';
import { AlarmType } from '../constants';
import { getAlarmRefsPaginatedGroupedByUnit } from '../utils';
import { fetchAlarmRefsByUnit } from './fetch-alarm-refs-by-unit';

export async function fetchAlarmsPaginatedByUnit(
  url: string,
  alarmType: AlarmType,
  pageIndex: number,
  pageSize: number,
  alarmRefsGroupedByUnit: { [key: string]: string[] },
  user: User,
  unit: Unit,
  skipSessionUpdate?: boolean,
) {
  const unitUrl = getUnitUrl(url, unit);
  const unitAddress = unit.unit_addr;

  try {
    const unitAlarmRefsPaginated = alarmRefsGroupedByUnit[unitAddress];
    const alarms = await fetchAlarmsByRefs(
      unitUrl,
      unitAlarmRefsPaginated,
      user,
      skipSessionUpdate,
    );
    return alarms;
  } catch (error) {
    const newAlarmRefs = await fetchAlarmRefsByUnit(
      url,
      alarmType,
      user,
      unit,
      skipSessionUpdate,
    );
    const newAlarmRefsGroupedByUnit = getAlarmRefsPaginatedGroupedByUnit(
      newAlarmRefs,
      pageIndex,
      pageSize,
    );
    const newAlarms = await fetchAlarmsByRefs(
      unitUrl,
      newAlarmRefsGroupedByUnit[unitAddress],
      user,
      skipSessionUpdate,
    );
    return newAlarms;
  }
}
