import { fetchXMLFileData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function fetchGenerateSscFile(user, url) {
  await fetchXMLFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action: XML_ACTION.GENERATE_SSC_FILE
  });
}

export { fetchGenerateSscFile };
