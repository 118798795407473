import { fetchXMLData, User, XML_ACTION } from '@danfoss/etui-sm-xml';

interface ReadConfigFileResponse {
  action: string;
  error: string;
  b64: string[];
  bpidx: string;
  device_id: string;
  num_bytes: string;
  num_lines: string;
  model_name: string;
  storeview_only: string;
  user: string;
  version: string;
  operation: string;
  offset: string;
  enc_bytes: string;
  lang: string;
}

interface FileConfigResponse {
  fileContent: string;
  fileName: string;
}

async function fetchCopyConfigFile(url: string, user: User, bnum) {
  try {
    const data: ReadConfigFileResponse = await fetchXMLData({
      url,
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        storeview_only: '1',
        version: 'C',
        bpidx: bnum,
        offset: 0,
        action: XML_ACTION.READ_CONFIG_FILE,
      },
    });

    const b64 = data?.b64;
    let b64Content = '';
    b64.forEach(b => {
      b64Content += `<b64> ${b}</b64>`;
    });
    const opearation = `<operation>${data?.operation}</operation>`;
    const modelName = `<model_name>${data?.model_name}</model_name>`;
    const deviceId = `<device_id>${data?.device_id}</device_id>`;
    const encBytes = `<enc_bytes>${data?.enc_bytes}</enc_bytes>`;
    const numLines = `<num_lines>${data?.num_lines}</num_lines>`;
    const numBytes = `<num_bytes>${data?.num_bytes}</num_bytes>`;

    const fileContent = `<resp bpidx= "${data?.bpidx}" action="${data?.action}" lang="${data?.lang}" offset="${data?.offset}" storeview_only="${data?.storeview_only}" version="${data?.version}" error="${data?.error}">${b64Content}${opearation}${modelName}${deviceId}${encBytes}${numLines}${numBytes}</resp>`;

    const fileResponse: FileConfigResponse = {
      fileContent,
      fileName: data?.model_name,
    };
    return fileResponse;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}

export { fetchCopyConfigFile };
