import { getIsValidIpAndPort } from '@danfoss/etui-sm-xml';
import { STORAGE_XML_BACKEND_IP_KEY } from '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';

/**
 * Gets xml ip from local storage if exists
 * Fallbacks to get it from document.location.host in production.
 * Used to set the xmlBackendIp for later usage in the application
 *
 * @returns {AppState['xmlBackendIp']}
 */
function getXmlBackendIp() {
  const xmlBackendIp = sessionStorage.getItem(STORAGE_XML_BACKEND_IP_KEY);
  if (xmlBackendIp) {
    return xmlBackendIp;
  }

  // Sets the xmlBackendIp from document.location.host only if it is a IP
  // This will be the case when it is used directly from the target
  const xmlBackendIpFromHost = process.env.NODE_ENV === 'production' ? getIsValidIpAndPort(document.location.host, false) ? document.location.host : null : null;
  if (xmlBackendIpFromHost) {
    return xmlBackendIpFromHost;
  }
  return null;
}

export { getXmlBackendIp };
