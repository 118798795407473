import '@danfoss/etui-sm-xml';
import '../../_rollupPluginBabelHelpers-e1ae8e90.js';
import '@danfoss/etui-core';
import '../../constants/session-storage-constants.js';
import '../../constants/software-versions.js';
import '../../utils/get-software-version-prefix.js';
import '../../utils/get-is-matched-software-version.js';
import '../../utils/get-language-code-by-name.js';
import '../../utils/get-measurements-by-unit.js';
import '../../utils/get-user-from-storage.js';
import '../../actions/authorize-units.js';
import '../../actions/fetch-initial-unit.js';
import '../../actions/fetch-metadata-files.js';
import '../../actions/fetch-other-units.js';
import '../../utils/get-first-valid-unit-urls.js';
import '../../utils/get-current-unit.js';
import '../../utils/get-is-misc-device.js';
import { useContext } from 'react';
import 'react-i18next';
import 'swr';
import '../modal/modal-context.js';
import '../modal/use-modal.js';
import 'use-deep-compare-effect';
import '../app/utils/get-xml-backend-ip.js';
import '../app/utils/get-xml-backend-protocol.js';
import '../app/utils/get-xml-proxy-config.js';
import '../app/utils/set-xml-proxy-config.js';
import '../app/app-context.js';
import '../app/hooks/use-app.js';
import '../app/hooks/use-xml-resource.js';
import '@danfoss/etui-sm-i18n';
import '../auth/utils/logout-clean-up.js';
import '../auth/utils/set-user-to-storage.js';
import '../auth/auth-context.js';
import '../auth/hooks/use-auth.js';
import 'styled-components';
import '../unit/utils/duplicate-session-info.js';
import '../unit/unit-actions.js';
import '../unit/unit-context.js';
import '../unit/hooks/use-unit.js';
import './utils/fetch-alarm-refs-by-units.js';
import { AlarmContext } from './alarm-context.js';

function useAlarm() {
  const context = useContext(AlarmContext);
  if (!context) {
    throw new Error(`useAlarm must be used within a AlarmProvider`);
  }
  const {
    activeAlarmsRefs,
    ackedAlarmsRefs,
    clearedAlarmsRefs,
    revalidateAlarms,
    setShouldRequest
  } = context;
  return {
    activeAlarmsRefs,
    ackedAlarmsRefs,
    clearedAlarmsRefs,
    revalidateAlarms,
    setShouldRequest
  };
}

export { useAlarm };
