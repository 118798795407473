import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { UnitNetwork } from '@danfoss/etui-sm-xml/';
import { useTranslation } from 'react-i18next';
import { getArray } from 'pages/ConfigurationPage';
import { UnitInfoType } from '@danfoss/etui-sm';
import { getUnitDetails } from '../../utils';
import { DataItem } from '../DataItem';
import { Seperator } from '../Seperator';

export interface OfflineUnitInfoProps {
  network: UnitNetwork;
  unitInfo: UnitInfoType;
  isPublicIpInInternalUnit: boolean;
  isPrivateIpInExternalUnit: boolean;
}
export function OfflineUnitInfo({
  network,
  unitInfo,
  isPublicIpInInternalUnit,
  isPrivateIpInExternalUnit,
}: OfflineUnitInfoProps) {
  const { t } = useTranslation();
  return (
    <Div testId="offline-unit-info" flex="1">
      <Seperator />
      <DataItem
        label={t('t889')}
        value={getUnitDetails(getArray(unitInfo.internalUnits), network).name}
      />
      <Seperator />
      <DataItem
        label={t('t3306')}
        value={getUnitDetails(getArray(unitInfo.internalUnits), network).ip}
        warning={isPublicIpInInternalUnit ? t('t3295') : ''}
      />
      <Seperator />
      <DataItem
        label={t('t3323')}
        value={getUnitDetails(getArray(unitInfo.internalUnits), network).port}
      />
      <Seperator />
      <DataItem
        label={t('t3307')}
        value={getUnitDetails(getArray(unitInfo.externalUnits), network).ip}
        warning={isPrivateIpInExternalUnit ? t('t3296') : ''}
      />
      <Seperator />
      <DataItem
        label={t('t3322')}
        value={getUnitDetails(getArray(unitInfo.externalUnits), network).port}
      />
      <Seperator />
    </Div>
  );
}
