import React from 'react';
import { useHistory } from 'react-router-dom';
import { Div } from '@danfoss/etui-system-elements';
import { Spinner, SpinnerSize } from '@danfoss/etui-core';
import { ConfigurationTabByUnitResponse } from '../actions';
import { TABLE_ADDRESS } from '../utils';
import { PollingStatusResponse } from '../components/ConfigurationItemContentList/types';
import { ConfigurationCustomActionsContext } from './ConfigurationCustomActionsContext';

export function ConfigurationCustomActionsProvider({ children }) {
  const {
    location: { pathname },
  } = useHistory();
  const [isDownloadPage, setIsDownloadPage] = React.useState<boolean>(false);
  const [menuId, setMenuId] = React.useState<string>('');
  const [isDownloadEnabledInNetworks, setIsDownloadEnabledInNetworks] =
    React.useState<boolean>(false);
  const [data, setData] = React.useState<ConfigurationTabByUnitResponse>(
    null as ConfigurationTabByUnitResponse,
  );
  const [isDownloadInprogressInNetworks, setIsDownloadInprogressInNetworks] =
    React.useState<boolean>(false);
  const [isDownloadComplete, setIsDownloadComplete] =
    React.useState<boolean>(false);
  const [pollingData, setPollingData] =
    React.useState<PollingStatusResponse>(null);

  const onSetPollingData = (data: PollingStatusResponse) => {
    data && pollingData !== data && setPollingData(data);
  };

  const progressText = (
    isDownloadInprogressInNetworks: boolean,
    data: ConfigurationTabByUnitResponse,
  ): JSX.Element => {
    return (
      <>
        {isDownloadInprogressInNetworks && data.list.length > 3 ? (
          <Div alignItems={'center'} textAlign={'center'} m={'6px'}>
            <Div m={'0px 0px 15px 0px'}>{data.list[3].name}</Div>
            <Spinner size={SpinnerSize.small} />
            <Div m={'7px 0px 0px 0px'}>{data.list[4].name}</Div>
          </Div>
        ) : null}
      </>
    );
  };

  React.useEffect(() => {
    if (
      data &&
      isDownloadPage &&
      data.list?.length > 3 &&
      isDownloadEnabledInNetworks
    ) {
      if (
        data.list[3].name.trim().length > 0 &&
        data.list[4].name.trim().length > 0
      ) {
        setIsDownloadInprogressInNetworks(true);
      } else {
        setIsDownloadInprogressInNetworks(false);
        setIsDownloadEnabledInNetworks(false);
        setIsDownloadComplete(true);
      }
    }
  }, [data]);

  React.useEffect(() => {
    setIsDownloadPage(false);
    setMenuId('');
    setIsDownloadEnabledInNetworks(false);
  }, [pathname]);

  const onSetMenuId = (newMenuId: string) => {
    if (newMenuId === menuId) return;
    setMenuId(newMenuId);
    if (newMenuId === TABLE_ADDRESS.NETWORK_NODES_DOWNLOAD_PAGE) {
      setIsDownloadPage(true);
    }
  };

  const onSetIsDownloadEnabledInNetworks = (value: boolean) => {
    if (value) {
      setIsDownloadComplete(false);
    }
    isDownloadEnabledInNetworks !== value &&
      setIsDownloadEnabledInNetworks(value);
  };

  const onSetData = (data: ConfigurationTabByUnitResponse) => {
    data && setData(data);
  };

  const onsetIsDownloadComplete = () => {
    if (isDownloadInprogressInNetworks) return;
    setIsDownloadEnabledInNetworks(false);
    setIsDownloadComplete(true);
  };

  return (
    <ConfigurationCustomActionsContext.Provider
      value={{
        isDownloadPage,
        menuId,
        isDownloadEnabledInNetworks,
        data,
        isDownloadInprogressInNetworks,
        isDownloadComplete,
        pollingData,
        progressText,
        setMenuId: onSetMenuId,
        setIsDownloadEnabledInNetworks: onSetIsDownloadEnabledInNetworks,
        setData: onSetData,
        setDownloadComplete: onsetIsDownloadComplete,
        setPollingData: onSetPollingData,
      }}
    >
      {children}
    </ConfigurationCustomActionsContext.Provider>
  );
}
