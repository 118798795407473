import { XML_DEVICE_LIST } from '@danfoss/etui-sm-xml';

export function getDeviceTypeForListData(deviceType: number): XML_DEVICE_LIST {
  let type = 0;

  switch (deviceType) {
    case 1: // EVAP DEVICELIST_T_EVAP
    case 2: // RACK DEVICELIST_T_RACK
    case 3: // HVAC DEVICELIST_T_HVAC
    case 4: // Meter DEVICELIST_T_METER
    case 5: // Drive DEVICELIST_T_DRIVE
    case 6: // EC Fan DEVICELIST_T_FANS
    case 7: // Light DEVICELIST_T_LIGHT
    case 8: // Leak DEVICELIST_T_LEAK
    case 9: // Leak DEVICELIST_T_CORE
      switch (deviceType) {
        case 1:
          type = XML_DEVICE_LIST.T_EVAP;
          break;
        case 2:
          type = XML_DEVICE_LIST.T_RACK;
          break;
        case 3:
          type = XML_DEVICE_LIST.T_HVAC;
          break;
        case 4:
          type = XML_DEVICE_LIST.T_METER;
          break;
        case 5:
          type = XML_DEVICE_LIST.T_DRIVE;
          break;
        case 6:
          type = XML_DEVICE_LIST.T_FANS;
          break;
        case 7:
          type = XML_DEVICE_LIST.T_LIGHT;
          break;
        case 8:
          type = XML_DEVICE_LIST.T_LEAK;
          break;
        case 9:
          type = XML_DEVICE_LIST.T_CORE;
          break;
        default:
          return null;
      }

      return type;
    default:
      return null;
  }
}

export function isListTypeData(deviceType: number): boolean {
  switch (deviceType) {
    case 250: // LIST_TYPE_ANALOG_SI for sensor list address table.
    case 251: // LIST_TYPE_DEVICE_MANAGEMENT
    case 252: // LIST_TYPE_FILE_MANAGEMENT
    case 253: // LIST_TYPE_HISTORY_SAMPLE
    case 254: // LIST_TYPE_ALARM return alarm action list.
    case 255: // LIST_TYPE_IMPORT return import si and oi
      return true;
    default:
      return false;
  }
}
