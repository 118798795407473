function mapBinaryStringToBlob(binaryString) {
  const binaryLen = binaryString.length;
  const ab = new ArrayBuffer(binaryLen);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < binaryLen; i++) {
    ia[i] = binaryString.charCodeAt(i);
  }
  return new Blob([ab]);
}

export { mapBinaryStringToBlob };
