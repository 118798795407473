import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import { XML_ACTION } from '../../constants/xml-action-constant.js';
import '../../constants/xml-tag-constant.js';
import { historyRequestString } from '../../constants/custom-request-constant.js';
import '../../types/Auth.js';
import '../../types/Metadata.js';
import '../../types/Unit.js';
import { _ as _objectSpread2 } from '../../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import '../../helpers/xml-helpers.js';
import '../../helpers/session-token-helpers.js';
import '../../helpers/trim-url-ending.js';
import '../../helpers/metadata-helpers.js';
import '../../helpers/header-helpers.js';
import '../../helpers/is-allowed-server-error.js';
import '../../helpers/proxy-helpers.js';
import '../../helpers/url-helpers/get-is-valid-dns.js';
import '../../helpers/url-helpers/get-is-valid-ip.js';
import '../../helpers/url-helpers/get-is-valid-ip-or-dns.js';
import '../../helpers/url-helpers/get-is-valid-port.js';
import '../../helpers/url-helpers/get-url-info.js';
import '../../helpers/unit-helpers/initial-unit-utils.js';
import '../../helpers/unit-helpers/get-unit-networks-by-unit.js';
import '../../helpers/unit-helpers/get-unit-network-by-unit.js';
import '../../helpers/unit-helpers/get-unit-url-by-unit-network.js';
import { getUnitUrl } from '../../helpers/unit-helpers/get-unit-url.js';
import '../../helpers/xml-error.js';
import '../../proxy-config.js';
import 'csvtojson';
import '../../fetch-xml/fetch-xml.js';
import { fetchXMLData } from '../../fetch-xml/fetch-xml-data.js';

function isHistoryRequest(action) {
  return action === historyRequestString;
}
async function fetchMiscDevicesByUnit(sourceUrl, unit, user, index, skipSessionUpdate, customAction) {
  const actions = isHistoryRequest(customAction) ? [XML_ACTION.READ_MISC, XML_ACTION.READ_POINTS_OI, XML_ACTION.READ_POINTS_RO, XML_ACTION.READ_POINTS_SI, XML_ACTION.READ_POINTS_VO] : [XML_ACTION.READ_MISC];
  const miscRequests = actions.map(action => fetchXMLData({
    url: getUnitUrl(sourceUrl, unit),
    attributes: _objectSpread2({
      user: user.user,
      password: user.password,
      lang: user.language,
      storeview_only: '1',
      version: 'C',
      action,
      index
    }, skipSessionUpdate ? {
      session_update: 'no'
    } : {})
  }));
  const responses = await Promise.allSettled(miscRequests);
  const mergedDevices = responses.reduce((acc, response) => {
    if (response.status === 'fulfilled' && response.value.device) {
      return acc.concat(response.value.device);
    }
    return acc;
  }, []);
  return {
    device: mergedDevices
  };
}

export { fetchMiscDevicesByUnit };
