import '../../constants/regex-patterns-constant.js';
import { getIsValidDns } from './get-is-valid-dns.js';
import { getIsValidIp } from './get-is-valid-ip.js';

/**
 * Validates ip or dns
 *
 * @param {string} ipOrDns
 * @returns {boolean}
 */
function getIsValidIpOrDns(ipOrDns) {
  return getIsValidIp(ipOrDns) || getIsValidDns(ipOrDns);
}

export { getIsValidIpOrDns };
