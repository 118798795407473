import { TreeItem } from '@danfoss/etui-core';

const getNormalizedData = (data: TreeItem[], type: string) => {
  const normalizedData = data
    .filter(item => item.name === type)
    .map(item => item?.items);

  return [].concat(...normalizedData);
};

export { getNormalizedData };
