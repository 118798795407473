import { STORAGE_METADATA_KEY, removeSelectedUnitFromStorage, removeInitialUnitFromStorage, getProxyConfig, getSessionTokensFromStorage, fetchXMLData, XML_ACTION, getUrlsWithSessionTokens, XML_SESSION_CMD, removeSessionTokens } from '@danfoss/etui-sm-xml';
import { STORAGE_PROXY_KEY, STORAGE_USER_AUTHLEVEL_KEY, STORAGE_USER_LANGUAGE_KEY, STORAGE_USER_PASSWORD_KEY, STORAGE_USER_USERNAME_KEY, STORAGE_XML_BACKEND_IP_KEY } from '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';

async function onLogoutCleanUp(isResetLogoutUser) {
  if (isResetLogoutUser === void 0) {
    isResetLogoutUser = false;
  }
  cleanSessionStorage();
  await removeVirtualUsers();
  !isResetLogoutUser && cleanSessionTokens();
}
const cleanSessionStorage = () => {
  sessionStorage.removeItem(STORAGE_METADATA_KEY);
  sessionStorage.removeItem(STORAGE_PROXY_KEY);
  sessionStorage.removeItem(STORAGE_USER_AUTHLEVEL_KEY);
  sessionStorage.removeItem(STORAGE_USER_LANGUAGE_KEY);
  sessionStorage.removeItem(STORAGE_USER_PASSWORD_KEY);
  sessionStorage.removeItem(STORAGE_USER_USERNAME_KEY);
  sessionStorage.removeItem(STORAGE_XML_BACKEND_IP_KEY);
  removeSelectedUnitFromStorage();
  removeInitialUnitFromStorage();
};
const removeVirtualUsers = async () => {
  const proxyCfg = getProxyConfig();
  if (!proxyCfg.proxyEnabled) return;
  const sessionTokens = getSessionTokensFromStorage();
  const sessionUrls = Object.keys(sessionTokens);
  await Promise.all(sessionUrls.map(url => fetchXMLData({
    url: `${url}/xml.cgi`,
    attributes: {
      action: XML_ACTION.LOG_OUT
    }
  })));
};
const cleanSessionTokens = async () => {
  const urls = getUrlsWithSessionTokens();
  try {
    await Promise.all(urls.map(url => fetchXMLData({
      url: `${url}/xml.cgi`,
      attributes: {
        session_cmd: XML_SESSION_CMD.CLEAR
      }
    })));
  } catch ({
    message
  }) {
    console.log(message);
  } finally {
    sessionStorage.removeItem(STORAGE_METADATA_KEY);
    removeSessionTokens();
  }
};

export { cleanSessionTokens, onLogoutCleanUp };
