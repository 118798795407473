import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { GraphTable, GraphTableContent } from '../../../types/RefrigCopy.types';
import { AllPageType } from '../../../context';
import { getArray } from '../../../../../../Configuration/utils';

export const processGraphTableData = (tableContentData: any[]) => {
  const tabledata = [];
  const tableContent = getArray(tableContentData);
  tableContent?.forEach(data => {
    const graphTableContentList: ConfigurationListItem[] = getArray(
      data?.items?.l,
    );

    graphTableContentList.forEach(item => {
      const result = item.name.split(/\s{4,8}/);
      const obj: GraphTable = {
        name: result[0],
        interval: result[result.length - 1],
        li: item,
        key: null,
        toggleVal: item.toggleVal,
      };
      tabledata.push(obj);
    });
  });
  return tabledata.flat();
};

export const filterGraphTableContentList = (
  allPageData: AllPageType[],
  searchValue: string,
) => {
  const filteredGraphTableContentList = allPageData?.filter(content => {
    const name = (content as GraphTableContent)?.name?.toLowerCase();
    const value = searchValue?.toLowerCase();
    return name.includes(value);
  });
  return getArray(filteredGraphTableContentList);
};

export const addPageToListItems = (
  listItems: ConfigurationListItem[],
  page: string,
) => {
  listItems?.forEach((_, index) => {
    listItems[index].page = page;
  });
  return listItems;
};
