import '../../utils/unique-timer.js';
import 'pako';
import { GraphState } from './GraphState.js';

class StateHandler {
  constructor(graph) {
    this.mxGraph = graph;
    this.states = new Map();
    this.init();
  }
  addState(mxCell) {
    const state = new GraphState(mxCell, this.mxGraph);
    this.states.set(mxCell.id, state);
  }
  init() {
    this.states.clear();
    const cells = this.mxGraph.getMxCells();
    // eslint-disable-next-line guard-for-in
    for (const cellId in cells) {
      this.addState(cells[cellId]);
    }
    return this;
  }
  setStates(rules, metrics) {
    rules.forEach(rule => {
      if (!rule.isHidden()) {
        const metricValue = this.getDataSourceMetricValue(rule.dataSource, metrics);
        const mappings = rule.mappings.getMappings();
        const conditions = rule.conditions.getConditions();
        this.states.forEach(state => state.setState(mappings, conditions, metricValue));
      }
    });
    this.mxGraph.refresh();
  }

  // eslint-disable-next-line class-methods-use-this
  getDataSourceMetricValue(dataSource, metrics) {
    const data = metrics.find(metric => metric.id === dataSource.getSourceId());
    if (data) {
      return data === null || data === void 0 ? void 0 : data.value;
    }
    return '';
  }
}

export { StateHandler };
