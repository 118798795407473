import * as React from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import {
  ConfigurationListItem,
  ConfigurationTabItem,
  Unit,
  XML_DEVICE_COMBO,
} from '@danfoss/etui-sm-xml';
import { ContainerDimensions } from '@danfoss/etui-core';
import { useHistory } from 'react-router-dom';
import { ConfigurationItemContentSkeletonItem } from '../ConfigurationItemContentSkeleton';
import { useConfiguration } from '../../context';
import { SaveFunc } from './ConfigurationListItemEdit';
import { ConfigurationListItem as ListItem } from './ConfigurationListItem';
import { ConfigurationListItemAddress } from './ConfigurationItemContentList';
import { EditType, getEditType, getHtmlId } from './utils';

export type ConfigurationItemVirtualizedListProps = {
  menuId: string;
  isUpdating?: boolean;
  isFailed: boolean;
  list: ConfigurationListItem[];
  tab: ConfigurationTabItem;
  getItemIsAuthorized: (item: ConfigurationListItem) => boolean;
  addresses: ConfigurationListItemAddress[];
  unit: Unit;
  tabIsEditable: boolean;
  onSave: SaveFunc;
  itemRenderer?: (...args: any[]) => React.ReactNode;
  resetConfigSwrCache: () => void;
};

const ConfigurationItemVirtualizedList = React.memo(
  function ConfigurationItemVirtualizedList({
    menuId,
    isUpdating,
    isFailed,
    list,
    tab,
    getItemIsAuthorized,
    addresses,
    unit,
    tabIsEditable,
    onSave,
    itemRenderer,
    resetConfigSwrCache,
  }: ConfigurationItemVirtualizedListProps) {
    if (isFailed || !list?.length) {
      return null;
    }

    const {
      location: { pathname },
    } = useHistory();
    const notEquipmentPage = !pathname.includes('equipment');

    const { multiPageById, menugroupsById, subgroupnamesById } =
      useConfiguration();

    const multipage = multiPageById[menuId];
    const menugroups = menugroupsById[menuId];
    const subgroupnames = subgroupnamesById[menuId];

    const hasMenu = React.useMemo(() => {
      const hasDeviceSelector =
        [XML_DEVICE_COMBO.COMBO_NONE, XML_DEVICE_COMBO.COMBO_UNITS].every(
          combo => combo !== tab?.combo,
        ) && notEquipmentPage;

      return (
        hasDeviceSelector ||
        menugroups?.length > 1 ||
        subgroupnames?.length > 1 ||
        multipage > '0'
      );
    }, [menuId, multipage, menugroups, subgroupnames, tab]);

    const ROW_HEIGHT_MIN = 42;
    const ROW_HEIGHT_MAX = 50;

    const getItemHasActionEditType = (item: ConfigurationListItem) =>
      getEditType(item) === EditType.Action;

    const getItemRowHeight = (item: ConfigurationListItem) =>
      getItemHasActionEditType(item) ? ROW_HEIGHT_MAX : ROW_HEIGHT_MIN;

    const Row = ({ rowIndex, style, isScrolling, data = [] }): JSX.Element => {
      if (isScrolling) {
        return <ConfigurationItemContentSkeletonItem />;
      }

      const item = data[rowIndex];
      const isAuthorized = item ? getItemIsAuthorized(item) : false;
      const isLast = data.length === rowIndex + 1;

      return (
        <span style={style}>
          <ListItem
            key={rowIndex}
            index={rowIndex}
            item={item}
            addresses={addresses}
            unit={unit}
            isLast={isLast}
            isEditable={tabIsEditable}
            isAuthorized={isAuthorized}
            isUpdating={isUpdating}
            htmlId={getHtmlId(item, rowIndex)}
            onSave={onSave}
            itemRenderer={itemRenderer}
            resetConfigSwrCache={resetConfigSwrCache}
          />
        </span>
      );
    };

    return (
      <ContainerDimensions>
        {({ height, width }) => (
          <Grid
            columnCount={1}
            columnWidth={() => '100%'}
            height={hasMenu ? height - 50 : height}
            rowCount={list?.length || 0}
            rowHeight={index => getItemRowHeight(list[index])}
            width={width}
            itemData={list}
          >
            {Row}
          </Grid>
        )}
      </ContainerDimensions>
    );
  },
);

export { ConfigurationItemVirtualizedList };
