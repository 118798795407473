import { Div } from '@danfoss/etui-system-elements';
import styled from 'styled-components';

const FeatureSwitchWrapper = styled(Div)`
  label {
    color: white;
  }
`;

export { FeatureSwitchWrapper };
