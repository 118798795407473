export const prepareAuditTableData = (data = []) => {
  return data.map(item => {
    const nameFromList = item?.name?.replace(/\s\s+/g, ' ').split(' ') || [''];
    const itemValue = item?.value;

    return {
      index: item?.li,
      eventId: nameFromList[0] || '',
      eventTime: `${nameFromList[1] || ''} ${nameFromList[2] || ''}`,
      eventRole: nameFromList[3] || '',
      eventInfo: itemValue || '',
    };
  }) as any[];
};
