import { getSessionTokensFromStorage } from '@danfoss/etui-sm-xml';
import { STORAGE_USER_AUTHLEVEL_KEY, STORAGE_USER_USERNAME_KEY, STORAGE_USER_PASSWORD_KEY, STORAGE_USER_LANGUAGE_KEY } from '../constants/session-storage-constants.js';
import '../constants/software-versions.js';

function getUserFromStorage() {
  const authtype = sessionStorage.getItem(STORAGE_USER_AUTHLEVEL_KEY);
  const user = sessionStorage.getItem(STORAGE_USER_USERNAME_KEY);
  const password = sessionStorage.getItem(STORAGE_USER_PASSWORD_KEY);
  const language = sessionStorage.getItem(STORAGE_USER_LANGUAGE_KEY);
  const sessionToken = getSessionTokensFromStorage();
  if (authtype && (user || user === '') && (password || password === '')) {
    return {
      user,
      password,
      authtype,
      language,
      sessionToken
    };
  }
  return null;
}

export { getUserFromStorage };
