import { RefrigerationSchedules } from 'pages/SchedulesPage/SchedulesPage.types';
import { parseResponseTimeString } from 'pages/SchedulesPage/utils';

export const getStoreDay = (
  day: RefrigerationSchedules,
): RefrigerationSchedules => ({
  ...day,
  open: parseResponseTimeString(day.on || day.on_time),
  close: parseResponseTimeString(day.off || day.off_time),
});
