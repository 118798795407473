import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { getIsEqual } from 'utils';

type ColumnProps = {
  columnIndex: number;
  rowIndex: number;
  data: { mergedColumns: any; rawData: any; rowAs: any; rowPropsGetter: any };
  style: any;
};

export const Column = React.memo(function Column({
  columnIndex,
  rowIndex,
  data,
  style,
}: ColumnProps) {
  const theme = useTheme();

  const { mergedColumns, rawData, rowAs, rowPropsGetter } = data;
  const column = mergedColumns[columnIndex];
  const rowDataByRowIndex = rawData[rowIndex] || null;
  const columnData = rowDataByRowIndex
    ? rowDataByRowIndex[column.dataIndex]
    : null;

  const rowProps = rowPropsGetter ? rowPropsGetter(rowDataByRowIndex) : {};

  return (
    <Div
      style={style}
      as={rowAs}
      display="flex"
      alignItems="center"
      p={[
        `${theme.spacing.xs}px `,
        `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      ]}
      borderBottom={`1px solid ${theme.palette.divider}`}
      overflow="hidden"
      color={theme.palette.text.primary}
      bg={theme.palette.common.white}
      textDecoration="none"
      {...rowProps}
    >
      {column.render
        ? column.render(columnData, rowDataByRowIndex, columnIndex)
        : columnData}
    </Div>
  );
},
getIsEqual);
