import React from 'react';
import { Button } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements/';
import { useTranslation } from 'react-i18next';
import { useRefrigCopy } from '../../context';

export const DevicesFlowCopy = () => {
  const { t } = useTranslation();
  const {
    step,
    activeStepNumber,
    isEmptyCopyDevicesTableData,
    setIsCopyDevicesDataFetched,
    setSelectAll,
    setStep,
    setActiveStepNumber,
    setDevicesTableContent,
    setCopyDevicesTableData,
    setCopyDevicesTableContent,
  } = useRefrigCopy();

  const handleOnCopyClick = () => {
    setStepValues();
    setDefault();
  };

  const setStepValues = () => {
    let currentStep = step;
    let currentStepNumber = activeStepNumber;
    currentStep += 1;
    currentStepNumber += 1;
    setStep(currentStep);
    setActiveStepNumber(currentStepNumber);
  };

  const setDefault = () => {
    setSelectAll([]);
    setDevicesTableContent([]);
    setIsCopyDevicesDataFetched(false);
    setCopyDevicesTableData(null);
    setCopyDevicesTableContent([]);
  };
  return (
    <Div float="right" testId="devices-flow-button-div">
      <Button
        width="150px"
        onClick={handleOnCopyClick}
        variant="primary"
        disabled={isEmptyCopyDevicesTableData}
      >
        {t('t486')}
      </Button>
    </Div>
  );
};
