import { ScheduleItem } from 'pages/SchedulesPage/SchedulesPage.types';

type StoreScheduleItem = ScheduleItem & {
  open?: {
    hour: string;
    minute: string;
    units: string;
  };
  close?: {
    hour: string;
    minute: string;
    units: string;
  };
  start?: string;
  end?: string;
  desc?: string;
  holidays?: string;
  days?: string;
  on?: string;
  off?: string;
};

const storeSchema = t => {
  return [
    {
      title: t('t3230') || 'ID',
      key: 'id',
      dataIndex: 'id',
      width: '8%',
      sorter: (a: StoreScheduleItem, b: StoreScheduleItem) => {
        return a.id.localeCompare(b.id);
      },
    },
    {
      title: t('t3235') || 'Day',
      key: 'value',
      dataIndex: 'value',
      width: '8%',
      sorter: (a: StoreScheduleItem, b: StoreScheduleItem) => {
        return a.value?.localeCompare(b.value);
      },
    },
    {
      title: t('t3231') || 'Open',
      key: 'open',
      dataIndex: 'open',
      width: '8%',
      render: (_, { open }: StoreScheduleItem) =>
        `${open?.hour} : ${open?.minute} ${open?.units || ''}`,
    },
    {
      title: t('t3236') || 'Close',
      key: 'close',
      dataIndex: 'close',
      width: '8%',
      render: (_, { close }: StoreScheduleItem) =>
        `${close?.hour} : ${close?.minute} ${close?.units || ''}`,
    },
  ];
};

export { storeSchema };
