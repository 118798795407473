import { XML_ACTION, XML_DEVICE_NODETYPE } from '@danfoss/etui-sm-xml';

export const getNodeTypeFromAction = (
  action: XML_ACTION,
): { nodeType: XML_DEVICE_NODETYPE; responseKey?: string } => {
  switch (action) {
    case XML_ACTION.READ_INPUTS:
      return {
        nodeType: XML_DEVICE_NODETYPE.NODETYPE_OI,
        responseKey: 'input',
      };
    case XML_ACTION.READ_RELAYS:
      return {
        nodeType: XML_DEVICE_NODETYPE.NODETYPE_RO,
        responseKey: 'relay',
      };
    case XML_ACTION.READ_METERS:
      return {
        nodeType: XML_DEVICE_NODETYPE.NODETYPE_EM,
        responseKey: 'meter',
      };
    case XML_ACTION.READ_SENSORS:
      return {
        nodeType: XML_DEVICE_NODETYPE.NODETYPE_SI,
        responseKey: 'sensor',
      };
    case XML_ACTION.READ_VAR_OUTS:
      return {
        nodeType: XML_DEVICE_NODETYPE.NODETYPE_VO,
        responseKey: 'var_output',
      };
    default: {
      return { nodeType: XML_DEVICE_NODETYPE.NODETYPE_GN };
    }
  }
};
