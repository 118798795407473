export interface StagingPatternResponse {
  action: string;
  arg1: string;
  comp: CompressorSetting;
  error: string;
  password: string;
  ptrn: string[];
  storview_only: string;
  t_comp: string;
  t_step: string;
  user: string;
  version: string;
}

export interface CompressorSetting {
  capacity: string;
  t_unld: string;
  unld_pct: string;
}

export interface Compressor {
  id?: number;
  numOfUnloader?: number;
  capacity?: number;
  unloader?: Unloader[];
  singleComp?: boolean;
  actualCapacity?: number;
}

export interface Unloader {
  pct?: number;
  status?: boolean;
}

export interface CompressorData {
  compressors?: Compressor[];
  totalCompressors?: number;
}

export interface UnloadersVisibility {
  unloader1Visible?: boolean;
  unloader2Visible?: boolean;
  unloader3Visible?: boolean;
  unloader4Visible?: boolean;
}

export interface CompressorUnloadersStatus {
  compressorStatus?: boolean;
  unloader1Status?: boolean;
  unloader2Status?: boolean;
  unloader3Status?: boolean;
  unloader4Status?: boolean;
}

export interface StagingPatternTableData {
  textCount?: string;
  capacity?: number;
  compressorUnloaderVisibiltyStatus: CompressorUnloaderVisibilityStatus[];
}

export interface CompressorUnloaderVisibilityStatus {
  unloadersVisibility?: UnloadersVisibility;
  compressorUnloadersStatus?: CompressorUnloadersStatus;
  compressor?: Compressor;
}

export interface StagingPatternDataSource {
  id: number;
  count: JSX.Element;
  capacity?: JSX.Element;
  compressor?: JSX.Element;
  unloader?: JSX.Element;
}

export enum STEPS {
  MIN_STEP = '0',
  DEFAULT_STEP = '2',
  MAX_STEP = '80',
  HASH_STEP = '#',
}

export enum PATTERNS {
  DEFAULT_PTRN = '0',
}
