import { SOFTWARE_VERSION_PREFIX, softwareRequirements } from '../constants/software-versions.js';
import { getSoftwareVersionPrefix, getSoftwareVersionMinor } from './get-software-version-prefix.js';

function getIsMatchedSoftwareVersion(units, requiredVersionPrefixes, sm800EnableSystemMenuFlag) {
  var _units$;
  if (requiredVersionPrefixes === void 0) {
    requiredVersionPrefixes = [];
  }
  const unitSoftwareVersion = ((_units$ = units[0]) === null || _units$ === void 0 ? void 0 : _units$.software) || '';
  const unitSoftwareVersionPrefix = getSoftwareVersionPrefix(unitSoftwareVersion);
  if (sm800EnableSystemMenuFlag && unitSoftwareVersionPrefix === SOFTWARE_VERSION_PREFIX.G08) {
    const unitSoftwareVersionMinor = getSoftwareVersionMinor(unitSoftwareVersion);
    return unitSoftwareVersionMinor >= softwareRequirements.G08.systemMenuSupportVersion;
  }
  return unitSoftwareVersionPrefix ? requiredVersionPrefixes.includes(unitSoftwareVersionPrefix) : false;
}

export { getIsMatchedSoftwareVersion };
