import * as React from 'react';
import { ConfigurationModalContext } from './ConfigurationModalContext';

function useConfigurationModals() {
  const context = React.useContext(ConfigurationModalContext);
  if (!context) {
    throw new Error(
      `useConfigurationModals must be used within a ConfigurationModalContext`,
    );
  }
  return context;
}

export { useConfigurationModals };
