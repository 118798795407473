import { StrategyAttributeDeviceName } from 'featurehub-javascript-client-sdk';

const getUserDeviceType = _ref => {
  let {
    isMobile,
    isDesktop,
    isWearable,
    isEmbedded
  } = _ref;
  return isMobile ? StrategyAttributeDeviceName.Mobile : isDesktop ? StrategyAttributeDeviceName.Desktop : isWearable ? StrategyAttributeDeviceName.Watch : isEmbedded ? StrategyAttributeDeviceName.Embedded : StrategyAttributeDeviceName.Browser;
};

export { getUserDeviceType };
