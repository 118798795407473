import { DEVICE_TYPE } from '../types/DiscoveryPage.types';

export const getIsNameEditable = (deviceType: string) => {
  switch (deviceType) {
    case DEVICE_TYPE.GEN_PACK:
    case DEVICE_TYPE.NO_COMP:
      return false;
    case DEVICE_TYPE.RACK:
      return true;
    default:
      return false;
  }
};

export const getIsAddressEditable = (deviceType: string, isOnline: boolean) => {
  if (deviceType === DEVICE_TYPE.GEN_PACK && !isOnline) {
    return false;
  }
  return true;
};

export const getIsModelEditable = (deviceType: string, isOnline: boolean) => {
  switch (deviceType) {
    case DEVICE_TYPE.NO_COMP:
    case DEVICE_TYPE.RACK:
      return false;
    case DEVICE_TYPE.GEN_PACK:
      if (!isOnline) {
        return false;
      }
      return true;
    default:
      return false;
  }
};

export const getIsSuctionGroupEditable = (
  deviceType: string,
  isOnline: boolean,
) => {
  switch (deviceType) {
    case DEVICE_TYPE.NO_COMP:
    case DEVICE_TYPE.RACK:
      return false;
    case DEVICE_TYPE.GEN_PACK:
      if (isOnline) {
        return false;
      }
      return true;
    default:
      return false;
  }
};

export const getIsDualSuctionEditable = (deviceType: string) => {
  switch (deviceType) {
    case DEVICE_TYPE.GEN_SUCT:
    case DEVICE_TYPE.RACKSUCT:
    case DEVICE_TYPE.NO_COMP_SUCT:
      return false;
    default:
      return true;
  }
};
