import { XML_DEVICE_NODETYPE, XML_DEVICE_STR } from '@danfoss/etui-sm-xml';

/**
 * Utility to validate if device is a on off out
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsOnOffOutputDevice(device) {
  return device.nodetype === XML_DEVICE_NODETYPE.NODETYPE_OI || device.type === XML_DEVICE_STR.STR_TYPE_OI || device.type === XML_DEVICE_STR.STR_TYPE_ALL_OI;
}

export { getIsOnOffOutputDevice };
