import { XML_DEVICE_NODETYPE, XML_DEVICE_STR } from '@danfoss/etui-sm-xml';

/**
 * Utility to validate if device is a variable output
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsVariableOutputDevice(device) {
  return device.nodetype === XML_DEVICE_NODETYPE.NODETYPE_VO || device.type === XML_DEVICE_STR.STR_TYPE_VO || device.type === XML_DEVICE_STR.STR_TYPE_ALL_VO;
}

export { getIsVariableOutputDevice };
