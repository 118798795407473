import * as React from 'react';
import { useAuth } from '@danfoss/etui-sm';
import { useTranslation } from 'react-i18next';
import { SCADAState, SCADAAction } from './types';

const SCADAContext = React.createContext<{
  scadaStorage: SCADAState;
  scadaDispatch: React.Dispatch<SCADAAction>;
}>({
  scadaStorage: null,
  scadaDispatch: () => {},
});

const SCADA_STORAGE_TIMER = 60000;

function scadaReducer(state: SCADAState, action: SCADAAction): SCADAState {
  switch (action.type) {
    case 'SET_SCADA_STORAGE':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_SCADA_STORAGE_ERROR':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
}

function SCADAProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const initialState: SCADAState = {
    instance: null,
    isLoading: true,
    storageConnectionError: null,
  };

  const { user } = useAuth();
  const [scadaStorage, scadaDispatch] = React.useReducer(
    scadaReducer,
    initialState,
  );

  React.useEffect(() => {
    const timeoutId =
      user &&
      setTimeout(() => {
        if (!scadaStorage.instance) {
          scadaDispatch({
            type: 'SET_SCADA_STORAGE_ERROR',
            payload: {
              isLoading: false,
              storageConnectionError: new Error(t('t3803')),
            },
          });
        }
      }, SCADA_STORAGE_TIMER);

    return () => clearTimeout(timeoutId);
  }, [user, scadaStorage.instance]);

  return (
    <SCADAContext.Provider value={{ scadaStorage, scadaDispatch }}>
      {children}
    </SCADAContext.Provider>
  );
}

export { SCADAContext, SCADAProvider };
