import { fetchXmlCsvFileData } from '@danfoss/etui-sm-xml';
import { XML_ACTION } from '@danfoss/etui-sm-xml/constants';

async function fetchDiagnosticsLog(_ref) {
  let [_, user, password, language, url] = _ref;
  return fetchXmlCsvFileData({
    url,
    user,
    password,
    lang: language,
    action: XML_ACTION.EXPORT_DIAGNOSTIC,
    offset: 0
  });
}

export { fetchDiagnosticsLog };
