import { fetchXMLData, XML_ACTION, XML_UPDATE_SW_CMD } from '@danfoss/etui-sm-xml';

async function fetchUpdateSoftwareRestartApp(url, user) {
  try {
    // This will always throw error.
    // Due to that the system manager will restart before returning the response.
    const response = await fetchXMLData({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        lang: user.language,
        action: XML_ACTION.UPDATE_SW,
        sw_upgrade_cmd: XML_UPDATE_SW_CMD.RESTART_APP,
        group: 'EDF'
      }
    });
    if (response.return_code && response.return_code !== '0') {
      throw Error('error_code was not 0 on restarting app');
    }
    return response;
  } catch (e) {
    return null;
  }
}

export { fetchUpdateSoftwareRestartApp };
