export const defineOpenSections = items => {
  const result = [];

  const findId = item => {
    result.push(item.id);
    if (item.items) {
      item.items.forEach(findId);
    }
  };

  items.forEach(findId);
  return result;
};
