import '../constants/session-storage-constants.js';
import '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Metadata.js';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import { buildXMLCommand } from '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import { fetchXML } from './fetch-xml.js';

async function fetchXMLFileName(_ref) {
  let {
    url,
    action,
    user,
    password,
    lang,
    backup,
    swCmd,
    attributes: inputAttributes = {},
    items = []
  } = _ref;
  let attributes = _objectSpread2({
    user,
    password,
    lang,
    action
  }, inputAttributes);
  if (backup !== undefined) {
    attributes = _objectSpread2(_objectSpread2({}, attributes), {}, {
      backup
    });
  }
  if (swCmd !== undefined) {
    attributes = _objectSpread2(_objectSpread2({}, attributes), {}, {
      sw_upgrade_cmd: swCmd
    });
  }
  const xml = buildXMLCommand({
    attributes,
    items
  });
  const response = await fetchXML({
    url,
    body: xml
  });
  if (response && response.error === '0') {
    return response;
  }
  return Promise.reject(new Error('Error'));
}

export { fetchXMLFileName };
