export const NOT_AVAILABLE = '-';

type AddressBookDate = Date | '-';

export interface AddressBook {
  version: string;
  data: AddressBookItem[];
  hash: string;
}

export interface AddressBookForm {
  ipAddress: string;
  name?: string;
  password?: string;
  protocol: { label: string; value: string };
  user: string;
}

export interface AddressBookItem extends AddressBookForm {
  id: string;
  inAddressBook: boolean;
  lastAccessed?: AddressBookDate | string;
}
