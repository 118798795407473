import { AmPm } from 'pages/HistoryPage/utils/history-utils';
import { OpenClose, TimeConstant } from '../SchedulesPage.types';

export const getStoreSchedulesData = scheduleData =>
  scheduleData.map(dayData => ({
    ...dayData,
    open: {
      ...dayData.open,
      hour: setTime(dayData.open),
    },
    close: {
      ...dayData.close,
      hour: setTime(dayData.close),
    },
  }));

const setTime = (storeHours: OpenClose) =>
  storeHours?.hour === TimeConstant.ZERO && storeHours?.units === AmPm.AM
    ? TimeConstant.STR_TWELVE
    : storeHours?.hour;
