import '../utils/sidebar-shape.js';
import '../classes/mxgraph-constants.js';
import '../utils/parseSvgInImgSrc.js';
import '../utils/parse-xml.js';
import '../utils/is-encoded.js';
import 'pako';
import '../utils/decode.js';
import '../utils/get-animated-spinner.js';
import '../_rollupPluginBabelHelpers-6901a291.js';
import '../classes/mxgraph.js';
import '../classes/mxsidebar-class.js';
import '../classes/ruleContainer-class.js';
import { MxGraph } from '../classes/mxgraph-class.js';
import { useState, useEffect, useCallback } from 'react';

function useMxGraph(containerRef, _ref) {
  let {
    xml = '',
    lock = true,
    grid = true
  } = _ref;
  const [graph, setGraph] = useState(null);
  useEffect(() => {
    if (containerRef == null) {
      return;
    }
    containerRef.current.innerHTML = '';
    const initialGraph = new MxGraph(containerRef.current, xml);
    initialGraph.setContent(xml);
    initialGraph.lock = lock;
    initialGraph.grid = grid;
    initialGraph.applyGraph();
    setGraph(initialGraph);
  }, [xml]);
  const draw = () => graph.draw();
  const setXml = useCallback(xmlData => {
    graph.setContent(xmlData);
    graph.applyGraph();
  }, [graph]);
  const setGrid = value => {
    graph.gridGraph(value);
  };
  return {
    graph,
    lock,
    grid,
    draw,
    setXml,
    setGrid
  };
}

export { useMxGraph };
