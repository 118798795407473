import { useXmlResource } from '@danfoss/etui-sm/context/app';
import { useAuth } from '@danfoss/etui-sm/context/auth';
import { useUnit } from '@danfoss/etui-sm';
import {
  fetchConfigurationTableByUnit,
  fetchLayoutListByUnit,
} from '@danfoss/etui-sm-xml';
import useSWR from 'swr';

function useRefrigLayoutContent(
  index: string,
  tableAction: string,
  tableAddress?: string,
) {
  const { url, ip } = useXmlResource();
  const { user } = useAuth();
  const { units, getFirstValidUrl } = useUnit();

  const filteredUnit = units.filter(unit =>
    getFirstValidUrl(unit).includes(ip),
  );
  const unit = filteredUnit[0];

  const { data: refrigLayoutHelp } = useSWR(
    () => (!tableAddress ? [url, unit, tableAction, '', user] : null),
    fetchConfigurationTableByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  const tabsHelpTexts = refrigLayoutHelp?.items?.tabitem;

  const tableaddress = refrigLayoutHelp && refrigLayoutHelp?.tableaddress;

  const networkTableAddress =
    tableAddress || tableaddress?.filter(address => address !== '')[0];

  const {
    data: networkTableData,
    isLoading,
    isValidating,
  } = useSWR(
    () =>
      networkTableAddress
        ? [url, unit, networkTableAddress, index, user]
        : null,
    fetchLayoutListByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateIfStale: true,
    },
  );

  const tableContentData = networkTableData?.items?.l;

  const stepContents = networkTableData?.tabs?.items?.tabitem;

  return {
    tableContentData,
    tabsHelpTexts,
    isLoading,
    stepContents,
    networkTableAddress,
    isValidating,
  };
}

export { useRefrigLayoutContent };
