import * as React from 'react';
import { Div, DivProps } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';

export type MenuItemProps = DivProps;

export type MenuItemContentProps = DivProps;

function MenuItemContent({ children, ...props }: MenuItemContentProps) {
  const theme = useTheme();

  return (
    <Div
      as={null}
      display="flex"
      alignItems="center"
      width="100%"
      p={`${theme.spacing.xs}px`}
      {...theme.typography.button}
      {...props}
    >
      {children}
    </Div>
  );
}

export function MenuItem({ children, ...props }: MenuItemProps) {
  const theme = useTheme();

  return (
    <Div
      display="flex"
      height="50px"
      alignItems="center"
      borderBottom={`1px solid ${theme.palette.divider}`}
      bg={theme.palette.common.white}
      boxSizing="content-box"
      {...props}
    >
      {children}
    </Div>
  );
}

MenuItem.Content = MenuItemContent;
