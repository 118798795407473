import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import {
  DiscoveryFlowActions,
  DiscoveryFlowActionsProps,
  DiscoveryFlowStatusLegend,
} from '.';

export const DiscoveryFlowLegendActions = ({
  handleOfflineProgramming,
  onRescanComplete,
  handleOnRefresh,
}: DiscoveryFlowActionsProps) => {
  return (
    <Div
      style={{
        marginTop: '50px',
        marginLeft: '12px',
        width: '100%',
        display: 'inline-block',
      }}
      testId="status-count-div"
    >
      <DiscoveryFlowStatusLegend />
      <DiscoveryFlowActions
        handleOfflineProgramming={handleOfflineProgramming}
        handleOnRefresh={handleOnRefresh}
        onRescanComplete={onRescanComplete}
      />
    </Div>
  );
};
