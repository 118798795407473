const getCurrentUnitAddress = (units, allNetworks) => {
  let currentUnit;
  allNetworks === null || allNetworks === void 0 ? void 0 : allNetworks.every(network => {
    currentUnit = getCurrentUnit(network, units);
    if (currentUnit) return false;
    return true;
  });
  return currentUnit;
};
const getCurrentUnit = (network, units) => {
  const validUnit = units.find(unit => unit.unit_addr === network.addr);
  return validUnit;
};

export { getCurrentUnitAddress };
