import * as React from 'react';
import { useHistory, matchPath, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { Unit, ConfigurationTabItem } from '@danfoss/etui-sm-xml';
import { Accordion } from '@danfoss/etui-sm/components';
import { useXmlResource } from '@danfoss/etui-sm/context/app';
import { useAuth } from '@danfoss/etui-sm/context/auth';
import { fetchConfigurationTabsByUnit } from '../../actions';
import { ConfigurationMenuGroup } from '../ConfigurationMenuGroup';
import { useConfiguration } from '../../context';
import { QUICKSETUP_MENU, TABLE_ADDRESS } from '../..';

function getVisibleTabs(tabs = [], hiddenMenuItems: string[] = []) {
  let isWizardMenu = false;
  const activeTabs = tabs.filter(tab => {
    const idSplit = tab.id.split('-');
    const id = `${idSplit[0]}-${idSplit[1]}`;
    if (hiddenMenuItems.includes(id)) {
      isWizardMenu = id === `${TABLE_ADDRESS.CONFIGURATION}-${0}`;
      return false;
    }
    return true;
  });

  if (isWizardMenu) {
    activeTabs.splice(0, 0, QUICKSETUP_MENU);
  }

  return activeTabs;
}

export type ConfigurationMenuProps = {
  unit: Unit;
  /**
   * To hide menu items by table address and index
   * Example [20019-1]
   * @type {string[]}
   */
  hiddenMenuItems?: string[];
};

const ConfigurationMenu = React.memo(
  ({ unit, hiddenMenuItems = [] }: ConfigurationMenuProps) => {
    const { url: xmlBackendURL } = useXmlResource();
    const { user } = useAuth();
    const location = useLocation<{
      usedBackButton?: boolean;
      custom?: string;
    }>();
    const { replace, push } = useHistory();
    const { route, rootMenuId, menuItemsById, onSetMenuItems, onSetMenuLevel } =
      useConfiguration();

    const match = matchPath<{ menuId: string }>(location.pathname, {
      path: `${route}/:menuId`,
    });

    const existingItems = menuItemsById[rootMenuId];

    const { data: fetchedTabs = [] as ConfigurationTabItem[] } = useSWR<
      ConfigurationTabItem[]
    >(
      () =>
        unit && !existingItems
          ? [xmlBackendURL, unit, '', rootMenuId, user]
          : null,
      fetchConfigurationTabsByUnit,
      {
        revalidateOnFocus: false,
        shouldRetryOnError: true,
      },
    );

    const tabs = existingItems || fetchedTabs;

    const shownTabs = React.useMemo(() => {
      return Array.isArray(tabs) ? getVisibleTabs(tabs, hiddenMenuItems) : [];
    }, [tabs]);

    React.useEffect(() => {
      if (Array.isArray(tabs) && !existingItems) {
        onSetMenuItems(rootMenuId, getVisibleTabs(tabs, hiddenMenuItems));
        onSetMenuLevel(rootMenuId, 0);
      }
    }, [rootMenuId, location.pathname, tabs, existingItems]);

    React.useEffect(() => {
      if (
        shownTabs?.length &&
        !location.state?.usedBackButton &&
        !location.state?.custom &&
        location.pathname === '/configuration'
      ) {
        handleOnMenuGroupChange([shownTabs[0].id]);
      }
    }, [location, shownTabs]);

    const handleOnMenuGroupChange = (args: React.ReactText[]) => {
      const openMenuId = args[0];
      if (openMenuId) {
        replace({
          pathname: `${route}/${openMenuId}`,
        });
      } else {
        push({ pathname: `${route}` });
      }
    };

    const getDefaultOpenMenuGroupId = React.useCallback(() => {
      if (match?.params?.menuId) {
        return [match.params.menuId];
      }
      return [];
    }, [match]);

    const renderTabItem = React.useCallback(
      (item: ConfigurationTabItem) => (
        <ConfigurationMenuGroup key={item.id} item={item} unit={unit} />
      ),
      [unit],
    );

    return (
      <Accordion
        allowZeroExpanded={true}
        preExpanded={getDefaultOpenMenuGroupId()}
        onChange={handleOnMenuGroupChange}
      >
        {shownTabs?.map(tabItem => renderTabItem(tabItem)) || null}
      </Accordion>
    );
  },
);

export { ConfigurationMenu };
