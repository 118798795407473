import { SelectInputOption } from '@danfoss/etui-core';
import {
  ConfigurationListDataArray,
  ConfigurationListItem,
} from '@danfoss/etui-sm-xml';

export enum REFRIG_COPY_STEP {
  SOURCE_CONTROLLER = '1',
  SOURCE_SETTINGS = '2',
  SOURCE_ALARMS = '3',
  FILES = '5',
  PARAMETERS = '6',
  ALARMS = '7',
}

export const REFRIG_COPY_TABLE_ADDR = '20104';

export interface RefrigCopySteps {
  title: string;
  stepNumber: number;
}

export interface DeviceConstarintsData {
  config_cnt: string;
  gen_ofs_cs: string;
  gen_ofs_ct: string;
  gen_ofs_pk: string;
  max_ca: string;
  max_cs: string;
  max_ct: string;
  max_naddr: string;
  max_name: string;
  max_octl: string;
  max_rk: string;
  max_sg: string;
  max_sg_gen: string;
  scan_cnt: string;
}

export interface DiscoveryTableData {
  status: string;
  name: string;
  address: string;
  model: string;
  type: string;
  code: string;
  version: string;
  deviceBus: string;
}
export interface DiscoveryDeviceStatusCnt {
  statusOrangeCnt: number;
  statusGreenCnt: number;
  statusRedCnt: number;
}

export interface SourceGraphTable {
  point: string;
  log: JSX.Element;
  haccp: JSX.Element;
}

export interface SourceGraphTableData {
  sourceGraphTableContent?: SourceGraphTableContent[];
  isLoading?: boolean;
  isSubmitting?: boolean;
  isSucceeded?: boolean;
  isFailed?: boolean;
}

export interface SourceGraphTableContent {
  point: string;
  haccp: boolean;
  log: string;
  logList: SelectInputOption[];
  isLogSubmitting?: boolean;
  isLogSucceeded?: boolean;
  isLogFailed?: boolean;
  page?: string;
  li?: string;
  isHaccpSubmitting?: boolean;
  isHaccpSucceeded?: boolean;
  isHaccpFailed?: boolean;
}

export interface SourceGraphTablePageContent {
  listItems?: ConfigurationListItem[];
  listData?: ConfigurationListDataArray;
  page?: string;
}

export interface SourceAlarmTablePageContent {
  listItems?: ConfigurationListItem[];
  listData?: ConfigurationListDataArray;
  page?: string;
}
export interface SourceAlarmTable {
  name: string;
  level: JSX.Element;
  action: JSX.Element;
  enable?: JSX.Element;
}

export interface SourceAlarmTableContent {
  name: string;
  level: string;
  action: string;
  levelList: SelectInputOption[];
  page?: string;
  li?: string;
  isLevelSubmitting?: boolean;
  isLevelSucceeded?: boolean;
  isLevelFailed?: boolean;
  isActionSubmitting?: boolean;
  isActionSucceeded?: boolean;
  isActionFailed?: boolean;
}

export interface SourceAlarmTableData {
  sourceAlarmTableContent?: SourceAlarmTableContent[];
}

export interface ParamTableData {
  paramTableContent?: ParametersTableContent[];
  isLoading?: boolean;
}

export interface ParametersTableContent {
  name: string;
  toggleVal: string;
  li: ConfigurationListItem;
}

export interface ParametersTable {
  name: string;
  toggleVal: string;
  key: number;
  li: ConfigurationListItem;
}

export interface AlarmsTableContent {
  name: string;
  settings: string;
  actionValue: string;
  toggleVal: string;
  li: ConfigurationListItem;
}

export interface AlarmsTable {
  name: string;
  settings: string;
  actionValue: string;
  toggleVal: string;
  key: number;
  li: ConfigurationListItem;
}

export interface AlarmsTableData {
  alarmTableContent?: AlarmsTableContent[];
  isLoading?: boolean;
}

export interface GraphTable {
  name: string;
  interval: string;
  toggleVal: string;
  key: number;
  li: ConfigurationListItem;
}

export interface GraphTableContent {
  name: string;
  interval: string;
  toggleVal: string;
  li: ConfigurationListItem;
}
export interface GraphTableData {
  graphTableContent?: GraphTableContent[];
  isLoading?: boolean;
}

export interface SourceGraphTableLogList {
  _: string;
  datavalue: string;
}

export interface EditedListItem {
  item: ConfigurationListItem;
  index: number;
}

export enum HACCP_STATUS {
  ENABLED_IVAL = '1',
  DISABLED_IVAL = '0',
}

export interface DevicesTableContent {
  name: string;
  address: string;
  model: string;
  li: string;
  toggleVal: string;
}

export interface CopyDevicesTableContent {
  name: string;
  address: string;
  model: string;
  li: string;
  status: string;
  isCopying: boolean;
}

export interface DevicesTableData {
  devicesTableContent: DevicesTableContent[];
}

export interface CopyDevicesTableData {
  copyDevicesTableContent: CopyDevicesTableContent[];
}

export interface DevicesTable {
  name: string;
  address: string;
  model: string;
  li: string;
  toggleVal: string;
  key: number;
}

export interface CopyDevicesTable {
  name: string;
  address: string;
  model: string;
  li: string;
  time: JSX.Element;
  status: JSX.Element;
}

export interface CopySourceControllerFileJson {
  resp: CopySourceControllerFileResp;
}

export interface CopySourceControllerFileResp {
  action: string;
  b64: string[];
  bpidx: string;
  device_id: string;
  enc_bytes: string;
  error: string;
  lang: string;
  model_name: string;
  num_bytes: string;
  num_lines: string;
  offset: string;
  operation: string;
  storeview_only: string;
  version: string;
  lines?: number;
}

export interface CopySourceLoadFileResp {
  action: string;
  bpidx: string;
  error: string;
  from: string;
  id: string;
  lang: string;
  name: string;
  offset: string;
  password: string;
  size: string;
  status: string;
  user: string;
}

export enum COPY_LIST_COUNT {
  TWO = '2',
  FOUR = '4',
  FIVE = '5',
}

export const Copy_File_Format = '.cfz';
