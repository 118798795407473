import { XML_TAG, fetchXMLFileData, XML_ACTION } from '@danfoss/etui-sm-xml';
import { saveAs } from 'file-saver';

async function fetchGenerateCsrFile(companyValue, domainValue, countryValue, ipValue, user, url) {
  if (ipValue === void 0) {
    ipValue = null;
  }
  const organization = {
    tag: XML_TAG.ORGANIZATION,
    value: companyValue
  };
  const country = {
    tag: XML_TAG.COUNTRY,
    value: countryValue
  };
  const items = [organization, country];
  if (domainValue) {
    const domain = {
      tag: XML_TAG.DOMAIN,
      value: domainValue
    };
    items.push(domain);
  }
  if (ipValue) {
    const ip = {
      tag: XML_TAG.IP,
      value: ipValue
    };
    items.push(ip);
  }
  const {
    fileName,
    fileContent
  } = await fetchXMLFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action: XML_ACTION.GENERATE_CSR_FILE,
    items
  });
  const blob = new Blob([fileContent], {
    type: 'text/plain'
  });
  saveAs(blob, fileName);
}

export { fetchGenerateCsrFile };
