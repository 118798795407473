import { fetchXMLData, XML_ACTION, XML_TAG } from '@danfoss/etui-sm-xml';

const byteSizeLimit = 100000;
async function fetchUpdateEdfFilesSM800(installEdfFile, url, user, selectedEdfFiles, totalLength, progressCb, installSuccessCb, failureCb) {
  try {
    const {
      b64,
      name,
      size,
      offset
    } = installEdfFile;
    const fileLength = size;
    const chunksNum = Math.ceil(fileLength / byteSizeLimit);
    const filename = name.substring(name.lastIndexOf('/') + 1, name.length);
    let chunksLeft = chunksNum;
    let index = 1;
    let done = 1;
    let file_id;
    do {
      done = chunksLeft === 1 ? 1 : 0;
      const chunkDB64 = getFileChunk(b64, chunksNum, chunksLeft);
      const {
        file_id: nextFileId
        // eslint-disable-next-line no-await-in-loop
      } = await loadFileRequest(url, user, filename, file_id, index, done, size, offset, chunkDB64);
      file_id = nextFileId;
      if (done === 1) {
        selectedEdfFiles.splice(selectedEdfFiles.findIndex(selectedEdfFile => {
          return selectedEdfFile === filename;
        }), 1);
        const doneLength = selectedEdfFiles.length;
        const progress = Math.floor((totalLength - doneLength) / totalLength * 100 / 2);
        progressCb(progress);
        if (progress === 50 && doneLength === 0) {
          installSuccessCb(true);
        }
      }
      index++;
      chunksLeft--;
    } while (chunksLeft > 0);
  } catch (e) {
    failureCb(e);
  }
}
function loadFileRequest(url, user, filename, file_id, index, done, size, offset, b64) {
  if (index === void 0) {
    index = 1;
  }
  if (done === void 0) {
    done = 0;
  }
  try {
    return fetchXMLData({
      url,
      attributes: {
        user: user.user,
        password: user.password,
        lang: user.language,
        action: XML_ACTION.LOAD_FILE,
        filename,
        file_id,
        index,
        done,
        size,
        offset
      },
      items: [{
        tag: XML_TAG.B64,
        value: b64
      }]
    });
  } catch (e) {
    throw new Error();
  }
}
function getFileChunk(encodedFileString, numOfChunks, chunksLeft) {
  const chunkNumber = numOfChunks - chunksLeft;
  const chunkSize = Math.ceil(encodedFileString.length / numOfChunks);
  return encodedFileString.substr(chunkNumber * chunkSize, chunkSize);
}

export { fetchUpdateEdfFilesSM800, loadFileRequest };
