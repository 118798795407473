import {
  RefrigerationSchedules,
  ScheduleTypes,
} from 'pages/SchedulesPage/SchedulesPage.types';

export const generateDefrostSchedId = ({ id, index }) => index || id;
export const generateNonDefrostSchedId = ({ key, index }) => key + index;
export const generateHVACSchedId = ({ name, index }) => name + index;

const generatorsByType = {
  [ScheduleTypes.REF_DEFROST]: generateDefrostSchedId,
  [ScheduleTypes.REF_NON_DEFROST]: generateDefrostSchedId,
  [ScheduleTypes.LIGHTING]: generateNonDefrostSchedId,
  [ScheduleTypes.HVAC]: generateHVACSchedId,
};

export const generateScheduleIdbyType = (
  schedule: RefrigerationSchedules,
  scheduleType: ScheduleTypes,
): string => generatorsByType[scheduleType](schedule);
