import {
  fetchXMLData,
  XML_ACTION,
  User,
  Unit,
  getUnitNetworksByInitialUnit,
  getUnitUrlByUnitNetwork,
} from '@danfoss/etui-sm-xml';
import { Protocol } from '@danfoss/etui-sm/context';

type UnitsResponse = Unit;

async function getUnits([_, url, user, protocol]: [
  string,
  string,
  User,
  Protocol,
]) {
  const initialUnit = await fetchXMLData<UnitsResponse>({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      lang: user.language,
      action: XML_ACTION.READ_UNITS,
      storeview_only: '1',
      version: 'C',
      session_update: 'no',
    },
  });

  const totalUnitCount = parseInt(initialUnit.total, 10);

  let units = [initialUnit];

  if (totalUnitCount === 1) {
    return units;
  }

  if (totalUnitCount > 1) {
    const unitsNetworkAddresses = getUnitNetworksByInitialUnit(
      url,
      initialUnit,
    );
    try {
      const otherUnits = await Promise.all(
        unitsNetworkAddresses.validUnitNetworks
          .filter(unitNetwork => unitNetwork.addr !== initialUnit.unit_addr)
          .map(unitNetwork => {
            return fetchXMLData<UnitsResponse>({
              url: getUnitUrlByUnitNetwork(unitNetwork, protocol),
              attributes: {
                user: user.user,
                password: user.password,
                lang: user.language,
                action: XML_ACTION.READ_UNITS,
                storeview_only: '1',
                version: 'C',
              },
            });
          }),
      );
      units = [initialUnit, ...otherUnits];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  return units;
}

export { getUnits };
