import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { DeviceType } from '@danfoss/etui-sm';
import { Icon } from '@danfoss/etui-core';
import { getDeviceIconByDeviceType } from './utils';

export interface DeviceTypeIconProps {
  deviceType: DeviceType;
}

export function DeviceTypeIcon({ deviceType }: DeviceTypeIconProps) {
  const theme = useTheme();

  return (
    <Icon
      glyph={getDeviceIconByDeviceType(deviceType)}
      styles={{
        root: {
          color: theme.palette.text.secondary,
          mr: `${theme.spacing.xxs}px`,
        },
      }}
    />
  );
}
