import React from 'react';
import { useModal } from '@danfoss/etui-sm';
import { DropdownMenu, icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { CopyModal } from '../RefrigLayoutModal/modals/CopyModal';

export interface LayoutDropDownMenuProps {
  rowIndex: number;
  handleOnCopyOk: (copyAmount: number, selectedRowIndex: number) => void;
  handleDeleteSelectedRow: (selectedRowIndex: number) => void;
}

export const LayoutDropDownMenu = ({
  rowIndex,
  handleOnCopyOk,
  handleDeleteSelectedRow,
}: LayoutDropDownMenuProps) => {
  const [selectedRowId, setSelectedRowId] = React.useState<number>(0);

  const { t } = useTranslation();

  const handleCopyOk = (copyAmount: number) => {
    handleOnCopyOk(copyAmount, selectedRowId);
  };
  const [showCopyModal] = useModal(CopyModal, {
    min: 1,
    max: 100,
    handleCopyOk,
  });

  const handleSelectedRowId = (key: number) => {
    setSelectedRowId(key);
  };

  const renderDefaultItems = (closeDropdown?: () => void) => {
    const handleOnCopy = (key: number) => () => {
      closeDropdown();
      handleSelectedRowId(key);
      showCopyModal();
    };

    const handleOnDelete = (key: number) => () => {
      closeDropdown();
      handleDeleteSelectedRow(key);
    };

    return (
      <>
        <DropdownMenu.Item onClick={handleOnCopy(rowIndex)}>
          {t('t486')}
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleOnDelete(rowIndex)}>
          {t('t2215')}
        </DropdownMenu.Item>
      </>
    );
  };
  return (
    <DropdownMenu
      styles={{ menuContainer: { zIndex: 100, width: '110px' } }}
      iconOnly={true}
      icon={{ glyph: icons.DOTS_HORIZONTAL }}
      renderList={({ closeDropdown }) => renderDefaultItems(closeDropdown)}
    />
  );
};
