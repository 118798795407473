function composeDeviceId(device) {
  const {
    host,
    nodetype,
    node,
    bpidx,
    arg1,
    arg2,
    arg3,
    stype,
    mod,
    point
  } = device;
  const modPoint = `${mod}-${point}`;
  const rootId = stype ? `${host}-${nodetype}-${node}-${stype}` : `${host}-${nodetype}-${node}-0`;
  // check if device has a bpidx
  const baseId = bpidx ? `${rootId}-${bpidx}-${modPoint}` : `${rootId}-0-${modPoint}`;
  if (arg1 && arg2 && arg3) {
    return `${baseId}-${arg1}-${arg2}-${arg3}`;
  }
  if (arg1 && arg2) {
    return `${baseId}-${arg1}-${arg2}-0`;
  }
  if (arg1) {
    return `${baseId}-${arg1}-0-0`;
  }
  return baseId;
}

export { composeDeviceId };
