function getOpenDeviceItemIdsById(id?: string) {
  const ids: string[] = [];
  if (!id) return ids;

  const idDecomposed = id.split('-');
  const first = idDecomposed[0];
  const second = idDecomposed[1];
  const third = idDecomposed[2];

  if (first && !second && !third) {
    ids.push(first);
    return ids;
  }
  if (first && second && !third) {
    ids.push(first);
    ids.push(`${first}-${second}`);
    return ids;
  }
  if (first && second && third) {
    ids.push(first);
    ids.push(`${first}-${second}`);
    ids.push(`${first}-${second}-${third}`);
    return ids;
  }
  return ids;
}

export { getOpenDeviceItemIdsById };
