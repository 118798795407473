import { DeviceType } from '@danfoss/etui-sm';

export function getDeviceTypeTextId(deviceType: DeviceType) {
  return deviceType === 'refrig'
    ? 't150'
    : deviceType === 'hvac'
    ? 't87'
    : deviceType === 'lights'
    ? 't109'
    : deviceType === 'meter'
    ? 't374'
    : 't212';
}
