import CryptoJS from 'crypto-js';
// we are not using Web Crypto API because it doesn't work with http protocol
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API
// Secure context: This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.

export const getAddressBookPasswordSHA = (masterPassword: string): string =>
  CryptoJS.SHA256(masterPassword).toString();

export const encrypt = (password: string, key: string) =>
  CryptoJS.AES.encrypt(password, key).toString();

export const decrypt = (hash: string, key: string): string =>
  CryptoJS.AES.decrypt(hash, key).toString(CryptoJS.enc.Utf8);
