export const fallbackDictionary = {
  200000: {
    list: [
      { name: 'Warning: Back up Log as needed', value: 'Loading...' },
      { name: 'Modify Datapoint Sample Rate below', value: 'Loading...' },
    ],
  },
  200001: {
    list: [
      { name: 'Warning: Back up Log as needed', value: 'Loading...' },
      { name: 'Modify Datapoint Sample Rate below', value: 'Loading...' },
    ],
  },
  200002: {
    list: [
      { name: 'E-Mail Configuration', value: 'Loading...' },
      { name: 'Use Encryption', value: 'Loading...' },
      { name: 'Server', value: 'Loading...' },
    ],
  },
  200010: {
    list: [
      { name: 'Warning: Back up Log as needed', value: 'Loading...' },
      { name: 'Auto Configure History', value: 'Loading...' },
      { name: 'Clear History Configuration', value: 'Loading...' },
      { name: 'Clear History Log', value: 'Loading...' },
    ],
  },
  200011: {
    list: [
      { name: 'Warning: Back up Log as needed', value: 'Loading...' },
      { name: 'Modify Datapoint Sample Rate below', value: 'Loading...' },
    ],
  },
  200012: {
    list: [
      { name: 'Warning: Back up Log as needed', value: 'Loading...' },
      { name: 'Modify Datapoint Sample Rate below', value: 'Loading...' },
    ],
  },
  200013: {
    list: [
      { name: 'Warning: Back up Log as needed', value: 'Loading...' },
      { name: 'Modify Datapoint Sample Rate below', value: 'Loading...' },
    ],
  },
  200014: {
    list: [
      { name: 'Warning: Back up Log as needed', value: 'Loading...' },
      { name: 'Modify Datapoint Sample Rate below', value: 'Loading...' },
    ],
  },
  200015: {
    list: [
      { name: 'Warning: Back up Log as needed', value: 'Loading...' },
      { name: 'Modify Datapoint Sample Rate below', value: 'Loading...' },
    ],
  },
  200300: {
    list: [{ name: 'Number of network connections', value: 'Loading...' }],
  },
  200301: {
    list: [
      { name: 'System Test Alarm', value: 'Loading...' },
      { name: 'Action', value: 'Loading...' },
    ],
  },
  200302: {
    list: [{ name: 'Component name display', value: 'Loading...' }],
  },
  200303: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200304: {
    list: [
      { name: 'Host Comm error', value: 'Loading...' },
      { name: 'Action', value: 'Loading...' },
    ],
  },
  200460: {
    list: [{ name: 'Meter', value: 'Loading...' }],
  },
  200461: {
    list: [
      { name: 'Type', value: 'Loading...' },
      { name: 'Name', value: 'Loading...' },
    ],
  },
  200510: {
    list: [
      { name: 'Sort by', value: 'Loading...' },
      { name: 'Address', value: 'Loading...' },
    ],
  },
  200511: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200512: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200513: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200514: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200515: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200521: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200522: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200523: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200524: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200525: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200530: {
    list: [
      { name: 'Fan type', value: 'Loading...' },
      { name: 'Number of fans', value: 'Loading...' },
      { name: 'Fan staging', value: 'Loading...' },
      { name: 'Fan stage monitoring', value: 'Loading...' },
      { name: 'Auto rotate fans', value: 'Loading...' },
      { name: 'Control sensor', value: 'Loading...' },
      { name: 'Monitor dropleg temp', value: 'Loading...' },
      { name: 'Control method', value: 'Loading...' },
      { name: 'Target control type', value: 'Loading...' },
      { name: 'Target', value: 'Loading...' },
    ],
  },
  200590: {
    list: [
      { name: 'PSIG target', value: 'Loading...' },
      { name: 'PSIG Safety cutout', value: 'Loading...' },
      { name: 'Suction Optimization', value: 'Loading...' },
      { name: 'Night setback', value: 'Loading...' },
      { name: 'Monitor suction temp', value: 'Loading...' },
      { name: 'Units', value: 'Loading...' },
      { name: 'Compressor type', value: 'Loading...' },
      { name: 'Proof type', value: 'Loading...' },
      { name: 'Show percentage of total', value: 'Loading...' },
      { name: 'Compressor size', value: 'Loading...' },
    ],
  },
  200591: {
    list: [
      { name: 'Alarms Suspended', value: 'Loading...' },
      { name: 'Low Pressure', value: 'Loading...' },
      { name: 'High Pressure', value: 'Loading...' },
      { name: 'High Pressure', value: 'Loading...' },
      { name: 'Comp. short cycle', value: 'Loading...' },
      { name: 'Low Pressure', value: 'Loading...' },
      { name: 'High Pressure', value: 'Loading...' },
      { name: 'Cond fan failure', value: 'Loading...' },
      { name: 'Cond fan failure', value: 'Loading...' },
      { name: 'Cond fan failure', value: 'Loading...' },
    ],
  },
  200630: {
    list: [
      { name: 'Show only scanned devices', value: 'Loading...' },
      { name: 'Auto defrost schedules', value: 'Loading...' },
      { name: 'Show all Questions', value: 'Loading...' },
    ],
  },
  200651: {
    list: [
      { name: 'Name', value: 'Loading...' },
      { name: 'Number of zone sensors', value: 'Loading...' },
      { name: 'Load Shed Level', value: 'Loading...' },
      { name: 'Fan type', value: 'Loading...' },
    ],
  },
  200653: {
    list: [
      { name: 'High zone temp', value: 'Loading...' },
      { name: 'Low zone temp', value: 'Loading...' },
      { name: 'High humidity', value: 'Loading...' },
      { name: 'High supply temp', value: 'Loading...' },
      { name: 'High indoor CO2', value: 'Loading...' },
      { name: 'HVAC fan down', value: 'Loading...' },
    ],
  },
  200710: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200711: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200712: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200713: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200790: {
    list: [
      { name: 'Channel LONWORKS', value: 'Loading...' },
      { name: 'Channel MODBUS 1', value: 'Loading...' },
      { name: 'Channel MODBUS 2', value: 'Loading...' },
    ],
  },
  200810: {
    list: [
      { name: 'Name', value: 'Loading...' },
      { name: 'Bd-Pt', value: 'Loading...' },
      { name: 'Broadcast', value: 'Loading...' },
      { name: 'Type', value: 'Loading...' },
      { name: 'Control Input', value: 'Loading...' },
      { name: 'Minimum OFF', value: 'Loading...' },
      { name: 'Pre delay', value: 'Loading...' },
      { name: 'Post delay', value: 'Loading...' },
      { name: 'Load Shed Level', value: 'Loading...' },
      { name: 'Number of alarms', value: 'Loading...' },
    ],
  },
  200811: {
    list: [
      { name: 'Name', value: 'Loading...' },
      { name: 'Bd-Pt', value: 'Loading...' },
      { name: 'Broadcast', value: 'Loading...' },
      { name: 'Type', value: 'Loading...' },
      { name: 'Number of alarms', value: 'Loading...' },
    ],
  },
  200812: {
    list: [
      { name: 'Name', value: 'Loading...' },
      { name: 'Bd-Pt', value: 'Loading...' },
      { name: 'Broadcast', value: 'Loading...' },
      { name: 'Type', value: 'Loading...' },
      { name: 'Number of alarms', value: 'Loading...' },
    ],
  },
  200814: {
    list: [{ name: 'No. of conversions', value: 'Loading...' }],
  },
  200815: {
    list: [
      { name: 'New Calc', value: 'Loading...' },
      { name: 'Point Description', value: 'Loading...' },
    ],
  },
  200830: {
    list: [
      { name: 'Rack label', value: 'Loading...' },
      { name: 'Refrig type', value: 'Loading...' },
      { name: 'Oil type', value: 'Loading...' },
      { name: 'Monitor phase loss', value: 'Loading...' },
      { name: 'Shutdown on OI Calc', value: 'Loading...' },
      { name: 'Suction Name', value: 'Loading...' },
    ],
  },
  200835: {
    list: [
      { name: 'Use adaptive rail heat', value: 'Loading...' },
      { name: 'Number of relays', value: 'Loading...' },
    ],
  },
  200840: {
    list: [{ name: 'Show only scanned devices', value: 'Loading...' }],
  },
  200850: {
    list: [
      { name: 'Store name', value: 'Loading...' },
      { name: 'Street', value: 'Loading...' },
      { name: 'City', value: 'Loading...' },
      { name: 'Phone', value: 'Loading...' },
      { name: 'Region 1', value: 'Loading...' },
      { name: 'Region 2', value: 'Loading...' },
      { name: 'Unit Name', value: 'Loading...' },
    ],
  },
  200851: {
    list: [
      { name: 'Add New User', value: 'Loading...' },
      { name: 'Require Strong Password', value: 'Loading...' },
    ],
  },
  200852: {
    list: [{ name: 'Add', value: 'Loading...' }],
  },
  200853: {
    list: [
      { name: 'Include in quick report', value: 'Loading...' },
      { name: 'Alarms', value: 'Loading...' },
      { name: 'Schedules', value: 'Loading...' },
      { name: 'Scanned devices', value: 'Loading...' },
      { name: 'Audit Trail', value: 'Loading...' },
      { name: 'Controller database', value: 'Loading...' },
    ],
  },
  200911: {
    list: [
      { name: 'Set date and time', value: 'Loading...' },
      { name: 'Time zone offset (UTC/GMT -500=New York)', value: 'Loading...' },
      { name: 'Operating Hours', value: 'Loading...' },
    ],
  },
  200918: {
    list: [
      { name: 'Make a selection', value: 'Loading...' },
      { name: 'Press to enable all', value: 'Loading...' },
      { name: 'Press to disable all', value: 'Loading...' },
    ],
  },
  200921: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  200922: {
    list: [{ name: 'Device Bluetooth interface', value: 'Loading...' }],
  },
  201120: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  201121: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  201122: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  201123: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  201124: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
  201125: {
    list: [{ name: 'Sort by', value: 'Loading...' }],
  },
};
