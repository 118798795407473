import {
  ConfigurationListItem,
  fetchXMLData,
  XML_ACTION,
} from '@danfoss/etui-sm-xml';
import {
  SECURITY_TABLE_ADDRESS,
  SECURITY_TABLE_ADDRESS_INDEX,
} from '../SecurityCompliancePage.types';

export const setSecurityMode = async (
  url: string,
  listItem: ConfigurationListItem,
  value: string,
) => {
  const { width, pnum, type, token, ef: enterfunc } = listItem;
  await fetchXMLData({
    url,
    attributes: {
      action: XML_ACTION.WRITE_LIST,
      tableaddress: SECURITY_TABLE_ADDRESS,
      index: SECURITY_TABLE_ADDRESS_INDEX,
      storeview_only: '1',
      version: 'C',
      ...{
        enterfunc,
        ival: value,
        pnum,
        token,
        type,
        width,
      },
    },
  });
};
