import { styled } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';

export default styled(Div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${({ theme }: DivProps) => theme.spacing.xxs}px 0;
  margin-left: -5px;
`;
