import * as React from 'react';
import { RefrigLayoutContext } from '../RefrigLayoutContext';

export function useRefrigLayout() {
  const context = React.useContext(RefrigLayoutContext);
  if (!context) {
    throw new Error(
      `useRefriglayout must be used within a RefrigLayoutProvider`,
    );
  }
  return context;
}
