import { ScheduleItemGraphicOptions } from 'pages/SchedulesPage/SchedulesPage.types';
import { getScheduleColor } from './getScheduleColors';

export const GRAPHIC_SCHEDULE_WIDTH = 22;

export function buildScheduleGraphicOptions(
  uniqueIdsByDays: { [key: string]: string[] }[],
): ScheduleItemGraphicOptions[] {
  const uniqueIds = [
    ...new Set(uniqueIdsByDays.map(day => Object.values(day).flat()).flat()),
  ];

  return uniqueIds.map((id, index) => ({
    id,
    color: getScheduleColor(index),
    offset: index * GRAPHIC_SCHEDULE_WIDTH,
  }));
}
