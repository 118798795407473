import { User } from '@danfoss/etui-sm-xml';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { TFunction } from 'i18next';
import { FileContent } from '../types/LocalGraphics.types';
import { convertB64ToByteArray } from '../utils/convertB64ToByteArray';
import { createBlobFromByteArray } from '../utils/createBlobFromByteArray';
import { delay } from '../utils/delay';
import { getLocalGraphicFiles } from './get-local-graphic-files';

export const exportLocalGraphicFiles = async (
  user: User,
  url: string,
  zipFileName: string,
  fileNames: string[],
  isSM800A: boolean,
  t: TFunction,
  singleFileExport: boolean = false,
) => {
  const graphicFileContents = [];
  try {
    await fileNames.reduce(async (promise, fileName) => {
      await promise;
      !isSM800A && (await delay(5000));
      const graphicFileContent = await getLocalGraphicFiles([
        user,
        url,
        fileName,
        isSM800A,
      ]);
      graphicFileContents.push(graphicFileContent);
    }, Promise.resolve());
    return await exportFiles(fileNames, graphicFileContents, zipFileName);
  } catch (e) {
    if (singleFileExport) {
      throw new Error(t('t3538'));
    } else {
      throw new Error(t('t3489'));
    }
  }
};

export const exportFiles = async (
  fileNames: string[],
  graphicFileContents: FileContent[],
  zipFileName: string,
) => {
  try {
    const zip = new JSZip();
    graphicFileContents?.forEach(async (graphicFileContent, index) => {
      const byteArray: Uint8Array = convertB64ToByteArray(
        graphicFileContent?.b64,
      );
      const blob: Blob = createBlobFromByteArray(
        byteArray,
        'text/plain;charset=utf-8',
      );
      zip.file(fileNames[index], blob);
    });
    const content: Blob = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `${zipFileName}.zip`);
    return true;
  } catch (e) {
    throw new Error();
  }
};
