import { QuerySchedTypes, ScheduleTypes } from '../SchedulesPage.types';

export const getScheduleType = (schedType: string) => {
  return schedType === QuerySchedTypes.SHUTDOWN
    ? ScheduleTypes.SHUTDOWN
    : schedType === QuerySchedTypes.DEFROST
    ? ScheduleTypes.DEFROST
    : schedType === QuerySchedTypes.CASE_LIGHTS
    ? ScheduleTypes.CASE_LIGHTS
    : schedType === QuerySchedTypes.HVAC
    ? ScheduleTypes.HVAC
    : ScheduleTypes.NIGHT_SETBACK;
};
