import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ConfigurationTabItem, Unit } from '@danfoss/etui-sm-xml';
import { useTheme } from '@danfoss/etui-system';
import { A, Div } from '@danfoss/etui-system-elements';
import { useConfiguration } from '../../context';
import { ConfigurationMenuItemDiv } from './styles';

export interface ConfigurationMenuItemProps {
  item: ConfigurationTabItem;
  unit: Unit;
  menuGroupId: string;
  level?: number;
}

const ConfigurationMenuItem = React.memo(
  ({ menuGroupId, item, level = 0 }: ConfigurationMenuItemProps) => {
    const theme = useTheme();
    const { location } = useHistory();
    const { route } = useConfiguration();
    const StyledRouterWrappedLink = props => <Link component={A} {...props} />;

    const menuLinkProps = {
      as: StyledRouterWrappedLink,
      to: `${route}/${menuGroupId}/${item.id}`,
    };

    const isActive = location.pathname.includes(item.id);

    return (
      <ConfigurationMenuItemDiv isActive={isActive}>
        <Div
          flexGrow="1"
          ml={level === 0 ? 0 : `${level * theme.spacing.lg}px`}
          color="inherit"
          textDecoration="none"
          {...menuLinkProps}
        >
          {item.label}
        </Div>
      </ConfigurationMenuItemDiv>
    );
  },
);

export { ConfigurationMenuItem };
