import { TFunction } from 'i18next';
import { DEVICE_TYPE } from '../types/DiscoveryPage.types';

export const getTypeDescription = (type: string, t: TFunction): string => {
  switch (type) {
    case DEVICE_TYPE.RACK:
      return t('t479');
    case DEVICE_TYPE.RACKSUCT:
    case DEVICE_TYPE.GEN_SUCT:
    case DEVICE_TYPE.NO_COMP_SUCT:
      return t('t402');
    case DEVICE_TYPE.NO_COMP:
      return t('t480');
    case DEVICE_TYPE.CIRCUIT:
      return t('t481');
    case DEVICE_TYPE.GEN_CIRCUIT:
      return t('t482');
    case DEVICE_TYPE.GEN_DRIVE:
      return t('t256');
    case DEVICE_TYPE.GEN_HVAC:
    case DEVICE_TYPE.HVAC:
      return t('t87');
    case DEVICE_TYPE.GEN_PACK:
      return t('t188');
    case DEVICE_TYPE.UNKNOWN:
      return t('t483');
    case DEVICE_TYPE.PI200:
      return DEVICE_TYPE.PI200.toUpperCase();
    default:
      return `${t('t484')} ${type}`;
  }
};
