import { fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function executeForceResetSm800(url, user) {
  const response = await fetchXMLData({
    url,
    attributes: {
      user: user.user,
      password: user.password,
      action: XML_ACTION.FORCE_RESET
    }
  });
  return response;
}

export { executeForceResetSm800 };
