import React from 'react';
import {
  ContainerDimensions,
  TextInput,
  TreeTableItem,
  TreeViewTable,
  TreeViewTableColumn,
  SelectInput,
  SelectInputOption,
  TreeItem,
  SelectedValueType,
  Notification,
  Spinner,
  SpinnerSize,
  Icon,
  icons,
} from '@danfoss/etui-core/';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { EmptyState, useModal } from '@danfoss/etui-sm';
import { useTheme } from '@danfoss/etui-system';
import { useRefrigLayout } from '../../context';
import {
  DeviceCompressorData,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../../types/DiscoveryPage.types';
import { generateNumberArray, getCurrentSuctionGroupCount } from '../../utils';
import { LayoutDropDownMenu } from '../../../LayoutDropDownMenu';
import {
  getIsAddressEditable,
  getIsDualSuctionEditable,
  getIsModelEditable,
  getIsNameEditable,
  getIsSuctionGroupEditable,
  hasSpecialCharacters,
  processCompressorData,
  updateSelectedDeviceInfo,
  validateAddress,
} from '../../actions';
import { SuctionGroupWaringModal } from '../../modals';
import { CompressorsFlowAddPack } from './CompressorsFlowAddPack';

export interface CompressorsFlowTableProps {
  handleOnCopyOk: (copyAmount: number, selectedRowIndex: number) => void;
  handleDeleteSelectedRow: (selectedRowIndex: number) => void;
  handleOnSuctionChange: (
    selectedRowIndex: number,
    selectedSuctionGroupValue: number,
  ) => void;
  tableDataLoading: boolean;
  handleOnAddRack: () => void;
}

export const CompressorsFlowTable = ({
  handleOnCopyOk,
  handleDeleteSelectedRow,
  handleOnSuctionChange,
  tableDataLoading,
  handleOnAddRack,
}: CompressorsFlowTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    deviceTypeModelList,
    deviceCompressorData,
    deviceConstraintsData,
    discoveryTableData,
    setDiscoveryTableData,
    setDeviceCompressorData,
  } = useRefrigLayout();
  const [compressorTableData, setCompressorTableData] = React.useState<
    TreeTableItem[]
  >([]);
  const [openSections, setOpenSections] = React.useState<SelectedValueType[]>(
    [],
  );
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>(0);
  const [selectedSgCount, setSelectedSgCount] = React.useState<number>(1);
  const [warningMessage, setWarningMessage] = React.useState<string>('');
  const [deviceNames, setDeviceNames] = React.useState<string[]>([]);
  const [deviceAddress, setDeviceAddress] = React.useState<string[]>([]);
  const [deviceSuctionNames, setDeviceSuctionNames] = React.useState<string[]>(
    [],
  );

  const maxSuctionGroups =
    deviceCompressorData?.dualSuctionDevices.length >= 12;
  React.useEffect(() => {
    if (maxSuctionGroups) {
      Notification.error({
        message: t('t17'),
        description: t('t3676'),
        duration: 5,
        theme,
      });
    }
  }, [maxSuctionGroups]);

  let currentSgFocus: React.FocusEvent<HTMLElement> = null;
  const [isTableTextValuesUpdated, setIsTableTextValuesUpdated] =
    React.useState<boolean>(false);

  const handleOnSgLessCountOkClick = () => {
    handleOnSuctionChange(selectedRowIndex, selectedSgCount);
  };
  const [showWarningModal] = useModal(SuctionGroupWaringModal, {
    handleOnSgLessCountOkClick,
    warningMessage,
  });

  const handleShowWarningModal = () => {
    showWarningModal();
  };

  React.useEffect(() => {
    if (isTableTextValuesUpdated) {
      getCompressorTableData();
    }
  }, [isTableTextValuesUpdated]);

  React.useEffect(() => {
    if (deviceCompressorData?.suction?.length) {
      processTableTextValues();
    }
  }, [deviceCompressorData]);

  const processTableTextValues = () => {
    const deviceNames: string[] = [];
    const deviceAddress: string[] = [];
    const deviceSuctionNames: string[] = [];
    deviceCompressorData?.suction?.forEach(suction => {
      deviceNames.push(suction?.name);
      deviceAddress.push(suction?.address);
      deviceCompressorData?.dualSuctionDevices?.forEach(childSuction => {
        if (
          childSuction?.rk === suction?.rk &&
          (childSuction?.deviceType === DEVICE_TYPE.NO_COMP_SUCT ||
            childSuction?.address === suction?.address)
        ) {
          deviceSuctionNames.push(childSuction.name);
        }
      });
    });

    setDeviceAddress(deviceAddress);
    setDeviceSuctionNames(deviceSuctionNames);
    setDeviceNames(deviceNames);
    setIsTableTextValuesUpdated(true);
  };

  const handleOnSuctionGroupChange = (options: SelectInputOption) => {
    const selectedRowIndex: number = +options.value;
    const selectedSgValue: number = +options.label;
    const currentSelectedRowNumSg: number = getCurrentSuctionGroupCount(
      deviceCompressorData?.suction,
      selectedRowIndex,
    );
    currentSgFocus.target.blur();
    if (selectedSgValue > currentSelectedRowNumSg) {
      handleOnSuctionChange(selectedRowIndex, selectedSgValue);
      return;
    }

    if (currentSelectedRowNumSg === 1 && selectedSgValue === 0) {
      Notification.error({
        message: t('t17'),
        description: t('t3363'),
        duration: 3,
        theme,
      });
      return;
    }

    if (currentSelectedRowNumSg > 1 && selectedSgValue === 0) {
      setWarningMessage(t('t3362'));
      setSelectedRowIndex(selectedRowIndex);
      setSelectedSgCount(1);
      handleShowWarningModal();
      return;
    }

    if (
      currentSelectedRowNumSg > 1 &&
      selectedSgValue < currentSelectedRowNumSg
    ) {
      setWarningMessage(t('t3362'));
      setSelectedRowIndex(selectedRowIndex);
      setSelectedSgCount(selectedSgValue);
      handleShowWarningModal();
    }
  };

  const handleOnDeleteSelectedRow = (selectedRowIndex: number) => {
    handleDeleteSelectedRow(selectedRowIndex);
  };

  const saveSuctionName = (
    event: React.FocusEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;

    const selectedDeviceDualSuction: DiscoveryTableData =
      deviceCompressorData.dualSuctionDevices[selectedRowIndex];
    if (selectedDeviceDualSuction?.name !== value) {
      const selectedDiscoveryTableData: DiscoveryTableData =
        discoveryTableData?.find(
          device => device.deviceId === selectedDeviceDualSuction.deviceId,
        );
      selectedDiscoveryTableData.name = value.length
        ? value
        : selectedDiscoveryTableData.name;

      const updatedData: DeviceCompressorData = processCompressorData(
        discoveryTableData,
        t,
        deviceTypeModelList,
        false,
      );
      setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
      setDeviceCompressorData(updatedData);
    }
  };

  const saveName = (
    event: React.FocusEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;

    const selectedDeviceSuction: DiscoveryTableData =
      deviceCompressorData.suction[selectedRowIndex];

    if (selectedDeviceSuction?.name !== value) {
      const selectedDiscoveryTableData: DiscoveryTableData =
        discoveryTableData?.find(
          device => device.deviceId === selectedDeviceSuction.deviceId,
        );

      selectedDiscoveryTableData.name = value;

      const updatedData: DeviceCompressorData = processCompressorData(
        discoveryTableData,
        t,
        deviceTypeModelList,
        false,
      );
      setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
      setDeviceCompressorData(updatedData);
    }
  };

  const validateAndSaveAddress = (
    event: React.FocusEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;

    const selectedDeviceSuction: DiscoveryTableData =
      deviceCompressorData.suction[selectedRowIndex];

    if (selectedDeviceSuction?.address !== value) {
      const selectedDeviceDualSuction: DiscoveryTableData =
        deviceCompressorData?.dualSuctionDevices?.find(
          dualSuctionDevice =>
            dualSuctionDevice.rk === selectedDeviceSuction.rk &&
            (dualSuctionDevice.deviceType === DEVICE_TYPE.NO_COMP_SUCT ||
              dualSuctionDevice.address === selectedDeviceSuction.address),
        );

      const selectedSuctionDiscoveryTableData: DiscoveryTableData[] =
        discoveryTableData?.filter(
          device =>
            device.deviceId === selectedDeviceSuction.deviceId ||
            device.deviceId === selectedDeviceDualSuction.deviceId,
        );

      const invalidMessage: string = validateAddress(
        value,
        selectedDeviceSuction?.deviceId,
        deviceConstraintsData,
        discoveryTableData,
      );

      let address: string = '';
      if (invalidMessage) {
        Notification.error({
          message: t('t17'),
          description:
            invalidMessage === 't3520'
              ? t(invalidMessage, { maxAddr: deviceConstraintsData.max_naddr })
              : t(invalidMessage),
          duration: 3,
          theme,
        });
        address = '0';
      } else {
        address =
          value.trim() !== '' && !hasSpecialCharacters(value) ? value : '0';
      }
      selectedSuctionDiscoveryTableData.forEach(device => {
        device.address = address;
      });

      const updatedData: DeviceCompressorData = {
        updatedDiscoveryTableData: discoveryTableData,
        suction: deviceCompressorData.suction,
        dualSuctionDevices: deviceCompressorData.dualSuctionDevices,
      };
      setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
      setDeviceCompressorData(updatedData);
    }
  };

  const handleOnModelListNameChange = (
    option: SelectInputOption,
    selectedRowIndex: number,
  ) => {
    const updatedData = updateSelectedDeviceInfo(
      modelListNameOptions,
      option,
      selectedRowIndex,
      deviceCompressorData,
      discoveryTableData,
      deviceConstraintsData,
      deviceTypeModelList,
      t,
    );
    setDiscoveryTableData(updatedData.updatedDiscoveryTableData);
    setDeviceCompressorData(updatedData);
  };

  const modelListNameOptions: SelectInputOption[] =
    deviceTypeModelList?.modelList?.map(value => ({
      value: value._,
      label: value._,
    }));

  const handleOnDeviceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;
    const names = deviceNames.slice();
    names[selectedRowIndex] = value;
    setDeviceNames(names);
    setIsTableTextValuesUpdated(true);
  };

  const handleOnDeviceAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;
    const address = deviceAddress.slice();
    address[selectedRowIndex] = value;
    setDeviceAddress(address);
    setIsTableTextValuesUpdated(true);
  };

  const handleOnDeviceSuctionNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;
    const suctionNames = deviceSuctionNames.slice();
    suctionNames[selectedRowIndex] = value;
    setDeviceSuctionNames(suctionNames);
    setIsTableTextValuesUpdated(true);
  };

  const getCompressorTableData = () => {
    const treeViewData: TreeTableItem[] = [];
    deviceCompressorData.suction.forEach((compressor, index) => {
      treeViewData.push({
        id: index,
        name: '',
        isTextInput: true,
        treeTableRows: [
          {
            col1: (
              <Div testId={`name-row-${index}-div`} width="80%">
                <TextInput
                  type="text"
                  value={deviceNames[index]}
                  onChange={e => {
                    handleOnDeviceNameChange(e, index);
                  }}
                  onBlur={e => {
                    saveName(e, index);
                  }}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur();
                    }
                  }}
                  maxLength={+deviceConstraintsData?.max_name}
                  disabled={getIsNameEditable(compressor?.deviceType)}
                  width="100%"
                  height="auto"
                />
              </Div>
            ),
            col2: (
              <Div testId={`address-row-${index}-div`} width="60%" ml="-30px">
                <TextInput
                  type="text"
                  value={deviceAddress[index]}
                  onChange={e => {
                    handleOnDeviceAddressChange(e, index);
                  }}
                  onBlur={e => {
                    validateAndSaveAddress(e, index);
                  }}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      e.currentTarget.blur();
                    }
                  }}
                  maxLength={+deviceConstraintsData?.max_naddr.length}
                  disabled={getIsAddressEditable(
                    compressor?.deviceType,
                    compressor?.online === '1',
                  )}
                  width="100%"
                  height="auto"
                />
              </Div>
            ),
            col3: (
              <Div
                testId={`model-row-${index}-div`}
                width="80%"
                height="90%"
                ml="-30px"
              >
                <SelectInput
                  value={{
                    label: compressor?.listname,
                    value: '',
                  }}
                  name=""
                  size="small"
                  options={modelListNameOptions}
                  disabled={getIsModelEditable(
                    compressor?.deviceType,
                    compressor?.online === '1',
                  )}
                  onChange={(event: SelectInputOption) => {
                    handleOnModelListNameChange(event, index);
                  }}
                />
              </Div>
            ),
            col4: (
              <Div
                testId={`suction-group-row-${index}-div`}
                width="80%"
                height="90%"
                ml="-30px"
              >
                <SelectInput
                  value={{
                    label: compressor?.num_sg?.toString(),
                    value: '',
                  }}
                  name=""
                  size="small"
                  onChange={(option: SelectInputOption) => {
                    handleOnSuctionGroupChange(option);
                  }}
                  onFocus={e => {
                    e.persist();
                    currentSgFocus = e;
                  }}
                  options={getSuctionGroupOptions(compressor?.max_sg, index)}
                  disabled={getIsSuctionGroupEditable(
                    compressor?.deviceType,
                    compressor?.online === '1',
                  )}
                />
              </Div>
            ),
            col5: (
              <Div testId="copy-delete-div" ml="30%">
                <LayoutDropDownMenu
                  rowIndex={index}
                  handleOnCopyOk={handleOnCopyOk}
                  handleDeleteSelectedRow={handleOnDeleteSelectedRow}
                />
              </Div>
            ),
          },
        ],
        items: getChildrenSuctions(compressor),
      });
    });
    setCompressorTableData(treeViewData);
    setIsTableTextValuesUpdated(false);
  };

  const getChildrenSuctions = (compressor: DiscoveryTableData) => {
    const treeItem: TreeItem[] & TreeTableItem[] = [];
    deviceCompressorData?.dualSuctionDevices?.forEach((childSuction, index) => {
      if (
        childSuction.rk === compressor.rk &&
        (childSuction.deviceType === DEVICE_TYPE.NO_COMP_SUCT ||
          childSuction.address === compressor.address)
      ) {
        treeItem.push({
          id: `suction-${childSuction.deviceId}`,
          name: '',
          isTextInput: true,
          treeTableRows: [
            {
              col1: (
                <Div testId={`suction-sub-row-${index}-div`} width="75.25%">
                  <TextInput
                    type="text"
                    value={deviceSuctionNames[index]}
                    onChange={e => {
                      handleOnDeviceSuctionNameChange(e, index);
                    }}
                    onBlur={e => {
                      saveSuctionName(e, index);
                    }}
                    onKeyUp={e => {
                      if (e.key === 'Enter') {
                        e.currentTarget.blur();
                      }
                    }}
                    maxLength={+deviceConstraintsData?.max_name}
                    disabled={getIsDualSuctionEditable(
                      childSuction?.deviceType,
                    )}
                    width="100%"
                    height="auto"
                    ml="40px"
                  />
                </Div>
              ),
            },
          ],
        });
      }
    });
    return treeItem;
  };

  const getSuctionGroupOptions = (maxSg: number, index: number) => {
    const suctionGroupValues: number[] = generateNumberArray(0, maxSg);
    const suctionGroupOptions: SelectInputOption[] = [];
    suctionGroupValues.forEach(suctionGroupValue => {
      suctionGroupOptions.push({
        value: index.toString(),
        label: suctionGroupValue.toString(),
      });
    });
    return suctionGroupOptions;
  };

  const columns: TreeViewTableColumn[] = [
    {
      key: 'col1',
      title: t('t76').toUpperCase(),
      width: 35,
    },
    {
      key: 'col2',
      title: t('t57').toUpperCase(),
      width: 10,
    },
    {
      key: 'col3',
      title: t('t355').toUpperCase(),
      width: 35,
    },
    {
      key: 'col4',
      title: t('t519').toUpperCase(),
      width: 10,
    },
    {
      key: 'col5',
      width: 10,
    },
  ];

  const handleOpenSectionChange = (
    sectionsOpenUpdated: SelectedValueType[],
  ) => {
    setOpenSections(sectionsOpenUpdated);
  };

  const handleOnAddPack = () => {
    if (deviceCompressorData?.suction?.length) {
      handleOnCopyOk(1, 0);
    } else {
      handleOnAddRack();
    }
  };

  return (
    <>
      <Div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Icon
          glyph={icons.WARNING}
          styles={{
            root: {
              color: theme.palette.warning.main,
            },
          }}
          size={32}
        />
        <Div style={{ marginTop: '5px', marginLeft: '5px' }}>{t('t3676')}</Div>
      </Div>
      <ContainerDimensions>
        {() =>
          tableDataLoading ? (
            <Div
              testId="compressor-flow-table-spinner-div"
              style={{ marginTop: '200px' }}
            >
              <Spinner size={SpinnerSize.small} />
            </Div>
          ) : !deviceCompressorData?.suction?.length ? (
            <>
              <Div
                testId="compressor-flow-table-empty-div"
                style={{ marginTop: '200px' }}
              >
                <EmptyState title={t('t3365')} />
              </Div>
              <Div
                testId="add-pack-div"
                style={{
                  marginTop: '100px',
                  width: '100%',
                  display: 'inline-block',
                }}
              >
                <CompressorsFlowAddPack handleOnAddPack={handleOnAddPack} />
              </Div>
            </>
          ) : (
            <>
              <Div
                testId="compressor-flow-table-content-div"
                style={{ marginTop: '20px', width: '97%', marginLeft: '20px' }}
              >
                <TreeViewTable
                  columns={columns}
                  data={compressorTableData}
                  openSections={openSections}
                  onOpenSectionChange={handleOpenSectionChange}
                />
                <Div
                  testId="add-pack-div"
                  style={{
                    width: '100%',
                    display: 'inline-block',
                  }}
                >
                  <CompressorsFlowAddPack handleOnAddPack={handleOnAddPack} />
                </Div>
              </Div>
            </>
          )
        }
      </ContainerDimensions>
    </>
  );
};
