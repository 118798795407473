import React from 'react';
import {
  ConfigurationListDataArray,
  ConfigurationListItem,
  fetchLayoutListByUnit,
  XML_REFRIG_COPY_TAB_INDEX,
} from '@danfoss/etui-sm-xml';
import { useAuth, useUnit, useXmlResource } from '@danfoss/etui-sm/';
import { SelectInputOption, Notification } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  SourceAlarmTableContent,
  SourceAlarmTableData,
  SourceAlarmTablePageContent,
} from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';
import { getArray } from '../../../../../Configuration/utils';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { writeConfigurationListItem } from '../../../../../Configuration/actions';
import { REFRIG_SUCCESS_RESPONSE } from '../../../RefrigLayoutModal/types';
import {
  addPageToListItems,
  checkIfPageContentNotAvailable,
  filterSourceAlarmTableContentList,
  findCurrentPageContent,
  findEditedActionListItem,
  findEditedListItem,
  processSourceAlarmTableData,
  setActionStatus,
  setLevelStatus,
} from './actions';
import { SourceAlarmFlowPage } from '.';

export type SourceAlarmFlowContentProps = {
  tableAddress: string;
};

export const SourceAlarmFlowContent = ({
  tableAddress,
}: SourceAlarmFlowContentProps) => {
  const { url, ip } = useXmlResource();
  const { user } = useAuth();
  const { units, getFirstValidUrl } = useUnit();
  const filteredUnit = units.filter(unit =>
    getFirstValidUrl(unit).includes(ip),
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const unit = filteredUnit[0];
  const [configurationListItem, setConfigurationListItem] = React.useState<
    any[]
  >([]);
  const [enableDisableToggledWithPage, setEnableDisableToggledWithPage] =
    React.useState<boolean>(false);
  const [enableDisableToggledWithSearch, setEnableDisableToggledWithSearch] =
    React.useState<boolean>(false);
  const {
    sourceAlarmTableData,
    isAllPageDataFetched,
    page,
    searchMode,
    searchValue,
    pageValues,
    allPageSourceAlarmData,
    sourceAlarmTablePageContent,
    setSourceAlarmTableData,
    setMultipage,
    setIsAllPageDataFetched,
    setSourceAlarmTablePageContent,
    setIsPageDataFetched,
    setAllPageSourceAlarmData,
    setSelectedRowIndex,
    setSaveCompleted,
  } = useRefrigCopy();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const networkTableData = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS,
          user,
          page,
        ]);
        if (networkTableData?.items?.l?.length) {
          const tableDataItemList = getArray(networkTableData?.items?.l);
          const updatedListItems: ConfigurationListItem[] = addPageToListItems(
            tableDataItemList,
            page,
          );
          const sourceAlarmTableContentList: SourceAlarmTableContent[] =
            processSourceAlarmTableData(
              updatedListItems,
              networkTableData?.listgroup?.listdata,
              page,
              t,
            );
          const updatedSourceAlarmTableData: SourceAlarmTableData = {
            sourceAlarmTableContent: sourceAlarmTableContentList,
          };
          const addedSourceAlarmTablePageContent: SourceAlarmTablePageContent =
            {
              listItems: networkTableData?.items?.l,
              listData: networkTableData?.listgroup
                ?.listdata as ConfigurationListDataArray,
              page,
            };
          setMultipage(networkTableData?.multipage);
          setSourceAlarmTableData(updatedSourceAlarmTableData);
          sourceAlarmTablePageContent.push(addedSourceAlarmTablePageContent);
          setSourceAlarmTablePageContent(sourceAlarmTablePageContent);
          setIsPageDataFetched(true);
          setEnableDisableToggledWithPage(false);
        }
      } catch (error) {
        Notification.error({
          message: t('t17'),
          description: t('t3417'),
          duration: 3,
          theme,
        });
      }
    };
    if (
      !sourceAlarmTablePageContent?.length ||
      checkIfPageContentNotAvailable(sourceAlarmTablePageContent, page)
    ) {
      setIsPageDataFetched(false);
      fetch();
    } else {
      getFetched();
    }
  }, [page, enableDisableToggledWithPage]);

  const getFetched = () => {
    const currentPageContent = findCurrentPageContent(
      sourceAlarmTablePageContent,
      page,
    );
    const updatedListItems: ConfigurationListItem[] = addPageToListItems(
      getArray(currentPageContent?.listItems),
      page,
    );
    const sourceAlarmTableContentList: SourceAlarmTableContent[] =
      processSourceAlarmTableData(
        updatedListItems,
        currentPageContent?.listData,
        page,
        t,
      );
    const updatedSourceAlarmTableData: SourceAlarmTableData = {
      sourceAlarmTableContent: sourceAlarmTableContentList,
    };
    setSourceAlarmTableData(updatedSourceAlarmTableData);
  };

  const fetchAllPages = async (pages: number[]) => {
    const response = [];
    try {
      await pages.reduce(async (promise, page) => {
        await promise;
        page -= 1;
        const data = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS,
          user,
          page.toString(),
        ]);
        response.push(data);
      }, Promise.resolve());
    } catch (error) {
      Notification.error({
        message: t('t17'),
        description: t('t3417'),
        duration: 3,
        theme,
      });
    }
    const sourceAlarmResponse = response
      .filter(data => data !== undefined)
      .flat();
    return sourceAlarmResponse;
  };

  React.useEffect(() => {
    const fetchAll = async () => {
      if (pageValues?.length && !allPageSourceAlarmData?.length) {
        const data: any[] = await fetchAllPages(pageValues);
        setConfigurationListItem(data);
      }
    };
    if (searchMode) {
      fetchAll();
    }
  }, [searchMode, enableDisableToggledWithSearch]);

  React.useEffect(() => {
    if (configurationListItem?.length) {
      const allContentList: SourceAlarmTableContent[] = [];
      const sourceAlarmTablePageContentList: SourceAlarmTablePageContent[] = [];
      configurationListItem?.forEach((pageData, index) => {
        const updatedListItem: ConfigurationListItem[] = addPageToListItems(
          getArray(pageData?.items?.l),
          index.toString(),
        );
        const processedSourceAlarmTableContentList: SourceAlarmTableContent[] =
          processSourceAlarmTableData(
            updatedListItem,
            pageData?.listgroup?.listdata,
            index.toString(),
            t,
          );
        const addedSourceAlarmTablePageContent: SourceAlarmTablePageContent = {
          listItems: updatedListItem,
          listData: pageData?.listgroup?.listdata
            ?.listdata as ConfigurationListDataArray,
          page: index.toString(),
        };
        sourceAlarmTablePageContentList.push(addedSourceAlarmTablePageContent);
        allContentList.push(...processedSourceAlarmTableContentList);
      });
      const filteredSourceAlarmTableContentList =
        filterSourceAlarmTableContentList(allContentList, searchValue);
      const filteredSourceAlarmTableData: SourceAlarmTableData = {
        sourceAlarmTableContent: filteredSourceAlarmTableContentList,
      };
      setSourceAlarmTablePageContent(sourceAlarmTablePageContentList);
      setAllPageSourceAlarmData(allContentList.flat());
      setIsAllPageDataFetched(true);
      setSourceAlarmTableData(filteredSourceAlarmTableData);
      setEnableDisableToggledWithSearch(false);
    }
  }, [configurationListItem]);

  React.useEffect(() => {
    if (searchMode && isAllPageDataFetched) {
      const filteredSourceAlarmTableContentList =
        filterSourceAlarmTableContentList(allPageSourceAlarmData, searchValue);
      const filteredSourceAlarmTableData: SourceAlarmTableData = {
        sourceAlarmTableContent: filteredSourceAlarmTableContentList,
      };
      setSourceAlarmTableData(filteredSourceAlarmTableData);
    }
  }, [searchValue]);

  const revalidate = async (page: string) => {
    const fetchResponse = await fetchLayoutListByUnit([
      url,
      unit,
      tableAddress,
      XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS,
      user,
      page,
    ]);
    return fetchResponse;
  };

  const save = async (
    listItem: ConfigurationListItem,
    iVal: string,
    value: string,
  ) => {
    const response = await withErrorHandled(
      t,
      theme,
      writeConfigurationListItem,
    )(
      url,
      user,
      null,
      `${tableAddress}-${XML_REFRIG_COPY_TAB_INDEX.SOURCE_ALARMS}`,
      null,
      '0',
      '0',
      listItem,
      value,
      iVal,
      '',
    );
    return response;
  };

  const updateSourceAlarmTablePageContent = (
    page: string,
    updatedListItems: ConfigurationListItem[],
    listData: ConfigurationListDataArray,
  ) => {
    const addedSourceAlarmTablePageContent: SourceAlarmTablePageContent = {
      listItems: updatedListItems,
      listData,
      page,
    };
    const currentPageContentIndex: number =
      sourceAlarmTablePageContent.findIndex(
        pageContent => pageContent.page === page,
      );
    sourceAlarmTablePageContent.splice(
      currentPageContentIndex,
      1,
      addedSourceAlarmTablePageContent,
    );
    setSourceAlarmTablePageContent(sourceAlarmTablePageContent);
  };

  const handleLevelSave = async (
    listItem: ConfigurationListItem,
    iVal: string,
    value: string,
    selectedRowIndex: number,
  ) => {
    const response = await save(listItem, iVal, value);
    let updatedSourceAlarmTableData: SourceAlarmTableData = null;
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      const fetchResponse = await revalidate(listItem.page);
      const fetchResponseListItem = getArray(fetchResponse?.items?.l);
      const updatedListItems: ConfigurationListItem[] = addPageToListItems(
        fetchResponseListItem,
        listItem.page,
      );
      const sourceAlarmTableContentList: SourceAlarmTableContent[] =
        processSourceAlarmTableData(
          fetchResponseListItem,
          fetchResponse?.listgroup?.listdata,
          listItem.page,
          t,
        );
      updateSourceAlarmTablePageContent(
        listItem.page,
        updatedListItems,
        fetchResponse?.listgroup?.listdata as ConfigurationListDataArray,
      );
      if (!searchMode) {
        updatedSourceAlarmTableData = {
          sourceAlarmTableContent: sourceAlarmTableContentList,
        };
      } else {
        let responseIndex: number = 0;
        allPageSourceAlarmData.forEach((data, index) => {
          if (
            data.page === listItem.page &&
            responseIndex < sourceAlarmTableContentList.length
          ) {
            allPageSourceAlarmData.splice(
              index,
              1,
              sourceAlarmTableContentList[responseIndex],
            );
            ++responseIndex;
          }
        });
        const filteredSourceAlarmTableContentList =
          filterSourceAlarmTableContentList(
            allPageSourceAlarmData,
            searchValue,
          );
        const filteredSourceAlarmTableData: SourceAlarmTableData = {
          sourceAlarmTableContent: filteredSourceAlarmTableContentList,
        };
        updatedSourceAlarmTableData = {
          sourceAlarmTableContent:
            filteredSourceAlarmTableData.sourceAlarmTableContent,
        };
      }

      setLevelStatus(
        updatedSourceAlarmTableData,
        selectedRowIndex,
        false,
        true,
        false,
      );
    } else {
      setLevelStatus(
        sourceAlarmTableData,
        selectedRowIndex,
        false,
        false,
        true,
        value,
      );
      updatedSourceAlarmTableData = {
        sourceAlarmTableContent: sourceAlarmTableData.sourceAlarmTableContent,
      };
    }
    setSourceAlarmTableData(updatedSourceAlarmTableData);
    setSelectedRowIndex(selectedRowIndex);
    setSaveCompleted(true);
  };

  const handleActionSave = async (
    listItem: ConfigurationListItem,
    iVal: string,
    value: string,
    selectedRowIndex: number,
    selectedActionValue: string,
  ) => {
    const response = await save(listItem, iVal, value);
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      const fetchResponse = await revalidate(listItem.page);
      let updatedSourceAlarmTableData: SourceAlarmTableData = null;
      let sourceAlarmTableContentList: SourceAlarmTableContent[] = null;
      if (!searchMode) {
        const updatedListItems: ConfigurationListItem[] = addPageToListItems(
          getArray(fetchResponse?.items?.l),
          page,
        );
        sourceAlarmTableContentList = processSourceAlarmTableData(
          updatedListItems,
          fetchResponse?.listgroup?.listdata,
          listItem.page,
          t,
        );
        updatedSourceAlarmTableData = {
          sourceAlarmTableContent: sourceAlarmTableContentList,
        };
        const addedSourceAlarmTablePageContent: SourceAlarmTablePageContent = {
          listItems: fetchResponse?.items?.l,
          listData: fetchResponse?.listgroup
            ?.listdata as ConfigurationListDataArray,
          page,
        };
        const currentPageContentIndex: number =
          sourceAlarmTablePageContent.findIndex(
            pageContent => pageContent.page === page,
          );
        sourceAlarmTablePageContent.splice(
          currentPageContentIndex,
          1,
          addedSourceAlarmTablePageContent,
        );
        setSourceAlarmTablePageContent(sourceAlarmTablePageContent);
      } else {
        sourceAlarmTablePageContent.forEach(pageContent => {
          pageContent.listItems.forEach(item => {
            if (
              item.name === listItem.name &&
              item.li === listItem.li &&
              item.page === listItem.page
            ) {
              item.iVal = iVal;
              item.value = selectedActionValue;
            }
          });
        });
        updatedSourceAlarmTableData = {
          sourceAlarmTableContent: sourceAlarmTableData.sourceAlarmTableContent,
        };
      }
      setActionStatus(
        searchMode ? sourceAlarmTableData : updatedSourceAlarmTableData,
        selectedRowIndex,
        false,
        true,
        false,
      );
      setSourceAlarmTableData(updatedSourceAlarmTableData);
    } else {
      setActionStatus(
        sourceAlarmTableData,
        selectedRowIndex,
        false,
        false,
        true,
        value,
      );
      const updatedSourceAlarmTableData: SourceAlarmTableData = {
        sourceAlarmTableContent: sourceAlarmTableData.sourceAlarmTableContent,
      };
      setSourceAlarmTableData(updatedSourceAlarmTableData);
    }
    setSelectedRowIndex(selectedRowIndex);
    setSaveCompleted(true);
  };

  const handleOnLevelChange = async (
    selectedOption: SelectInputOption,
    content: SourceAlarmTableContent,
    selectedRowIndex: number,
  ) => {
    const selectedLevelValue: string = selectedOption.label.includes(t('t2215'))
      ? t('t3404')
      : selectedOption.label;
    const ival: string = selectedOption.value;
    const value: string = content.level;

    setLevelStatus(
      sourceAlarmTableData,
      selectedRowIndex,
      true,
      false,
      false,
      selectedLevelValue,
    );

    const updatedSourceAlarmTableData: SourceAlarmTableData = {
      sourceAlarmTableContent: sourceAlarmTableData.sourceAlarmTableContent,
    };
    setSourceAlarmTableData(updatedSourceAlarmTableData);
    const currentPageContent = findCurrentPageContent(
      sourceAlarmTablePageContent,
      content.page,
    );
    const listItem: ConfigurationListItem = findEditedListItem(
      content,
      currentPageContent.listItems,
    );
    await handleLevelSave(listItem, ival, value, selectedRowIndex);
  };

  const handleOnActionChange = async (
    selectedOption: SelectInputOption,
    content: SourceAlarmTableContent,
    selectedRowIndex: number,
  ) => {
    const selectedActionValue: string = selectedOption.label;
    const ival: string = selectedOption.value;
    const value: string = content.action;

    setActionStatus(
      sourceAlarmTableData,
      selectedRowIndex,
      true,
      false,
      false,
      selectedActionValue,
    );
    const updatedSourceAlarmTableData: SourceAlarmTableData = {
      sourceAlarmTableContent: sourceAlarmTableData.sourceAlarmTableContent,
    };
    setSourceAlarmTableData(updatedSourceAlarmTableData);
    const currentPageContent = findCurrentPageContent(
      sourceAlarmTablePageContent,
      content.page,
    );
    const listItem: ConfigurationListItem = findEditedActionListItem(
      content,
      currentPageContent.listItems,
    );

    await handleActionSave(
      listItem,
      ival,
      value,
      selectedRowIndex,
      selectedActionValue,
    );
  };

  const enableOrDisableAllAlarms = async (listItem: ConfigurationListItem) => {
    const response = await save(listItem, '', '');
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      setSourceAlarmTablePageContent([]);
      if (searchMode) {
        setAllPageSourceAlarmData([]);
        setEnableDisableToggledWithSearch(true);
      } else {
        setEnableDisableToggledWithPage(true);
      }
    }
  };

  const handleOnEnableToggle = async (isChecked: boolean) => {
    searchMode ? setIsAllPageDataFetched(false) : setIsPageDataFetched(false);
    const currentPageContent = findCurrentPageContent(
      sourceAlarmTablePageContent,
      '0',
    );
    const listItem = isChecked
      ? currentPageContent?.listItems?.[0]
      : currentPageContent?.listItems?.[1];
    await enableOrDisableAllAlarms(listItem);
  };

  return (
    <SourceAlarmFlowPage
      handleOnLevelChange={handleOnLevelChange}
      handleOnActionChange={handleOnActionChange}
      handleOnEnableToggle={handleOnEnableToggle}
    />
  );
};
