import { styled } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';
import { lighten } from '@danfoss/etui-colors';

type DivPropsWithTheme = Omit<DivProps, 'theme'> &
  Required<Pick<DivProps, 'theme'>>;
interface TreeViewItemContentDivProps extends DivPropsWithTheme {
  isRowSelected?: boolean;
}

export const TreeViewItemContentDiv = styled(Div).attrs(
  ({
    isRowSelected,
    theme,
    ...props
  }: TreeViewItemContentDivProps): DivProps => ({
    display: 'flex',
    alignItems: 'center',
    p: `${theme.spacing.xs}px`,
    minHeight: '38px',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: isRowSelected ? 700 : 400,
    outline: 0,
    userSelect: 'none',
    ...props,
  }),
)<TreeViewItemContentDivProps>`
  &:hover {
    background-color: ${({ theme }) =>
      lighten(theme.palette.black[80] as string, 0.15)};
  }
`;
