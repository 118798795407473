import { Theme } from '@danfoss/etui-themes';
import { ModalPlacement } from './FloatingModalButton.types';

enum ModalPosition {
  left,
  right,
  center,
}

export const getFloatingModalPosition = (
  buttonRef: any,
  modalWidth: number,
  modalHeight: number,
  placement: ModalPlacement,
  theme: Theme,
): { top: number; left: number; modalHeight: number; modalWidth: number } => {
  const buttonSize = buttonRef.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  const windowHeight = document.body.clientHeight;
  const buttonTop = scrollY + buttonSize.top;
  const buttonLeft = scrollX + buttonSize.left;
  const buttonBottom = windowHeight - buttonTop;
  const MIN_TOP_MARGIN = -12;

  // If the button is on the sides of the page (1/5 or 4/5) it will be rendered to the side of the element in order to prevent overflow
  const positionPercentage = buttonLeft / (windowWidth / 5);

  const position =
    positionPercentage < 1
      ? ModalPosition.left
      : positionPercentage > 4
      ? ModalPosition.right
      : ModalPosition.center;

  let computedLeft: number;

  switch (position) {
    case ModalPosition.left:
      computedLeft = theme.spacing.xs;
      break;
    case ModalPosition.right:
      computedLeft = -(modalWidth - buttonSize.width + theme.spacing.xs);
      break;
    default:
      computedLeft = buttonSize.width / 2 - modalWidth / 2;
      break;
  }

  let computedHeight: number;

  // Prevents height overflow either on top or bottom based on the placement provided
  if (placement === 'top') {
    computedHeight =
      windowHeight - (buttonBottom + modalHeight) < theme.spacing.md
        ? windowHeight - (buttonBottom + theme.spacing.md)
        : modalHeight;
  } else {
    computedHeight =
      windowHeight < modalHeight + buttonTop + buttonSize.height
        ? modalHeight -
          (modalHeight +
            buttonTop +
            buttonSize.height +
            theme.spacing.md -
            windowHeight)
        : modalHeight;
  }

  // Calculates top value
  const computedTop =
    placement === 'bottom'
      ? MIN_TOP_MARGIN
      : -buttonSize.height - computedHeight;

  return {
    left: computedLeft,
    top: computedTop,
    modalHeight: computedHeight,
    modalWidth,
  };
};
