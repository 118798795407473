import * as React from 'react';
import { Icon, IconSize } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import * as S from './styles';
import { InfoItem } from './InfoItem';
import { getIconForCategory } from './utils';
import { HelpPanelCategoryItem } from './HelpPanel.types';

export interface InfoItemCategoryProps {
  category: HelpPanelCategoryItem;
  categoryIconMapping?: any;
}

export const InfoItemCategory: React.FC<InfoItemCategoryProps> = ({
  category,
  categoryIconMapping,
}) => {
  const theme = useTheme();
  return (
    <S.CategoryWrapper>
      <S.CategoryTitleWrapper theme={theme}>
        <Icon
          glyph={getIconForCategory(category.categoryKey, categoryIconMapping)}
          size={34 as IconSize}
        />
        <S.InfoItemCategoryTitle theme={theme}>
          {category.categoryName}
        </S.InfoItemCategoryTitle>
      </S.CategoryTitleWrapper>
      {category.items
        .sort((first, second) => first.itemName?.localeCompare(second.itemName))
        .map(infoItem => (
          <InfoItem key={infoItem.itemKey} item={infoItem} />
        ))}
    </S.CategoryWrapper>
  );
};
