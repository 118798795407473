import '@danfoss/etui-sm-xml';
import { getIsMcxLightDevice } from './get-is-mcx-light-device.js';
import { checkIfLightingDevice } from './check-if-lighting-device.js';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-e1ae8e90.js';
import { DeviceGroupedType } from '../types/DeviceGrouped.js';
import { getIsLightZoneDevice } from './get-is-light-zone-device.js';

function getGroupedLightingDevices(deviceType, devices) {
  const lightZones = [];
  const {
    zoneDevices,
    lightDevices
  } = devices.reduce((accumulator, device) => {
    if (getIsLightZoneDevice(device)) {
      accumulator.zoneDevices.push(device);
    }
    if (checkIfLightingDevice(device)) {
      accumulator.lightDevices.push(_objectSpread2(_objectSpread2({}, device), {}, {
        id: device.arg2 ? `${device.host}-${device.arg1}-${device.arg2}` : `${device.host}-${device.arg1}`,
        groupType: DeviceGroupedType.DEVICE_ONLY,
        deviceType,
        items: []
      }));
    }
    return accumulator;
  }, {
    zoneDevices: [],
    lightDevices: []
  });
  if (!zoneDevices.length) return lightDevices;
  for (let index = 0; index < zoneDevices.length; index++) {
    const lightZone = _objectSpread2(_objectSpread2({}, zoneDevices[index]), {}, {
      groupType: DeviceGroupedType.GROUP_DEVICE,
      deviceType
    });
    lightZone.id = `${lightZone.host}-${lightZone.arg1}-${lightZone.nodetype}`;
    const lightZoneItems = [];
    for (let lightDevicesIndex = 0; lightDevicesIndex < lightDevices.length; lightDevicesIndex++) {
      const lightDevice = lightDevices[lightDevicesIndex];
      const lightDeviceIdItems = lightDevice.id.split('-');
      const lightZoneIdItems = lightZone.id.split('-');
      if (lightDeviceIdItems[0] === lightZoneIdItems[0] && lightDeviceIdItems[1] === lightZoneIdItems[1] && !getIsMcxLightDevice(lightZone)) {
        lightZoneItems.push(lightDevice);
      }
    }
    lightZone.items = lightZoneItems;
    lightZones.push(lightZone);
  }
  return lightZones;
}

export { getGroupedLightingDevices };
