import React from 'react';
import useSWR, { SWRResponse } from 'swr';

export function useSWRWithFetchingCount<T>(
  key,
  fetcher,
  options,
): SWRResponse<T> & { fetchingCount: number } {
  const props = useSWR(key, fetcher, options);
  const [fetchingCount, setFetchingCount] = React.useState(0);

  React.useEffect(() => {
    if (props.isValidating) {
      setFetchingCount(prevCount => prevCount + 1);
    }
  }, [props.isValidating]);

  return { ...props, fetchingCount };
}
