const timeoutPromise = (promise, ms, errorMessage) => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error(errorMessage));
    }, ms);
    promise.then(value => {
      clearTimeout(timeout);
      resolve(value);
    }).catch(reason => {
      clearTimeout(timeout);
      reject(reason);
    }).finally(() => {
      clearTimeout(timeout);
    });
  });
};

export { timeoutPromise };
