/**
 * Validate domain name server (dns)
 *
 * @export
 * @param {string} uri
 * @returns
 */
function getIsValidDns(uri) {
  try {
    if (uri.replace(/https:\/\/|http:\/\//g, '') === '0') return false;
    return !!new URL(uri.includes('http') ? uri : `http://${uri}`);
  } catch (_) {
    return false;
  }
}

export { getIsValidDns };
