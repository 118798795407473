import { XML_ACTION } from '../constants/xml-action-constant.js';
import { XML_DEVICE_LIST, XML_DEVICE_COMBO, EMPTY_DEVICE_ADDRESS } from '../constants/xml-device-constant.js';

function getDeviceActionByDeviceType(type) {
  switch (type) {
    case XML_DEVICE_LIST.REFRIG:
    case XML_DEVICE_LIST.SUCTION:
      return XML_ACTION.READ_DEVICES;
    case XML_DEVICE_LIST.HVAC:
      return XML_ACTION.READ_HVAC;
    case XML_DEVICE_LIST.LIGHTS:
      return XML_ACTION.READ_LIGHTING;
    case XML_DEVICE_LIST.MISC:
    case XML_DEVICE_LIST.OVERRIDES:
    case XML_DEVICE_LIST.FANS:
    case XML_DEVICE_LIST.LEAK:
    case XML_DEVICE_LIST.ALARMS:
    case XML_DEVICE_LIST.METER:
      return XML_ACTION.READ_MISC;
    case XML_DEVICE_LIST.SCHED:
      return XML_ACTION.READ_SCHEDULE;
    case XML_DEVICE_LIST.CALC:
    case XML_DEVICE_LIST.VLT:
    case XML_DEVICE_LIST.DRIVE:
      return XML_ACTION.READ_OTHERS;
    case XML_DEVICE_LIST.OI:
      return XML_ACTION.READ_POINTS_OI;
    case XML_DEVICE_LIST.RO:
      return XML_ACTION.READ_POINTS_RO;
    case XML_DEVICE_LIST.SI:
      return XML_ACTION.READ_POINTS_SI;
    case XML_DEVICE_LIST.VO:
      return XML_ACTION.READ_POINTS_VO;
    case XML_DEVICE_LIST.T_EVAP:
      return XML_ACTION.READ_DEVICE_TYPE_EVAP;
    case XML_DEVICE_LIST.T_RACK:
      return XML_ACTION.READ_DEVICE_TYPE_RACK;
    case XML_DEVICE_LIST.T_HVAC:
      return XML_ACTION.READ_DEVICE_TYPE_HVAC;
    case XML_DEVICE_LIST.T_METER:
      return XML_ACTION.READ_DEVICE_TYPE_METER;
    case XML_DEVICE_LIST.T_DRIVE:
      return XML_ACTION.READ_DEVICE_TYPE_DRIVE;
    case XML_DEVICE_LIST.T_FANS:
      return XML_ACTION.READ_DEVICE_TYPE_ECFAN;
    case XML_DEVICE_LIST.T_LIGHT:
      return XML_ACTION.READ_DEVICE_TYPE_LIGHT;
    case XML_DEVICE_LIST.T_LEAK:
      return XML_ACTION.READ_DEVICE_TYPE_LEAK;
    case XML_DEVICE_LIST.T_CORE:
      return XML_ACTION.READ_DEVICE_TYPE_CSMD;
    default:
      return XML_ACTION.READ_DEVICES;
  }
}
function getDeviceListByDeviceCombo(combo) {
  switch (combo) {
    case XML_DEVICE_COMBO.COMBO_COND:
    case XML_DEVICE_COMBO.COMBO_RACK:
    case XML_DEVICE_COMBO.COMBO_RACKONLY:
    case XML_DEVICE_COMBO.COMBO_SUCTION:
    case XML_DEVICE_COMBO.COMBO_SUCTALL:
    case XML_DEVICE_COMBO.COMBO_MULTI_SUCTION:
    case XML_DEVICE_COMBO.COMBO_CIRCUIT:
    case XML_DEVICE_COMBO.COMBO_EVAPS:
    case XML_DEVICE_COMBO.COMBO_DRIVES:
      return XML_DEVICE_LIST.REFRIG;
    case XML_DEVICE_COMBO.COMBO_CORESENSE:
      return XML_DEVICE_LIST.CORESENSE;
    case XML_DEVICE_COMBO.COMBO_HVAC:
      return XML_DEVICE_LIST.HVAC;
    case XML_DEVICE_COMBO.COMBO_LIGHTS:
    case XML_DEVICE_COMBO.COMBO_MCX:
      return XML_DEVICE_LIST.LIGHTS;
    case XML_DEVICE_COMBO.COMBO_PANELS:
      return XML_DEVICE_LIST.PANEL;
    case XML_DEVICE_COMBO.COMBO_SCHEDS:
      return XML_DEVICE_LIST.SCHED;
    case XML_DEVICE_COMBO.COMBO_ENERGY:
      return XML_DEVICE_LIST.METER;
    case XML_DEVICE_COMBO.COMBO_VLT:
      return XML_DEVICE_LIST.VLT;
    case XML_DEVICE_COMBO.COMBO_GENERIC:
      return XML_DEVICE_LIST.GENERIC;
    case XML_DEVICE_COMBO.COMBO_LEAK:
      return XML_DEVICE_LIST.LEAK;
    case XML_DEVICE_COMBO.COMBO_OVERRIDES:
      return XML_DEVICE_LIST.OVERRIDES;
    case XML_DEVICE_COMBO.COMBO_FANS:
      return XML_DEVICE_LIST.FANS;
    case XML_DEVICE_COMBO.COMBO_CALCS:
      return XML_DEVICE_LIST.CALC;
    case XML_DEVICE_COMBO.COMBO_MIS_RO:
    case XML_DEVICE_COMBO.COMBO_MIS_SI:
    case XML_DEVICE_COMBO.COMBO_MIS_OI:
    case XML_DEVICE_COMBO.COMBO_MIS_VO:
    case XML_DEVICE_COMBO.COMBO_IO_TYPES:
    case XML_DEVICE_COMBO.COMBO_MISC:
      return XML_DEVICE_LIST.MISC;
    case XML_DEVICE_COMBO.COMBO_OI:
      return XML_DEVICE_LIST.OI;
    case XML_DEVICE_COMBO.COMBO_RO:
      return XML_DEVICE_LIST.RO;
    case XML_DEVICE_COMBO.COMBO_SI:
      return XML_DEVICE_LIST.SI;
    case XML_DEVICE_COMBO.COMBO_VO:
      return XML_DEVICE_LIST.VO;
    default:
      return XML_DEVICE_LIST.REFRIG;
  }
}
function checkUnitForMiscDevices(type, unit, idxMisc) {
  if (idxMisc === void 0) {
    idxMisc = 0;
  }
  const maxMiscItems = 9;
  // 1 = energy
  // 2 = rail heat
  // 3 = misc ro
  // 4 = misc si
  // 5 = misc oi
  // 6 = misc vo
  // 7 = leak detectors
  // 8 = Mangager's Overrides
  // 9 = fans

  if (type === XML_DEVICE_LIST.METER) {
    if (idxMisc === 1) {
      idxMisc = maxMiscItems + 1; // all done
    } else if (parseInt(unit.meters, 10) > 0) {
      idxMisc = 1;
    } else {
      idxMisc = maxMiscItems + 1; // all done
    }
  } else {
    switch (idxMisc) {
      case 1:
        // was energy
        if (parseInt(unit.rail, 10) > 0) {
          // now check rail
          idxMisc = 2;
        }
        break;
      case 2:
        // was rail heat
        if (parseInt(unit.miscro, 10) > 0) {
          // now check misc RO
          idxMisc = 3;
        }
        break;
      case 3:
        // misc ro
        if (parseInt(unit.miscsi, 10) > 0) {
          idxMisc = 4;
        }
        break;
      case 4:
        // misc si
        if (parseInt(unit.miscoi, 10) > 0) {
          idxMisc = 5;
        }
        break;
      case 5:
        // misc oi
        if (parseInt(unit.miscvo, 10) > 0) {
          idxMisc = 6;
        }
        break;
      case 6:
        // misc vo
        if (parseInt(unit.leaks, 10) > 0) {
          idxMisc = 7;
        }
        break;
      case 7:
        // misc leak
        if (parseInt(unit.ovrs, 10) > 0) {
          idxMisc = 8;
        }
        break;
      case 8:
        // manager's override
        if (parseInt(unit.ecfans, 10) > 0) {
          idxMisc = 9;
        }
        break;
      case 9:
        //
        idxMisc = maxMiscItems + 1; // all done
        break;
    }
  }
  return idxMisc; // no devices available in this unit
}
const isAddressConfigured = _ref => {
  let {
    addr
  } = _ref;
  return addr !== EMPTY_DEVICE_ADDRESS;
};
function getValidDeviceData(data) {
  if (!(data !== null && data !== void 0 && data.length)) return [];
  return data.filter(isAddressConfigured);
}

export { checkUnitForMiscDevices, getDeviceActionByDeviceType, getDeviceListByDeviceCombo, getValidDeviceData };
