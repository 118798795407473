import { DEVICE_TYPE, DiscoveryTableData } from '../types/DiscoveryPage.types';

export const getSuctionGroupDevices = (
  discoveredDevices: DiscoveryTableData[],
) => {
  const packDevices = discoveredDevices.filter(
    device =>
      device.deviceType === DEVICE_TYPE.GEN_PACK ||
      device.deviceType === DEVICE_TYPE.RACK ||
      device.deviceType === DEVICE_TYPE.NO_COMP,
  );

  const caseDevices = discoveredDevices.filter(
    device =>
      device.deviceType === DEVICE_TYPE.CIRCUIT ||
      device.deviceType === DEVICE_TYPE.GEN_CIRCUIT,
  );

  const suctionDevices = discoveredDevices.filter(
    device =>
      device.deviceType === DEVICE_TYPE.RACKSUCT ||
      device.deviceType === DEVICE_TYPE.GEN_SUCT ||
      device.deviceType === DEVICE_TYPE.NO_COMP_SUCT,
  );

  return {
    packDevices,
    suctionDevices,
    caseDevices,
  };
};
