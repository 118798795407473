import { ConfigurationListItem, User } from '@danfoss/etui-sm-xml';

export function checkPasswordUpdatedForCurrentUser(
  listItem: ConfigurationListItem,
  dataList: ConfigurationListItem[],
  value: string,
  user: User,
): boolean {
  let isPasswordUpdatedForCurrentUser = false;
  const passwordFieldUpdated = checkPasswordFieldUpdated(listItem);
  if (passwordFieldUpdated) {
    const passwordUpdatedUserObj = findPasswordUpdatedUserObj(
      dataList,
      listItem,
    );
    isPasswordUpdatedForCurrentUser = validatePasswordUpdatedForCurrentUser(
      passwordUpdatedUserObj,
      user,
      value,
    );
  }
  return isPasswordUpdatedForCurrentUser;
}

function checkPasswordFieldUpdated(listItem: ConfigurationListItem) {
  const PASSWORD_PNUM = '4902';
  return (
    listItem?.token?.toLowerCase().includes('password') ||
    listItem?.pnum === PASSWORD_PNUM
  );
}

function findPasswordUpdatedUserObj(
  dataList: ConfigurationListItem[],
  listItem: ConfigurationListItem,
) {
  const updatedPasswordFieldIndex = dataList?.findIndex(
    data => data?.li === listItem?.li,
  );
  return dataList[updatedPasswordFieldIndex - 1];
}

function validatePasswordUpdatedForCurrentUser(
  passwordUpdatedUserObj: ConfigurationListItem,
  user: User,
  value: string,
) {
  return (
    passwordUpdatedUserObj?.value?.trim() === user?.user?.trim() &&
    value !== user?.password
  );
}
