import moment from 'moment';
import { RefrigerationSchedules, TimeConstant } from '../SchedulesPage.types';
import { DAYS } from './getScheduleDayFromLetter';

// min gap between end point of a schedule and its start when it's devided by weeks
const MINS_IN_4_DAYS = 5760;

export const convertTimeFormats = (
  fromTimeFormat: string,
  toTimeFormat: string,
  time: string,
): string => {
  return moment(time, fromTimeFormat).format(toTimeFormat);
};

export const updateOffTime = (
  time: string,
  maxTime: string,
  timeFormat: string,
): string => {
  return checkIfTimeSameOrAfter(time, maxTime)
    ? moment(time, 'h:mma').minutes(TimeConstant.FIFTY_NINE).format(timeFormat)
    : null;
};

export const getTime = time =>
  `${time.hour}:${time.minute} ${time.units || ''}`;

const getNextDayIndex = (index: number) => (index > 6 ? 0 : index);
const getPrevDayIndex = (index: number) => (index < 0 ? 6 : index);

export const isNextDayConfigured = (
  activeDay: string,
  storeDay,
  daysOfTheWeek: string[],
) => {
  const daysConfigured: string = storeDay.days || storeDay.weekdays;
  const activeDayIndex = daysOfTheWeek.indexOf(activeDay);
  const nextDayIndex = getNextDayIndex(activeDayIndex + 1);
  const nextDay = DAYS[nextDayIndex];
  return daysConfigured.includes(nextDay);
};

export const isPrevDayConfigured = (
  activeDay: string,
  storeDay,
  daysOfTheWeek: string[],
) => {
  const daysConfigured: string = storeDay.days || storeDay.weekdays;
  const activeDayIndex = daysOfTheWeek.indexOf(activeDay);
  const nextDayIndex = getPrevDayIndex(activeDayIndex - 1);
  const nextDay = DAYS[nextDayIndex];
  return daysConfigured.includes(nextDay);
};

export const isOpenHoursLaterThanCloseHours = (
  openHours: string,
  closeHours: string,
) => moment(closeHours, 'h:mma') <= moment(openHours, 'h:mma');

export const updateScheduleOffTime = (
  activeDay: string,
  storeDay: RefrigerationSchedules,
  timeFormat: string,
  daysOfTheWeek: string[],
  isRelativeMode: boolean = false,
): string | void => {
  const { on_time, off_time } = storeDay || {};
  if (
    !isNextDayConfigured(activeDay, storeDay, daysOfTheWeek) &&
    isOpenHoursLaterThanCloseHours(on_time, off_time)
  ) {
    return moment(off_time, 'h:mma')
      .hours(isRelativeMode ? 0 : TimeConstant.TWENTY_THREE)
      .minutes(isRelativeMode ? 0 : TimeConstant.FIFTY_NINE)
      .format(timeFormat);
  }
};

const isMidnight = (time: string): boolean =>
  [TimeConstant.MIDNIGHT_24, TimeConstant.MIDNIGHT_12].includes(
    time.trim() as TimeConstant,
  );

export const updateStoreHoursOffTime = (on_time, off_time, timeFormat) => {
  return isMidnight(off_time) || checkIfOffTimeGoesToNextDay(on_time, off_time)
    ? restrictOffTimeToSameDay(off_time, timeFormat)
    : null;
};

export const restrictOffTimeToSameDay = (offTime: Date, timeFormat: string) => {
  return moment(offTime, 'h:mma')
    .hours(TimeConstant.NON_DEFROST_MAX_HOUR)
    .minutes(TimeConstant.FIFTY_NINE)
    .format(timeFormat);
};

// edge case when store hours are from 0:00 am till 0:00 am and relative time is set from -23:59 (start time) to +23:59 (end time)
// needed to draw schedule representation correctly when sched is devided by end and start of the week
export const isDiffMoreThan4Days = (
  startDate: Date,
  endDate: Date,
): boolean => {
  const start = moment(startDate);
  const end = moment(endDate);
  return Math.abs(end.diff(start, 'minutes')) > MINS_IN_4_DAYS;
};

export const getOffTimeDayIndex = (
  activeDay: string,
  storeDay: RefrigerationSchedules,
  onTimeDayIndex: number,
  daysOfTheWeek: string[],
) =>
  isOpenHoursLaterThanCloseHours(storeDay?.on_time, storeDay?.off_time) &&
  isNextDayConfigured(activeDay, storeDay, daysOfTheWeek)
    ? onTimeDayIndex + 1 > 6
      ? 0
      : onTimeDayIndex + 1
    : onTimeDayIndex;

const checkIfOffTimeGoesToNextDay = (
  onTime: string,
  offTime: string,
): boolean => {
  const onScheduledTime = moment(onTime, 'h:mma');
  const offScheduledTime = moment(offTime, 'h:mma');
  const hourDifference = offScheduledTime.diff(onScheduledTime, 'hours');
  return hourDifference < 0;
};

export const checkIfTimeSameOrAfter = (
  time: string,
  maxTime: string,
): boolean => {
  const scheduleTime = moment(time, 'h:mma');
  const endTime = moment(maxTime, 'h:mma');
  return scheduleTime.isSame(endTime) || scheduleTime.isAfter(endTime);
};

export const addTime = (
  onTime: string,
  timeFormat: string,
  minutes: number,
) => {
  return moment.utc(onTime, 'h:mma').add(minutes, 'minutes').format(timeFormat);
};

export const getStartOfTheDay = (date: Date): Date =>
  moment(date).startOf('day').toDate();

export const getEndOfTheDay = (date: Date): Date =>
  moment(date).endOf('day').toDate();
