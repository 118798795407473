import React from 'react';
import { icons } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  useUnit,
  SOFTWARE_VERSION_PREFIX,
  getIsMatchedSoftwareVersion,
  useModal,
} from '@danfoss/etui-sm';
import { Unit } from '@danfoss/etui-sm-xml';
import { QUICKSETUP_SUBMENU } from '../Configuration';
import { QuickSetupLayout } from './QuickSetupLayout';
import { HVACLayoutModal } from './modules/HVACModules/HVACLayoutModal';
import { RefrigLayoutModal, RefrigCopyModal } from './modules';

type UnitConfigurationBaseQuickSetupProps = {
  unit: Unit;
};
function UnitConfigurationBaseQuickSetup({
  unit,
}: UnitConfigurationBaseQuickSetupProps) {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { units } = useUnit();
  const route = '/configuration';
  const [openRefrigationLayoutModal] = useModal(RefrigLayoutModal);
  const [openRefrigationCopyModal] = useModal(RefrigCopyModal, {
    unit,
  });
  const [openHVACLayoutModal] = useModal(HVACLayoutModal);
  const [openHVACCopyModal] = useModal(RefrigCopyModal, {
    unit,
    isHvac: true,
  });

  const dynamicUserUrlParam = React.useMemo(
    () =>
      getIsMatchedSoftwareVersion(units, [SOFTWARE_VERSION_PREFIX.G08])
        ? '-2'
        : '-1',
    [units],
  );

  const dynamicLicenseUrlParam = React.useMemo(
    () =>
      getIsMatchedSoftwareVersion(units, [SOFTWARE_VERSION_PREFIX.G08])
        ? '-3'
        : '-2',
    [units],
  );

  const redirectToPreferences = () => {
    push(route + QUICKSETUP_SUBMENU.SYSTEM_PREFERENCES);
  };

  const redirectToUsers = () => {
    push(route + QUICKSETUP_SUBMENU.SYSTEM_USERS + dynamicUserUrlParam);
  };

  const redirectToLicensing = () => {
    push(route + QUICKSETUP_SUBMENU.SYSTEM_LICENSING + dynamicLicenseUrlParam);
  };

  return (
    <QuickSetupLayout
      tilesSystemSetup={[
        {
          text: t('t372'),
          icon: icons.USER,
          action: redirectToPreferences,
        },
        {
          text: t('t504'),
          icon: icons.USERS,
          action: redirectToUsers,
        },
        {
          text: t('t503'),
          icon: icons.KEY,
          action: redirectToLicensing,
        },
      ]}
      tilesRerigaration={[
        {
          text: t('t501'),
          icon: icons.REFRIG_LAYOUT,
          action: openRefrigationLayoutModal,
        },
        {
          text: t('t486'),
          icon: icons.REFRIG_COPY,
          action: openRefrigationCopyModal,
        },
      ]}
      tilesHVAC={[
        {
          text: t('t501'),
          icon: icons.HVAC_LAYOUT,
          action: openHVACLayoutModal,
        },
        {
          text: t('t486'),
          icon: icons.HVAC_COPY,
          action: openHVACCopyModal,
        },
      ]}
    />
  );
}

export { UnitConfigurationBaseQuickSetup };
