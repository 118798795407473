import { styled, css } from '@danfoss/etui-system';
import { Button, Span, SpanProps } from '@danfoss/etui-system-elements';
import { danfoss } from '@danfoss/etui-colors';

export const PreWrapper = styled(Span)`
  pre {
    font-weight: 400;
    color: ${danfoss.blueGrey[100]};
    margin: 0;
  }
`;
interface FloatModalButtonProps extends SpanProps {
  isSmView?: boolean;
}

export const FloatingModalButton = styled(Span).attrs(({ ...props }) => ({
  position: 'relative',
  ...props,
}))<FloatModalButtonProps>`
  ${Button} {
    ${({ isSmView }: FloatModalButtonProps) =>
      !isSmView &&
      css`
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      `};
  }
`;
