import { ScheduleTreeItem, ScheduleTypes } from '../SchedulesPage.types';

export const getScheduleTypeFromScheduleTreeItem = (item: ScheduleTreeItem) => {
  const scheduleType = item.id.includes(ScheduleTypes.STORE_HOURS)
    ? ScheduleTypes.STORE_HOURS
    : item.id.includes(ScheduleTypes.ZONE)
    ? ScheduleTypes.LIGHTING
    : item.id.includes(ScheduleTypes.HVAC)
    ? ScheduleTypes.HVAC
    : item.id.includes(ScheduleTypes.DEFROST)
    ? ScheduleTypes.REF_DEFROST
    : ScheduleTypes.REF_NON_DEFROST;
  return scheduleType;
};
