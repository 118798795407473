import * as React from 'react';
import useSWR from 'swr';
import { useResponsive } from '@danfoss/etui-core';
import { ContentError } from '@danfoss/etui-sm';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from 'hooks/featurehub/FeatureHubContext';
import {
  ConfigurationAuthProvider,
  DeviceConfigurationWidget,
  getUnitByDevice,
} from '../Configuration';
import { fetchDeviceConfigurationByUnit } from './actions';
import { DeviceConfigurationBaseProps } from './DeviceConfiguration.types';

function DeviceConfigurationBase({
  xmlBackendUrl,
  device,
  units,
  user,
}: DeviceConfigurationBaseProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const unit = getUnitByDevice(units, device);
  const DeviceConfigurationWrite = useFeatureFlag('DeviceConfigurationWrite');
  const { screenIsAtMost } = useResponsive({
    lg: parseInt(theme.breakpoints[2], 10),
  });
  const isLgView = screenIsAtMost('lg');

  const {
    data: deviceTabs,
    error: deviceTabsError,
    mutate,
  } = useSWR(
    () => (unit ? [xmlBackendUrl, unit, device, user] : null),
    fetchDeviceConfigurationByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );
  return deviceTabsError ? (
    <ContentError
      title={t('t17')}
      message={t('t66')}
      onRetryClick={() => mutate()}
    />
  ) : !deviceTabs?.length ? null : (
    <ConfigurationAuthProvider
      access={{ writeAccess: DeviceConfigurationWrite }}
    >
      <Div display="flex" justifyContent="space-between" mt="1px" height="100%">
        {isLgView ? (
          <Div width="100%">
            <DeviceConfigurationWidget
              xmlBackendUrl={xmlBackendUrl}
              device={device}
              user={user}
              unit={unit}
              tabs={deviceTabs}
            />
          </Div>
        ) : (
          <>
            <Div
              width="50%"
              borderStyle="solid"
              borderWidth="1px"
              borderColor={theme.palette.divider}
            >
              <DeviceConfigurationWidget
                xmlBackendUrl={xmlBackendUrl}
                device={device}
                user={user}
                unit={unit}
                tabs={deviceTabs.slice(0, 1)}
              />
            </Div>
            {deviceTabs.length > 1 ? (
              <Div
                width="50%"
                borderStyle="solid"
                borderWidth="1px"
                borderColor={theme.palette.divider}
              >
                <DeviceConfigurationWidget
                  xmlBackendUrl={xmlBackendUrl}
                  device={device}
                  user={user}
                  unit={unit}
                  tabs={deviceTabs.slice(1)}
                />
              </Div>
            ) : null}
          </>
        )}
      </Div>
    </ConfigurationAuthProvider>
  );
}

export { DeviceConfigurationBase };
