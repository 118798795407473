import React from 'react';
import { Icon, IconGlyph, Tooltip } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { DeviceFlowStatusIconToolTipMessage } from './DeviceFlowStatusCntIconToolTipMessage';

interface DeviceFlowStatusCntIconProps {
  colorTheme: string;
  iconGlyph: IconGlyph;
  message: string;
  isLastRow: boolean;
}
export const DeviceFlowStatusCntIcon = ({
  colorTheme,
  iconGlyph,
  message,
  isLastRow,
}: DeviceFlowStatusCntIconProps) => {
  return (
    <Div testId="device-status-tooltip-div">
      <Tooltip
        message={() => <DeviceFlowStatusIconToolTipMessage message={message} />}
        placement={isLastRow ? 'right' : 'bottom'}
        warning={!isLastRow}
        warningWidth="75%"
      >
        <Div testId="device-status-icon-div">
          <Icon
            size={24}
            styles={{
              root: {
                color: colorTheme,
              },
            }}
            glyph={iconGlyph}
          />
        </Div>
      </Tooltip>
    </Div>
  );
};
