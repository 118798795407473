import {
  CONFIGURATION_REFRESH_INTERVAL,
  PATHNAMES,
} from '@danfoss/etui-sm-xml';

const TABLE_IDS = {
  TIME: '20091-1',
  CALCULATIONS: '20105-1',
  POINTS: '20079-1',
  SENSORS: '20071-1',
};

export function getTableDataRefreshInterval(pathname, isSm800A?: boolean) {
  return pathname.endsWith(TABLE_IDS.TIME)
    ? CONFIGURATION_REFRESH_INTERVAL.SINGLE_PAGE
    : pathname.endsWith(TABLE_IDS.CALCULATIONS) ||
      pathname.endsWith(`${TABLE_IDS.POINTS}/${TABLE_IDS.SENSORS}`)
    ? 0 // disable useSWR refreshInterval
    : pathname.includes(PATHNAMES.EQUIPMENT)
    ? isSm800A
      ? CONFIGURATION_REFRESH_INTERVAL.SM800A_EQUIPMENT_PAGE
      : CONFIGURATION_REFRESH_INTERVAL.SM800_EQUIPMENT_PAGE
    : CONFIGURATION_REFRESH_INTERVAL.DEFAULT;
}
