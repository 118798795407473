import { styled, keyframes, createElement } from '@danfoss/etui-system';
import {} from '@danfoss/etui-system-elements';
import React from 'react';

const LoadingTextDivElement = createElement('div');

const width = '2.0em';

const ellipsis = keyframes`
  to {
    width: ${width};    
  }
`;

const LoadingTextDiv = styled(LoadingTextDivElement)`
  &:after {
    height: 2em;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ${ellipsis} steps(8, end) 1200ms infinite;
    animation: ${ellipsis} steps(8, end) 1200ms infinite;
    content: ' .....';
    width: 0px;
  }
`;

const LoadingTextDivWrapper = styled(createElement('div'))`
  width: ${width};
`;

export const LoadingEllipsisDiv = () => {
  return (
    <LoadingTextDivWrapper>
      <LoadingTextDiv />
    </LoadingTextDivWrapper>
  );
};
