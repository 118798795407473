import { AkioDefrostSchedules, Refrigerations } from '../SchedulesPage.types';

export const filterPackLevelAkioDefrostSchedules = (
  refrigerations: Refrigerations[],
  scheduleType: string,
  scheduleIdsPack: string,
): AkioDefrostSchedules[] => {
  return refrigerations
    ?.find(refrigeration => refrigeration?.id === scheduleType)
    ?.items?.find(
      refSchItem => refSchItem?.id === `${scheduleType}-${scheduleIdsPack}`,
    )
    ?.items?.find(schItem => schItem?.akioDefrostSchedules)
    ?.akioDefrostSchedules;
};
