export const createSequenceFromNumber = (value: number) => {
  if (!value) return 0;

  const arr = [];
  let i = 0;
  while (i !== value) {
    arr.push(`${i++}`);
  }

  return arr;
};
