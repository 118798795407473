import { TimeConstant } from 'pages/SchedulesPage/SchedulesPage.types';

export enum AmPm {
  AM = 'AM',
  PM = 'PM',
}
export enum Sign {
  Plus = '+',
  Minus = '-',
}

interface InputProps {
  newInputCaret: number;
  newInputValue?: string;
  initialValue: string;
  newInput: number;
}

// tweaked from https://stackoverflow.com/a/34402261/5531166
// ^                # Start of the string
//  (?:             # NCG1
//    (?:           # NCG2
//      0\d         # A zero followed by a single number between zero and nine
//      |           # OR
//      1\d         # A literal '1' followed by a single number between zero and nine
//      |           # OR
//      2[0-3]      # A literal '2' followed by a single number between zero and three
//     )            # CLOSE NCG2
//     :            # A literal colon ':'
//     [0-5]\d      # A single number from 0 to 5 followed by any digit
//   )              # CLOSE NCG1
// $                # End of the string
export const timeRegex24 = /^(?:(?:0?\d|1\d|2[0-3]):[0-5]\d)$/;
// ^                # Start of the string
//  (?:             # NCG1
//    (?:           # NCG2
//      1[0-2]|0\d  # Possible values 10, 11, 12 or from 00 till 09
//     )            # CLOSE NCG2
//     :            # A literal colon ':'
//     [0-5]\d      # A single number from 0 to 5 followed by any digit
//   )              # CLOSE NCG1
// $                # End of the string
export const timeRegex12 = /^(?:(?:1[0-2]|0\d):[0-5]\d)$/;

const getHoursAndMinutes = (time: string): string[] => time.split(':');

const replaceChar = (
  timeString: string,
  charNum: number,
  value: number | string,
  qty: number = 1,
) => {
  const firstPart = timeString.slice(0, charNum);
  const secondPart = timeString.slice(charNum + qty);
  return `${firstPart}${value}${secondPart}`;
};

export function handle12Edit({
  newInputCaret,
  newInput,
  initialValue,
}: InputProps) {
  let newTime = '';
  const prevCaret = newInputCaret - 1;
  if (newInputCaret === 1) {
    if (newInput > 1) {
      const hours = `0${newInput}`;
      newTime = replaceChar(initialValue, 0, hours, 2);
      newInputCaret = 3;
    } else {
      const hours = '10';
      newTime =
        +initialValue[newInputCaret] > 2
          ? replaceChar(initialValue, 0, hours, 2)
          : replaceChar(initialValue, prevCaret, newInput);
    }
  } else if (newInputCaret === 3) {
    newTime = replaceChar(initialValue, newInputCaret, newInput);
    newInputCaret++;
  } else {
    newTime = replaceChar(initialValue, prevCaret, newInput);
  }

  const [hours] = getHoursAndMinutes(newTime);
  +hours < 13 && newInputCaret === 2 && newInputCaret++;

  return [newTime, newInputCaret];
}

export const handle12Delete = ({
  newInputCaret,
  newInputValue,
  initialValue,
  newInput,
}: InputProps): [string, number] => {
  if (newInputCaret === 2) return [initialValue, newInputCaret];

  const trimmedInputValue = newInputValue.trim();
  if (!trimmedInputValue) return [TimeConstant.MIDNIGHT_24 as string, 0];

  let newTime;
  const prevCaret = newInputCaret - 1;

  if (isNaN(newInput) && trimmedInputValue.length === 3) {
    const hasSpace = newInputValue !== trimmedInputValue;
    newTime = replaceChar(
      initialValue,
      hasSpace ? prevCaret : newInputCaret,
      '00',
      2,
    );

    return [newTime, hasSpace ? newInputCaret + 1 : newInputCaret];
  }

  if (isNaN(newInput) && newInputCaret !== 2) {
    newTime = replaceChar(initialValue, newInputCaret, 0);
    newInputCaret === 3 && newInputCaret--;

    return [newTime, newInputCaret];
  }

  if (newInputCaret === 1) {
    const isBiggerThen1 = newInput > 1;
    const isBiggerThen12 = +initialValue[1] > 2;
    const hours = isBiggerThen1
      ? `0${newInput}`
      : isBiggerThen12
      ? `${newInput}0`
      : `${newInput}${initialValue[1]}`;

    newTime = replaceChar(initialValue, 0, hours, 2);
    if (isBiggerThen1) return [newTime, 3];
  } else {
    newTime = replaceChar(initialValue, prevCaret, newInput);
  }

  return [newTime, newInputCaret];
};

export function handle24Edit({
  newInputCaret,
  newInput,
  initialValue,
}: InputProps) {
  let newTime = '';
  const prevCaret = newInputCaret - 1;
  if (newInputCaret === 1) {
    if (newInput > 2) {
      const hours = `0${newInput}`;
      newTime = replaceChar(initialValue, 0, hours, 2);
      newInputCaret = 3;
    } else {
      const hours = `${newInput}0`;
      newTime =
        +initialValue[newInputCaret] > 4
          ? replaceChar(initialValue, 0, hours, 2)
          : replaceChar(initialValue, prevCaret, newInput);
    }
  } else if (newInputCaret === 3) {
    newTime = replaceChar(initialValue, newInputCaret, newInput);
    newInputCaret++;
  } else {
    newTime = replaceChar(initialValue, prevCaret, newInput);
  }

  const [hours] = getHoursAndMinutes(newTime);
  +hours < 24 && newInputCaret === 2 && newInputCaret++;

  return [newTime, newInputCaret];
}

export const handle24Delete = ({
  newInputCaret,
  newInputValue,
  initialValue,
  newInput,
}: InputProps): [string | TimeConstant, number] => {
  if (newInputCaret === 2) return [initialValue, newInputCaret];

  const trimmedInputValue = newInputValue.trim();
  if (!trimmedInputValue) return [TimeConstant.MIDNIGHT_24, 0];

  let newTime;
  const prevCaret = newInputCaret - 1;

  if (isNaN(newInput) && trimmedInputValue.length === 3) {
    const hasSpace = newInputValue !== trimmedInputValue;
    newTime = replaceChar(
      initialValue,
      hasSpace ? prevCaret : newInputCaret,
      '00',
      2,
    );

    return [newTime, hasSpace ? newInputCaret + 1 : newInputCaret];
  }

  if (isNaN(newInput) && newInputCaret !== 2) {
    newTime = replaceChar(initialValue, newInputCaret, 0);
    newInputCaret === 3 && newInputCaret--;

    return [newTime, newInputCaret];
  }

  if (newInputCaret === 1) {
    const isBiggerThen2 = newInput > 2;
    const isBiggerThen23 = +initialValue[1] > 3;
    const hours = isBiggerThen2
      ? `0${newInput}`
      : isBiggerThen23
      ? `${newInput}0`
      : `${newInput}${initialValue[1]}`;

    newTime = replaceChar(initialValue, 0, hours, 2);
    if (isBiggerThen2) return [newTime, 3];
  } else {
    newTime = replaceChar(initialValue, prevCaret, newInput);
  }

  return [newTime, newInputCaret];
};

export function get24Hour(hour: string, amPm: AmPm): string {
  const hourInt = parseInt(hour, 10);
  if (amPm === AmPm.AM) {
    return hourInt === 12 ? '00' : hour;
  }
  if (amPm === AmPm.PM) {
    return hourInt === 12 ? hour : `${hourInt + 12}`;
  }
  return hour;
}

export function from12ToSaveValue(s: string, amPm: AmPm) {
  const [hours, minutes] = s.split(':');
  let hoursInt = Number(hours);
  if (amPm === AmPm.PM) {
    hoursInt = hoursInt === 12 ? 12 : hoursInt + 12;
  }
  if (amPm === AmPm.AM) {
    hoursInt = hoursInt === 12 ? 0 : hoursInt;
  }
  return `${hoursInt.toString().padStart(2, '0')}:${minutes}`;
}

export function formatSaveValueTo12(s: string) {
  const [hours, minutes] = s.split(':').map(Number);
  if (hours < 12) {
    return `${hours === 0 ? 12 : hours}:${minutes} ${AmPm.AM}`;
  }

  return `${hours === 12 ? hours : hours - 12}:${minutes} ${AmPm.PM}`;
}

export function timeToMs(time: string) {
  const [hours, minutes] = time.split(':').map(Number);
  return (hours * 3600 + minutes * 60) * 1e3;
}
