import * as React from 'react';
import { Form, SelectInput, SelectInputOption } from '@danfoss/etui-core';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from '@danfoss/etui-system';
import {
  ConfigurationListDataArray,
  ConfigurationListItem,
  REGEX_PATTERNS,
} from '@danfoss/etui-sm-xml';
import { Span } from '@danfoss/etui-system-elements';
import { getArray } from '../../utils';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { useKeyboardEvent } from './hooks/useKeyboardEvent';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { checkIfOtherItemClicked, FORM_TEST_ID_PREFIX } from './utils';

function getInitialValue(
  item: ConfigurationListItem,
  valueKey: string,
  options: SelectInputOption[],
  isFilelist: boolean,
  isCopyControllerSelected?: boolean,
  isWizards?: boolean,
) {
  if (!isCopyControllerSelected && isWizards) {
    return {
      label: options[0]?.label,
      value: options[0]?.value,
    };
  }
  const initialValue = options.find(({ value }) => value === item[valueKey]);
  const trimmedValue = item.value ? item.value.trim() : '';
  if (isFilelist || initialValue?.label === trimmedValue) return initialValue;

  // Edge case when the valueKey (iVal or iti) is out of range, then fallback to use value.
  // And find the initialValue by label
  const valueByLabel = options.find(({ label }) => label === trimmedValue);

  // One more edge case when value/iVal is not on the list of options
  return valueByLabel || { label: item.value, value: item[valueKey] };
}

const ConfigurationListItemEditSelect = React.memo(
  function ConfigurationListItemEditSelect({
    index,
    name,
    item,
    editMode,
    onSave,
    onCancel,
    onEnableEdit,
    isAuthorized,
    addonAnimatonDuration = 2000,
    isFilelist = false,
    isSearchable,
    isCopyControllerSelected,
    isCopyLoading,
    isWizards,
  }: BaseEditProps & {
    isFilelist: boolean;
    isSearchable: boolean;
    isCopyControllerSelected?: boolean;
    isCopyLoading?: boolean;
    isWizards?: boolean;
  }) {
    const node = React.useRef<HTMLDivElement>();
    const selectRef = React.useRef(null);
    const theme = useTheme();
    const [isSucceeded, setIsSucceeded] = React.useState(false);

    const {
      control,
      setValue,
      setError,
      getValues,
      errors,
      formState,
      handleSubmit,
      clearErrors,
    } = useForm<{ [key: string]: SelectInputOption }>();

    useKeyboardEvent('Escape', event => {
      handleOnCancelEdit(event);
    });

    const handleFocus = () => {
      onEnableEdit(name);
    };

    const options: SelectInputOption[] = getArray(
      item?.listdata as ConfigurationListDataArray,
    )?.map(({ _: label, datavalue, iti }) => ({
      value: datavalue || iti,
      label: label?.replace(REGEX_PATTERNS.SPACE, ' ').trim(),
    }));
    const valueProperty = item.iVal ? 'iVal' : 'iti';
    const isEditing = editMode.includes(name);
    const initialValue = getInitialValue(
      item,
      valueProperty,
      options,
      isFilelist,
      isCopyControllerSelected,
      isWizards,
    );
    const error = errors[name];
    const isFailed = !!error;

    const handleOnCancelEdit = (event, isOnBlur: boolean = false) => {
      if (selectRef.current.menuListRef === event.target) return;

      if (!isOnBlur && node.current.contains(event.target)) {
        // close dropdown when clicking on it for the 2nd time
        if (!event.target.id.includes('react-select')) {
          // To re-open select menu on click
          selectRef.current.blur();
          onCancel();
        }

        return;
      }

      const values = getValues();
      const nextValue = values[name];

      if (nextValue?.value !== initialValue.value) {
        console.log('Unsaved changes');
      } else {
        const otherConfigLineName = checkIfOtherItemClicked(event.target);
        onCancel(otherConfigLineName);
      }
    };

    const handleOnSubmit = async (data: {
      [key: string]: SelectInputOption;
    }) => {
      const { value, label } = data[name];
      try {
        await onSave(item, {
          [valueProperty.toLowerCase()]: value,
        });
        item[valueProperty] = value;
        item.value = label;
        setIsSucceeded(true);
      } catch (e) {
        setError(name, { type: 'server' });
      }
    };

    React.useEffect(() => {
      if (isEditing) {
        document.addEventListener('mousedown', handleOnCancelEdit);
      }
      return () => {
        document.removeEventListener('mousedown', handleOnCancelEdit);
      };
    }, [isEditing]);

    React.useEffect(() => {
      if (error) {
        setTimeout(() => {
          clearErrors(name);
          setValue(name, initialValue);
          onCancel();
        }, addonAnimatonDuration);
      }

      if (isSucceeded) {
        setTimeout(() => {
          setIsSucceeded(false);
          onCancel();
        }, addonAnimatonDuration);
      }
    }, [error, isSucceeded]);

    const { isSubmitting } = formState;

    return (
      <div key={index} ref={node}>
        {isAuthorized ? (
          <Form
            onSubmit={handleSubmit(handleOnSubmit)}
            testId={`${FORM_TEST_ID_PREFIX}${name}`}
          >
            <Controller
              control={control}
              name={name}
              defaultValue={initialValue}
              render={({ value }) => (
                <SelectInput
                  elRef={selectRef}
                  searchable={isSearchable}
                  name={name}
                  onChange={option => {
                    setValue(name, option as SelectInputOption);
                    handleSubmit(handleOnSubmit)();
                  }}
                  onFocus={handleFocus}
                  value={value}
                  options={options}
                  menuIsOpen={isEditing}
                  size="small"
                  disabled={
                    isSubmitting || isSucceeded || !!error || isCopyLoading
                  }
                  indicatorColor="#686868"
                  styles={{
                    label: error
                      ? { color: theme.palette.error.main }
                      : isSucceeded
                      ? { color: theme.palette.success.main }
                      : {},
                    input: error
                      ? { borderColor: theme.palette.error.main }
                      : isSucceeded
                      ? { borderColor: theme.palette.success.main }
                      : {},
                  }}
                  endInputAddon={
                    isSubmitting || isSucceeded || isFailed ? (
                      <ConfigurationListitemEditInputAddon
                        isSubmitting={isSubmitting}
                        isSucceeded={isSucceeded}
                        isFailed={isFailed}
                        styles={{
                          inputAddon: {
                            root: isSubmitting
                              ? {
                                  top: '9px',
                                  width: '24px',
                                  height: '24px',
                                  right: `${theme.spacing.xxs}px`,
                                }
                              : {
                                  top: '5px',
                                  width: '32px',
                                  height: '32px',
                                  right: `${theme.spacing.xxs}px`,
                                },
                          },
                        }}
                      />
                    ) : null
                  }
                />
              )}
            />
          </Form>
        ) : (
          <Span>{item.value}</Span>
        )}
      </div>
    );
  },
);

export { ConfigurationListItemEditSelect };
