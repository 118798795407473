import { dates, WeekCalendarEvent } from '@danfoss/etui-core';
import { CALENDAR_VIEW_FORMATS } from '@danfoss/etui-sm-xml';
import { scheduleDays } from '../SchedulesPage.types';
import { DAYS_IN_A_WEEK } from '../components/SchedulesWeekCalendarView/utils';
import { useStoreHours } from '../context';
import {
  convertTimeFormats,
  isDiffMoreThan4Days,
  restrictOffTimeToSameDay,
} from './convertTimeFormat';

const checkIfSunday = (day: WeekCalendarEvent): boolean => {
  const { storeWeekdays } = useStoreHours();
  return day.id === storeWeekdays[0];
};

const updateDayEndTime = (schedule: WeekCalendarEvent, timeFormat: string) => {
  const daysToSubtract = checkIfSunday(schedule) ? 1 : 2;
  const endDateDay = schedule.endDate.getDay() - daysToSubtract;
  const offTime = restrictOffTimeToSameDay(schedule.endDate, timeFormat);

  const convertedCloseTime = convertTimeFormats(
    CALENDAR_VIEW_FORMATS.FORMAT_12,
    CALENDAR_VIEW_FORMATS.FORMAT_24,
    offTime,
  ).split(':');

  schedule.endDate = dates.manipulateDate(
    endDateDay < 0 ? DAYS_IN_A_WEEK + endDateDay : endDateDay % DAYS_IN_A_WEEK,
    +convertedCloseTime[0],
    +convertedCloseTime[1],
  );
};

const restrictOffTime = (schedule, timeFormat, dayIndex) => {
  const endDateDay = dayIndex;
  const offTime = restrictOffTimeToSameDay(schedule.endDate, timeFormat);

  const convertedCloseTime = convertTimeFormats(
    CALENDAR_VIEW_FORMATS.FORMAT_12,
    CALENDAR_VIEW_FORMATS.FORMAT_24,
    offTime,
  ).split(':');

  schedule.endDate = dates.manipulateDate(
    endDateDay,
    +convertedCloseTime[0],
    +convertedCloseTime[1],
  );
};

export function getWeekUpdatedSchedules(
  schedules: WeekCalendarEvent[],
  storeWeekdays: string[],
  timeFormat: string,
): WeekCalendarEvent[] {
  const updatedSchedules: WeekCalendarEvent[] = [];
  schedules.forEach(schedule => {
    const endHours = schedule.endDate.getHours();
    const endMinutes = schedule.endDate.getMinutes();
    const endsAtMidnight = !endHours && !endMinutes;
    if (+schedule.startDate !== +schedule.endDate) {
      if (isDiffMoreThan4Days(schedule.startDate, schedule.endDate)) {
        const isSunday = checkIfSunday(schedule);
        const day = schedule[isSunday ? 'endDate' : 'startDate'].getDay();
        const otherWeekSchedule = {
          id: scheduleDays[day],
          innerId: schedule.innerId,
          startDate: isSunday
            ? dates.manipulateDate(day, 0, 0)
            : schedule.startDate,
          endDate: isSunday
            ? schedule.endDate
            : dates.manipulateDate(day, 23, 59),
          styles: schedule.styles,
          tooltipMessage: schedule.tooltipMessage,
          tooltipContent: schedule.tooltipContent,
          hideEventMarkers: true,
        };

        if (isSunday) {
          updateDayEndTime(schedule, timeFormat);
          !endsAtMidnight && updatedSchedules.push(otherWeekSchedule);
        } else {
          updatedSchedules.push(otherWeekSchedule);
          schedule.startDate = dates.manipulateDate(1, 0, 0);
        }
      }

      if (endsAtMidnight) {
        const dayIndex = storeWeekdays.indexOf(`${schedule.id}`);
        restrictOffTime(schedule, timeFormat, dayIndex);
      }
    }

    updatedSchedules.push(schedule);
  });

  return updatedSchedules;
}
