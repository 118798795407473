import { fetchXmlCsvFileData, User, XML_ACTION } from '@danfoss/etui-sm-xml';

export const fetchCurrentSoftware = async ([_key, user, url]: [
  string,
  User,
  string,
]): Promise<
  {
    software: string;
    version: string;
    rollbackversion: string;
  }[]
> => {
  const currentData = await fetchXmlCsvFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action: XML_ACTION.EXPORT_SW_VERSIONS,
    backup: 0,
  });
  const rollbackData = await fetchXmlCsvFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action: XML_ACTION.EXPORT_SW_VERSIONS,
    backup: 1,
  });
  return currentData.map(current => {
    const rollbackversion = rollbackData.find(
      rollback => rollback.software === current.software,
    );
    return {
      ...current,
      rollbackversion: rollbackversion ? rollbackversion.version : null,
    };
  });
};
