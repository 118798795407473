import React from 'react';
import {
  ButtonProps,
  Modal,
  SelectInput,
  SelectInputOption,
  Label,
} from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { generateNumberArray } from '../utils';

interface CopyModalProps {
  min: number;
  max: number;
  isOpen?: boolean;
  onClose?: () => void;
  handleCopyOk: (copyAmount: number) => void;
}
export const CopyModal = ({
  min,
  max,
  isOpen,
  onClose,
  handleCopyOk,
}: CopyModalProps) => {
  const [shouldCloseOnOverlayClick] = React.useState<boolean>(false);
  const [copyAmount, setCopyAmount] = React.useState<number>(1);
  const { t } = useTranslation();

  const handleOnOKClick = () => {
    handleCopyOk(copyAmount);
    onClose();
  };
  const renderButtons = (): Partial<ButtonProps>[] => {
    const closeButton: Partial<ButtonProps> = {
      children: t('t45'),
      block: true,
      onClick: onClose,
      variant: 'secondary',
    };

    const detailsButton: Partial<ButtonProps> = {
      children: t('t44'),
      block: true,
      onClick: handleOnOKClick,
      variant: 'primary',
    };
    return [closeButton, detailsButton];
  };
  const copyOptions: SelectInputOption[] = generateNumberArray(min, max).map(
    value => ({
      value: value.toString(),
      label: value.toString(),
    }),
  );

  const handleOnChangeAmount = (option: SelectInputOption) => {
    const { value } = option;
    setCopyAmount(+value);
  };

  return (
    <>
      <Modal
        styles={{
          contentContainer: {
            overflow: 'auto',
            minWidth: [400],
            minHeight: [200],
          },
        }}
        isOpen={isOpen}
        onClose={onClose}
        actionButtons={renderButtons()}
        header={<Modal.Header title={t('t486')} showCloseButton={false} />}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <Label>{t('t491')}</Label>
        <SelectInput
          styles={{
            root: { width: '50%', mt: '10px' },
            menuList: { maxHeight: '100px' },
          }}
          label={t('t3361')}
          options={copyOptions}
          defaultValue={copyOptions[0]}
          size="small"
          name="copy modal"
          onChange={handleOnChangeAmount}
          searchable={true}
        />
      </Modal>
    </>
  );
};
