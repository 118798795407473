import { ConfigurationListItem, PATHNAMES } from '@danfoss/etui-sm-xml';

/**
 * Get is configuration list item is editable
 *
 * @export
 * @param {ConfigurationListItem} item
 * @returns {boolean}
 */
export function getIsEditableByItem(
  item: ConfigurationListItem,
  pathname?: string,
): boolean {
  // item with edit control || action or toggle item
  if (parseInt(item?.eda || '0', 10) >= 1 || item?.ef !== '0') {
    if (pathname?.includes(PATHNAMES.EQUIPMENT)) {
      return item?.online === 1;
    }
    return true;
  }
  return false;
}
