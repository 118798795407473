import 'react';
import '@danfoss/etui-system';
import '@danfoss/etui-system-elements';
import { getFirstKeyValuePair } from './get-first-key-value-pair.js';

const getDeviceNameIndex = data => {
  const firstObject = data && data.length > 0 ? data[0] : null;
  const {
    key: firstColumnDataIndex
  } = getFirstKeyValuePair(firstObject);
  return firstColumnDataIndex;
};

export { getDeviceNameIndex };
