import { getSoftwareVersionMinor } from '../../../../../utils/get-software-version-prefix.js';
import moment from 'moment';

const getCombinedEdfFilesListSM800 = (parsedEdfFiles, sm800EdfFiles, progressSuccessCb, unit) => {
  var _filteredCombined;
  if (!sm800EdfFiles && !parsedEdfFiles) {
    progressSuccessCb(20);
  }
  if (!sm800EdfFiles && parsedEdfFiles) {
    progressSuccessCb(60);
  }
  let filteredCombined;
  let combined = sm800EdfFiles ? sm800EdfFiles.map(_ref => {
    let {
      filename,
      filetime,
      filesize
    } = _ref;
    return {
      filename,
      dateInstalled: filetime,
      sizeInstalled: filesize
    };
  }) : null;
  if (parsedEdfFiles) {
    if (!combined) {
      combined = [];
    }
    parsedEdfFiles.forEach(_ref2 => {
      let {
        name,
        date,
        _data
      } = _ref2;
      const size = _data.uncompressedSize;
      const formattedDate = moment(date).format('MM/DD/YY hh:mm');
      const existingFile = combined.find(_ref3 => {
        let {
          filename: existingFilename
        } = _ref3;
        return existingFilename === name;
      });
      if (existingFile) {
        existingFile.dateUpdate = formattedDate;
        existingFile.sizeUpdate = size;
      } else {
        combined.push({
          filename: name,
          dateUpdate: formattedDate,
          sizeUpdate: size
        });
      }
    });
  }
  if (combined) {
    combined.sort((a, b) => !a.dateInstalled ? -1 : a.dateInstalled > b.dateInstalled ? -1 : 1);
  }
  if (parsedEdfFiles && sm800EdfFiles && combined && checkSoftwareVersionForFilter(unit) === 'lower') {
    filteredCombined = combined.filter(eachFile => eachFile.filename.endsWith('.ed3'));
  }
  return ((_filteredCombined = filteredCombined) === null || _filteredCombined === void 0 ? void 0 : _filteredCombined.length) > 0 ? filteredCombined : combined;
};
function checkSoftwareVersionForFilter(unit) {
  const unitSoftwareMinorVersion = getSoftwareVersionMinor(unit.software);
  const version = unitSoftwareMinorVersion > '074' ? 'larger' : unitSoftwareMinorVersion < '074' ? 'lower' : 'equal';
  return version;
}

export { getCombinedEdfFilesListSM800 };
