import { Div, Span } from '@danfoss/etui-system-elements';
import { Icon, IconGlyph } from '@danfoss/etui-core';
import React from 'react';

export interface ShowParentDeviceWithIconProps {
  deviceName: string;
  deviceIcon: IconGlyph;
}

export const ShowParentDeviceWithIcon = ({
  deviceName,
  deviceIcon,
}: ShowParentDeviceWithIconProps) => {
  return (
    <Div testId="parent-device-div" display="flex" alignItems="center">
      <Span testId="device-icon">
        <Icon glyph={deviceIcon} />
      </Span>
      <Span testId="device-name">{deviceName}</Span>
    </Div>
  );
};
