import { TreeItem } from '@danfoss/etui-core';

export const findScheduleDeviceById = (
  treeviewData: TreeItem[],
  id: string,
): TreeItem => {
  let result = null;
  treeviewData.forEach(item => {
    if (item.id === id) {
      result = item;
      return;
    }

    if (item.items) {
      const coincidence = findScheduleDeviceById(item.items, id);
      if (coincidence) {
        result = coincidence;
      }
    }
  });

  return result;
};
