import { styled } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';

export const Hint = styled(Div)`
  padding: ${({ theme }) => theme.spacing.xs}px;

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
`;
