import '../../_rollupPluginBabelHelpers-6901a291.js';
import 'uuid';
import '../Conditions/utils/execute-operation.js';
import '../Conditions/Condition.js';
import { bindMethods } from '../utils/bind-methods.js';
import { Subscribable } from '../utils/Subscribable.js';
import '../Conditions/ConditionCollection.js';
import '../Mappings/Mapping.js';
import '../Mappings/MappingCollection.js';
import '../DataSource/DataSource.js';
import '../Display/Display.js';
import { Rule } from './Rule.js';

class RuleCollection extends Subscribable {
  constructor() {
    super();
    this.rules = [];
    bindMethods(this, ['addRule', 'copyRule', 'changeRuleName', 'toggleRule', 'removeRule']);
  }
  addRule(ruleName) {
    const data = Rule.defaultData();
    const rule = new Rule(ruleName, data);
    this.rules.push(rule);
    rule.setOrder(this.getRulesCount());
    this.notify();
    return rule;
  }
  copyRule(ruleId) {
    const ruleTemplate = this.getRuleById(ruleId);
    if (!ruleTemplate) {
      return undefined;
    }
    const name = `Copy of ${ruleTemplate.name}`;
    const defaultData = Rule.defaultData();
    const newRule = new Rule(name, defaultData);
    newRule.import(ruleTemplate.getData());

    // Add rule's copy after template rule
    const ruleTemplateIndex = this.rules.indexOf(ruleTemplate);
    this.rules.splice(ruleTemplateIndex + 1, 0, newRule);
    this.setOrder();
    this.notify();
    return newRule;
  }
  changeRuleName(ruleId, newName) {
    const rule = this.getRuleById(ruleId);
    if (rule) {
      rule.name = newName;
      this.notify();
    }
    return rule;
  }
  getRules() {
    return this.rules;
  }
  getRuleById(ruleId) {
    return this.rules.find(rule => rule.id === ruleId);
  }
  importRules(rawObj) {
    if (Array.isArray(rawObj)) {
      rawObj.forEach(raw => {
        this.addRule('').import(raw);
      });
      this.setOrder();
    }
    return this;
  }
  removeRule(ruleId) {
    this.rules = this.rules.filter(rule => rule.id !== ruleId);
    this.setOrder();
    this.notify();
  }
  toggleRule(ruleId) {
    const rule = this.getRuleById(ruleId);
    if (rule) {
      rule.setHidden(!rule.isHidden());
      this.notify();
    }
  }
  getRulesCount() {
    return this.rules.length;
  }
  setOrder() {
    for (let index = 0; index < this.getRulesCount(); index++) {
      const rule = this.rules[index];
      rule.setOrder(index + 1);
    }
    return this;
  }
  toJSON() {
    return this.rules || [];
  }
}

export { RuleCollection };
