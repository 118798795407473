import * as React from 'react';
import { Unit } from '@danfoss/etui-sm-xml';
import { ConfigurationProvider } from '../../../Configuration/context';
import { useKeepSessionAlive } from '../../../../ConfigurationPage/UnitConfiguration/hooks/use-keep-session-alive';
import { RefrigLayoutProvider } from './context';
import { RefrigLayoutInnerModal } from './RefrigLayoutInnerModal';

export interface RefrigarationLayoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  unit: Unit;
}
function RefrigLayoutModal({
  isOpen,
  onClose,
  unit,
}: RefrigarationLayoutModalProps) {
  useKeepSessionAlive();
  return (
    <ConfigurationProvider>
      <RefrigLayoutProvider>
        <RefrigLayoutInnerModal isOpen={isOpen} onClose={onClose} unit={unit} />
      </RefrigLayoutProvider>
    </ConfigurationProvider>
  );
}

export { RefrigLayoutModal };
