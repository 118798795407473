import { XML_DEVICE_LIST } from '@danfoss/etui-sm-xml';

export type MaximizedQueryValue =
  | 'alarms'
  | 'refrig'
  | 'hvac'
  | 'lights'
  | 'meter'
  | 'misc'
  | null;

export function getMaximizedQueryByDeviceType(
  deviceType: XML_DEVICE_LIST,
): MaximizedQueryValue {
  return deviceType === XML_DEVICE_LIST.REFRIG
    ? 'refrig'
    : deviceType === XML_DEVICE_LIST.HVAC
    ? 'hvac'
    : deviceType === XML_DEVICE_LIST.LIGHTS
    ? 'lights'
    : deviceType === XML_DEVICE_LIST.METER
    ? 'meter'
    : deviceType === XML_DEVICE_LIST.MISC
    ? 'misc'
    : null;
}
