import { AmPm } from 'pages/HistoryPage/utils/history-utils';
import { TimeConstant } from '../SchedulesPage.types';

export const getStoreCloseTime = (closeTime: any = {}, measurements: any) => {
  if (
    closeTime?.hour === TimeConstant.ZERO &&
    closeTime?.minute === TimeConstant.ZERO &&
    (closeTime?.units === AmPm.AM || !closeTime?.units)
  ) {
    return setStoreTime(TimeConstant.TWENTY_THREE, TimeConstant.FIFTY_NINE);
  }
  if (+closeTime?.hour < TimeConstant.TWELVE && closeTime?.units === AmPm.AM) {
    return setStoreTime(+closeTime?.hour, +closeTime?.minute);
  }
  if (
    +measurements?.timeFormat === TimeConstant.TIMEFORMAT &&
    +closeTime?.hour === TimeConstant.TWELVE &&
    closeTime?.units === AmPm.AM
  ) {
    return setStoreTime(TimeConstant.TWELVE, +closeTime?.minute);
  }
  return setStoreTime(
    +measurements?.timeFormat === TimeConstant.TIMEFORMAT
      ? TimeConstant.TWELVE + +closeTime?.hour
      : +closeTime?.hour,
    +closeTime?.minute,
  );
};

const setStoreTime = (hour: any, minute: any) => {
  const storeTime = {
    hour,
    minute,
  };
  return storeTime;
};
