interface CookieOptions {
  path?: string;
  'max-age'?: string | number;
  samesite?: 'strict' | 'lax';
}

const THIRTY_DAYS_IN_SECS = 2592000;

export const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions = {},
) => {
  options = {
    path: '/',
    samesite: 'strict',
    'max-age': THIRTY_DAYS_IN_SECS,
    ...options,
  };

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value,
  )}`;

  Object.entries(options).forEach(([key, value]) => {
    updatedCookie += `; ${key}`;
    if (value !== true) {
      updatedCookie += `=${value}`;
    }
  });

  document.cookie = updatedCookie;
};

export const deleteCookie = (name: string) =>
  setCookie(name, '', {
    'max-age': -1,
  });
