import React from 'react';
import {
  ConfigurationListDataArray,
  ConfigurationListItem,
  fetchLayoutListByUnit,
  XML_REFRIG_COPY_TAB_INDEX,
} from '@danfoss/etui-sm-xml';
import { useAuth, useUnit, useXmlResource } from '@danfoss/etui-sm/';
import { SelectInputOption, Notification } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  HACCP_STATUS,
  SourceGraphTableContent,
  SourceGraphTableData,
  SourceGraphTablePageContent,
} from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';
import { getArray } from '../../../../../Configuration/utils';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { writeConfigurationListItem } from '../../../../../Configuration/actions';
import { REFRIG_SUCCESS_RESPONSE } from '../../../RefrigLayoutModal/types';
import {
  addPageToListItems,
  checkIfPageContentNotAvailable,
  filterSourceGraphTableContentList,
  findCurrentPageContent,
  findEditedHaccpListItem,
  findEditedListItem,
  processSourceGraphTableData,
  setHaccpStatus,
  setLogStatus,
} from './actions';
import { SourceGraphFlowPage } from '.';

export type SourceGraphFlowContentProps = {
  tableAddress: string;
};

export const SourceGraphFlowContent = ({
  tableAddress,
}: SourceGraphFlowContentProps) => {
  const { url, ip } = useXmlResource();
  const { user } = useAuth();
  const { units, getFirstValidUrl } = useUnit();
  const filteredUnit = units.filter(unit =>
    getFirstValidUrl(unit).includes(ip),
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const unit = filteredUnit[0];
  const [configurationListItem, setConfigurationListItem] = React.useState<
    any[]
  >([]);
  const {
    isAllPageDataFetched,
    sourceGraphTableData,
    page,
    searchMode,
    searchValue,
    pageValues,
    allPageData,
    sourceGraphTablePageContent,
    setSourceGraphTableData,
    setMultipage,
    setAllPageData,
    setIsAllPageDataFetched,
    setSourceGraphTablePageContent,
    setIsPageDataFetched,
    setSaveCompleted,
    setSelectedRowIndex,
  } = useRefrigCopy();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const networkTableData = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.SOURCE_GRAPH,
          user,
          page,
        ]);
        if (networkTableData?.items?.l?.length) {
          const updatedListItems: ConfigurationListItem[] = addPageToListItems(
            getArray(networkTableData?.items?.l),
            page,
          );
          const sourceGraphTableContentList: SourceGraphTableContent[] =
            processSourceGraphTableData(
              updatedListItems,
              networkTableData?.listgroup?.listdata,
              page,
              t,
            );
          const updatedSourceGraphTableData: SourceGraphTableData = {
            sourceGraphTableContent: sourceGraphTableContentList,
          };
          const addedSourceGraphTablePageContent: SourceGraphTablePageContent =
            {
              listItems: networkTableData?.items?.l,
              listData: networkTableData?.listgroup
                ?.listdata as ConfigurationListDataArray,
              page,
            };
          setMultipage(networkTableData?.multipage);
          setSourceGraphTableData(updatedSourceGraphTableData);
          sourceGraphTablePageContent.push(addedSourceGraphTablePageContent);
          setSourceGraphTablePageContent(sourceGraphTablePageContent);
          setIsPageDataFetched(true);
        }
      } catch (error) {
        Notification.error({
          message: t('t17'),
          description: t('t3403'),
          duration: 3,
          theme,
        });
      }
    };
    if (
      !sourceGraphTablePageContent.length ||
      checkIfPageContentNotAvailable(sourceGraphTablePageContent, page)
    ) {
      setIsPageDataFetched(false);
      fetch();
    } else {
      getFetched();
    }
  }, [page]);

  const getFetched = () => {
    const currentPageContent = findCurrentPageContent(
      sourceGraphTablePageContent,
      page,
    );
    const updatedListItems: ConfigurationListItem[] = addPageToListItems(
      getArray(currentPageContent?.listItems),
      page,
    );
    const sourceGraphTableContentList: SourceGraphTableContent[] =
      processSourceGraphTableData(
        updatedListItems,
        currentPageContent?.listData,
        page,
        t,
      );
    const updatedSourceGraphTableData: SourceGraphTableData = {
      sourceGraphTableContent: sourceGraphTableContentList,
    };
    setSourceGraphTableData(updatedSourceGraphTableData);
  };

  const fetchAllPages = async (pages: number[]) => {
    const response = [];
    try {
      await pages.reduce(async (promise, page) => {
        await promise;
        page -= 1;
        const data = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.SOURCE_GRAPH,
          user,
          page.toString(),
        ]);
        response.push(data);
      }, Promise.resolve());
    } catch (error) {
      Notification.error({
        message: t('t17'),
        description: t('t3403'),
        duration: 3,
        theme,
      });
    }
    const sourceGraphResponse = response
      .filter(data => data !== undefined)
      .flat();
    return sourceGraphResponse;
  };

  React.useEffect(() => {
    const fetchAll = async () => {
      if (pageValues?.length && !allPageData?.length) {
        const data: any[] = await fetchAllPages(pageValues);
        setConfigurationListItem(data);
      }
    };
    if (searchMode) {
      fetchAll();
    }
  }, [searchMode]);

  React.useEffect(() => {
    if (configurationListItem?.length) {
      const allContentList: SourceGraphTableContent[] = [];
      const sourceGraphTablePageContentList: SourceGraphTablePageContent[] = [];
      configurationListItem?.forEach((pageData, index) => {
        const updatedListItem: ConfigurationListItem[] = addPageToListItems(
          getArray(pageData?.items?.l),
          index.toString(),
        );
        const processedSourceGraphTableContentList: SourceGraphTableContent[] =
          processSourceGraphTableData(
            updatedListItem,
            pageData?.listgroup?.listdata,
            index.toString(),
            t,
          );
        const addedSourceGraphTablePageContent: SourceGraphTablePageContent = {
          listItems: updatedListItem,
          listData: pageData?.listgroup?.listdata
            ?.listdata as ConfigurationListDataArray,
          page: index.toString(),
        };
        sourceGraphTablePageContentList.push(addedSourceGraphTablePageContent);
        allContentList.push(...processedSourceGraphTableContentList);
      });
      const filteredSourceGraphTableContentList =
        filterSourceGraphTableContentList(allContentList, searchValue);
      const filteredSourceGraphTableData: SourceGraphTableData = {
        sourceGraphTableContent: filteredSourceGraphTableContentList,
      };
      setSourceGraphTablePageContent(sourceGraphTablePageContentList);
      setAllPageData(allContentList.flat());
      setIsAllPageDataFetched(true);
      setSourceGraphTableData(filteredSourceGraphTableData);
    }
  }, [configurationListItem]);

  React.useEffect(() => {
    if (searchMode && isAllPageDataFetched) {
      const filteredSourceGraphTableContentList =
        filterSourceGraphTableContentList(
          allPageData,
          searchValue,
        ) as SourceGraphTableContent[];
      const filteredSourceGraphTableData: SourceGraphTableData = {
        sourceGraphTableContent: filteredSourceGraphTableContentList,
      };
      setSourceGraphTableData(filteredSourceGraphTableData);
    }
  }, [searchValue]);

  const revalidate = async (page: string) => {
    await fetchLayoutListByUnit([
      url,
      unit,
      tableAddress,
      XML_REFRIG_COPY_TAB_INDEX.SOURCE_GRAPH,
      user,
      page,
    ]);
  };

  const handleSave = async (
    listItem: ConfigurationListItem,
    iVal: string,
    value: string,
    selectedRowIndex: number,
    isHaccp: boolean,
    isChecked?: boolean,
    selectedLogValue?: string,
  ) => {
    const response = await withErrorHandled(
      t,
      theme,
      writeConfigurationListItem,
    )(
      url,
      user,
      null,
      `${tableAddress}-${XML_REFRIG_COPY_TAB_INDEX.SOURCE_GRAPH}`,
      null,
      '0',
      '0',
      listItem,
      value,
      iVal,
      '',
    );
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      await revalidate(listItem.page);
      if (!isHaccp) {
        setLogStatus(
          sourceGraphTableData,
          selectedRowIndex,
          false,
          true,
          false,
        );

        sourceGraphTablePageContent.forEach(pageContent => {
          pageContent.listItems.forEach(item => {
            if (
              item.name === listItem.name &&
              item.li === listItem.li &&
              item.page === listItem.page
            ) {
              item.iVal = iVal;
              item.value = selectedLogValue;
              item.width = `0|{${selectedLogValue}}`;
            }
          });
        });
      } else {
        setHaccpStatus(
          sourceGraphTableData,
          selectedRowIndex,
          false,
          true,
          false,
        );

        sourceGraphTablePageContent.forEach(pageContent => {
          pageContent.listItems.forEach(item => {
            if (
              item.name === listItem.name &&
              item.li === listItem.li &&
              item.page === listItem.page
            ) {
              item.iVal = iVal;
              item.value =
                iVal === HACCP_STATUS.ENABLED_IVAL ? t('t3415') : t('t3416');
              item.width = `0|{${item.value}}`;
            }
          });
        });
      }
    } else {
      !isHaccp
        ? setLogStatus(
            sourceGraphTableData,
            selectedRowIndex,
            false,
            false,
            true,
            value,
          )
        : setHaccpStatus(
            sourceGraphTableData,
            selectedRowIndex,
            false,
            false,
            true,
            !isChecked,
          );
    }
    const updatedSourceGraphTableData: SourceGraphTableData = {
      sourceGraphTableContent: sourceGraphTableData.sourceGraphTableContent,
    };
    setSourceGraphTableData(updatedSourceGraphTableData);
    setSelectedRowIndex(selectedRowIndex);
    setSaveCompleted(true);
  };

  const handleOnLogChange = async (
    selectedOption: SelectInputOption,
    content: SourceGraphTableContent,
    selectedRowIndex: number,
  ) => {
    const selectedLogValue: string = selectedOption.label;
    const ival: string = selectedOption.value;
    const value: string = content.log;

    setLogStatus(
      sourceGraphTableData,
      selectedRowIndex,
      true,
      false,
      false,
      selectedLogValue,
    );
    const updatedSourceGraphTableData: SourceGraphTableData = {
      sourceGraphTableContent: sourceGraphTableData.sourceGraphTableContent,
    };
    setSourceGraphTableData(updatedSourceGraphTableData);
    const currentPageContent = findCurrentPageContent(
      sourceGraphTablePageContent,
      content.page,
    );
    const listItem: ConfigurationListItem = findEditedListItem(
      content,
      currentPageContent.listItems,
    );

    await handleSave(
      listItem,
      ival,
      value,
      selectedRowIndex,
      false,
      null,
      selectedLogValue,
    );
  };

  const handleOnHaccpToggle = async (
    isChecked: boolean,
    content: SourceGraphTableContent,
    selectedRowIndex: number,
  ) => {
    setHaccpStatus(
      sourceGraphTableData,
      selectedRowIndex,
      true,
      false,
      false,
      isChecked,
    );
    const updatedSourceGraphTableData: SourceGraphTableData = {
      sourceGraphTableContent: sourceGraphTableData.sourceGraphTableContent,
    };
    setSourceGraphTableData(updatedSourceGraphTableData);

    const currentPageContent = findCurrentPageContent(
      sourceGraphTablePageContent,
      content.page,
    );
    const listItem: ConfigurationListItem = findEditedHaccpListItem(
      content,
      currentPageContent.listItems,
    );
    const ival = isChecked
      ? HACCP_STATUS.ENABLED_IVAL
      : HACCP_STATUS.DISABLED_IVAL;
    const { value } = listItem;
    await handleSave(listItem, ival, value, selectedRowIndex, true, isChecked);
  };

  return (
    <SourceGraphFlowPage
      handleOnLogChange={handleOnLogChange}
      handleOnHaccpToggle={handleOnHaccpToggle}
    />
  );
};
