function parseXml(xml) {
  if (window.DOMParser) {
    const parser = new DOMParser();
    return parser.parseFromString(xml, 'text/xml');
  }
  const result = this.createXmlDocument();
  result.async = 'false';
  result.loadXML(xml);
  return result;
}

export { parseXml };
