import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import { UnitNetworkError } from '../../types/Unit.js';
import { getIsValidDns } from '../url-helpers/get-is-valid-dns.js';
import { getIsValidIp } from '../url-helpers/get-is-valid-ip.js';
import { getIsValidIpOrDns } from '../url-helpers/get-is-valid-ip-or-dns.js';
import { getIsValidPort } from '../url-helpers/get-is-valid-port.js';
import { getUrlInfo } from '../url-helpers/get-url-info.js';
import { getInitialUnitFromStorage } from './initial-unit-utils.js';

const networkChecker = sourceURI => _ref => {
  let {
    ip,
    ip1,
    ip2
  } = _ref;
  return [ip, ip1, ip2].includes(sourceURI);
};

/**
 * Get the valid and invalid unit networks based on the source url.
 *
 * @param {string} sourceUrl
 * @param {Unit} unit
 * @returns {{ validUnitNetworks: UnitNetwork[]; invalidUnitNetworks: UnitNetwork[];
 *             internalUnits: UnitNetwork[]; externalUnits: UnitNetwork[];
 *             isDNS?: boolean; isValidDomainName?: boolean;
 *             isMisconfigured?: boolean; isExternal?: boolean;}}
 */
function getUnitNetworksByInitialUnit(sourceUrl, unit, isSourceUrl) {
  const initialUnit = getInitialUnitFromStorage();
  const {
    protocol,
    host
  } = getUrlInfo(sourceUrl);
  const isHTTPs = protocol.includes('https');
  let sourceIpOrDns = host;
  let sourcePort = null;
  if (host !== null && host !== void 0 && host.includes(':')) {
    const [ipFromIpAndPort, portFromIpAndPort] = host.split(':');
    sourceIpOrDns = ipFromIpAndPort;
    sourcePort = portFromIpAndPort;
  }
  if (!getIsValidIpOrDns(sourceIpOrDns)) {
    return {
      validUnitNetworks: [],
      invalidUnitNetworks: [],
      internalUnits: [],
      externalUnits: [],
      isDNS: false,
      isValidDomainName: false,
      isMisconfigured: false,
      isExternal: false
    };
  }
  const isDomain = getIsValidDns(sourceIpOrDns) && !getIsValidIp(sourceIpOrDns);
  const port = getIsValidPort(sourcePort) ? sourcePort : null;
  const validUnitNetworks = [];
  const invalidUnitNetworks = [];
  let internalUnitNetworks = [];
  let externalUnitNetworks = [];
  let isMisconfigured = false;
  let isExternal = false;
  let isValidDomainName;
  if (isDomain) {
    const domainName = `${unit.domain_name}.${unit.domain}`;
    isValidDomainName = unit.domain_name === sourceIpOrDns ? unit.isDNS = true : domainName.toLowerCase() === sourceIpOrDns ? unit.isDNS = true : unit.isDNS = false;
  }
  if (unit && (unit.unit || unit.unit_internet)) {
    internalUnitNetworks = unit.unit ? Array.isArray(unit.unit) ? unit.unit : [unit.unit] : [];
    internalUnitNetworks.forEach(internalUnitNetwork => {
      internalUnitNetwork.isExternal = false;
    });
    externalUnitNetworks = unit.unit_internet ? Array.isArray(unit.unit_internet) ? unit.unit_internet : [unit.unit_internet] : [];
    externalUnitNetworks.forEach(externalUnitNetwork => {
      externalUnitNetwork.isExternal = true;
    });
    const shouldUseInternalNetworks = internalUnitNetworks.some(networkChecker(sourceIpOrDns));
    const shouldUseExternalNetworks = externalUnitNetworks.some(networkChecker(sourceIpOrDns));
    isMisconfigured = isDomain ? shouldUseInternalNetworks && shouldUseExternalNetworks && !isValidDomainName : !(shouldUseInternalNetworks || shouldUseExternalNetworks);
    const unitNetworks = shouldUseInternalNetworks ? internalUnitNetworks : externalUnitNetworks;
    isExternal = !isMisconfigured && shouldUseExternalNetworks;
    const fallback = {
      addr: initialUnit ? initialUnit.unit_addr : unit.unit_addr,
      name: initialUnit ? initialUnit.unit_name : unit.unit_name,
      ip: sourceIpOrDns,
      port: port || (isHTTPs ? '443' : '80'),
      online: '1'
    };
    const shouldUseFallback = networkAddress => initialUnit ? networkAddress === initialUnit.unit_addr : networkAddress === unit.unit_addr && isSourceUrl;
    unitNetworks.forEach(unitNetwork => {
      if (shouldUseFallback(unitNetwork.addr)) {
        validUnitNetworks.push(fallback);
      } else if ((getIsValidIpOrDns(unitNetwork.ip) || getIsValidIpOrDns(unitNetwork.ip1) || getIsValidIpOrDns(unitNetwork.ip2)) && getIsValidPort(unitNetwork.port)) {
        unitNetwork.error = '';
        validUnitNetworks.push(unitNetwork);
      } else {
        unitNetwork.error = UnitNetworkError.IP_ERROR;
        invalidUnitNetworks.push(unitNetwork);
      }
    });

    // Default fallback for getting at least one validUnitNetwork
    // Due to that we know at this stage that it should have one.
    // This is fixing a bug where the unit_internet networks ip property is 0
    if (validUnitNetworks.length === 0) {
      validUnitNetworks.push(fallback);
    }
    return {
      validUnitNetworks,
      invalidUnitNetworks,
      internalUnits: internalUnitNetworks,
      externalUnits: externalUnitNetworks,
      isDNS: isDomain,
      isValidDomainName,
      isMisconfigured,
      isExternal
    };
  }
  return {
    validUnitNetworks: [],
    invalidUnitNetworks: [],
    internalUnits: [],
    externalUnits: [],
    isDNS: isDomain,
    isValidDomainName,
    isMisconfigured,
    isExternal
  };
}

export { getUnitNetworksByInitialUnit };
