import { User, Unit } from '@danfoss/etui-sm-xml';
import { getAlarmRefsGroupedByUnit } from '../utils';
import { fetchAcknowledgeAlarmsByUnit } from './fetch-acknowledge-alarms-by-unit';

export async function fetchAcknowledgeAlarmsByUnits(
  url: string,
  alarmRefs: string[],
  user: User,
  units: Unit[],
) {
  const alarmRefsGroupedByUnit = getAlarmRefsGroupedByUnit(alarmRefs);
  const unitAddresses = Object.keys(alarmRefsGroupedByUnit);

  const responses = await Promise.all(
    unitAddresses.map(unitAddress => {
      const unit = units.find(u => u.unit_addr === unitAddress);
      return fetchAcknowledgeAlarmsByUnit(
        url,
        alarmRefsGroupedByUnit,
        user,
        unit,
      );
    }),
  );

  return responses.flat();
}
