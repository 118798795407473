import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import {
  ParametersTable,
  ParametersTableContent,
} from '../../../types/RefrigCopy.types';
import { AllPageType } from '../../../context';
import { getArray } from '../../../../../../Configuration/utils';

export const processParametersTableData = (tableContentData: any[]) => {
  const tabledata = [];
  const tableContent = getArray(tableContentData);
  tableContent?.forEach(data => {
    const paramsTableContentList: ConfigurationListItem[] = getArray(
      data?.items?.l,
    );

    paramsTableContentList.forEach(item => {
      const obj: ParametersTable = {
        name: item.name,
        li: item,
        key: null,
        toggleVal: item.toggleVal,
      };
      tabledata.push(obj);
    });
  });
  return tabledata.flat();
};

export const filterParamTableContentList = (
  allPageData: AllPageType[],
  searchValue: string,
) => {
  const filteredSourceGraphTableContentList = allPageData?.filter(content => {
    const name = (content as ParametersTableContent)?.name?.toLowerCase();
    const value = searchValue?.toLowerCase();
    return name.includes(value);
  });
  return filteredSourceGraphTableContentList;
};
