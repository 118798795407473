import * as React from 'react';
import { lighten } from '@danfoss/etui-colors';
import { useTheme } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';
import { ellipsis } from '@danfoss/etui-core';

export type ConfigurationListItemEditDisplayValueProps = DivProps & {
  value: string;
};

function ConfigurationListItemEditDisplayValue({
  value,
  ...props
}: ConfigurationListItemEditDisplayValueProps) {
  const theme = useTheme();
  return (
    <Div
      display="flex"
      alignItems="center"
      color={theme.palette.text.primary}
      border={`0.5px solid ${lighten(theme.palette.primary.main, 0.6)}`}
      borderRadius={theme.shape.borderRadius}
      p={`${theme.spacing.xs}px`}
      height="42px"
      bg={lighten(theme.palette.common.bg, 0.02)}
      letterSpacing="normal"
      cursor="pointer"
      tabIndex={0}
      {...props}
    >
      <Div {...(ellipsis() as any)} title={value}>
        {value}
      </Div>
    </Div>
  );
}

export { ConfigurationListItemEditDisplayValue };
