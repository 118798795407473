import { styled } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';

interface Props extends DivProps {
  showWait?: boolean;
}

export const ListEditItemWrapper = styled(Div)<Props>`
  overflow: initial;

  * {
    cursor: ${({ showWait }) => showWait && 'not-allowed !important'};
  }
`;
