import { XML_DEVICE_NODETYPE, XML_DEVICE_STR } from '@danfoss/etui-sm-xml';

/**
 * Utility to validate if device is a relay output
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsRelayOutputDevice(device) {
  return device.nodetype === XML_DEVICE_NODETYPE.NODETYPE_RO || device.type === XML_DEVICE_STR.STR_TYPE_RO || device.type === XML_DEVICE_STR.STR_TYPE_RO_RH || device.type === XML_DEVICE_STR.STR_TYPE_ALL_RO;
}

export { getIsRelayOutputDevice };
