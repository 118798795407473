import '../constants/session-storage-constants.js';
import '../constants/xml-action-constant.js';
import { XML_TAG } from '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Metadata.js';
import { _ as _objectSpread2 } from '../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import { buildXMLCommand } from '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import 'csvtojson';
import { fetchXML } from './fetch-xml.js';

async function fetchUploadFileByChunks(url, user, fileChunksArray, fileName, fileSize, done, index, fileId, setProgressCallback) {
  if (done === void 0) {
    done = 0;
  }
  if (index === void 0) {
    index = 1;
  }
  const offset = fileSize;
  const size = fileChunksArray[index - 1].length;
  let body = '';
  const baseAttributes = {
    action: 'load_file',
    user: user.user,
    password: user.password,
    lang: user.language,
    filename: fileName,
    size,
    offset,
    index,
    done
  };
  if (fileChunksArray.length === 1) {
    done = 1;
    body = buildXMLCommand({
      attributes: _objectSpread2(_objectSpread2({}, baseAttributes), {}, {
        done
      }),
      items: [{
        tag: XML_TAG.B64,
        value: fileChunksArray[index - 1]
      }]
    });
  } else if (index === 1) {
    body = buildXMLCommand({
      attributes: _objectSpread2({}, baseAttributes),
      items: [{
        tag: XML_TAG.B64,
        value: fileChunksArray[index - 1]
      }]
    });
  } else if (index === fileChunksArray.length) {
    done = 1;
    body = buildXMLCommand({
      attributes: _objectSpread2(_objectSpread2({}, baseAttributes), {}, {
        done,
        file_id: fileId
      }),
      items: [{
        tag: XML_TAG.B64,
        value: fileChunksArray[index - 1]
      }]
    });
  } else {
    body = buildXMLCommand({
      attributes: _objectSpread2(_objectSpread2({}, baseAttributes), {}, {
        file_id: fileId
      }),
      items: [{
        tag: XML_TAG.B64,
        value: fileChunksArray[index - 1]
      }]
    });
  }
  const response = await fetchXML({
    url,
    body
  });
  if (done !== 1) {
    const {
      file_id
    } = response;
    index++;
    setProgressCallback && setProgressCallback(index, fileChunksArray);
    return fetchUploadFileByChunks(url, user, fileChunksArray, fileName, fileSize, done, index, file_id, setProgressCallback);
  }
  return response;
}

export { fetchUploadFileByChunks };
