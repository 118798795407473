import { SelectInputOption } from '@danfoss/etui-core';
import { TFunction } from 'i18next';
import {
  DeviceCompressorData,
  DeviceConstraintsData,
  DeviceTypeModelList,
  DEVICE_PACK_SUCTION_NAME,
  DEVICE_TYPE,
  DiscoveryTableData,
  SPECIAL_CHAR_REGEX,
} from '../types/DiscoveryPage.types';
import {
  getMaxSg,
  getModelListName,
  getSuctionGroupCount,
  getTypeDescription,
} from '../utils';
import { removeAllSuctionGroups } from './compressor-flow-delete-actions';
import { insertSuctionGroup } from '.';

function processCompressorData(
  discoveryTableData: DiscoveryTableData[],
  t: TFunction,
  deviceTypeModelList: DeviceTypeModelList,
  updateValues: boolean,
) {
  const dualSuctionDevices: DiscoveryTableData[] = [];
  const suction: DiscoveryTableData[] = [];
  discoveryTableData?.forEach(device => {
    switch (device?.deviceType) {
      case DEVICE_TYPE.RACK:
        if (updateValues) {
          device.listname = t('t511');
          if (device?.max_sg === 0) {
            device.max_sg = getSuctionGroupCount(suction);
          }
        }
        suction.push(device);
        return;
      case DEVICE_TYPE.RACKSUCT:
        dualSuctionDevices.push(device);
        return;
      case DEVICE_TYPE.GEN_SUCT:
        dualSuctionDevices.push(device);
        return;
      case DEVICE_TYPE.NO_COMP_SUCT:
        dualSuctionDevices.push(device);
        return;
      case DEVICE_TYPE.GEN_PACK:
        if (updateValues) {
          device.listname = getModelListName(
            device,
            deviceTypeModelList?.modelList,
          );
        }
        suction.push(device);
        return;
      case DEVICE_TYPE.NO_COMP:
        if (updateValues) {
          device.listname = t('t480');
        }
        suction.push(device);
        return;
      default:
        return null;
    }
  });
  const updatedData: DeviceCompressorData = {
    updatedDiscoveryTableData: discoveryTableData,
    suction,
    dualSuctionDevices,
  };
  return updatedData;
}

function getUpdatedCompressorData(discoveryTableData: DiscoveryTableData[]) {
  const dualSuctionDevices: DiscoveryTableData[] = [];
  const suction: DiscoveryTableData[] = [];
  discoveryTableData?.forEach(device => {
    switch (device?.deviceType) {
      case DEVICE_TYPE.RACK:
        suction.push(device);
        return;
      case DEVICE_TYPE.RACKSUCT:
        dualSuctionDevices.push(device);
        return;
      case DEVICE_TYPE.GEN_SUCT:
        dualSuctionDevices.push(device);
        return;
      case DEVICE_TYPE.NO_COMP_SUCT:
        dualSuctionDevices.push(device);
        return;
      case DEVICE_TYPE.GEN_PACK:
        suction.push(device);
        return;
      case DEVICE_TYPE.NO_COMP:
        suction.push(device);
        return;
      default:
        return null;
    }
  });
  const updatedData: DeviceCompressorData = {
    updatedDiscoveryTableData: discoveryTableData,
    suction,
    dualSuctionDevices,
  };
  return updatedData;
}

function updateSelectedDeviceInfo(
  modelListNameOptions: SelectInputOption[],
  selectedModelOption: SelectInputOption,
  selectedRowIndex: number,
  deviceCompressorData: DeviceCompressorData,
  discoveryTableData: DiscoveryTableData[],
  deviceContraintsData: DeviceConstraintsData,
  deviceTypeModelList: DeviceTypeModelList,
  t: TFunction,
) {
  const selectedDeviceSuction: DiscoveryTableData =
    deviceCompressorData.suction[selectedRowIndex];
  const selectedDiscoveryTableData: DiscoveryTableData =
    discoveryTableData?.find(
      device => device.deviceId === selectedDeviceSuction.deviceId,
    );
  const selectedModelIndex = modelListNameOptions.findIndex(
    modelList => modelList.label === selectedModelOption.label,
  );
  const origType: string = selectedDeviceSuction.deviceType;
  if (selectedModelIndex === 0) {
    selectedDiscoveryTableData.deviceType = DEVICE_TYPE.RACK;
    selectedDiscoveryTableData.address = '-';
    selectedDiscoveryTableData.version = '';
    selectedDiscoveryTableData.device = '';
    selectedDiscoveryTableData.name = `${t('t479')} ${String.fromCharCode(
      65 + selectedDiscoveryTableData.rk - 1,
    )}`;
    selectedDiscoveryTableData.model = selectedModelOption.label;
    if (origType !== selectedDiscoveryTableData.type) {
      removeAllSuctionGroups(selectedDiscoveryTableData.rk, discoveryTableData);
      selectedDiscoveryTableData.num_sg = 1;
    }
    selectedDiscoveryTableData.type = getTypeDescription(
      selectedDiscoveryTableData.deviceType,
      t,
    );
  } else if (selectedModelIndex === 1) {
    selectedDiscoveryTableData.name = `${t('t480')} ${String.fromCharCode(
      65 + selectedDiscoveryTableData.rk - 1,
    )}`;
    if (origType !== DEVICE_TYPE.NO_COMP) {
      removeAllSuctionGroups(selectedDiscoveryTableData.rk, discoveryTableData);
    }
    selectedDiscoveryTableData.deviceType = DEVICE_TYPE.NO_COMP;
    if (origType !== selectedDiscoveryTableData.type) {
      selectedDiscoveryTableData.num_sg = 1;
    }
    selectedDiscoveryTableData.address = '-';
    selectedDiscoveryTableData.version = '';
    selectedDiscoveryTableData.device = '';
    selectedDiscoveryTableData.type = getTypeDescription(
      selectedDiscoveryTableData.deviceType,
      t,
    );
    selectedDiscoveryTableData.file = '';
    selectedDiscoveryTableData.version = '';
    selectedDiscoveryTableData.model = selectedModelOption.label;
    selectedDiscoveryTableData.model_idx = selectedModelIndex + 1;
  } else {
    if (origType !== DEVICE_TYPE.GEN_PACK) {
      selectedDiscoveryTableData.address = '0';
      removeAllSuctionGroups(selectedDiscoveryTableData.rk, discoveryTableData);
    }
    selectedDiscoveryTableData.deviceType = DEVICE_TYPE.GEN_PACK;
    selectedDiscoveryTableData.num_sg = 1;
    selectedDiscoveryTableData.name = `${t('t188')} ${
      selectedDiscoveryTableData.rk
    }`;
    selectedDiscoveryTableData.type = getTypeDescription(
      selectedDiscoveryTableData.deviceType,
      t,
    );
    selectedDiscoveryTableData.file = '';
    selectedDiscoveryTableData.version = '';
    const model = selectedModelOption.label.split(' ')[0];
    selectedDiscoveryTableData.model = model;
    selectedDiscoveryTableData.model_idx = selectedModelIndex + 1;
  }
  selectedDiscoveryTableData.max_sg = getMaxSg(
    selectedDiscoveryTableData.deviceType,
  );
  if (
    (origType !== DEVICE_TYPE.RACK &&
      selectedDiscoveryTableData.deviceType === DEVICE_TYPE.RACK) ||
    (origType !== DEVICE_TYPE.NO_COMP &&
      selectedDiscoveryTableData.deviceType === DEVICE_TYPE.NO_COMP)
  ) {
    const name = `${t('t402')} ${
      String.fromCharCode(65 + selectedDiscoveryTableData.rk - 1) +
      String.fromCharCode(65)
    }`;
    insertSuctionGroup(
      selectedDiscoveryTableData.rk,
      name,
      false,
      discoveryTableData,
      deviceContraintsData,
      t,
    );
  } else if (
    origType !== DEVICE_TYPE.GEN_PACK &&
    selectedDiscoveryTableData.deviceType === DEVICE_TYPE.GEN_PACK
  ) {
    const name = `${t('t402')} ${DEVICE_PACK_SUCTION_NAME[0]}`;
    insertSuctionGroup(
      selectedDiscoveryTableData.rk,
      name,
      false,
      discoveryTableData,
      deviceContraintsData,
      t,
    );
  }
  selectedDeviceSuction.listname = selectedModelOption.label;
  const updatedData: DeviceCompressorData = processCompressorData(
    discoveryTableData,
    t,
    deviceTypeModelList,
    false,
  );

  return updatedData;
}

function validateAddress(
  address: string,
  selectedRowDeviceId: number,
  deviceConstraintsData: DeviceConstraintsData,
  discoveryTableData: DiscoveryTableData[],
) {
  if (address !== '0') {
    if (+address > +deviceConstraintsData?.max_naddr || +address < 0) {
      return 't3520';
    }
    if (
      discoveryTableData.filter(
        device =>
          device.deviceId !== selectedRowDeviceId &&
          device.address !== '0' &&
          device.address !== '-' &&
          device.address === address,
      ).length
    ) {
      return 't517';
    }
  }
  return '';
}

function hasSpecialCharacters(value: string) {
  return !new RegExp(SPECIAL_CHAR_REGEX).test(value);
}

export {
  processCompressorData,
  getUpdatedCompressorData,
  updateSelectedDeviceInfo,
  validateAddress,
  hasSpecialCharacters,
};
