import { XML_UPDATE_SW_CMD, fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function fetchDbS55Load(url, user) {
  try {
    const sw_upgrade_cmd = XML_UPDATE_SW_CMD.DB_S55_LOAD;
    const response = await fetchXMLData({
      url,
      attributes: {
        action: XML_ACTION.UPDATE_SW,
        user: user.user,
        password: user.password,
        sw_upgrade_cmd
      }
    });
    if (response.return_code !== '0') {
      throw new Error();
    }
    return response;
  } catch (error) {
    throw new Error();
  }
}

export { fetchDbS55Load };
