import { AkioDefrostSchedules, Refrigerations } from '../SchedulesPage.types';

export const filterCaseLevelAkioDefrostSchedules = (
  refrigerations: Refrigerations[],
  scheduleType: string,
): AkioDefrostSchedules[] => {
  return refrigerations
    ?.find(refrigeration => refrigeration.id === scheduleType)
    ?.items?.find(item =>
      item?.items?.find(subItems => subItems?.akioDefrostSchedules),
    )
    ?.items?.find(item => item?.akioDefrostSchedules)?.akioDefrostSchedules;
};
