/**
 *
 *  used internally in schedule-override-types for unifing filtering for refrigerations, returns wrapped filterRefgirerationSchedules
 */

import { getArray } from 'utils';
import {
  Refrigerations,
  RefrigerationSchedules,
  ScheduleTypes,
} from '../SchedulesPage.types';
import { findSceduleDevices } from './findSceduleDevices';

export const applyFilter =
  (schedType: number[]) =>
  (data = [] as any[], ids: string, refrigerations, type) =>
    filterRefgirerationSchedules(data, ids, refrigerations, type, schedType);

export const filterRefgirerationSchedules = (
  data = [] as any[],
  schedulesIds: string,
  refrigerations: Refrigerations[] = [],
  type: ScheduleTypes,
  schedType: number[],
): RefrigerationSchedules[] => {
  const ids = schedulesIds?.split('-')?.filter(item => item.match(/^\d/));

  if (!ids?.length) return [];

  return getArray(data)
    .filter(item => schedType.includes(+item.schedules?.schedType))
    .filter(item => ids.some(subId => item.schedules?.id === subId))
    .map(item => {
      const scheduleDetails = getArray(item.schedules?.schedules?.sch_detail);
      const id = item.schedules?.id;

      const cases = findSceduleDevices(refrigerations, type, schedulesIds, [
        id,
      ]);

      return scheduleDetails.map(subitem => ({
        id,
        cases,
        name: item.name,
        usage: item.schedules?.usage,
        type: subitem.type,
        ...subitem,
      }));
    })
    .flat();
};
