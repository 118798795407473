import {
  ConfigurationListItem,
  ConfigurationTabItem,
  User,
} from '@danfoss/etui-sm-xml';
import React from 'react';
import { getIsAuthorized } from '../components/ConfigurationItemContentList/utils';
import { ConfigurationAuthContext } from '../context/ConfigurationAuthContext';

export function useConfigurationAuth(
  activeTab: ConfigurationTabItem,
  user: User,
) {
  const context = React.useContext(ConfigurationAuthContext);
  if (!context) {
    throw Error('Missing configuration auth context');
  }
  const { writeAccess } = context;
  const getItemIsAuthorized = (item: ConfigurationListItem) =>
    getIsAuthorized(activeTab, item, user, writeAccess);
  return { ...context, getItemIsAuthorized };
}
