import React from 'react';
import { useHistory } from 'react-router-dom';
import { FoodGroups } from '@danfoss/etui-sm-xml';
import { areObjectsEqual } from '../components/ConfigurationItemContentMenuDeviceSelect/utils/areObjectsEqual';
import { ConfigurationMenuContext } from './ConfigurationMenuContext';

export function ConfigurationMenuProvider({ children }) {
  const {
    location: { pathname },
  } = useHistory();
  const [foodGroup, setFoodGroup] = React.useState<FoodGroups>(null);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [searchMode, setSearchMode] = React.useState<boolean>(false);
  const [isSecurityEnforcementChanged, setIsSecurityEnforcementChanged] =
    React.useState<boolean>(false);

  const handleOnSetFoodGroup = (newFoodGroup: FoodGroups) => {
    if (newFoodGroup && !areObjectsEqual(foodGroup, newFoodGroup)) {
      setFoodGroup(newFoodGroup);
    }
  };

  const handleOnSetSearchValue = (value: string) => {
    setSearchValue(value.trim().toLowerCase());
    setSearchMode(true);
  };

  const handleOnSetSecurityEnforcementChanged = (value: boolean) => {
    setIsSecurityEnforcementChanged(value);
  };

  React.useEffect(() => {
    setSearchValue('');
    setSearchMode(false);
    setFoodGroup(null);
  }, [pathname]);

  return (
    <ConfigurationMenuContext.Provider
      value={{
        foodGroup,
        onSetFoodGroup: handleOnSetFoodGroup,
        searchMode,
        searchValue,
        onSetSearchValue: handleOnSetSearchValue,
        isSecurityEnforcementChanged,
        onSetSecurityEnforcementChanged: handleOnSetSecurityEnforcementChanged,
      }}
    >
      {children}
    </ConfigurationMenuContext.Provider>
  );
}
