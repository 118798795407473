import {
  User,
  getUnitUrl,
  Unit,
  fetchAlarmRefsByType,
} from '@danfoss/etui-sm-xml';
import { AlarmType } from '../constants';

export async function fetchAlarmRefsByUnit(
  url: string,
  alarmType: AlarmType,
  user: User,
  unit: Unit,
  skipSessionUpdate?: boolean,
) {
  try {
    const unitAlarmRefs = await fetchAlarmRefsByType(
      getUnitUrl(url, unit),
      alarmType,
      user,
      skipSessionUpdate,
    );
    return unitAlarmRefs.map(
      unitAlarmRef => `${unit.unit_addr}-${unitAlarmRef}`,
    );
  } catch (error) {
    return [] as string[];
  }
}
