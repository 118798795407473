function generateDynListString(dynListLabels) {
  let dynListStr = '';
  let i = 0;
  for (const dll of dynListLabels) {
    dynListStr += dll.name;
    dynListStr += ':';
    dynListStr += dll.value;
    if (i < dynListLabels.length - 1) {
      dynListStr += '; ';
    }
    i++;
  }
  return dynListStr;
}

export { generateDynListString };
