import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { CommandBar, icons, useResponsive } from '@danfoss/etui-core';
import { useHistory } from 'react-router-dom';
import { useFeatureFlag } from 'hooks/featurehub/FeatureHubContext';
import { QuickSetupTileButton } from './QuickSetupTileButton';

export function QuickSetupLayout({
  tilesSystemSetup,
  tilesRerigaration,
  tilesHVAC,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { push } = useHistory();
  const LayoutConfigurationEnabled = useFeatureFlag('LayoutConfiguration');

  const { screenIsAtMost } = useResponsive({
    sm: parseInt(theme.breakpoints[1], 10),
  });

  const getMenuItems = () => {
    const items = [];
    const isSmView = screenIsAtMost('sm', ['portrait', 'landscape']);

    if (isSmView) {
      items.push({
        key: 'back',
        iconProps: { glyph: icons.CHEVRON_DOWN, rotate: 90, key: 'back' },
        onClick: () => {
          push({
            pathname: '/configuration',
            state: { usedBackButton: true },
          });
        },
        iconOnly: true,
        styles: {
          root: {
            flexShrink: 0,
            width: 50,
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderRight: `1px solid ${theme.palette.divider}`,
          },
        },
      });
    }

    items.push({
      key: 'quickSetup',
      onRender: () => (
        <Div
          key="quickSetup"
          fontWeight={theme.typography.fontWeightBold}
          color={theme.palette.primary.main}
          height={50}
          lineHeight="50px"
          width="100%"
          position="relative"
          overflow="hidden"
          borderBottom="1px solid #303844"
          p={`0 ${theme.spacing.sm}px`}
          whiteSpace="nowrap"
          fontSize="14px"
        >
          {t('t460')}
        </Div>
      ),
    });

    return items;
  };

  return (
    <>
      <CommandBar
        items={getMenuItems()}
        styles={{
          root: { position: 'absolute', width: '100%' },
        }}
      />
      <>
        <Div
          fontWeight={theme.typography.fontWeightBold}
          color={theme.palette.primary.main}
          mt="75px"
          ml="50px"
        >
          {t('t502')}
        </Div>
        <Div mt="20px" ml="50px" display="flex" flexWrap="wrap">
          {tilesSystemSetup.map(({ text, icon, action }) => (
            <Div mr="42px" mb="42px" key={text}>
              <QuickSetupTileButton
                title={text}
                icon={icon}
                onClick={action}
                m="0"
              />
            </Div>
          ))}
        </Div>
      </>

      {LayoutConfigurationEnabled && (
        <>
          <Div
            fontWeight={theme.typography.fontWeightBold}
            color={theme.palette.primary.main}
            ml="50px"
          >
            {t('t150')}
          </Div>
          <Div mt="20px" ml="50px" display="flex" flexWrap="wrap">
            {tilesRerigaration.map(({ text, icon, action }) => (
              <Div mr="42px" mb="42px" key={text}>
                <QuickSetupTileButton
                  title={text}
                  icon={icon}
                  onClick={action}
                  m="0"
                />
              </Div>
            ))}
          </Div>

          <Div
            fontWeight={theme.typography.fontWeightBold}
            color={theme.palette.primary.main}
            ml="50px"
          >
            {t('t87')}
          </Div>
          <Div mt="20px" ml="50px" display="flex" flexWrap="wrap">
            {tilesHVAC.map(({ text, icon, action }) => (
              <Div mr="42px" mb="42px" key={text}>
                <QuickSetupTileButton
                  title={text}
                  icon={icon}
                  onClick={action}
                  m="0"
                />
              </Div>
            ))}
          </Div>
        </>
      )}
    </>
  );
}
