import {
  ConfigurationListDataArray,
  ConfigurationListDataObject,
  ConfigurationListItem,
} from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import {
  SourceGraphTableContent,
  SourceGraphTableData,
  SourceGraphTablePageContent,
} from '../../../types/RefrigCopy.types';
import { AllPageType } from '../../../context';

export const processSourceGraphTableData = (
  tableContentData: ConfigurationListItem[],
  logList: ConfigurationListDataArray | ConfigurationListDataObject,
  page: string,
  t: TFunction,
) => {
  const sourceGraphTableContentList: SourceGraphTableContent[] = [];
  tableContentData?.forEach((data, index) => {
    const names: string[] = data?.name?.split(':');
    if (names.length === 1) {
      return;
    }
    const point = names[1]?.trim();
    const logListData =
      (data?.listdata as ConfigurationListDataArray) ||
      (logList as ConfigurationListDataArray);
    const sourceGraphTableContent: SourceGraphTableContent = {
      point,
      haccp: getHaccpValue(tableContentData[index + 1], t),
      log: data?.value,
      logList: getLogList(logListData),
      page,
      li: data?.li,
      isLogSubmitting: false,
      isLogSucceeded: false,
      isLogFailed: false,
      isHaccpSubmitting: false,
      isHaccpSucceeded: false,
      isHaccpFailed: false,
    };
    sourceGraphTableContentList.push(sourceGraphTableContent);
  });
  return sourceGraphTableContentList;
};

const getHaccpValue = (
  nextTableContentData: ConfigurationListItem,
  t: TFunction,
) => {
  return nextTableContentData?.value !== t('t3416');
};

const getLogList = (logListData: ConfigurationListDataArray) => {
  return logListData?.map(value => ({
    value: value?.datavalue,
    label: value?._,
  }));
};

export const filterSourceGraphTableContentList = (
  allPageData: AllPageType[],
  searchValue: string,
) => {
  const filteredSourceGraphTableContentList = (
    allPageData as SourceGraphTableContent[]
  )?.filter(content => {
    const point = content.point.toLowerCase();
    const value = searchValue.toLowerCase();
    return point.includes(value);
  });
  return filteredSourceGraphTableContentList;
};

export const findEditedListItem = (
  content: SourceGraphTableContent,
  sourceGraphListItems: ConfigurationListItem[],
) => {
  return sourceGraphListItems?.find(item => {
    return (
      item.name.includes(content.point) &&
      item.page === content.page &&
      item.li === content.li
    );
  });
};

export const findEditedHaccpListItem = (
  content: SourceGraphTableContent,
  sourceGraphListItems: ConfigurationListItem[],
) => {
  const index: number = sourceGraphListItems?.findIndex(item => {
    return (
      item.name.includes(content.point) &&
      item.page === content.page &&
      item.li === content.li
    );
  });
  return sourceGraphListItems[index + 1];
};

export const addPageToListItems = (
  listItems: ConfigurationListItem[],
  page: string,
) => {
  listItems?.forEach((_, index) => {
    listItems[index].page = page;
  });
  return listItems;
};

export const checkIfPageContentNotAvailable = (
  sourceGraphTablePageContent: SourceGraphTablePageContent[],
  page: string,
) => {
  const filteredLength: number = sourceGraphTablePageContent?.filter(
    pageContent => pageContent.page === page,
  )?.length;
  return filteredLength === 0;
};

export const findCurrentPageContent = (
  sourceGraphTablePageContent: SourceGraphTablePageContent[],
  page: string,
) => {
  return sourceGraphTablePageContent?.find(pageContent => {
    return pageContent.page === page;
  });
};

export const setLogStatus = (
  sourceGraphTableData: SourceGraphTableData,
  selectedRowIndex: number,
  submitting: boolean,
  succeeded: boolean,
  failed: boolean,
  value?: string,
) => {
  if (value) {
    sourceGraphTableData.sourceGraphTableContent[selectedRowIndex].log = value;
  }
  sourceGraphTableData.sourceGraphTableContent[
    selectedRowIndex
  ].isLogSubmitting = submitting;
  sourceGraphTableData.sourceGraphTableContent[
    selectedRowIndex
  ].isLogSucceeded = succeeded;
  sourceGraphTableData.sourceGraphTableContent[selectedRowIndex].isLogFailed =
    failed;
};

export const setHaccpStatus = (
  sourceGraphTableData: SourceGraphTableData,
  selectedRowIndex: number,
  submitting: boolean,
  succeeded: boolean,
  failed: boolean,
  value?: boolean,
) => {
  if (value !== undefined) {
    sourceGraphTableData.sourceGraphTableContent[selectedRowIndex].haccp =
      value;
  }
  sourceGraphTableData.sourceGraphTableContent[
    selectedRowIndex
  ].isHaccpSubmitting = submitting;
  sourceGraphTableData.sourceGraphTableContent[
    selectedRowIndex
  ].isHaccpSucceeded = succeeded;
  sourceGraphTableData.sourceGraphTableContent[selectedRowIndex].isHaccpFailed =
    failed;
};
