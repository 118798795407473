import '../constants/session-storage-constants.js';
import '../constants/xml-action-constant.js';
import '../constants/xml-tag-constant.js';
import '../types/Auth.js';
import '../types/Metadata.js';
import '../_rollupPluginBabelHelpers-10728f69.js';
import 'xml2js';
import '../helpers/xml-helpers.js';
import '../helpers/session-token-helpers.js';
import '../helpers/trim-url-ending.js';
import '../helpers/metadata-helpers.js';
import '../helpers/header-helpers.js';
import '../helpers/is-allowed-server-error.js';
import '../helpers/proxy-helpers.js';
import '../helpers/xml-error.js';
import '../proxy-config.js';
import './fetch-xml.js';
import { fetchXMLFile } from './fetch-xml-file.js';
import { fetchXMLFileName } from './fetch-xml-file-name.js';

async function fetchXMLFileData(_ref) {
  let {
    url,
    action,
    user,
    password,
    lang,
    offset = 0,
    backup,
    swCmd,
    attributes,
    items = [],
    decodeFile = false
  } = _ref;
  const {
    filename,
    filepath,
    access_area
  } = await fetchXMLFileName({
    url,
    user,
    password,
    lang,
    action,
    backup,
    swCmd,
    attributes,
    items
  });
  const response = await fetchXMLFile({
    url,
    user,
    password,
    filename,
    filepath: access_area || filepath,
    offset,
    done: 0,
    fileStream: '',
    decodeFile,
    directoryAttribute: access_area ? 'access_area' : 'directory'
  });
  if (response) {
    return {
      fileName: filename,
      fileContent: response
    };
  }
  return Promise.reject(new Error('Uncaught error'));
}

export { fetchXMLFileData };
