import { useQuery } from 'hooks';
import { useLocation } from 'react-router-dom';
import { b64UriToObj } from 'utils/b64-object-serialisation';
import { useXmlResource, useApp } from '@danfoss/etui-sm/context';
import React from 'react';
import { isElectron } from '../../../config';

export function useLoginParams() {
  const [paramIp, setParamIp] = React.useState('');
  const [paramUser, setParamUser] = React.useState('');
  const [paramPassword, setParamPassword] = React.useState('');

  const [{ proxy }, dispatch] = useApp();
  const { ip: xmlBackendIp, setIp } = useXmlResource();
  const query = useQuery();
  const location = useLocation<{ prevPathName: string; prevSearch: string }>();

  /**
   * Login information about user that is kept in login= url query param
   * which is a url encoded base 64 string of JSON {user: ..., IP: ...}
   */
  const loginUrlInfo = React.useRef(location.state?.prevSearch || '');
  const {
    IP: ipFromUrl,
    user: userNameFromUrl,
    password: passwordFromUrl,
  } = b64UriToObj(new URLSearchParams(loginUrlInfo.current).get('login') || '');
  /**
   * Login information about proxy that is kept in proxy= url query param
   * which is a url encoded base 64 string of JSON {proxyAddrr: ..., ipAddress: ..., token: ...}
   */
  const proxyAsObject = b64UriToObj(
    new URLSearchParams(loginUrlInfo.current).get('proxy') || '',
  );
  const { ipAddress: ipFromProxy, port } = proxyAsObject;
  const proxyString = proxyAsObject ? JSON.stringify(proxyAsObject) : '{}';

  React.useEffect(() => {
    dispatch({
      type: 'SET_PROXY',
      payload: proxyString,
    });
  }, [proxyString]);

  const fullIpFromProxy =
    proxy?.proxyAddress &&
    ipFromProxy &&
    ipFromProxy + (port ? `:${port}` : '');

  const user = userNameFromUrl || query.get('user') || '';
  const password = passwordFromUrl || query.get('password');
  const ip: string = ipFromUrl || fullIpFromProxy || query.get('ip');

  if (ip && ip !== xmlBackendIp && !isElectron) {
    setIp(ip);
  }

  React.useEffect(() => {
    ip && setParamIp(ip);
    password && setParamPassword(password);
    user && setParamUser(user);
  }, [ip, password, user]);

  return {
    paramUser,
    paramIp,
    paramPassword,
    paramProxyConfig: proxy,
  };
}
