import React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { DiscoveryPageData } from '../../types/DiscoveryPage.types';
import { LayoutFlowTitle } from '../LayoutContent';
import { DiscoveryFlowLegendActions, DiscoveryFlowTable } from '.';

interface DiscoveryFlowPageProps {
  handleOfflineProgramming: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onRescanComplete: (rescannedPageData: DiscoveryPageData) => void;
  handleOnRefresh: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  tableDataLoading: boolean;
}
export const DiscoveryFlowPage = ({
  handleOfflineProgramming,
  onRescanComplete,
  handleOnRefresh,
  tableDataLoading,
}: DiscoveryFlowPageProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Div mt="10px">
        <LayoutFlowTitle title={t('t3343')} subTitle={t('t3352')} />
        <DiscoveryFlowLegendActions
          handleOfflineProgramming={handleOfflineProgramming}
          handleOnRefresh={handleOnRefresh}
          onRescanComplete={onRescanComplete}
        />
      </Div>
      <DiscoveryFlowTable tableDataLoading={tableDataLoading} />
    </>
  );
};
