import { styled } from '@danfoss/etui-system';
import { Div, DivProps } from '@danfoss/etui-system-elements';

type DropdownMenuDivProps = DivProps & { isVisible: boolean };

export const DropdownMenuDiv = styled(Div).attrs(({ theme, ...props }) => ({
  boxShadow: theme.shadows[3],
  border: `1px solid ${theme.palette.divider}`,
  p: `${theme.spacing.xxs}px 0`,
  ...props,
}))<DropdownMenuDivProps>`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  background-color: white;
  border-radius: 2px;
  min-width: 180px;
`;
