import { Icon, IconProps, icons, H3 } from '@danfoss/etui-core';
import { Span } from '@danfoss/etui-system-elements';
import styled from 'styled-components';

type H3Props = Parameters<typeof H3>[0];

export const Main = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) =>
    `radial-gradient(${theme.palette.brand.light} 65%,#4c0209 135%)`};
`;

export const Text = styled(Span)`
  margin-bottom: 1em;
  color: white;
`;

export const Title = styled(H3).attrs(
  () =>
    ({
      color: 'white',
      textAlign: 'center',
      testId: 'header',
      letterSpacing: '1px',
      fontWeight: 700,
      mb: '1em',
    } as H3Props),
)`
  color: white;
`;

export const DanfossIcon = styled(Icon).attrs(
  () =>
    ({
      glyph: icons.DANFOSS,
      viewBox: '20 -100 350 350',
      size: 208,
      styles: {
        root: {
          color: 'white',
        },
      },
    } as IconProps),
)``;
