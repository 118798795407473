import { D as DeviceCategory, b as DeviceSpecificTag, S as StorageType, A as Ak2DataType, E as EdfParameterUnitType, M as MessageType, d as MultiTags } from '../../../DeviceIntegrationPage.types-1a4ac020.js';

function parseEDF(fileContent) {
  const data = {
    parseError: false,
    parseErrorMessage: '',
    parameters: [],
    alarms: [],
    dynlists: [],
    groups: [],
    basicInfo: {
      model: '',
      description: '',
      deviceCategory: {
        value: 0,
        label: ''
      },
      version: ''
    }
  };
  try {
    // File Layout type
    const ftidx = fileContent.indexOf('<EDF_FILE_LAYOUT_TYPE>');
    const fileType = fileContent.substring(ftidx + 22, ftidx + 24).trim();
    if (fileType !== '3') {
      data.parameters = [];
      data.alarms = [];
      data.groups = [];
      data.dynlists = [];
      data.parseError = true;
      data.parseErrorMessage = 'Unsupported EDF Layout type';
      return data;
    }

    // Basic info section
    let basicInfoSection = '';
    const bsidx = fileContent.indexOf('<BASICINFO_SECTION_START>');
    const beidx = fileContent.indexOf('<BASICINFO_SECTION_END>');
    if (bsidx !== -1 && beidx !== -1 && beidx > bsidx + 25) {
      basicInfoSection = fileContent.substring(bsidx + 25, beidx);
      const basicinfolines = basicInfoSection.split('\n');
      // Remove empty elements form array:
      const fbasicinfolines = basicinfolines.filter(el => {
        return el.trim() !== '';
      });
      for (const bInfo of fbasicinfolines) {
        let bidx = bInfo.indexOf('<MODEL>');
        if (bidx + 1) {
          data.basicInfo.model = bInfo.substring(bidx + 7, bInfo.length).trim();
        }
        bidx = bInfo.indexOf('<INFO>');
        if (bidx + 1) {
          data.basicInfo.description = bInfo.substring(bidx + 7, bInfo.length);
        }
        bidx = bInfo.indexOf('<CATEGORY>');
        if (bidx + 1) {
          const devGroupVal = +bInfo.substring(bidx + 11, bInfo.length);
          data.basicInfo.deviceCategory.value = devGroupVal;
          data.basicInfo.deviceCategory.label = DeviceCategory[devGroupVal];
        }
        bidx = bInfo.indexOf('<VERSION>');
        if (bidx + 1) {
          data.basicInfo.version = bInfo.substring(bidx + 9, bInfo.length).trim();
        }
      }
    }

    // Group Section Start
    let groupSection = '';
    const gsidx = fileContent.indexOf('<GROUPTEXT_SECTION_START>');
    const geidx = fileContent.indexOf('<GROUPTEXT_SECTION_END>');
    if (gsidx !== -1 && geidx !== -1 && geidx > gsidx + 25) {
      groupSection = fileContent.substring(gsidx + 25, geidx);
      const grouplines = groupSection.split('\n');
      // Remove empty elements from array:
      const fgrouplines = grouplines.filter(el => {
        return el.trim() !== '';
      });
      // The first line should contain the number of dynLists
      const nGroups = parseInt(fgrouplines[0], 10);
      for (let gidx = 0; gidx < nGroups; gidx++) {
        if (fgrouplines[1 + gidx].includes('Not used')) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const fgroups = fgrouplines[1 + gidx].split(',');
        const g = {
          invisible: false,
          name: ''
        };
        g.invisible = fgroups[1].split('|')[0].trim() === '1';
        g.name = fgroups[1].split('|')[1].trim();
        data.groups.push(g);
      }
    }

    // Device Specific Section
    const parameterTags = new Map();
    let deviceSpecificSection = '';
    const dsidx = fileContent.indexOf('<DEVICE_SPECIFIC_START>');
    const deidx = fileContent.indexOf('<DEVICE_SPECIFIC_END>');
    if (dsidx !== -1 && deidx !== -1 && deidx > dsidx + 23) {
      deviceSpecificSection = fileContent.substring(dsidx + 23, deidx);
      const devicelines = deviceSpecificSection.split('\n');
      // Remove empty elements from array:
      const fdevicelines = devicelines.filter(el => {
        return el.trim() !== '';
      });
      for (let dsi = 0; dsi < fdevicelines.length; dsi++) {
        const tag_id = parseInt(fdevicelines[dsi].substring(1, 5), 10);
        if (tag_id in DeviceSpecificTag) {
          const params = fdevicelines[dsi].trim().split(' ');
          if (params.length === 2) {
            // If there is only two entries there is no size indication
            const key = parseInt(params[1].trim(), 10);
            const value = {
              value: tag_id,
              label: DeviceSpecificTag[tag_id]
            };
            let tags = parameterTags.get(key);
            if (!tags) {
              tags = [];
            }
            tags.push(value);
            parameterTags.set(key, tags);
          } else {
            // Fist entry specifies list size
            const nparams = parseInt(params[1].trim(), 10);
            for (let pi = 2; pi < nparams + 2; pi++) {
              const key = parseInt(params[pi].trim(), 10);
              const value = {
                value: tag_id,
                label: DeviceSpecificTag[tag_id]
              };
              let tags = parameterTags.get(key);
              if (!tags) {
                tags = [];
              }
              tags.push(value);
              parameterTags.set(key, tags);
            }
          }
        }
      }
    }

    // Parameter Section
    let parameterSection = '';
    const psidx = fileContent.indexOf('<PARAMETER_SECTION_START>');
    const peidx = fileContent.indexOf('<PARAMETER_SECTION_END>');
    if (psidx !== -1 && peidx !== -1 && peidx > psidx + 25) {
      parameterSection = fileContent.substring(psidx + 25, peidx);
      const paramlines = parameterSection.split('\n');
      // Remove empty elements from array:
      const fparamlines = paramlines.filter(el => {
        return el.trim() !== '';
      });
      // The first line should contain the number of parameters twice, followed by 1
      const psizeInfo = fparamlines[0].split(',');
      const nParam = parseInt(psizeInfo[0], 10);
      if (nParam !== parseInt(psizeInfo[1], 10) || parseInt(psizeInfo[2], 10) !== 1) {
        data.parameters = [];
        data.alarms = [];
        data.groups = [];
        data.dynlists = [];
        data.parseErrorMessage = 'File format error: Invalid parameter size';
        data.parseError = true;
        return data;
      }
      for (let pidx = 0; pidx < nParam; pidx++) {
        const fparams = fparamlines[1 + pidx].split(',');
        if (fparams.length < 15) {
          data.parameters = [];
          data.alarms = [];
          data.groups = [];
          data.dynlists = [];
          data.parseErrorMessage = 'File format error: Invalid Parameter entry';
          data.parseError = true;
          return data;
        }
        const p = {
          storageType: {
            value: 0,
            label: ''
          },
          unit: {
            value: 0,
            label: ''
          },
          conversion: {
            value: 0,
            label: ''
          },
          messageType: {
            value: 0,
            label: ''
          },
          swapMode: {
            value: 0,
            label: ''
          },
          dynlistIndex: {
            value: '',
            label: ''
          },
          groupIndex: {
            value: '',
            label: ''
          }
        };
        // (6) Parameter number for the parameter refers to coil number or register number
        p.unit.value = +fparams[1].trim();
        p.logEnabled = fparams[2].trim() === '6';
        const dlIndex = Number(fparams[3].trim()) - 1;
        if (dlIndex >= 0) {
          p.dynlistIndex.value = dlIndex.toString();
        }
        p.address = +fparams[6].trim();
        p.min = +fparams[10].trim();
        p.max = +fparams[11].trim();
        p.def = +fparams[9].trim();
        p.groupIndex.value = fparams[12].trim();
        const fpa = fparams[14].split('|');
        p.description = fpa[1].trim();
        p.readonly = fpa[0].trim() === 'R'; // TODO: check for other parameters
        p.conversion.value = +fpa[2].trim();
        p.swapMode.value = +fparams[15].trim();
        p.messageType.value = +fparams[16].trim();
        p.deviceSpecificTags = parameterTags.get(pidx + 1);
        switch (fparams[7].trim()) {
          case Ak2DataType.AK2_BOOL:
            p.storageType.value = StorageType.U1;
            p.storageType.label = 'U1';
            break;
          case Ak2DataType.AK2_FLOAT:
            p.storageType.value = StorageType.F16;
            p.storageType.label = 'F16';
            break;
          case Ak2DataType.AK2_IEEE754:
            p.storageType.value = StorageType.F32;
            p.storageType.label = 'F32';
            break;
          case Ak2DataType.AK2_INT16:
            p.storageType.value = StorageType.S16;
            p.storageType.label = 'S16';
            break;
          case Ak2DataType.AK2_INT32:
            p.storageType.value = StorageType.S32;
            p.storageType.label = 'S32';
            break;
          case Ak2DataType.AK2_UINT16:
            p.storageType.value = StorageType.U16;
            p.storageType.label = 'U16';
            break;
          case Ak2DataType.AK2_UINT32:
            p.storageType.value = StorageType.U32;
            p.storageType.label = 'U32';
            break;
          default:
            p.storageType.value = StorageType.U16;
            p.storageType.label = 'U16';
            break;
        }
        data.parameters.push(p);
      }
    } else {
      data.parameters = [];
      data.alarms = [];
      data.groups = [];
      data.dynlists = [];
      data.parseErrorMessage = 'File format error: Parameter Section not found.';
      data.parseError = true;
      return data;
    }

    // Alarm Section
    let alarmSection = '';
    const asidx = fileContent.indexOf('<ALARM_SECTION_START>');
    const aeidx = fileContent.indexOf('<ALARM_SECTION_END>');
    if (asidx !== -1 && aeidx !== -1 && aeidx > asidx + 21) {
      alarmSection = fileContent.substring(asidx + 21, aeidx);
      const alarmlines = alarmSection.split('\n');
      // Remove empty elements from array:
      const falarmlines = alarmlines.filter(el => {
        return el.trim() !== '';
      });
      // The first line should contain the number of parameters twice, followed by 1
      const nAlarm = parseInt(falarmlines[0], 10);
      for (let aidx = 0; aidx < nAlarm; aidx++) {
        var _data$parameters$para, _data$parameters$para2;
        const falarms = falarmlines[1 + aidx].split(',');
        if (falarms.length < 4) {
          data.parameters = [];
          data.alarms = [];
          data.groups = [];
          data.dynlists = [];
          data.parseErrorMessage = 'File format error: Invalid Alarm entry';
          data.parseError = true;
          return data;
        }
        const a = {
          bit: {
            value: '',
            label: ''
          }
        };
        const paramIdx = parseInt(falarms[2].trim(), 10) - 1;
        // Check if parameter exists and create item
        if (paramIdx > 0 && paramIdx < data.parameters.length + 1) {
          a.parameterIdx = {
            value: paramIdx.toString(),
            label: data.parameters[paramIdx].description
          };
        }
        let availableBits = 1;
        switch ((_data$parameters$para = data.parameters[paramIdx]) === null || _data$parameters$para === void 0 ? void 0 : (_data$parameters$para2 = _data$parameters$para.storageType) === null || _data$parameters$para2 === void 0 ? void 0 : _data$parameters$para2.label) {
          case 'S16':
          case 'U16':
            availableBits = 16;
            break;
          case 'S32':
          case 'U32':
            availableBits = 32;
            break;
          case 'U1':
            availableBits = 1;
            break;
          default:
        }
        a.bit.value = Math.min(parseInt(falarms[3].trim(), 10), availableBits - 1).toString();
        a.negated = falarms[4].trim() === '1';
        a.name = falarms[5].split('|')[1].trim();
        data.alarms.push(a);
      }
    }

    // DynList Section
    let dynListSection = '';
    const dlsidx = fileContent.indexOf('<DYN_LIST_START>');
    const dleidx = fileContent.indexOf('<DYN_LIST_END>');
    if (dlsidx !== -1 && dleidx !== -1 && dleidx > dlsidx + 16) {
      dynListSection = fileContent.substring(dlsidx + 16, dleidx);
      const dynListlines = dynListSection.split('\n');
      // Remove empty elements from array:
      const fdynListlines = dynListlines.filter(el => {
        return el.trim() !== '';
      });
      // The first line should contain the number of dynLists
      const nDynList = parseInt(fdynListlines[0], 10);
      for (let dlidx = 0; dlidx < nDynList; dlidx++) {
        // There should be 3 entries followed by key value pairs: 0x,e,Dyn_0x,X,NameX
        const numberDelimiters = (fdynListlines[1 + dlidx].match(/,/g) || []).length;
        if (numberDelimiters < 4) {
          data.parameters = [];
          data.alarms = [];
          data.groups = [];
          data.dynlists = [];
          data.parseErrorMessage = 'File format error: Invalid DynList Entry';
          data.parseError = true;
          return data;
        }
        const dl = {
          name: '',
          list: ''
        };
        const delimiter = ',';
        const lineTokens = fdynListlines[1 + dlidx].split(delimiter);

        // DynList Name
        dl.name = lineTokens[2].trim();

        // The first item contains the number of labels
        // TODO: Use label length for validation
        // const nLabels = lineTokens[3].trim();

        // DnyList Value (Starting from 5th list entry)
        const listStartIdx = 4;
        let liststr = '';
        for (let listindex = listStartIdx; listindex < numberDelimiters + 1; listindex++) {
          liststr += lineTokens[listindex];
          if (listindex % 2 === 0) {
            liststr += ':';
          } else {
            liststr += '; ';
          }
        }
        // Remove last semicolon
        liststr = liststr.slice(0, -2);
        dl.list = liststr;
        data.dynlists.push(dl);
      }
    }
  } catch (err) {
    // error handling

    data.parameters = [];
    data.alarms = [];
    data.groups = [];
    data.dynlists = [];
    data.parseErrorMessage = 'File format error: Internal Error';
    data.parseError = true;
  }
  return data;
}
function generateEDF(data, deviceCategory) {
  const parameters = data.parameters || [];
  const alarms = data.alarms || [];
  const dynlists = data.dynlists || [];
  const groups = data.groups || [];
  const {
    description,
    model,
    version
  } = data.basicInfo;
  let edf = '';
  let i;

  // remove parameters with no address from the parameter array
  for (i = 0; i < parameters.length; i++) {
    if (parameters[i].address === undefined) {
      parameters.splice(i, 1);
      i--;
    }
  }

  // File Version Info
  edf += '<EDF_FILE_VERSION> 10\n';
  edf += '<EDF_FILE_LAYOUT_TYPE> 3\n';
  edf += '\n';

  // Basicinfo section
  edf += '<BASE_DESCRIPTION_START>\n';
  edf += '\n';
  edf += '<BASICINFO_SECTION_START>\n';
  edf += '<MODEL> ';
  edf += model;
  edf += '\n';
  edf += '<NAME> ';
  edf += data.fileName;
  edf += '\n';
  edf += '<BOARD_TYPE> 86\n'; // 3rd party devices always have board type 86
  edf += `<VERSION> ${version || '1'}\n`;
  edf += `<INFO> ${description}\n`;
  edf += `<CATEGORY> ${deviceCategory ? deviceCategory.value.toString() : '0'}\n`;
  edf += '<SUBCATEGORY> 0\n';
  edf += '<SER> 0\n';
  edf += '<BASICINFO_SECTION_END>\n';
  edf += '\n';

  // Group Section
  edf += '<GROUPTEXT_SECTION_START>\n';
  edf += `${groups.length}\n`;
  for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
    edf += `${(groupIndex + 1).toString().padStart(2, '0')},\t`; // group number
    edf += groups[groupIndex].invisible ? '1| ' : '0| ';
    edf += groups[groupIndex].name;
    edf += '\n';
  }
  edf += '<GROUPTEXT_SECTION_END>\n';
  edf += '\n';

  // Parameter Section
  const deviceTags = new Map();
  edf += 'FPN  U  X  L scaling  CID   VID m 2          def          min          max gp exp RW name\n';
  edf += '<PARAMETER_SECTION_START>\n';
  // The first line defines the number of parameters in the list,
  // for compatible reasons the number is to be written twice followed by 1
  edf += `${parameters.length},${parameters.length},1\n`;
  for (i = 0; i < parameters.length; i++) {
    var _parameters$i$dynlist;
    // (0) Parameter number that can be used as reference in the system
    edf += `${(i + 1).toString().padStart(3, '0')},\t`;
    // (1) Unit
    edf += parameters[i].unit ? `${parameters[i].unit.value.toString()},\t` : `${EdfParameterUnitType.BLANK.toString()},\t`;
    // (2) Enable / History Flags -> Enabled
    edf += parameters[i].logEnabled ? '6,\t' : '0,\t';
    // Dynamic list -> N/A
    if (((_parameters$i$dynlist = parameters[i].dynlistIndex) === null || _parameters$i$dynlist === void 0 ? void 0 : _parameters$i$dynlist.value) !== '') {
      edf += parameters[i].dynlistIndex ? `${(Number(parameters[i].dynlistIndex.value) + 1).toString()},\t` : '0,\t';
    } else {
      edf += '0,\t';
    }
    // (4) TODO: Scaling
    edf += '0,\t';
    // (5) CID
    edf += '0,\t';
    // (6) VID: Parameter number for the parameter refers to coil number or register number
    edf += parameters[i].address ? `${parameters[i].address.toString()},\t` : '0,\t';
    // (7), (8) Data Type
    if (parameters[i].storageType != null) {
      switch (parameters[i].storageType.value) {
        case StorageType.U1:
          edf += `${Ak2DataType.AK2_BOOL},\t`;
          edf += `${Ak2DataType.AK2_BOOL},\t`;
          break;
        case StorageType.F16:
          edf += `${Ak2DataType.AK2_FLOAT},\t`;
          edf += `${Ak2DataType.AK2_FLOAT},\t`;
          break;
        case StorageType.F32:
          edf += `${Ak2DataType.AK2_IEEE754},\t`;
          edf += `${Ak2DataType.AK2_FLOAT},\t`;
          break;
        case StorageType.S16:
          edf += `${Ak2DataType.AK2_INT16},\t`;
          edf += `${Ak2DataType.AK2_INT16},\t`;
          break;
        case StorageType.S32:
          edf += `${Ak2DataType.AK2_INT32},\t`;
          edf += `${Ak2DataType.AK2_INT32},\t`;
          break;
        case StorageType.U16:
          edf += `${Ak2DataType.AK2_UINT16},\t`;
          edf += `${Ak2DataType.AK2_UINT16},\t`;
          break;
        case StorageType.U32:
          edf += `${Ak2DataType.AK2_UINT32},\t`;
          edf += `${Ak2DataType.AK2_UINT32},\t`;
          break;
        default:
          edf += `${Ak2DataType.AK2_UINT16},\t`;
          edf += `${Ak2DataType.AK2_UINT16},\t`;
      }
    } else {
      edf += `${Ak2DataType.AK2_UINT16},\t`;
      edf += `${Ak2DataType.AK2_UINT16},\t`;
    }
    // (9) Default Value
    edf += parameters[i].def != null ? `${parameters[i].def.toString()},\t` : '0,\t';
    // (10) Minimum Value
    edf += parameters[i].min != null ? `${parameters[i].min.toString()},\t` : '-100,\t';
    // (11) Maximum Value
    edf += parameters[i].max ? `${parameters[i].max.toString()},\t` : '100,\t';
    // (12) Group reference
    edf += parameters[i].groupIndex ? `${parameters[i].groupIndex.value},\t` : '0,\t';
    // (13) EXP – defines how the parameter is to be scaled and represented.
    edf += '1,\t';
    // (14) Read write attribute
    edf += parameters[i].readonly ? 'R| ' : 'W| ';
    // (15) Name
    edf += parameters[i].description || '';
    // (16) Read Conversion
    edf += parameters[i].conversion ? `| ${parameters[i].conversion.value}, \t` : '| 0, \t';
    // (17) Write Swap Mode
    edf += parameters[i].swapMode ? `${parameters[i].swapMode.value}, \t` : '0, \t';
    // (18) Exact read message
    edf += parameters[i].messageType ? `${parameters[i].messageType.value}, \t` : '0, \t';
    // (19) Exact write message
    if (parameters[i].messageType.value === MessageType.COILS) edf += '5';else edf += '6';
    edf += '\n';

    // Update device specific tags
    const tags = parameters[i].deviceSpecificTags;
    if (tags) {
      for (const tag of tags) {
        const tag_id = tag.value;
        let params = deviceTags.get(tag_id);
        if (!params) {
          params = [];
        }
        params.push(i + 1);
        deviceTags.set(tag_id, params);
      }
    }
  }
  edf += '<PARAMETER_SECTION_END>\n';

  // Alarms section
  if (alarms.length !== 0) {
    edf += '\n';
    edf += '<ALARM_SECTION_START>\n';
    edf += `${alarms.length}\n`;
    for (let alarmIndex = 0; alarmIndex < alarms.length; alarmIndex++) {
      var _alarm$parameterIdx;
      const alarm = alarms[alarmIndex];
      edf += `${(alarmIndex + 1).toString().padStart(3, '0')},\t`; // alarm number
      edf += `${0},\t`; // not used
      edf += (_alarm$parameterIdx = alarm.parameterIdx) !== null && _alarm$parameterIdx !== void 0 && _alarm$parameterIdx.value ? `${(Number(alarm.parameterIdx.value) + 1).toString()},\t` : '0,\t'; // parameter reference
      edf += alarm.bit ? `${alarm.bit.value.toString()},\t` : '0,\t';
      edf += alarm.negated ? '1,\t' : '0,\t';
      edf += '0|';
      edf += alarm.name ? alarm.name : `Alarm ${alarmIndex}`;
      edf += '\n';
    }
    edf += '<ALARM_SECTION_END>\n';
  }

  // DynList section
  if (dynlists.length !== 0) {
    edf += '\n';
    edf += '<DYN_LIST_START>\n';
    edf += `${dynlists.length}\n`;
    for (let dynlistIndex = 0; dynlistIndex < dynlists.length; dynlistIndex++) {
      const dynlist = dynlists[dynlistIndex];
      if (dynlist) {
        edf += `${(dynlistIndex + 1).toString().padStart(2, '0')},`; // Dynlist number
        edf += `e,`; // TODO: ALways e?
        edf += dynlist.name ? `${dynlist.name},` : `Dyn_${(dynlistIndex + 1).toString().padStart(2, '0')},`; // DynListName
        const numberDelimiters = (dynlist.list.match(/;/g) || []).length;
        edf += `${(numberDelimiters + 1).toString()},`;
        if (dynlist.list) {
          let list = dynlist.list.replace(/:|; /g, ',');
          list = list.trim();
          edf += list;
          edf += '\n';
        } else {
          edf += '\n';
        }
      }
    }
    edf += '<DYN_LIST_END>\n';
  }

  // Device Specific section
  if (deviceTags.size !== 0) {
    edf += '\n';
    edf += '<DEVICE_SPECIFIC_START>\n';
    for (const tag_id of deviceTags.keys()) {
      const params = deviceTags.get(tag_id);
      params.sort((a, b) => a - b);
      edf += `<${tag_id.toString().padStart(4, '0')}_${DeviceSpecificTag[tag_id]}> `;
      if (MultiTags.includes(DeviceSpecificTag[tag_id])) edf += `${params.length} `;
      for (const p of params) {
        edf += `${p.toString()} `;
      }
      edf += '\n';
    }
    edf += '<DEVICE_SPECIFIC_END>\n';
  }
  edf += '\n';
  edf += '<BASE_DESCRIPTION_END>\n';
  edf += '\n';
  edf += '<EDF_EOF> ------------\n';

  // Write as file
  const textToWrite = edf.replace(/\n/g, '\r\n'); // Add linebreaks

  return textToWrite;
}

export { generateEDF, parseEDF };
