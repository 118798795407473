import { AkioDefrostSchedules, Refrigerations } from '../SchedulesPage.types';

export const filterSuctionLevelAkioDefrostSchedules = (
  refrigerations: Refrigerations[] = [],
  scheduleType: string,
  scheduleIdsSuction: string,
): AkioDefrostSchedules[] => {
  return refrigerations
    .find(refrigeration => refrigeration.id === scheduleType)
    .items.map(refSchItem => refSchItem.items)
    .flat()
    .find(({ id }) => id === `${scheduleType}-${scheduleIdsSuction}`)
    ?.akioDefrostSchedules;
};
