import '@danfoss/etui-sm-xml';
import '../../../_rollupPluginBabelHelpers-e1ae8e90.js';
import '../../../constants/session-storage-constants.js';
import '../../../constants/software-versions.js';
import { useContext } from 'react';
import 'use-deep-compare-effect';
import '../utils/get-xml-backend-ip.js';
import '../utils/get-xml-backend-protocol.js';
import '../utils/get-xml-proxy-config.js';
import '../utils/set-xml-proxy-config.js';
import { AppContext } from '../app-context.js';

function useApp() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useApp must be used within a AppStateProvider`);
  }
  return context;
}

export { useApp };
