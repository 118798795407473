import '../../constants/regex-patterns-constant.js';
import '../../constants/session-storage-constants.js';
import '../../types/Unit.js';
import '../url-helpers/get-is-valid-dns.js';
import '../url-helpers/get-is-valid-ip.js';
import '../url-helpers/get-is-valid-ip-or-dns.js';
import '../url-helpers/get-is-valid-port.js';
import '../url-helpers/get-url-info.js';
import './initial-unit-utils.js';
import { getUnitNetworksByInitialUnit } from './get-unit-networks-by-unit.js';

/**
 * Get a valid unit network by unit
 *
 * @param {string} sourceUrl
 * @param {Unit} unit
 * @returns
 */
function getUnitNetworkByUnit(sourceUrl, unit) {
  const unitNetworks = getUnitNetworksByInitialUnit(sourceUrl, unit);
  const unitNetwork = unitNetworks.validUnitNetworks.find(un => un.addr === unit.unit_addr);
  return unitNetwork;
}

export { getUnitNetworkByUnit };
