import React from 'react';
import {
  SearchInput,
  SelectInput,
  SelectInputOption,
  SpinnerSize,
  Table,
} from '@danfoss/etui-core';
import { Div, Span } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { ContentSpinner, EmptyState } from '@danfoss/etui-sm';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { useTheme } from '@danfoss/etui-system';
import {
  AlarmsTable,
  AlarmsTableContent,
  AlarmsTableData,
} from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';
import { generateNumberArray } from '../../../RefrigLayoutModal/utils';

export interface AlarmsFlowTableProps {
  handleOnSelect: (record: ConfigurationListItem) => void;
}

export const AlarmsFlowTable = ({ handleOnSelect }: AlarmsFlowTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    alarmsTableData,
    multipage,
    searchMode,
    isAllPageDataFetched,
    setPage,
    setPageValues,
    onSetSearchValue,
    setSelectAll,
    selectAll,
    isPageDataFetched,
  } = useRefrigCopy();

  const tableColumns = [
    {
      title: t('t76'),
      dataIndex: 'name',
      width: '20%',
      key: 'name',
    },
    {
      title: t('t902'),
      dataIndex: 'settings',
      key: 'settings',
      width: '10%',
    },
    {
      title: t('t599'),
      dataIndex: 'actionValue',
      key: 'actionValue',
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [alarmTable, setAlarmTable] = React.useState<AlarmsTableContent[]>([]);
  const [alarmsTablePage, setAlarmsTablePage] =
    React.useState<SelectInputOption[]>();
  const [alarmsTableSelectedPage, setAlarmsTableSelectedPage] =
    React.useState<SelectInputOption>({
      value: '1',
      label: '1',
    });
  React.useEffect(() => {
    if (alarmsTableData?.alarmTableContent?.length) {
      getTableData(alarmsTableData);
      if (!searchMode) {
        getAlarmsPages();
      }
    }
  }, [alarmsTableData]);

  const getAlarmsPages = () => {
    const pageValues: number[] = generateNumberArray(1, +multipage);
    const alarmsPageOptions: SelectInputOption[] = [];
    pageValues.forEach(page => {
      alarmsPageOptions.push({
        value: page.toString(),
        label: page.toString(),
      });
    });
    setPageValues(pageValues);
    setAlarmsTablePage(alarmsPageOptions);
  };

  const handleOnPageChange = (options: SelectInputOption) => {
    const page: number = +options.label - 1;
    setAlarmsTableSelectedPage(options);
    setPage(page.toString());
  };

  const getTableData = (alarmsTableData: AlarmsTableData) => {
    const alarmsTable: AlarmsTable[] = [];
    const rowKeys: number[] = [];
    const selectData = alarmsTableData?.alarmTableContent
      .slice(0, 4)
      .filter(data => data.name.length > 1)
      .map(data => data.li);
    setSelectAll(selectData);

    const tableData = searchMode
      ? alarmsTableData?.alarmTableContent.filter(
          item => item.toggleVal !== undefined,
        )
      : alarmsTableData?.alarmTableContent.slice(5);

    tableData?.forEach((content, index) => {
      if (content.toggleVal === '1') {
        rowKeys.push(index);
      }
      alarmsTable.push({
        name: content.name,
        settings: content.settings,
        actionValue: content.actionValue,
        toggleVal: content.toggleVal,
        key: index,
        li: content.li,
      });
    });
    setAlarmTable(alarmsTable);
    setSelectedRowKeys(rowKeys);
  };

  const handleRowSelectionChange = async (selectedKeys: string[]) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = React.useMemo(() => {
    return {
      selectedRowKeys,
      onChange: handleRowSelectionChange,
      onSelect: record => {
        handleOnSelect(record.li);
      },
      onSelectAll: (selected, selectedRows) => {
        selected && selectedRows?.length
          ? handleOnSelect(selectAll[0])
          : handleOnSelect(selectAll[1]);
      },
      columnWidth: '1%',
    };
  }, [selectedRowKeys.length, alarmTable]);

  return (
    <>
      <Div
        testId="alarms-flow-table-search-page-div"
        style={{
          marginTop: '10px',
          width: '60%',
          display: 'flex',
        }}
      >
        <Span
          style={{
            width: '30%',
            display: 'inline-block',
          }}
        >
          <SearchInput
            placeholder="Search for alarms"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSetSearchValue(e.target.value)
            }
            styles={{
              input: {
                borderColor: 'grey',
                background: 'white',
              },
            }}
          />
        </Span>
        {!searchMode ? (
          <Span
            style={{
              width: '30%',
              marginLeft: '10px',
              display: 'inline-block',
            }}
          >
            <SelectInput
              name=""
              options={alarmsTablePage}
              value={alarmsTableSelectedPage}
              onChange={handleOnPageChange}
              size="small"
            />
          </Span>
        ) : null}
      </Div>

      {!isPageDataFetched || (searchMode && !isAllPageDataFetched) ? (
        <Div
          testId="alarms-flow-table-spinner-div"
          style={{ marginTop: '200px' }}
        >
          <ContentSpinner size={SpinnerSize.small} transparent={true} />
        </Div>
      ) : !alarmTable.length ? (
        <Div
          testId="alarms-flow-table-empty-div"
          style={{ marginTop: '200px' }}
        >
          <EmptyState title="No data available" />
        </Div>
      ) : (
        <Div
          testId="alarms-flow-table-content-div"
          style={{ marginTop: '20px' }}
        >
          <Table
            styles={{
              td: { p: `${theme.spacing.sm}px`, height: 10 },
              root: { overflow: 'visible' },
            }}
            columns={tableColumns}
            dataSource={alarmTable}
            rowSelection={rowSelection}
            pagination={false}
          />
        </Div>
      )}
    </>
  );
};
