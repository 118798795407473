import { ContainerClient, BlockBlobClient } from '@azure/storage-blob';
import 'crypto-js';
import { getHashedString } from '../utils/get-hashed-string.js';
import './helpers/azure-sas-policy.helper.js';
import { getPipelineObject } from './helpers/get-pipline-object.js';
import { getContainerClientUrl } from './helpers/get-container-client-url.js';

class AzureConnector {
  constructor(macAdds, authData) {
    this.blockBlobName = getHashedString(macAdds);
    this.containerClientExists = false;
    this.pipeline = getPipelineObject(authData.SAS_TOKEN);
    this.clientUrl = getContainerClientUrl(authData.ACCOUNT, authData.CONTAINER_NAME);
    this.blobClientUrl = `${this.clientUrl}/${this.blockBlobName}`;
    this.containerClient = new ContainerClient(this.clientUrl, this.pipeline);
    this.blockBlobClient = new BlockBlobClient(this.blobClientUrl, this.pipeline);
  }

  /** Should be run before any other operations */
  async connect() {
    this.containerClientExists = await this.containerClient.exists();
    if (!this.containerClientExists) {
      const {
        succeeded
      } = await this.containerClient.createIfNotExists();
      this.containerClientExists = succeeded;
    }
  }
  async delete() {
    try {
      await this.containerClient.deleteBlob(this.blockBlobName);
      return {
        succeeded: true
      };
    } catch (error) {
      console.warn('Azure connector DELETE error: ', error.message);
      return {
        succeeded: false,
        error
      };
    }
  }
  async fetch() {
    let downloadBlockBlobResponse;
    let blobBody;
    try {
      downloadBlockBlobResponse = await this.blockBlobClient.download();
      blobBody = await downloadBlockBlobResponse.blobBody;
    } catch (error) {
      console.warn('Azure connector FETCH error: ', error.message);
      return {
        succeeded: false,
        error
      };
    }
    return {
      succeeded: true,
      blobBody
    };
  }
  async put(file) {
    try {
      await this.containerClient.uploadBlockBlob(this.blockBlobName, file, file.size);
    } catch (error) {
      console.warn('Azure connector PUT error: ', error.message);
      return {
        succeeded: false,
        error
      };
    }
    return {
      succeeded: true
    };
  }
}

export { AzureConnector };
