import { XML_TAG } from '../constants/xml-tag-constant.js';
import { _ as _objectSpread2, a as _objectWithoutProperties } from '../_rollupPluginBabelHelpers-10728f69.js';
import { parseStringPromise } from 'xml2js';

const _excluded = ["$"];
const {
  Builder
} = require('./builder');
const groupBy = (items, key) => items.reduce((result, item) => _objectSpread2(_objectSpread2({}, result), {}, {
  [item[key]]: [...(result[item[key]] || []), item]
}), {});
function convertToXMLObject(_ref) {
  let {
    tag,
    attributes,
    items = []
  } = _ref;
  const groupedByTags = groupBy(items, 'tag');
  const objectBody = Object.assign({
    $: attributes
  }, ...Object.keys(groupedByTags).map(groupTag => ({
    [groupTag]: [...groupedByTags[groupTag].map(groupItem => {
      const obj = {
        $: groupItem.attributes
      };
      if (groupItem.value) {
        obj._ = groupItem.value;
      }
      if (groupItem.items) {
        const _convertToXMLObject$g = convertToXMLObject({
            tag: groupTag,
            attributes: groupItem.attributes,
            items: groupItem.items,
            value: groupItem.value
          })[groupTag],
          children = _objectWithoutProperties(_convertToXMLObject$g, _excluded);
        Object.assign(obj, children);
      }
      return obj;
    })]
  })));
  return {
    [tag]: objectBody
  };
}
function buildXML(_ref2) {
  let {
    tag,
    attributes,
    items = []
  } = _ref2;
  const xmlBuilder = new Builder({
    headless: true
  });
  return xmlBuilder.buildObject(convertToXMLObject({
    tag,
    attributes,
    items
  }));
}
function buildXMLCommand(_ref3) {
  let {
    attributes,
    items = []
  } = _ref3;
  return buildXML({
    tag: XML_TAG.CMD,
    attributes,
    items
  });
}
async function parseXMLToJSON(xml) {
  const json = await parseStringPromise(xml, {
    trim: true,
    mergeAttrs: true,
    explicitArray: false
  });
  return json;
}
const extractFromRequest = (request, pattern) => {
  var _request$match;
  return (_request$match = request.match(pattern)) === null || _request$match === void 0 ? void 0 : _request$match[1];
};
const objToB64Uri = obj => window.btoa(JSON.stringify(obj));
const b64UriToObj = str => JSON.parse(window.atob(str));

export { b64UriToObj, buildXML, buildXMLCommand, convertToXMLObject, extractFromRequest, objToB64Uri, parseXMLToJSON };
