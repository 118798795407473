import {
  DEVICE_TYPE,
  DiscoveryTableData,
  SPECIAL_CHAR_REGEX,
} from '../types/DiscoveryPage.types';

export const isCircuitConfigured = (discoveryTableData: DiscoveryTableData[]) =>
  discoveryTableData?.filter(
    device =>
      device.deviceType === DEVICE_TYPE.CIRCUIT ||
      device.deviceType === DEVICE_TYPE.GEN_CIRCUIT,
  ).length > 0;

export const getCircuitDeviceIdBasedOnIndex = (
  discoveryTableData: DiscoveryTableData[],
  circuitIndex: number,
) =>
  discoveryTableData
    ?.filter(
      device =>
        device.deviceType === DEVICE_TYPE.CIRCUIT ||
        device.deviceType === DEVICE_TYPE.GEN_CIRCUIT,
    )
    .at(circuitIndex).deviceId;

export const isAddressDisabled = (circuits: DiscoveryTableData) => {
  switch (circuits.deviceType) {
    case DEVICE_TYPE.CIRCUIT:
      return true;
    case DEVICE_TYPE.GEN_CIRCUIT:
      return Number(circuits.online) === 1;
    default:
      return false;
  }
};

export const isModelDisabled = (circuits: DiscoveryTableData) => {
  switch (circuits.deviceType) {
    case DEVICE_TYPE.CIRCUIT:
      return false;
    case DEVICE_TYPE.GEN_CIRCUIT:
      return Number(circuits.online) === 1;
    default:
      return false;
  }
};

export const getIsMonitorOrSubcool = (idxCombo: number, modelIdx: number) =>
  idxCombo === modelIdx ? '1' : '0';

export const verifyAddres = (
  newAddress: string,
  selectedDeviceId: string,
  discoveryTableData: DiscoveryTableData[],
  max_naddr: number,
) => {
  if (newAddress !== '0') {
    if (Number(newAddress) > max_naddr || Number(newAddress) < 0) {
      return 't3520';
    }
    if (
      discoveryTableData.filter(
        device =>
          device.deviceId.toString() !== selectedDeviceId &&
          device.address !== '0' &&
          device.address !== '---' &&
          device.address === newAddress,
      ).length
    ) {
      return 't517';
    }
  }
  return '';
};

export const hasSpecialCharacters = (value: string) => {
  return !new RegExp(SPECIAL_CHAR_REGEX).test(value);
};
