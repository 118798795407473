import { fetchXMLData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function fetchUpdateSoftwareBackup(url, user, backupCommand) {
  const response = await fetchXMLData({
    url,
    attributes: {
      action: XML_ACTION.UPDATE_SW,
      user: user.user,
      password: user.password,
      sw_upgrade_cmd: backupCommand
    }
  });
  if (response.return_code !== '0') {
    throw new Error();
  }
  return response;
}

export { fetchUpdateSoftwareBackup };
