import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import {
  AlarmsTable,
  AlarmsTableContent,
} from '../../../types/RefrigCopy.types';
import { AllPageType } from '../../../context';
import { getArray } from '../../../../../../Configuration/utils';

export const processAlarmsTableData = (tableContentData: any[]) => {
  const tabledata = [];
  const tableContent = getArray(tableContentData);
  tableContent?.forEach(data => {
    const alarmsTableContentList: ConfigurationListItem[] = getArray(
      data?.items?.l,
    );

    alarmsTableContentList.forEach(data => {
      const obj: AlarmsTable = {
        name: '',
        actionValue: '',
        settings: '',
        li: data,
        key: null,
        toggleVal: data.toggleVal,
      };
      const actionStr = getSubString(data.name);
      const action = actionStr.length > 1 ? '' : actionStr;
      obj.actionValue = action;
      if (action.length) {
        const result = getNameSettings(data.name);
        const settings = getSubString(result);
        obj.settings = settings;
        const name = getNameSettings(result);
        obj.name = name;
      } else {
        const settings = getSubString(actionStr);
        obj.settings = settings;
        obj.name = getNameSettings(data.name);
      }
      tabledata.push(obj);
    });
  });
  return tabledata.flat();
};

const getSubString = (str: string) => {
  return str.substring(str.lastIndexOf(' '), str.length).trim();
};

const getNameSettings = (str: string) => {
  return str.slice(0, str.lastIndexOf(' ')).trim();
};
export const filterAlarmsTableContentList = (
  allPageData: AllPageType[],
  searchValue: string,
) => {
  const filteredAlarmsTableContentList = allPageData?.filter(content => {
    const name = (content as AlarmsTableContent)?.name?.toLowerCase();
    const value = searchValue?.toLowerCase();
    return name.includes(value);
  });
  return filteredAlarmsTableContentList;
};

export const addPageToListItems = (
  listItems: ConfigurationListItem[],
  page: string,
) => {
  listItems?.forEach((_, index) => {
    listItems[index].page = page;
  });
  return listItems;
};
