import { XML_DEVICE_STR } from '@danfoss/etui-sm-xml';
import { getIsMcxLightDevice } from './get-is-mcx-light-device.js';

/**
 * Utility to get if device is light zone
 *
 * @export
 * @param {Device} device
 * @returns {boolean}
 */
function getIsLightZoneDevice(device) {
  return device.type === XML_DEVICE_STR.STR_TYPE_ZONE && device.nodetype === '255' || getIsMcxLightDevice(device);
}

export { getIsLightZoneDevice };
