import { CALENDAR_VIEW_FORMATS } from '@danfoss/etui-sm-xml';
import {
  OpenClose,
  RefrigerationSchedules,
  TimeConstant,
} from 'pages/SchedulesPage/SchedulesPage.types';
import {
  convertTimeFormats,
  isPrevDayConfigured,
  isNextDayConfigured,
} from 'pages/SchedulesPage/utils';

const HOURS_IN_DAY = 24;
const MINS_IN_HOUR = 60;

export const sumRelativeTime = (
  time: string[],
  timeToAdd: string,
  timeFormat: CALENDAR_VIEW_FORMATS,
  endOFTheDay: boolean = false,
  activeDay: string,
  storeDay: RefrigerationSchedules,
  daysOfTheWeek: string[],
): [OpenClose, number] => {
  let hourOffset = 0;
  let dayOffset = 0;

  const plusOrMinus = timeToAdd[0];
  const splitedTime = timeToAdd.split(':');
  const hoursToAdd = splitedTime[0];
  const minsToAdd = `${plusOrMinus}${splitedTime[1]}`;

  let minutes = +time[1] + +minsToAdd;

  if (minutes >= MINS_IN_HOUR) {
    minutes -= MINS_IN_HOUR;
    hourOffset++;
  } else if (minutes < 0) {
    minutes = MINS_IN_HOUR + minutes;
    hourOffset--;
  }

  let hours = +time[0] + +hoursToAdd + hourOffset;

  if (hours >= HOURS_IN_DAY) {
    hours -= HOURS_IN_DAY;
    dayOffset++;
  } else if (hours < 0) {
    hours = HOURS_IN_DAY + hours;
    dayOffset--;
  }

  if (endOFTheDay) dayOffset++;

  let resultTime = [
    `${hours}`.padStart(2, '0'),
    `${minutes}`.padStart(2, '0'),
  ].join(':');

  if (timeFormat === CALENDAR_VIEW_FORMATS.FORMAT_12) {
    resultTime = convertTimeFormats(
      CALENDAR_VIEW_FORMATS.FORMAT_24,
      CALENDAR_VIEW_FORMATS.FORMAT_12,
      resultTime,
    );
  }

  const shouldStartAtMidnight =
    dayOffset < 0 && !isPrevDayConfigured(activeDay, storeDay, daysOfTheWeek);

  const shouldEndAtMidnight =
    dayOffset > 0 && !isNextDayConfigured(activeDay, storeDay, daysOfTheWeek);

  if (shouldStartAtMidnight || shouldEndAtMidnight) {
    const is12HFormat = timeFormat === CALENDAR_VIEW_FORMATS.FORMAT_12;
    dayOffset = shouldStartAtMidnight ? 0 : 1;
    resultTime = is12HFormat
      ? TimeConstant.MIDNIGHT_12
      : TimeConstant.MIDNIGHT_24;
  }

  const [hour, minute, units] = resultTime.split(/:|\s/);
  const openCloseHours = { hour, minute, units };

  return [openCloseHours, dayOffset];
};
