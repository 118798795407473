/**
 * Composer for rack id
 *
 * @export
 * @param {Rack} rack
 * @param {Suction} suction
 * @param {number} index
 * @returns {string}
 */
function composeRackId(rack) {
  return rack.rack_id ? `${rack.host}-${rack.rack_id}` : `${rack.host}-${rack.arg1}-${rack.arg2}-${rack.arg3}`;
}

export { composeRackId };
