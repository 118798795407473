import { User, Unit } from '@danfoss/etui-sm-xml';
import {
  fetchAllAlarmRefsByUnits,
  fetchAllAlarmsByUnits,
} from 'pages/AlarmsPage/actions';

export async function fetchActiveAlarmsByUnits([
  url,
  user,
  units,
  skipSessionUpdate = false,
]: [string, User, Unit[], boolean?]) {
  const { active } = await fetchAllAlarmRefsByUnits([
    url,
    user,
    units,
    skipSessionUpdate,
  ]);
  return fetchAllAlarmsByUnits(url, active, user, units, skipSessionUpdate);
}
