import * as React from 'react';

export * from './Configuration';
export * from './DeviceConfiguration';
export * from './UnitConfiguration';

const ConfigurationPage = React.lazy(
  () =>
    import(/* webpackChunkName: "configuration-page" */ './ConfigurationPage'),
);

export { ConfigurationPage };
