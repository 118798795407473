import { createFeatureHubInstance } from '@danfoss/etui-sm';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isExperimental } from '../../config';
import { UseExperimentalFeatureFlagHook, UseFeatureFlagHook } from './types';

export type AllFeatureFlags =
  keyof typeof import('../../../public/feature-flags.json');

const {
  FeatureHubContext,
  FeatureHubProvider,
  useFeatureHub,
  useFeatureFlag: useFeatureFlagFn,
} = createFeatureHubInstance(
  !window.electronConstants
    ? `${location.pathname}feature-flags.json`
    : `${window.electronConstants.REACT_APP_ELECTRON_STATIC_PATH}/feature-flags.json`,
);

export const useOverridableFeatureFlag = (flag: AllFeatureFlags) => {
  const featureFlag = useFeatureFlagFn(flag);
  const [localOverrides] = useLocalStorage<
    Partial<Record<AllFeatureFlags, boolean>>
  >('featureFlagOverrides', {});

  // TODO: Add beta flag here?
  if (isExperimental && flag in localOverrides) return localOverrides[flag];

  return featureFlag;
};

export const useFeatureFlag = useOverridableFeatureFlag as UseFeatureFlagHook;
export const useExperimentalFeatureFlag =
  useOverridableFeatureFlag as UseExperimentalFeatureFlagHook;

export { FeatureHubContext, FeatureHubProvider, useFeatureHub };
