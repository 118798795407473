import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { getArray } from '../../../../../../Configuration/utils';
import { PI200Table } from '../../../types/RefrigLayout.types';

export const processPI200TableData = (tableContentData: any[]) => {
  const tabledata = [];
  const tableContent = getArray(tableContentData);
  tableContent?.forEach(data => {
    const graphTableContentList: ConfigurationListItem[] = getArray(
      data?.items?.l,
    );

    graphTableContentList.forEach(item => {
      const rowItems = item?.name?.match(/\S+/g);
      if (rowItems && +item.ef !== 0 && parseInt(item?.pnum, 10) === 0) {
        const [address, model, ipAddress] = rowItems;
        const obj: PI200Table = {
          address,
          ipAddress,
          model,
          li: item,
          key: null,
          toggleVal: item.toggleVal,
        };
        tabledata.push(obj);
      }
    });
  });
  return tabledata.flat();
};
