import {
  ADDRESS_FIELD_NAMES,
  MAX_VALUES,
  MIN_VALUES,
} from '@danfoss/etui-sm-xml';

export const getFieldMaxValue = (field: string): number => {
  switch (field) {
    case ADDRESS_FIELD_NAMES.NODE:
      return MAX_VALUES.NODE;
    case ADDRESS_FIELD_NAMES.MOD:
      return MAX_VALUES.MOD;
    case ADDRESS_FIELD_NAMES.POINT:
      return MAX_VALUES.POINT;
    default:
      return MIN_VALUES.MIN_LIMIT_VALUE;
  }
};
