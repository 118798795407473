import { DateFormat } from '@danfoss/etui-sm';

export const configModalOptions = [
  {
    // name: AxisType.Date,
    name: 'dateFormat',
    label: 't566',
    options: [
      { value: DateFormat.MMDDYY, label: 'MM/DD/YY' },
      { value: DateFormat.MMDDYYYY, label: 'MM/DD/YYYY' },
      { value: DateFormat.DDMMYY, label: 'DD/MM/YY' },
      { value: DateFormat.DDMMYYYY, label: 'DD/MM/YYYY' },
    ],
  },
  {
    name: 'timeFormat',
    label: 't565',
    options: [
      { value: '0', label: '12 Hour' },
      { value: '1', label: '24 Hour' },
    ],
  },
  {
    name: 'pressure',
    label: 't567',
    options: [
      { value: '1', label: 'PSI' },
      { value: '2', label: 'Bar' },
    ],
  },
  {
    name: 'temperature',
    label: 't123',
    options: [
      { value: '4', label: '°C' },
      { value: '3', label: '°F' },
    ],
  },
  {
    name: 'light',
    label: 't568',
    options: [
      { value: '5', label: '%' },
      { value: '20', label: 'Footcandles' },
      { value: '25', label: 'Lux' },
    ],
  },
];

export const legendColumns = [
  {
    title: 't867',
    dataIndex: 'color',
    key: 'color',
  },
  {
    title: ' ',
    dataIndex: 'icon',
    key: 'icon',
  },
  {
    title: 't76',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 't540',
    dataIndex: 'interval',
    key: 'interval',
  },
  {
    title: 't541',
    dataIndex: 'min',
    key: 'min',
  },
  {
    title: 't542',
    dataIndex: 'max',
    key: 'max',
  },
  {
    title: 't543',
    dataIndex: 'avg',
    key: 'avg',
  },
];

export const range: { name: 'startDate' | 'endDate'; label: string }[] = [
  { name: 'startDate', label: 't46' },
  { name: 'endDate', label: 't47' },
];
