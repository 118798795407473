import { isAndroid, isDesktop, isEmbedded, isIOS, isMacOs, isMobile, isWearable, isWindows, isMobileOnly, isTablet, isChrome, isEdge, isFirefox, isOpera } from 'react-device-detect';

function getDeviceInfo() {
  return {
    isAndroid,
    isDesktop,
    isEmbedded,
    isIOS,
    isMacOs,
    isMobile,
    isWearable,
    isWindows,
    isMobileOnly,
    isTablet,
    isChrome,
    isEdge,
    isFirefox,
    isOpera
  };
}

export { getDeviceInfo };
