import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from '@danfoss/etui-core';
import { isXmlError, XmlError } from '@danfoss/etui-sm-xml';
import { useTheme } from '@danfoss/etui-system';
import { getErrorCodeTranslator } from 'utils/error-code-to-t-code';
import { SWRConfig } from 'swr';
import { logoutUser, useAuth } from '@danfoss/etui-sm';

const SESSION_EXPIRY_CODE = 100;
const NOTIFICATION_SHOWN_SECONDS = 5;

export function SwrGlobal({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const { dispatch } = useAuth();
  const theme = useTheme();

  return (
    <SWRConfig
      value={{
        onError(err: Error | XmlError) {
          if (isXmlError(err)) {
            const isSessionExpired = +err.code === SESSION_EXPIRY_CODE;
            isSessionExpired && logoutUser(dispatch);

            Notification.error({
              theme,
              message: t('t900'),
              description: err.displayError(getErrorCodeTranslator(t)),
              duration: NOTIFICATION_SHOWN_SECONDS,
            });
          }
          // TODO else ? handle non business logic errors
        },
        revalidateIfStale: false,
        revalidateOnFocus: false,
        shouldRetryOnError: true,
      }}
    >
      {children}
    </SWRConfig>
  );
}
