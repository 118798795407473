import { AkioShutdownSchedules, Refrigerations } from '../SchedulesPage.types';

export const filterPackLevelAkioShutdownSchedules = (
  refrigerations: Refrigerations[],
  scheduleType: string,
  scheduleIdsPack: string,
): AkioShutdownSchedules[] => {
  return refrigerations
    ?.find(refrigeration => refrigeration?.id === scheduleType)
    ?.items?.find(
      refSchItem => refSchItem?.id === `${scheduleType}-${scheduleIdsPack}`,
    )
    ?.items?.find(schItem => schItem?.akioShutdownSchedules)
    ?.akioShutdownSchedules;
};
