import { fetchXMLFileData, XML_ACTION } from '@danfoss/etui-sm-xml';

async function fetchReadCertFile(_ref) {
  let [_key, user, url] = _ref;
  const {
    fileContent
  } = await fetchXMLFileData({
    url,
    user: user.user,
    password: user.password,
    lang: user.language,
    action: XML_ACTION.READ_CERT_FILE
  });
  return fileContent;
}

export { fetchReadCertFile };
