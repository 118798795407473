import { useContext } from 'react';

function createUseFeatureHubHook(genericContext) {
  return () => {
    const context = useContext(genericContext);
    if (context === undefined) {
      throw new Error('useFeatureHub must be used inside a FeatureHub Provider');
    }
    return context;
  };
}

export { createUseFeatureHubHook };
