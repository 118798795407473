import { Unit } from '@danfoss/etui-sm-xml';
import {
  getIsMatchedSoftwareVersion,
  SOFTWARE_VERSION_PREFIX,
} from '@danfoss/etui-sm';
import { isAzureStorage, isXmlStorage } from '../config';

export function getIsAllowedScadaStorage(units: Unit[]) {
  return (
    isAzureStorage ||
    (isXmlStorage &&
      getIsMatchedSoftwareVersion(units, [SOFTWARE_VERSION_PREFIX.G09]))
  );
}
