import {
  BuildXMLOptions,
  fetchXMLData,
  User,
  XML_ACTION,
  XML_TAG,
} from '@danfoss/etui-sm-xml';
import { getHvacModelListNameIndex } from '../../../RefrigLayoutModal/utils';
import {
  ConfigureDeviceResponse,
  ConfigureDeviceStatusResponse,
  DeviceConstraintsData,
  DeviceTypeModelList,
  DiscoveryTableData,
} from '../../../RefrigLayoutModal/types';

export const startConfigHvacDevice = async ([
  url,
  user,
  hvacFinalTableData,
  hvacDeviceTypeModelList,
]: [string, User, DiscoveryTableData[], DeviceTypeModelList]) => {
  const hvacXmlTags: BuildXMLOptions[] = getConfigDevicesXml(
    hvacFinalTableData,
    hvacDeviceTypeModelList,
  );
  try {
    const data: ConfigureDeviceResponse = await fetchXMLData({
      url,
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        storeview_only: '1',
        version: 'C',
        total: hvacFinalTableData?.length,
        action: XML_ACTION.CONFIGURE_HVAC_DEVICES,
      },
      items: hvacXmlTags,
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

const getConfigDevicesXml = (
  hvacFinalTableData: DiscoveryTableData[],
  hvacDeviceTypeModelList: DeviceTypeModelList,
) => {
  const hvacXmlTags: BuildXMLOptions[] = [];
  hvacFinalTableData?.forEach(hvacDevice => {
    const hvacXmlTag: BuildXMLOptions = {
      tag: XML_TAG.HVAC,
      attributes: {
        name: hvacDevice?.name || '',
        type: hvacDevice?.deviceType || '',
        arg1: hvacDevice?.dim1 || '',
        ah: hvacDevice?.ah?.toString() || '',
        model: hvacDevice?.model || '',
        filename: hvacDevice?.file || '',
        version: hvacDevice?.version || '',
        device: hvacDevice?.device || '',
        addr: hvacDevice?.address || '',
        list_name: hvacDevice?.listname || '',
        index: getHvacModelListNameIndex(
          hvacDevice,
          hvacDeviceTypeModelList?.modelList,
        ),
      },
    };
    hvacXmlTags.push(hvacXmlTag);
  });
  return hvacXmlTags;
};

export const getConfigStatus = async ([url, user]: [string, User]) => {
  try {
    const data: ConfigureDeviceStatusResponse = await fetchXMLData({
      url,
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        storeview_only: '1',
        version: 'C',
        action: XML_ACTION.READ_CONFIGURE_STATUS,
      },
    });
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const validateAddress = (
  address: string,
  selectedRowDeviceId: number,
  deviceConstraintsData: DeviceConstraintsData,
  hvacFinalTableData: DiscoveryTableData[],
) => {
  if (address !== '0') {
    if (+address > +deviceConstraintsData?.max_naddr || +address < 0) {
      return 't3520';
    }
    if (
      hvacFinalTableData.filter(
        device =>
          device.deviceId !== selectedRowDeviceId &&
          device.address !== '0' &&
          device.address !== '-' &&
          device.address === address,
      ).length
    ) {
      return 't517';
    }
  }
  return '';
};
