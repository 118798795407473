import * as React from 'react';
import { SelectInput } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { FoodGroups } from '@danfoss/etui-sm-xml';
import { useConfigurationMenu } from '../../context';

function ConfigurationItemContentMenuFoodGroupSelect() {
  const theme = useTheme();
  const { foodGroup, onSetFoodGroup } = useConfigurationMenu();
  const title = 'Food Groups';

  const foodGroups: FoodGroups[] = [
    {
      label: 'CPT 1',
      index: '1',
    },
    {
      label: 'CPT 2',
      index: '2',
    },
    {
      label: 'CPT 3',
      index: '3',
    },
    {
      label: 'CPT 4',
      index: '4',
    },
    {
      label: 'CPT 5',
      index: '5',
    },
    {
      label: 'CPT 6',
      index: '6',
    },
    {
      label: 'CPT 7',
      index: '7',
    },
  ];

  const getOptions = React.useCallback(() => {
    return foodGroups.map(({ label }, index) => ({
      label,
      value: `${index + 1}`,
    }));
  }, [foodGroups]);

  const options = getOptions();

  const assignSelectedOption = (foodgroupInput: FoodGroups) => {
    return options.filter(
      foodGroup => foodGroup.label === foodgroupInput.label,
    );
  };

  let selectedOption = null;
  const setFirstOption = () => {
    [selectedOption] = options;
    onSetFoodGroup({
      label: selectedOption.label,
      index: selectedOption.value,
    });
  };

  selectedOption = foodGroup
    ? assignSelectedOption(foodGroup)
    : setFirstOption();

  const handleOnChange = item => {
    onSetFoodGroup({ label: item.label, index: item.value });
  };

  return !options.length ? null : (
    <SelectInput
      label={title}
      name="foodGroup"
      value={selectedOption}
      options={options}
      onChange={handleOnChange}
      styles={{
        root: {
          width: ['100%', '25%'],
          borderRight: `1px solid ${theme.palette.divider}`,
        },
        input: { borderColor: 'transparent', boxShadow: 'none' },
        menu: { borderColor: 'transparent', top: '100%' },
      }}
    />
  );
}

export { ConfigurationItemContentMenuFoodGroupSelect };
