import { AkioShutdownSchedules, Refrigerations } from '../SchedulesPage.types';
import { filterPackLevelAkioShutdownSchedules } from './filterPackLevelAkioShutdownSchedules';
import { filterSuctionLevelAkioShutdownSchedules } from './filterSuctionLevelAkioShutdownSchedules';
import { filterCaseLevelAkioShutdownSchedules } from './filterCaseLevelAkioShutdownSchedules';

export const filterAkioShutdownSchedules = (
  refrigerations: Refrigerations[],
  schedulesType: string,
  schedulesIds: string,
): AkioShutdownSchedules[] => {
  if (!schedulesIds) {
    return filterCaseLevelAkioShutdownSchedules(refrigerations, schedulesType);
  }
  if (schedulesIds.includes('pack')) {
    return filterPackLevelAkioShutdownSchedules(
      refrigerations,
      schedulesType,
      schedulesIds,
    );
  }
  if (schedulesIds.includes('suction')) {
    return filterSuctionLevelAkioShutdownSchedules(
      refrigerations,
      schedulesType,
      schedulesIds,
    );
  }
};
