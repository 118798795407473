const getFirstKeyValuePair = obj => {
  const keys = obj ? Object.keys(obj) : null;
  const key = keys ? keys[0] : null;
  const value = key ? obj[key] : null;
  return {
    key,
    value
  };
};

export { getFirstKeyValuePair };
