import { icons, Tooltip, Icon } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MIN_VALUES } from '@danfoss/etui-sm-xml';
import * as S from './styles';
import { getFieldMaxValue } from './utils';

export interface FieldRangeToolTipProps {
  field: string;
}

export function FieldRangeToolTip({ field }: FieldRangeToolTipProps) {
  const { t } = useTranslation();
  const max = getFieldMaxValue(field);
  return (
    <S.FieldRangeTooltipDiv testId="field-range-tooltip">
      <Tooltip
        placement="right"
        message={() => (
          <Div>
            <Div testId="min-text" display="inline-block">
              {`${t('t541')}: `}
              <strong>{MIN_VALUES.MIN_LIMIT_VALUE}</strong>
            </Div>
            <Div
              testId="max-text"
              style={{ display: 'inline-block', marginLeft: '5px' }}
            >
              {`${t('t542')}: `}
              <strong>{max}</strong>
            </Div>
          </Div>
        )}
        styles={{
          div: { lineHeight: 0 },
        }}
      >
        <Icon glyph={icons.INFO_CIRCLE} />
      </Tooltip>
    </S.FieldRangeTooltipDiv>
  );
}
