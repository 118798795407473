const SidebarShapes = [{
  name: 'Rectangle',
  type: 'vertex',
  style: 'rounded=0;whiteSpace=wrap;html=1;',
  icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5MCIgaGVpZ2h0PSI1MCI+DQogIDxnPg0KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSwwLjUpIiBzdHlsZT0idmlzaWJpbGl0eTp2aXNpYmxlIj4NCiAgICAgIDxyZWN0IHg9IjEuNDQiIHk9IjcuNjgiIHdpZHRoPSI4MCIgaGVpZ2h0PSI0MCIgZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjEuMyIgcG9pbnRlci1ldmVudHM9ImFsbCIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo=',
  x: 100,
  y: 40
}, {
  name: 'Circle',
  type: 'vertex',
  style: 'shape=ellipse;whiteSpace=wrap;html=1;',
  icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCI+DQogIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSwwLjUpIiBzdHlsZT0idmlzaWJpbGl0eTp2aXNpYmxlIj4NCiAgICA8ZWxsaXBzZSBjeD0iMjAiIGN5PSIyMCIgcng9IjIwIiByeT0iMjAiIGZpbGw9IiNmZmZmZmYiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIxLjMiIHBvaW50ZXItZXZlbnRzPSJhbGwiLz4NCiAgPC9nPg0KPC9zdmc+DQo=',
  x: 40,
  y: 40
}, {
  name: 'Line',
  type: 'edge',
  style: 'edgeStyle=loopEdgeStyle;endArrow=none;html=1;',
  icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMCIgc3R5bGU9J3BhZGRpbmctcmlnaHQ6MTBweCc+DQogIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSwwLjUpIiBzdHlsZT0idmlzaWJpbGl0eTp2aXNpYmxlIj4NCiAgICA8cGF0aCBkPSJNIDIuMTIgMjcuNTYgTCAyOC42MiAxLjA2IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEwIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50ZXItZXZlbnRzPSJzdHJva2UiIHZpc2liaWxpdHk9ImhpZGRlbiIvPg0KICAgIDxwYXRoIGQ9Ik0gMi4xMiAyNy41NiBMIDI4LjYyIDEuMDYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50ZXItZXZlbnRzPSJzdHJva2UiLz4NCiAgPC9nPg0KPC9zdmc+DQo=',
  x: 50,
  y: 50
}];

export { SidebarShapes };
