import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { TABLE_ADDRESS } from './enums';

export const multiOptionsListLength: number = 5;
export const initialPage: number = 0;

export const isPaginationEnabledInUploadPage = (
  menuId: string = '',
  multipage: string = '',
): boolean =>
  menuId.split('-')[0] === TABLE_ADDRESS.NETWORK_NODES_UPLOAD && +multipage > 1;

export const isMultiOptionListAlreadyUpdated = (
  multiOptionsList: ConfigurationListItem[] = [],
  dataList: ConfigurationListItem[] = [],
): boolean => {
  if (
    dataList.length < multiOptionsListLength &&
    multiOptionsList.length < multiOptionsListLength
  )
    return;
  return dataList
    .slice(0, multiOptionsListLength)
    .every((data, index) => multiOptionsList[index].name === data.name);
};

const isConditionMetToUpdate = (
  multiOptionsList: ConfigurationListItem[] = [],
  dataList: ConfigurationListItem[] = [],
  pageNumber: string = '',
): boolean =>
  multiOptionsList.length &&
  dataList.length &&
  pageNumber &&
  +pageNumber > initialPage &&
  !isMultiOptionListAlreadyUpdated(multiOptionsList, dataList);

export const updateDataListWithMultiOptions = (
  multiOptionsList: ConfigurationListItem[] = [],
  dataList: ConfigurationListItem[] = [],
  pageNumber: string = '',
): ConfigurationListItem[] => {
  if (!isConditionMetToUpdate(multiOptionsList, dataList, pageNumber)) {
    return dataList;
  }
  return [
    ...multiOptionsList,
    ...dataList.map(item => ({
      ...item,
      li: (+item.li + multiOptionsListLength).toString(),
    })),
  ];
};
