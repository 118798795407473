import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { CommandBar } from '@danfoss/etui-core';
import {
  ConfigurationItemContentMenuDeviceGroupSelect,
  ConfigurationItemContentMenuPageSelect,
  useConfigurationMenu,
} from '../Configuration';

export type IDeviceConfigurationBaseCommandBarProps = {
  groupnames: string[];
  subgroupnames: string[];
  multipage: string;
};

function DeviceConfigurationBaseCommandBar({
  groupnames = [],
  subgroupnames = [],
  multipage = '0',
}: IDeviceConfigurationBaseCommandBarProps) {
  const theme = useTheme();
  const {
    deviceGroup,
    deviceSubgroup,
    onSetDeviceGroup,
    onSetDeviceSubgroup,
    page,
    onSetPage,
  } = useConfigurationMenu();

  const handleDeviceGroupChange = (newSubgroup: string) => {
    onSetDeviceGroup(newSubgroup);
  };

  const getCommandBarItems = () => {
    const hasGroupnames = groupnames.length !== 0;
    const hasSubgroupnames = subgroupnames.length > 1;

    const items = [];

    if (hasGroupnames) {
      items.push({
        key: 'deviceGroup',
        onRender: item => (
          <ConfigurationItemContentMenuDeviceGroupSelect
            key={item.key}
            selectedValue={deviceGroup}
            menugroups={groupnames}
            onChange={handleDeviceGroupChange}
          />
        ),
      });
    }

    if (hasGroupnames && hasSubgroupnames) {
      items.push({
        key: 'deviceSubgroup',
        onRender: item => (
          <ConfigurationItemContentMenuDeviceGroupSelect
            key={item.key}
            name="deviceSubgroup"
            selectedValue={deviceSubgroup}
            menugroups={subgroupnames}
            onChange={onSetDeviceSubgroup}
          />
        ),
      });
    }

    if (+multipage > 0) {
      items.push({
        key: 'pageSelect',
        onRender: item => (
          <ConfigurationItemContentMenuPageSelect
            key={item.key}
            selectedPage={page}
            isFullWidth={true}
            multipage={multipage}
            onPageChange={onSetPage}
          />
        ),
      });
    }

    return items;
  };

  return (
    <CommandBar
      items={getCommandBarItems()}
      styles={{
        root: {
          m: null,
          borderRadius: theme.shape.borderRadius,
        },
        leftSide: { width: '100%' },
      }}
    />
  );
}

export { DeviceConfigurationBaseCommandBar };
