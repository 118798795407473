import * as React from 'react';
import { RouteGuard } from './RouteGuard';

export function lookupForRoutesToGuard(children: React.ReactNode) {
  const routes: React.ReactNode[] = [];
  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      const { guard, children: subChildren, ...restProps } = child.props;
      if (child.type === RouteGuard) {
        if (guard(restProps)) {
          routes.push(...lookupForRoutesToGuard(subChildren));
        }
      } else {
        routes.push(child);
      }
    }
  });

  return routes;
}
