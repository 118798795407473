import { ConfigurationTabItem } from '@danfoss/etui-sm-xml';
import { getMenuItemsByMenuId } from './getMenuItemsByMenuId';
import { getParentMenuIdByPathname } from './getParentMenuIdByPathname';

function getParentMenuItemsByPathname(
  baseRoute: string,
  pathname: string,
  menuItemsById: {},
  level: number = 2,
) {
  const parentId = getParentMenuIdByPathname(baseRoute, pathname, level);
  const parentMenuItems: ConfigurationTabItem[] = getMenuItemsByMenuId(
    parentId,
    menuItemsById,
  );
  return parentMenuItems || [];
}

export { getParentMenuItemsByPathname };
