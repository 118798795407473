import { STORAGE_INITIAL_UNIT_KEY, STORAGE_SELECTED_UNIT_KEY } from '../../constants/session-storage-constants.js';

const getInitialUnitFromStorage = () => JSON.parse(sessionStorage.getItem(STORAGE_INITIAL_UNIT_KEY) || 'null');
const setInitialUnitToStorage = initialUnit => sessionStorage.setItem(STORAGE_INITIAL_UNIT_KEY, JSON.stringify(initialUnit));
const getSelectedUnitFromStorage = () => JSON.parse(sessionStorage.getItem(STORAGE_SELECTED_UNIT_KEY) || 'null');
const removeSelectedUnitFromStorage = () => sessionStorage.removeItem(STORAGE_SELECTED_UNIT_KEY);
const setSelectedUnitToStorage = unit => {
  const selectedUnit = unit ? JSON.stringify(unit) : null;
  sessionStorage.setItem(STORAGE_SELECTED_UNIT_KEY, selectedUnit);
};
const removeInitialUnitFromStorage = () => sessionStorage.removeItem(STORAGE_INITIAL_UNIT_KEY);
const isCurrentlySelectedUnit = unit => {
  const selectedUnit = getSelectedUnitFromStorage();
  if (selectedUnit) return unit.unit_addr === selectedUnit.unit_addr;
  const initialUnit = getInitialUnitFromStorage();
  return unit.unit_addr === (initialUnit === null || initialUnit === void 0 ? void 0 : initialUnit.unit_addr);
};

export { getInitialUnitFromStorage, getSelectedUnitFromStorage, isCurrentlySelectedUnit, removeInitialUnitFromStorage, removeSelectedUnitFromStorage, setInitialUnitToStorage, setSelectedUnitToStorage };
