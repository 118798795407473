import { Unit, XML_DEVICE_LIST } from '@danfoss/etui-sm-xml';
import { getIsUnitHasMisc } from './get-is-unit-has-misc';

export function getDeviceTypesByUnits(units: Unit[]) {
  const unitsArr = Array.isArray(units) ? units : [units];
  const deviceTypes: XML_DEVICE_LIST[] = [];
  let haveRefrig = false;
  let haveHVAC = false;
  let haveLights = false;
  let haveMeters = false;
  let haveMisc = false;

  for (const unit of unitsArr) {
    if (parseInt(unit.refrig, 10) > 0) {
      haveRefrig = true;
    }
    if (parseInt(unit.hvac, 10) > 0) {
      haveHVAC = true;
    }
    if (parseInt(unit.lights, 10) > 0) {
      haveLights = true;
    }
    if (parseInt(unit.meters, 10) > 0) {
      haveMeters = true;
    }
    if (getIsUnitHasMisc(unit)) {
      haveMisc = true;
    }
  }

  if (haveRefrig) {
    deviceTypes.push(XML_DEVICE_LIST.REFRIG);
  }
  if (haveHVAC) {
    deviceTypes.push(XML_DEVICE_LIST.HVAC);
  }
  if (haveLights) {
    deviceTypes.push(XML_DEVICE_LIST.LIGHTS);
  }
  if (haveMeters) {
    deviceTypes.push(XML_DEVICE_LIST.METER);
  }
  if (haveMisc) {
    deviceTypes.push(XML_DEVICE_LIST.MISC);
  }

  return deviceTypes;
}
