import React from 'react';
import {
  ConfigurationAuthContext,
  ConfigurationAuthContextProps,
} from './ConfigurationAuthContext';

export function ConfigurationAuthProvider({
  access,
  children,
}: {
  access: ConfigurationAuthContextProps;
  children: React.ReactNode;
}) {
  return (
    <ConfigurationAuthContext.Provider value={access}>
      {children}
    </ConfigurationAuthContext.Provider>
  );
}
