import { ScheduleItem } from 'pages/SchedulesPage/SchedulesPage.types';

type LightingScheduleItem = ScheduleItem & {
  open?: string;
  start?: string;
  end?: string;
  desc?: string;
  holidays?: string;
  days?: string;
  on?: string;
  off?: string;
};

const lightingSchema = t => {
  return [
    {
      title: t('t3224') || 'Index',
      key: 'index',
      dataIndex: 'index',
      width: '8%',
      sorter: (a: LightingScheduleItem, b: LightingScheduleItem) => {
        return a?.id?.localeCompare(b?.id);
      },
    },
    {
      title: t('t3225') || 'Type',
      key: 'type',
      dataIndex: 'type',
      width: '8%',
      sorter: (a: LightingScheduleItem, b: LightingScheduleItem) => {
        return a?.type.localeCompare(b?.type);
      },
    },
    {
      title: t('t3226') || 'Holidays',
      key: 'holidays',
      dataIndex: 'holidays',
      width: '8%',
      sorter: (a: LightingScheduleItem, b: LightingScheduleItem) => {
        return a?.holidays.localeCompare(b?.holidays);
      },
    },
    {
      title: t('t3227') || 'Days',
      key: 'days',
      dataIndex: 'days',
      width: '8%',
      sorter: (a: LightingScheduleItem, b: LightingScheduleItem) => {
        return a?.days.localeCompare(b?.days);
      },
    },
    {
      title: t('t3228') || 'On',
      key: 'on',
      dataIndex: 'on',
      width: '8%',
      sorter: (a: LightingScheduleItem, b: LightingScheduleItem) => {
        return a?.on.localeCompare(b?.on);
      },
    },
    {
      title: t('t3229') || 'Off',
      key: 'off',
      dataIndex: 'off',
      width: '8%',
      sorter: (a: LightingScheduleItem, b: LightingScheduleItem) => {
        return a?.off.localeCompare(b?.off);
      },
    },
  ];
};

export { lightingSchema };
