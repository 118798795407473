import * as React from 'react';
import _get from 'lodash.get';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { UnitNetwork } from '@danfoss/etui-sm-xml';
import { getUnitDetails } from '../../utils';
import { DataItem } from '../DataItem';
import { Seperator } from '../Seperator';

function getArray<T>(data: T | T[]): T[] {
  if (!data) {
    return [];
  }
  return Array.isArray(data) ? (data as T[]) : [data];
}
interface IndividualUnitMinimalInfoProps {
  currentUnit: any;
  network: UnitNetwork;
  onInternet?: any;
  isPublicIpInInternalUnit: boolean;
  isPrivateIpInExternalUnit: boolean;
  macAddrSameForMasterSlaveUnits: boolean;
}
export function IndividualUnitMinimalInfo({
  currentUnit,
  network,
  onInternet,
  isPublicIpInInternalUnit,
  isPrivateIpInExternalUnit,
  macAddrSameForMasterSlaveUnits,
}: IndividualUnitMinimalInfoProps) {
  const { t } = useTranslation();

  const latestVersion = null;
  const latestFetchError = false;

  onInternet && onInternet(!latestFetchError && latestVersion);

  const InfoMap = [
    { label: 't889', path: 'unit_name' },
    { label: 't355', path: 'sys_model_name' },
    { label: 't883', path: 'sys_model_name' },
    { label: 't887', path: 'macAddr' },
  ];
  const getVersionContent = () => (
    <>
      {InfoMap.map(({ path, label }) => {
        const info = _get(
          { ...currentUnit, unit: getArray(currentUnit?.unit) },
          path,
        );
        return info ? (
          <Div key={`${label}_${path}`}>
            <Seperator />
            {path === 'macAddr' ? (
              <DataItem
                label={t(label)}
                value={info}
                warning={macAddrSameForMasterSlaveUnits ? t('t3311') : ''}
              />
            ) : (
              <DataItem label={t(label)} value={info} />
            )}
          </Div>
        ) : null;
      })}
      <Seperator />
      <DataItem
        label={t('t3306')}
        value={getUnitDetails(getArray(currentUnit?.unit), network).ip}
        warning={isPublicIpInInternalUnit ? t('t3295') : ''}
      />
      <Seperator />
      <DataItem
        label={t('t3323')}
        value={getUnitDetails(getArray(currentUnit?.unit), network).port}
      />
      <Seperator />
      <DataItem
        label={t('t3307')}
        value={getUnitDetails(getArray(currentUnit?.unit_internet), network).ip}
        warning={isPrivateIpInExternalUnit ? t('t3296') : ''}
      />
      <Seperator />
      <DataItem
        label={t('t3322')}
        value={
          getUnitDetails(getArray(currentUnit?.unit_internet), network).port
        }
      />
      <Seperator />
    </>
  );
  return (
    <Div testId="individual-unit-min-info" style={{ marginLeft: '33px' }}>
      {getVersionContent()}
    </Div>
  );
}
